/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  SAVE_PAYMENT_METHOD,
  SAVE_INSTALLMENT,
} = require('../actions/types');

const actions = {};

const initialState = {
  paymentMethod: {},
};

actions[SAVE_PAYMENT_METHOD] = (state, action) => ({
  ...state,
  paymentMethod: action.payload.paymentMethod,
});

actions[SAVE_INSTALLMENT] = (state, action) => ({
  ...state,
  installment: action.payload,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
