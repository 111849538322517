/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  CHANGE_IFRAME_SIZE,
} = require('../actions/types');

const actions = {};

const initialState = {
  actionToTake: '',
};

actions[CHANGE_IFRAME_SIZE] = (state, action) => ({
  ...state,
  actionToTake: action.payload,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
