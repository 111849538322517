const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');

const { BUTTON_HIERARCHY } = require('../../../constants/commons');

const BackButton = (props) => (
    <Button className="form-back-button" hierarchy={BUTTON_HIERARCHY.QUIET} onClick={props.handleBack}>
      {props.text}
    </Button>
  );

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = BackButton;
