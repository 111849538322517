const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const inputValuesActions = require('../../spa/actions/inputValues');
const installmentActions = require('../../spa/actions/installment');
const {
  CURRENT_INPUT_VALUES,
  INSTALLMENT_CLEAN,
  INSTALLMENT_UPDATE,
  VISIBILITY_INPUT_VALUES,
} = require('../../spa/actions/types');
const Select = require('../Select');
const translate = require('../../translation');

class SelectInstallments extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      value: props.savedValue || '',
      step: props.step,
    };
    this.translations = translate(i18n);
    this.onChange = this.onChange.bind(this);
    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.addAdditionalInfoElements = this.addAdditionalInfoElements.bind(this);
    // add child element to props
    this.addAdditionalInfoElements(this.props);
  }

  componentDidMount() {
    const optionSelected = this.getDefaultValue(this.props.options);
    if (optionSelected) {
      const toUpdate = optionSelected.value;

      if (this.props.id && this.state.step) {
        this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, toUpdate);
      }

      this.updateValue(toUpdate);

      if (this.props.updateSummary) {
        this.props.installmentActions[INSTALLMENT_UPDATE]({
          label: optionSelected.summaryLabel,
          value: optionSelected.id,
          total: optionSelected.total,
          interestFree: optionSelected.interestFree,
          totalPurchase: optionSelected.totalPurchase,
          totalWithInterest: optionSelected.totalWithInterest,
          interestAmount: optionSelected.interestAmount,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.optionsChanged(this.props.options, nextProps.options)) {
      this.onChange({}, '');
      /** reset summary installment value from store if the options (installments) had changed */
      this.props.installmentActions[INSTALLMENT_CLEAN]();
    }

    if (nextProps.options?.length > 0) {
      this.addAdditionalInfoElements(nextProps);
    }

  }

  onChange(e, value) {
    const optionSelected = this.props.options.find(opt => opt.value === value);
    const inputVisibility = {};

    const toUpdate = value;

    if (this.props.id && this.state.step) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, toUpdate);
    }

    if (optionSelected) {
      inputVisibility[`${this.state.step}_text_installment`] = optionSelected.showInterest
      this.props.inputValuesActions[VISIBILITY_INPUT_VALUES](inputVisibility);
    }

    if (this.props.updateSummary && optionSelected) {
      this.props.installmentActions[INSTALLMENT_UPDATE]({
        label: optionSelected.summaryLabel,
        value: optionSelected.id,
        total: optionSelected.total,
        interestFree: optionSelected.interestFree,
        totalPurchase: optionSelected.totalPurchase,
        totalWithInterest: optionSelected.totalWithInterest,
        interestAmount: optionSelected.interestAmount,
      });
    }
  }

  getDefaultValue(options) {
    return options?.find(opt => opt.selected);
  }

  optionsChanged(currOps, nextOps) {
    if (currOps && nextOps) {
      return nextOps && (nextOps.length !== currOps.length // if length changed
        || currOps.filter((op, i) => op.label !== nextOps[i].label).length > 0); // if labels were modified
    }
    return null;
  }

  /**
   * add child element for
   * @param {*} props
   */
  addAdditionalInfoElements(props) {
    // add additional info for additional option info ie: interest free
    if (props.options) {
      let selectAdditionalInfoChild = null;

      props.options.forEach((opt) => {
        let optionAdditionalInfoChild = null;
        if (opt.interestFree) {
          optionAdditionalInfoChild = (
            <span className="installment__interest">
              {this.translations.NO_INTEREST()}
            </span>
          );
        }
        if (opt.additionalInfoLabel && opt.additionalInfoValue) {
          selectAdditionalInfoChild = (<span className="financial-cost-info">{opt.additionalInfoLabel}: {opt.additionalInfoValue}</span>);
        }
        opt.selectAdditionalInfo = selectAdditionalInfoChild;
        opt.additionalInfo = optionAdditionalInfoChild;
      });
    }
  }

  updateValue(value) {
    if (value !== this.state.value) {
      this.setState({
        value,
      });
    }
  }

  render() {
    return (
      <div className="select-installments">
        <Select
          onChange={this.onChange}
          value={this.state.value}
          {...this.props}
        />
      </div>
    );
  }
}

SelectInstallments.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    pgettext: PropTypes.func,
  }),
  id: PropTypes.string,
  inputValuesActions: PropTypes.shape({
    [CURRENT_INPUT_VALUES]: PropTypes.func,
  }),
  installmentActions: PropTypes.shape({
    [INSTALLMENT_CLEAN]: PropTypes.func,
    [INSTALLMENT_UPDATE]: PropTypes.func,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    additionalInfo: PropTypes.string,
    additionalInfoLabel: PropTypes.string,
    additionalInfoValue: PropTypes.string,
    id: PropTypes.string,
    interestFree: PropTypes.bool,
    showInterest: PropTypes.bool,
    label: PropTypes.string,
    message: PropTypes.string,
    selected: PropTypes.bool,
    summaryLabel: PropTypes.string,
    total: PropTypes.string,
    totalPurchase: PropTypes.string,
    value: PropTypes.string,
  })),
  step: PropTypes.string,
  savedValue: PropTypes.string,
  updateSummary: PropTypes.bool,
  value: PropTypes.string,
};

SelectInstallments.defaultProps = {
  i18n: {
    gettext: (text, translation) => text.replace('{0}', translation),
    pgettext: (c, t) => t,
  },
  id: '',
  inputValuesActions: {
    [CURRENT_INPUT_VALUES]: () => { },
  },
  installmentActions: {
    [INSTALLMENT_CLEAN]: () => { },
    [INSTALLMENT_UPDATE]: () => { },
  },
  options: [{ showInterest: false }],
  savedValue: '',
  step: '',
  updateSummary: false,
  value: '',
};

const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  installmentActions: bindActionCreators(installmentActions, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
  options: ownProps.stateoptions ? state.selectOptions[`${state.page.flow.step}_${ownProps.stateoptions}`] : ownProps.options,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectInstallments;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(SelectInstallments));
}
