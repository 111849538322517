const React = require('react');

const IconUserPill = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="12" fill="#479AD1" fillOpacity="0.1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 13.4399C14.1222 13.4399 15.8423 11.7197 15.8423 9.59786C15.8423 7.47598 14.1222 5.75586 12.0003 5.75586C9.8784 5.75586 8.15827 7.47598 8.15827 9.59786C8.15827 11.7197 9.8784 13.4399 12.0003 13.4399ZM12.0003 12.4799C10.4086 12.4799 9.11827 11.1895 9.11827 9.59786C9.11827 8.00617 10.4086 6.71586 12.0003 6.71586C13.592 6.71586 14.8823 8.00617 14.8823 9.59786C14.8823 11.1895 13.592 12.4799 12.0003 12.4799Z" fill="#009EE3"/>
      <path d="M8.63449 15.8216C7.5727 15.8216 6.71195 16.6823 6.71195 17.7441V18.2241H5.75195V17.7441C5.75195 16.1521 7.04251 14.8616 8.63449 14.8616H15.3423C16.943 14.8616 18.2406 16.1592 18.2406 17.7599V18.2399H17.2806V17.7599C17.2806 16.6894 16.4128 15.8216 15.3423 15.8216H8.63449Z" fill="#009EE3"/>
    </svg>
);

module.exports = IconUserPill;
