import type { NavbarProps } from './types';

import React, { useState } from 'react';
import { Card, CardHeader } from '@andes/card';

import Logo from './Components/Logo';
import MenuOptions from './MenuOptions';
import { Theme } from '../../constants/commons';

function Navbar({
  user,
  theme = Theme.DEFAULT,
  isMLB = false,
  isMobile = false,
  menuOptions,
  changeUser,
  logOut,
  altLogo,
  altClosedMenu,
  altOpenedMenu,
}: NavbarProps) {
  const [openOverlay, setOpenOverlay] = useState(false);

  return (
    <>
      {isMobile && openOverlay && (
        <div
          onClick={() => setOpenOverlay(!openOverlay)}
          className="cow-navbar-v2-overlay"
        ></div>
      )}
      <Card className={`cow-navbar-v2 cow-navbar-v2__${theme}`}>
        <CardHeader>
          <div>
            <Logo isMLB={isMLB} theme={theme} alt={altLogo} />
            <MenuOptions
              theme={theme}
              user={user}
              menuOptions={menuOptions}
              setOpenOverlay={setOpenOverlay}
              changeUser={changeUser}
              logOut={logOut}
              altClosedMenu={altClosedMenu}
              altOpenedMenu={altOpenedMenu}
              isMobile={isMobile}
            />
          </div>
        </CardHeader>
      </Card>
    </>
  );
}

export default React.memo(Navbar);
