const ANIMATIONS_CLASSES = {
  OPEN: 'slide-down',
  CLOSED: 'slide-up',
};

const createToggleFunctionWithAnimation = (getElement, getVisibleState, setVisibleState) => {
  const animationDuration = 500; // Duration in milliseconds

  const toggleOverflow = () => {
    if (document?.body) {
      document.body.style.overflow = 'hidden';
      setTimeout(() => {
        document.body.style.overflow = 'visible';
      }, animationDuration);
    }
  };

  return () => {
    const element = getElement();
    const isVisible = getVisibleState();

    if (isVisible) {
      element.classList.remove(ANIMATIONS_CLASSES.OPEN);
      element.classList.add(ANIMATIONS_CLASSES.CLOSED);
    } else {
      element.classList.remove(ANIMATIONS_CLASSES.CLOSED);
      element.classList.add(ANIMATIONS_CLASSES.OPEN);
    }

    toggleOverflow();
    setVisibleState(!isVisible);
  };
};

module.exports = {
  createToggleFunctionWithAnimation,
};
