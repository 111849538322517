const React = require('react');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const modalActions = require('../../spa/actions/modal');
const { TOGGLE_MODAL_VISIBILITY } = require('../../spa/actions/types');
const IconGenericQuestionMark = require('./GenericQuestionMark');

class QuestionMark extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
  }

  render() {
    return (
      <div className="question-mark" onClick={this.handleClick}>
        <IconGenericQuestionMark />
      </div>
    );
  }
}

const mapDispatchToProp = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = QuestionMark;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(null, mapDispatchToProp)(QuestionMark);
}
