/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const Button = require('../../containers/Button/ButtonWithStore');
const modalActions = require('../../spa/actions/modal');
const { TOGGLE_MODAL_VISIBILITY } = require('../../spa/actions/types');

/**
 * Button to open modal
 */
class ButtonOpenModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault(event);
    this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
  }

  render() {
    const {
      id,
      kind,
      label,
      type,
      ...buttonProps
    } = this.props;

    return (
      <Button
        {...buttonProps}
        id={id}
        kind={kind}
        text={label}
        onClick={this.handleClick}
      />
    );
  }
}

ButtonOpenModal.propTypes = {
  id: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
  label: PropTypes.string,
  type: PropTypes.string,
  modalActions: PropTypes.shape({})
};

ButtonOpenModal.defaultProps = {
  kind: 'loud',
  id: '',
  label: '',
  type: '',
  modalActions: {},
};

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonOpenModal;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(null, mapDispatchToProps)(ButtonOpenModal);
}
