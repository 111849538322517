const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators, compose } = require('redux');

const buttonActions = require('../../spa/actions/button');
const { BUTTON_REQUEST_TRIGGERED } = require('../../spa/actions/types');
const { handleWebviewUrls } = require('../../utils/mobileWebkit');
const { isExternalDeepLinkFromBackUrl } = require('../../utils/deeplinks');

const { useEffect, useState } = React;

const buttonClass = {
  button: 'andes-button--loud',
  link: 'andes-button--link',
  quiet: 'andes-button--quiet',
};

const ButtonExternalLink = ({ buttonAction, buttonRequest, configurations, data, id, label, type, url }) => {
  const [deeplinkUrl, setDeeplinkUrl] = useState(null);

  useEffect(() => {
    buttonAction[BUTTON_REQUEST_TRIGGERED](false);
  }, [buttonAction]);

  useEffect(() => {
    if (isExternalDeepLinkFromBackUrl(url)) {
      const deeplink_url_to_replace = url.replace('http://', '');
      setDeeplinkUrl(deeplink_url_to_replace);
    }
  }, [url]);

  const handleClick = (event) => {
    if (!buttonRequest) {
      buttonAction[BUTTON_REQUEST_TRIGGERED](true);
      if (configurations.isWebview) {
        event.preventDefault();
        handleWebviewUrls(deeplinkUrl || url);
      }
    }
  };

  return (
    <a
      className={`andes-button ${buttonClass[type]} button-link`}
      href={deeplinkUrl || url}
      id={id}
      onClick={handleClick}
      target={data?.target || '_top'}
    >
      {label}
    </a>
  );
};

ButtonExternalLink.propTypes = {
  buttonAction: PropTypes.shape({
    BUTTON_REQUEST_TRIGGERED: () => {},
  }),
  buttonRequest: PropTypes.bool,
  configurations: PropTypes.PropTypes.shape({
    isWebview: PropTypes.bool,
  }),
  data: PropTypes.shape({
    target: PropTypes.string,
  }),
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

ButtonExternalLink.defaultProps = {
  buttonAction: {},
  buttonRequest: false,
  configurations: {},
  data: {},
  id: '',
  label: '',
  url: '',
  type: 'button',
};

const mapDispatchToProps = (dispatch) => ({
  buttonAction: bindActionCreators(buttonActions, dispatch),
});

const mapStateToProps = ({ button, configurations }) => ({
  buttonRequest: button.buttonRequest,
  configurations,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonExternalLink;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = compose(connect(mapStateToProps, mapDispatchToProps))(ButtonExternalLink);
}
