const React = require('react');

const ChevronLeft = () => (
  <svg
    className="chevron-left with-custom-color"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      id="chevron_left"
      d="M3.94554 7.39988L8.82796 2.53673L7.98111 1.68652L1.64844 7.99421L7.98111 14.3019L8.82796 13.4517L3.95693 8.59988H15.0986V7.39988H3.94554Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

module.exports = React.memo(ChevronLeft);
