import type { ReactElement, ReactNode } from 'react';
import type { CardListType } from '../components/CardListV2';

import React from 'react';
import { VisuallyHidden } from '@andes/common';

import { CardListTypeEnum } from '../constants/commons';

export const getTitle = (
  title?: ReactElement | string,
  fullTitle?: string,
  rightContent?: ReactNode,
  cardListType?: CardListType,
): ReactElement | string | null => {
  if (cardListType === CardListTypeEnum.INSTALLMENTS) {
    return (
      <>
        <span aria-hidden>{title}</span>
        <VisuallyHidden>{title}</VisuallyHidden>
        <VisuallyHidden>{rightContent}</VisuallyHidden>
      </>
    );
  }

  if (title && fullTitle) {
    return (
      <>
        <span aria-hidden>{title}</span>
        <VisuallyHidden>{fullTitle}</VisuallyHidden>
      </>
    );
  }

  return title || null;
};
