const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectValidations } = require('../../utils/validator-provider');

const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');

class InputEmail extends BaseValidation {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      value: props.defaultEmail || props.initialValue || '',
      error: props.error,
      invalid: props.invalid,
    };

    this.updateValue = this.updateValue.bind(this);
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  updateValue(value) {
    // Update the state
    this.setState({
      value,
    });
  }

  render() {
    const {
      globalErrors,
      step,
      errors,
      validations,
      initialValue,
      defaultEmail,
      ...inputProps
    } = this.props;

    return (
      <BaseInput
        {...inputProps}
        type="email"
        autoComplete="email"
        autoCapitalize="no"
        spellCheck="no"
        error={this.state.error}
        invalid={this.state.invalid}
        value={this.state.value}
        onChange={this.onChange}
        updateCallback={(value) => { this.updateValue(value); }}
        validations={validations}
      />
    );
  }
}

InputEmail.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  shouldSaveValue: PropTypes.bool,
  id: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.object),
};

InputEmail.defaultProps = {
  error: [],
  invalid: false,
  value: '',
  shouldSaveValue: true,
  id: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  globalErrors: state.globalErrors, // If this component support globalErrors
  step: state.page.flow.step,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputEmail;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(InputEmail));
}
