const React = require('react');
const PropTypes = require('prop-types');

const NavbarV2 = require('@cow/core-components/components/NavbarV2');
const { injectI18n } = require('nordic/i18n');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const translate = require('../../translation');
const { useLogOut } = require('../../hooks/useLogOut');
const STEP_ACTIONS = require('../../spa/actions/step');
const { SITE_ID: { MLB } } = require('../../../constants/commons');
const snackbarActions = require('../../spa/actions/snackbar');
const { isMobile } = require('../../utils/webview');
const { getCompanyTheme, getBrandNameCompany } = require('../../pages/review/templates/utils/configurations');


const Navbar = ({ i18n, ...props }) => {

  const translations = translate(i18n);

  const { logout } = useLogOut({
    siteId: props.siteId,
    browserName: props.configs?.browserName,
    flow: props.flow,
    history: props.history,
    stepActions: props.stepActions,
    snackbarActions: props.snackbarActions,
    translations: props.translations,
  });

  const theme = getCompanyTheme(props.configs?.internalConfigurations);

  const formattedProps = {
    user: {
      fullName: props.full_name,
      lastname: props.last_name,
      firstname: props.first_name,
      email: props.email,
      avatar: props.avatar_url,
    },
    menuOptions: {
      title: translations.YOU_ARE_LOGGED_AS,
      changeUserLabel: translations.CHANGE_ACCOUNT,
      logOutUserLabel: translations.LOG_OUT,
    },
    changeUser: () => logout({ changeUser: true }),
    logOut: () => logout({ changeUser: false }),
    isMobile: isMobile(props.configs?.deviceType),
    isMLB: props.siteId === MLB,
    altLogo: getBrandNameCompany(theme, translations),
    altClosedMenu: translations.YOU_ARE_USING_YOUR_ACCOUNT(props.first_name),
    altOpenedMenu: translations.CLOSE_ACCOUNT_OPTION,
    theme
  };

  return (<NavbarV2 {...formattedProps} />);
};

Navbar.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  email: PropTypes.string,
  image_url: PropTypes.string,
  siteId: PropTypes.string,
  configs: PropTypes.object,
  flow: PropTypes.string,
  history: PropTypes.object,
  stepActions: PropTypes.object,
  snackbarActions: PropTypes.object,
  translations: PropTypes.object,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
}

Navbar.defaultProps = {
  i18n: {
    gettext: (t) => t,
  },
}

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
});

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Navbar;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = injectI18n(connect(mapStateToProps, mapDispatchToProps)(Navbar));
}
