const React = require('react');
const PropTypes = require('prop-types');

const Markdown = require('../../Markdown');
const IconWithTooltip = require('../../IconWithTooltip');
const IconQuestionMark = require('../../icons/QuestionMark');
const IconBppShield = require('../../icons/ShieldBPP');
const UserPill = require('../../UserPill');

const {useCallback, useMemo} = React;

const types = {
  withHint: 'text_with_hint',
  withTooltip: 'icon_with_tooltip',
  withBPPShield: 'title_with_bpp',
  withUserPill: 'user_pill',
};

const GroupRowDetails = ({value}) => {
  const getItem = useCallback((item) => {
    switch (item.type) {
      case types.withHint:
        return (
          <>
            <Markdown text={item.text}/>
            <IconQuestionMark/>
          </>
        );
      case types.withTooltip:
        return (
          <IconWithTooltip
            icon={item.icon}
            text={item.text}
            title={item.title}
          />
        );
      case types.withBPPShield:
        return (
          <>
            <Markdown text={item.text}/>
            <IconBppShield/>
          </>
        );

      case types.withUserPill:
        return (
          <UserPill label={item.label}/>
        )
      default:
        return <Markdown text={item.text} trackEvent={item.track_event}/>;
    }
  }, []);

  const details = useMemo(() => value.map(
    (item) => (
      <div className={item.type} key={Math.random()}>
        {getItem(item)}
      </div>
    ),
  ), [value, getItem]);

  return <div className="row-details">{details}</div>;
};

GroupRowDetails.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
            track_event: PropTypes.shape({}),
          }),
        ),
      ]),
    }),
  ),
};

GroupRowDetails.defaultProps = {
  value: [],
};

module.exports = GroupRowDetails;
