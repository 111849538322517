/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { Route } = require('react-router-dom');

const SubPage = require('../SubPage');

const Page = props => (
  <Route
    exact
    path={`/${props.path}`}
    render={renderProps => <SubPage {...renderProps} pageProps={props} />}
  />
);

Page.propTypes = {
  path: PropTypes.string,
  tracking: PropTypes.object, // eslint-disable-line
  stepTitle: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Page.defaultProps = {
  path: '',
  tracking: {},
  stepTitle: '',
};

module.exports = Page;
