const React = require('react');
const PropTypes = require('prop-types');
const Loading = require('ui-library-mp/ui/Loading');

const Processing = (props) => (
  <div className="processing-loading">
    <Loading size={props.size} />
  </div>
);

Processing.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Processing.defaultProps = {
  size: 'large',
};

module.exports = Processing;
