/* eslint-env browser */


const removeAccentMark = (inputString) => inputString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

/**
 * Transform issuerName from api to a className Card component understands
 * @param issuerName
 */
const transformIssuer = (issuerName = '', escapeAccentMark = false) => {
  const mercadoPagoCard = /(Tarjeta|Cartão|Tarjeta de crédito) Mercado Pago/.exec(issuerName) || '';

  /**
   * @TODO temporary fix for the mercadopago cardIcon name
   * remove after the changes on the middlend are implemented
   */
  const replaceIssuerName = {
    'Mercado Pago + Banco Patagonia': 'mercadopago',
    'Banco Santander': 'banco santander rio sa',
    [mercadoPagoCard[0]]: 'mercado pago',
  };
  const fixedIssuerName = replaceIssuerName[issuerName] || issuerName;

  const normalizeIssuerName = fixedIssuerName
    .toLowerCase()
    .replace(/_/, ' ')
    .replace(/(-)(ar|br|mx|uy|co|cl|)/i, '')
    .replace(/\s+/g, '-')
    .replace(/\./g, '');

  return escapeAccentMark ? removeAccentMark(normalizeIssuerName) : normalizeIssuerName;
};


const shortenString = (string, maxLength) => {
  if (string.length > maxLength) {
    if (string.indexOf(' ') !== -1) {
      const splittedString = string.split(' ')[0];

      return shortenString(splittedString, maxLength);
    }

    return `${string.substring(0, maxLength - 3)}...`;
  }

  return string;
};

/**
 * Method that reduce a long word to a maxLength passed as param and adds elipsis
 * @param {*} string
 * @param {*} maxLength
 */
const reduceLongWord = (string, maxLength) => {
  if (string.length > maxLength) {
    if (string.indexOf(' ') === -1 || string.split(' ')[0].length > maxLength) {
      return `${string.substring(0, maxLength)}...`;
    }
  }

  return string;
};

/**
 * Method that split long texts that doesn't have space between word
 * Adds a '-' to separate the text between every line
 * @param {*} string
 * @param {*} maxLength
 */
const splitLongWord = (string, maxLength) => {
  if (string.length > maxLength) {
    if (string.indexOf(' ') === -1 || string.split(' ')[0].length > maxLength) {
      return `${string.slice(0, maxLength)} ${splitLongWord(string.substring(maxLength), maxLength)}`;
    }
  }

  return string;
};

/**
 * Method that returns true if text is a long word that doesn't have space between word
 * @param {*} string
 * @param {*} maxLength
 */
const isLongWord = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.indexOf(' ') === -1 || string.split(' ')[0].length > maxLength;
  }

  return false;
};

/**
 * Method that replaces characters of a string with others.
 * @param {*} string
 * @param {*} strToReplace String | Regex
 * @param {*} strNew
 */
const replaceChar = (string, strToReplace, strNew) => {
  if (!string || string.length === 0 || typeof string !== 'string') {
    return '';
  }

  return string.replace(strToReplace, strNew);
};

module.exports = {
  transformIssuer,
  shortenString,
  reduceLongWord,
  splitLongWord,
  isLongWord,
  replaceChar,
};
