const React = require('react');
const PropTypes = require('prop-types');

// Redux
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { ErrorScreen } = require('@andes/technical-error');
const { injectI18n } = require('nordic/i18n');

// Actions
const { MANAGE_CARD_FORM_ERROR } = require('../../spa/actions/types');
const CARD_FORM_ACTIONS = require('../../spa/actions/cardFormBricks');

const ErrorIllustration = require('../icons/ErrorIllustration');

// Constants
const { ENVIROMENT } = require('../../../constants/commons');

const TechnicalError = (props) => {
  const { cardFormActions, isManagingError } = props;

  let classNames = 'technical_error';

  if (props.className) {
    classNames += ` ${props.className}`;
  }

  React.useEffect(() => {
    // Clean the cardFormBricks error management state
    if (isManagingError) {
      cardFormActions[MANAGE_CARD_FORM_ERROR](false);
    }
  }, [isManagingError]);

  return (
    <div className={classNames}>
      <ErrorScreen
        action={{
          onClick: (event) => {
            event.preventDefault();
            window.history.back();
          },
          text: props.label
        }}
        asset={<ErrorIllustration />}
        /* TODO: The following prop is blocked until after the rollout. 
        Since this property injects custom wording that must be 
        controlled by AndesProvider, to avoid issues with AndesProvider 
        during the rollout, we decided to block this property and later 
        enable it and test it with a canary deploy, so we can measure the impact.
        errorCode={props?.errorCode}
        */
        title={props.message}
        className='technical_error__error-screen'
      />
    </div>

  );
};

TechnicalError.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  errorCode: PropTypes.string,
  label: PropTypes.string,
};

TechnicalError.defaultProps = {
  message: '',
  className: '',
  i18n: {
    gettext: (t) => t,
  },
  errorCode: '',
  label: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  cardFormActions: bindActionCreators(CARD_FORM_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  isManagingError: state.cardFormBricks.isManagingError,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = TechnicalError;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(TechnicalError));
}
