const { COMPONENT_ID, IS_NEW_INTERFACE } = require('../../constants/app');

const getComponentById = (components, id) => (Array.isArray(components) ? components.find((it) => it.id === id) : null);

const isSecureFieldsEnabled = (hiddenComponents) => {
  const secureFieldsEnabled = getComponentById(hiddenComponents, COMPONENT_ID.SECURE_FIELDS_ENABLED);

  return !!secureFieldsEnabled?.value;
};

const isProcessV2 = (components) => {
  if (!components) {
    return false;
  }
  const PROCESS_V2 = 2;
  const processV2 = getComponentById(components, COMPONENT_ID.PROCESS_VERSION);

  return !!(processV2?.value === PROCESS_V2);
};

const isEscEnabled = (hiddenComponents) => {
  const escEnabled = getComponentById(hiddenComponents, COMPONENT_ID.ESC_ENABLED);

  return !!escEnabled?.value;
};

const isCardId = (hiddenComponents) => {
  const cardId = getComponentById(hiddenComponents, COMPONENT_ID.CARD_ID);

  return cardId?.value;
};

const isRedirectTo = (hiddenComponents) => {
  const redirectTo = getComponentById(hiddenComponents, COMPONENT_ID.REDIRECT_TO);

  return redirectTo?.value;
};

const isZeroDollarError = (hiddenComponents) => {
  const zeroDollarError = getComponentById(hiddenComponents, COMPONENT_ID.ZERO_DOLLAR_ERROR);

  return zeroDollarError?.value;
};

const isErrorCode = (hiddenComponents) => {
  const errorCode = getComponentById(hiddenComponents, COMPONENT_ID.ERROR_CODE);

  return errorCode?.value;
};

/**
 * This function determines if a flow is migrated to the new checkout architecture
 * @param serverData
 * @returns {boolean}
 */
const isNewInterface = (serverData) => {
  // == Complete Migration ==
  const stepModelExists = (serverData?.step_model && Object.keys(serverData.step_model).length);
  if (stepModelExists) {
    return true;
  }
  // No Components found
  if (serverData?.components === null) {
    return true;
  }
  // == Intermediate Migration ==
  const isNewInterfaceInHiddenComp = serverData?.hidden_components?.find((elem) => elem.id === IS_NEW_INTERFACE);
  if (isNewInterfaceInHiddenComp) {
    return true;
  }
  return false;
};

module.exports = {
  isSecureFieldsEnabled,
  isProcessV2,
  isEscEnabled,
  isCardId,
  isRedirectTo,
  isNewInterface,
  isZeroDollarError,
  isErrorCode,
};
