// TODO this component should move to COW library and update optimus with this component.
const React = require('react');
const PropTypes = require('prop-types');
const ArrowLeft = require('@andes/icons/ArrowLeft16');
const { Text } = require('@andes/typography');

const BackButton = ({ text, url }) => (
  <Text component="a" size="s" weight="semibold" color="link" className="cow-back-button" href={url}>
    <ArrowLeft />
    {text}
  </Text>
);

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

module.exports = BackButton;
