const React = require('react');

const { useState, useEffect } = React;

const useScript = (url, name, loaded) => {

  const [lib, setLib] = useState({});

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement('script');
      script.src = url
      script.async = true
      script.onload = () => setLib({ [name]: window[name] });
      document.body.appendChild(script);
    }
  }, [loaded])

  return lib;
};

module.exports = useScript;
