import React, { useCallback, useEffect, useState } from 'react';

import useClickOnElement from '../../hooks/useClickOnElement';

export type CollapsibleByElementRefProps = {
  show?: boolean;
  triggerId?: string;
  children: React.ReactNode;
  withTransition?: boolean;
};

export default function CollapsibleByElementRef(
  props: CollapsibleByElementRefProps,
) {
  const [isOpen, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen((value) => !value);
  }, []);

  useClickOnElement(toggle, props?.triggerId);

  useEffect(() => {
    if (props.show !== undefined) {
      setOpen(props.show);
    }
  }, [props.show]);

  return (
    <>
      {(isOpen || props.withTransition) && (
        <div
          className={`collapsible ${
            isOpen ? 'collapsible--open' : 'collapsible--closed'
          }`}
        >
          {props.children}
        </div>
      )}
    </>
  );
}
