/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  LOTTIE_ANIMATION,
} = require('./types');

// Request Actions
const lottieLoaded = createAction(LOTTIE_ANIMATION);

/**
 * Export functions
 */
module.exports = {
  [LOTTIE_ANIMATION]: loaded => dispatch => dispatch(lottieLoaded(loaded)),
};
