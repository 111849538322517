/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  TRACKING_LOADED,
  EXECUTE_TRACKING,
} = require('./types');

// Track Actions
const trackingFired = createAction(TRACKING_LOADED);
const executeTrackingFired = createAction(EXECUTE_TRACKING);

/**
 * Export functions
 */
module.exports = {
  [TRACKING_LOADED]: (loaded) => (dispatch) => dispatch(trackingFired(loaded)),
  [EXECUTE_TRACKING]: (trackData) => (dispatch) => dispatch(executeTrackingFired(trackData)),
};
