const restclient = require('nordic/restclient');

const RenderJS = require('./RenderJs');
const { COMPONENT_ID } = require('../../constants/app');

/**
 * Change modal opacity to 0.
 */
const changeModalOpacity = () => {
  const modal = document.getElementById('layout-modal');

  if (modal) {
    modal.style.opacity = 0;
  }
};

const MODAL_ACTION = {
  CLOSE: 'close'
};

/**
 * Terminate the flow by sending a DELETE request to the current URL.
 */
exports.terminateFlow = () => {
  const request = restclient({
    baseURL: window.location.href,
    timeout: 5000,
  });

  request.delete('');
};

/**
 * Close the modal and send a postMessage to the parent window.
 *
 * Cancel payment when post a MODAL_ACTION.CLOSE message to the parent window when input_hidden.
 * @param {Array|Object} data - The data to be sent in the postMessage. *
 * @param {Array|Object} extraData - If exists, it`s additional data will be sent in the postMessage. 
 */
exports.closeModal = (data, extraData) => {
  changeModalOpacity();
  
  if (window.parent !== undefined) {
    const backUrl = Array.isArray(data) ? data.find((component) => component.id === COMPONENT_ID.BACK_URL) : null;
    const baseData = { type: MODAL_ACTION.CLOSE };
    let postData = backUrl ? { ...baseData, value: [backUrl] } : baseData;

    if (extraData) {
      postData = {...baseData, data: extraData};
    }

    window.parent.postMessage(postData, '*');
    RenderJS.finalize(data);
  }
};
