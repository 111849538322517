const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');
const path = require('path');
const MarkDown = require('../../components/Markdown');
const { URL_HASH } = require('../../../constants/commons');

const TyCCredits = ({ text, contractUrl, tycUrl, basePath, flow, checkoutType }) => {

  const handleClick = (event) => {
    const tagName = event.target.tagName;
    if(tagName === 'A') {
      const hashUrl = event.target.href.split('#')[1];
      let finalURL = (hashUrl === URL_HASH.TYC_CC_PARTICULAR || hashUrl === URL_HASH.TYC_CC_CONTRACT) ? contractUrl : tycUrl;
      finalURL = finalURL ? encodeURIComponent(finalURL) : '';
      window.location.href = path.join(basePath, `/${checkoutType}/${flow.id}/terms-and-conditions?url=${finalURL}&type=${hashUrl}`);
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="tyc_consumer_credits" onClick={handleClick}>
      <MarkDown text={text} />
    </div>
  );
};

TyCCredits.propTypes = {
  text: PropTypes.string.isRequired,
  basePath: PropTypes.string,
  flow: PropTypes.object,
  checkoutType: PropTypes.string,
  tycUrl: PropTypes.string,
  contractUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  basePath: state.configurations.basePath,
  checkoutType: state.configurations.checkoutType,
  flow: state.page.flow,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = TyCCredits;
} else {
  module.exports = connect(mapStateToProps)(TyCCredits);
}
