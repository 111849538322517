/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  GLOBAL_ERRORS_ADD,
  GLOBAL_ERRORS_CLEAN,
} = require('../actions/types');

const actions = {};

const initialState = {};

actions[GLOBAL_ERRORS_ADD] = (state, action) => ({
  ...state,
  [action.payload.id]: action.payload.validations,
});

actions[GLOBAL_ERRORS_CLEAN] = () => (initialState);

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
