const React = require('react');
const PropTypes = require('prop-types');

class AdTracking extends React.Component {
  componentDidMount() {
    const adTrackingComponent = this.props.components ? this.props.components.find(component => component.id === 'ad_tracking') : [];
    /**
     * group components by id and apply model
     * transform [{id: fb},{id: fb}, {id: adwords}]
     * into: {adwords: [{...}], fb: [{...}, {...}]}
     */
    const adTrackingModel = adTrackingComponent && Array.isArray(adTrackingComponent.value)
      ? adTrackingComponent.value.reduce((objectsById, component) => {
        const { id } = component;
        const model = {
          conversionValue: component.value,
          conversionLabel: component.label,
          conversionId: component.conversion_id, // campaign id
          currencyCode: component.currency,
        };
        objectsById[id] = (objectsById[id] || []).concat(model);
        return objectsById;
      }, {}) : null;

    if (adTrackingModel) {
      adTrackingModel.adwordsEnabled = !!adTrackingModel.adwords;
      adTrackingModel.fbEnabled = !!adTrackingModel.fb;

      const GTMdataLayer = { event: 'adTrackingLoad', ...adTrackingModel };
      if (window.dataLayer && (GTMdataLayer.adwords || GTMdataLayer.fb)) {
        window.dataLayer.push(GTMdataLayer);
      }
    }
  }

  render() {
    return (null);
  }
}

AdTracking.propTypes = {
  components: PropTypes.arrayOf(PropTypes.any),
};

AdTracking.defaultProps = {
  components: [],
};

module.exports = AdTracking;
