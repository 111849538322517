/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  SAVE_ADDRESS_STATE_INFORMATION,
  SAVE_ADDRESS_CITY_INFORMATION,
  SAVE_ADDITIONAL_ADDRESS_INFORMATION,
  CLEAR_ADDRESS_INFORMATION,
} = require('./types');

// Request Actions
const saveAddressStateInformation = createAction(SAVE_ADDRESS_STATE_INFORMATION);
const saveAddressCityInformation = createAction(SAVE_ADDRESS_CITY_INFORMATION);
const saveAddressAdditionalInformation = createAction(SAVE_ADDITIONAL_ADDRESS_INFORMATION);
const clearAddressInformation = createAction(CLEAR_ADDRESS_INFORMATION);
/**
 * Export functions
 */
module.exports = {
  /**
   * Save address state input data
   * @return {function(*)}
   */
  [SAVE_ADDRESS_STATE_INFORMATION]: address => (dispatch) => {
    dispatch(saveAddressStateInformation(address));
  },
  /**
   * Save address city input data
   * @return {function(*)}
   */
  [SAVE_ADDRESS_CITY_INFORMATION]: address => (dispatch) => {
    dispatch(saveAddressCityInformation(address));
  },
  /**
   * Save address additional data
   * (street, neighborhood, zipcode)
   * @return {function(*)}
   */
  [SAVE_ADDITIONAL_ADDRESS_INFORMATION]: address => (dispatch) => {
    dispatch(saveAddressAdditionalInformation(address));
  },
  [CLEAR_ADDRESS_INFORMATION]: () => (dispatch) => {
    dispatch(clearAddressInformation());
  },
};
