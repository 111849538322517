const React = require('react');
const PropTypes = require('prop-types');
const { TextField } = require('@andes/textfield');
const { formatYapePhone } = require('../../utils/format');

const InputPhoneYape = React.forwardRef((props, ref) => {
  const ariaInvalid = props.modifier === 'error';

  const onChange = (e) => {
    formatYapePhone(e.target.value, e.target);
    props.onChange(e);
  };

  return (
    <TextField
      {...props}
      ref={ref}
      onChange={onChange}
      aria-invalid={ariaInvalid}
      className="input__phone"
      data-testid="phone"
      placeholder='Ej.: 921 213 238'
      maxLength={11}
    />
  );
});

InputPhoneYape.displayName = 'InputPhoneYape';

InputPhoneYape.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helper: PropTypes.string,
  modifier: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

module.exports = InputPhoneYape;
