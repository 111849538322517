const React = require('react');
const PropTypes = require('prop-types');
const BlueChevron = require('../../icons/BlueChevron');
const classNames = require('classnames');

const { useState } = React;

const GroupRowDropdown = ({value, wording}) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow((prevState) => !prevState);
  const rowDropdownStyle = classNames('row-dropdown__toggle', {'up': show, 'down': !show});

  const details = value.map((item) => (
    <div className={item.type} key={Math.random()}>
      {item.text}
    </div>
  ));

  return (
    <div className="row-dropdown">
      {show && <div className="row-dropdown__content">{details}</div>}
      <div className={rowDropdownStyle} onClick={toggle}>
        <span className="label">{wording} <BlueChevron /></span>
      </div>
    </div>
  )
};

GroupRowDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  ]),
  wording: PropTypes.string
};

GroupRowDropdown.defaultProps = {
  value: [],
};

module.exports = GroupRowDropdown;
