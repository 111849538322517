const React = require('react');
const PropTypes = require('prop-types');

const Instruction = ({ position, text }) => (
  <div className="instruction intruction-steps__item">
    <div className="intruction-steps__item-counter">{position}</div>
    <div className="intruction-steps__item-label">{text}</div>
  </div>
);

Instruction.propTypes = {
  position: PropTypes.number,
  text: PropTypes.string,
};

Instruction.defaultProps = {
  position: 1,
  text: '',
};

module.exports = Instruction;
