const React = require('react');
const PropTypes = require('prop-types');

const { RadioGroup } = require('@andes/radio-button');
const RadioButton = require('@andes/radio-button');

const BaseValidation = require('../BaseValidation');

class InputGroupRadio extends BaseValidation {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.selectedValue || props.options[0].value,
      key: '',
    };

    this.renderOptions = this.renderOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    /**
     * there is an issue where the component don't apply the default value when it mounts from client side
     * if we trigger a state change we can re render the component with the selected value
     */
    this.setState({ key: Math.random() });
  }

  handleChange(value, event) {
    this.setState({
      selectedValue: value,
    });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  renderOptions() {
    if (this.props.options && this.props.options.length !== 0) {
      return this.props.options.map((option, index) => (
        <RadioButton
          key={`${option.label}-${option.value}`}
          id={`${this.props.id}_button_${index}`}
          label={option.label}
          value={option.value}
        />
      ));
    }
    return null;
  }

  render() {
    return (
      <>
        <RadioGroup
          id={this.props.id}
          name={this.props.name}
          defaultValue={this.state.selectedValue}
          inline={this.props.inline}
          onChange={this.handleChange}
          key={this.state.key}
        >
          {this.renderOptions()}
        </RadioGroup>
      </>
    );
  }
}

InputGroupRadio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  inline: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
};

InputGroupRadio.defaultProps = {
  id: '',
  name: '',
  onChange: null,
  show: true,
  inline: false,
  selectedValue: '',
  options: [],
};

module.exports = InputGroupRadio;
