const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const InputCaptchaToken = ({ name, captchaToken }) => (
  <input
    type="hidden"
    name={name}
    value={captchaToken}
  />
);

InputCaptchaToken.propTypes = {
  name: PropTypes.string.isRequired,
  captchaToken: PropTypes.string,
};

InputCaptchaToken.defaultProps = {
  captchaToken: '',
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  captchaToken: state.captchaToken.captchaToken,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputCaptchaToken;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, null)(InputCaptchaToken);
}
