/**
 * Helper for parsing dates.
 */
const addLeftZero = (n) => (n < 10 ? '0' : '') + n;

/**
 * Convert and format specified date in the specified format: dd-MM-yyyy HH:mm
 * @param {string} fullDate - date in ISO-8601 format
 *
 * @return {string} formated date
 */
const convertTime = (fullDate) => {
  const date = new Date(fullDate);

  return `${addLeftZero(date.getDate())}-${addLeftZero(date.getMonth() + 1)}-${date.getFullYear()} ${addLeftZero(
    date.getHours(),
  )}:${addLeftZero(date.getMinutes())}`;
};

const shiftedDateFromNow = (days = 0) => {
  const date = new Date();

  date.setDate(date.getDate() + days);

  return date;
};

const minsBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffMs = end.getTime() - start.getTime();

  return Math.round(diffMs / 60000);
};

const reduceDatesDifferenceToMinutes = (start, end) => {
  const differenceTime = Math.abs(end - start);
  const oneHour = 3600000;

  if (differenceTime < oneHour) {
    return differenceTime;
  }

  const minutes = differenceTime % oneHour;

  return start > end ? oneHour - minutes : minutes;
};

module.exports = {
  convertTime,
  shiftedDateFromNow,
  minsBetweenDates,
  reduceDatesDifferenceToMinutes,
};
