const React = require('react');

const SkeletonFull = () => (
  <div className="skeleton-full">
    <div className="skeleton-full__content">
      <div className="skeleton-full__content-header" />
      <div className="skeleton-full__content-item" />
      <div className="skeleton-full__content-header" />
      <div className="skeleton-full__content-item" />
      <div className="skeleton-full__content-item" />
    </div>
    <div className="skeleton-full__sidebar">
      <div className="skeleton-full__sidebar-header" />
      <div className="skeleton-full__sidebar-item" />
    </div>
  </div>
);

module.exports = SkeletonFull;
