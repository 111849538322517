const React = require('react');

const IconWrapper = require('../IconWrapper');

const CardCancel = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="102" height="78" viewBox="0 0 102 78">
      <g fill="none" fillRule="evenodd">
        <path fill="#D7DDE4" d="M1.316 24.475H91.72V13.58H1.316z" />
        <rect width="17" height="10.9" x="9" y="44" fill="#D7DDE4" fillRule="nonzero" rx="5" />
        <path fill="#9BA9BB" d="M59.016 61.933l.002-.103H9c-3.86 0-7-3.14-7-7V9c0-3.859 3.14-7 7-7h74.805c3.86 0 7 3.141 7 7v32.441c.686.312 1.353.656 2 1.032V9c0-4.963-4.038-9-9-9H9C4.037 0 0 4.037 0 9v45.83c0 4.963 4.037 9 9 9h50.101a22.89 22.89 0 0 1-.085-1.897" />
        <path fill="#D7DDE4" d="M78.5 78C65.542 78 55 67.458 55 54.5S65.542 31 78.5 31 102 41.542 102 54.5 91.458 78 78.5 78" />
        <path fill="#F0F0F0" d="M78.5 33C66.645 33 57 42.645 57 54.5S66.645 76 78.5 76 100 66.355 100 54.5 90.355 33 78.5 33" />
        <path d="M72.541 48.541L85.53 61.53M72.33 61.248l12.988-12.989" stroke="#D7DDE4" strokeLinecap="round" strokeWidth="1.8" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = CardCancel;
