import type { Logo as LogoType } from '../types';

import React from 'react';

import { Theme } from '../../../constants/commons';
import MercadoPagoFullLogo from '../../Icons/MercadoPagoFullLogo';
import IconWrapper from '../../Icons/IconWrapper';
import MercadoLivreNewLogo from '../../Icons/MercadoLivreNewLogo';
import MercadoLibreNewLogo from '../../Icons/MercadoLibreNewLogo';

const Logo = ({ isMLB, theme, alt }: LogoType) => {
  let logo: React.ReactNode;

  switch (theme) {
    case Theme.YELLOW_BUTTON:
    case Theme.ML:
    case Theme.POTS:
      logo = isMLB ? (
        <MercadoLivreNewLogo alt={alt} />
      ) : (
        (logo = <MercadoLibreNewLogo alt={alt} />)
      );

      break;

    default:
      logo = <MercadoPagoFullLogo alt={alt} />;

      break;
  }

  return <IconWrapper className="icon-mercadopago-full">{logo}</IconWrapper>;
};

export default Logo;
