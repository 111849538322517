const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');
const env = require('nordic/env');

const { injectValidations } = require('../../utils/validator-provider');
const InputAutocomplete = require('../InputAutocomplete');
const Api = require('../../service/api');
const addressActions = require('../../spa/actions/address');
const {
  SAVE_ADDRESS_CITY_INFORMATION,
} = require('../../spa/actions/types');
const translate = require('../../translation');

class InputAddressCity extends InputAutocomplete {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.stateId = null;
    this.getCities = this.getCities.bind(this);
    this.onSelectSave = this.onSelectSave.bind(this);
    this.saveCityInStore = this.saveCityInStore.bind(this);
    this.translations = translate(i18n);
    this.state.error = [this.translations.ENTER_VALID_CITY];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.address !== nextProps.address) {
      this.getCities(nextProps.address);
    }
  }

  /**
   *
   * @param {*} address
   */
  getCities(address) {
    const stateId = address.state.id || null;
    if (stateId && this.stateId !== stateId) {
      this.stateId = stateId;
      Api.getCities(stateId).then(res => this.setState({ suggestions: res }));
    }
  }

  saveCityInStore(selectedOption) {
    this.props.addressActions[SAVE_ADDRESS_CITY_INFORMATION]({
      id: selectedOption.id,
      name: selectedOption.name,
    });
  }

  onSelectSave(e, option) {
    this.saveCityInStore(option);
  }
}

InputAddressCity.propTypes = {
  address: PropTypes.object, // eslint-disable-line
  globalErrors: PropTypes.object, // eslint-disable-line
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

InputAddressCity.defaultProps = {
  address: {},
  globalErrors: {},
  i18n: {
    gettext: t => t,
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  addressActions: bindActionCreators(addressActions, dispatch),
});

const mapStateToProps = (state, props) => {
  const elVisibility = state.inputValues.visibility[`${state.page.flow.step}_${(props.id)}`];
  return {
    address: state.address,
    globalErrors: state.globalErrors,
    show: (elVisibility !== undefined) ? elVisibility : props.show,
    step: state.page.flow.step,
  };
};

if (env.TEST) {
  module.exports = InputAddressCity;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(injectI18n(InputAddressCity)));
}
