const React = require('react');
const PropTypes = require('prop-types');

const { List } = require('@andes/list');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const { STEP_NEXT, RESET_BANK_SEARCH } = require('../../spa/actions/types');
const stepActionCreators = require('../../spa/actions/step');
const bankSearchActionCreators = require('../../spa/actions/bankSearch');
const { getQueryParams } = require('../../utils/Dom');
const BankListItem = require('../BankListItem');

const { useState, useEffect, useRef } = React;

const instantSearchBanks = (term, banks) => {
  const searchTerm = term.toLowerCase();

  return banks.filter(({ label }) => label.toLowerCase().search(searchTerm) !== -1);
};

const BankList = (props) => {
  const { banks, search, stepActions, bankSearchActions, flow, history } = props;

  const [stateBanks, setStateBanks] = useState(banks);
  const [selectedId, setSelectedId] = useState();

  const formRef = useRef(null);
  const emptyStateBanks = stateBanks.length === 0;

  useEffect(() => {
    bankSearchActions[RESET_BANK_SEARCH]();
  }, [bankSearchActions]);

  useEffect(() => {
    if (search) {
      setStateBanks(instantSearchBanks(search, banks));
    } else {
      setStateBanks(banks);
    }
  }, [search, banks]);

  const handleSubmit = () => {
    if (formRef) {
      const queryParams = getQueryParams();

      stepActions[STEP_NEXT](
        formRef.current,
        flow.id,
        {
          type: flow.type,
          urlParams: queryParams,
        },
        flow.type,
        {},
        history,
      );
    }
  };

  useEffect(() => {
    if (selectedId) {
      handleSubmit();
    }
  }, [selectedId]);

  return (
    <List>
      <form hidden ref={formRef}>
        <input type="hidden" name="[bank_id]" value={selectedId} />
      </form>
      {!emptyStateBanks &&
        stateBanks.map((bank) => (
          <BankListItem
            key={bank.label}
            bank={bank}
            onClick={() => setSelectedId(bank.id)}
          />
        ))}
    </List>
  );
};

BankList.propTypes = {
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  stepActions: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func,
  }).isRequired,
  bankSearchActions: PropTypes.objectOf(
    PropTypes.shape({
      [RESET_BANK_SEARCH]: PropTypes.func.isRequired,
    }),
  ),
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
  }),
  search: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.bankSearch.value,
  flow: state.page.flow,
});

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActionCreators, dispatch),
  bankSearchActions: bindActionCreators(bankSearchActionCreators, dispatch),
});

const connectWithRedux = connect(mapStateToProps, mapDispatchToProps);

if (process.env.NODE_ENV === 'test') {
  module.exports = BankList;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connectWithRedux(BankList);
}
