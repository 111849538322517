/* eslint-disable react/sort-comp */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const LottieAnimation = props => (
  <div className="lottie-wrapper">
    <div className="spacer" />
    <span className={props.showMessage ? 'animation-text--show' : 'animation-text--hide'}>{props.text}</span>
  </div>
);

LottieAnimation.propTypes = {
  text: PropTypes.string,
  showMessage: PropTypes.bool,
};

LottieAnimation.defaultProps = {
  text: '',
  showMessage: false,
};

const mapStateToProps = state => ({
  showMessage: state.lottie.loaded,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = LottieAnimation;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(LottieAnimation);
}
