module.exports = {
  SERVICE_NAMES: {
    BIN: 'bin',
    CARDS: 'cards',
    CARD_TOKENS: 'cardTokens',
    COUNTRIES: 'countries',
    FLOW: 'flow',
    INSCRIPTION: 'inscription',
    OPENFINANCE: 'openfinance',
    PREFERENCE: 'preference',
    RELATIONS: 'relations',
    SITESECURITY: 'sitesecurity',
    INSTALLMENTS: 'installments',
    SNIFFING: 'sniffing',
    PAYMENT_YAPE: 'paymentYape',
  },

  DEFAULT_ERRORS: {
    NO_DATA: 'Invalid Response: There was no data',
    INVALID_RESPONSE: 'Invalid Response',
    INVALID_PARAMS: 'Invalid params',
    USER_NOT_SPECIFIED: 'Invalid Request: User not specified',
    EMPTY_DATA: 'Invalid Response: empty data',
    WIDGET_FRAUD: 'Error getting widget from Fraud'
  }
}
