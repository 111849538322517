const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const InstallmentsSummary = ({ text, total }) => (
  <div className="installments-summary">{total || text}</div>
);

InstallmentsSummary.propTypes = {
  text: PropTypes.string,
  total: PropTypes.string,
};

InstallmentsSummary.defaultProps = {
  text: '',
  total: '',
};

const mapStateToProps = (state) => ({
  total: state.installment.total,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InstallmentsSummary;
} else {
  /* istanbul ignore next: can't test it with tests */
  module.exports = connect(mapStateToProps)(InstallmentsSummary);
}
