const { CARD_FORM_BRICKS_ERRORS: {
  EXCLUDED_PAYMENT_TYPE_ERROR,
  EXCLUDED_PAYMENT_ID_ERROR,
  PAYMENT_METHOD_NOT_FOUND,
} } = require('../../../constants/app');
const translate = require('../../translation');

const normalizeKey = (key) => key.toLowerCase();

const getErrorMessage = (i18n, error) => {
  const translations = translate(i18n);

  const errorMap = {
    [normalizeKey(EXCLUDED_PAYMENT_TYPE_ERROR)]: {
      reason: '',
      message: translations.EXCLUDE_PAYMENT_TYPE_MESSAGE,
    },
    [normalizeKey(EXCLUDED_PAYMENT_ID_ERROR)]: {
      reason: '',
      message: translations.EXCLUDE_PAYMENT_METHOD_MESSAGE,
    },
    [normalizeKey(PAYMENT_METHOD_NOT_FOUND)]: {
      reason: '',
      message: translations.INVALID_PAYMENT_METHOD,
    },
  }

  return errorMap[normalizeKey(error?.reason)];
}


module.exports = {
  getErrorMessage,
};