const React = require('react');
const PropTypes = require('prop-types');

const { Message: AndesMessage, MessageActions } = require('@andes/message');

const Message = ({ id, color, text, title, closable, hierarchy, className, actionUrl, actionText }) => (
  <AndesMessage
    id={id}
    color={color}
    title={title}
    closable={closable}
    hierarchy={hierarchy}
    className={className}
    actions={actionUrl && <MessageActions primary={{ href: actionUrl, text: actionText }} /> }
  >
    <div>{text}</div>
  </AndesMessage>
);

Message.propTypes = {
  color: PropTypes.oneOf(['accent', 'green', 'orange', 'red']),
  text: PropTypes.string,
  title: PropTypes.string,
  closable: PropTypes.bool,
  hierarchy: PropTypes.oneOf(['loud', 'quiet']),
  className: PropTypes.string,
  actionUrl: PropTypes.string,
  actionText: PropTypes.string,
};

Message.defaultProps = {
  color: 'accent',
  text: '',
  title: '',
  closable: false,
  hierarchy: 'loud',
  className: '',
};

module.exports = Message;
