const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const IconCreditCard = ({ color, avatar = null }) => {
  const width = avatar ? 51 : 24;
  const height = avatar ? 36 : 18;

  return (
    <IconWrapper>
      <svg aria-hidden="true" className="with-custom-color" width={width} height={height} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.248 14.25H3.751V12.75H8.248V14.25Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 0.75H3C1.75736 0.75 0.75 1.75736 0.75 3V15C0.75 16.2426 1.75736 17.25 3 17.25H21C22.2426 17.25 23.25 16.2426 23.25 15V3C23.25 1.75736 22.2426 0.75 21 0.75ZM2.25 8.25V6.75H21.75V8.25H2.25ZM2.25 5.25H21.75V3C21.75 2.58579 21.4142 2.25 21 2.25H3C2.58579 2.25 2.25 2.58579 2.25 3V5.25ZM2.25 9.75H21.75V15C21.75 15.4142 21.4142 15.75 21 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V9.75Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
};

IconCreditCard.defaultProps = {
  color: 'currentColor',
};

IconCreditCard.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(IconCreditCard);
