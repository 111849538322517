const React = require('react');

const Skeleton = () => (
  <div className="skeleton">
    <div className="skeleton-header" />
    <div className="skeleton-title" />
    <div className="skeleton-secondary" />
    <div className="skeleton-item" />
    <div className="skeleton-item" />
    <div className="skeleton-secondary bottom" />
    <div className="skeleton-item" />
    <div className="skeleton-item" />
  </div>
);

module.exports = Skeleton;
