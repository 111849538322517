/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

/**
 * InstallmentRow
 */
const InstallmentRow = (props) => {
  const {
    installment,
    amount,
    totalAmount,
  } = props;

  return (
    <div className="installments-list">
      <p className="installments-list__installment">{installment}</p>
      <p className="installments-list__amount">{amount}</p>
      <div className="installments-list__total-amount">
        {totalAmount.map(({ type, text }) => (
          <p key={Math.random()} className={type === 'text_promo' ? 'promo' : 'total'}>
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

/**
 * Prop Types
 */
InstallmentRow.propTypes = {
  installment: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  totalAmount: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

/**
 * Default Props
 */

InstallmentRow.defaultProps = {
  installment: '',
  amount: '',
  totalAmount: [
    {
      type: '',
      text: '',
    },
  ],
};

/**
 * Expose InstallmentRow
 */
module.exports = InstallmentRow;
