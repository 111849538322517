/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { SNACKBAR_TRIGGER } = require('../actions/types');

const actions = {};

const initialState = {
  show: false,
  status: '',
  message: '',
  delay: 0
};

actions[SNACKBAR_TRIGGER] = (state, action) => ({
  show: action.payload.show,
  status: action.payload.status || 'success',
  message: action.payload.message,
  delay: action.payload.delay,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
