const React = require('react');
const PropTypes = require('prop-types');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');
const {
  COMMONS: { CONDITIONS_TYPES: conditionTypes },
  SELECTORS: { TERMS_AND_CONDITIONS_BASE_CLASS },
} = require('../../../constants');

const { useEffect } = React;

const  ConsumerCreditsTyC = ({ type: pageType, installment, i18n, url }) => {
  const baseClass = TERMS_AND_CONDITIONS_BASE_CLASS;
  const translations = translate(i18n);

  useEffect(() => {
    document.body.className = `${document.body.className} sub_step_consumer_credits_tyc`;

    return () => {
      document.body.className = document.body.className
        .split(' ')
        .filter(e => !e.startsWith('sub_step'))
        .join(' ');
    };
  }, []);

  const getUrl = (type) => (type === conditionTypes.PARTICULAR || type === conditionTypes.CONTRACT) ? installment?.contractUrl : installment?.tycUrl;

  const finalUrl = url || getUrl(pageType);

  return finalUrl ? (
    <div className={`${baseClass} credits-conditions--${pageType}`}>
      <iframe
        className="credits-conditions__iframe"
        title={`Credits Conditions - ${pageType}`}
        src={finalUrl}
        width="100%"
        height="100%"
      />
    </div>
  ) : (
    <div className={`${baseClass} credits-conditions--message`}>
      <p className="message__content">
        {translations.YOU_WILL_BE_ABLE_TO_SEE_THIS_INFORMATION_AFTER_SELECTING_THE_NUMBER_OF_INSTALLMENTS}
      </p>
    </div>
  );
}

ConsumerCreditsTyC.propTypes = {
  type: PropTypes.string,
  installment: PropTypes.shape({
    contractUrl: PropTypes.string,
    tycUrl: PropTypes.string,
  }),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  url: PropTypes.string,
};

ConsumerCreditsTyC.defaultProps = {
  siteId: '',
  type: '',
  installment: {},
  i18n: {
    gettext: t => t,
  },
  url: null
};

const mapStateToProps = state => ({
  installment: state.installment,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ConsumerCreditsTyC;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(injectI18n(ConsumerCreditsTyC));
}
