const React = require('react');
const PropTypes = require('prop-types');

const { Modal } = require('@andes/modal');

const Button = require('../../containers/Button/ButtonWithStore');
const { defaultProps } = require('../../containers/Optimus/helper');

const InstructionModal = ({ visible, className, title, steps, ctaLabel, onCloseAction, deviceType }) => {
  let classes = 'instruction';
  if (className) {
    classes = `${classes} ${className}`;
  }

  return (
    <Modal
      className={classes}
      open={visible}
      type={deviceType === 'mobile' ? 'light' : 'tight'}
      onClose={onCloseAction}
      title={title}
    >
      <div className="instruction-steps">
        {steps.map((step, index) => (
          <React.Fragment key={step}>
            <div className="instruction-steps__item"  {...defaultProps()}>
              <div className="instruction-steps__item-counter">{index + 1}</div>
              <div className="instruction-steps__item-label">{step}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="instruction-action">
        <Button onClick={onCloseAction} text={ctaLabel} kind="loud" />
      </div>
    </Modal>
  );
};

InstructionModal.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  ctaLabel: PropTypes.string,
  onCloseAction: PropTypes.func,
  deviceType: PropTypes.string,
};

InstructionModal.defaultProps = {
  visible: false,
  className: '',
  title: '',
  steps: [],
  ctaLabel: '',
  onCloseAction: e => e,
  deviceType: 'desktop',
};

module.exports = InstructionModal;
