const { handleActions } = require('redux-actions');

const { BOTTOM_SHEET } = require('../actions/types');

const actions = {};

const initialState = {
  openBottomSheet: false,
};

actions[BOTTOM_SHEET] = (state, action) => {
  const newState = {
    ...state,
    openBottomSheet: action.payload.openBottomSheet,
    paymentMethodForm: action.payload.paymentMethodForm,
  };
  return newState;
};

module.exports = handleActions(actions, initialState);
