import type { TypographyProps } from '@andes/typography';
import type { MoneyAmountProps } from '@andes/money-amount';

import React from 'react';

// Andes Components
import { Typography } from '@andes/typography';
import { MoneyAmount } from '@andes/money-amount';

export type RowItemProps = {
  id?: string;
  label: TypographyProps['children'];
  labelColor?: TypographyProps['color'];
  labelWeight?: TypographyProps['weight'];
  labelClassName?: string;
  icon?: React.ReactNode;
  iconAlignment?: 'left' | 'right';
  value: string;
  valueSymbol?: MoneyAmountProps['symbol'];
  valueCents?: string | undefined;
  valueCentsType?: MoneyAmountProps['centsType'];
  valueColor?: TypographyProps['color'];
  valueWeight?: TypographyProps['weight'];
  valueClassName?: string;
  valueObservation?: TypographyProps['children'];
  valueObservationColor?: TypographyProps['color'];
  valueObservationTextSize?: Exclude<TypographyProps['size'], 'xl'>;
  labelObservationTextSize?: Exclude<TypographyProps['size'], 'xl'>;
  textSize?: Exclude<TypographyProps['size'], 'xl'>;
  valueSize?: MoneyAmountProps['size'];
  className?: string;
  labelComplement?: TypographyProps['children'];
  labelComplementColor?: TypographyProps['color'];
  labelComplementWeight?: TypographyProps['weight'];
  labelComplementClassName?: string;
  labelObservation?: TypographyProps['children'];
  labelObservationColor?: TypographyProps['color'];
  labelObservationWeight?: TypographyProps['weight'];
  labelObservationClassName?: string;
};

const RowItem = ({
  id,
  label,
  labelObservation,
  labelColor = 'primary',
  labelWeight = 'regular',
  labelClassName,
  icon,
  iconAlignment = 'left',
  value,
  valueCents,
  valueCentsType = 'superscript',
  valueSymbol,
  valueColor = 'primary',
  valueWeight = 'regular',
  valueClassName,
  valueObservation,
  valueObservationColor = 'primary',
  labelObservationColor = 'primary',
  labelObservationTextSize = 's',
  valueObservationTextSize = 'm',
  textSize = 'm',
  valueSize,
  className = '',
  labelComplement,
  labelComplementColor = 'primary',
  labelComplementWeight = 'regular',
  labelComplementClassName,
  labelObservationWeight = 'regular',
  labelObservationClassName,
  ...containerProps
}: RowItemProps) => (
  <div {...containerProps} className={`row-item ${className}`} id={id}>
    <div className="row-item__label--container">
      <div className="row-item__label--product">
        <Typography
          type="body"
          size={textSize}
          color={labelColor}
          weight={labelWeight}
          className={labelClassName}
        >
          {label}
        </Typography>

        {labelComplement && (
          <Typography
            type="body"
            size={textSize}
            color={labelComplementColor}
            weight={labelComplementWeight}
            className={labelComplementClassName}
          >
            {labelComplement}
          </Typography>
        )}
      </div>
      {labelObservation && (
        <div className="row-item__label--observation">
          <Typography
            type="body"
            size={labelObservationTextSize}
            color={labelObservationColor}
            weight={labelObservationWeight}
            className={labelObservationClassName}
          >
            {labelObservation}
          </Typography>
        </div>
      )}
    </div>
    {icon && (
      <div
        className={`row-item__icon-container ${
          iconAlignment === 'left'
            ? 'row-item__icon-container--left'
            : 'row-item__icon-container--right'
        }`}
      >
        <span>{icon}</span>
      </div>
    )}
    <div className="row-item__observation">
      <MoneyAmount
        centsType={valueCentsType}
        size={valueSize ?? 18}
        suffix=""
        className={`${
          valueClassName ?? ''
        } andes-typography--color-${valueColor}`}
        symbol={valueSymbol}
        value={{
          cents: valueCents,
          fraction: value,
        }}
        weight={valueWeight ?? 'regular'}
      />

      {valueObservation && (
        <Typography
          type="body"
          size={valueObservationTextSize}
          color={valueObservationColor}
        >
          {valueObservation}
        </Typography>
      )}
    </div>
  </div>
);

export default RowItem;
