const React = require('react');

const IconWrapper = require('../IconWrapper');

const MailIcon = () => (
  <IconWrapper>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 4H1V16H19V4ZM17.799 7.43001V14.799H2.199V7.42887L8.51042 11.0391L8.69768 11.1377C9.58349 11.5647 10.6282 11.5319 11.4896 11.0391L17.799 7.43001ZM17.799 6.04756V5.199H2.199V6.04641L9.10625 9.99753C9.60965 10.2855 10.2174 10.3117 10.7398 10.0761L10.8938 9.99753L17.799 6.04756Z" fill="#009EE3" />
    </svg>
  </IconWrapper>
);


module.exports = React.memo(MailIcon);
