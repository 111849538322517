const React = require('react');

// Custom Components
const IconQR = require("../components/icons/QR");
const IconPhoneQR = require("../components/icons/PhoneQR");
const IconAccountMoney = require("../components/icons/AccountMoney");
const IconEmail = require("../components/icons/Email");
const IconMercadoCredito = require("../components/icons/MercadoCredito");
const IconMercadoCreditoFull = require("../components/icons/MercadoCreditoFull");
const { defaultProps } = require("../containers/Optimus/helper");
const IconMercadoPago = require("../components/icons/MercadoPago");
const IconMercadoPagoFull = require("../components/icons/MercadoPagoFull");
const IconMercadoPagoBlack = require("../components/icons/MercadoPagoBlack");
const IconBank = require("../components/icons/Bank");
const CardCancel = require("../components/icons/CardCancel");
const IconOops = require("../components/icons/Oops");
const IconBack = require("../components/icons/Back");
const Loteria = require("../components/icons/Loteria");
const Boleto = require("../components/icons/Boleto");
const IconCash = require("../components/icons/Cash");
const IconIdentification = require("../components/icons/Identification");
const ImageIcon = require("../components/icons/ImageIcon");
const IconExtraData = require("../components/icons/ExtraData");
const IconQuestionMark = require("../components/icons/QuestionMark");
const IconPin = require("../components/icons/Pin");
const IconLocalPick = require("../components/icons/LocalPick");
const IconShppingType = require("../components/icons/ShippingType");
const IconStore = require("../components/icons/Store");
const IconUser = require("../components/icons/User");
const IconCreditCard = require("../components/icons/CreditCard");
const GenericWalletIcon = require("../components/icons/GenericWallet");
const IconSplitPayment = require("../components/icons/SplitPayment");
const PaymentCardIcon = require("../components/PaymentCardIcon");
const IconInstallments = require("../components/IconInstallments");
const IconInstallmentsDynamic = require("../components/IconInstallmentsDynamic");
const IconChevron = require("../components/icons/Chevron");
const IconBlueChevron = require("../components/icons/BlueChevron");
const IconUserDefault = require("../components/icons/UserDefault");
const IconNoCollaborator = require("../components/icons/NoCollaborator");
const IconUnrecoverablePayment = require("../components/icons/UnrecoverablePayment");
const GeoLocalization = require("../components/GeoLocalization");
const IconRecoveryApprovedPayment = require("../components/icons/RecoveryApprovedPayment");
const IconNoAccountBalance = require("../components/icons/NoAccountBalance");
const IconNoPaymentOption = require("../components/icons/NoPaymentOption");
const IconNoMeliCollector = require("../components/icons/NoMeliCollector");
const LottieAnimation = require("../components/LottieAnimation");
const Resizer = require("../components/Resizer");
const IconChevronLeft = require("../components/icons/ChevronLeft");
const IconMercadoLibre = require("../components/icons/MercadoLibre");
const IconDiscount = require("../components/icons/Discount");
const IconShield = require("../components/icons/shield");
const IconLock = require("../components/icons/Lock");
const IconShieldBPP = require("../components/icons/ShieldBPP");
const IconShieldBPPDisabled = require("../components/icons/ShieldBPPDisabled");
const IconShieldBPPWhite = require("../components/icons/ShieldBPPWhite");
const IconTicket = require("../components/icons/Ticket");
const IconWebpay = require("../components/icons/Webpay");
const IconOpenFinance = require("../components/icons/OpenFinance");
const IconAccountMoneyDisabled = require("../components/icons/AccountMoneyDisabled");
const IconCardCvv = require("../components/IconCardCvv");
const IconCaixa = require("../components/icons/Caixa");
const IconNoAvailableShipping = require("../components/icons/NoAvailableShipping");
const WebpayDebitUnavailable = require("../components/icons/WebpayDebitUnavailable");
const IconPse = require("../components/icons/Pse");
const IconPixUnavailable = require("../components/icons/PixUnavailable");
const IconAppStore = require("../components/icons/AppStore");
const IconPlayStore = require("../components/icons/PlayStore");
const IconProtectedPurchase = require("../components/icons/ProtectedPurchase");
const IconProtectedPurchaseGuest = require("../components/icons/ProtectedPurchaseGuest");
const IconFeedbackNegative = require("../components/icons/FeedbackNegative");
const IconCopy = require("../components/icons/Copy");
const IconSuccess = require("../components/icons/Success");
const OverflowMenuHorizontal = require("../components/icons/OverflowMenuHorizontal");
const IconInfo = require("../components/icons/Info");
const IconCombination = require("../components/icons/Combination");
const Anchor3DSIcon = require("../components/icons/Anchor3DS");

// Cow Core Components
const MercadoPagoBlack = require("@cow/core-components/components/Icons/MercadoPagoBlack");

// Constants
const { SITE_ID, ISSUER_ID } = require('../../constants/commons');
const { COMMONS: { PAYMENT_METHOD } } = require("../../constants");

const icons = {
  icon_installments: (props) => <IconInstallments {...defaultProps(props)} text={props.text} key={Math.random()} />,
  icon_installments_dynamic: (props) => (
    <IconInstallmentsDynamic {...defaultProps(props)} id={props.id} text={props.text} key={Math.random()} />
  ),
  icon_chevron: () => <IconChevron key={Math.random()} />,
  icon_blue_chevron: () => <IconBlueChevron key={Math.random()} />,
  icon_user_default: () => <IconUserDefault ley={Math.random()} />,
  generic_payment_card_icon: () => <IconCreditCard key={Math.random()}/>,
  generic_wallet_icon: () => <GenericWalletIcon key={Math.random()}/>,
  icon_qr: () => <IconQR key={Math.random()}/>,
  icon_phone_qr: () => <IconPhoneQR key={Math.random()}/>,
  icon_account_money: () => <IconAccountMoney key={Math.random()}/>,
  icon_email: () => <IconEmail key={Math.random()}/>,
  icon_mercado_credito: () => <IconMercadoCredito key={Math.random()}/>,
  icon_mercado_credito_full: () => <IconMercadoCreditoFull {...defaultProps()} />,
  icon_mercado_pago: () => <IconMercadoPago key={Math.random()}/>,
  icon_mercado_pago_full: () => <IconMercadoPagoFull key={Math.random()}/>,
  icon_mercado_pago_black: () => <IconMercadoPagoBlack key={Math.random()}/>,
  icon_bank: () => <IconBank key={Math.random()}/>,
  icon_card_cancel: () => <CardCancel/>,
  icon_oops: () => <IconOops key={Math.random()}/>,
  icon_back: () => <IconBack key="icon-back"/>,
  icon_loteria: () => <Loteria key={Math.random()}/>,
  icon_boleto: () => <Boleto key={Math.random()}/>,
  icon_cash: () => <IconCash key={Math.random()}/>,
  icon_identification: () => <IconIdentification key={Math.random()}/>,
  image_icon: (props) => <ImageIcon {...props} key={Math.random()}/>,
  icon_extra_data: (props) => <IconExtraData {...props} key={Math.random()}/>,
  icon_question_mark: () => <IconQuestionMark key={Math.random()}/>,
  icon_pin: () => <IconPin key={Math.random()}/>,
  icon_local_pick: () => <IconLocalPick key={Math.random()}/>,
  icon_shipping_type: () => <IconShppingType key={Math.random()}/>,
  icon_store: () => <IconStore key={Math.random()}/>,
  icon_user: () => <IconUser key={Math.random()}/>,
  merchant_logo: () => <span className="merchant-logo" key={Math.random()}/>,
  payment_card_icon: (props) => (
    <PaymentCardIcon
      cardId={props.data?.payment_method_id}
      issuerName={props.data?.issuer_name}
      siteId={props?.siteId}
      key={Math.random()}
    />
  ),
  split_payment_card_icon: () => <IconSplitPayment key={Math.random()}/>,
  icon_no_collaborator: () => <IconNoCollaborator />,
  icon_unrecoverable_payment: () => <IconUnrecoverablePayment />,
  geolocalization: (props) => <GeoLocalization {...defaultProps(props)} name={props.name} />,
  icon_recovery_approved_payment: () => <IconRecoveryApprovedPayment />,
  icon_no_account_balance: () => <IconNoAccountBalance {...defaultProps()} />,
  icon_no_payment_option: () => <IconNoPaymentOption />,
  icon_no_meli_collector: () => <IconNoMeliCollector />,
  congrats_animation: (props) => <LottieAnimation text={props.text} />,
  resizer: () => <Resizer />,
  icon_chevron_left: () => <IconChevronLeft />,
  icon_mercado_libre: () => <IconMercadoLibre {...defaultProps()}/>,
  icon_discount: (props) => <IconDiscount {...defaultProps(props)} />,
  icon_shield: (props) => <IconShield {...defaultProps(props)} />,
  icon_lock: (props) => <IconLock {...defaultProps(props)} />,
  icon_shield_bpp: (props) => <IconShieldBPP {...defaultProps(props)} />,
  icon_shield_bpp_disabled: (props) => <IconShieldBPPDisabled {...defaultProps(props)} />,
  icon_shield_bpp_white: (props) => <IconShieldBPPWhite {...defaultProps(props)} />,
  icon_ticket: () => <IconTicket {...defaultProps()} />,
  icon_webpay: () => <IconWebpay key={Math.random()} />,
  icon_open_finance: () => <IconOpenFinance key={Math.random()} />,
  icon_account_money_disabled: () => <IconAccountMoneyDisabled key={Math.random()} />,
  icon_virtual_cvv: (props) => <IconCardCvv cardId="virtual" show={props.show} id={props.id} />,
  icon_caixa: (props) => <IconCaixa {...props} {...defaultProps()} />,
  icon_no_available_shipping: () => <IconNoAvailableShipping key={Math.random()} />,
  icon_webpay_debit_unavailable: () => <WebpayDebitUnavailable key={Math.random()} />,
  icon_pse: () => <IconPse key={Math.random()} />,
  icon_pix_unavailable: () => <IconPixUnavailable key={Math.random()} />,
  icon_app_store: () => <IconAppStore key={Math.random()} />,
  icon_play_store: () => <IconPlayStore key={Math.random()} />,
  icon_protected_purchase: () => <IconProtectedPurchase key={Math.random()} />,
  icon_protected_purchase_guest: () => <IconProtectedPurchaseGuest key={Math.random()} />,
  icon_feedback_negative: (props) => <IconFeedbackNegative {...props} key={Math.random()} />,
  icon_copy: () => <IconCopy {...defaultProps()} />,
  icon_feedback_positive: (props) => <IconSuccess {...props} key={Math.random()} />,
  icon_instructions: (props) => <OverflowMenuHorizontal {...props} key={Math.random()} />,
  icon_info: (props) => <IconInfo {...defaultProps()} {...props} />,
  icon_combination: (props) => <IconCombination {...props} key={Math.random()} />,
  icon_anchor_3ds: (props) => <Anchor3DSIcon {...defaultProps()} {...props} />,
};

/**
 *
 * @returns React.Component
 * @param iconData
 */
const generateIcon = (iconData) => icons[iconData.type]?.(iconData) ?? null;

/**
 * Get the MercadoPago icon by siteId
 * @param {string} siteId
 * @param {boolean} exceptionalCondition // This property is used to replicate some Inconsistent behaviors with the MP icon in the MLA site, this could be deprecated/removed in the future
 * @returns {*}
 */
const getMercadoPagoIconBySiteId = (siteId, exceptionalCondition = false) => {
  if (siteId === SITE_ID.MLB || siteId === SITE_ID.MLM || siteId === SITE_ID.MLC || siteId === SITE_ID.MLA || exceptionalCondition) {
    return <MercadoPagoBlack className="icon-mercado-pago-black" />;
  }

  return <IconAccountMoney />;
};

/*
* Get the Payment Method icon by type, issuerName, issuerID, id and siteId
* @param {string} type
* @param {string} issuerName
* @param {string} issuerID
* @param {string} id
* @param {string} siteId
* @returns {*}
*/
const getPaymentMethodIcon = ({ type, issuerName, issuerID, id, siteId, escapeAccentMark = false }) => {
  if (type === PAYMENT_METHOD.ACCOUNT_MONEY) {
    return getMercadoPagoIconBySiteId(siteId);
  }

  if (siteId === SITE_ID.MLB && issuerID === ISSUER_ID.VISA_MERCADO_PAGO) {
    return <PaymentCardIcon
      cardId="mercado-pago"
      issuerName="mercado-pago"
    />;
  }

  return <PaymentCardIcon
    cardId={id || issuerName}
    issuerName={issuerName}
    escapeAccentMark={escapeAccentMark}
  />;
};

module.exports = {
  icons,
  generateIcon,
  getPaymentMethodIcon,
  getMercadoPagoIconBySiteId
};
