import { useEffect } from 'react';

function useClickOnElement(callback: () => void, elementId: string | undefined) {
  useEffect(() => {
    if (!elementId) {
      return;
    }

    const element = document.getElementById(elementId);

    element?.addEventListener('click', callback, true);

    return () => {
      element?.removeEventListener('click', callback, true);
    };
  }, [callback, elementId]);
}

export default useClickOnElement;
