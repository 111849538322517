const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators, compose } = require('redux');
const { injectI18n } = require('nordic/i18n');

const { useEffect, useState } = React;

const { Message: AndesMessage } = require('@andes/message');

const RequestActions = require('../../spa/actions/request');
const translate = require('../../translation');
const { DEVICE_TYPE } = require('../../../constants/commons');
const { CUSTOM_EVENTS, CURRENT_STEP, ZERO_DOLLAR_STATUS } = require('../../../constants/app');

const ZeroDollarMessage = (props) => {
  const { deviceType, i18n } = props;
  const translations = translate(i18n);

  const [zeroDollarError, setZeroDollarError] = useState(false);

  const scrollUpInMobile = (device) => {
    if (device === DEVICE_TYPE.MOBILE) {
      window.scrollTo(0, 0);
    }
  };

  function showZeroDollarMessage({ detail }) {
    if (detail.currentStep === CURRENT_STEP.CARD_FORM) {
      // prevent double re-render after change step.
      setZeroDollarError(detail.zeroDollarValue);
    }
  }

  useEffect(() => {
    scrollUpInMobile(deviceType);
    window.addEventListener(CUSTOM_EVENTS.ZERO_DOLLAR_VALIDATION, showZeroDollarMessage);

    return () => window.removeEventListener(CUSTOM_EVENTS.ZERO_DOLLAR_VALIDATION, showZeroDollarMessage);
  }, []);

  return (
    zeroDollarError && (
      <AndesMessage color="red" closable={false} hierarchy="quiet" className="zero-dollar-message">
        {zeroDollarError === ZERO_DOLLAR_STATUS.HIGH_RISK
          ? translations.PAYMENT_CANNOT_BE_COMPLETED_WITH_THIS_CARD
          : translations.CHECK_THAT_THE_DATA_IS_EQUAL_TO_YOUR_CARD}
      </AndesMessage>
    )
  );
};

ZeroDollarMessage.propTypes = {
  deviceType: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

ZeroDollarMessage.defaultProps = {
  deviceType: '',
  i18n: {
    gettext: (t) => t,
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  requestActions: bindActionCreators(RequestActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */

const mapStateToProps = (state) => ({
  deviceType: state.configurations.deviceType,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ZeroDollarMessage;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = compose(connect(mapStateToProps, mapDispatchToProps))(injectI18n(ZeroDollarMessage));
}
