const { createStore, applyMiddleware } = require('redux');
const thunk = require('redux-thunk').default;

const reducers = require('../reducers');

// Redux middlewares
const middlewares = [thunk];


module.exports = {
  createStore: (initialStore = {}) => (
    createStore(
      reducers,
      initialStore,
      applyMiddleware(...middlewares),
    )
  ),
};
