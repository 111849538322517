const React = require('react');

const { isIframe } = require('../../utils/Dom');
const { checkIfCookiesAreBlocked } = require('../../utils/cookie');

const CheckCookie = () => {
  React.useEffect(() => {
    if (isIframe()) {
      checkIfCookiesAreBlocked();
    }
  }, []);
  return null;
};

module.exports = CheckCookie;
