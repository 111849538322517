const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { injectValidations } = require('../../utils/validator-provider');
const inputValuesActions = require('../../spa/actions/inputValues');
const BaseValidation = require('../BaseValidation');
const InputAction = require('../InputAction');
const translate = require('../../translation');

class InputZipCode extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;

    this.state = {
      value: props.value || props.savedZipcode || '',
      error: props.error,
    };

    this.handleChange = this.handleChange.bind(this);
    this.translations = translate(i18n);
  }



  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.savedZipcode && nextProps.savedZipcode !== this.state.value) {
      this.setState({ value: nextProps.savedZipcode });
      // this will trigger the parent's fetchOnUpdate function which will update the autofillable fields
      this.props.onChange(nextProps.savedZipcode);
    }
    this.setState({
      error: nextProps.error
    });
  }

  updateValue(value) {
    // Update the state
    this.setState({
      value,
    });
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
    this.setState({
      value: event.target.value,
    });
  }

  render() {
    const {
      name,
      label,
      id,
      nextPage,
      disabled,
      kind,
      ...inputProps
    } = this.props;

    if (this.state.invalid) {
      inputProps.modifier = 'error';
    }

    if (this.state.error.length > 0) {
      [inputProps.message] = this.state.error;
      inputProps.modifier = 'error'
      inputProps.messageShow = true;
    } else if (inputProps.message) {
      // Shows message below the input if set
      inputProps.messageFixed = true;
      inputProps.messageShow = true;
    }

    return (
      <InputAction
        {...inputProps}
        kind={kind}
        className="zip-code"
        name={name}
        onChange={this.handleChange}
        label={label}
        childlabel={this.translations.MY_CODE}
        next_page={nextPage}
        value={this.state.value}
        id={id}
        error={this.state.error}
        updateCallback={(value) => { this.updateValue(value); }}
        disabled={disabled}
      />
    );
  }
}

InputZipCode.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.array, // eslint-disable-line
  label: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['link', 'modal_link', 'no_link']),
  onChange: PropTypes.func,
  nextPage: PropTypes.string,
  disabled: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

InputZipCode.defaultProps = {
  id: '',
  name: '',
  label: '',
  kind: 'link',
  onChange: null,
  disabled: false,
  error: [],
  i18n: {
    gettext: t => t,
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, props) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues[`${state.page.flow.step}_${(props.id)}`],
  hidden_components: state.page.data.hidden_components,
  savedZipcode: state.address.zipcode,
});

/**
 * Expose InputZipCode
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = InputZipCode;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(InputZipCode)));
}
