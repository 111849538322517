const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconProtectedPurchase = () => (
  <IconWrapper>
    <svg
      className="with-custom-color"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09851 5.83523C2.09851 14.2844 5.34568 19.6986 11.8084 21.8529L12.093 21.9477L12.3776 21.8529C18.8403 19.6986 22.0875 14.2844 22.0875 5.83523V4.93523H21.1875C18.3823 4.93523 15.5471 3.76039 12.6673 1.37376L12.093 0.897827L11.5187 1.37376C8.63892 3.76039 5.80367 4.93523 2.99851 4.93523H2.09851V5.83523ZM12.093 3.22111C14.7921 5.32182 17.5224 6.489 20.2753 6.70023C20.0737 13.7874 17.3551 18.173 12.093 20.0471C6.83083 18.173 4.11227 13.7874 3.9106 6.70023C6.66351 6.489 9.39389 5.32182 12.093 3.22111ZM8.37481 10.6662L10.736 12.9359L15.522 7.56251L16.8661 8.75971L10.8366 15.5293L7.12742 11.9639L8.37481 10.6662Z"
        fill="currentColor"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(IconProtectedPurchase);
