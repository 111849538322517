/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  CONFIGURATIONS,
  CONFIGURATIONS_UPDATE_CARD_FORM_BRICKS,
} = require('../actions/types');

const actions = {};

const initialState = {
  baseName: '',
  login: {},
  deviceType: '',
  browserName: '',
  publicKey: '',
  testCredentials: false,
  version: '',
  brandName: '',
  platform: '',
  country: {},
  internalBricksData: {},
};

actions[CONFIGURATIONS] = (state, action) => {
  const newState = {
    ...state,
    ...action.payload,
  };
  return newState;
};

actions[CONFIGURATIONS_UPDATE_CARD_FORM_BRICKS] = (state, action) => {
  const newState = {
    ...state,
    internalBricksData: action.payload || state.internalBricksData,
  };
  return newState;
};
/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
