const React = require('react');
const PropTypes = require('prop-types');

const { Switch } = require('@andes/switch');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const inputValuesActions = require('../../spa/actions/inputValues');
const {
  CURRENT_INPUT_VALUES,
} = require('../../spa/actions/types');

const SwitchAlternativePaymentMethod = ({ id, value, inputValueActions, step }) => {
  const [checked, setChecked] = React.useState(value);

  const setValue = React.useCallback((newValue) => {
    inputValueActions[CURRENT_INPUT_VALUES](`${step}_${id}`, newValue);
  }, [inputValueActions, step, id]);

  React.useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    setValue(e.target.checked);
  };

  return <Switch checked={checked} onChange={handleChange} />;
};

SwitchAlternativePaymentMethod.propTypes = {
  inputValueActions: PropTypes.shape({
    [CURRENT_INPUT_VALUES]: PropTypes.func,
  }).isRequired,
  step: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  inputValueActions: bindActionCreators(inputValuesActions, dispatch),
});

const mapStateToProps = (state) => ({
  step: state.page.flow.step,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SwitchAlternativePaymentMethod;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SwitchAlternativePaymentMethod);
}
