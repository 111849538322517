const {
  APP: { INTERNAL_CONFIGURATIONS },
} = require('../../constants');

/**
 * Determines if internal configurations are enabled based on the provided configurations.
 * @param {Array<String>} internalConfigurations - The configurations to check.
 * @returns {Object} - An object containing the enabled internal configurations.
 */
const internalConfigurationsEnabled = (internalConfigurations = []) => {
  const configurations = internalConfigurations || [];

  const botonAmarilloEnabled = configurations.includes(INTERNAL_CONFIGURATIONS.YELLOW_BUTTON);
  const inMlEnabled = configurations.includes(INTERNAL_CONFIGURATIONS.IN_ML);
  const inPotsEnabled = configurations.includes(INTERNAL_CONFIGURATIONS.IN_POTS);
  const enableMlTheme = botonAmarilloEnabled || inMlEnabled || inPotsEnabled;

  return {
    botonAmarilloEnabled,
    inMlEnabled,
    inPotsEnabled,
    enableMlTheme,
  };
};

module.exports = internalConfigurationsEnabled;
