const React = require('react');
const PropTypes = require('prop-types');

const InfoRow = ({ title, detail }) =>
{
  let accessibilityHint = title ? title + " " : "";
  accessibilityHint += detail || "";

  return(
  <div className="info-row">
    { title
        && <span aria-hidden="true" className="info-row__title">{title}</span>}
    { detail
        && <span aria-hidden="true" className="info-row__detail">{detail}</span>}
    {accessibilityHint && accessibilityHint!=""
        && <span aria-label={accessibilityHint} className="u-accessibility-hidden" />}
  </div>
)};

InfoRow.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
};

InfoRow.defaultProps = {
  title: '',
  detail: '',
};

module.exports = InfoRow;
