/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const { BrowserRouter, withRouter } = require('react-router-dom');
const AppContainer = require('./containers/App');

/**
 * SPA Container
 */
const SPAContainer = (props) => {
  const ContainerWithRouter = withRouter(props.Container || AppContainer);

  return (
    <BrowserRouter basename={props.basePath}>
      <ContainerWithRouter {...props} />
    </BrowserRouter>
  );
};

SPAContainer.propTypes = {
  basePath: PropTypes.string,
  Container: PropTypes.func,
};

SPAContainer.defaultProps = {
  basePath: '',
  Container: null,
};

/**
 * Expose SPA Container
 */
module.exports = SPAContainer;
