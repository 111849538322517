
// Constants
const {
  COLORS_BY_STATUS
} = require('../../../../../../constants/commons');



// Utils for Mobile Subscription
const buildSubscriptionFreeTrialMessage = (subscription) => {
  if (subscription?.free_trial && Object.keys(subscription?.free_trial)?.length) {
    return {
      status: COLORS_BY_STATUS[(subscription.free_trial.status).toUpperCase()],
      title: subscription.free_trial.title,
      label: subscription.free_trial.label,
    }
  }
  return null;
};

// Utils for Desktop Subscription
const getMessageColor = (subscription) => COLORS_BY_STATUS[(subscription?.free_trial?.status ?? '').toUpperCase()]

module.exports = {
  buildSubscriptionFreeTrialMessage,
  getMessageColor
}
