const React = require('react');
const PropTypes = require('prop-types');

const Text = require('../Text');
const Check = require('../icons/Check');
const { replaceChar } = require('../../utils/Strings');

const IconsList = ({ items, id }) => (
    <div id={replaceChar(id, /_/g, '-')} className="icons-list">
      {items.map((item, index) => <div key={Math.random()} id={`item-${index + 1}`} className="item">
        <Check />
        <Text text={item.text} />
      </div>)}
    </div>
  );

IconsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

IconsList.defaultProps = {
  items: [],
  id: '',
};

module.exports = IconsList;
