import React from 'react';

const AccountMoney = ({ className, id, ...props }: SVGProps) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" id={id}>
    <path
      d="M9.20699 14.13L8.29699 15.39C8.99052 16.1305 9.98013 16.6737 11.299 16.8289V18H12.799V16.8358C14.8169 16.608 15.703 15.3899 15.703 13.99C15.703 11.96 13.869 11.484 12.329 11.092L12.3154 11.0886C11.2152 10.81 10.271 10.571 10.271 9.846C10.271 9.146 10.887 8.684 11.853 8.684C12.833 8.684 13.827 9.02 14.555 9.748L15.493 8.53C14.7913 7.85097 13.8884 7.41895 12.799 7.27831V6H11.299V7.27636C9.64733 7.51379 8.59099 8.59977 8.59099 9.958C8.59099 11.932 10.383 12.38 11.909 12.758L11.9318 12.7639C13.0563 13.0554 14.037 13.3096 14.037 14.144C14.037 14.76 13.505 15.418 12.161 15.418C10.859 15.418 9.85099 14.816 9.20699 14.13Z"
      fill="currentColor"
      fillOpacity="0.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.049 23.352C18.2904 23.352 23.35 18.2924 23.35 12.051C23.35 5.80963 18.2904 0.75 12.049 0.75C5.80762 0.75 0.747986 5.80963 0.747986 12.051C0.747986 18.2924 5.80762 23.352 12.049 23.352ZM12.049 21.852C6.63604 21.852 2.24799 17.4639 2.24799 12.051C2.24799 6.63806 6.63604 2.25 12.049 2.25C17.4619 2.25 21.85 6.63806 21.85 12.051C21.85 17.4639 17.4619 21.852 12.049 21.852Z"
      fill="currentColor"
      fillOpacity="0.9"
    />
  </svg>
);

export default React.memo(AccountMoney);
