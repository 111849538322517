const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const SummaryList = require('../../components/SummaryList');
const translate = require('../../translation');
const {
  APP: {
    ICON: { INFO },
    HIDDEN_COMPONENTS: { CANDIDATE_JUROS_COMBINED_PAYMENT_REVIEW },
  },
  COMMONS: {
    ANDES_PLACEMENT: { TOP, BOTTOM },
    TOOLTIP_TYPE: { DARK },
    DEVICE_TYPE,
  },
} = require('../../../constants');

const SummaryListWithCombination = ({ value, deviceType, i18n, hidden_components }) => {
  const translations = translate(i18n);
  const showJurosMessage = hidden_components.find((component) => component.id === CANDIDATE_JUROS_COMBINED_PAYMENT_REVIEW);
  const jurosMessage = showJurosMessage?.value ? translations.IT_IS_A_DIFFERENTIATION_IN_PRICE_ACCORDING_TO_THE_NUMBER_OF_INSTALLMENTS_YOU_WILL_PAY_BY_CARD :translations.THERE_IS_A_PRICE_DIFFERENCE_DEPENDING_ON_THE_NUMBER_OF_INSTALLMENTS_YOU_CHOOSE;
  return (
    <SummaryList
      value={value}
      deviceType={deviceType}
      tooltipText={jurosMessage}
      tooltipSide={deviceType === DEVICE_TYPE.MOBILE ? BOTTOM : TOP}
      tooltipIcon={INFO}
      tooltipType={DARK}
      displayAccordion={deviceType !== DEVICE_TYPE.MOBILE}
    />
  );
};

SummaryListWithCombination.propTypes = {
  value: PropTypes.array,
  deviceType: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  hidden_components: PropTypes.array,
};

SummaryListWithCombination.defaultProps = {
  value: [],
  deviceType: '',
  i18n: {
    gettext: () => {},
  },
  hidden_components: []
}

const mapStateToProps = (state) => ({
  hidden_components: state.page.data.hidden_components,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SummaryListWithCombination;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, null)(injectI18n(SummaryListWithCombination));
}
