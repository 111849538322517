const { buildRoutes } = require('frontend-spa');
/* eslint consistent-return: 1 */

/**
 * Fetch Actions
 */
const {
  GET_FLOW,
  REDIRECT_TO_CURRENT_FLOW,
} = require('../actions/types');
const { routesMap } = require('../../server/stepsMap');

const baseRoutes = [
  {
    path: '/:type(modal|redirect|embed)/:flowId/exception',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/exception'), 'chunk.exception'),
    webpack: () => require.resolveWeak('../../pages/exception'),
  },
  /**
   * Exceptions coming from the application
   */
  {
    path: '/:type(modal|redirect|embed)/exception',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/exception'), 'chunk.exception'),
    webpack: () => require.resolveWeak('../../pages/exception'),
  },
  {
    path: '/:type(modal|redirect|embed)/expired',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/expired'), 'chunk.expired'),
    webpack: () => require.resolveWeak('../../pages/expired'),
  },
  /**
   * Auth callback
   */
  {
    path: '/(auth/callback|user/login-popup)',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/auth-callback'), 'chunk.auth-callback'),
    webpack: () => require.resolveWeak('../../pages/auth-callback'),
  },
  /**
   * Browser Support error
   */
  {
    path: '/:type(modal|redirect|embed)/browser-support',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/browser-support'), 'chunk.browser-support'),
    webpack: () => require.resolveWeak('../../pages/browser-support'),
  },
  /**
   * Crawler page
   */
  {
    path: '/:type(modal|redirect|embed)/crawlers',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/crawlers'), 'chunk.crawler'),
    webpack: () => require.resolveWeak('../../pages/crawlers'),
  },
  /**
   * Payment Unavailable error
   */
  {
    path: '/:type(modal|redirect|embed)/payment-unavailable',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/payment-unavailable'), 'chunk.payment-unavailable'),
    webpack: () => require.resolveWeak('../../pages/payment-unavailable'),
  },
  {
    path: '/:type(modal|redirect|embed)/access-denied',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/access-denied'), 'chunk.access-denied'),
    webpack: () => require.resolveWeak('../../pages/access-denied'),
  },
  /**
   * Blocked Pop-ups error
   */
  {
    path: '/:type(modal|redirect|embed)/blocked-popups',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/blocked-popups'), 'chunk.blocked-popups'),
    webpack: () => require.resolveWeak('../../pages/blocked-popups'),
  },
  /**
   * Login Workaround page
   */
  {
    path: '/:type(modal|redirect|embed)/restart-flow',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/restart-flow'), 'chunk.restart-flow'),
    webpack: () => require.resolveWeak('../../pages/restart-flow'),
  },
  /**
   * open finance callback
   */
  {
    path: '/:type(modal|redirect|embed)/open-finance/:flowId*',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/openfinance'), 'chunk.openfinance-callback'),
    webpack: () => require.resolveWeak('../../pages/openfinance'),
  },
  /**
   * challenge 3ds remedy callback
   */
  {
    path: '/:type(modal|redirect|embed)/challenge-3ds-remedy/:flowId*', // colides with challenge-3ds-remedy step
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/challenge-3ds-callback'), 'chunk.challenge-3ds-callback'),
    webpack: () => require.resolveWeak('../../pages/challenge-3ds-callback'),
  },
  /**
   * Login challenge remedy callback
   */
  {
    path: '/:type(modal|redirect|embed)/login-challenge-remedy/:flowId*', // colides with login-challenge-remedy step
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/login-challenge-callback'), 'chunk.login-challenge-callback'),
    webpack: () => require.resolveWeak('../../pages/login-challenge-callback'),
  },
  /**
   * challenge Random charge callback with orquestador
   */
  {
    path: '/:type(modal|redirect|embed)/random-charge-challenge-remedy/:flowId*',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/challenge-random-charge-callback'), 'chunk.challenge-random-charge-callback'),
    webpack: () => require.resolveWeak('../../pages/challenge-random-charge-callback'),
  },
   /**
   * Challenge facetec remedy callback
   */
  {
    path: '/:type(modal|redirect|embed)/facetec-challenge-remedy/:flowId*', // colides with challenge-facetec-remedy step
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/challenge-facetec-callback'), 'chunk.challenge-facetec-callback'),
    webpack: () => require.resolveWeak('../../pages/challenge-facetec-callback'),
  },
  /**
   * Challenge unico remedy callback
   */
  {
    path: '/:type(modal|redirect|embed)/unico-challenge-remedy/:flowId*', // colides with unico-challenge-remedy step
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/unico-challenge-callback'), 'chunk.unico-challenge-callback'),
    webpack: () => require.resolveWeak('../../pages/unico-challenge-callback'),
  },

  /**
   * Webpay one click
   */
  {
    path: '/:type(modal|redirect|embed)/webpay-one-click/:flowId',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/webpay-one-click'), 'chunk.webpay-one-click'),
    webpack: () => require.resolveWeak('../../pages/webpay-one-click'),
  },
  /**
   * Remedy Random Charge with remedy selector
   */
  {
    path: '/:type(modal|redirect|embed)/random-charge/:flowId/:status',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/remedy-random-charge'), 'chunk.remedy-random-charge'),
    webpack: () => require.resolveWeak('../../pages/remedy-random-charge'),
  },
  {
    path: '/:type(modal|redirect|embed)/opensea-credits/:flowId/:status?',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/opensea-credits'), 'chunk.opensea-credits'),
    webpack: () => require.resolveWeak('../../pages/opensea-credits'),
  },
  /**
   * OpenFinance redirect
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId/openfinance-redirect',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/openfinance-redirect'), 'chunk.open-finance'),
    webpack: () => require.resolveWeak('../../pages/openfinance-redirect'),
  },
  /**
   * Challenge 3DS remedy redirect
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId/challenge-3ds-remedy',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/challenge-3ds-remedy'), 'chunk.challenge-3ds-remedy'),
    webpack: () => require.resolveWeak('../../pages/challenge-3ds-remedy'),
  },
  /**
   * Challenge Login remedy redirect
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId/login-challenge-remedy',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/login-challenge-remedy'), 'chunk.login-challenge-remedy'),
    webpack: () => require.resolveWeak('../../pages/login-challenge-remedy'),
  },
  /**
   * Challenge Unico remedy redirect
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId/unico-challenge-remedy',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/unico-challenge-remedy'), 'chunk.unico-challenge-remedy'),
    webpack: () => require.resolveWeak('../../pages/unico-challenge-remedy'),
  },
  /**
   * Challenge Random Charge redirect with orquestador
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId/random-charge-challenge-remedy',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/challenge-random-charge-remedy'), 'chunk.challenge-random-charge-remedy'),
    webpack: () => require.resolveWeak('../../pages/challenge-random-charge-remedy'),
  },
  /**
   * Challenge Facetec remedy redirect
   */
   {
      path: '/:type(modal|redirect|embed)/:flowId/facetec-challenge-remedy',
      exact: true,
      action: null,
      resolve: () => require.ensure([], require => require('../../pages/challenge-facetec-remedy'), 'chunk.challenge-facetec-remedy'),
      webpack: () => require.resolveWeak('../../pages/challenge-facetec-remedy'),
    },
  /**
   * Redirect to current flow
   */
  {
    path: '/:type(modal|redirect|embed)/:flowId([0-9|a-z|A-Z]+-[0-9|a-z|A-Z|-]+)',
    exact: true,
    action: REDIRECT_TO_CURRENT_FLOW,
    resolve: () => require.ensure([], require => require('../../pages/redirect'), 'chunk.redirect'),
    webpack: () => require.resolveWeak('../../pages/redirect'),
  },
  /**
   * Skeleton
   */
  {
    path: '/:type(modal|redirect|embed)',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/skeleton'), 'chunk.skeleton'),
    webpack: () => require.resolveWeak('../../pages/skeleton'),
  },
  /**
   * Skeleton
   */
  {
    path: '/compatible-px',
    exact: true,
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/compatible-px'), 'chunk.compatible-px'),
    webpack: () => require.resolveWeak('../../pages/compatible-px'),
  },
  /**
   * 404 - Not Found Route
   */
  {
    path: '*',
    action: null,
    resolve: () => require.ensure([], require => require('../../pages/exception'), 'chunk.exception'),
    webpack: () => require.resolveWeak('../../pages/exception'),
  },
];

/**
 * specific routes for each step
 * this block will add all the routes that represent a step from stepsMap
 * no step route should be added in this file manually
 */
const stepRoutes = Object.keys(routesMap).map((it) => {
  const route = routesMap[it];
  if (route.chunk) {
    return {
      path: `/:type(modal|redirect|embed)/:flowId/:step(${route.steps.join('|')})`,
      exact: true,
      action: route.disableAction ? null : GET_FLOW,
      resolve: route.resolve, // require async chunk with require.ensure
      webpack: route.webpack, // require.resolveweak + page
    };
  }
});

const routes = [...stepRoutes, ...baseRoutes];

/**
* Export routes
*/
module.exports = buildRoutes(routes);
