const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const Head = require('nordic/head');

const { updateMelidataPath, trackMelidata } = require('../../../utils/Tracker');

/*
Flag to check if the initial route is already tracked
*/
let tracked = true;

/**
 * Tracks for analytics and melidata on nested pages.
 * @param {*} tracking
 * @param {*} stateConfigs
 */
const track = (tracking, stateConfigs) => {
  if (tracking && tracking.value) {

    const {melidata} = tracking.value;
    if (melidata && melidata.path) {
      if (melidata.data && Object.keys(melidata.data).length > 0) {
        trackMelidata({
          path: melidata.path,
          payload: melidata.data,
          experiments: melidata.experiments,
        }, stateConfigs);
      } else {
        updateMelidataPath(melidata.path);
      }
    }
  }
};

/**
 * Function to Track each sub page on route change inside the router render function
 * @param {*} props
 * @param {*} renderProps
 */
const trackSubPages = (props, renderProps) => {
  if (renderProps.history && renderProps.history.action === 'PUSH') {
    track(props.tracking, props.stateConfigs);
    tracked = false;
  } else if (!tracked && renderProps.history.action === 'POP') {
    // back to parent page
    track(props.tracking, props.stateConfigs);
  }
};

const SubPage = (props) => {
  const { pageProps, stateConfigs } = props;
  trackSubPages({ ...pageProps, stateConfigs }, props);

  return (
    <>
      <Head>
        {pageProps.stepTitle && <title>{pageProps.stepTitle}</title>}
      </Head>
      {pageProps.children}
    </>
  );
};

SubPage.propTypes = {
  pageProps: PropTypes.object, // eslint-disable-line
  stateConfigs: PropTypes.object, // eslint-disable-line
};

SubPage.defaultProps = {
  pageProps: {},
  stateConfigs: {},
};

const mapStateToProps = state => ({
  stateConfigs: state.configurations,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SubPage;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(SubPage);
}
