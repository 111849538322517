import React from 'react';

const Exit = ({ width = 16, height = 16 }: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.4341 3.07341L10.2826 2.22489L15.9312 7.87341L10.2826 13.5219L9.4341 12.6734L13.634 8.47354L3.67294 8.47873L3.67231 7.27873L13.6342 7.27354L9.4341 3.07341Z"
      fill="black"
      fill-opacity="0.9"
    />
    <path
      d="M1.86773 0.0729974L4.87273 0.0735181L4.87252 1.27352L1.86762 1.273C1.53625 1.273 1.26762 1.54163 1.26762 1.87316L1.27262 13.8732C1.27274 14.2045 1.54133 14.473 1.87273 14.473L4.87273 14.4735L4.87252 15.6735L1.87262 15.673C0.878755 15.673 0.0729688 14.8675 0.0726236 13.8737L0.067624 1.873C0.0676241 0.878885 0.873511 0.0729974 1.86773 0.0729974Z"
      fill="black"
      fill-opacity="0.9"
    />
  </svg>
);

export default Exit;
