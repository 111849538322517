const React = require('react');

const GreyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#000"
      fillOpacity="0.25"
      fillRule="evenodd"
      d="M16.2 3.997H1.8a1.8 1.8 0 00-1.8 1.8v8.4a1.8 1.8 0 001.8 1.8h14.4a1.8 1.8 0 001.8-1.8v-8.4a1.8 1.8 0 00-1.8-1.8zm-15 10.2v-5.39h15.6v5.39a.6.6 0 01-.6.6H1.8a.6.6 0 01-.6-.6zm0-6.59h15.6v-1.81a.6.6 0 00-.6-.6H1.8a.6.6 0 00-.6.6v1.81z"
      clipRule="evenodd"
    />
    <rect width="14" height="9" x="6" y="3" fill="#737373" rx="2" />
    <path
      fill="#fff"
      d="M8.872 9.11l.05-1.15-.96.63-.22-.4 1.02-.52-1.02-.52.22-.39.96.62-.05-1.15h.46l-.06 1.15.96-.62.23.39-1.02.52 1.02.52-.23.4-.96-.63.06 1.15h-.46zm3.899 0l.05-1.15-.96.63-.22-.4 1.02-.52-1.02-.52.22-.39.96.62-.05-1.15h.46l-.06 1.15.96-.62.23.39-1.02.52 1.02.52-.23.4-.96-.63.06 1.15h-.46zm3.898 0l.05-1.15-.96.63-.22-.4 1.02-.52-1.02-.52.22-.39.96.62-.05-1.15h.46l-.06 1.15.96-.62.23.39-1.02.52 1.02.52-.23.4-.96-.63.06 1.15h-.46z"
    />
  </svg>
);

module.exports = GreyIcon;
