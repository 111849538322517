const React = require('react');

const IconWrapper = require('../IconWrapper');

const Caixa = (props) => (
  <IconWrapper className="icon-caixa">
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26953 8.89311H13.0151L17.6372 16.5479H10.8905L6.26953 8.89311Z"
        fill="#0070AF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.97266 7.71582H13.945L20.0004 0.0931401H13.0281L6.97266 7.71582Z"
        fill="#F6822A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16.5918H6.97122L13.0267 8.87621H6.05434L0 16.5918Z"
        fill="#F6822A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.51953 0.000238345H9.02623L13.4846 7.71582H6.97677L2.51953 0.000238345Z"
        fill="#0070AF"
      />
    </svg>
  </IconWrapper>
);

module.exports = Caixa;
