// Installments Detail components
const AmountDetail = require('@cow/core-components/components/CardListV2/InstallmentRowDetail/AmountDetail');
const QuantityDetail = require('@cow/core-components/components/CardListV2/InstallmentRowDetail/QuantityDetail');

// Utils
const { getDecimalSeparator } = require('../DecimalSeparator');
// Constants
const { SITE_ID } = require('../../../../../../constants/commons');

const getComponentId = (quantity) => `${quantity}x-row`;

const getQuantityText = (quantity, site) => {
  if (SITE_ID.MLM === site) {
    return `${quantity} de`;
  }
  return `${quantity}x`;
};

const getPluralOrSingularWording = (quantity, translations) => {
  if (quantity === 1) {
    return translations.INSTALLMENT_OF;
  }
  return translations.INSTALLMENTS_OF(quantity);
};

const mapQuantityDetail = (quantity, price, translations, site, deviceType) => {
  const { cents, amount, currency_symbol, decimal_separator } = price;

  return (
    <QuantityDetail
      srLabel={getPluralOrSingularWording(quantity, translations)}
      quantity={getQuantityText(quantity, site)}
      deviceType={deviceType}
      amountInfo={{
        cents,
        fraction: amount,
        centsType: getDecimalSeparator(decimal_separator),
        symbol: currency_symbol,
      }}
    />
  );
};

const getAmountSrLabel = (isThirdParty, translations) =>
  isThirdParty ? translations.THIRD_PARTY_AMOUNT_LABEL : translations.INSTALLMENT_TOTAL;

const mapAmountDetail = (detail, types, translations, deviceType) => {
  const { text_value, amount, cents, currency_symbol, decimal_separator } = detail;
  const { is_promotion, is_third_party } = types;

  if (text_value) {
    return <AmountDetail content={text_value} isPromotion={is_promotion} deviceType={deviceType} />;
  }

  if (!amount || amount === '0') {
    return <AmountDetail deviceType={deviceType} />;
  }

  return (
    <AmountDetail
      deviceType={deviceType}
      ariaHiddenMoneyAmount={is_third_party}
      totalAmount={{
        cents,
        fraction: amount,
        centsType: getDecimalSeparator(decimal_separator),
        symbol: currency_symbol,
        srLabel: getAmountSrLabel(is_third_party, translations),
      }}
      totalAmountSuffix={is_third_party ? '*' : ''}
    />
  );
};

const mapInstallment = (installment, selectInstallmentAction, translations, site, deviceType) => {
  const { quantity, price, rate, detail, types } = installment;

  return {
    id: getComponentId(quantity),
    withChevron: true,
    title: mapQuantityDetail(quantity, price, translations, site, deviceType),
    rightContent: mapAmountDetail(detail, types, translations, deviceType),
    onClick: () => selectInstallmentAction(quantity, rate),
    className: 'installment-row',
  };
};

const generateItems = (installments, selectInstallmentAction, translations, site, device) => {
  if (!Array.isArray(installments) || installments.length === 0) {
    return [];
  }

  return installments.map((installment) =>
    mapInstallment(installment, selectInstallmentAction, translations, site, device),
  );
};

const hasThirdPartyInstallments = (installments) => {
  if (!Array.isArray(installments) || installments.length === 0) {
    return false;
  }

  return installments.some((installment) => installment.types.is_third_party);
};

export { generateItems, hasThirdPartyInstallments };
