/* istanbul ignore file */
const React = require('react');
const PropTypes = require('prop-types');

const Autocomplete = require('@andes/autocomplete');
const Textfield = require('@andes/textfield');

const BaseValidation = require('../BaseValidation');

class InputAutocomplete extends BaseValidation {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: null,
      value: '',
      error: this.props.error || '',
      invalid: this.props.invalid || false,
    };
  }

  /**
   * Autocomplete 'onSelect' event callback
   * This functionality updates current state & call 'onSelectSave'
   */
  onAutocompleteSelect(e, option) {
    this.setState({ value: option.name, invalid: false });
    if (this.onSelectSave) {
      this.onSelectSave(e, option); // call implementations
    }
  }

  /**
   * On unexpected manual value change, we reset the current selected value
   * i.e.: Someone selected 'Quilmes' & after adds or remove any letter (Quilme) which
   * it's not equals which previous selected option
   */
  onChangeSave(e) {
    if (this.state.value && this.state.value !== '' && this.onSelectSave) {
      this.onSelectSave(e, { id: '', name: '' }); // call implementations
    }
    this.setState({ value: '', invalid: false });
  }

  /**
   * When the user stops editting we update errors & possible values
   */
  onBlurSave(e) {
    if (!this.state.value || this.state.value === '') {
      const newOption = this.matchValue(e.target.value, this.state.suggestions);
      if (newOption) {
        this.onAutocompleteSelect(undefined, newOption);
      } else {
        this.setState({ invalid: true });
      }
    }
  }

  /**
   * in case there is the need to compare the input value vs the suggestions
   * we need to normalize the suggestion selected to be able to match it
   * @param {*} value
   * @return normalized string eg: ÀÈò -> aeo
   */
  normalizeInputData(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  /**
   * Searches among given options the same value name
   * @param {String} value - Value to look among options names
   * @param {Array<{id, name}>} options - Options array with {id, name} objects
   */
  matchValue(value, options) {
    const normValue = this.normalizeInputData(value);
    for (let index = 0; index < options.length; index += 1) {
      const option = options[index];
      const normOption = this.normalizeInputData(option.name);
      if (normValue === normOption) {
        return option;
      }
    }
    return null;
  }

  render() {
    const { invalid, error } = this.state;
    const inputProps = {};
    if (invalid) {
      inputProps.modifier = 'error';

      if (error.length > 0) {
        const [errorMessage] = error;
        inputProps.helper = errorMessage;
      }
    }

    if (!this.props.show) {
      return null;
    }

    return (
      <>
        {this.state.suggestions
          && (
            <Autocomplete
              key={`${this.state.suggestions[0].id}_${this.state.suggestions.length}`} // needed to reset component if the model has changed
              suggestions={this.state.suggestions}
              onSelect={(e, opt) => this.onAutocompleteSelect(e, opt)}
            >
              <Textfield
                {...inputProps}
                name={this.props.name}
                label={this.props.label}
                placeholder={this.props.placeHolder}
                onChange={this.onChangeSave ? e => (this.onChangeSave(e)) : null}
                onBlur={this.onBlurSave ? e => (this.onBlurSave(e)) : null}
              />
            </Autocomplete>
          )}
      </>
    );
  }
}

InputAutocomplete.propTypes = {
  error: PropTypes.string,
  invalid: PropTypes.bool,
  name: PropTypes.string,
  show: PropTypes.bool,
  label: PropTypes.string,
  palceholder: PropTypes.string,
};

InputAutocomplete.defaultProps = {
  error: '',
  invalid: false,
  name: '',
  show: true,
  label: '',
  palceholder: '',
};

module.exports = InputAutocomplete;
