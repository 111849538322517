const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const Select = require('../Select');
const inputValuesActions = require('../../spa/actions/inputValues');
const BaseValidation = require('../BaseValidation');
const { CLEAN_INPUT_VALUES, CURRENT_INPUT_VALUES } = require('../../spa/actions/types');

const InputTypes = {
  BASE_INPUT: 'BASE_INPUT',
  SELECT: 'SELECT',
  PLACEHOLDER: 'PLACEHOLDER',
};
class InputAutofillable extends BaseValidation {
  constructor(props) {
    super(props);

    this.state = {
      value: props.savedValue || props.value,
      options: props.options || [],
      error: props.error,
    };

    this.updateValue = this.updateValue.bind(this);
    this.baseInputChange = this.baseInputChange.bind(this);
    this.setSelectDefaultValue = this.setSelectDefaultValue.bind(this);
    this.cleanValues = this.cleanValues.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options.join('') !== this.props.options.join('')) {
      const { options } = nextProps;
      const defaultOption = (options && options[0]) ?? '';
      this.updateValue(defaultOption);
    }
  }

  componentWillUnmount() {
    this.cleanValues();
  }

  cleanValues() {
    this.props.inputValuesActions[CLEAN_INPUT_VALUES]();
  }

  updateValue(value) {
    this.setState({ value });
  }

  baseInputChange(event) {
    this.updateValue(event.target.value);
  }

  getInputType(options) {
    if (options && options.length === 0 && this.props.disabled) {
      return InputTypes.PLACEHOLDER;
    }

    return options && options.length > 1 ? InputTypes.SELECT : InputTypes.BASE_INPUT;
  }

  setSelectDefaultValue(value) {
    const { step, id } = this.props;
    this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${step}_${id}`, value);
  }

  render() {
    const {
      name,
      label,
      id,
      step,
      deviceType,
      options,
      ...inputProps
    } = this.props;

    const inputType = this.getInputType(options);

    inputProps.value = this.state.value ?? '';

    if (inputType === InputTypes.SELECT) {
      inputProps.disabled = false;
      inputProps.options = options.map((option) => ({
        label: option,
        value: option,
        selected: option === this.state.value,
      }));
      if (!inputProps.options.some(option => option.value === this.props.savedValue)) {
        const selectedOption = inputProps.options.find(option => option.selected);
        this.setSelectDefaultValue(selectedOption.value);
      }
    } else if (inputType === InputTypes.BASE_INPUT) {
      inputProps.onChange = this.baseInputChange;
    }

    return (
      <>
        {inputType === InputTypes.SELECT && (
          // require user select option
          <Select
            name={name}
            label={label}
            id={id}
            step={step}
            error={this.state.error}
            deviceType={deviceType}
            shouldSaveValue={false}
            {...inputProps}
          />
        )}

        {inputType === InputTypes.BASE_INPUT && (
          // disabled input with only one option selected
          <>
            <BaseInput
              className="input-autofillable"
              name={name.concat(inputProps.disabled ? '_disabled' : '')}
              label={label}
              id={id.concat(inputProps.disabled ? '_disabled' : '')}
              error={this.state.error}
              invalid={this.state.invalid}
              autoCapitalize="no"
              spellCheck="no"
              {...inputProps}
            />

            {inputProps.disabled && (
              <input
                type="hidden"
                name={name}
                id={id}
                value={inputProps.value}
              />
            )}
          </>
        )}

        {inputType === InputTypes.PLACEHOLDER && (
          // disabled input and no options to select
          <Select
            name={name}
            label={label}
            id={id}
            step={step}
            deviceType={deviceType}
            shouldSaveValue={false}
            disabled
          />
        )}
      </>
    );
  }
}

InputAutofillable.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

InputAutofillable.defaultProps = {
  id: '',
  name: '',
  label: '',
  onChange: null,
  value: '',
  options: [],
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, props) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${(props.id)}`],
  options: state.inputValues.current[`${state.page.flow.step}_${(props.id)}_options`],
});

/**
 * Expose InputZipCode
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = InputAutofillable;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(InputAutofillable)));
}
