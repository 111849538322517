const React = require('react');
const PropTypes = require('prop-types');

const IconCheckBadge = ({ title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon-check-badge" width="32" height="32" viewBox="0 0 32 32">
    {title
      && <title>{title}</title>}
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="15" cy="15.346" r="15" fill="#0D895C" />
      <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.92" d="M9 15.346l3.6 4 8.4-8" />
    </g>
  </svg>
);

IconCheckBadge.defaultProps = {
  title: '',
};

IconCheckBadge.propTypes = {
  title: PropTypes.string,
};

module.exports = IconCheckBadge;
