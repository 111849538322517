const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const InputHidden = ({ id, name, value }) => <input id={id} name={name} value={value} hidden />;

InputHidden.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  value: state.inputValues.current[`${state.page.flow.step}_${props.id}`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputHidden;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(InputHidden);
}
