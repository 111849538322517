const React = require('react');

const IconWrapper = require('../IconWrapper');

const User = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.004a9 9 0 110 18 9 9 0 010-18zM15.602 16.2H8.383a1.39
        1.39 0 00-1.39 1.39v.393A7.762 7.762 0 0012 19.803a7.766 7.766
        0 005.007-1.82v-.38c0-.775-.63-1.403-1.405-1.403zM12 4.204a7.8
        7.8 0 00-6.103 12.658A2.593 2.593 0 018.383 15h7.22c1.18 0
        2.178.786 2.497 1.864A7.8 7.8 0 0012 4.203zm.006 1.192a4.202
        4.202 0 110 8.405 4.202 4.202 0 010-8.405zm0 1.2a3.003 3.003 0
        100 6.006 3.003 3.003 0 000-6.006z"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(User);
