import React from 'react';

const CloseIcon = ({
  className,
  id,
  width = 20,
  height = 20,
  ...props
}: SVGProps) => (
  <svg
    {...props}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="m5.2 4.346 4.801 4.802 4.795-4.793.849.849-4.795 4.793 4.795 4.794-.849.849-4.794-4.795L5.2 15.65l-.849-.849 4.802-4.803-4.802-4.802.849-.849z"
      fill-opacity=".8"
    />
  </svg>
);

export default React.memo(CloseIcon);
