const React = require('react');
const PropTypes = require('prop-types');

const DisabledText = ({ text }) => (
  <div className="disabled-text">
    <span>{text}</span>
    <span className="final-gradient" />
  </div>
);

DisabledText.defaultProps = {
  text: '',
};

DisabledText.propTypes = {
  text: PropTypes.string,
};

module.exports = DisabledText;
