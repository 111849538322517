import type { TypographyProps } from '@andes/typography';
import type {
  PaymentSummaryItem,
  PaymentSummarySplitPayment,
  PaymentSummaryTotal,
} from '../../@types/summary';

import React from 'react';
// Andes Components
import { Card, CardContent } from '@andes/card';
import { Typography } from '@andes/typography';
import { ProgressButton } from '@andes/button';

// Icons
import Lock from '../Icons/Lock';
// Custom Components
import RowItem from '../RowItem';
// Constants
import { PaymentSummaryTheme, Theme } from '../../constants/commons';
import TotalSection from './TotalSection';

export type PaymentSummaryProps = {
  title: string;
  // Items Row
  items: Array<PaymentSummaryItem>;
  // Split Payment Box
  splitPayment?: PaymentSummarySplitPayment;
  // Total Row
  total?: PaymentSummaryTotal & {
    nameObservation?: string;
    nameObservationColor?: TypographyProps['color'];
    nameObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    priceObservation?: string;
    priceObservationColor?: TypographyProps['color'];
    priceObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    helperText?: string;
    textSize?: Exclude<TypographyProps['size'], 'xl'>;
  };
  // Security Payment Information
  securityPaymentInformationLabel?: string;
  // Dynamic External Content
  bottomContent?: React.ReactNode;
  // Themes
  theme?: PaymentSummaryTheme;
  companyTheme?: Theme;
  // Submit Button
  showSubmitButton?: boolean;
  buttonText?: string;
  disablePayButton?: boolean;
  payButtonHelperText?: string | React.ReactNode;
  onPayClick?: () => void;
  loadingButton?: boolean;
  timeout?: number;
  isFullAndes?: boolean;
};

function PaymentSummary({
  title,
  // Items Row
  items,
  // Split Payment Box
  splitPayment,
  // Total Row
  total,
  // Security Payment Information
  securityPaymentInformationLabel,
  // Dynamic External Content
  bottomContent,
  // Themes
  theme = PaymentSummaryTheme.LIGHT,
  companyTheme = Theme.DEFAULT,
  // Submit Button
  showSubmitButton = true,
  buttonText,
  disablePayButton = false,
  onPayClick,
  payButtonHelperText,
  loadingButton = false,
  timeout = 5000,
  isFullAndes = false,
}: PaymentSummaryProps) {
  return (
    <Card
      className={`cow-payment_summary ${
        isFullAndes ? '' : 'cow-payment_summary--desktop'
      } cow-payment_summary--${theme} cow-payment_summary--${companyTheme}`}
      paddingSize={32}
    >
      <CardContent>
        <Typography
          className="cow-payment_summary__title"
          component={isFullAndes ? 'span' : 'h2'}
          type="body"
          size="m"
          weight="semibold"
        >
          {title}
        </Typography>

        {items.map(
          ({
            // Name
            name,
            nameColor = 'primary',
            nameWeight,
            nameComplement,
            nameComplementColor,
            nameObservation,
            icon,
            // Price
            price,
            priceColor = 'primary',
            priceWeight,
            priceSize,
            priceCents,
            priceCentsType,
            priceSymbol,
            priceObservation,
            // General
            type,
            color,
          }) => (
            <RowItem
              key={`${name}-${price}`}
              label={name}
              labelColor={color || nameColor}
              value={price}
              valueWeight={priceWeight}
              valueSize={priceSize}
              valueCents={priceCents}
              valueCentsType={priceCentsType}
              valueSymbol={priceSymbol}
              valueColor={color || priceColor}
              labelWeight={nameWeight}
              className={`cow-payment_summary__row cow-payment_summary__row--${
                type || 'product'
              }`}
              icon={icon}
              labelComplement={nameComplement}
              labelComplementColor={nameComplementColor}
              labelObservation={nameObservation}
              valueObservation={priceObservation}
            />
          ),
        )}

        {total && <hr className="cow-payment_summary__line" />}
        <TotalSection total={total} splitPayment={splitPayment} />

        {/* Split / Combination Payment */}
        {!!splitPayment && (
          <React.Fragment>
            <Typography type="body" size="m" color="primary" weight="semibold">
              {splitPayment.label}
            </Typography>

            {splitPayment.methods.map((method, index, methods) => {
              const isLastItem = index === methods.length - 1;

              return (
                <React.Fragment key={method.name}>
                  <RowItem
                    id={`payment-method-${index + 1}`}
                    label={method.name}
                    value={method.price}
                    valueObservation={method.priceObservation}
                    valueObservationColor="positive"
                    textSize="l"
                    className="cow-payment_summary__row cow-payment_summary__row--payment-method"
                  />

                  {isLastItem && <hr className="cow-payment_summary__line" />}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}

        {total?.name && total.price && !!splitPayment && (
          <RowItem
            label={total.name}
            labelWeight={total.nameWeight || 'semibold'}
            value={total.price}
            valueWeight={total.priceWeight || 'semibold'}
            valueObservation={total.priceObservation}
            valueObservationColor={total.priceObservationColor || 'positive'}
            textSize="l"
            className="cow-payment_summary__row cow-payment_summary__row--total"
          />
        )}

        {/* External Content */}
        {bottomContent}

        {showSubmitButton && (
          <ProgressButton
            className="cow-payment_summary__button"
            onClick={onPayClick}
            disabled={disablePayButton}
            loading={loadingButton}
            timeout={timeout}
          >
            {buttonText}
          </ProgressButton>
        )}

        {securityPaymentInformationLabel && (
          <div className="cow-payment_summary__security-payment">
            <Lock />

            <Typography type="body" size="xs" weight="semibold">
              {securityPaymentInformationLabel}
            </Typography>
          </div>
        )}

        {!!payButtonHelperText && typeof payButtonHelperText === 'string' ? (
          <div className="cow-payment_summary__security-payment">
            <Typography type="body" size="xs" color="secondary">
              {payButtonHelperText}
            </Typography>
          </div>
        ) : (
          payButtonHelperText
        )}
      </CardContent>
    </Card>
  );
}

export default PaymentSummary;
