module.exports = {
  lessThan(validations, value) {
    if (validations.lessThan !== undefined && value && parseFloat(value) < validations.lessThan) {
      return validations.error;
    }
    return '';
  },
  regex(validations, value) {
    if (validations.regex !== undefined && !new RegExp(validations.regex).test(value)) {
      return validations.error;
    }
    return '';
  },
  greaterThan(validations, value) {
    if (validations.greaterThan !== undefined && value && parseFloat(value) > validations.greaterThan) {
      return validations.error;
    }
    return '';
  },
};
