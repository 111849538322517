const { HTTP_STATUS } = require('../../constants/app');

/**
 * Allow retries
 */
const WHITELISTSTATUS = [
  'ETIMEDOUT',
  'ECONNRESET',
  'EADDRINUSE',
  'ESOCKETTIMEDOUT',
  'ECONNREFUSED',
  'EPIPE',
  'ECONNABORTED',
];

/**
 * isRetryAllowed
 */
const isRetryAllowed = (err) => {
  if (!err) {
    return false;
  }

  if (err.response && err.response.status > HTTP_STATUS.INTERNAL_SERVER_ERROR) {
    return true;
  }

  if (WHITELISTSTATUS.includes(err.code)) {
    return true;
  }

  return false;
};

/**
 * Expose isRetryAllowed
 */
module.exports = isRetryAllowed;
