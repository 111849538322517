import React, { memo } from 'react';

const IconOpenFinance = ({
  color = '#009EE3',
  width = 25,
  height = 24,
  className = '',
  ...props
}: SVGProps) => (
  <svg
    {...props}
    className={`cow-icon-open-finance ${className}`}
    width={width}
    height={height}
    viewBox="0 0 25 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0319 3.96034C14.1783 3.819 14.3759 3.74325 14.5793 3.75047C18.0043 3.87207 20.4926 5.71516 22.1278 8.62737C25.9994 15.2559 21.0828 23.8929 13.3124 23.7482C10.7403 23.7455 9.13603 23.1136 7.63961 22.126C7.45997 22.0074 7.33992 21.8174 7.31001 21.6042C7.28011 21.3911 7.34322 21.1753 7.4833 21.0119L10.4833 17.5119C10.6139 17.3595 10.8001 17.2659 11.0003 17.2518C11.2005 17.2378 11.398 17.3046 11.5486 17.4373C11.995 17.8306 12.6934 18.0108 13.5335 17.857C14.3683 17.7043 15.2903 17.2251 16.076 16.3862L16.0769 16.3852C17.212 15.1777 17.6097 13.5371 17.3157 12.1966C17.0306 10.8965 16.1079 9.88314 14.49 9.74737C14.1015 9.71477 13.8027 9.3899 13.8027 9V4.5C13.8027 4.29647 13.8855 4.10168 14.0319 3.96034ZM13.3588 22.2488C13.349 22.2484 13.3391 22.2482 13.3292 22.2482C11.4876 22.2482 10.2695 21.8954 9.19846 21.3158L11.1865 18.9964C11.9647 19.4066 12.8927 19.4992 13.8035 19.3325C14.981 19.117 16.1844 18.4645 17.1703 17.4121C18.637 15.8514 19.1831 13.7089 18.7809 11.8752C18.4118 10.1922 17.2341 8.76902 15.3027 8.35395V5.31428C17.7199 5.63685 19.5294 7.06071 20.8228 9.36704L20.8227 9.3671L20.8296 9.37887C24.1255 15.0131 19.926 22.357 13.3588 22.2488Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7048 0.527261C11.8468 0.638026 11.9316 0.806738 11.9357 0.986809L12.1202 9.13464C12.127 9.43302 11.9134 9.691 11.619 9.74007C11.6237 9.73928 11.6262 9.73883 11.6262 9.739C11.6264 9.73965 11.5934 9.74888 11.5167 9.78176C11.4346 9.81693 11.3338 9.86544 11.2272 9.92111C11.0043 10.0376 10.8073 10.1584 10.7261 10.2216C10.4872 10.4074 10.1474 10.3862 9.93346 10.1723L9.75814 9.99696C9.73007 9.9689 9.70522 9.93852 9.6837 9.90636L5.11035 4.9053C4.89933 4.67454 4.90091 4.32041 5.11399 4.09154L5.35669 3.83087C6.82563 2.1517 9.13378 0.802792 11.0125 0.458568L11.2029 0.415316C11.3785 0.375398 11.5628 0.416496 11.7048 0.527261ZM10.4573 8.97409C10.5292 8.93335 10.6018 8.89396 10.6716 8.85749C10.7511 8.81597 10.8322 8.77552 10.9109 8.7386L10.7526 1.74903C9.29362 2.15214 7.55057 3.20282 6.36688 4.50116L10.4573 8.97409Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8442 6.17482C4.01163 6.15818 4.17834 6.21261 4.3037 6.32483L9.29085 10.7892C9.48455 10.9626 9.54455 11.241 9.43947 11.4788C9.10664 12.2319 9.10614 12.2332 8.99328 12.5211L8.98792 12.5348C8.88651 12.7935 8.62152 12.9475 8.3488 12.9104L2.61295 13.146C2.44968 13.1527 2.29075 13.0926 2.17286 12.9794C2.05497 12.8663 1.98832 12.7099 1.98832 12.5465V12.4386C1.87345 11.3037 1.86058 10.397 2.01311 9.54174C2.16824 8.67184 2.4877 7.89208 2.98806 7.00208C3.00085 6.97933 3.01511 6.95744 3.03075 6.93655L3.4232 6.41231C3.52403 6.27762 3.67678 6.19145 3.8442 6.17482ZM3.14599 11.9231L8.02187 11.7229C8.05678 11.6409 8.10054 11.5406 8.16574 11.3926L3.99541 7.65942C3.56149 8.442 3.31524 9.07524 3.19447 9.75242C3.08563 10.3627 3.07366 11.0364 3.14599 11.9231Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89684 14.3716C8.89682 14.3715 8.8968 14.3714 8.89678 14.3713C8.89677 14.3713 8.89677 14.3713 8.89677 14.3713C8.89673 14.3711 8.8967 14.371 8.89667 14.3708C8.83426 14.0646 8.54724 13.8576 8.23691 13.895L2.95881 14.5317C2.77705 14.5536 2.61524 14.6573 2.51941 14.8133C2.42359 14.9693 2.40423 15.1605 2.46685 15.3326L2.49683 15.415C3.038 17.2813 3.91064 18.2966 4.63833 19.1432C4.72371 19.2426 4.80709 19.3396 4.88771 19.4354C4.89317 19.4418 4.89876 19.4482 4.90449 19.4545L5.10749 19.6759C5.27468 19.8583 5.5367 19.9194 5.7673 19.8297C5.86982 19.7898 5.95678 19.7237 6.02176 19.6409L9.30152 16.5514C9.49432 16.3698 9.54523 16.0833 9.42677 15.8464C9.23316 15.4591 9.09919 15.0856 9.01373 14.8079C8.97121 14.6697 8.94124 14.557 8.92222 14.4804C8.91272 14.4422 8.90599 14.4131 8.90183 14.3945L8.89748 14.3746L8.89684 14.3716ZM5.58528 18.4035L8.16433 15.9741C8.03331 15.6674 7.93601 15.3857 7.8668 15.1608C7.86557 15.1568 7.86434 15.1528 7.86312 15.1488L3.83038 15.6352C4.29042 16.8929 4.92093 17.6285 5.54889 18.3611C5.56102 18.3752 5.57315 18.3894 5.58528 18.4035Z"
      fill={color}
    />
  </svg>
);

export default memo(IconOpenFinance);
