const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');

const MpBrand = props => {
  const { i18n } = props;
  const translations = translate(i18n);

  return (
    <div className="mp-brand">
      <span className="mp-brand__text">{translations.PROCESSED_BY}</span>
      <span className="u-accessibility-hidden">{translations.MP}</span>
      <span className="mp-brand__logo" />
    </div>
  )
};

MpBrand.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

MpBrand.defaultProps = {
  i18n: {
    gettext: t => t,
  },
};

if (process.env.NODE_ENV === 'test') {
  module.exports = MpBrand;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(MpBrand);
}
