const { trackInfo } = require('../service/api');

const checkIfCookiesAreBlocked = () => {
  if (document.cookie !== '') {
    return false;
  }

  // log that the coookies are not being able to set
  // if is safari check if has storage access denied
  trackInfo({
    message: 'Unable to set cookies',
  });

  if (typeof document.hasStorageAccess === 'function') {
    document
      .hasStorageAccess()
      .then((access) => {
        if (!access) {
          // log this spefic case
          trackInfo({
            message: 'Storage access blocked',
          });
        }
      })
      .catch(() => {});
  }

  return true;
};

module.exports = {
  checkIfCookiesAreBlocked,
};
