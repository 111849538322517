/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  SAVE_OPTIONS,
  OPTIONS_PAGE,
} = require('../actions/types');

const actions = {};

const initialState = {
  optionsPage: false,
};

actions[SAVE_OPTIONS] = (state, action) => ({
  ...state,
  [action.payload.select]: action.payload.value,
});

actions[OPTIONS_PAGE] = (state, action) => ({
  ...state,
  optionsPage: action.payload.optionsPage,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
