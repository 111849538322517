export enum Colors {
  COW_LIGHT_BLUE = '#009EE3',
  MINE_SHAFT = '#333',
}

export enum AndesTypographyColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  POSITIVE = 'positive',
}

export enum AndesTypographySizes {
  S = 's',
  XS = 'xs',
  L = 'l',
  M = 'm',
}
