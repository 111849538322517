/**
 * Module dependencies
 */
const React = require('react');

const OrderedList = props => (
  <ol className="ordered-list">
    {props.children
      && props.children.map((it, key) => <li><span id={props.id} className="order-list-number">{key + 1}</span><div className="order-list-item">{it}</div></li>)}
  </ol>
);

module.exports = OrderedList;
