const React = require('react');

const { getErrorProps, defaultProps, defaultSecurityDisables } = require('../helper');
const InputCVV = require('../../../components/InputCVV');
const GenericInput = require('../../../components/GenericInput');
const CardNumber = require('../../../components/CardNumber');
const Fullname = require('../../../components/Fullname');
const InputExpirationDate = require('../../../components/InputExpirationDate');
const InputDocument = require('../../../components/InputDocument');
const InputEmail = require('../../../components/InputEmail');
const InputSplitAmount = require('../../../components/InputSplitAmount');
const InputCaptchaToken = require('../../../components/InputCaptchaToken');
const InputZipCode = require('../../../components/InputZipCode');
const Autofiller = require('../../../components/AutoFiller');
const InputAutofillable = require('../../../components/InputAutofillable');
const InputAction = require('../../../components/InputAction');
const InputGroupRadio = require('../../../components/InputGroupRadio');
const InputAddressCity = require('../../../components/InputAddressCity');
const InputHidden = require('../../../components/InputHidden');
const SecureFieldWrapper = require('../../../components/SecureFieldWrapper');
const InputAmountCard = require('../../InputAmountCard/InputAmountCardWithCombination');

module.exports = {
  input_text: (props) => (
    <GenericInput
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      label={props.label}
      type="text"
      name={props.name}
      show={props.show}
      initialValue={props.value}
      max_length={props.max_length}
      data={props.data}
      message={props.message}
      shouldSaveValue
      disabled={props.disabled}
      placeholder={props.placeholder}
      onlyLetters={props.only_letters}
    />
  ),
  input_textarea: (props) => (
    <GenericInput
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      placeholder={props.label}
      type="text"
      name={props.name}
      show={props.show}
      initialValue={props.value}
      data={props.data}
      message={props.message}
      multiline
      textbox
      shouldSaveValue
      disabled={props.disabled}
      countdown
    />
  ),
  input_number: (props) => (
    <GenericInput
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      name={props.name}
      label={props.label}
      type="tel"
      autoCorrect="no"
      autoCapitalize="no"
      spellCheck="no"
      initialValue={props.value}
      show={props.show}
      message={props.message}
      shouldSaveValue
      disabled={props.disabled}
    />
  ),
  input_city_autocomplete: (props) => (
    <InputAddressCity
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      placeholder={props.placeholder}
    />
  ),
  input_cvv: (props) => (
    <InputCVV
      {...defaultProps(props)}
      {...getErrorProps(props)}
      {...defaultSecurityDisables(props.publicKey)}
      id={props.id}
      name={props.name}
      iconType={props.icon_type}
      show={props.show}
      label={props.label}
      data={props.data}
      message={props.message}
      shouldSaveValue={false}
      deviceType={props.deviceType}
      siteId={props.siteId}
      showIcon={props.show_icon}
    />
  ),
  card_number: (props) => (
    <CardNumber
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      label={props.label}
      name={props.name}
      show={props.show}
      data={props.data}
      deviceType={props.deviceType}
      showIcon={props.show_icon}
    />
  ),
  input_email: (props) => (
    <InputEmail
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      label={props.label}
      name={props.name}
      show={props.show}
      initialValue={props.value}
      defaultEmail={props.default_value}
      message={props.message}
      placeholder={props.placeholder}
    />
  ),
  input_document: (props) => (
    <InputDocument
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      initialValue={props.value}
      data={props.data}
      deviceType={props.deviceType}
    />
  ),
  fullname: (props) => (
    <Fullname
      {...defaultProps(props)}
      {...getErrorProps(props)}
      {...defaultSecurityDisables(props.publicKey)}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      initialValue={props.value}
      deviceType={props.deviceType}
      placeholder={props.placeholder}
      message={props.message}
    />
  ),
  secure_field: (props) => (
    <SecureFieldWrapper
      {...defaultProps(props)}
      id={props.id}
      field={props.field}
      label={props.label}
      hint={props.hint}
      paymentMethods={props.data}
      history={props.history}
      showIcon={props.show_icon}
      cvvSettings={props.cvv_settings}
      placeholder={props.placeholder}
    />
  ),
  input_expiration_date: (props) => (
    <InputExpirationDate
      {...defaultProps(props)}
      {...getErrorProps(props)}
      {...defaultSecurityDisables(props.publicKey)}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      message={props.message}
    />
  ),
  input_split_amount: (props) => (
    <InputSplitAmount
      {...defaultProps(props)}
      {...getErrorProps(props)}
      {...defaultSecurityDisables(props.publicKey)}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      text={props.text}
      disabled={props.disabled}
      isCombination={props.is_combination}
      paymentMethod={props.payment_method}
      textInstallment={props.text_installment}
      changedValue={props.change_value}
      data={props.data}
    />
  ),
  input_captcha_token: (props) => (
    <InputCaptchaToken {...defaultProps(props)} id={props.id} name={props.name} invisible={props.invisible} />
  ),
  input_zip_code: (props) => (
    <InputZipCode {...defaultProps(props)} id={props.id} name={props.name} nextPage={props.next_page} />
  ),
  autofiller_input_zip_code: (props) => (
    <Autofiller
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      kind={props.kind}
      name={props.name}
      cityId={props.city_id}
      stateId={props.state_id}
      validation={props.validation}
      label={props.label}
      nextPage={props.next_page}
      value={props.value}
      disabled={props.disabled}
    />
  ),
  input_autofillable: (props) => (
    <InputAutofillable
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      name={props.name}
      label={props.label}
      value={props.value}
      disabled={props.disabled}
    />
  ),
  input_action: (props) => (
    <InputAction
      {...defaultProps(props)}
      {...getErrorProps(props)}
      className={props.className}
      id={props.id}
      label={props.label}
      kind={props.kind}
      name={props.name}
      show={props.show}
      next_page={props.next_page}
      childlabel={props.child_label}
      childvalue={props.child_value}
      value={props.value}
      message={props.message}
      placeholder={props.placeholder}
    />
  ),
  input_group_radio: (props) => (
    <InputGroupRadio
      {...defaultProps(props)}
      id={props.id}
      name={props.name}
      inline={props.inline}
      disabled={props.disabled}
      options={props.options}
      selectedValue={props.value}
    />
  ),
  input_hidden: (props) => <InputHidden {...defaultProps(props)} id={props.id} name={props.name} />,
  input_amount_card: (props) => (
    <InputAmountCard
      {...defaultProps()}
      errors={props.errors}
      validations={props.validations}
      publicKey={props.publicKey}
      id={props.id}
      name={props.name}
      show={props.show}
      label={props.label}
      text={props.text}
      disabled={props.disabled}
      isCombination={props.is_combination}
      paymentMethod={props.payment_method}
      textInstallment={props.text_installment}
      changedValue={props.change_value}
      data={props.data}
    />
  ),
};
