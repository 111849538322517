/* global document */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const MoreOptionsIcon = require('../../icons/MoreOptions');
const translate = require('../../../translation');

class GroupMenu extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      showPopup: false,
    };

    this.translations = translate(i18n);
    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setMenuRef(node) {
    this.menuRef = node;
  }

  handleClick() {
    if (!this.state.showPopup) {
      document.addEventListener('click', this.handleClickOutside);
    }
    this.setState({ showPopup: !this.state.showPopup });
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      this.setState({
        showPopup: false,
      }, () => document.removeEventListener('click', this.handleClickOutside));
    }
  }

render() {
  return (
      <div className="group-menu" ref={this.setMenuRef}>
        <button type="button" aria-label={this.translations.ACCOUNT_OPTION(this.state.showPopup ? 'Expandido' : 'Contraído')} className="group-menu__button" onClick={this.handleClick}>
          <MoreOptionsIcon />
        </button>
        {this.state.showPopup
          && (
            <div className="group-menu__popup">
              {
                this.props.children && this.props.children.map(option => (
                  <div className="group-menu__popup-option">
                    {option}
                  </div>
                ))
              }
            </div>
          )}
      </div>
    );
  }
}

GroupMenu.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  children: null,
};

GroupMenu.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


if (process.env.NODE_ENV === 'test') {
  module.exports = GroupMenu;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(GroupMenu);
}
