const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { injectValidations } = require('../../../utils/validator-provider');
const BaseGroup = require('../BaseGroup');
const identificationCardActions = require('../../../spa/actions/identificationCard');
const {
  IDENTIFICATION_CARD_TYPE,
} = require('../../../spa/actions/types');

class GroupIdentification extends BaseGroup {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      error: this.getPropErrors(),
      invalid: props.invalid,
    };
  }

  render() {
    let className = 'group-identification';
    const groupHasError = this.state.error.length > 0;
    if (groupHasError) {
      className += ' andes-form-control--error';
    }

    return (
      <fieldset className={className}>
        <div className="group-identification__children" data-hj-suppress>
          {this.renderChild(this.props.children[0], true, {
            /**
             * Emit message on value change
             * @param event
             */
            triggerOnChange: (event, value) => {
              this.props.identificationCardActions[IDENTIFICATION_CARD_TYPE](value);
            },
            triggerOnMount: (defaultValue) => {
              this.props.identificationCardActions[IDENTIFICATION_CARD_TYPE](defaultValue);
            },
            saveInputValue: true,
          })}
          {this.renderChild(this.props.children[1], !groupHasError)}
        </div>
        {
          (this.state.error.length > 0)
          && this.state.error.map(err => (
            <div
              key={Math.random()}
              className="andes-form-control__message"
            >
              {err}
            </div>
          ))
        }
      </fieldset>
    );
  }
}

GroupIdentification.defaultProps = {
  error: [],
  identificationCardActions: PropTypes.object,
};

GroupIdentification.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  identificationCardActions: bindActionCreators(identificationCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = () => ({});

if (process.env.NODE_ENV === 'test') {
  module.exports = injectValidations(GroupIdentification);
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(GroupIdentification));
}
