/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { List, ListItem } = require('@andes/list');

const { CHANGE_IFRAME_SIZE, COLLAPSIBLE_CLICKED } = require('../../spa/actions/types');

const dictionary = require('../../containers/Optimus/dictionary');

const translate = uiComponent => (
  dictionary.get([uiComponent.type])
    ? (dictionary.get([uiComponent.type])(uiComponent)) : ''
);

/**
 * CollapsibleSelector
 */
class CollapsibleSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      transitioning: false,
      selected: this.props.options[0] || {},
      options: this.props.options.slice(1, this.props.options.length),
    };
    this.toggle = this.toggle.bind(this);
    this.resize = this.resize.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.newCardClicked = false;
    this.clickHanled = false;
  }

  UNSAFE_componentWillReceiveProps() {
    /**
     * reset the clickHandled flag
     */
    this.clickHanled = false;
  }

  /**
   * Needed to stop unnecessary renders
   * when there is a click on the new card item
   * to avoid collapsible menu to re render
   * @param {*} nextProps
   */
  shouldComponentUpdate() {
    return !this.newCardClicked;
  }

  // eslint-disable-next-line react/sort-comp
  toggle() {
    // eslint-disable-next-line no-unused-expressions, react/no-access-state-in-setstate
    this.setState({ open: !this.state.open, transitioning: true }, () => {
      this.props.collapsibleActions[COLLAPSIBLE_CLICKED]({ collapsibleId: this.props.id, isOpen: this.state.open });
    });
  }

  resize() {
    if (typeof document !== 'undefined' && document.documentElement) {
      let size = document.documentElement.scrollHeight;
      if (!this.state.open) {
        // document.body.scrollHeight is actually returning 0 in Safari, so we'll pass a non-zero value for the iframe to resize properly;
        size = 1000;
      }
      this.props.sizeActions[CHANGE_IFRAME_SIZE](size);
    }
  }

  handleClick(event, option) {
    /**
     * @TODO check why for some reason
     * this handler is called twice
     * */
    if (!this.clickHandled) {
      this.hanldled = true;
      if (this.state.selected.values[0].id === option.values[0].id && option.values[0].id !== 'new_card_row') {
        this.toggle();
      } else if (option.values[0].id === 'new_card_row') {
        if (!this.newCardClicked) {
          this.setNewCardOption(option);
          this.newCardClicked = true;
        }
      } else {
        this.setOption(option);
      }
    }
  }

  renderItem(option, className = '') {
    return (
      <ListItem
        onClick={event => this.handleClick(event, option)}
        key={Math.random()}
        className={className}
      >
        {translate(option)}
      </ListItem>
    );
  }

  renderOption(option, className) {
    return this.renderItem(option.image, option.primary, option.secondary, this.toggle, option.id, className);
  }

  renderOptions() {
    const options = this.state.options.map(option => this.renderItem(option));
    return (
      <List className={`payment-options-list ${this.state.open ? 'unfolded' : 'folded'}`}>
        {options}
      </List>
    );
  }

  render() {
    if (!this.newCardClicked && !this.props.show) {
      return null;
    }

    const {
      label,
    } = this.props;
    const header = <ListItem className="title" primary={label} />;
    const selectedOption = Object.keys(this.state.selected).length ? this.renderItem(this.state.selected, 'selected') : null;
    const options = this.renderOptions();
    return (
      <div className={`collapsible-selector-container ${this.state.open ? 'unfolded' : 'folded'} ${this.state.transitioning ? 'transitioning' : ''} ${this.getClasses()}`}>
        {header}
        {selectedOption}
        {options}
      </div>
    );
  }
}

/**
 * Prop Types
 */
CollapsibleSelector.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  id: PropTypes.string,
  show: PropTypes.bool,
};

/**
 * Default Props
 */
CollapsibleSelector.defaultProps = {
  label: '',
  options: [],
  i18n: {
    gettext: t => t,
  },
  id: '',
  show: true,
};

module.exports = CollapsibleSelector;
