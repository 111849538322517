/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

/**
 * IssuersRow
 */
const IssuersRow = (props) => {
  const {
    issuer,
    url,
  } = props;

  return (
    <div className="issuers-row">
      {url !== ''
        ? (
          <img
            className="issuers-row__logo"
            src={url}
            alt={issuer}
            title={issuer}
          />
        )
        : <p className="issuers-row__text">{issuer}</p>}
    </div>
  );
};

/**
 * Prop Types
 */
IssuersRow.propTypes = {
  issuer: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

/**
 * Expose IssuersRow
 */
module.exports = IssuersRow;
