/**
 * Generate a new array without repeated values
 * @param {array} values
 * @returns {array}
 */
const removeDuplicatedFromArray = (values) => {
  const newArray = values.filter((value, index, self) => self.indexOf(value) === index).filter((value) => !!value);

  return newArray;
};

module.exports = removeDuplicatedFromArray;
