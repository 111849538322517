const { handleActions } = require('redux-actions');

const { CHECK_PAYMENT_OPTION, UNCHECK_PAYMENT_OPTION, RESET_COMBINATION } = require('../actions/types');

const actions = {};

const initialState = {
  denyList: {},
  maxCombinations: 2,
  paymentOptionsToCombine: [],
  disabledDefault: ['account_money'],
};

actions[CHECK_PAYMENT_OPTION] = (state = initialState, action) => ({
  ...state,
  paymentOptionsToCombine: [...state.paymentOptionsToCombine, action.payload],
});

actions[UNCHECK_PAYMENT_OPTION] = (state = initialState, action) => {
  let { paymentOptionsToCombine } = state;
  paymentOptionsToCombine = paymentOptionsToCombine.filter((option) => option.id !== action.payload.id);
  return {
    ...state,
    paymentOptionsToCombine,
  };
};

// TODO: this must be changed with hidden_components values (Proactive Combination).
actions[RESET_COMBINATION] = (state = initialState) => ({ ...state, ...initialState });

module.exports = handleActions(actions, initialState);
