const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { compose, bindActionCreators } = require('redux');

const LoadingActions = require('../../spa/actions/loading');
const { LOADER_TRIGGERED } = require('../../spa/actions/types');

const { useState, useEffect } = React;
const Button = require('../../components/Button');

const ButtonWithStore = () => (Component) => {
  const WrappedButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [disabledState, setDisabledState] = useState(true);
    const {
      blockLoading,
      className,
      disabled,
      blockedByCaptcha,
      dynamicDescription,
      icon,
      id,
      kind,
      size,
      fullWidth,
      requestLoading,
      loadingId,
      loadingActions,
      loadingStopAnimation,
      name,
      onClick,
      text,
      type,
      isDefault,
      progressBar,
      ...other
    } = props;

    const handleClick = (event) => {
      // Prevent tests failing
      if (loadingActions && !blockLoading) {
        if (progressBar || id === 'pay') {
          loadingActions[LOADER_TRIGGERED]('empty', id);
        } else {
          loadingActions[LOADER_TRIGGERED]('', id);
        }
      }
      if (typeof onClick === 'function') {
        onClick(event);
      }
    };

    const enableButton = () => {
      setDisabledState(false);
    };

    const updateState = () => {
      if (loading && loadingId !== id) {
        setLoading(false);
      }
    };

    /**
     * Enable button once component is mounted
     */
    useEffect(() => {
      enableButton();
    }, []);

    useEffect(() => {
      updateState();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingId, id]);

    useEffect(() => {
      if(loadingId === id) {
        setLoading(true);
      }
    }, [requestLoading]);

    /**
     * @TODO
     * kind should never be pay
     * in the prop validation checks : ['link', 'loud', 'quiet', 'transparent'];
     * we should generate a change request for flows to stop sending kind == pay for this
     */
    const isPay = kind === 'pay' || id === 'pay';
    const isLoading = requestLoading && loading && !blockLoading && (isPay || progressBar);
    const isDisabled = disabledState || (loading && requestLoading) || disabled || (id === 'pay' && blockedByCaptcha);
    const progressDone = loadingStopAnimation && requestLoading && isPay && loading;

    return (
      <Component
        {...other}
        id={id}
        name={name}
        text={text}
        disabled={isDisabled}
        loading={isLoading}
        size={size}
        type={type}
        fullWidth={fullWidth}
        kind={kind}
        onClick={handleClick}
        icon={icon}
        isDefault={isDefault}
        className={className}
        progressDone={progressDone}
        dynamicDescription={dynamicDescription}
      />
    );
  };

  WrappedButton.propTypes = {
    blockLoading: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    blockedByCaptcha: PropTypes.bool,
    dynamicDescription: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string,
    kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    fullWidth: PropTypes.bool,
    requestLoading: PropTypes.bool,
    loadingId: PropTypes.string,
    loadingActions: PropTypes.shape({
      [LOADER_TRIGGERED]: PropTypes.func,
    }),
    loadingStopAnimation: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.string,
    isDefault: PropTypes.bool,
    progressBar: PropTypes.bool,
  };

  WrappedButton.defaultProps = {
    blockLoading: false,
    className: '',
    disabled: false,
    blockedByCaptcha: false,
    dynamicDescription: false,
    icon: null,
    id: null,
    kind: 'loud',
    size: 'large',
    fullWidth: false,
    requestLoading: false,
    loadingId: '',
    loadingActions: {
      [LOADER_TRIGGERED]: () => {},
    },
    loadingStopAnimation: false,
    name: '',
    onClick: null,
    text: '',
    type: 'submit',
    isDefault: false,
    progressBar: false,
  };

  /* istanbul ignore next: cant test it with tests */
  return WrappedButton;
};

const mapDispatchToProps = (dispatch) => ({
  loadingActions: bindActionCreators(LoadingActions, dispatch),
});

const mapStateToProps = (state) => ({
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  dynamicDescription: state.transitionRedirect.dynamicDescription,
  requestLoading: state.request.loading,
  loadingId: state.request.loadingId,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonWithStore()(Button);
} else {
  module.exports = compose(connect(mapStateToProps, mapDispatchToProps), ButtonWithStore())(Button);
}
