/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  CREDITS_INSTALLMENT_UPDATE,
  CREDITS_INSTALLMENT_UPDATE_COMBINATION,
  CREDITS_INSTALLMENT_CLEAN,
} = require('./types');

// Request Actions
const installmentUpdate = createAction(CREDITS_INSTALLMENT_UPDATE);
const installmentClean = createAction(CREDITS_INSTALLMENT_CLEAN);
const installmentUpdateCombination = createAction(CREDITS_INSTALLMENT_UPDATE_COMBINATION);

/**
 * Export functions
 */
module.exports = {
  [CREDITS_INSTALLMENT_UPDATE]: installment => (dispatch) => {
    dispatch(installmentUpdate(installment));
  },
  [CREDITS_INSTALLMENT_CLEAN]: () => (dispatch) => {
    dispatch(installmentClean());
  },
  [CREDITS_INSTALLMENT_UPDATE_COMBINATION]: installment => (dispatch) => {
    dispatch(installmentUpdateCombination(installment));
  },
};
