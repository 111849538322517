/* eslint-disable max-depth */
// This is set by webpack on compile time (DefineConstant on Building Blocks)
const env = process.env.NODE_ENV;
const PublicKeyUtil = require('../../utils/PublicKey');
const validationMethods = require('./validations');

/**
 * Default Props across all components
 * key: This is a function to prevent Math.random() to be the same if is not run as a function
 */
exports.defaultProps = () => {
  const defaultProps = {
    key: Math.random(),
  };
  // If props where sent, add custom values
  return defaultProps;
};

/**
 * This is going to get the errors from middle-end response and is gonig to construct the error array that is going
 * to be send to the component as props
 *
 * Example of the result:
 * error={['Este es un Error', 'Este es otro Error']}
 *
 * If you want to see a more detail example, please check the tests
 *
 * @param errors
 * @param validations
 */
exports.getErrorProps = ({ errors, validations = [] }) => {
  const error = validations.filter(v => v.show).map(v => (
    (errors && errors[v.error]) ? errors[v.error] : v.error
  ));
  const invalid = error.length > 0;

  return {
    error,
    invalid,
    errors,
    validations,
  };
};

/**
 * Validate the regex with the appropiate REGEX and return the error id
 * @param validations
 * @param value
 * @returns {string}
 */
exports.getErrorAndValidate = (validations = [], value = '') => {
  let errorMessage = '';
  let i = 0;
  const validationsLength = validations.length;

  for (; i < validationsLength; i++) {
    try {
      if (validations[i]) {
        const keys = Object.keys(validations[i]);

        for (let x = 0; x < keys.length; x++) {
          const key = keys[x];
          if (validationMethods[key]) {
            /** call validator function, pass validations and value */
            const error = validationMethods[key](validations[i], value);

            if (error) {
              errorMessage = error;
              break;
            }
          }
        }
        /** if an error matches with a validator function stop checking */
        if (errorMessage) {
          break;
        }
      }
    } catch (error) {
      // Prevent breaking if a REGEX pattern is invalid (CHECKOFF-71)
    }
  }
  return errorMessage;
};

/**
 * Return default security disables (copy, paste, drag, drop, etc...)
 * This functionality is disable when the Public Key is from Test or the environment is not Production, or Unit Testing
 * @returns {{onPaste: onPaste, onCopy: onCopy, onCut: onCut}}
 */
exports.defaultSecurityDisables = publicKey => ({
  onPaste: (e) => {
    if (PublicKeyUtil.isTestKey(publicKey) || (env !== 'production' && env !== 'test')) {return;}
    e.preventDefault();
  },
  onCopy: (e) => {
    if (PublicKeyUtil.isTestKey(publicKey) || (env !== 'production' && env !== 'test')) {return;}
    e.preventDefault();
  },
  onCut: (e) => {
    if (PublicKeyUtil.isTestKey(publicKey) || (env !== 'production' && env !== 'test')) {return;}
    e.preventDefault();
  },
});

/**
 * Default Props across all titles & texts components
 * key: This is a function to prevent Math.random() to be the same if is not run as a function
 * text & reference binding
 */
exports.defaultTextProps = (props) => {
  const defaultProps = {
    id: props.id,
    key: Math.random(),
    text: props.label || props.text,
    trackEvent: props.track_event || {},
  };
  // If props where sent, add custom values
  return defaultProps;
};
