/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const { BANK_SEARCH, RESET_BANK_SEARCH } = require('./types');

const searchBank = createAction(BANK_SEARCH);

const resetSearchBank = createAction(RESET_BANK_SEARCH);

/**
 * Export functions
 */
module.exports = {
  [BANK_SEARCH]: (value) => (dispatch) => {
    dispatch(searchBank(value));
  },
  [RESET_BANK_SEARCH]: () => (dispatch) => {
    dispatch(resetSearchBank());
  },
};
