const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const classNames = require('classnames');

const { isCardId } = require('../../../utils/HiddenComponents');

const { useEffect, useState } = React;

const CvvWithEsc = (props) => {
  const {hidden_components, validateEsc, cardId, children} = props;
  const [show, setShow] = useState(true);
  const currentCardId = isCardId(hidden_components);

  useEffect(() => {
    if (typeof validateEsc !== 'undefined' && currentCardId === cardId) {
      setShow(false);
    } else {
      setShow(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEsc]);

  const rowCvvClasses = classNames('row_cvv', { 'andes-visually-hidden': !show });

  return (
    <>
      <div className={rowCvvClasses} key={Math.random()}>
        {children}
      </div>
      <div style={{display: !show ? 'block' : 'none'}} className='wrapper-without-cvv' />
    </>
  );
};

CvvWithEsc.propTypes = {
  validateEsc: PropTypes.string,
  hidden_components: PropTypes.array,
  cardId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

CvvWithEsc.defaultProps = {
  validateEsc: undefined,
  hidden_components: [],
  cardId: '',
  children: null,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  validateEsc: state.esc?.cardTokenId,
  hidden_components: state.page.data.hidden_components,
  cardId: state.esc?.cardId,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = CvvWithEsc;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(CvvWithEsc);
}
