const React = require('react');
const PropTypes = require('prop-types');

const CommonRow = require('../CommonRow');
const OptionsList = require('../OptionsList');

class SelectableListItem extends React.Component {
  getTertiary() {
    if (this.props.tertiary === '') {
      return {
        type: 'icon_blue_chevron',
      };
    }

    return {
      text: this.props.tertiary,
      type: 'text',
    };
  }

  getOptions() {
    const components = [
      {
        type: 'group_row_details',
        id: 'group_row_details',
        label: '',
        errors: {},
        value: [
          {
            text: this.props.primary,
            type: 'text_title',
          },
          {
            text: this.props.secondary,
            type: 'text',
          },
        ],
        validations: [],
        show: true,
      },
    ];
    components.push(this.getTertiary());

    return [
      {
        id: this.props.id,
        name: this.props.name,
        value: `[${this.props.value}]`,
        siteId: this.props.siteId, // Coming from Optimus (Needed for CreditCardIcon)
        nextPage: this.props.nextPage,
        components,
      },
    ];
  }

  render() {
    const options = {
      name: this.props.name,
      className: '',
      options: this.getOptions(),
      siteId: '',
    };
    return <div className={`list-item ${this.props.modifier}`}><OptionsList {...options} component={CommonRow} /></div>;
  }
}

SelectableListItem.propTypes = {
  nextPage: PropTypes.string,
  name: PropTypes.string,
  siteId: PropTypes.string,
  type: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  tertiary: PropTypes.string,
  modifier: PropTypes.string,
  id: PropTypes.string.required,
  value: PropTypes.string.required,
  listType: PropTypes.string,
};

SelectableListItem.defaultProps = {
  type: 'radio',
  primary: '',
  secondary: '',
  tertiary: '',
  modifier: '',
  value: '',
  id: '',
  listType: '',
};

module.exports = SelectableListItem;
