const React = require('react');

const BuyFast = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M7.326 1.536l-.613 3.42 2.632.104h.08c.215.033.4.155.5.331a.575.575 0 010 .565l-3.295 5.79a.53.53 0 01-.613.231c-.238-.072-.384-.292-.343-.519l.613-3.424-2.634-.11h-.081a.695.695 0 01-.5-.332.587.587 0 010-.566l3.294-5.778a.535.535 0 01.612-.225c.237.072.383.288.348.513z"
      />
    </g>
  </svg>
);

module.exports = BuyFast;
