const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const { Tooltip } = require('@andes/tooltip');
const IconWithTooltip = require('../IconWithTooltip');
const Markdown = require('../Markdown');
const {
  COMMONS: { ELEMENT_TYPE, SITE_ID },
  APP: {
    CURRENT_STEP: { CONSUMER_CREDITS },
    COMPONENTS_GROUP_NAME: { SUMMARY_PRODUCT },
  },
} = require('../../../constants');
const { reduceLongWord, splitLongWord, isLongWord } = require('../../utils/Strings');
const translate = require('../../translation');

const MAX_LENGTH_TOOLTIP = 35;
const MAX_LENGTH_SUMMARY_ROW = 18;

const RowSummary = ({
  id,
  kind,
  installment,
  splitValueFirstMethod,
  splitValueSecondMethod,
  initialValue,
  installmentSelected,
  installmentCreditsSelected,
  value,
  i18n,
  step,
  siteId,
}) => {
  const translations = translate(i18n);
  let label = null;
  const installmentsFree = installment?.interestFree;

  const hasInterest = !!installment.totalWithInterest && parseInt(installment.value) > 1;

  const isKind = {
    tax: kind === 'tax',
    pay: kind === 'pay',
    dynamic: kind === 'dynamic',
    installment: kind === 'installment',
    payCombined: kind === 'pay-combined',
    firstCombine: kind === 'first-combine',
  };

  if (isKind.firstCombine) {
    label = splitValueFirstMethod;
  }

  const shouldRenderForCombination = splitValueFirstMethod && (installmentSelected || installmentCreditsSelected);

  if (isKind.payCombined) {
    label = shouldRenderForCombination ? installment.totalPurchase : initialValue;
  }

  if (isKind.installment) {
    label = shouldRenderForCombination ? installment.label : splitValueSecondMethod;
  }

  if (isKind.pay) {
    label = installment.label;
  }

  // Adding 'Sin Interes' in summary
  const thereIsPromo = value.some((item) => item.type === 'text_promo');
  const showNoInterestWording = (isKind.pay || isKind.installment) && installmentsFree && !thereIsPromo;

  const getItemTextComponent = (text) => {
    const nonMarkdownComponents = [SUMMARY_PRODUCT];

    if (nonMarkdownComponents.includes(id)) {
      return text;
    }

    return <Markdown text={text} />;
  };

  const getItemText = (type, text) => {
    const renderLabel =
      (isKind.pay || isKind.payCombined || isKind.installment || isKind.firstCombine) && type === 'price' && label;

    if (!renderLabel && (text.length > MAX_LENGTH_TOOLTIP || isLongWord(text, MAX_LENGTH_SUMMARY_ROW))) {
      return (
        <Tooltip className="product-name-tooltip" content={splitLongWord(text, MAX_LENGTH_TOOLTIP)}>
          {reduceLongWord(text, MAX_LENGTH_SUMMARY_ROW)}
        </Tooltip>
      );
    }

    if (renderLabel) {
      const complement = hasInterest ? ` (${installment.totalWithInterest})` : '';

      if (complement && siteId === SITE_ID.MLB) {
        return (
          <div className="row-summary__price__group">
            <span>{label}</span>
            <span className="row-summary__price__group__interest">{complement}</span>
          </div>
        );
      }

      if (parseInt(installment.value) === 1) {
        const priceValue = value.find((item) => item.type === ELEMENT_TYPE.PRICE)?.text;
        return priceValue || label;
      }

      return label;
    }

    return getItemTextComponent(text);
  };

  const renderItem = (type, text) => {
    return (
      <span
        className={`row-summary__${type} ${text.length > MAX_LENGTH_TOOLTIP ? 'two-lines-row' : ''}`}
        key={Math.random()}
      >
        {getItemText(type, text)}
      </span>
    );
  };

  return (
    <div
      className={classNames('row-summary', {
        'row-summary--tax': isKind.tax,
        'row-summary--pay': isKind.pay || isKind.payCombined,
        'row-summary--hide': (isKind.firstCombine || isKind.installment) && !splitValueFirstMethod,
        'row-summary--with-interest': hasInterest && isKind.pay,
        'row-summary--dynamic': isKind.dynamic,
        'row-summary--installment-combined': isKind.installment,
      })}
    >
      {value.map(({ type, text, icon, title }) => {
        switch (type) {
          case 'icon_with_tooltip':
            return <IconWithTooltip icon={icon} text={text} title={title} />;
          default:
            return renderItem(type, text);
        }
      })}
      {showNoInterestWording && (
        <span className="row-summary__text_promo ">{translations.NO_INTEREST(step === CONSUMER_CREDITS)}</span>
      )}
    </div>
  );
};

RowSummary.propTypes = {
  id: PropTypes.string,
  kind: PropTypes.string,
  step: PropTypes.string,
  installment: PropTypes.shape({
    totalPurchase: PropTypes.string,
    label: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      installment: PropTypes.shape({
        label: PropTypes.string,
      }),
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        PropTypes.string,
      ]),
    }),
  ),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  step: PropTypes.string,
  siteId: PropTypes.string,
};

RowSummary.defaultProps = {
  id: '',
  kind: '',
  installment: {},
  value: [],
  i18n: {
    gettext: (t) => t,
  },
  step: '',
  value: [],
  installment: {},
  i18n: {
    gettext: (t) => t,
  },
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  step: state.page.flow.step,
  installment: state.installment,
  splitValueFirstMethod: state.inputValues.current[`${state.page.flow.step}_split_amount_first_method`],
  splitValueSecondMethod: state.inputValues.current[`${state.page.flow.step}_split_amount_second_method`],
  initialValue: state.inputValues.current[`${state.page.flow.step}_split_amount_initial_value`],
  installmentSelected: !!state.inputValues.current[`${state.page.flow.step}_installments_select_express`],
  installmentCreditsSelected: !!state.inputValues.current[`${state.page.flow.step}_installments_select_credits`],
  siteId: state.configurations.platform.siteId,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = RowSummary;
} else {
  module.exports = connect(mapStateToProps)(injectI18n(RowSummary));
}

module.exports.MAX_LENGTH_TOOLTIP = MAX_LENGTH_TOOLTIP;
module.exports.MAX_LENGTH_SUMMARY_ROW = MAX_LENGTH_SUMMARY_ROW;
