/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  RESET_DINAMIC_TEXT,
  UPDATE_DINAMIC_TEXT_VALUE,
} = require('../actions/types');

const actions = {};

const initialState = {};

actions[RESET_DINAMIC_TEXT] = (state, action) => ({
  ...state,
  [action.payload.reference]: null,
});

actions[UPDATE_DINAMIC_TEXT_VALUE] = (state, action) => ({
  ...state,
  [action.payload.reference]: action.payload.value,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
