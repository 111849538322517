/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { Dropdown } = require('@andes/dropdown');
const { injectI18n } = require('nordic/i18n');

const { injectValidations } = require('../../utils/validator-provider');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const addressActions = require('../../spa/actions/address');
const { VISIBILITY_INPUT_VALUES } = require('../../spa/actions/types');

const { DropdownItem, DropdownNative } = Dropdown;

const {
  CURRENT_INPUT_VALUES,
  SAVE_ADDRESS_STATE_INFORMATION,
} = require('../../spa/actions/types');
const translate = require('../../translation');

/**
 * Select
 */
class SelectState extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.savedValue || '',
      step: props.step,
      unselected: props.savedValue === '',
      selectKey: Math.random(),
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.getComponentValue = this.getComponentValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getOptionByValue = this.getOptionByValue.bind(this);
    this.translations = translate(i18n);
  }

  componentDidMount() {
    let defaultValue;
    if (!this.props.showPlaceholder || this.props.deviceType === 'mobile') {
      defaultValue = this.getDefaultValue(this.props.options) || '';
    }

    this.updateValue(defaultValue);
    const selectedOption = defaultValue ? this.getOptionByValue(defaultValue) : null;
    this.dispatchVisibilityOnValue(defaultValue);

    // If there is a trigger function on mount call it
    if (this.props.triggerOnMount) {
      this.props.triggerOnMount(defaultValue);
    }

    if (this.props.id && this.state.step) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, defaultValue);
    }

    if (this.props.id === 'select_state') {
      this.props.addressActions[SAVE_ADDRESS_STATE_INFORMATION]({
        id: selectedOption.value,
        name: selectedOption.label,
      });
    }
  }

  dispatchVisibilityOnValue(valueSelected) {
    const optionSelected = this.props.options.find(opt => opt.value === valueSelected);

    // If the select is dynamic, hide and show elements
    if (optionSelected && optionSelected.toShow && optionSelected.toHide) {
      const inputsVisibility = {};

      optionSelected.toShow.forEach(id => (inputsVisibility[`${this.state.step}_${id}`] = true));
      optionSelected.toHide.forEach(id => (inputsVisibility[`${this.state.step}_${id}`] = false));

      this.props.inputValuesActions[VISIBILITY_INPUT_VALUES](inputsVisibility);
    }
  }

  getDefaultValue(options) {
    let selectedOptionValue = '';
    if (options.length > 0) {
      let selectedOption = options.filter(opt => !!opt.selected)[0];
      if (!selectedOption) {
        selectedOption = options[0];
      }
      selectedOptionValue = selectedOption.value;
    }
    return selectedOptionValue;
  }

  getComponentValue() {
    const value = (this.state.value || '').toString();
    // Remove the brackets
    return value.substring(1, value.length - 1);
  }

  handleBlur(event) {
    this.props.onBlur(event);
  }

  handleFocus(event) {
    this.props.onFocus(event);
  }

  getOptionByValue(value) {
    const selectedValue = this.props.options.filter(option => option.value === value);
    return selectedValue.length > 0 ? selectedValue[0] : null;
  }

  onChange(e, value) {
    const optionSelected = this.props.options.find(opt => opt.value === value);

    if (this.props.onChange) {
      this.props.onChange(e, value);
    }

    this.setState({ unselected: false });

    // Save the current option label to be used on visible elements like Extra-Data component
    if (this.props.id && this.state.step) {
      const inputValue = optionSelected ? optionSelected.value : value;
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, inputValue);

      this.props.addressActions[SAVE_ADDRESS_STATE_INFORMATION]({
        id: optionSelected.value,
        name: optionSelected.label,
      });
    }

    this.dispatchVisibilityOnValue(value);

    this.updateValue(value);
  }

  updateValue(value) {
    this.setState({
      value,
    });
  }

  render() {
    const {
      onBlur,
      onFocus,
      onChange,
      invalid,
      errors,
      updateSummary,
      shouldSaveValue,
      inputValuesActions, // eslint-disable-line
      identificationCardActions, // eslint-disable-line
      addressActions, //eslint-disable-line
      savedValue,
      saveInputValue,
      triggerOnMount,
      validateCallback,
      validations,
      showErrorMessage,
      showPlaceholder,
      error,
      errorMessage,
      options,
      i18n,
      placeholder,
      deviceType,
      ...selectProps
    } = this.props;

    const {CONTEXT, CHOOSE_OPTION} = this.translations;

    let className = `${this.props.className || ''} select-state`;
    if (options.length > 0 && options[0].value === '') {
      className += ' placeholder';

      if (this.state.unselected) {
        className += ' unselected ';
      }
    }

    if (this.state.invalid && this.state.error.length > 0 && showErrorMessage) {
      selectProps.message = this.state.error[0];
      selectProps.valid = false;
    }

    const showNativeSelect = this.props.deviceType === 'mobile';
    const DropdownComponent = showNativeSelect ? DropdownNative : Dropdown;
    const optionsList = [...options];
    if (showNativeSelect) {
      optionsList.unshift(
        {
          name: placeholder || i18n.pgettext(CONTEXT, CHOOSE_OPTION),
          value: '',
          key: 'dd_placeholder',
          selected: true,
          label: placeholder || i18n.pgettext(CONTEXT, CHOOSE_OPTION),
        },
      );
    }

    if (!showNativeSelect) {
      selectProps.dismissPlaceholder = true;
    }

    return (
      <>
        <DropdownComponent
          {...selectProps}
          helper={selectProps.message}
          onChange={this.onChange}
          onBlur={onBlur && this.handleBlur}
          onFocus={onFocus && this.handleFocus}
          value={this.state.value}
          className={className}
          disabled={options.length <= 0}
          type="form"
          placeholder={placeholder || i18n.pgettext(CONTEXT, CHOOSE_OPTION)}
          key={this.state.selectKey}
          menuMaxHeight={220}
        >
          {optionsList.map(({ value, label }, index) => (
            (index === 0 && (value === '' || !value))
              ? (<DropdownItem value="" title={label} selected disabled />)
              : (
                <DropdownItem
                  name={value}
                  key={`dd_${value}`}
                  value={value}
                  title={label}
                />
              )
          ))}
        </DropdownComponent>
        {!showNativeSelect && this.state.value && (
          <input type="hidden" value={this.state.value} name={selectProps.name} />
        )}
      </>
    );
  }
}

/**
 * Prop Types
 */
SelectState.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  updateSummary: PropTypes.bool,
  shouldSaveValue: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
    toShow: PropTypes.arrayOf(PropTypes.string),
    toHide: PropTypes.arrayOf(PropTypes.string),
  })),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputValuesActions: PropTypes.shape({
    current_input_values: PropTypes.func,
    visibility_input_values: PropTypes.func,
  }),
  showErrorMessage: PropTypes.bool,
  triggerOnMount: PropTypes.func,
  saveInputValue: PropTypes.bool,
  savedValue: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    pgettext: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  stateoptions: PropTypes.string,
};

/**
 * Default Props
 */

SelectState.defaultProps = {
  id: `sel-${Math.random().toString(36).substr(2, 9)}`,
  name: '',
  className: '',
  label: '',
  hint: '',
  error: [],
  errorMessage: '',
  disabled: false,
  options: [],
  onFocus: null,
  onBlur: null,
  showErrorMessage: true,
  saveInputValue: true,
  showPlaceholder: false,
  updateSummary: false,
  shouldSaveValue: true,
  savedValue: '',
  step: '',
  inputValuesActions: {
    current_input_values: null,
    visibility_input_values: null,
  },
  i18n: {
    gettext: t => t,
    pgettext: (c, t) => t,
  },
  stateoptions: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  addressActions: bindActionCreators(addressActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
  options: ownProps.stateoptions ? state.selectOptions[`${state.page.flow.step}_${ownProps.stateoptions}`] : ownProps.options,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectState;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(SelectState)));
}
