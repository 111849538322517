const { CURRENCY_SYMBOL } = require('../../constants/app');
const { logErrorFromClient } = require('./logTags');
const { ERROR_SPA } = require('../../constants/app');

/**
 * Complete the value with zeros, or truncate it, to the length given
 * If the value is empty, empty string is returned
 * @param {string|int} value - Value to complete or truncate
 * @param {int} length - desired length
 */
const completeWithZeros = (value = '', length = 2) => {
  if (value && value.toString().length) {
    const zeros = '0'.repeat(length);

    return (value.toString() + zeros).substring(0, length);
  }

  return '';
};

/**
 * Parse a number to a "money" format (i.e. 4000.50 -> "BR 4.000,50")
 * @param {number} value - Value to format
 * @param {Object} settings
 * @param {string} settings.symbol - currency symbol
 * @param {string} settings.thousands_separator - thousands separator symbol (default: ,)
 * @param {string} settings.decimal_separator - decimal separator symbol (default: .)
 * @param {string} settings.decimal_places - decimal separator symbol (default: .)
 */
const parseAmount = (value, settings = {}) => {
  // eslint-disable-next-line no-useless-catch
  try {
    let amount = (value || '').toString();

    if (amount.length > 0) {
      const symbol = settings.symbol || CURRENCY_SYMBOL.ARS;
      const decSeparator = settings.decimal_separator || '.';
      const thousandsSeparator = settings.thousands_separator || '';

      let [intAmount, decAmount] = amount.split('.');

      /**
       * Warning:
       * the expression can return empty matches, and may match infinitely in some use cases
       * @TODO search for a a regex that does't have this potential risk
       * or use a lib that limit the posible matches when using a regex.
       */
      // eslint-disable-next-line security/detect-unsafe-regex
      intAmount = (intAmount || '').replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
      decAmount = completeWithZeros(decAmount, settings.decimal_places || 2);
      amount = `${symbol} ${intAmount}${decAmount ? decSeparator : ''}${decAmount}`;
    }

    return amount;
  } catch (error) {
    logErrorFromClient(error, ERROR_SPA.CURRENCY_PARSE_AMOUNT, '[Currency][parseAmount]', {
      value,
      settings,
    });

    throw error;
  }
};

/**
 * Round a float to a specific accuracy with float fix
 * @param {float} value - Value to round
 * @param {int} accuracy - Decimals amount
 */
const round = (value, accuracy) => {
  if (value == null || Number.isNaN(value) || (accuracy != null && Number.isNaN(accuracy))) {
    return null;
  }

  const fix = Math.pow(10, (accuracy + 1) * -1); // eslint-disable-line
  accuracy = Math.pow(10, accuracy || 0); // eslint-disable-line

  return Math.round(value * accuracy + fix) / accuracy;
};

/**
 * Return float number of amount format
 * @param {string} value - amount number (i.e.: BR 4.000,50)
 * @param {Object} settings
 * @param {string} settings.symbol - currency symbol
 * @param {string} settings.thousands_separator - thousands separator symbol (default: ,)
 * @param {string} settings.decimal_separator - decimal separator symbol (default: .)
 */
const getFloatValue = (value = '', settings = {}) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (typeof value === 'number') {
      return value;
    }

    const symbol = settings.symbol || CURRENCY_SYMBOL.ARS;
    const decSeparator = settings.decimal_separator || '.';
    const thousandsSeparator = settings.thousands_separator || '';
    const thousandsSeparatorRegex = thousandsSeparator !== '' ? new RegExp(`\\${thousandsSeparator}`, 'g') : '';

    const formattedValue = value.replace(symbol, '').replace(thousandsSeparatorRegex, '').replace(decSeparator, '.');

    return parseFloat(formattedValue || 0);
  } catch (error) {
    logErrorFromClient(error, ERROR_SPA.CURRENCY_GET_FLOAT_VALUE, '[Currency][getFloatValue]', {
      value,
      settings,
    });

    throw error;
  }
};

const handleDecimals = (value, digits = 2) => parseFloat(value.toFixed(digits));

module.exports = {
  parseAmount,
  round,
  getFloatValue,
  handleDecimals,
};
