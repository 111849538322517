/* eslint-disable import/order */
const React = require('react');

// Andes Components
const { Typography } = require('@andes/typography');
const { Card, CardContent } = require('@andes/card');
const { Message } = require('@andes/message');

// Custom Components
const IconExtraData = require('../../../../components/icons/ExtraData');
const BankIcon = require('../../../../components/icons/Bank');
const Info = require('../../../../components/icons/Info');
const UserDefault = require('../../../../components/icons/UserDefault');
const IconEmail = require('../../../../components/icons/Email');

// Cow Components Icons
const InstallmentsIcon = require('@cow/core-components/components/Icons/InstallmentsIcon');

// Utils Nodes
const { getPaymentMethodIcon } = require('../../../../utils/icons');
const { getShippingOptions } = require('../utils/shipping');
const { isMobile } = require('../../../../utils/webview');

// Constants
const { DEVICE_TYPE, PAYMENT_METHOD, PAYMENT_METHOD_TYPE } = require('../../../../../constants/commons');
const { COLORS } = require('../../../../../constants');

// TODO: Add translations for other payment methods (CHECK WITH BACKEND)
const getTitleForUserExtraData = ({ translations }) => translations.DATA_FOR_YOUR_INVOCE;

const getBottomContentComponent = ({ paymentMethod, translations, isBppFlow }) => {
  if (paymentMethod.id === PAYMENT_METHOD.pix && !isBppFlow) {
    return (
      <Card className="card-option__disclaimer">
        <CardContent>
          <Info width={15} height={12} />
          <Typography className="text" type="body" size="m">
            {translations.PIX_BPP}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (paymentMethod.id === PAYMENT_METHOD.SPEI) {
    return (
      <Message id="card-option__spei" hierarchy="quiet">
        {translations.CLABE_MESSAGE}
      </Message>
    );
  }

  return null;
};

const getPaymentMethodDescription = (paymentMethod, deviceType) => {
  if (isMobile(deviceType) || paymentMethod.id === PAYMENT_METHOD.ACCOUNT_MONEY || paymentMethod?.tax?.status === true) {
    return paymentMethod?.tax?.subtitle || "";
  }
  return paymentMethod.accreditation_description || paymentMethod.detail;
}

export const useCardList = ({
  siteId,
  isBppFlow,
  translations,
  payer,
  shipping,
  isGuest,
  deviceType,
  actions: {
    changePaymentMethod,
    changeInstallments,
    changeExtraData,
    changeShippingOption,
    changeShippingAddress,
    changeEmail,
  },
}) => {
  const buildCardList = (paymentMethods) => {
    if (Array.isArray(paymentMethods) && (!paymentMethods || paymentMethods.length === 0)) {
      return [];
    }

    const options = [];

    // Get Shipping Options
    shipping &&
      options.push(
        ...getShippingOptions({
          shipping,
          translations,
          changeShippingOption,
          changeShippingAddress,
          deviceType
        }),
      );

    if (isGuest && payer.email?.value?.length > 0 && !payer.email?.is_required) {
      options.push({
        id: 'email-row',
        title: payer.email?.value,
        icon: payer.email?.is_preference_email ? <UserDefault /> : <IconEmail />,
        color: COLORS.MP_ANDES_BLUE_500,
        onClick: payer.email?.is_preference_email ? null : changeEmail,
        callToAction: !payer.email?.is_preference_email && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        className: `email-card-option email-card-option--${
          payer.email?.is_preference_email ? 'is-not-modifiable' : 'is-modifiable'
        }`,
        isMobile: isMobile(deviceType),
      });
    }

    const paymentMethod = paymentMethods[0];

    const paymentMethodOption = {
      id: `${paymentMethod.id}-row`,
      title: paymentMethod.title,
      description: getPaymentMethodDescription(paymentMethod, deviceType),
      icon:
        paymentMethod.id === PAYMENT_METHOD.SPEI ? (
          <BankIcon />
        ) : (
          getPaymentMethodIcon({
            siteId,
            type: paymentMethod.type,
            issuerName: (paymentMethod.property_type === PAYMENT_METHOD_TYPE.OFFLINE) ? paymentMethod.title : paymentMethod.id,
            issuerID: paymentMethod.issuer_id,
            escapeAccentMark: true,
          })
        ),
      onClick: changePaymentMethod,
      callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
      className: 'payment-method-option',
      bottomContent: getBottomContentComponent({ paymentMethod, translations, isBppFlow }),
      isMobile: isMobile(deviceType),
    };

    options.push(paymentMethodOption);

    if (
      paymentMethod.installment &&
      Object.keys(paymentMethod.installment)?.length > 0 &&
      paymentMethod.installment.details_required
    ) {
      const installmentsOption = {
        id: `installments-quantity-row`,
        title: paymentMethod.installment.total_finance_cost || paymentMethod.installment.title,
        titleColor: (paymentMethod.installment.total_finance_cost?.length) ? 'gray' : 'black',
        titleSize: (paymentMethod.installment.total_finance_cost?.length) ? 'big' : 'normal',
        description: paymentMethod.installment.detail,
        descriptionColor: (paymentMethod.installment.is_interest_free) ? 'green' : 'black',
        icon: <InstallmentsIcon text={`${paymentMethod.installment.quantity}x`} fontWeight="bold" />,
        onClick: paymentMethod.installment?.is_modifiable && changeInstallments,
        callToAction: paymentMethod.installment?.is_modifiable && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        isMobile: isMobile(deviceType)
      };

      options.push(installmentsOption);
    }

    if (payer?.extra_data && Object.keys(payer.extra_data)?.length && payer.extra_data.payer_detail !== '') {
      const extraDataOption = {
        title: getTitleForUserExtraData({ paymentMethod, translations }),
        description: payer.extra_data.payer_detail,
        icon: <IconExtraData />,
        color: COLORS.MP_ANDES_BLUE_500,
        onClick: changeExtraData,
        callToAction: payer?.extra_data?.is_modifiable && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        isMobile: isMobile(deviceType)
      };

      options.push(extraDataOption);
    }

    return options;
  };

  return {
    buildCardList,
  };
};
