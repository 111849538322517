const React = require('react');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const _mercadoPagoSdkActions = require('../../spa/actions/mercadopagoSdk');
const { MERCADOPAGO_SDK_LOADED } = require('../../spa/actions/types');
const { COMMONS: { ENVIROMENT } } = require('../../../constants');
const useScript = require('./hooks/useScript');

const { useEffect } = React;

const MercadoPagoSDK = (props) => {
  const { publicKey, mercadopagoSdk, mercadoPagoSdkActions, siteId } = props;
  const { MercadoPago } = useScript('https://sdk.mercadopago.com/js/v2/', 'MercadoPago', mercadopagoSdk?.loaded);

  useEffect(() => {
    if (MercadoPago && publicKey) {
      mercadoPagoSdkActions[MERCADOPAGO_SDK_LOADED](true);
      window.MercadoPagoSDK = new MercadoPago(publicKey, {siteId});
    }
  }, [publicKey, MercadoPago]);

  return null;
}

/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state) => ({
  mercadopagoSdk: state.mercadopagoSdk,
  publicKey: state.page.data.publicKey,
  siteId: state.configurations.platform.siteId,
});

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  mercadoPagoSdkActions: bindActionCreators(_mercadoPagoSdkActions, dispatch),
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = MercadoPagoSDK;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(MercadoPagoSDK);
}
