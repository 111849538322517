// Bins stored in this whitelist should not ask for the security code
const ALLOWLIST = {
  MLB: ['407843'],
};

const contains = (siteId, bin) => {
  if (siteId && bin) {
    return ALLOWLIST[siteId]?.includes(bin);
  }

  return false;
};

module.exports = {
  contains,
};
