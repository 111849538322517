const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const ArrowHead = ({ color }) => (
  <IconWrapper className="icon-arrow-head">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="10" viewBox="0 0 26 10">
      <path fill={color} fillRule="nonzero" d="M17.037.5c.083 0 .165.02.24.057l7.926 3.963a.537.537 0 0 1 0 .96l-7.926 3.963a.537.537 0 0 1-.777-.48V1.037c0-.297.24-.537.537-.537zM6 4.5a.5.5 0 0 1 0 1H1a.5.5 0 0 1 0-1zm10 0a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1z" />
    </svg>
  </IconWrapper>
);

ArrowHead.propTypes = {
  color: PropTypes.string,
};

ArrowHead.defaultProps = {
  color: '#979797',
};

module.exports = ArrowHead;
