const PropTypes = require('prop-types');

// Andes Components
const { Button } = require('@andes/button');

const CallToActionButtons = (props) => {
  const { backButtonOptions, continueButtonOptions } = props;

  // Show Back Button by default only if the value is undefined or true
  if (backButtonOptions.show === undefined) {
    backButtonOptions.show = true;
  }

  return (
    <div className="button-wrapper">
      {continueButtonOptions && (
        <Button
          hierarchy="loud"
          className="continue_button"
          onClick={continueButtonOptions.action}
          disabled={continueButtonOptions.disabled}
        >
          {continueButtonOptions.label}
        </Button>
      )}

      {backButtonOptions?.show && (
        <Button
          hierarchy={backButtonOptions.hierarchy ?? 'quiet'}
          className="back_button"
          onClick={backButtonOptions.action}
          role="link"
        >
          {backButtonOptions.label}
        </Button>
      )}
    </div>
  );
};

CallToActionButtons.propTypes = {
  backButtonOptions: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  continueButtonOptions: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  }),
};

CallToActionButtons.defaultProps = {
  backButtonOptions: {
    label: '',
    action: () => {},
  },
};

module.exports = CallToActionButtons;
