const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const {INPUT_ID} = require("../../../constants/commons");

class GenericInput extends BaseValidation {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.initialValue || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }


  handleChange(event) {
    const ONLY_NUMERIC_REGEX = /[^0-9]/g;

    if (this.props.id === INPUT_ID.ZIPCODE || this.props.id === INPUT_ID.PHONE_NUMBER) {
      this.setState( { value: event.target.value.replace(ONLY_NUMERIC_REGEX, '')})
    }

    this.onChange(event);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  updateValue(value) {
    // Update the state
    this.setState({
      value,
    });
  }

  // TODO: changing this input rendering control to one that we already have in redux. To centralize this logic
  shouldRender() {
    const { entityType, id } = this.props;
    const isEntityTypeJuridica = entityType === INPUT_ID.ENTITY_TYPE_JURIDIC;
    const isPersonalNameField = id === INPUT_ID.PERSONAL_FIRST_NAME || id === INPUT_ID.PERSONAL_LAST_NAME;

    return !(isEntityTypeJuridica && isPersonalNameField);
  }

  render() {
    const {
      data,
      // Remove Redux values
      step,
      errors,
      validations,
      inputValuesActions,
      initialValue,
      max_length,
      ...inputProps
    } = this.props;

    const shouldRender = this.shouldRender();

    return shouldRender && (
      <BaseInput
        {...inputProps}
        onChange={this.handleChange}
        error={this.state.error}
        invalid={this.state.invalid}
        updateCallback={(value) => { this.updateValue(value); }}
        value={this.state.value}
        maxLength={max_length || data.max_length}
        validations={validations}
        data-testid="input"
      />
    )
  }
}

GenericInput.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  step: PropTypes.string,
  data: PropTypes.shape({
    max_length: PropTypes.number,
  }),
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.object),
  countdown: PropTypes.bool
};

GenericInput.defaultProps = {
  error: [],
  invalid: false,
  step: '',
  data: {
    max_length: 120,
  },
  multiline: false,
  disabled: false,
  countdown: false
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  step: state.page.flow.step,
  entityType: state.inputValues.current[`${state.page.flow.step}_entity_type_label`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = GenericInput;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(GenericInput));
}
