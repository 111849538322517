/**
 * Module dependencies
 */
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const selectOptionsActions = require('../../spa/actions/selectOptions');
const paymentMethodActions = require('../../spa/actions/paymentMethod');
const collapsibleActions = require('../../spa/actions/collapsible');
const creditCardActions = require('../../spa/actions/creditCard');
const inputValuesActions = require('../../spa/actions/inputValues');
const sizeActions = require('../../spa/actions/size');
const CollapsibleSelector = require('../CollapsibleSelector');
const {
  SAVE_OPTIONS,
  SAVE_PAYMENT_METHOD,
  VISIBILITY_INPUT_VALUES,
  COLLAPSIBLE_CLICKED,
} = require('../../spa/actions/types');

/**
 * PaymentOptionsSelector
 */
class PaymentOptionsSelector extends CollapsibleSelector {
  constructor(props) {
    super(props);
    this.setOption = this.setOption.bind(this);
    this.setChildrenVisibility = this.setChildrenVisibility.bind(this);
    this.setInstallments = this.setInstallments.bind(this);
  }

  componentDidMount() {
    this.setChildrenVisibility(this.state.selected);
    this.props.collapsibleActions[COLLAPSIBLE_CLICKED]({ collapsibleId: this.props.id, isOpen: this.state.open });
    this.props.paymentMethodActions[SAVE_PAYMENT_METHOD](this.state.selected.values[0]);
    this.setInstallments(this.state.selected);
    this.resize();
  }

  /**
   * method to only set the payment method for the new card option
   * @param {*} paymentMethod
   */
  setNewCardOption(paymentMethod) {
    this.props.paymentMethodActions[SAVE_PAYMENT_METHOD](paymentMethod.values[0]);
  }

  setOption(paymentMethod) {
    this.toggle();
    this.setInstallments(paymentMethod);
    this.props.paymentMethodActions[SAVE_PAYMENT_METHOD](paymentMethod.values[0]);
    const { options } = this.state;
    options.unshift(this.state.selected);
    this.setState({ options: options.filter(option => option.values[0].id !== paymentMethod.values[0].id), selected: paymentMethod });
    this.setChildrenVisibility(paymentMethod);
  }

  setInstallments(paymentMethod) {
    const payerCosts = paymentMethod.values.length > 0 && paymentMethod.values[0].payer_costs ? paymentMethod.values[0].payer_costs : [];
    this.props.selectOptionsActions[SAVE_OPTIONS](`${this.props.step}_installments_collapsible_selector`, payerCosts);
  }

  setChildrenVisibility(paymentMethod) {
    const inputsVisibility = {};
    const showCvv = paymentMethod.values[0].security_code !== undefined && (paymentMethod.values[0].security_code.length > 0);
    inputsVisibility[`${this.props.step}_cvv`] = showCvv;
    inputsVisibility[`${this.props.step}_icon_cvv`] = showCvv;
    inputsVisibility[`${this.props.step}_payment_options_selector`] = true;
    inputsVisibility[`${this.props.step}_installments_collapsible_selector`] = paymentMethod.values[0].payer_costs && paymentMethod.values[0].payer_costs.length > 0;
    // Installments can be limited to a max of just 1 so we need to consider the case where the length of said array equals 1.
    this.props.inputValuesActions[VISIBILITY_INPUT_VALUES](inputsVisibility);
  }

  getClasses() {
    return 'payment-option-selector';
  }
}

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */

const mapDispatchToProps = dispatch => ({
  selectOptionsActions: bindActionCreators(selectOptionsActions, dispatch),
  paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
  sizeActions: bindActionCreators(sizeActions, dispatch),
  collapsibleActions: bindActionCreators(collapsibleActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, props) => ({
  step: state.page.flow.step,
  flow: state.page.flow,
  show: state.inputValues.visibility[`${state.page.flow.step}_${(props.id)}`],
  paymentMethod: state.paymentMethod,
});

/**
 * Expose OptionList
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = PaymentOptionsSelector;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsSelector);
}
