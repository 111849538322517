const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const Arrow = require('../icons/Back');
const Cross = require('../icons/Cross');
const modalActions = require('../../spa/actions/modal');
const { TOGGLE_MODAL_VISIBILITY, RESET_MODAL_CUSTOM_TITLE } = require('../../spa/actions/types');
const { submitForm } = require('../../utils/Dom');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.handleBackdropClick = this.handleBackdropClick.bind(this);
    this.props.modalActions[RESET_MODAL_CUSTOM_TITLE]();
  }

  componentDidMount() {
    if (this.props.show || this.props.initialVisibility) {
      this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
    }
  }
  
  // This Unmount controls the modal when the user sees the modal on initial view and without closing it goes back to
  // the previous page, so we close it here to not affect other modals.
  componentWillUnmount() {
    if (this.props.show) {
      this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
    }
  }

  handleBackdropClick(event) {
    const { target } = this.props;

    if (!target) {
      return;
    }

    const form = document.querySelector(`#${target}`);
    if (!form || form.tagName !== 'FORM') {
      return;
    }

    event.preventDefault();
    submitForm(form);
    this.close();
  }

  close() {
    this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
  }

  render() {
    const { target } = this.props;

    if (!this.props.show) {
      return null;
    }

    return (
      <div className={`modal-content ${this.props.className}`}>
        {target && (
          <div className="backdrop" onClick={this.handleBackdropClick} />
        )}
        <div className="modal-wrapper">
          <div className="inner-wrapper">
            <div className="header">
              <div className="arrow" onClick={this.close}><Arrow /></div>
              <h3 className="title">{this.props.customTitle || this.props.title}</h3>
              <div className="cross" onClick={this.close}><Cross /></div>
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
};

Modal.defaultProps = {
  content: '',
  title: '',
  target: null,
};

const mapStateToProps = state => ({
  show: state.modal.visible,
  customTitle: state.modal.customTitle,
});

const mapDispatchToProp = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Modal;
} else {
  /* istanbul ignore next: cant test it with tests */module.exports = connect(mapStateToProps, mapDispatchToProp)(Modal);
}
