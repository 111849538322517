const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const Button = require('../../containers/Button/ButtonWithStore');
const modalActions = require('../../spa/actions/modal');
const { submitForm } = require('../../utils/Dom');
const { TOGGLE_MODAL_VISIBILITY } = require('../../spa/actions/types');


const ButtonCloseModal = ({ id, name, text, kind, modalAction, ...props }) => {
  const handleClick = (event) => {
    event.preventDefault();

    modalAction[TOGGLE_MODAL_VISIBILITY]();

    if (event.currentTarget?.form) {
      submitForm(event.currentTarget.form);
    }
  };

  return (
    <Button
      {...props}
      id={id}
      name={name}
      text={text}
      kind={kind}
      onClick={handleClick}
    />
  );
};

ButtonCloseModal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
  modalAction: PropTypes.shape({
    TOGGLE_MODAL_VISIBILITY: PropTypes.func,
  }),
};

ButtonCloseModal.defaultProps = {
  id: '',
  name: '',
  text: '',
  kind: 'link',
  modalAction: {
    TOGGLE_MODAL_VISIBILITY: () => {},
  },
};

const mapDispatchToProp = (dispatch) => ({
  modalAction: bindActionCreators(modalActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonCloseModal;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(null, mapDispatchToProp)(ButtonCloseModal);
}
