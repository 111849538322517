const React = require('react');
const PropTypes = require('prop-types');
const { CodeInput } = require('@andes/textfield');

const InputCode = React.forwardRef((props, ref) => {
  const ariaInvalid = props.modifier === 'error';
  return <CodeInput data-testid="code" {...props} ref={ref} aria-invalid={ariaInvalid} />;
});

InputCode.displayName = 'InputCode';

InputCode.propTypes = {
  name: PropTypes.string,
  focus: PropTypes.bool,
  label: PropTypes.string,
  digits: PropTypes.number,
  helper: PropTypes.string,
  modifier: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

module.exports = InputCode;
