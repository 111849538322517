/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  RESET_DINAMIC_TEXT,
  UPDATE_DINAMIC_TEXT_VALUE,
} = require('./types');

// Request Actions
const resetDinamicText = createAction(RESET_DINAMIC_TEXT);
const updateCustomTextValue = createAction(UPDATE_DINAMIC_TEXT_VALUE);

/**
 * Export functions
 */
module.exports = {
  [RESET_DINAMIC_TEXT]: (reference) => (dispatch) => dispatch(resetDinamicText({ reference })),
  [UPDATE_DINAMIC_TEXT_VALUE]: (reference, value) => (dispatch) => dispatch(updateCustomTextValue({ reference, value })),
};
