const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const ButtonActions = require('../../spa/actions/button');
const { handleWebviewUrls } = require('../../utils/mobileWebkit');
const translate = require('../../translation');
const { BUTTON_REQUEST_TRIGGERED } = require('../../spa/actions/types');
const { isExternalDeepLinkFromBackUrl } = require('../../utils/deeplinks');

class AutoReturn extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      countdown: this.props.countdown,
      animate: false,
    };
    this.translations = translate(i18n);
    this.getProgressLeftStyle = this.getProgressLeftStyle.bind(this);
    this.getProgressRightStyle = this.getProgressRightStyle.bind(this);
  }

  componentDidMount() {
    this.animate();
    const { value: url, buttonRequest, isWebview, buttonActions } = this.props;
    const interval = setInterval(() => {
      this.setState(
        (prevState) => ({ countdown: prevState.countdown - 1 }),
        () => {
          if (this.state.countdown <= 0) {
            // We are making two navigation when the user clicks the button manually,
            // buttonRequest should be true when another navigation was executed.
            if (!buttonRequest && url) {
              buttonActions[BUTTON_REQUEST_TRIGGERED](true);
              if (isWebview) {
                handleWebviewUrls(url);
              } else if (isExternalDeepLinkFromBackUrl(url)) {
                const deeplink_url_to_replace = url.replace('http://', '');
                window.location.replace(deeplink_url_to_replace);
              } else {
                window.top.location.href = url;
              }
            }
            clearInterval(interval);
          }
        },
      );
    }, 1000);
  }

  componentWillUnmount() {
    this.props.buttonActions[BUTTON_REQUEST_TRIGGERED](false);
  }

  getProgressLeftStyle() {
    if (this.state.animate) {
      return {
        animation: `loading ${this.props.countdown / 2}s linear forwards`,
      };
    }

    return {};
  }

  getProgressRightStyle() {
    if (this.state.animate) {
      return {
        animation: `loading ${this.props.countdown / 2}s linear forwards ${this.props.countdown / 2}s`,
      };
    }

    return {};
  }

  animate() {
    this.setState({ animate: true });
  }

  render() {
    return (
      <div className="autoreturn">
        <div className="progress progress__small">
          <span className="progress-left">
            <span style={this.getProgressLeftStyle()} className="progress-bar" />
          </span>
          <span className="progress-right">
            <span style={this.getProgressRightStyle()} className="progress-bar" />
          </span>
        </div>
        <span className="autoreturn-label">
          {this.translations.COUNTDOWN_MESSAGE(this.state.countdown, this.props.merchant)}
        </span>
      </div>
    );
  }
}

AutoReturn.propTypes = {
  value: PropTypes.string.isRequired,
  merchant: PropTypes.string,
  countdown: PropTypes.number,
  isWebview: PropTypes.bool,
  buttonRequest: PropTypes.bool,
  buttonActions: PropTypes.shape({}),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

AutoReturn.defaultProps = {
  value: '',
  merchant: '',
  buttonActions: {},
  countdown: 5,
  isWebview: false,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  buttonActions: bindActionCreators(ButtonActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  merchant: state.configurations.brandName,
  isWebview: state.configurations.isWebview,
  buttonRequest: state.button.buttonRequest,
});

/**
 * Expose AutoReturn
 */
/* istanbul ignore next: cant test it with tests */
if (process.env.NODE_ENV === 'test') {
  module.exports = AutoReturn;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = injectI18n(connect(mapStateToProps, mapDispatchToProps)(AutoReturn));
}
