/**
 * Modules dependencies
 */
const https = require('https');
const AppError = require('./errors/AppError');
const restclient = require('nordic/restclient')({
  timeout: 3000,
  sendCSRFToken: false,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

/**
 * YapeOtpPayment Service
 */
class YapeOtpPayment {
  /**
   * @param {object} data
   * @param {string} publicKey
   *
   * @returns {Promise}
   */
  static async createPaymentYape(payload, publicKey) {
    try {
      const path = `https://api.mercadopago.com/platforms/pci/yape/v1/payment?public_key=${publicKey}`;
      return restclient.post(path, {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      const status = err.response?.status || err.status;
      const dataError = err.response?.data?.error || err;
      throw new AppError(dataError, status);
    }
  }
}

module.exports = YapeOtpPayment;
