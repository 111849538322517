/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { ANIMATION_TOGGLE } = require('../actions/types');

const actions = {};

const initialState = {
  animate: {},
};

actions[ANIMATION_TOGGLE] = (state, action) => {
  const status = {
    animate: { ...state.animate },
  };
  status.animate[action.payload.id] = action.payload.animate;
  return status;
};

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
