/**
 * Modules dependencies
 */
 const restclient = require('nordic/restclient')({
   baseURL: 'https://api.mercadopago.com/v1',
   timeout: 3000,
   sendCSRFToken: false,
 });

 const { DEFAULT_ERRORS } = require('../constants/services');

 /** the same limit that has Flows */
const PAYMENT_METHODS_SEARCH_LIMIT = 300;

 /**
  * Service interface
  */
 class Service {
   /**
    * Get service base path
    */
   static getBasePath() {
     return '/payment_methods/search';
   }

   /**
    * Get PaymentMethods
    * @param publicKey
    * @param data
    * @returns {*|Promise.<TResult>}
    */
   static getPaymentMethods(queryParams) {
     return restclient
       .get(`${this.getBasePath()}?${queryParams}&limit=${PAYMENT_METHODS_SEARCH_LIMIT}`).then(async (response) => {
         let result;
         if (response?.data && Array.isArray(response?.data?.results)) {
             result = Promise.resolve(response?.data?.results);
         } else {
           result = Promise.reject(DEFAULT_ERRORS.INVALID_RESPONSE);
         }
         return result;
       });
   }
 }

 /**
  * Expose Service
  */
 module.exports = Service;
