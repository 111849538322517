const React = require('react');
const PropTypes = require('prop-types');

const IconShoppingBag = ({ title, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="27"
    viewBox="0 0 28 27"
    className="icon-shopping with-custom-color"
  >
    {title && <title>{title}</title>}
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        d="M3.941 26.196h20.17L21.751 7.32a.5.5 0 0 0-.496-.438H6.797a.5.5 0 0 0-.496.438L3.94 26.196z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.712 26.204h26.662M18.336 10.19V4.654c0-5.39-8.62-5.353-8.62 0v5.534"
      />
      <path fill={color} fillOpacity=".4" d="M8.967 15.127H19.12l1.27 7.593H7.696z" />
    </g>
  </svg>
);

IconShoppingBag.defaultProps = {
  title: '',
  color: 'currentColor',
};

IconShoppingBag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

module.exports = React.memo(IconShoppingBag);
