const merge = require('deepmerge');

const { getFormData } = require('./GetFormData');
const { isProcessV2, isNewInterface } = require('./HiddenComponents');
const { CURRENT_STEP } = require('../../constants/app');

class StepUtil {
  // TODO: Need to refactor this - OR remove it with a new way
  /**
   * Convert array into object
   * ['[123]', '[asd]', '[qwe]'] => { 123: { asd: { qwe: 'VALUE' } } }
   * @param arr
   * @param value
   * @param obj
   * @returns {{}}
   */
  static convertArrayToObject(arr, value, obj = {}) {
    const firstElement = arr.shift();
    const cleanKey = firstElement.replace('[', '').replace(']', '');

    if (arr.length > 0) {
      obj[cleanKey] = {};
      this.convertArrayToObject(arr, value, obj[cleanKey]);
    } else {
      obj[cleanKey] = value;
    }

    return obj;
  }

  /**
   * Get Submit and Button elements ignored by the get-form-data library
   * @param form
   * @returns {{}}
   */
  static getSubmitElements(form) {
    const data = {};

    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];

      if ((element.type === 'button' || element.type === 'submit') && !!element.name) {
        data[element.name] = '';
      }
    }

    return data;
  }

  // TODO: Need to refactor this - OR remove it with a new way
  /**
   * Get form values on JSON ready to send to API
   * @param form
   * @returns {{}}
   */
  static getFormValues(form) {
    let formValues = {};

    const elementsConverted = [{}];
    // Get values from form
    const data = { ...getFormData(form), ...this.getSubmitElements(form) };
    const keys = Object.keys(data);

    if (keys.length > 0) {
      keys.forEach((key) => {
        // The name of the name of the elements are going to be wrapped on brackets [name][second_name]
        const matches = key.match(/\[(.*?)\]/g);

        if (matches !== null && Array.isArray(matches)) {
          // Convert the bracket keys into objects
          elementsConverted.push(this.convertArrayToObject(matches, data[key]));
        }
      });

      formValues = merge.all(elementsConverted);
    }

    return formValues;
  }

  /**
   * Extract the values to use depending on the type (Form or Direct object)
   *
   * @param {*} form
   *
   * @returns {*}
   */
  static extractData(data, state = {}) {
    // Use the data directly
    // TODO data?.isNewInterface is a temporal fix until all the steps are using the new architecture
    if (isNewInterface(state.page?.data) || data?.isNewInterface) {
      // Add captchaToken if it was generated
      if (data?.values && state?.captchaToken?.captchaToken?.length > 0) {
        data.values.captchaToken = state.captchaToken.captchaToken;
      }
      return data;
    }
    // Forms (Legacy and ProcessV2)
    const formValues = this.getFormValues(data);
    // Add Form ID (If dataset process is set, pass it as the payload id)
    if (formValues && isProcessV2(state.page?.data?.hidden_components)) {
      formValues.id = data.id;
    }
    return formValues;
  }

  static isCardFormStep = (currentStep) => currentStep === CURRENT_STEP.CARD_FORM;
}

module.exports = StepUtil;
