/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  IDENTIFICATION_CARD_CHANGE,
  IDENTIFICATION_CARD_TYPE,
  IDENTIFICATION_CARD_FOCUS,
  IDENTIFICATION_CARD_BLUR,
  IDENTIFICATION_CARD_CLEAN,
} = require('../actions/types');

const actions = {};

const initialState = {
  number: '',
  type: '',
  type_data: {},
  focus: false,
};

actions[IDENTIFICATION_CARD_CHANGE] = (state, action) => ({
  ...state,
  number: action.payload,
});

actions[IDENTIFICATION_CARD_TYPE] = (state, action) => ({
  ...state,
  type: action.payload,
});

actions[IDENTIFICATION_CARD_FOCUS] = (state) => ({
  ...state,
  focus: true,
});

actions[IDENTIFICATION_CARD_BLUR] = (state) => ({
  ...state,
  focus: false,
});

actions[IDENTIFICATION_CARD_CLEAN] = () => (initialState);

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
