import React from 'react';

import type { TypographyProps } from '@andes/typography';
import { Typography } from '@andes/typography';

// Icons
import ArrowLeft16 from '@andes/icons/ArrowLeft16';

export type BackLinkProps = {
  label: string;
  href: string;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
  size?: Exclude<TypographyProps['size'], 'xl'>;
  color?: TypographyProps['color'];
};

export default function BackLink({
  label,
  href,
  className = '',
  target = '_top',
  size = 's',
  color = 'link',
}: BackLinkProps) {
  return (
    <Typography
      size={size}
      className={`cow-back-link ${className}`}
      component="a"
      color={color}
      href={href}
      target={target}
    >
      <ArrowLeft16 />
      <span className="cow-back-link__label">{label}</span>
    </Typography>
  );
}
