const React = require('react');

const WebpayDebitUnavailable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="105" viewBox="0 0 108 105">
    <defs>
      <filter id="ick8a6g1ya" width="162.3%" height="162.3%" x="-31.2%" y="-23.4%" filterUnits="objectBoundingBox">
        <feOffset dy="6" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="7" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
      </filter>
      <circle id="tidzwbrywb" cx="38.5" cy="38.5" r="38.5" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8)">
                  <use fill="#000" filter="url(#ick8a6g1ya)" href="#tidzwbrywb" />
                  <use fill="#FFF" href="#tidzwbrywb" />
                </g>
                <g>
                  <g>
                    <g>
                      <path fill="#080909" d="M0 3.655L2.424 3.655 4.397 9.58 6.536 3.655 8.01 3.655 10.101 9.508 12.074 3.655 14.498 3.655 10.909 14.071 9.341 14.071 7.249 8.146 5.086 14.071 3.541 14.071z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                      <path fill="#080909" fillRule="nonzero" d="M23.625 9.628h-8.342c.119.74.451 1.337.974 1.767.523.43 1.189.645 1.997.645.974 0 1.806-.334 2.495-1.027h2.52c0 .55-.333 1.027-.333 1.027-.547.789-1.188 1.362-1.949 1.72-.76.383-1.664.55-2.71.55-1.616 0-2.947-.526-3.969-1.553-1.022-1.027-1.52-2.317-1.52-3.87 0-1.577.498-2.915 1.52-3.966 1.022-1.05 2.306-1.576 3.827-1.576 1.64 0 2.947.525 3.97 1.576 1.021 1.051 1.52 2.437 1.52 4.157v.55zm-2.614-2.055c-.167-.573-.5-1.051-1.022-1.41-.5-.358-1.094-.549-1.76-.549-.712 0-1.354.215-1.9.621-.333.263-.666.693-.951 1.338h5.633z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                      <path fill="#080909" fillRule="nonzero" d="M25.978.215v4.587c.476-.478.975-.836 1.521-1.051.547-.24 1.141-.359 1.76-.359 1.354 0 2.495.502 3.446 1.53.95 1.027 1.426 2.34 1.426 3.941 0 1.553-.5 2.843-1.474 3.894-.974 1.051-2.14 1.553-3.47 1.553-.594 0-1.14-.096-1.664-.31-.523-.216-1.022-.574-1.521-1.052v1.1h-2.59V.19h2.566v.024zm2.734 5.59c-.809 0-1.498.287-2.044.836-.547.55-.809 1.29-.809 2.198 0 .932.262 1.672.809 2.246.546.573 1.212.86 2.044.86.784 0 1.45-.287 1.996-.86.547-.574.808-1.314.808-2.222 0-.884-.261-1.624-.808-2.198-.523-.573-1.188-.86-1.996-.86z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                      <path fill="#E6A730" fillRule="nonzero" d="M6.108 13.928v1.123c.452-.454.951-.813 1.474-1.028.523-.239 1.093-.334 1.711-.334 1.307 0 2.448.502 3.352 1.505.926 1.003 1.378 2.293 1.378 3.846 0 1.505-.475 2.771-1.426 3.799-.95 1.027-2.092 1.529-3.399 1.529-.57 0-1.117-.096-1.616-.311-.5-.215-.998-.55-1.497-1.027v4.443H3.589V13.952h2.52v-.024zM8.77 16.03c-.808 0-1.45.263-1.996.812-.523.55-.785 1.266-.785 2.15 0 .908.262 1.625.785 2.198.523.55 1.188.836 1.996.836.785 0 1.426-.286 1.95-.86.522-.573.784-1.29.784-2.174 0-.86-.262-1.576-.785-2.15-.499-.55-1.14-.812-1.949-.812z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                      <path fill="#E6A730" fillRule="nonzero" d="M21.819 13.928h2.21V24.08h-2.21v-1.075c-.523.478-1.046.812-1.569 1.027-.523.215-1.093.31-1.711.31-1.379 0-2.567-.501-3.589-1.528-.998-1.028-1.521-2.294-1.521-3.799 0-1.576.499-2.843 1.473-3.846.975-1.003 2.163-1.505 3.566-1.505.641 0 1.236.12 1.806.334.57.24 1.093.574 1.569 1.028v-1.1h-.024zM18.99 16.03c-.832 0-1.522.287-2.068.836-.547.55-.832 1.266-.832 2.15 0 .884.285 1.6.832 2.174.57.574 1.236.86 2.068.86.831 0 1.544-.286 2.091-.836.547-.55.832-1.29.832-2.198 0-.884-.285-1.6-.832-2.15-.547-.573-1.236-.836-2.091-.836z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                      <path fill="#E6A730" d="M24.029 13.928L26.62 13.928 29.234 20.306 32.134 13.928 34.725 13.928 28.403 27.784 25.788 27.784 27.856 23.316z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                    </g>
                    <path fill="#000" fillOpacity=".45" fillRule="nonzero" d="M5.131 38.5c2.277 0 3.762-1.573 3.762-3.663 0-2.079-1.485-3.674-3.762-3.674H2.623V38.5h2.508zm0-.814H3.537v-5.709h1.595c1.816 0 2.816 1.276 2.816 2.86 0 1.573-1.034 2.849-2.816 2.849zm7.47-5.302l1.858-1.584h-.857l-1.629 1.584h.627zm.087 6.248c.87 0 1.585-.286 2.112-.814l-.396-.539c-.418.429-1.034.671-1.639.671-1.144 0-1.848-.836-1.914-1.837h4.301v-.209c0-1.595-.945-2.849-2.551-2.849-1.519 0-2.619 1.243-2.619 2.783 0 1.661 1.134 2.794 2.707 2.794zm1.65-3.124h-3.486c.043-.814.604-1.771 1.737-1.771 1.21 0 1.739.979 1.75 1.771zm4.764 3.124c1.386 0 2.364-1.056 2.364-2.783 0-1.694-.979-2.794-2.364-2.794-.727 0-1.364.352-1.794.935v-2.827h-.825V38.5h.825v-.792c.396.539 1.046.924 1.794.924zm-.21-.737c-.648 0-1.297-.374-1.584-.836V34.65c.287-.462.936-.858 1.584-.858 1.079 0 1.716.88 1.716 2.057s-.637 2.046-1.716 2.046zm4.323-5.467c.308 0 .561-.242.561-.55 0-.308-.253-.561-.56-.561-.297 0-.55.253-.55.561 0 .308.253.55.55.55zm.419 6.072v-5.313h-.826V38.5h.826zm2.991.132c.485 0 .782-.143.99-.341l-.241-.616c-.11.121-.33.22-.561.22-.363 0-.54-.286-.54-.682v-3.3h1.079v-.726h-1.079v-1.452h-.825v1.452h-.88v.726h.88v3.476c0 .792.396 1.243 1.177 1.243zm4.213 0c1.617 0 2.63-1.254 2.63-2.794s-1.013-2.783-2.63-2.783-2.629 1.243-2.629 2.783c0 1.54 1.012 2.794 2.63 2.794zm0-.737c-1.133 0-1.77-.968-1.77-2.057 0-1.078.637-2.046 1.77-2.046s1.76.968 1.76 2.046c0 1.089-.627 2.057-1.76 2.057z" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(16.5 16.5) translate(4.125 1.375)" />
                  </g>
                </g>
              </g>
              <g>
                <path fill="#F23D4F" d="M16.5 0C7.402 0 0 7.402 0 16.5 0 25.598 7.402 33 16.5 33 25.598 33 33 25.598 33 16.5 33 7.402 25.598 0 16.5 0" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(60.5 44)" />
                <g stroke="#FFF" strokeLinecap="round" strokeWidth="1.8">
                  <path d="M7.674 0.665L7.674 14.761" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(60.5 44) rotate(-45 23.396 1.162)" />
                  <path d="M7.713 0.397L7.713 14.493" transform="translate(-142 -237) translate(44 237) translate(98) translate(14 8) translate(60.5 44) rotate(-45 23.396 1.162) rotate(-90 7.713 7.445)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

module.exports = WebpayDebitUnavailable;
