const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const creditCardActions = require('../../spa/actions/creditCard');
const {
  CREDIT_CARD_PC_NAME,
} = require('../../spa/actions/types');

class Fullname extends BaseValidation {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.initialValue || '',
      step: props.step,
    };

    this.updateValue = this.updateValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  updateValue(value) {
    // Dispatch action for updating the PaymentCard component
    this.props.creditCardActions[CREDIT_CARD_PC_NAME](value);

    // Update the state
    this.setState({
      value,
    });
  }

  onChange(event) {
    this.updateValue(event.target.value);
  }

  render() {
    const className = 'input-fullname';

    const {
      error,
      invalid,
      errors,
      validations,
      showErrorMessage,
      validateCallback,
      data,
      // Remove the ones coming from Redux
      inputValuesActions, // eslint-disable-line
      creditCardActions, // eslint-disable-line
      step,
      globalErrors,
      shouldSaveValue,
      initialValue,
      placeholder,
      message,
      ...inputProps
    } = this.props;

    return (
      <BaseInput
        {...inputProps}
        className={className}
        name={this.props.name}
        type="text"
        error={this.state.error}
        invalid={this.state.invalid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.state.value}
        updateCallback={(value) => { this.updateValue(value); }}
        autoComplete="cc-name"
        autoCorrect="no"
        autoCapitalize="no"
        spellCheck="no"
        placeholder={placeholder}
        message={message}
      />
    );
  }
}

Fullname.propTypes = {
  id: PropTypes.string,
  step: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  deviceType: PropTypes.string,
  initialValue: PropTypes.string,
  inputValuesActions: PropTypes.object, // eslint-disable-line
  creditCardActions: PropTypes.object, // eslint-disable-line
  globalErrors: PropTypes.object, // eslint-disable-line
  shouldSaveValue: PropTypes.bool,
  placeholder: PropTypes.string,
  message: PropTypes.string,
};

Fullname.defaultProps = {
  id: '',
  name: '',
  step: '',
  label: '',
  value: '',
  invalid: false,
  error: [],
  deviceType: 'desktop',
  initialValue: '',
  inputValuesActions: {},
  creditCardActions: {},
  globalErrors: {},
  shouldSaveValue: true,
  placeholder: '',
  message: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  step: state.page.flow.step,
  globalErrors: state.globalErrors, // If this component support globalErrors
  value: state.inputValues.current[`${state.page.flow.step}_fullname`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Fullname;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(Fullname)); // eslint-disable-line max-len
}
