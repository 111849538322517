const React = require('react');

module.exports = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={52}
    height={38}
    viewBox="0 0 52 38"
  >
    <defs>
      <linearGradient
        id="c"
        x1="26.143%"
        x2="81.378%"
        y1="69.531%"
        y2="34.266%"
      >
        <stop offset="0%" stopColor="#73A299" />
        <stop offset="100%" stopColor="#A8C5B2" />
      </linearGradient>
      <path
        id="amex_path_a"
        d="M3.797 0h30a3 3 0 0 1 3 3v16.5a3 3 0 0 1-3 3h-30a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
      />
      <filter
        id="amex_filter_b"
        width="127.8%"
        height="144.4%"
        x="-13.9%"
        y="-17.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <linearGradient
        id="e"
        x1="16.442%"
        x2="39.22%"
        y1="60.553%"
        y2="93.464%"
      >
        <stop offset="0%" stopColor="#FFF" stopOpacity=".525" />
        <stop offset="100%" stopColor="#7B7B7B" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <circle id="f" cx="11.836" cy="11.836" r="11.836" />
      <filter
        id="g"
        width="188.6%"
        height="188.6%"
        x="-44.3%"
        y="-35.9%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1.991"
          result="shadowSpreadOuter1"
        />
        <feOffset
          dy={2}
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        />
        <feMorphology
          in="SourceAlpha"
          radius={1}
          result="shadowInner"
        />
        <feOffset dy={2} in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
      </filter>
      <linearGradient
        id="k"
        x1="26.143%"
        x2="81.378%"
        y1="68.578%"
        y2="35.034%"
      >
        <stop offset="0%" stopColor="#73A299" />
        <stop offset="100%" stopColor="#A8C5B2" />
      </linearGradient>
      <path
        id="i"
        d="M0 4.927A4.923 4.923 0 0 1 4.916 0H120.48a4.92 4.92 0 0 1 4.916 4.927v66.582a4.923 4.923 0 0 1-4.916 4.927H4.916A4.92 4.92 0 0 1 0 71.51V4.927z"
      />
      <filter
        id="j"
        width="108%"
        height="113.1%"
        x="-4%"
        y="-5.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1.5"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <linearGradient
        id="m"
        x1="15.243%"
        x2="38.834%"
        y1="60.553%"
        y2="93.464%"
      >
        <stop offset="0%" stopColor="#FFF" stopOpacity=".525" />
        <stop offset="100%" stopColor="#7B7B7B" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(5.203 5.75)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#amex_path_a" />
        </mask>
        <use fill="#000" filter="url(#amex_filter_b)" xlinkHref="#amex_path_a" />
        <use fill="#306fc5" xlinkHref="#amex_path_a" />
        <ellipse
          cx="11.372"
          cy="24.375"
          fill="#1C1C52"
          fillOpacity=".6"
          mask="url(#d)"
          opacity=".2"
          rx="22.257"
          ry="22.875"
        />
        <rect
          width={9}
          height={2}
          x={4}
          y={16}
          fill="#FFF"
          fillOpacity=".3"
          fillRule="nonzero"
          mask="url(#d)"
          rx={1}
        />
        <rect
          width={8}
          height={2}
          x={4}
          y={4}
          fill="#FFF"
          fillOpacity=".3"
          fillRule="nonzero"
          mask="url(#d)"
          rx={1}
        />
        <rect
          width={14}
          height={2}
          x={4}
          y={12}
          fill="#FFF"
          fillOpacity=".3"
          fillRule="nonzero"
          mask="url(#d)"
          rx={1}
        />
        <ellipse
          cx="29.615"
          cy="-4.125"
          fill="#142858"
          fillOpacity=".87"
          stroke="url(#e)"
          strokeWidth=".5"
          mask="url(#d)"
          opacity=".103"
          rx="22.257"
          ry="22.875"
        />
      </g>
      <g transform="translate(21 5)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#f" />
        </mask>
        <use fill="#000" filter="url(#g)" xlinkHref="#f" />
        <circle
          cx="11.836"
          cy="11.836"
          r="12.831"
          stroke="#FFF"
          strokeWidth="1.991"
        />
        <g mask="url(#h)">
          <g transform="translate(-90.991 -28.79)">
            <mask id="l" fill="#fff">
              <use xlinkHref="#i" />
            </mask>
            <use fill="#000" filter="url(#j)" xlinkHref="#i" />
            <use fill="#306fc5" xlinkHref="#i" />
            <ellipse
              cx="31.619"
              cy="83.192"
              fill="#29295F"
              fillOpacity=".596"
              mask="url(#l)"
              opacity=".103"
              rx="77.516"
              ry="78.283"
            />
            <ellipse
              cx="95.365"
              cy="-12.348"
              fill="#1A3A83"
              fillOpacity=".869"
              stroke="url(#m)"
              strokeWidth=".72"
              mask="url(#l)"
              opacity=".103"
              rx="77.516"
              ry="78.283"
            />
          </g>
        </g>
      </g>
      <text
        fill="#FFF"
        fontFamily="RobotoMono-Medium, Roboto Mono"
        fontSize={8}
        fontWeight={400}
        transform="translate(21 5)"
      >
        <tspan x="2.355" y={14}>
          {props.text}
        </tspan>
      </text>
    </g>
  </svg>
);
