/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const classNames = require('classnames');

const IconChevron = require('../icons/BlueChevron');
const IconQuestionMark = require('../icons/QuestionMark');
const IconWithTooltip = require('../IconWithTooltip');
const { SummaryListWithCombination: SummaryList } = require('../../containers/SummaryList');
const RowCollapsible = require('../RowCollapsible');
const CONSTANTS = require('../../../constants');
const { isMobile } = require('../../utils/webview');

class StickySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapsible: false,
      arrowTransitioning: false,
      scrollPosition: 'docked',
    };

    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.displaySummary = this.displaySummary.bind(this);
    this.userScrolled = this.userScrolled.bind(this);
    this.showInstallmentsLabel = this.showInstallmentsLabel.bind(this);
    this.getExtraInfo = this.getExtraInfo.bind(this);
    this.renderExtraInfo = this.renderExtraInfo.bind(this);
    this.renderChevron = this.renderChevron.bind(this);
    this.observer = null;
  }

  componentDidMount() {
    // check the scroll position to add box-shadow to the summary
    window.addEventListener('scroll', this.userScrolled);

    const targetNode = document.querySelector(CONSTANTS.SELECTORS.DROPDOWN_NAVBAR);

    if (isMobile(this.props.configs?.deviceType) && targetNode) {
      const mutationCallback = (mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            this.state.showCollapsible && document.querySelector(CONSTANTS.SELECTORS.POPPER) && this.displaySummary();
          }
        }
      };

      const observerOptions = {
        childList: true,
      };

      this.observer = new MutationObserver(mutationCallback);
      this.observer.observe(targetNode, observerOptions);
    }
  }

  componentWillUnmount() {
    // remmove handler from global
    window.removeEventListener('scroll', this.userScrolled);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  getExtraInfo() {
    return this.props.collapsibleValues && this.props.collapsibleValues.filter((row) => row.should_display)[0];
  }

  displaySummary() {
    this.setState((prevState) => ({ showCollapsible: !prevState.showCollapsible &&  prevState, arrowTransitioning: true }));
    setTimeout(() => this.setState({ arrowTransitioning: false }), CONSTANTS.COMMONS.DELAY.EIGHT_MILLISECONDS);
  }

  /**
   *
   * @param {string} type
   * @param {string} installmentLabel
   *
   * Should show installments label if type is price, installmentLabel is not null,
   * kind is pay or kind is coupon and only if it has no collapsible values
   */
  showInstallmentsLabel(type, installmentLabel) {
    const { kind } = this.props;

    return (kind === 'pay' || kind === 'coupon' || kind === 'pay-combined') && type === 'price' && installmentLabel;
  }

  /**
   * check if the user has scrolled between 0 and x number
   * if the scroll value is higher the user is scrolling
   * else the summary is docked
   */
  userScrolled() {
    const scrollPosition = window.scrollY > 30 ? 'transitioning' : 'docked';
    this.setState({ scrollPosition });
  }

  renderRow({ type: elementType, text, extra_label: extraLabel, dynamic, installments = '', value, items }) {
    if (elementType === CONSTANTS.APP.STICKY_SUMMARY.ICON_QUESTION_MARK) {
      return <IconQuestionMark key={Math.random()} />;
    }

    if (elementType === CONSTANTS.APP.STICKY_SUMMARY.SEPARATOR) {
      return <hr className="sticky-summary__separator-row" key={Math.random()} />;
    }

    let label = text;
    let extraLabelInfo = extraLabel;

    if (extraLabelInfo === 'first_payment' && dynamic) {
      label = this.props.splitValueFirstMethod || text;
      extraLabelInfo = null;
    } else if (dynamic && this.props.installment.label) {
      label = this.props.installment.label;
    }

    return (
      <div
        className={classNames(`sticky-summary__${elementType} u-truncate`, {
          'with-label': !!extraLabelInfo,
          dynamic: !!dynamic,
          combination: !!this.props.isCombination,
        })}
        key={Math.random()}
      >
        <span className="text">{label}</span>
        {this.props.isCombination && !!installments && <span className="installments">{installments}</span>}
        <span className="extra-label">{extraLabelInfo}</span>
      </div>
    );
  }

  renderCollapsible() {
    const { showCollapsible } = this.state;
    const { collapsibleValues } = this.props;

    return (
      <div className={`collapsible-summary ${showCollapsible ? 'open' : 'hidden'}`}>
        {collapsibleValues.map(({ type, value, id }) => {
          if (type === CONSTANTS.APP.STICKY_SUMMARY.SUMMARY_LIST_COMBINATION) {
            return <SummaryList key={Math.random()} deviceType={this.props.deviceType} value={value} />;
          }

          if (type === CONSTANTS.APP.STICKY_SUMMARY.ROW_COLLAPSIBLE) {
            return (
              <RowCollapsible
                showChevron={false}
                id={id}
                value={value}
                deviceType={CONSTANTS.COMMONS.DEVICE_TYPE.MOBILE}
              />
            );
          }

          return (
            <div className={type} key={Math.random()}>
              {value.map((props) => {
                switch (props.type) {
                  case 'icon_with_tooltip':
                    return (
                      <IconWithTooltip icon={props.icon} text={props.text} title={props.title} key={Math.random()} />
                    );

                  default:
                    return this.renderRow(props);
                }
              })}
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * When we have a row with should_display in collapsible values, we need to display this
   * above the row that display the final amount that the payer will pay
   */
  renderExtraInfo() {
    const { arrowTransitioning } = this.state;
    const extraInfo = this.getExtraInfo();

    if (extraInfo && Array.isArray(extraInfo.value)) {
      const isDiscount = extraInfo.type === 'row_discount';
      const extraText = extraInfo.value.filter((value) => value.type === 'text')[0];
      const extraPrice = extraInfo.value.filter((value) => value.type === 'price')[0];

      if (extraText && extraPrice) {
        return (
          <div
            className={classNames('sticky-summary__row-container extra-info', {
              discount: isDiscount,
              animate: arrowTransitioning,
            })}
          >
            <span className="sticky-summary__text u-truncate" key={Math.random()}>
              {extraText.text}
              {extraText.extra_label && <span className="extra-label">{extraText.extra_label}</span>}
            </span>
            <span className="sticky-summary__price u-truncate" key={Math.random()}>
              {extraPrice.text}
            </span>
          </div>
        );
      }
    }

    return null;
  }

  renderChevron() {
    return (
      <button
        type="button"
        className={classNames('chevron-button-wrapper', { transitioning: this.state.arrowTransitioning })}
        onClick={() => this.displaySummary()}
        aria-expanded={false}
        aria-label={this.state.showCollapsible ? 'open' : 'close'}
      >
        <span className={this.state.showCollapsible ? 'up' : 'down'}>
          <IconChevron />
        </span>
      </button>
    );
  }

  render() {
    const { showCollapsible, scrollPosition } = this.state;
    const {
      kind,
      installment,
      isCollapsible,
      label,
      value,
      splitValueFirstMethod,
      installmentSelected,
      installmentCreditsSelected,
      initialValue,
    } = this.props;
    const isOpen = isCollapsible && showCollapsible;

    let installmentLabel = '';

    if (kind === 'pay' || kind === 'coupon') {
      installmentLabel = installment.label;
    }

    const shouldRenderForCombination = splitValueFirstMethod && (installmentSelected || installmentCreditsSelected);
    if (kind === 'pay-combined') {
      installmentLabel = shouldRenderForCombination ? installment.totalPurchase : initialValue;
    }

    return (
      <>
        <div
          className={classNames({
            'sticky-summary': true,
            collapsible: isCollapsible,
            open: isOpen,
            closed: !isOpen,
            [`sticky-summary__${scrollPosition}`]: true,
            coupon: kind === 'coupon',
            'extra-info': this.getExtraInfo(),
          })}
        >
          <span className={`collapsible-title ${showCollapsible ? 'open' : 'hidden'}`}>{label}</span>
          {this.renderCollapsible()}
          {kind === 'coupon' && !showCollapsible && this.renderExtraInfo()}
          <div className="sticky-summary__row-container">
            {value.map(({ type, text }) => {
              const renderInstallmentLabel = this.showInstallmentsLabel(type, installmentLabel);
              return (
                <span className={`sticky-summary__${type} u-truncate`} key={Math.random()}>
                  {renderInstallmentLabel ? installmentLabel : text}
                </span>
              );
            })}
          </div>
          {this.renderChevron()}
        </div>
        <div className={`overlay-${showCollapsible ? 'visible' : 'invisible'}`} onClick={()=> this.displaySummary()} />
      </>
    );
  }
}

/**
 * Prop Types
 */
StickySummary.propTypes = {
  installment: PropTypes.shape({
    label: PropTypes.string,
    totalPurchase: PropTypes.string,
  }),
  kind: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  isCollapsible: PropTypes.bool,
  collapsibleValues: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.array,
    }),
  ),
  isCombination: PropTypes.bool,
  deviceType: PropTypes.string,
  configs: PropTypes.object,
};

/**
 * Default Props
 */
StickySummary.defaultProps = {
  installment: {
    label: '',
  },
  label: '',
  kind: '',
  value: [],
  isCollapsible: false,
  collapsibleValues: [],
  isCombination: false,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  installment: state.installment,
  splitValueFirstMethod: state.inputValues.current[`${state.page.flow.step}_split_amount_first_method`],
  initialValue: state.inputValues.current[`${state.page.flow.step}_split_amount_initial_value`],
  installmentSelected: !!state.inputValues.current[`${state.page.flow.step}_installments_select_express`],
  installmentCreditsSelected: !!state.inputValues.current[`${state.page.flow.step}_installments_select_credits`],
  configs: state.configurations,
});
/**
 * Expose StickySummary
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = StickySummary;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(StickySummary);
}
