const PropTypes = require('prop-types');

// Constants
const {
  CHECKOUT_CASE: { PAYMENT, SUBSCRIPTION },
} = require('../../constants/commons');

const newInterfaceGenericDefaultValues = {
  transaction_type: '',
  step_title: '',
  experiment: '',
  payer: {
    name: '',
    email: {},
    avatar: '',
    is_guest: false,
  },
  summary: {},
  payment_methods: [],
  subscription: {},
  main_action: '',
  secure_with_captcha: '',
  payment_method_with_regulation: '',
  incentives: {},
  shipping: {},
  discount: {
    is_applicable: false,
    detail: null
  },
  notification: {},
  notifications: {
    zero_dollar: null,
    enable_online_payments: {
      status: '',
      message: '',
    },
  },
  navigation: {
    back_urls: {},
  },
};

const newInterfaceGenericPropsTypes = {
  transaction_type: PropTypes.oneOf([PAYMENT, SUBSCRIPTION]),
  step_title: PropTypes.string,
  experiment: PropTypes.string,
  payer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.shape,
    avatar: PropTypes.string,
    is_guest: PropTypes.bool,
  }),
  summary: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.string,
        type: PropTypes.string,
        detail: PropTypes.string,
      }),
    ),
    total: PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.string,
    }),
    instructions: PropTypes.string,
    pay_secure: PropTypes.bool,
  }),
  main_action: PropTypes.string,
  payment_methods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      detail: PropTypes.string,
      issuerName: PropTypes.string,
      installments: PropTypes.shape({
        quantity: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ).isRequired,
  subscription: PropTypes.shape({
    free_trial: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    validation: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  incentives: PropTypes.shape({
    credits: PropTypes.shape({
      description: PropTypes.string,
      interest_text: PropTypes.string,
    }),
    is_bpp_flow: PropTypes.bool,
    is_secure_payment: PropTypes.bool,
  }),
  discount: PropTypes.shape({
    is_applicable: PropTypes.bool,
    detail: PropTypes.string,
  }).isRequired,
  // notify: PropTypes.array,
  secure_with_captcha: PropTypes.string,
  payment_method_with_regulation: PropTypes.string,
  actions: PropTypes.array,
  shipping: PropTypes.shape({
    is_pickup: PropTypes.bool,
    delivery: PropTypes.shape({
      address: PropTypes.shape({
        street: PropTypes.string,
        details: PropTypes.string,
        contact: PropTypes.string,
      }),
      arrival: PropTypes.string,
      price: PropTypes.string,
    }),
  }),
  notification: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
  }),
  notifications:
    PropTypes.shape({
      zero_dollar: PropTypes.any,
      enable_online_payments: PropTypes.shape({
        status: PropTypes.string,
        message: PropTypes.string,
      }),
    }),
  navigation: PropTypes.shape({
    back_urls: PropTypes.shape({
      failure: PropTypes.string,
      pending: PropTypes.string,
      success: PropTypes.string,
    }),
  })
};

const genericDefaultValues = {
  stepTitle: '',
  currentStep: '',
  urls: {},
  trackingPath: '',
  analytics: {},
  deviceType: '',
  flow: {},
  history: null,
  siteId: '',
  i18n: {},
};

const genericPropsTypes = {
  stepTitle: PropTypes.string,
  currentStep: PropTypes.string,
  urls: PropTypes.object,
  trackingPath: PropTypes.string,
  analytics: PropTypes.object,
  deviceType: PropTypes.string,
  flow: PropTypes.object,
  history: PropTypes.object,
  stepActions: PropTypes.object,
  i18n: PropTypes.shape({ gettext: PropTypes.func }),
  siteId: PropTypes.string,
};

module.exports = {
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
  genericPropsTypes,
  genericDefaultValues,
};
