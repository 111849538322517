/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { BUTTON_CLICK_TRIGGERED, CLEAN_BUTTON_ACTIONS, BUTTON_REQUEST_TRIGGERED } = require('../actions/types');

const actions = {};

const initialState = {
  buttonClicked: {},
  buttonRequest: false,
};

actions[BUTTON_CLICK_TRIGGERED] = (state, action) => {
  const newState = {
    ...state,
    buttonClicked: { id: action.payload.buttonId },
  };
  return newState;
};

actions[CLEAN_BUTTON_ACTIONS] = state => ({
  ...state,
  buttonClicked: initialState.buttonClicked,
});

actions[BUTTON_REQUEST_TRIGGERED] = (state, action) => {
  const newState = {
    ...state,
    buttonRequest: action.payload,
  };
  return newState;
};

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
