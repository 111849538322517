const React = require('react');

const IconWrapper = require('../IconWrapper');

const Combination = () => (
  <IconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.15741 16.6638H4.61818C3.72448 16.6638 3 15.9517 3 15.0733V6.59052C3 5.7121 3.72448 5 4.61818 5H17.5636C18.4573 5 19.1818 5.7121 19.1818 6.59052V7.12066H18.1028V6.59055C18.1028 6.29775 17.8613 6.06038 17.5634 6.06038H4.61798C4.32008 6.06038 4.07858 6.29775 4.07858 6.59055V15.0733C4.07858 15.3661 4.32008 15.6035 4.61798 15.6035H5.15741V16.6638Z"
        fill="black"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7213 7.12061H6.77589C5.8822 7.12061 5.15771 7.8327 5.15771 8.71112V10.3016V13.4827V17.1939C5.15771 18.0723 5.8822 18.7844 6.77589 18.7844H19.7213C20.615 18.7844 21.3395 18.0723 21.3395 17.1939V13.4827V10.3016V8.71112C21.3395 7.8327 20.615 7.12061 19.7213 7.12061ZM6.23672 12.4223V11.362H20.2609V12.4223H6.23672ZM6.23672 10.3016H20.2609V8.7112C20.2609 8.41839 20.0195 8.18103 19.7216 8.18103H6.77612C6.47822 8.18103 6.23672 8.41839 6.23672 8.7112V10.3016ZM6.23672 13.4827H20.2609V17.1939C20.2609 17.4867 20.0195 17.7241 19.7216 17.7241H6.77612C6.47822 17.7241 6.23672 17.4867 6.23672 17.1939V13.4827ZM10.55 16.6637H7.31579V15.6033H10.55V16.6637Z"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Combination);
