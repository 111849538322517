const { SUBMIT_FORM_ID } = require('../../constants/app');

/**
 * ChallengeConfig function that builds login challenge configuration
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param redirectUrl - desired url to use a return url from challenge
 * @param components - step components
 * @param hiddenComponents - step hidden_components
 */
const challenge3DSConfig = async (challengeRawData, configuration, redirectUrl, components, hiddenComponents) => {
  const challengeURL = hiddenComponents.find(({ id }) => id === 'redirect_to');

  return Promise.resolve({
    challengeUrl: challengeURL?.value,
    submitFormId: SUBMIT_FORM_ID.THREEDS_FORM,
    popupSetup: {
      width: 500,
      height: 600,
      completeOnPostmessage: true,
    },
  });
};

module.exports.challenge3DSConfig = challenge3DSConfig;
