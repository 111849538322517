const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');

const CaptchaTerms = props => {
  const { i18n } = props;
  const translations = translate(i18n);
  return (
    <div className={`captcha-terms ${props.logged ? 'logged' : 'guest'}`}>
      <p>
        <span>{translations.PROTECTED_BY_RECAPTCHA}</span>
        <span> - </span>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translations.PRIVACY}
        </a>
        <span> - </span>
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translations.CONDITIONS}
        </a>
      </p>
    </div>
  )
};

CaptchaTerms.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  logged: PropTypes.bool,
};

CaptchaTerms.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  logged: false,
};

if (process.env.NODE_ENV === 'test') {
  module.exports = CaptchaTerms;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(CaptchaTerms);
}
