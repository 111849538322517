/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const Markdown = require('../Markdown');

/**
 * This is just a simple component that takes a value from the store and shows it inside a <p> tag
 * Actual: reading `installment` value from store and using `installment.detail` as value to show
 * NTH/Technical debt/TODO: make this component agnostic of which key from store to render and receive it as prop,
 * in order to make a generic _show dynamic text_ component
 */
const InstallmentDetail = ({ installment, kind }) => {
  const shouldRender = Boolean(installment && installment.detail);

  return shouldRender ? (
    <div className={`installment-detail ${kind}`}>
      <p className={`installment-detail__content ${kind}`}>
        <Markdown text={installment.detail} />
      </p>
    </div>
  ) : null;
};

/**
 * Prop Types
 */
InstallmentDetail.propTypes = {
  installment: PropTypes.shape({
    detail: PropTypes.string,
  }),
  kind: PropTypes.string,
};

/**
 * Default Props
 */
InstallmentDetail.defaultProps = {
  installment: {},
  kind: '',
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  installment: state.installment,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InstallmentDetail;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(InstallmentDetail);
}
