const React = require('react');

const IconWrapper = require('../IconWrapper');

const CashWithDollar = () => (
  <IconWrapper>
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.929199 0.495117H16.3247V11.5051H0.929199V0.495117ZM1.95284 1.59609V10.4058H15.2956V1.59609H1.95284ZM7.02651 6.93898L6.44696 7.81029C6.83633 8.23438 7.38291 8.54613 8.11292 8.63776V9.29992H9.13929V8.6157C10.1083 8.43406 10.5791 7.77352 10.5791 6.93898C10.5791 5.7215 9.52664 5.45485 8.69893 5.24515L8.69744 5.24477L8.68249 5.24099C8.1471 5.10552 7.69639 4.99148 7.69639 4.64777C7.69639 4.32506 7.9523 4.10723 8.41142 4.10723C8.92324 4.10723 9.46516 4.29279 9.8716 4.69617L10.4662 3.85714C10.1068 3.50107 9.66068 3.26747 9.13929 3.16553V2.55474H8.11292V3.13651C7.16805 3.28303 6.60502 3.95408 6.60502 4.74458C6.60502 5.95436 7.63058 6.2062 8.44591 6.40642L8.47916 6.41458L8.52033 6.42545C9.05172 6.56564 9.49526 6.68266 9.49526 7.06806C9.49526 7.37463 9.20925 7.66507 8.62217 7.66507C7.92972 7.66507 7.38027 7.3343 7.02651 6.93898ZM13.0179 7.09928C13.5848 7.09928 14.0443 6.60674 14.0443 5.99915C14.0443 5.39156 13.5848 4.89901 13.0179 4.89901C12.4511 4.89901 11.9916 5.39156 11.9916 5.99915C11.9916 6.60674 12.4511 7.09928 13.0179 7.09928ZM5.33426 5.99915C5.33426 6.60674 4.87474 7.09928 4.30789 7.09928C3.74104 7.09928 3.28152 6.60674 3.28152 5.99915C3.28152 5.39156 3.74104 4.89901 4.30789 4.89901C4.87474 4.89901 5.33426 5.39156 5.33426 5.99915Z"
        fill="#009EE3"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(CashWithDollar);
