const React = require('react');

const IconWrapper = require('../IconWrapper');

const Discount = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g fill="none" fillRule="evenodd" transform="translate(-.14 -5.13)">
        <path
          fill="currentColor"
          fillOpacity=".4"
          d="M15.622 11.085l3.854-3.854a3 3 0 0 1 2.48-.857l9.461 1.137a3 3 0 0 1 2.627 2.675l.938 9.215a3 3 0 0 1-.863 2.426l-3.9 3.9-14.597-14.642z"
        />
        <path
          stroke="currentColor"
          d="M2.615 24.747a2.5 2.5 0 0 0 0 3.536l10.377 10.376a2.5 2.5 0 0 0 3.535 0l17.338-17.338a2.5 2.5 0 0 0 .72-2.02l-.939-9.216a2.5 2.5 0 0 0-2.189-2.23l-9.461-1.137a2.5 2.5 0 0 0-2.067.715L2.615 24.747z"
        />
        <circle
          cx="27.393"
          cy="13.882"
          r="2.327"
          fill="#FFF"
          stroke="currentColor"
          transform="rotate(-45 27.393 13.882)"
        />
        <g stroke="currentColor" transform="translate(10.1 21.156)">
          <circle cx="1.964" cy="2.339" r="1.964" fill="#FFF" />
          <circle cx="8.93" cy="6.708" r="1.964" fill="#FFF" />
          <path strokeLinecap="round" d="M8.93.295L1.964 8.628" />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Discount);
