const { SUBMIT_FORM_ID } = require('../../constants/app');
const { isRedirectTo } = require('./HiddenComponents');

/**
 * ChallengeConfig function that builds facetec challenge configuration
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param redirectUrl - desired url to use a return url from challenge
 * @param components - step components
 * @param hiddenComponents - step hidden_components
 */
const challengeFacetecConfig = async (challengeRawData, configuration, redirectUrl, components, hiddenComponents) => {
  const challengeURL = isRedirectTo(hiddenComponents);

  return Promise.resolve({
    challengeUrl: challengeURL,
    submitFormId: SUBMIT_FORM_ID.FACETEC_FORM,
    popupSetup: {
      width: 500,
      height: 600,
      completeOnPostmessage: true,
    },
  });
};

module.exports.challengeFacetecConfig = challengeFacetecConfig;
