const React = require('react');

const IconWrapper = require('../IconWrapper');

const Anchor3DS = () => (
  <IconWrapper className="icon-3ds-anchor">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="223"
      height="144"
      fill="none"
      viewBox="0 0 223 144"
    >
      <path
        fill="#000"
        fillOpacity="0.04"
        fillRule="evenodd"
        d="M191.311 143.806c28.876-23.409 34.369-58.277 28.836-80.615C210.729 25.161 175.905 2.914 152.353.4c-17.211-1.84-37.305 2.588-49.81 14.923-13.732 13.538-24.808 21.706-40.41 26.355C47.237 46.122 22.43 46.601 8.166 65.74c-9.55 12.814-10.558 41.956-.495 58.892 7.323 12.327 25.363 18.807 25.946 19.174H191.31z"
        clipRule="evenodd"
       />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M26.154 34.37H196.749V131.007H26.154z"
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M25.016 42.114v-9.48H197.89V105.8M25.016 46.223v85.805H197.89v-14.929m0-7.19v3.081"
       />
      <path
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        d="M25.016 29.017a6.163 6.163 0 016.163-6.163h160.579a6.164 6.164 0 016.163 6.163v3.945H25.016v-3.945z"
       />
      <path
        fill="#fff"
        d="M11.22 132.211c0 6.09 4.938 11.028 11.028 11.028H200.69c6.091 0 11.028-4.938 11.028-11.028H11.221z"
       />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M22.703 140.124H200.24c4.917 0 9.076-2.647 10.456-6.29h.486l-.004.246c-.16 4.818-4.995 8.684-10.938 8.684H22.703c-6.043 0-10.941-3.998-10.941-8.93h.486c1.38 3.643 5.539 6.29 10.455 6.29z"
        clipRule="evenodd"
        style={{ mixBlendMode: "multiply" }}
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M11.22 132.211c0 6.09 4.938 11.028 11.028 11.028H200.69c6.091 0 11.028-4.938 11.028-11.028H11.221z"
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M92.484 132.211a3.677 3.677 0 003.679 3.676h30.612a3.678 3.678 0 003.679-3.676h-37.97z"
        clipRule="evenodd"
       />
      <ellipse
        cx="111.964"
        cy="28.387"
        fill="#333"
        rx="1.542"
        ry="1.541"
       />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M138.929 95.457V99.708c0 2.686-2.171 4.864-4.848 4.864H74.229a4.858 4.858 0 01-4.85-4.864V62.566c0-2.688 2.172-4.866 4.85-4.866h59.852c2.677 0 4.848 2.178 4.848 4.866v32.891z"
        clipRule="evenodd"
       />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M69.379 96.999c0 2.165 2.106 3.927 4.727 3.981l.123.001h59.852c2.636 0 4.781-1.728 4.847-3.88l.001-.102v2.897c0 2.687-2.171 4.865-4.848 4.865H74.229a4.858 4.858 0 01-4.85-4.865V97z"
        clipRule="evenodd"
        style={{ mixBlendMode: "multiply" }}
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M138.929 95.457V99.708c0 2.686-2.171 4.864-4.848 4.864H74.229a4.858 4.858 0 01-4.85-4.864V62.566c0-2.688 2.172-4.866 4.85-4.866h59.852c2.677 0 4.848 2.178 4.848 4.866v32.891z"
        clipRule="evenodd"
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M78.719 86.292h8.733M92.53 86.292h8.732M78.639 92.718h26.875"
       />
      <rect
        width="7.784"
        height="4.56"
        x="80.219"
        y="69.946"
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        rx="2.28"
       />
      <path stroke="#333" strokeWidth="1.5" d="M109.965 92.718h5.875" />
      <rect
        width="10.784"
        height="7.56"
        x="78.719"
        y="68.446"
        stroke="#333"
        strokeWidth="1.5"
        rx="3.78"
       />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M106.34 86.292h8.733M120.15 86.292h8.733"
       />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M150.361 98.61c0 7.065-5.846 12.841-13.119 12.841s-13.119-5.776-13.119-12.84c0-7.065 5.846-12.84 13.119-12.84s13.119 5.775 13.119 12.84z"
       />
      <ellipse
        cx="137.138"
        cy="98.611"
        stroke="#333"
        strokeWidth="1.5"
        rx="14.619"
        ry="14.34"
       />
      <path
        stroke="#009EE3"
        strokeWidth="1.5"
        d="M129.963 99.029l4.365 4.453 9.277-9.463"
       />
    </svg>
  </IconWrapper>
);

module.exports = Anchor3DS;
