const React = require('react');
const PropTypes = require('prop-types');

const IconErrorBadge = ({ title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" className="icon-error-badge">
    {title
      && <title>{title}</title>}
    <g fill="none" fillRule="evenodd">
      <circle fill="#D12440" cx="15" cy="15" r="15" />
      <path d="M7.5 7.417h15V22.25h-15z" opacity="0.3" />
      <g fill="#D8D8D8" stroke="#FFF" strokeLinecap="round" strokeWidth="1.9">
        <path d="M11.454 11.489l7.975 7.975" />
        <path strokeLinejoin="round" d="M19.429 11.489l-7.975 7.975 7.975-7.975z" />
      </g>
    </g>
  </svg>
);

IconErrorBadge.defaultProps = {
  title: '',
};

IconErrorBadge.propTypes = {
  title: PropTypes.string,
};

module.exports = IconErrorBadge;
