/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const IconsInstallments = require('../IconInstallments');

const IconsInstallmentsDynamic = (props) => {
  let textToShow = '0x';
  let storedText = props.expressText || props.text;
  if (typeof storedText !== 'string') {
    storedText = storedText.toString();
  }

  if (storedText) {
    const installmentsAmount = storedText.match(/(\d+)/g);
    textToShow = `${installmentsAmount}x`;
  }
  return (
    <IconsInstallments text={textToShow} />
  );
};

IconsInstallmentsDynamic.propTypes = {
  text: PropTypes.string.isRequired,
  expressText: PropTypes.string.isRequired,
};

IconsInstallmentsDynamic.defaultProps = {
  className: '',
  text: '',
  expressText: '',
};

const mapStateToProps = state => ({
  text: (state.installment || {}).value,
  expressText: state.inputValues.current[`${state.page.flow.step}_installments_select_express`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = IconsInstallmentsDynamic;
} /* istanbul ignore next: cant test it with tests */ else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(IconsInstallmentsDynamic);
}
