const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = ({ children, className, id, onClick }) => {
  let classNames = 'icon-wrapper';

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} id={id} onClick={onClick} aria-hidden="true">
      {children}
    </div>
  );
};

IconWrapper.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

IconWrapper.defaultProps = {
  id: '',
  className: '',
};

module.exports = IconWrapper;
