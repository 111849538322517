/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  FETCH_PAYMENT_METHODS,
} = require('../actions/types');

const actions = {};

const initialState = []

/**
 * this reducer will store the pm search response into the store for split payments
 * @TODO analyze the posibility to do this cross steps replacing the pm wrapper with the pm search response
 * @param {*} state
 * @param {*} action
 * @returns
 */
actions[FETCH_PAYMENT_METHODS] = (state, action) => (action.payload || []);

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
