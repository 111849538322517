const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const Mobile = ({ text }) => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g fill="currentColor">
        <path d="M7 7.726h20.94v-1.61H7z" />
        <path d="M7 3.697a2.416 2.416 0 012.416-2.416h16.108a2.416 2.416 0 012.416 2.416v28.995a2.416 2.416 0 01-2.416 2.416H9.416A2.417 2.417 0 017 32.691V3.697zm1.61 0v28.995c0 .444.361.805.806.805h16.108c.445 0 .806-.36.806-.805V3.697a.805.805 0 00-.806-.805H9.416a.804.804 0 00-.805.805z" />
        <path d="M15.054 31.886h4.833v-1.611h-4.833zm-8.052-3.217h20.936v-1.611H7.002z" />
        <text
          fill="currentColor"
          fontFamily="RobotoMono-Medium, Roboto Mono"
          fontSize="8"
          fontWeight="400"
          transform="translate(20 9)"
        >
          <tspan x="-9.50" y="12.636">
            {text}
          </tspan>
        </text>
      </g>
    </svg>
  </IconWrapper>
);

Mobile.propTypes = {
  text: PropTypes.string,
};

Mobile.defaultProps = {
  text: '',
};

module.exports = React.memo(Mobile);
