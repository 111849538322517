const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const Button = require('../../containers/Button/ButtonWithStore');
const ChallengeOneClick = require('../../utils/ChallengeOneClick');
const { isIframe } = require('../../utils/Dom');


const BlockedPopupFallback = ({ id, text, description, ctaLabel, url, value, blockedPopup, flowId, flowType }) => {

  let formRef = null;

  const handleClick = (event) => {
    event.preventDefault();

    if (formRef) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      ChallengeOneClick.open(formRef, 'oneclick', flowId, flowType);
    }
  };

  const isRedirectInsideIframe = () => flowType === 'redirect' && isIframe();

  // We shouldn't display this fallback if not blocked popups occurred or for normal redirect.
  if (!blockedPopup && (flowType !== 'modal' || !isRedirectInsideIframe())) {
    return null;
  }

  return (
    <div className="blockedpopup-fallback">
      <form ref={(form) => { formRef = form; }} action={url} method="post">
        <input type="hidden" name="TBK_TOKEN" value={value} />
      </form>
      <h2 className="title-h2">
        <span>{text}</span>
      </h2>
      <h2 className="title-h3">
        <span>{description}</span>
      </h2>
      <Button
        id={id}
        text={ctaLabel}
        className="blockedpopup-fallback__cta"
        onClick={handleClick}
      />
    </div>
  );
};

/**
 * Prop Types
 */
BlockedPopupFallback.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  flowId: PropTypes.string,
  flowType: PropTypes.string,
  blockedPopup: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

/**
 * Default Props
 */
BlockedPopupFallback.defaultProps = {
  id: '',
  text: '',
  url: '',
  value: '',
  description: '',
  ctaLabel: '',
  flowId: '',
  flowType: '',
  blockedPopup: false,
  i18n: {
    gettext: t => t,
  },
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  blockedPopup: state.transitionRedirect.blockedPopup,
  flowId: state.page.flow.id,
  flowType: state.page.flow.type,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = BlockedPopupFallback;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(BlockedPopupFallback);
}
