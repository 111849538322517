class PublicKeyUtil {
  /**
   * Check if the public key is from test
   * @param publicKey
   * @returns {*|boolean}
   */
  static isTestKey(publicKey) {
    let isTestKey = false;

    if (publicKey) {
      isTestKey = publicKey.startsWith('TEST');
    }

    return isTestKey;
  }
}

module.exports = PublicKeyUtil;
