const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const InputAmountCard = require('../../components/InputAmountCard');
const translate = require('../../translation');

const InputAmountCardWithCombination = (props) => {
  const { i18n } = props;
  const translations = translate(i18n);
  return <InputAmountCard {...props} labelInputSplitAmount={translations.AMOUNT_TO_PAY_WITH_THIS_MEANS} />;
};

InputAmountCardWithCombination.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

module.exports = injectI18n(InputAmountCardWithCombination);
