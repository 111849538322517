const React = require('react');

const IconWrapper = require('../IconWrapper');

const Check = () => (
  <IconWrapper className="icon-check">
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.78786 5.32694L8.36451 0.75029L9.39303 1.77881L3.78786 7.38398L0.606934 4.20305L1.63545 3.17453L3.78786 5.32694Z"
        fill="#009EE3"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Check);
