const React = require('react');
const PropTypes = require('prop-types');

const { Accordion } = require('@andes/accordion');
const { List, ListItem } = require('@andes/list');

const IconWithTooltip = require('../IconWithTooltip');

const SummaryList = ({
  value,
  displayAccordion,
  deviceType,
  tooltipText,
  tooltipSide,
  tooltipIcon,
  tooltipType,
}) => {
  const getTitle = () => {
    if (value.length === 0 && displayAccordion) {
      return '';
    }
    const { text, value: price } = value[0];

    return (
      <List>
        <ListItem title={text} rightContent={price} />
      </List>
    );
  };

  const renderText = (text, showTooltip) => (
    <span className="secondary-text">
      {text}
      {showTooltip ? (
        <IconWithTooltip
          deviceType={deviceType}
          icon={tooltipIcon}
          side={tooltipSide}
          text={tooltipText}
          type={tooltipType}
        />
      ) : null}
    </span>
  );

  const getContent = () => {
    if (value.length <= 1 && displayAccordion) {
      return '';
    }
    const list = [...value];
    
    if(displayAccordion){
      list.shift();
    }

    return (
      <List>
        {list.map((item) => (
          <ListItem
            key={Math.random()}
            title={renderText(item.text, item.tooltip)}
            rightContent={<span className="secondary-text">{item.value}</span>}
          />
        ))}
      </List>
    );
  };

  const generateAccordion = () => (
    <div className="summary-list-accordion" id="summary-list-accordion">
      <Accordion title={getTitle()} showDivider={false}>
        {getContent()}
      </Accordion>
    </div>
  );

  const generateList = () => (
    <div className="summary-list" id="summary-list">
      {getContent()}
    </div>
  )

  const renderComponent = () => {
    if (displayAccordion) {
      return generateAccordion();
    }

    return generateList();
  };

  return renderComponent();
};

SummaryList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      tooltip: PropTypes.bool,
    }),
  ),
};

SummaryList.defaultProps = {
  value: [],
  displayAccordion: false,
  deviceType: 'desktop',
  tooltipText: '',
  tooltipSide: 'right',
  tooltipIcon: 'info',
  tooltipType: 'light',
}

module.exports = SummaryList;
