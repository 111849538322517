const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconSplitPaymentCard = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="19.5" fill="#FFF" stroke="#000" strokeOpacity="0.07" transform="translate(-8 -11)" />
        <g fill="currentColor">
          <path
            d="M18.667.667a2 2 0 012 2v.666h-1.334v-.666A.666.666 0 0018.667
            2h-16A.666.666 0 002 2.667v10.666c0 .369.298.667.667.667h.666v1.333h-.666a2
            2 0 01-2-2V2.667a2 2 0 012-2h16z"
          />
          <path
            d="M21.334 3.333a2 2 0 012 2V16a2 2 0 01-2 2h-16a2 2 0 01-2-2V5.333a2 2
            0 012-2h16zm.666 8H4.667V16c0 .368.298.667.667.667h16A.667.667 0 0022 16v-4.667zM9.999
            14v1.333H6.001V14h3.998zM22 8.667H4.667V10H22V8.667zm-.666-4h-16a.666.666 0
            00-.667.666v2H22v-2a.666.666 0 00-.666-.666z"
          />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(IconSplitPaymentCard);
