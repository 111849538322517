import React from 'react';

const QR = ({ width = 25, height = 25, ...props }: SVGProps) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75781 3C5.75836 1.75742 6.76556 0.750667 8.00776 0.750667L17.0038 0.75C18.2465 0.75 19.2538 1.75736 19.2538 3V21C19.2538 22.2426 18.2465 23.25 17.0038 23.25L8.00781 23.2493C6.76556 23.2493 5.75836 22.2426 5.75781 21V3ZM17.7538 5.25V18.752H7.25781L7.25781 5.25H17.7538ZM7.25781 20.252V20.9997C7.258 21.4137 7.59373 21.7493 8.00787 21.7493L17.0038 21.75C17.418 21.75 17.7538 21.4142 17.7538 21V20.252H7.25781ZM17.7538 3.75H7.25781V3.00019C7.25807 2.58618 7.59378 2.25067 8.00781 2.25067L17.0039 2.25C17.418 2.25 17.7538 2.58579 17.7538 3V3.75Z"
      fill="currentColor"
    />
  </svg>
);

export default React.memo(QR);
