const React = require('react');
const PropTypes = require('prop-types');

const Tag = require('@andes/tag');

const IconUserPill = require('../icons/UserPill');

const UserPill = (props) => (
  <div className="user-pill">
    <Tag asset={{ icon: <IconUserPill /> }} label={props.label} />
  </div>
);


UserPill.propTypes = {
  label: PropTypes.string,
};

UserPill.defaultProps = {
  label: '',
};

module.exports = UserPill;
