/**
 * References
 */
const React = require('react');

const inputs = require('./references/input.references');
const groups = require('./references/group.references');
const buttons = require('./references/button.references');
const selects = require('./references/select.references');
const visuals = require('./references/visual.references');
const forms = require('./references/forms.references');
const GroupList = require('../../components/Groups/GroupList');
const GroupZipcodeFinder = require('../../components/Groups/GroupZipcodeFinder');

/**
 * UI Components Type => Transform Props and Return => Component
 */
const references = {
  ...inputs,
  ...groups,
  ...buttons,
  ...selects,
  ...visuals,
  ...forms,
};

// WARNING: Temporary hack until andes radio button list is available
references.group_list = (props) => {
  const { deviceType, name, ...listProps } = props;
  return <GroupList {...listProps} inputName={name} deviceType={deviceType}>{props.children}</GroupList>;
};

references.group_zipcode_finder = props => (
  <GroupZipcodeFinder
    primaryLabel={props.primary_label}
    secondaryLabel={props.secondary_label}
    name={props.name}
    deviceType={props.device_type}
  >
    {props.children}
  </GroupZipcodeFinder>
);

/**
 * Return the correct Component for the Type specified
 * @param type
 */
exports.get = type => references[type];
