/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');
const uuidv4 = require('uuid/v4');

const { waitMs } = require('../../utils/animations');
const {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  CHUNK_LOADED,
  LOADER_STOP_ANIMATION,
} = require('./types');

// Request Actions
const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);
const loaderStopAnimation = createAction(LOADER_STOP_ANIMATION);

/**
 * Export functions
 */
module.exports = {
  [CHUNK_LOADED]: (params, urlParams, component, history) => (dispatch, getState) => {
    const requestId = uuidv4();
    const currentState = getState();

    dispatch(requestStarted({ requestId }));

    component.preload().then(() => {
      if (currentState.request.loadingStartAnimation) {
        dispatch(loaderStopAnimation({}));
        return waitMs();
      }
      return Promise.resolve();
    }).then(() => {
      dispatch(requestFinished({ requestId }));
    }).catch(() => {
      dispatch(requestFinished({ requestId }));
      // Redirect to exception page
      history.push(`/${params.type}/exception/?${urlParams}`);
    });
  },
};
