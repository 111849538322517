const React = require('react');
const CardList = require('@cow/core-components/components/CardListV2');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');
const useNavigationBack = require('../../../../../hooks/useNavigationBack');

const PaidWithCashSection = ({ onNavigationBack, deviceType, children, onClickMethod }) => {
  useNavigationBack(onNavigationBack);

  return (
    <CardList
      idList="pay-with-cash"
      withDividers
      deviceType={deviceType}
      items={children.map((paymentMethod) => ({
        ...paymentMethod,
        method: paymentMethod.id,
        withChevron: true,
        onClick: () => {
          onClickMethod(paymentMethod);
        },
      }))}
    />
  );
};

PaidWithCashSection.propTypes = {
  onNavigationBack: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  onClickMethod: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      payment_method_id: PropTypes.string,
      payment_type_id: PropTypes.string,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      next_step: PropTypes.string,
      icon_uitype: PropTypes.string,
      header: PropTypes.string,
      accreditation_time: PropTypes.number,
      status: PropTypes.string,
      financial_institutions: PropTypes.arrayOf(PropTypes.object),
      balance: PropTypes.number,
      children: PropTypes.arrayOf(PropTypes.object),
      max_allowed_amount: PropTypes.number,
      min_allowed_amount: PropTypes.number,
    }),
  ),
};

module.exports = injectI18n(PaidWithCashSection);
