/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  TOGGLE_MODAL_VISIBILITY,
  RESET_MODAL_CUSTOM_TITLE,
  UPDATE_MODAL_CUSTOM_TITLE,
} = require('../actions/types');

const actions = {};

const initialState = {
  visible: false,
  customTitle: null,
};

actions[TOGGLE_MODAL_VISIBILITY] = (state, action) => ({
  ...state,
  visible: action.payload.visible,
});

actions[RESET_MODAL_CUSTOM_TITLE] = (state) => ({
  ...state,
  customTitle: null,
});

actions[UPDATE_MODAL_CUSTOM_TITLE] = (state, action) => ({
  ...state,
  customTitle: action.payload.title,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
