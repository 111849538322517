const currency = require('./currency');

const BIN_LENGTH = {
  SIX: 6,
  EIGHT: 8,
};

class PaymentMethodUtils {
  /**
   * @param {Array} data - Payment Method search response
   * @param {*} settings -
   * @param {Int} settings.decimal_places - rounding decimals count for results
   * @param {Int} settings.symbol - currency symbol used for parsing amount
   * @param {Int} settings.thousands_separator - symbol used as thousand's amount separator
   * @param {Int} settings.decimal_separator - symbol used as decimal's amount separator
   * @param {Int} settings.max_installments - max installments allowed, to filter payer_costs
   */
  constructor(data = [], settings = {}) {
    this._data = data;
    this._settings = {
      ...settings,
      max_installments: settings.max_installments || 0,
      decimal_places: settings.decimal_places || 2,
    };
  }

  /**
   * Get a list of all payment methods
   */
  getAll() {
    return this._data || [];
  }

  /**
   * Get a list of all payment methods related to a bin number
   * @param {String} bin - first six-digits number from card
   */
  getAllByBin(bin = '') {
    const binLength = this._calculateBinLength(bin, this.getAll());
    const formattedBin = bin.substring(0, binLength);

    if (formattedBin) {
      let filteredPMS = this._filterByBin(this.getAll(), formattedBin);

      if (filteredPMS.length === 0) {
        filteredPMS = this._matchByBinSettings(this.getAll(), formattedBin);
      }

      return filteredPMS;
    }

    return [];
  }

  /**
   * Get the payment method related to a bin number and a payment type
   * @param {String} bin - first six-digits number from card
   * @param {String} paymentTypeId - payment type to filter
   * @param {String} amount - amount to pay, required to filter installments options
   */
  getPaymentMethod(bin, paymentTypeId = '', amount = 0) {
    let filteredPMS = this.getAllByBin(bin);

    if (paymentTypeId) {
      filteredPMS = this._filterByPaymentType(filteredPMS, paymentTypeId);
    }

    const defaultPMS = this._filterPaymentMethodsByDefault(filteredPMS);
    return this._preparePaymentMethod(defaultPMS, bin, amount);
  }

  /**
   * Get an object with the minimun & maximum amount allowed corresponding
   * to the initial data given.
   * @param {Integer} installments {optional} - specific number of installments to know the amount range
   * @return {Object} - i.e.: { minimun: Number, maximum: Number}
   */
  getAllowedAmountRange(installments = 0) {
    let minimum = Number.MAX_VALUE;
    let maximum = 0;

    this.getAll().forEach((pm) => {
      pm.payer_costs.forEach((pc) => {
        if (installments === 0 || pc.installments === installments) {
          minimum = minimum > pc.min_allowed_amount ? pc.min_allowed_amount : minimum;
          maximum = maximum < pc.max_allowed_amount ? pc.max_allowed_amount : maximum;
        }
      });
    });

    return { minimum, maximum };
  }

  /**
   * Calculates the length of the bin, based on a bin.
   * Logic: bin eight are added to each payment method bins array, so check if the bin is in bins array,
   * otherwise assumes that it's a six digits length bin by default.
   * @returns {number} bin Length 6 or 8.
   */
  _calculateBinLength(bin, paymentMethods) {
    if (!bin) {
      return BIN_LENGTH.SIX;
    }

    if (typeof bin !== 'string' && typeof bin !== 'number') {
      return BIN_LENGTH.SIX;
    }

    if (bin.length < 8) {
      return BIN_LENGTH.SIX;
    }

    const bin8 = +bin.toString().substring(0, BIN_LENGTH.EIGHT);

    if (paymentMethods.some(({ bins }) => Array.isArray(bins) && bins.includes(bin8))) {
      return BIN_LENGTH.EIGHT;
    }

    return BIN_LENGTH.SIX;
  }

  /**
   * @private
   * Filter all payment methods by an specific bin number
   */
  _filterByBin(pms, bin) {
    return pms.filter((pm) => pm?.bins?.indexOf(parseInt(bin, 10)) >= 0);
  }

  /**
   * @private
   * Return all payment methods that matches with inclussion/exclussion rules for an specific bin.
   * Each returned pm may contain multiple settings, in which case the matched one will be at position '0'.
   */
  _matchByBinSettings(pms, bin) {
    return pms.filter((pm) => {
      const settingFound = pm.settings.find((setting) => {
        const binSetting = setting ? setting.bin : null;

        if (
          binSetting &&
          /** Bin Inclussion rules */
          binSetting.pattern &&
          !!bin.match(binSetting.pattern) &&
          /** Bin Exclussion rules */
          (!binSetting.exclusion_pattern || !bin.match(setting.bin.exclusion_pattern))
        ) {
          return true;
        }

        return false;
      });

      this._prioritizeSetting(pm, settingFound);

      return settingFound;
    });
  }

  /**
   * @private
   * PM's Settings sorter. This is used to set first a particular setting.
   * This is required when a pm contains more than one possible setting (i.e. bin 501080)
   */
  _prioritizeSetting(pm, setting) {
    if (setting && pm.settings.length > 1) {
      pm.settings.sort((setting1, setting2) => {
        if (setting1 !== setting2) {
          return setting1 === setting ? -1 : 1;
        }

        return 0;
      });
    }
  }

  /**
   * @private
   * Filter payment methods for an specific payment type id
   * Obs: If you want to support more than one pm, you should modify this function
   */
  _filterByPaymentType(pms, paymentTypeId = '') {
    return pms.filter((pm) => paymentTypeId === pm.payment_type_id);
  }

  /**
   * @private
   * Get the default payment methods
   * These are filtered by default property or id
   * Exception: If there is more than one id, the search is invalid
   */
  _filterPaymentMethodsByDefault(pms) {
    // Check if default payment method is set and return it
    const defaultPMS = pms.filter((pm) => pm.issuer && pm.issuer.default);

    if (defaultPMS && defaultPMS.length === 1) {
      return defaultPMS;
    }

    // Check if multiple payment method ids exists
    const ids = [];

    pms.forEach((pm) => {
      if (!ids.includes(pm.id)) {
        ids.push(pm.id);
      }
    });

    if (ids.length > 1) {
      throw new Error('More than 1 payment_method resolved');
    }

    return pms;
  }

  /**
   * @private
   * Construct an usable object with the payment method information and
   * all asociated issuers, with their individual installments plan
   */
  _preparePaymentMethod(pms, bin, amount) {
    if (!pms.length) {
      return null;
    }

    const pm = this._buildPM(pms[0]);

    pm.issuers = this._buildIssuers(pms, bin, amount);

    return pm;
  }

  /**
   * @private
   */
  _buildPM(pm) {
    // added payment_method_id to support card form step pms model
    return {
      id: pm.id || pm.payment_method_id,
      name: pm.name,
      payment_type_id: pm.payment_type_id,
      settings: pm.settings,
      issuers: [],
      deferred_capture: pm?.deferred_capture,
    };
  }

  /**
   * @private
   */
  _buildIssuers(pms, bin, amount) {
    // filter pm that doesn't have issuer (card form step) to avoid errors
    return pms
      .filter((pm) => pm.issuer)
      .map((pm) => {
        const { issuer } = pm;

        issuer.thumbnail = pm.thumbnail;
        issuer.secure_thumbnail = pm.secure_thumbnail;
        issuer.payer_costs = this._buildInstallments(pm, bin, amount);

        return issuer;
      });
  }

  /**
   * @private
   */
  _buildInstallments(pm, bin, amount) {
    let payerCosts = pm.payer_costs;

    const installmentsLimitation = this._existsInstallmentsForBin(pm, bin) ? this._settings.max_installments : 1;

    if (installmentsLimitation) {
      payerCosts = payerCosts.filter((pc) => pc.installments <= installmentsLimitation);
    }

    return this._buildPayerCostsWithAmounts(payerCosts, amount);
  }

  /**
   * @private
   * Check if the bin number given matches with the payment method installment pattern
   */
  _existsInstallmentsForBin(pm, bin) {
    if (bin && !!pm.settings) {
      const matches = pm.settings.filter((setting) => {
        let iPattern = setting.bin ? setting.bin.installments_pattern : null;
        iPattern = iPattern ? new RegExp(iPattern) : null; //eslint-disable-line

        return !iPattern || iPattern.test(bin);
      });

      return !!matches.length; // If any setting match, no installments allowed
    }

    return true; // If no setting, default true
  }

  /**
   * @private
   * Constructs a new list of the payerCosts given with valid installments and values calculated
   */
  _buildPayerCostsWithAmounts(payerCosts, totalAmount) {
    if (!totalAmount) {
      return payerCosts;
    }

    return payerCosts
      .map((pc) => {
        const installmentAmount = (totalAmount * (1 + pc.installment_rate / 100)) / pc.installments;
        const taxedTotalAmount = pc.installment_rate > 0 ? installmentAmount * pc.installments : totalAmount;

        return {
          ...pc,
          installment_amount: currency.round(installmentAmount, this._settings.decimal_places),
          taxed_total_amount: taxedTotalAmount,
        };
      })
      .filter((pc) => {
        const validOperation = pc.min_allowed_amount <= totalAmount && pc.max_allowed_amount >= pc.taxed_total_amount;

        if (validOperation) {
          this._buildPayerCostMessage(pc);
        }

        return validOperation;
      });
  }

  /**
   * @private
   * Modifies installments message with the calculated values
   * Template vars info:
   * inst_amt - value of each amount
   * tot_amt - value of financing total
   */
  _buildPayerCostMessage(payerCost = {}) {
    let { message } = payerCost;

    if (message) {
      const installmentAmount = currency.parseAmount(payerCost.installment_amount, this._settings);
      const totalAmount = currency.parseAmount(payerCost.taxed_total_amount, this._settings);

      message = message.replace('inst_amt', installmentAmount).replace('tot_amt', totalAmount);
      payerCost.message = message;
    }
  }
}

module.exports = PaymentMethodUtils;
