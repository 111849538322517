const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const creditCardActions = require('../../spa/actions/creditCard');
const { CREDIT_CARD_PC_EXPIRATION } = require('../../spa/actions/types');

class InputExpirationDate extends BaseValidation {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: '',
      step: props.step,
    };
    this.updateValue = this.updateValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateValue(nextProps.value);
  }

  updateValue(value) {
    const targetValue = value;
    const cleanTargetValue = targetValue.replace(/[^0-9]/g, ''); // To test Autofill
    // Clean the value from other characters and only get the first 4 numbers
    let cleanExpirationDate = targetValue.replace(/[^0-9]/g, '').slice(0, 4);

    if ((cleanTargetValue.length === 6 || cleanTargetValue.length === 5) && /20[0-9]{2}$/.test(cleanTargetValue)) {
      if (cleanTargetValue.length === 6) {
        cleanExpirationDate = [cleanTargetValue.slice(0, 2), '/', cleanTargetValue.slice(4, 6)].join('');
      } else {
        cleanExpirationDate = ['0', cleanTargetValue.slice(0, 1), '/', cleanTargetValue.slice(3, 5)].join('');
      }
    } else {
      cleanExpirationDate = cleanExpirationDate
        // group numbers in two groups of two numbers then separate the grpups with a /
        .replace(/(\d{2})/g, '$&/')
        // remove any extra / at the end of the string
        .replace(/\/$/g, '');
    }

    // Dispatch action for updating the PaymentCard component
    this.props.creditCardActions[CREDIT_CARD_PC_EXPIRATION](cleanExpirationDate);

    // Update the state
    this.setState({
      value: cleanExpirationDate,
    });
  }

  onChange(event) {
    this.updateValue(event.target.value);
  }

  /**
   * Override the base one to replace the / with -
   * @returns {string}
   */
  getComponentValue() {
    return this.state.value.replace('/', '-');
  }

  render() {
    const className = 'input-expiration-date';

    const {
      error,
      invalid,
      errors,
      validations,
      showErrorMessage,
      validateCallback,
      data,
      // Remove the ones coming from Redux
      inputValuesActions,
      creditCardActions,
      step,
      globalErrors,
      ...inputProps
    } = this.props;

    return (
      <BaseInput
        {...inputProps}
        className={className}
        name={this.props.name}
        type="tel"
        label={this.props.label}
        placeholder={this.props.placeholder}
        error={this.state.error}
        invalid={this.state.invalid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.state.value}
        updateCallback={(value) => { this.updateValue(value); }}
        autoComplete="cc-exp"
        autoCorrect="no"
        autoCapitalize="no"
        spellCheck="no"
        maxLength="5"
      />
    );
  }
}

InputExpirationDate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  step: PropTypes.string,
  inputValuesActions: PropTypes.object, // eslint-disable-line
  creditCardActions: PropTypes.object, // eslint-disable-line
  globalErrors: PropTypes.object, // eslint-disable-line
  shouldSaveValue: PropTypes.bool,
};

InputExpirationDate.defaultProps = {
  id: '',
  name: '',
  value: '',
  label: '',
  placeholder: 'MM/AA',
  invalid: false,
  error: [],
  step: '',
  globalErrors: {},
  shouldSaveValue: true,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  step: state.page.flow.step,
  globalErrors: state.globalErrors, // If this component support globalErrors
  value: state.inputValues.current[`${state.page.flow.step}_input_expiration_date`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputExpirationDate;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(InputExpirationDate));
}
