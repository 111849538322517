const React = require('react');

const NoMeliCollector = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="78" height="98" viewBox="0 0 78 98">
    <g fill="none" fillRule="evenodd">
      <path fill="#D7DDE4" d="M9.662 40.602h42.676v-7.165H9.662zM9.662 55.728h24.156v-7.165H9.662zM0 63.69h62V74.8a7.2 7.2 0 0 1-7.2 7.2H7.2A7.2 7.2 0 0 1 0 74.8V63.69z" />
      <path stroke="#9BA9BB" strokeWidth="1.8" d="M61.1 24.26L37.474.9H7.2A6.3 6.3 0 0 0 .9 7.2v67.6a6.3 6.3 0 0 0 6.3 6.3h47.6a6.3 6.3 0 0 0 6.3-6.3V24.26z" />
      <path stroke="#9BA9BB" strokeWidth="1.8" d="M37.844.796v16.055a7.2 7.2 0 0 0 7.2 7.2h16.15" />
      <g>
        <path fill="#D7DDE4" d="M54.5 98C41.542 98 31 87.458 31 74.5S41.542 51 54.5 51 78 61.542 78 74.5 67.458 98 54.5 98" />
        <path fill="#F0F0F0" d="M54.5 53C42.645 53 33 62.645 33 74.5S42.645 96 54.5 96 76 86.355 76 74.5 66.355 53 54.5 53" />
        <g stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.8">
          <path d="M48.541 68.541L61.53 81.53M48.33 81.248l12.988-12.989" />
        </g>
      </g>
    </g>
  </svg>
);

module.exports = NoMeliCollector;
