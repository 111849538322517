const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconWebpay = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" className="icon-webpay">
      <g fill="none" fillRule="evenodd">
        <path fill="#080909" d="M1.281 9.146h2.667l2.17 6.518 2.354-6.518h1.621l2.301 6.44 2.17-6.44h2.668l-3.949 11.46h-1.726l-2.3-6.519-2.38 6.518h-1.7z" />
        <path fill="#080909" fillRule="nonzero" d="M27.272 15.717h-9.178c.131.814.497 1.471 1.072 1.945.576.473 1.308.71 2.197.71 1.072 0 1.987-.369 2.745-1.131h2.772c0 .605-.366 1.13-.366 1.13-.601.867-1.307 1.498-2.144 1.892-.837.421-1.83.605-2.981.605-1.778 0-3.242-.578-4.367-1.708-1.124-1.13-1.673-2.55-1.673-4.258 0-1.735.549-3.206 1.673-4.363 1.125-1.156 2.537-1.735 4.21-1.735 1.804 0 3.242.579 4.367 1.735 1.124 1.157 1.673 2.68 1.673 4.573v.605zm-2.876-2.26a2.765 2.765 0 0 0-1.124-1.551 3.259 3.259 0 0 0-1.935-.605c-.785 0-1.49.237-2.092.684-.366.289-.732.762-1.046 1.471h6.197z" />
        <path fill="#080909" fillRule="nonzero" d="M29.861 5.362v5.046c.523-.526 1.072-.92 1.673-1.157a4.809 4.809 0 0 1 1.935-.394c1.49 0 2.746.552 3.792 1.682 1.046 1.13 1.569 2.576 1.569 4.337 0 1.708-.55 3.127-1.621 4.284-1.073 1.156-2.354 1.708-3.818 1.708a4.726 4.726 0 0 1-1.83-.342c-.576-.236-1.125-.63-1.674-1.156v1.209h-2.85V5.335h2.824v.027zm3.007 6.15c-.889 0-1.647.315-2.249.92-.601.604-.889 1.419-.889 2.417 0 1.025.288 1.84.89 2.47.6.632 1.333.947 2.248.947.863 0 1.595-.315 2.196-.946.602-.63.89-1.446.89-2.444 0-.973-.288-1.788-.89-2.418-.575-.631-1.307-.946-2.196-.946z" />
        <path fill="#E6A730" fillRule="nonzero" d="M8.001 20.447v1.236c.497-.5 1.046-.894 1.621-1.13.576-.263 1.203-.368 1.883-.368 1.438 0 2.693.552 3.687 1.655 1.02 1.104 1.517 2.523 1.517 4.232 0 1.656-.523 3.049-1.57 4.179-1.045 1.13-2.3 1.682-3.738 1.682a4.468 4.468 0 0 1-1.779-.342c-.549-.237-1.098-.604-1.647-1.13v4.888H5.23V20.474H8v-.027zm2.929 2.313c-.89 0-1.595.29-2.197.894-.575.604-.862 1.393-.862 2.365 0 .999.287 1.787.862 2.418.576.605 1.308.92 2.197.92.863 0 1.569-.315 2.144-.946.575-.63.863-1.42.863-2.392 0-.946-.288-1.734-.863-2.365-.55-.605-1.255-.894-2.144-.894z" />
        <path fill="#E6A730" fillRule="nonzero" d="M25.285 20.447h2.432v11.17h-2.432v-1.182c-.575.525-1.15.893-1.726 1.13-.575.236-1.202.341-1.882.341-1.517 0-2.824-.552-3.949-1.682-1.098-1.13-1.673-2.523-1.673-4.178 0-1.735.549-3.128 1.621-4.232s2.38-1.656 3.922-1.656c.706 0 1.36.132 1.987.368.628.263 1.203.631 1.726 1.13v-1.209h-.026zm-3.112 2.313c-.915 0-1.673.316-2.274.92-.602.605-.916 1.393-.916 2.366 0 .972.314 1.76.916 2.391.627.63 1.36.946 2.274.946.916 0 1.7-.315 2.301-.92.602-.604.916-1.419.916-2.417 0-.973-.314-1.761-.916-2.366-.6-.63-1.36-.92-2.3-.92z" />
        <path fill="#E6A730" d="M27.717 20.447h2.85l2.876 7.018 3.19-7.018h2.85l-6.955 15.244h-2.876l2.275-4.915z" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = IconWebpay;
