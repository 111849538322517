/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  CREDIT_CARD_GUESSING,
  CREDIT_CARD_PC_NUMBER,
  CREDIT_CARD_PC_NAME,
  CREDIT_CARD_PC_EXPIRATION,
  CREDIT_CARD_PC_SECURITY,
  CREDIT_CARD_ISSUER,
  CREDIT_CARD_CLEAN,
} = require('../actions/types');

const actions = {};

const initialState = {
  guessing: {},
  issuer: {},
  paymentCard: {
    showFront: true,
    number: '',
    name: '',
    expiration: '',
    security: '',
  },
};

actions[CREDIT_CARD_GUESSING] = (state, action) => ({
  ...state,
  guessing: action.payload.data,
});

actions[CREDIT_CARD_PC_NUMBER] = (state, action) => ({
  ...state,
  paymentCard: {
    ...state.paymentCard,
    number: action.payload.number,
  },
});

actions[CREDIT_CARD_PC_NAME] = (state, action) => ({
  ...state,
  paymentCard: {
    ...state.paymentCard,
    name: action.payload.name,
  },
});

actions[CREDIT_CARD_PC_EXPIRATION] = (state, action) => ({
  ...state,
  paymentCard: {
    ...state.paymentCard,
    expiration: action.payload.expiration,
  },
});

actions[CREDIT_CARD_PC_SECURITY] = (state, action) => ({
  ...state,
  paymentCard: {
    ...state.paymentCard,
    security: action.payload.security,
  },
});

actions[CREDIT_CARD_ISSUER] = (state, action) => ({
  ...state,
  issuer: action.payload.value,
});

actions[CREDIT_CARD_CLEAN] = () => (initialState);

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
