/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const inputValuesActions = require('../../spa/actions/inputValues');
const Select = require('../Select');
const { VISIBILITY_INPUT_VALUES } = require('../../spa/actions/types');

/**
 * Select
 */
class SelectDynamicFields extends React.Component {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.value || '',
      step: props.step,
    };
    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  UNSAFE_componentWillMount() {
    const defaultValue = this.props.savedValue || this.getDefaultValue(this.props.options);

    if (defaultValue) {
      this.updateValue(defaultValue);
      this.dispatchVisibilityOnValue(defaultValue);
    }
  }

  onChange(e, value) {
    /**
     *  update value set by the Select component
     */
    this.updateValue(value);
    this.dispatchVisibilityOnValue(value);
  }

  /**
     * if no value is set in props and there must find the default value
     * this is the selected option or the first option selected
     */
  getDefaultValue(options) {
    if (options.length > 0) {
      const selectedOption = options.filter(opt => !!opt.selected)[0];
      if (selectedOption) {
        return selectedOption.value;
      }
      // if there is no selected option return the first option available as default
      return options[0] ? options[0].value : '';
    }
    return '';
  }

  /**
   * Method to control where or not the given elements should be visible
   * can check on the store the visibility values stored
   * @param {*} valueSelected
   */
  dispatchVisibilityOnValue(valueSelected) {
    const optionSelected = this.props.options.find(opt => opt.value === valueSelected);
    // trigger hide and show elements
    if (optionSelected && optionSelected.toShow && optionSelected.toHide) {
      const inputsVisibility = {};

      optionSelected.toShow.forEach(id => (inputsVisibility[`${this.state.step}_${id}`] = true));
      optionSelected.toHide.forEach(id => (inputsVisibility[`${this.state.step}_${id}`] = false));

      this.props.inputValuesActions[VISIBILITY_INPUT_VALUES](inputsVisibility);
    }
  }

  updateValue(value) {
    this.setState({
      value,
    });
  }

  render() {
    const {
      inputValuesActions,
      ...selectProps
    } = this.props;

    return (
      <>
        <Select
          onChange={this.onChange}
          value={this.state.value}
          {...selectProps}
        />
      </>
    );
  }
}

/**
 * Prop Types
 */
SelectDynamicFields.propTypes = {
  value: PropTypes.string,
  inputValuesActions: PropTypes.shape({
    current_input_values: PropTypes.func,
    visibility_input_values: PropTypes.func,
  }),
  savedValue: PropTypes.string,
  step: PropTypes.string,
};

/**
 * Default Props
 */

SelectDynamicFields.defaultProps = {
  value: '',
  inputValuesActions: {
    current_input_values: () => { },
    visibility_input_values: () => { },
  },
  savedValue: '',
  step: '',
};
/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectDynamicFields;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(SelectDynamicFields);
}
