const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const { useEffect, useState, useRef } = React;
const { bindActionCreators } = require('redux');
const { loadable, LazyHydrate } = require('nordic/lazy');

const Lottie = loadable(async () => import('react-lottie'));

const { connect } = require('react-redux');

const sizeActions = require('../../spa/actions/size');
const stepActions = require('../../spa/actions/step');
const lottieActions = require('../../spa/actions/lottie');
const animationClock = require('../LottieAnimation/animationData/clock');
const { STEP_NEXT } = require('../../spa/actions/types');
const { getQueryParams } = require('../../utils/Dom');
const { polling, cancelPolling, ERROR_MAX_RETRIES } = require('../../utils/polling');
const Title = require('../Title');
const Text = require('../Text');
const ApiService = require('../../service/api');
const translate = require('../../translation');
const { isMobile } = require('../../utils/webview');

const statusAccepted = 'ACCEPTED_SETTLEMENT_COMPLETED';
const statusRejected = 'REJECTED';
const statusAwaiting = 'AWAITING_AUTHORIZATION';

const isTestEnvironment = process.env.NODE_ENV === 'test';
const callOpenFinanceStatus = (paymentId, isTest) => async () => ApiService.getPaymentStatus(paymentId, isTest);
const shouldStopPollingFn = ({ data }) => data?.status === statusAccepted || data?.status === statusRejected;

const OpenFinanceAwaitingController = (props) => {
  const { flow, history, stepAction, paymentId, payerUserTest: isTest, i18n, deviceType } = props;
  const translations = translate(i18n);
  const POLLING_CONFIG = {
    START_DELAY: 3000, // After this time, the first polling begins
    LAP_DELAY: 5000, // Time between rounds of the same polling
    ATTEMPT_LAPS: 5, // Number of rounds each polling has
    ALLOWED_LAPS_WITH_ERRORS: 2, // Number of error responses that polling allows until it stops
    MAX_ATTEMPTS: 1, // Maximum number of times a new polling cycle can be restarted
  };
  const [attempts, setAttempts] = useState(POLLING_CONFIG.MAX_ATTEMPTS);
  const formRef = useRef();
  const inputRef = useRef();

  const submitFormWithAction = (status) => {
    inputRef.current.value = status;
    const queryParams = getQueryParams();

    stepAction[STEP_NEXT](
      formRef.current,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  const lottieOptions = {
    style: {
      height: isMobile(deviceType) ? 230 : 330,
      width: isMobile(deviceType) ? 330 : 430,
    },
    loop: true,
    autoplay: true,
    animationData: animationClock,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const doPolling = () => {
    setAttempts((prevAttempts) => prevAttempts - 1);
    polling({
      fn: callOpenFinanceStatus(paymentId, isTest),
      stopWhen: shouldStopPollingFn,
      delay: POLLING_CONFIG.LAP_DELAY,
      retries: POLLING_CONFIG.ATTEMPT_LAPS,
      allowedErrors: POLLING_CONFIG.ALLOWED_LAPS_WITH_ERRORS,
    })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        const { status } = data;

        submitFormWithAction(status);
      })
      .catch((error) => {
        if (attempts && error?.message === ERROR_MAX_RETRIES) {
          return submitFormWithAction(statusAwaiting);
        }
        return submitFormWithAction(statusRejected);
      });
  };

  useEffect(() => {
    const startPollingTimeoutId = setTimeout(() => {
      doPolling();
    }, POLLING_CONFIG.START_DELAY);

    return () => {
      clearTimeout(startPollingTimeoutId);
      cancelPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="open-finance-awaiting-container">
      <div className="open-finance-awaiting-container__lottie">
        {!isTestEnvironment && (
          /* istanbul ignore next: cant test it with tests */
          <LazyHydrate whenIdle>
            <div className='lottie__container'>
              <Lottie options={lottieOptions} style={lottieOptions.style} />
            </div>
          </LazyHydrate>
        )}
      </div>
      <div className="open-finance-awaiting-container__text">
        <Title text={translations.YOUR_PAYMENT_IS_BEING_PROCESSED_BY_THE_OTHER_INSTITUTION} />
        <Text text={translations.THIS_WILL_TAKE_LESS_THAN_30_SECONDS} />
      </div>
      <form hidden ref={formRef}>
        <input ref={inputRef} name="[status]" type="hidden" />
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sizeActions: bindActionCreators(sizeActions, dispatch),
  lottieActions: bindActionCreators(lottieActions, dispatch),
  stepAction: bindActionCreators(stepActions, dispatch),
});

const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

OpenFinanceAwaitingController.propTypes = {
  flow: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func,
  }),
  paymentId: PropTypes.string.isRequired,
  payerUserTest: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  deviceType: PropTypes.string,
};

OpenFinanceAwaitingController.defaultProps = {
  flow: {
    id: '',
    type: '',
  },
  history: {
    push: () => { },
  },
  stepAction: { [STEP_NEXT]: () => { } },
  i18n: {
    gettext: (t) => t,
  },
  deviceType: 'mobile',
};

if (isTestEnvironment) {
  module.exports = OpenFinanceAwaitingController;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(OpenFinanceAwaitingController));
}
