const React = require('react');
const PropTypes = require('prop-types');

const { Pill } = require('@andes/badge');

const IconBuyFast = require('../icons/BuyFast');
const IconShield = require('../icons/ShieldBPP');

const Badge = ({ size, kind, icon, border, content, className, hierarchy, children }) => {
  let iconComponent = null;

  switch (icon) {
    case 'buyfast':
      iconComponent = <IconBuyFast />;
      break;
    case 'shield':
      iconComponent = <IconShield />;
      break;
    default:
      iconComponent = null;
  }
  return (
    <Pill size={size} color={kind} roundedCorners={border} hierarchy={hierarchy} className={`badge ${className}`}>
      {content} {iconComponent}
      {children}
    </Pill>
  );
};

Badge.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  kind: PropTypes.oneOf(['gray', 'accent', 'green', 'orange', 'red']),
  border: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
  content: PropTypes.string,
  className: PropTypes.string,
  hierarchy: PropTypes.oneOf(['loud', 'quiet']),
  children: PropTypes.node,
};

Badge.defaultProps = {
  icon: null,
  kind: 'accent',
  size: 'large',
  border: 'bottom-left',
  content: '',
  className: '',
  hierarchy: 'loud',
  children: null,
};

module.exports = Badge;
