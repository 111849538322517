/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');
const uuidv4 = require('uuid/v4');
const { CancelToken, isCancel } = require('nordic/restclient/cancel');

const { getStepFromUrl } = require('../../server/stepsMap');
const apiService = require('../../service/api');
const { waitMs } = require('../../utils/animations');
const isRetryAllowed = require('../../utils/isRetryAllowed');
const { nativeClose } = require('../../utils/mobileWebkit');
const {
  GET_FLOW,
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_CANCEL_TOKEN,
  REQUEST_CANCELLED,
  REQUEST_FINISHED_WITHOUT_RENDER,
  EMPTY_PAGE,
  PAGE_LOAD,
  LOADER_STOP_ANIMATION,
} = require('./types');

// Request Actions
const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);
const requestCancelToken = createAction(REQUEST_CANCEL_TOKEN);
const requestCancelled = createAction(REQUEST_CANCELLED);
const loaderStopAnimation = createAction(LOADER_STOP_ANIMATION);
const requestFinishedWithoutRender = createAction(REQUEST_FINISHED_WITHOUT_RENDER);
// Page Actions
const emptyPage = createAction(EMPTY_PAGE);
const pageLoad = createAction(PAGE_LOAD);

/**
 * Export functions
 */
module.exports = {
  /**
   * Get step from flows api only if the step is not currently loaded
   * @param params
   * @param urlParams
   * @param hisotry
   * @return {function(*)}
   */
  [GET_FLOW]: (params, urlParams, history) => (dispatch, getState) => {
    const currentState = getState();
    const requestId = uuidv4();
    const source = CancelToken.source();
    const step = getStepFromUrl(params.step);
    const currentPage = currentState.page;
    const { version } = currentState.configurations;
    let actionResult = {};

    // only request the next step when navigating back & forward (history)
    if (step && params.step && step !== currentPage.data.currentStep) {
      // Cancel previous page request
      dispatch(requestCancelToken(source));

      let isIrreversible = (currentPage.data.hidden_components || []).filter(c => c.id === 'is_irreversible');
      isIrreversible = isIrreversible && isIrreversible.length > 0 ? isIrreversible[0].value : false;

      if (currentState.configurations.isWebview && isIrreversible) {
        nativeClose();
        return;
      }
      if (history.action === 'POP' && isIrreversible) {
        /**
         * On irreversible steps & browser back, avoid
         * recalling Flows to GET the very exact same step's data
         */
        history.push(currentPage.data.redirectTo);
      } else {
        dispatch(emptyPage());
        dispatch(requestStarted({ requestId }));
        actionResult = apiService.getFlow(
          version,
          params.flowId,
          params.type,
          step,
          params.action,
          urlParams,
          {
            cancelToken: source.token,
          }
        ).then((data) => {
          // The middle-end is redirecting you to another step
          // Example: If you are on processing and you go back, you need to be redirect to processing again
          if (step !== data.currentStep) {
            history.push(data.redirectTo);
          }
          dispatch(pageLoad({ data }));
          dispatch(requestFinished({ requestId, cancelToken: source }));
          dispatch(requestFinishedWithoutRender({}));
        }).catch((err) => {
          if (isCancel(err)) {
            dispatch(requestCancelled({ requestId }));
          } else {
            // Finish the request and keep loading
            dispatch(requestFinished({ requestId, cancelToken: source, leaveLoading: true }));
            // Stop the animation
            dispatch(loaderStopAnimation({}));
            // TODO THIS IS A WORKAORUND BECAUSE IF WE MODIFY SOMETHING ON THE PAGE, THERE IS A TRANSICTION THAT UX NOTICE
            dispatch(requestFinishedWithoutRender({}));
            waitMs().then(() => {
              // Go to exception/retry step
              const showRetry = isRetryAllowed(err);
              history.push(`/${params.type}${showRetry ? `/${params.flowId}` : ''}/exception?${urlParams}`);
              dispatch(pageLoad({
                data: {
                  ...currentState.page.data,
                  currentStep: 'exception',
                  showRetry,
                },
              }));
            });
          }
        });
      }
    }
    return actionResult;
  },
};
