const React = require('react');
const PropTypes = require('prop-types');

const WalletIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" className="icon-wallet with-custom-color">
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        strokeOpacity="0.5"
        strokeWidth="1.749"
        d="M9.434 18.126h25.132c.37-1.245.56-2.547.56-3.876C35.126 6.86 29.246.874 22 .874 14.754.874 8.874 6.86 8.874 14.25c0 1.33.19 2.63.56 3.876z"
      />
      <path
        fill={color}
        fillOpacity="0.3"
        d="M1 39.45c0 .853.695 1.55 1.551 1.55H41.45c.855 0 1.551-.697 1.551-1.55V33H1v6.45z"
      />
      <path
        fill={color}
        d="M9.033 9a14.35 14.35 0 0 0-.499 1.842H3.271c-.823 0-1.494.694-1.494 1.549V38.61c0 .854.67 1.548 1.494 1.548h37.458c.823 0 1.494-.694 1.494-1.548V12.391c0-.855-.67-1.549-1.494-1.549h-5.26a14.35 14.35 0 0 0-.5-1.842h5.76C42.532 9 44 10.52 44 12.39v26.22c0 1.87-1.468 3.39-3.27 3.39H3.27C1.468 42 0 40.48 0 38.61V12.39C0 10.52 1.467 9 3.27 9h5.763z"
      />
      <path
        fill={color}
        d="M9.99 18.245H5.878a.878.878 0 1 0 0 1.755h31.244a.878.878 0 1 0 0-1.755H24.52c.45-.463.739-1.093.739-1.944 0-3.783-5.284-2.458-5.626-4.572-.01-.072-.033-.135-.033-.213 0-1.123.797-1.404 1.969-1.404 1.394 0 1.77.69 1.874 1.404a.44.44 0 0 0 .11.213.32.32 0 0 0 .242.103h.903a.354.354 0 0 0 .351-.351 2.62 2.62 0 0 0-.493-1.507c-.431-.606-1.161-1.1-2.295-1.23V7.28a.286.286 0 0 0-.282-.281h-.737a.278.278 0 0 0-.283.28V8.73c-1.183.103-2.048.527-2.526 1.243-.276.416-.425.931-.425 1.542 0 .077.015.14.02.213.214 3.635 5.638 2.232 5.638 4.572 0 1.146-.797 1.567-2.097 1.567-1.922 0-2.215-1.333-2.262-1.825-.011-.14-.14-.315-.328-.315h-.902a.342.342 0 0 0-.34.35c0 .68.305 1.517.973 2.167H9.99z"
      />
    </g>
  </svg>
);

WalletIcon.defaultProps = {
  color: 'currentColor',
};

WalletIcon.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(WalletIcon);
