const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators, compose } = require('redux');

const Markdown = require('../Markdown');
const textsActions = require('../../spa/actions/texts');

const Title = ({ tagName: Tag, text, className, dinamicText, id }) => (
  <Tag id={id} className={className}>
    <Markdown text={dinamicText || text} />
  </Tag>
);

Title.propTypes = {
  className: PropTypes.string,
  dinamicText: PropTypes.string,
  id: PropTypes.string,
  tagName: PropTypes.string,
  text: PropTypes.string,
};

Title.defaultProps = {
  className: '',
  dinamicText: '',
  id: '',
  tagName: 'h1',
  text: '',
};

const mapDispatchToProps = (dispatch) => ({
  textsActions: bindActionCreators(textsActions, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  dinamicText: (ownProps.id && state.texts) ? state.texts[ownProps.id] : null,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Title;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(Title);
}
