const React = require('react');

const { useEffect, useState } = React;

const useHeightElement = () => {
  const [height, setHeight] = useState(0);

  const calculateTotal = (...args) => args.reduce((acc, val) => acc + (typeof val !== 'undefined' && val), 0);

  useEffect(() => {
    const navBarHeight = document.getElementsByClassName('cow-navbar-v2')[0]?.offsetHeight;
    const brandNameHeight = document.getElementsByClassName('navbar')[0]?.offsetHeight;
    const sumamry = document.getElementsByClassName('sticky-summary')[0]?.offsetHeight;

    setHeight(calculateTotal(navBarHeight, brandNameHeight, sumamry));
  }, []);

  return height;
};

module.exports = useHeightElement;
