const React = require('react');
const PropTypes = require('prop-types');

const { transformIssuer } = require('../../utils/Strings');
const IconWrapper = require('../IconWrapper');

const PaymentCardIcon = ({ className, cardId, issuerName, escapeAccentMark = false }) => {
  let classNames = 'payment-card-icon-wrapper';

  if (className) {
    classNames += ` ${className}`;
  }

  if (cardId) {
    classNames += ` payment-card__brand-${cardId}`;
  }

  if (issuerName) {
    classNames += ` payment-card__brand-${transformIssuer(issuerName, escapeAccentMark)}`;
  }

  return (
    <IconWrapper className={classNames} id="payment-card-icon-wrapper">
      <svg aria-hidden="true" className="payment-card-icon" />
    </ IconWrapper>
  );
};

PaymentCardIcon.propTypes = {
  className: PropTypes.string,
  cardId: PropTypes.string,
  issuerName: PropTypes.string
};

PaymentCardIcon.defaultProps = {
  className: '',
  cardId: '',
  issuerName: '',
};

module.exports = PaymentCardIcon;
