const React = require('react');
const { Text } = require('@andes/typography');
const { Message } = require('@andes/message');
const { ANDES_MESSAGES } = require('../../../constants/commons');

/**
 * AOPO meaning: Account Options/Payment Options
 */
const MessageAOPO = ({ title, message, closable, color, location }) => {
  return (
  <div className={`message_ao-po ${location ?? ''}`}>
    <Message title={title} closable={closable} hierarchy="quiet" color={ANDES_MESSAGES[color]}>
      <Text component="span" size="s">
        {message}
      </Text>
    </Message>
  </div>);
};

module.exports = MessageAOPO;
