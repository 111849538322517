/* eslint-disable import/order */
const uuidv4 = require('uuid/v4');

const ApiService = require('../../../../service/api');

// Redux
const {
  STEP_NEXT,
  REQUEST_STARTED,
  SAVE_CAPTCHA_TOKEN,
  REQUEST_FINISHED,
  STEP_LOGOUT,
  SNACKBAR_TRIGGER,
  LOADER_PAY_BUTTON,
  LOADER_TRIGGERED,
} = require('../../../../spa/actions/types');

// Constants
const {
  PAGE_ACTIONS,
  CAPTCHA_VERSION,
  CHECKOUT_TYPE,
  URLS: {
    BASE_URL_MERCADO_LIBRE
  },
} = require('../../../../../constants/app');
const { INPUTS_ID } = require('../../../../../constants/ui');

// Utils
const { getQueryParams } = require('../../../../utils/Dom');
const { DISCOUNT_CODE_VALIDATIONS, validate } = require('../../../../utils/regex');

export const useActions = ({
  flow,
  siteId,
  browserName,
  history,
  stepActions,
  snackbarActions,
  captchaToken,
  captchaConfig,
  discountCode,
  setDiscountCodeErrorMessage,
  payer,
  email,
  emailErrorMessage,
  setEmailErrorMessage,
  challengesInstructions,
  captcha,
  translations,
  useAlternativePaymentMethod,
  loadingActions,
  requestActions,
}) => {
  const requestId = uuidv4();

  // ===== Utilities =====

  const generateTokenCaptchaV3 = () => {
    requestActions[REQUEST_STARTED]({ requestId });
    const promiseToken = new Promise((resolve, reject) => {
      try {
        return window.grecaptcha.enterprise
          .execute(captchaConfig.siteKeyV3, { action: captchaConfig.action })
          .then((token) => {
            requestActions[REQUEST_FINISHED]({
              requestId,
              leaveLoading: true,
            });
            if (captchaToken) {
              captchaToken[SAVE_CAPTCHA_TOKEN](token);
            }
            resolve(token);
          });
      } catch (error) {
        requestActions[REQUEST_FINISHED]({
          requestId,
          leaveLoading: true,
        });
        reject(error);
      }
    });
    return promiseToken;
  };

  const getLogoutSnackbarComponent = () => {
    // Link to MP home _blank
    const mpHomeHref = (<a href="/" target="_blank">{translations.PANEL}</a>);
    return (
      <span>
        {translations.SIGN_OUT} {mpHomeHref} {translations.TRY_AGAIN}
      </span>
    );
  };

  const showNotification = ({ status = 'default', component, displayTimeout = 10000 }) => {
    // set action data for error message
    const snackbarActionData = {
      show: true,
      status,
      message: component,
    };

    snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    setTimeout(() => {
      snackbarActionData.show = false;
      snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    }, displayTimeout);
  };

  // ===== GENERAL ACTIONS =====

  const nextStepAction = ({ data }) => {
    const queryParams = getQueryParams();

    if (typeof useAlternativePaymentMethod === 'boolean') {
      data.values.alternative_payment_method = useAlternativePaymentMethod
    }

    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  const goToNextStep = async (data) => {
    if (captcha?.version === CAPTCHA_VERSION.V3) {
      if (window.captchaReady) {
        await generateTokenCaptchaV3();
      } else if (!window.captchaError) {
        // In case Captcha from google is still loading we will save in window the callback function to execute when ready
        window.executeAction = async () => {
          await generateTokenCaptchaV3();
          nextStepAction({ data });
        };
        // If Google's Api is still loading should save a callback method to continue with normal path if Google's Api fail to load
        window.executeErrorAction = () => {
          nextStepAction({ data });
        };
        return;
      }
    }

    nextStepAction({ data });
  };

  /**
   * @TODO Review this code recessivity + async code + timeout
   * @param {*} logoutPopup
   * @param {*} retries
   */
  const checkLogoutStatus = ({ logoutPopup, retries, changeUser }) => {
    const queryParams = getQueryParams();
    setTimeout(() => {
      ApiService.isUserLogged()
        .then((response) => {
          if (!response.logged) {
            logoutPopup.close();
            nextStepAction({
              data: {
                components: {
                  user: changeUser.toString(),
                },
              },
              defaultData: { type: flow.type, urlParams: queryParams, cancel: flow.cancel },
            });
            return;
          }

          if (retries > 0) {
            checkLogoutStatus(logoutPopup, retries - 1);
            return;
          }

          logoutPopup.close();
          showNotification({
            component: getLogoutSnackbarComponent(),
          });
        })
        .catch(() => {
          // If there is any error, trigger the snackbar error and close the popup
          logoutPopup.close();
          showNotification({
            component: getLogoutSnackbarComponent(),
          });
        });
    }, 250);
  };

  const createLogoutIframe = ({ logoutUrl, changeUser = false }) => {
    const queryParams = getQueryParams();
    const iframe = document.createElement('iframe');
    iframe.id = 'logout-frame';
    iframe.title = 'logout';
    iframe.style.visibility = 'hidden';
    iframe.style.display = 'none';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.src = logoutUrl;

    iframe.onload = () =>
      nextStepAction({
        data: {
          components: {
            user: changeUser.toString(),
          },
        },
        defaultData: { type: flow.type, urlParams: queryParams, cancel: flow.cancel },
      });

    return iframe;
  };

  const logOutStepAction = ({ logoutUrl, data }) => {
    const queryParams = getQueryParams();
    stepActions[STEP_LOGOUT]({
      data,
      flowId: flow.id,
      defaultData: {
        cancel: flow.cancel,
        type: flow.type,
        urlParams: queryParams
      },
      type: flow.type,
      urlParams: queryParams,
      history,
      logoutUrl,
    });
  };

  // ===== STEP ACTIONS =====

  const changePaymentMethod = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_PAYMENT_OPTION,
      values: {},
    };
    goToNextStep(data);
  };

  const changeInstallments = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_INSTALLMENTS,
      values: {},
    };
    goToNextStep(data);
  };

  const dismissConsumerCredits = () => {
    const data = {
      id: PAGE_ACTIONS.DISMISS_CONSUMER_CREDITS,
      values: {},
    };
    goToNextStep(data);
  };

  const payConsumerCredits = () => {
    const data = {
      id: PAGE_ACTIONS.PAY_WITH_CONSUMER_CREDITS,
      values: {},
    };
    goToNextStep(data);
  };

  const sentDiscountCode = () => {
    // Validate the discount code
    const validation = validate(discountCode, DISCOUNT_CODE_VALIDATIONS(translations));
    if (validation.error) {
      setDiscountCodeErrorMessage(validation.message);

      // Accessibility - Set focus to the discount code input
      document.getElementById(INPUTS_ID.DISCOUNT_CODE)?.focus();
      return;
    }

    const data = {
      id: PAGE_ACTIONS.SEND_DISCOUNT_CODE,
      values: {
        discount: {
          discount_code: discountCode,
        },
      },
    };

    goToNextStep(data);
  };

  const changeExtraData = () => {
    if (!payer?.extra_data?.is_modifiable) {
      return;
    }

    // To Define the Step Action
    const data = {
      id: PAGE_ACTIONS.CHANGE_EXTRA_DATA,
      values: {},
    };
    goToNextStep(data);
  };

  const kyc = () => {
    const data = {
      id: PAGE_ACTIONS.PAY,
      values: {
        payer: {
          email,
        },
      },
    };
    goToNextStep(data);
  };

  const pay = () => {
    // KyC validation
    if (challengesInstructions?.kyc && Object.keys(challengesInstructions?.kyc).length) {
      return;
    }

    // Email validation
    if (payer.is_guest) {
      if (!email || email?.length === 0) {
        setEmailErrorMessage(translations.COMPLETE_THIS_DATA);

        // Accessibility - Set focus to the email input
        document.getElementById(INPUTS_ID.USER_EMAIL)?.focus();
        return;
      }

      if (emailErrorMessage?.length > 0) {
        return;
      }
    }

    // Show Loader Wrapper animation
    loadingActions[LOADER_TRIGGERED]('empty', 'pay');
    // Start Pay Button Loading Animation
    loadingActions[LOADER_PAY_BUTTON](true);

    // New Response
    const data = {
      id: PAGE_ACTIONS.PAY,
      values: {
        payer: {
          email,
        },
      },
    };
    goToNextStep(data);
  };

  const changeShippingOption = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_SHIPPING_OPTION,
      values: {},
    };
    goToNextStep(data);
  };

  const changeShippingAddress = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_SHIPPING_ADDRESS,
      values: {},
    };
    goToNextStep(data);
  };

  const logout = ({ changeUser = false }) => {
    const LOG_OUT_URL = `${BASE_URL_MERCADO_LIBRE}/jms/${siteId.toLowerCase()}/lgz/logout`;

    // === REDIRECT MODE ===
    if (flow?.type === CHECKOUT_TYPE.REDIRECT) {
      return logOutStepAction({
        logoutUrl: LOG_OUT_URL,
        data: {
          components: {
            user: changeUser.toString(),
          },
        },
      });
    }

    // === EMBEDDED & MODAL MODES ===
    const IFRAME_URL = `${LOG_OUT_URL}?embedded_mode=true`;
    const WHITELISTED_BROWSERS = ['safari', 'mobile safari', 'firefox'];

    if (WHITELISTED_BROWSERS.includes(browserName)) {
      const logoutPopup = window.open(IFRAME_URL, 'logout_popup', 'width=10, height=10, top=10, left=10');
      if (logoutPopup && !logoutPopup.closed) {
        checkLogoutStatus({ logoutPopup, retries: 3, changeUser });
        return;
      }

      // Couldn't open the Logout popup so a Snackbar will display the action he needs to do to logout
      showNotification({
        component: getLogoutSnackbarComponent(),
      });
      return null;
    }

    const iframe = createLogoutIframe({ logoutUrl: IFRAME_URL, changeUser });
    document?.body?.appendChild(iframe);

    return null;
  };

  const changeEmail = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_EMAIL,
      values: {},
    };
    goToNextStep(data);
  };

  return {
    showNotification,
    changePaymentMethod,
    changeInstallments,
    dismissConsumerCredits,
    payConsumerCredits,
    sentDiscountCode,
    changeExtraData,
    changeEmail,
    kyc,
    pay,
    changeShippingOption,
    changeShippingAddress,
    logout,
  };
}
