/* global window */

/**
 * client-side restclient configuration object.
 *
 * NOTE: if this file is not loading properly client-side, please ensure the
 * import path in your code matches the path in the package.json "browser" section
 *
 **/
const { join } = require('path');
const { protocol, host } = window.location;
// Remove trailing slash and page number from URL
const baseURL = `${protocol}//${host}${join('/', window.env.apiBasePath)}`;

const restclientConfig = (config = {}) => {
  const mainConfig = {
    baseURL,
    timeout: 30000,
  };

  return Object.assign({}, mainConfig, config);
};

module.exports = restclientConfig;
