/**
 * Wrapper for andes List component.
 * Once Andes Radio List Item component is available, it should be replaced with <List>.
 */
const React = require('react');
const PropTypes = require('prop-types');

const { ListItem, ListGroup } = require('@andes/list');
const RadioList = require('@andes/radio-list');
const classNames = require('classnames');

const SelectableListItem = require('../../SelectableListItem');

class GroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      defaultValue: null,
    };
    this.inputEl = null;
    this.handleClick = this.handleClick.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderDesktopChild = this.renderDesktopChild.bind(this);
  }

  componentDidMount() {
    if (this.props.deviceType === 'desktop') {
      let optionselected = null;
      this.props.value.forEach((child) => {
        if (child.type === 'radio_list') {
          child.value.forEach((childChild) => {
            if (childChild.selected || optionselected === null) {
              optionselected = childChild;
            }
          });
        } else if (child.selected || optionselected === null) {
          optionselected = child;
        }
      });

      const defaultOption = optionselected || this.props.value[0].value[0];
      this.setState({
        value: defaultOption.value,
        defaultValue: defaultOption.value,
      });
    }
  }

  handleClick(event, value) {
    if (value !== undefined) {
      this.setState({ value });
      if (this.props.onClick) {
        this.props.onClick(event, value);
      }
    }
  }

  renderDesktopChild(children) {
    if (children.type === 'list_item') {
      return (
        <RadioList {...children} handleClick={this.handleClick} name={this.props.name}>
          <ListItem {...children} name={this.props.name} />
        </RadioList>
      );
    } if (children.type === 'radio_list') {

      // label is required
      children.label = children.label || 'hide-header';
      const listGroupClasses = classNames({
        'hide-header': children.label === 'hide-header'
      });

      return (
        <ListGroup
          name={this.props.name}
          label={children.label}
          className={listGroupClasses}
        >
          {children.value.map((item) => {
            const { name, ...otherProps } = item;
            return <ListItem {...otherProps} type="ListItem" name={`${this.props.name}[radio]`} />;
          })}
        </ListGroup>
      );
    }
    return null;
  }

  renderDesktop() {
    if (!this.state.defaultValue) {
      return <></>;
    }

    const radios = this.props.value.map(child => this.renderDesktopChild(child));
    return (
      <div className="group-list-wrapper">
        <input type="hidden" name={this.props.inputName} value={`[${this.state.value}]`} />
        <RadioList defaultValue={this.state.defaultValue} onClick={this.handleClick}>{radios}</RadioList>
      </div>
    );
  }

  renderMobile() {
    return this.props.value.map((children) => {
      const label = children.label ? <div className="list-group--title">{children.label}</div> : '';
      return (
        <div className="group-list">
          {label}
          {children.value.map(item => <SelectableListItem {...item} name={this.props.inputName} />)}
        </div>
      );
    });
  }

  render() {
    return (this.props.deviceType === 'mobile') ? this.renderMobile() : this.renderDesktop();
  }
}

GroupList.propTypes = {
  deviceType: PropTypes.string,
  id: PropTypes.string.required,
  value: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.required,
  onClick: PropTypes.func,
  inputName: PropTypes.string,
};

GroupList.defaultProps = {
  deviceType: 'desktop',
  value: [],
  name: '',
  id: '',
  inputName: '',
  onClick: () => { },
};

module.exports = GroupList;
