const React = require('react');

const IconWrapper = require('../IconWrapper');

const UserDefaultML = () => (
  <IconWrapper>
    <svg aria-hidden="true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00453 8.60084C9.6616 8.60084 11.0049 7.25752 11.0049 5.60044C11.0049 3.94336 9.6616 2.60004 8.00453 2.60004C6.34745 2.60004 5.00412 3.94336 5.00412 5.60044C5.00412 7.25752 6.34745 8.60084 8.00453 8.60084ZM8.00453 7.40084C7.01019 7.40084 6.20412 6.59477 6.20412 5.60044C6.20412 4.60611 7.01019 3.80004 8.00453 3.80004C8.99886 3.80004 9.80493 4.60611 9.80493 5.60044C9.80493 6.59477 8.99886 7.40084 8.00453 7.40084Z"
        fill="black"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7979 7.99967C15.7979 12.3067 12.3064 15.7982 7.99943 15.7982C3.69244 15.7982 0.200928 12.3067 0.200928 7.99967C0.200928 3.69268 3.69244 0.201172 7.99943 0.201172C12.3064 0.201172 15.7979 3.69268 15.7979 7.99967ZM3.79393 13.0846C4.93573 14.03 6.40123 14.5982 7.99943 14.5982C9.59716 14.5982 11.0623 14.0303 12.2039 13.0854V12.7996C12.2039 11.8057 11.3982 10.9999 10.4042 10.9999H5.57802C4.59269 10.9999 3.79393 11.7987 3.79393 12.784V13.0846ZM2.71246 11.9485C1.88875 10.8475 1.40093 9.48054 1.40093 7.99967C1.40093 4.35542 4.35518 1.40117 7.99943 1.40117C11.6437 1.40117 14.5979 4.35542 14.5979 7.99967C14.5979 9.48265 14.1087 10.8514 13.2829 11.9532C12.9175 10.7087 11.767 9.7999 10.4042 9.7999H5.57802C4.21997 9.7999 3.0738 10.7071 2.71246 11.9485Z"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  </IconWrapper>
);

module.exports = UserDefaultML;
