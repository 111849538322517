/* global window */
const React = require('react');
const PropTypes = require('prop-types');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');

const { memo, useEffect } = React;

const ProcessingRedirect = ({ url, delay, size, label, target }) => {
  useEffect(() => {
    setTimeout(() => {
      window.open(url, target);
    }, delay);
  });

  return <ProgressIndicatorCircular size={size} label={label} />;
};

ProcessingRedirect.propTypes = {
  size: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
  ]),
  label: PropTypes.string,
  delay: PropTypes.number,
  target: PropTypes.string,
  url: PropTypes.string.isRequired,
};

ProcessingRedirect.defaultProps = {
  size: 'large',
  label: '',
  delay: 3000,
  target: '_self',
};

module.exports = memo(ProcessingRedirect);
