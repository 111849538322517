// Animations durations times in ms (This must be the exact ones on SCSS, and, must be the same for all animations)
const endingAnimationDuration = 250;
const msForNextStep = 200;

/**
 * This functions is going to return a promise when a animation has ended. Return the information to the other promise
 * @param toPass
 * @param transactionDurationInMs
 * @returns {Promise<any>}
 */
const msWaitTimeForLoadingAnimation = async toPass => (
  // Return a promise when the animation end
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(toPass);
    }, endingAnimationDuration + msForNextStep);
  })
);

module.exports = {
  times: {
    ending: endingAnimationDuration,
    msDelay: msForNextStep,
  },
  waitMs: msWaitTimeForLoadingAnimation,
};
