const findFormByEventTarget = (target = {}) => {
  if (target.nodeName === 'FORM') {
    return target;
  }

  if (target.parentNode.nodeName === 'BODY') {
    return null;
  }

  return findFormByEventTarget(target.parentNode);
};

module.exports = findFormByEventTarget;
