const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const Success = ({ status }) => (
  <IconWrapper className="icon-success">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g>
          <circle cx="16" cy="16" r="16" fill={status === 'approved' ? '#FFF' : '#00A650'} />
          <path d="M8.5 7.5h17v17h-17z" opacity=".3" />
        </g>
        <path
          stroke={status === 'approved' ? '#00A650' : '#FFF'}
          strokeLinecap="square"
          strokeLinejoin="square"
          strokeWidth={status === 'approved' ? 3 : '1.92'}
          d="M10 16l3.6 4 8.4-8"
        />
      </g>
    </svg>
  </IconWrapper>
);

Success.defaultProps = {
  status: '',
};

Success.propTypes = {
  status: PropTypes.string,
};

module.exports = React.memo(Success);
