const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const PaymentMethodCardList = require('@cow/core-components/components/PaymentMethodCardList');

const useInputs = require('./hooks/useInputs');
// eslint-disable-next-line import/order
const combinationActions = require('../../spa/actions/combinations');

const { useCallback, useEffect, useRef, useState } = React;
const { CHECK_PAYMENT_OPTION, RESET_COMBINATION } = require('../../spa/actions/types');
const { DEVICE_TYPE } = require('../../../constants/commons');

const RadioListWithCombination = ({ checkActions, items, paymentOptionsToCombine, withDividers, deviceType }) => {
  const ref = useRef(null);

  const Form = useInputs(paymentOptionsToCombine, ref);

  useEffect(() => {
    checkActions[RESET_COMBINATION]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (id) => {
      checkActions[RESET_COMBINATION]();
      checkActions[CHECK_PAYMENT_OPTION](id);
    },
    [checkActions],
  );

  return (
    <>
      {Form}
      <PaymentMethodCardList items={items} onChange={handleChange} withDividers={withDividers} deviceType={deviceType} />
    </>
  );
};

/* istanbul ignore next: can't test it with tests */
const mapDispatchToProps = (dispatch) => ({
  checkActions: bindActionCreators(combinationActions, dispatch),
});

/* istanbul ignore next: can't test it with tests */
const mapStateToProps = (state) => {
  const { paymentOptionsToCombine } = state.combinations;
  return {
    paymentOptionsToCombine,
  };
};

RadioListWithCombination.defaultProps = {
  withDividers: true,
  paymentOptionsToCombine: [],
  items: [],
  deviceType: DEVICE_TYPE.DESKTOP,
};

RadioListWithCombination.propTypes = {
  checkActions: PropTypes.object,
  items: PropTypes.array.isRequired,
  paymentOptionsToCombine: PropTypes.array,
  withDividers: PropTypes.bool,
  deviceType: PropTypes.string.isRequired,
};

/* istanbul ignore next: can't test it with tests */
module.exports =
  process.env.NODE_ENV === 'test'
    ? RadioListWithCombination
    : connect(mapStateToProps, mapDispatchToProps)(RadioListWithCombination);
