const React = require('react');
const PropTypes = require('prop-types');

const GroupLink = ({ className, children, href, target }) => (
  <a className={className} href={href} target={target}>
    {children}
  </a>
);

GroupLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
};

GroupLink.defaultProps = {
  className: '',
  children: null,
  href: '',
  target: '_blank',
};

module.exports = GroupLink;
