const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../../../translation');

const AmountDetailsRow = ({ amount, installments, i18n, extraLabel }) => {
  const translations = translate(i18n);
  return (
    <div className="amount-details">
      <div className="amount-details__label">{translations.AMOUNT}</div>
      <div className="amount-details__values">
        <div className="amount">{amount}</div>
        <div className='amount-installments__row'>
          {!!installments && <div className="installments">{installments}</div>}
          {!!extraLabel && <div className="extra-label">{extraLabel}</div>}
        </div>
      </div>
    </div>
  );
};

AmountDetailsRow.propTypes = {
  amount: PropTypes.string,
  installments: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  extraLabel: PropTypes.string,
};

AmountDetailsRow.defaultProps = {
  amount: '',
  installments: '',
  i18n: {
    gettext: (t) => t,
  },
  extraLabel: null,
};

module.exports = injectI18n(AmountDetailsRow);
