/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  MERCADOPAGO_SDK_LOADED,
  MERCADOPAGO_SDK_TOKENIZE_ERRORS,
  MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN,
  MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION,
  MERCADOPAGO_SDK_SECURE_FIELD_MOUNT,
  MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT,
  MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN,
} = require('./types');

// Request Actions
const mercadoPagoSdkLoaded = createAction(MERCADOPAGO_SDK_LOADED);
const mercadoPagoSdktokenizeErrors = createAction(MERCADOPAGO_SDK_TOKENIZE_ERRORS);
const mercadoPagoSdktokenizeCardToken = createAction(MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN);
const mercadoPagoSdkSecureFieldException = createAction(MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION);
const mercadoPagoSdkSecureFieldMount = createAction(MERCADOPAGO_SDK_SECURE_FIELD_MOUNT);
const mercadoPagoSdkSecureFieldUnmount = createAction(MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT);
const cleanMercadoPagoSdktokenizeErrors = createAction(MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN);

/**
 * Export functions
 */
module.exports = {
  [MERCADOPAGO_SDK_LOADED]: loaded => dispatch => dispatch(mercadoPagoSdkLoaded(loaded)),
  [MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN]: loaded => dispatch => dispatch(mercadoPagoSdktokenizeCardToken(loaded)),
  [MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION]: thrown => dispatch => dispatch(mercadoPagoSdkSecureFieldException(thrown)),
  [MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN]: () => dispatch => dispatch(cleanMercadoPagoSdktokenizeErrors()),
  [MERCADOPAGO_SDK_SECURE_FIELD_MOUNT]: (settings) => (dispatch) => dispatch(mercadoPagoSdkSecureFieldMount(settings)),
  [MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT]: (settings) => (dispatch) => dispatch(mercadoPagoSdkSecureFieldUnmount(settings)),
  [MERCADOPAGO_SDK_TOKENIZE_ERRORS]: errors => dispatch => {
    const sdkErrors = Array.isArray(errors) ? errors : [];
    const isValidError = sdkErrors.every((error) => error.cause && error.field);
    if (sdkErrors.length > 0 && isValidError) {
      const payload = sdkErrors.map(sdkError => ({
        cause: sdkError?.cause,
        details: sdkError?.details,
        field: sdkError?.field,
      }));

      const groupedPayload = payload.reduce((groups, item) => {
        const group = (groups[item.field] || []);
        group.push(item);
        groups[item.field] = group;
        return groups;
      }, {});

      /**
       * Merge expirationMonth, expirationMonth into expirationDate error field object
       * to match the expirationDate secure field type
       */
      if (
        groupedPayload.expirationMonth &&
        groupedPayload.expirationYear &&
        Array.isArray(groupedPayload.expirationDate)
      ) {
        groupedPayload.expirationDate = groupedPayload.expirationDate
          .concat(groupedPayload.expirationMonth, groupedPayload.expirationYear);
      }
      return dispatch(mercadoPagoSdktokenizeErrors(groupedPayload));
    }
    return dispatch(mercadoPagoSdktokenizeErrors([]));
  },
};
