const React = require('react');
const PropTypes = require('prop-types');

const Container = (props) => <div className={`form-actions ${props?.className || ''}`}>{props.children}</div>;

Container.propTypes = {
  className: PropTypes.string,
};

module.exports = Container;
