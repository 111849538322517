const React = require('react');
const PropTypes = require('prop-types');

// Cow Components
const ToggleButton = require('@cow/core-components/components/ToggleButton');

const DiscountTrigger = (props) => props.isApplicable && (
    <div className="discount--trigger">
      <ToggleButton
        labelToggle={props.labelToggle}
        id={props.discountTriggerId}
        onClick={props.toggleDiscount}
        className={props.className}
      />
    </div>
  )

DiscountTrigger.propTypes = {
  isApplicable: PropTypes.bool,
  labelToggle: PropTypes.string,
  discountTriggerId: PropTypes.string,
  toggleDiscount: PropTypes.func
};

module.exports = DiscountTrigger;
