const React = require('react');
const PropTypes = require('prop-types');

const Loading = require('ui-library-mp/ui/Loading');
const { connect } = require('react-redux');

const PageLoader = ({ loading, loadingType, forceLoading }) => (
  <>
    {
      ((loading || forceLoading) && !loadingType)
      && (
      <div className="loader-wrapper">
        <Loading size="large" />
      </div>
      )
    }
    {
      ((loading || forceLoading) && loadingType === 'empty')
      && <div className="loader-wrapper" />
    }
  </>
);

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  loading: state.request.loading,
  loadingType: state.request.loadingType,
});

PageLoader.defaultProps = {
  loading: false,
  loadingType: '',
  forceLoading: false,
};

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingType: PropTypes.string,
  forceLoading: PropTypes.bool,
};

if (process.env.NODE_ENV === 'test') {
  module.exports = PageLoader;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(PageLoader);
}
