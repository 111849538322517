const React = require('react');
const PropTypes = require('prop-types');
const { SplitTextfield } = require('@andes/textfield');
const { formatDocument } = require('../../utils/format');

const InputIdentification = React.forwardRef((props, ref) => {
  const list = {
    content: props.list?.content,
    onClick: (item) => {
      props.list?.onClick(item);
      handleIdentificationTypeClick(item);
    },
  };

  const handleIdentificationTypeClick = (item) => {
    if (ref && props.identificationType !== item.title) {
      ref.current.querySelector('input').value = '';
    }
  };

  const onChange = (e) => {
    formatDocument(e.target.value, props.identificationType, e.target);
    if (props?.onChange) {
      props.onChange(e);
    }
  };

  return (
    <SplitTextfield
      ref={ref}
      list={list}
      onChange={onChange}
      onBlur={props.onBlur}
      name={props.name}
      label={props.label}
      className={props.className}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      defaultSelection={props.defaultSelection}
      modifier={props.modifier}
      helper={props.helper}
      id={props.id}
    />
  );
});

InputIdentification.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultSelection: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  modifier: PropTypes.string,
  helper: PropTypes.string,
  identificationType: PropTypes.string.isRequired,
  list: PropTypes.shape({
    content: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = InputIdentification;
