const React = require('react');
const PropTypes = require('prop-types');

const Markdown = require('../../Markdown');

const GroupRowBanner = props => (
  <div className="group-row-banner-container">
    {props.children}
    {props.label
      ? (
        <div className="row-banner">
          <Markdown text={props.label} />
        </div>
      ) : ''}
  </div>
);

GroupRowBanner.defaultProps = {
  children: null,
  label: '',
};

GroupRowBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string.isRequired,
};

module.exports = GroupRowBanner;
