const ValidatorProvider = require('./ValidatorProvider');
const injectValidations = require('./validations');
const injectValidator = require('./validator');
const injectSecureFieldCardFormValidations = require('./inject-secure-field-card-form-validation');

/**
 * Export ValidatorProvider Component
 * @type {ValidatorProvider}
 */
exports.ValidatorProvider = ValidatorProvider;

/**
 * Export injectValidation method (Add To Component)
 * @type {(p1:*)}
 */
exports.injectValidations = injectValidations;

/**
 * Export injectValidator method (Add to the Form Component)
 */
exports.injectValidator = injectValidator;

/**
 * Export injectValidator method (Add to the Form Component)
 */
exports.injectSecureFieldCardFormValidations = injectSecureFieldCardFormValidations;
