const React = require('react');
const PropTypes = require('prop-types');

const Scroller = (props) => {
  let classNames = 'scroller';

  if (props.className) {
    classNames += ` ${props.className}`;
  }

  return (
    <div className={classNames}>
      {props.children}
    </div>
  );
};

Scroller.propTypes = {
  className: PropTypes.string,
};

Scroller.defaultProps = {
  className: '',
};

module.exports = Scroller;
