const React = require('react');
const PropTypes = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');

const Oops = require('../Oops');
const IconOops = require('../icons/Oops');
const { logErrorFromClient } = require('../../utils/logTags');
const {
  ERROR_SPA: { REACT_CRASH_ERROR },
} = require('../../../constants/app');
const translate = require('../../translation');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = { hasError: false };
    this.translations = translate(i18n);

    // this attribute controls the double calling of componentDidCatch
    this.errorLogged = false;
  }

  /**
   * Update state so the next render will show the fallback UI.
   * @returns {{ hasError: true }}
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (!this.errorLogged) {
      logErrorFromClient(error, REACT_CRASH_ERROR, '[ErrorBoundary][log]', {});
    }

    this.errorLogged = true;
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error_boundary">
          <Oops message={this.translations.OH_NO_SOMETHING_WENT_WRONG}>
            <IconOops />
          </Oops>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  children: null,
  i18n: {
    gettext: (t) => t,
  },
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

/* istanbul ignore next: cant test */
if (process.env.NODE_ENV === 'test') {
  module.exports = ErrorBoundary;
} else {
  /* istanbul ignore next: cant test */ module.exports = injectI18n(ErrorBoundary);
}
