import type { PaymentSummaryProps } from './index';

import React from 'react';
import { Typography } from '@andes/typography';

import RowItem from '../RowItem';

// Total Section
function TotalSection({ total, splitPayment }: Partial<PaymentSummaryProps>) {
  if (total?.name && total.price && !splitPayment) {
    return (
      <React.Fragment>
        <RowItem
          label={total.name}
          labelWeight={total.nameWeight || 'semibold'}
          labelObservation={total.nameObservation}
          labelObservationColor={total.nameObservationColor}
          labelObservationTextSize={total.nameObservationSize}
          value={total.price}
          valueCents={total.priceCents}
          valueCentsType={total.priceCentsType}
          valueWeight={total.priceWeight || 'semibold'}
          valueObservation={total.priceObservation}
          valueObservationColor={total.priceObservationColor || 'positive'}
          valueObservationTextSize={total.priceObservationSize}
          valueSize={total.priceSize}
          valueSymbol={total.priceSymbol}
          textSize={total.textSize || 'l'}
          className="cow-payment_summary__row cow-payment_summary__row--total"
        />

        {total.helperText && (
          <Typography type="body" size="xs" color="secondary">
            {total.helperText}
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default TotalSection;
