const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const uuidv4 = require('uuid/v4');

const { injectValidator } = require('../../utils/validator-provider');
const { getQueryParams } = require('../../utils/Dom');
const stepActions = require('../../spa/actions/step');
const globalErrors = require('../../spa/actions/globalErrors');
const {
  STEP_NEXT,
  GLOBAL_ERRORS_CLEAN,
  GLOBAL_ERRORS_ADD,
  CLEAN_INPUT_VALUES,
  SAVE_CAPTCHA_TOKEN,
  REQUEST_STARTED,
  REQUEST_FINISHED,
  BOTTOM_SHEET,
  LOADER_TRIGGERED,
} = require('../../spa/actions/types');
const LoadingActions = require('../../spa/actions/loading');
const inputValuesActions = require('../../spa/actions/inputValues');
const captchaToken = require('../../spa/actions/captchaToken');
const request = require('../../spa/actions/request');
const bottomSheetActions = require('../../spa/actions/bottomSheet');
const MercadoPagoSdkActions = require('../../spa/actions/mercadopagoSdk');

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsible: this.props?.collapsible,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitWithCaptchaV3 = this.onSubmitWithCaptchaV3.bind(this);
    this.nextStepAction = this.nextStepAction.bind(this);
    this.generateTokenCaptchaV3 = this.generateTokenCaptchaV3.bind(this);
    this.requestTokenV3AndContinue = this.requestTokenV3AndContinue.bind(this);
  }

  componentDidMount() {
    // Get all inputs inside the form element
    const inputs = this.form.getElementsByTagName('input');

    // TODO: temporary solution, once definitive solution is implemented
    // remove this code
    // For cases where the is a lower bandwidth
    // if a option was selected while hydrate is executing
    // send the selected option automaticly when react-dom hydrate ends
    if (inputs) {
      for (let i = 0; i < inputs.length; i += 1) {
        if (inputs[i] && inputs[i].checked) {
          inputs[i].checked = false;
        }
      }

      if (inputs.length > 0 && this.areCollapsiblesOpened()) {
        const [inputOpened] = inputs;
        this.input = inputOpened;
        this.input.focus();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.collapsible !== nextProps.collapsible) {
      this.setState({ collapsible: nextProps.collapsible });
    }
  }

  async onSubmit(event) {
    event.preventDefault();

    const form = event.target;
    // Clean all the global errors
    if (this.props.globalErrors[GLOBAL_ERRORS_CLEAN]) {
      this.props.globalErrors[GLOBAL_ERRORS_CLEAN]();
    }
    // If there are no errors, execute the onSubmit from event
    // If the captcha V2 token is needed, should not continue the submit
    const isSecureFieldsValid = await this.props.validatorProvider?.isSecureFieldsValid(this.props.mercadoPagoSdkActions);
    const isFormValid = this.props.validatorProvider.isValid();
    const invalidForm = (!isFormValid || !isSecureFieldsValid)

    if (invalidForm || (this.props.blockedByCaptcha && this.props.tokenCaptchaV3Needed !== undefined)) {
      // add global error with if form failed to submit
      if (invalidForm) {
        this.props.loadingActions && this.props.loadingActions[LOADER_TRIGGERED]('', null);
        this.props.globalErrors[GLOBAL_ERRORS_ADD]({
          id: 'invalid_form',
          validations: [
            {
              error: '',
              show: false,
            },
          ],
        });
      }
    } else if (this.props.onSubmit) {
      this.props.onSubmit(event);

      if (this.props.clearAll) {
        this.props.inputValuesActions[CLEAN_INPUT_VALUES]();
      }
    } else {
      const queryParams = getQueryParams();

      // If there is no error and we need to submit the form via AJAX
      event.preventDefault();

      // tokenCaptchaV3Needed should come from backend to know when the token V3 should be request
      if (this.props.tokenCaptchaV3Needed && !window.captchaError) {
        // If captcha token V3 is needed should go to this submit to get the token first
        this.onSubmitWithCaptchaV3(form, queryParams);
      } else if (this.props.openBottomSheet) {
        this.nextStepAction(this.props.paymentMethodForm, queryParams, form[0].defaultValue);
        this.props.bottomSheetActions[BOTTOM_SHEET]({ openBottomSheet: false });
      } else {
        this.nextStepAction(form, queryParams);
      }
    }
  }

  onSubmitWithCaptchaV3(form, queryParams) {
    // If the Captcha from google is already loaded and ready to execute
    if (window.captchaReady) {
      this.requestTokenV3AndContinue(form, queryParams);
    } else if (window.captchaError) {
      // If Google's api fail to load should continue with normal path, without Token
      this.nextStepAction(form, queryParams);
    } else {
      // In case Captcha from google is still loading we will save in window the callback function to execute when ready
      window.executeAction = () => this.requestTokenV3AndContinue(form, queryParams);
      // If Google's Api is still loading should save a callback method to continue with normal path if Google's Api fail to load
      window.executeErrorAction = () => {
        this.nextStepAction(form, queryParams);
      };
    }
  }

  setCollapsibleStatus() {
    if (this.areCollapsiblesOpened()) {
      return 'collapsible-open';
    }
    return '';
  }

  areCollapsiblesOpened() {
    return (
      this.state.collapsible &&
      (this.state.collapsible.installments_collapsible_selector || this.state.collapsible.payment_option_selector)
    );
  }

  requestTokenV3AndContinue(form, queryParams) {
    const requestId = uuidv4();
    this.props.request[REQUEST_STARTED]({ requestId });
    this.generateTokenCaptchaV3()
      .then((token) => {
        this.props.request[REQUEST_FINISHED]({
          requestId,
          leaveLoading: true,
        });
        if (this.props.captchaToken) {
          this.props.captchaToken[SAVE_CAPTCHA_TOKEN](token);
        }

        this.nextStepAction(form, queryParams);
      })
      .catch(() => {
        this.props.request[REQUEST_FINISHED]({
          requestId,
          leaveLoading: true,
        });
        // If execute action from grecaptcha fails, should not stop the payment
        this.nextStepAction(form, queryParams);
      });
  }

  nextStepAction(form, queryParams, email) {
    this.props.stepActions[STEP_NEXT](
      form,
      this.props.flow.id,
      {
        type: this.props.flow.type,
        urlParams: queryParams,
        email,
      },
      this.props.flow.type,
      queryParams,
      this.props.history,
    );

    if (this.props.clearAll) {
      this.props.inputValuesActions[CLEAN_INPUT_VALUES]();
    }
  }

  async generateTokenCaptchaV3() {
    const promiseToken = new Promise((resolve, reject) => {
      try {
        return window.grecaptcha.enterprise
          .execute(this.props.captcha.siteKeyV3, { action: this.props.captcha.action })
          .then((token) => resolve(token));
      } catch (error) {
        reject(error);
      }
    });
    return promiseToken;
  }

  render() {
    let classNames = 'form';

    if (this.props.className) {
      classNames += ` ${this.props.className}`;
    }

    return (
      <form
        key={this.props.key}
        id={this.props.id}
        className={`${classNames} ${this.setCollapsibleStatus()}`}
        method={this.props.method}
        onSubmit={this.onSubmit}
        noValidate={this.props.noValidate}
        autoComplete="off"
        ref={(e) => {
          this.form = e;
        }}
      >
        {this.props.children}
      </form>
    );
  }
}

Form.propTypes = {
  id: PropTypes.string,
  key: PropTypes.string,
  className: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  noValidate: PropTypes.bool,
  history: PropTypes.object, // eslint-disable-line
  flow: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  clearAll: PropTypes.bool,
  globalErrors: PropTypes.object, // eslint-disable-line
  validatorProvider: PropTypes.object, // eslint-disable-line
  inputValuesActions: PropTypes.object, // eslint-disable-line
  loadingActions: PropTypes.object, // eslint-disable-line
  stepActions: PropTypes.object, // eslint-disable-line
  mercadoPagoSdkActions: PropTypes.object, // eslint-disable-line
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  captcha: PropTypes.shape({
    siteKeyV2: PropTypes.string,
    siteKeyV3: PropTypes.string,
    lang: PropTypes.shape({
      MLA: PropTypes.string,
      MLB: PropTypes.string,
      MLM: PropTypes.string,
      MLC: PropTypes.string,
      MCO: PropTypes.string,
      MLU: PropTypes.string,
      MPE: PropTypes.string,
    }),
    action: PropTypes.string,
  }).isRequired,
};

Form.defaultProps = {
  id: '',
  key: '',
  className: '',
  method: 'POST',
  onSubmit: null,
  noValidate: true,
  history: null,
  flow: {
    id: '',
    type: '',
  },
  clearAll: false,
  globalErrors: {},
  validatorProvider: {},
  inputValuesActions: {},
  stepActions: {},
  children: null,
  loadingActions: {
    [LOADER_TRIGGERED]: () => { },
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActions, dispatch),
  mercadoPagoSdkActions: bindActionCreators(MercadoPagoSdkActions, dispatch),
  globalErrors: bindActionCreators(globalErrors, dispatch),
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
  request: bindActionCreators(request, dispatch),
  bottomSheetActions: bindActionCreators(bottomSheetActions, dispatch),
  loadingActions: bindActionCreators(LoadingActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  collapsible: state.collapsible,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  executeTokenV3: state.captchaToken.executeTokenV3,
  captcha: state.configurations.captcha,
  openBottomSheet: state.bottomSheet.openBottomSheet,
  paymentMethodForm: state.bottomSheet.paymentMethodForm,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Form;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = injectValidator(
    connect(mapStateToProps, mapDispatchToProps)(Form),
  );
}
