const React = require('react');
const PropTypes = require('prop-types');

const UserDefaultIcon = require('../icons/UserDefault');
const UserAvatar = require('../UserAvatar');
const { shortenString } = require('../../utils/Strings');

class LogoutRow extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    this.context.router.history.push('logout');
  }

  render() {
    const {
      text,
      userName,
      imageURL,
    } = this.props;

    const avatar = imageURL ? <UserAvatar image={imageURL} /> : <UserDefaultIcon />;
    const maxUsernameLength = 15;
    const username = shortenString(userName, maxUsernameLength);
    return (
      <div className="logout-row" onClick={this.redirect}>
        <div className="user-name">
          {text}
          <span> {username}</span>
        </div>
        {avatar}
      </div>
    );
  }
}

LogoutRow.propTypes = {
  text: PropTypes.string,
  userName: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
};

LogoutRow.defaultProps = {
  text: '',
  imageURL: '', // TODO: get default image URL
};

LogoutRow.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      createHref: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

module.exports = LogoutRow;
