const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const ExtraData = ({ color }) => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="22" height="29" viewBox="0 0 22 29">
      <g fill="none">
        <path
          fill="#FFF"
          stroke={color}
          d="M.5 27.56l2.25-1.8 2.75 2.2 2.75-2.2 2.75 2.2 2.75-2.2 2.75 2.2 2.75-2.2 2.25 1.8V2A1.5 1.5 0 0 0 20 .5H2A1.5 1.5 0 0 0 .5 2v25.56z"
        />
        <path
          d="M4.4 7.7h13.2v2.2H4.4zm0 4.4h13.2v2.2H4.4zm0 4.4H11v2.2H4.4z"
          fill={color}
          fillOpacity=".3"
        />
      </g>
    </svg>
  </IconWrapper>
);

ExtraData.defaultProps = {
  color: 'currentColor',
};

ExtraData.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(ExtraData);
