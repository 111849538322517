/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  TRACKING_LOADED,
  EXECUTE_TRACKING,
} = require('../actions/types');

const actions = {};

const initialState = {
  loaded: false,
  trackData: null,
};

actions[TRACKING_LOADED] = (state, action) => ({
  loaded: action.payload,
});

actions[EXECUTE_TRACKING] = (state, action) => ({
  ...state,
  trackData: action.payload,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
