const React = require('react');
const PropTypes = require('prop-types');

const Shield = require('../../icons/ShieldBPPDisabled');
const IconTooltip = require('../../IconWithTooltip');
const Markdown = require('../../Markdown');
const { COMMONS: {TOOLTIP_TYPE} } = require('../../../../constants');

const icons = {
  shieldBPPDisabled: <Shield />,
};

const Disclaimer = (props) => (
  <div className="disclaimer-row">
    {props.iconName && icons[props.iconName]}
    {props.message && <span className="text">{props.message}</span>}
    {props.tooltipContent && (
      <IconTooltip
        side={props.tooltipSide}
        type={props.tooltipType}
        text={<Markdown text={props.tooltipContent} />}
        icon={props.tooltipIcon}
      />
    )}
    {props.children}
  </div>
);

Disclaimer.propTypes = {
  message: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  tooltipSide: PropTypes.string,
  tooltipType: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipIcon: PropTypes.string,
  children: PropTypes.node,
};

Disclaimer.defaultProps = {
  message: '',
  iconName: null,
  tooltipSide: 'top',
  tooltipType: TOOLTIP_TYPE.LIGHT,
  tooltipContent: '',
  tooltipIcon: 'info',
  children: null
};

module.exports = Disclaimer;
