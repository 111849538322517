const React = require('react');

const IconWrapper = require('../IconWrapper');

const Failure = () => (
  <IconWrapper className="icon-failure">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-651 -179) translate(511 163) translate(28) translate(112 16)">
                <circle cx="16" cy="16" r="16" fill="#F23D4F" />
                <path fill="#FFF" fillRule="nonzero" d="M11.2 10.346l4.801 4.802 4.795-4.793.849.849-4.795 4.793 4.795 4.794-.849.849-4.794-4.795L11.2 21.65l-.849-.849 4.802-4.803-4.802-4.802.849-.849z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = Failure;
