const React = require('react');
const PropTypes = require('prop-types');

const { Text, Title } = require('@andes/typography');
const { Pill } = require('@andes/badge');
const ProtectedPurchase = require('@andes/icons/ProtectedPurchase16');
const CardList = require('@cow/core-components/components/CardListV2');

const IconShieldBPPDisabled = require('../../../../../components/icons/ShieldBPPDisabled');
const PaymentOptionPropTypes = require('../../templatePropTypes');
const {
  DEVICE_TYPE: { MOBILE, DESKTOP },
} = require('../../../../../../constants/commons');

const CardListGuestSection = (props) => (
  <>
    <div className="login-section__title">
      <Title component="h2" size="xs">
        {props.loginSection.title}
      </Title>

      {props.deviceType === DESKTOP && props.loginSection.assuredPurchase && (
        <Pill color="green" size="small" className="badge-list-item">
          {props.loginSection.assuredPurchase.toUpperCase()}
          <ProtectedPurchase color="white" />
        </Pill>
      )}
    </div>

    {props.deviceType === DESKTOP && props.loginSection.description && (
      <Text size="m" color="secondary" component="p" className="login-section__description">
        {props.loginSection.description}
      </Text>
    )}

    <CardList
      idList="login"
      deviceType={props.deviceType}
      items={props.loginSection.rows.map((item) => ({
        ...item,
        fullTitle:
          props.deviceType === MOBILE && props.loginSection.assuredPurchase
            ? `${item.title}, ${props.loginSection.assuredPurchase} `
            : null,
        description:
          props.deviceType === MOBILE && props.loginSection.description ? props.loginSection.description : null,
        withChevron: true,
        onClick: () => {
          props.goToScreen(item);
        },
        rightContent: (
          <>
            {props.deviceType === MOBILE && props.loginSection.assuredPurchase && (
              <Pill
                aria-hidden
                color="green"
                size="small"
                className="badge-list-item"
                roundedCorners={['top-right', 'bottom-left']}
              >
                {props.loginSection.assuredPurchase.toUpperCase()}
                <ProtectedPurchase color="white" />
              </Pill>
            )}
          </>
        ),
      }))}
    />

    <Title component="h2" size="xs" className="guest-section__title">
      {props.guestSection.title}
    </Title>

    {props.guestSection.description && (
      <Text size="xs" weight="semibold" color="secondary" component="p" className="guest-section__description">
        {props.guestSection.description.toUpperCase()}
        <IconShieldBPPDisabled />
      </Text>
    )}

    {!!props.otherPaymentMethods.length && (
      <CardList
        idList="other-options"
        withDividers
        deviceType={props.deviceType}
        items={props.otherPaymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          withChevron: true,
          onClick: () => {
            if (props.isTheOptionToPayWithCash(paymentMethod)) {
              props.showPayWithCashSection(paymentMethod.id);

              return;
            }

            props.goToScreen(paymentMethod);
          },
        }))}
      />
    )}
  </>
);

CardListGuestSection.propTypes = {
  otherPaymentMethods: PaymentOptionPropTypes.frontendData.otherPaymentMethods,
  loginSection: PaymentOptionPropTypes.frontendData.loginSection,
  guestSection: PaymentOptionPropTypes.frontendData.guestSection,
  goToScreen: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  showPayWithCashSection: PropTypes.func.isRequired,
  isTheOptionToPayWithCash: PropTypes.func.isRequired,
};

module.exports = CardListGuestSection;
