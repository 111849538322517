const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');

const { BUTTON_HIERARCHY } = require('../../../constants/commons');

const SubmitButton = (props) => (
    <Button hierarchy={BUTTON_HIERARCHY.LOUD} onClick={props.handleSubmit}>
      {props.text}
    </Button>
  );

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = SubmitButton;
