const React = require('react');

const IconWrapper = require('../IconWrapper');
const PixOutline = require('@cow/core-components/components/Icons/PixOutline');

const Pix = () => (
  <IconWrapper>
    <PixOutline className="icon-pix-outline" />
  </IconWrapper>
);

module.exports = React.memo(Pix);
