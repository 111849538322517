import React, { JSXElementConstructor, ReactElement } from 'react';

import { Switch } from '@andes/switch';
import { Card, CardContent } from '@andes/card';
import { Typography } from '@andes/typography';

import { Theme } from '../../constants/commons';
import { Thumbnail, ThumbnailSize } from '@andes/thumbnail';

export type CardSwitchProps = {
  id?: string;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  onChange?: () => void;
  value?: boolean;
  description?: string;
  callToAction?: React.ReactNode;
  className?: string;
  theme?: Theme.ML | Theme.MP;
  iconSize?: ThumbnailSize;
  disabled?: boolean;
};

export default function CardSwitch({
  onChange,
  value = false,
  id,
  description,
  icon,
  theme = Theme.MP,
  className = '',
  iconSize = '40',
  disabled = false,
}: CardSwitchProps) {
  return (
    <div id={id} className={`cow-card-switch ${className} ${theme}`}>
      <Card>
        <CardContent>
          <Thumbnail size={iconSize} className="cow-card-switch__icon-wrapper">
            {icon}
          </Thumbnail>

          <div className="cow-card-switch__texts">
            <div>
              <Typography type="body" size="s" color="primary">
                {description}
              </Typography>
            </div>
          </div>

          <Switch
            className="cow-card-switch__switch"
            defaultChecked={value}
            checked={value}
            srLabel="Pay with account money as alternative option"
            onChange={onChange}
            disabled={disabled}
          />
        </CardContent>
      </Card>
    </div>
  );
}
