const React = require('react');

const IconWrapper = require('../IconWrapper');

const TimeOut = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="68" viewBox="0 0 66 68" className="icon-timeout">
      <g fill="none" fillRule="evenodd">
        <g strokeWidth="1.5" transform="translate(.7 .7)">
          <circle cx="24.85" cy="29.75" r="24.85" stroke="#9BA9BB" strokeLinecap="round" />
          <path stroke="#9BA9BB" strokeLinecap="square" d="M24.85 4.9V.7" />
          <path stroke="#9BA9BB" strokeLinecap="round" d="M18.55.35h12.6" />
          <path d="M25.2 8.25v5.047M25.2 45.571v5.048" fillRule="nonzero" stroke="#D7DDE4" strokeLinecap="round" />
          <path stroke="#D7DDE4" strokeLinecap="round" d="M4.05 29.75h5.047" />
          <path stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" d="M43.4 29.754H25.2V20.3" />
        </g>
        <path fill="#D7DDE4" d="M48.65 67.2c-9.07 0-16.45-7.38-16.45-16.45S39.58 34.3 48.65 34.3 65.1 41.68 65.1 50.75 57.72 67.2 48.65 67.2" />
        <path fill="#F0F0F0" d="M48.65 35.7c-8.298 0-15.05 6.752-15.05 15.05 0 8.299 6.752 15.05 15.05 15.05 8.299 0 15.05-6.752 15.05-15.05 0-8.298-6.752-15.05-15.05-15.05" />
        <path d="M44.479 46.579l9.092 9.092M44.331 55.473l9.092-9.091" stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.5" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = TimeOut;
