/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const buttonActions = require('../../spa/actions/button');
const {
  BUTTON_CLICK_TRIGGERED,
} = require('../../spa/actions/types');

class ButtonTriggerAction extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    const buttonId = `${this.props.step}_${this.props.id}`;
    this.props.buttonActions[BUTTON_CLICK_TRIGGERED]({ buttonId });

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  render() {
    const {
      id,
      name,
      kind,
      text,
      className,
      onClick, // Do not remove it or it will be overriden,
      ...buttonProps } = this.props;
    let classNames = 'andes-button';

    if (className) {
      classNames += ` ${className}`;
    }

    if (kind) {
      classNames += ` andes-button--${kind}`;
    }

    return (

      <button
        className={classNames}
        id={id}
        name={name}
        onClick={this.handleClick}
        {...buttonProps}
      >
        <span className="andes-button__content">{text}</span>
      </button>
    );
  }
}

/**
 * Prop Types
 */
ButtonTriggerAction.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

/**
 * Default Props
 */

ButtonTriggerAction.defaultProps = {
  id: null,
  name: '',
  className: '',
  kind: 'loud',
  text: '',
  onClick: null,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  buttonActions: bindActionCreators(buttonActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  step: state.page.flow.step,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonTriggerAction;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(ButtonTriggerAction); // eslint-disable-line max-len
}
