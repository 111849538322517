const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');

const Stepper = ({ id, current, total, i18n }) => {


  const translations = translate(i18n);
  return (

    <div id={id} className="stepper" >
      <p className="stepper__secondary-text">
        {translations.STEP_OF(current, total)}
      </p>
    </div>
  )
}

Stepper.defaultProps = {
  i18n: {
    gettext: t => t
  }
}

Stepper.propTypes = {
  id: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func
  }),
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

module.exports = injectI18n(Stepper)
