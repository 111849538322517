const logger = require('nordic/logger')('melidata');

const { COMPONENT_ID } = require('../../constants/app');

const getData = (components) => {
  let tracking = null;

  if (components) {
    const trackingComponent = components.find((component) => component.id === COMPONENT_ID.TRACKING);

    if (trackingComponent && trackingComponent.value && trackingComponent.value.melidata) {
      tracking = {
        path: trackingComponent.value.melidata.path,
        payload: trackingComponent.value.melidata.data,
        experiments: trackingComponent.value.melidata.experiments,
      };
    } else {
      logger.info('Missing melidata tracking component');
    }
  }

  return tracking;
};

module.exports = {
  getData,
};
