const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');

const CardHint = (props) => {
  const { i18n, cvvOnFront } = props;
  const translations = translate(i18n);
  const accessibilityHint = cvvOnFront
    ? translations.THE_4_DIGITS_ON_THE_FRONT_OF_THE_CARD
    : translations.LAST_3_DIGITS_ON_THE_BACK_OF_THE_CARD;
  return (
    <div className={`card-hint ${cvvOnFront ? 'front' : 'back'}`}>
      <span className="u-accessibility-hidden">{accessibilityHint}</span>
    </div>
  );
};

CardHint.propTypes = {
  cvvOnFront: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

CardHint.defaultProps = {
  cvvOnFront: false,
  i18n: {
    gettext: t => t,
  },
};

module.exports = injectI18n(CardHint);
