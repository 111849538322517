const { handleActions } = require('redux-actions');

const { EXTERNAL_REDIRECT_WINDOW } = require('../actions/types');


const actions = {};

const initialState = {
  window: '',
};

actions[EXTERNAL_REDIRECT_WINDOW] = (state, action) => ({
  window: action.payload,
});

module.exports = handleActions(actions, initialState);
