// TODO: recreate this component using all component of the Andes
const React = require('react');
const PropTypes = require('prop-types');

const { Text } = require('@andes/typography');
const { Tooltip } = require('@andes/tooltip');

const IconWithTooltip = require('../IconWithTooltip');
const Accordion = require('../Accordion');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const CONSTANTS = require('../../../constants');
const { MAX_LENGTH_SUMMARY_ROW, MAX_LENGTH_TOOLTIP } = require('../RowSummary');
const { getFloatValue: getFloatValueUtil } = require('../../utils/currency');
const { reduceLongWord, splitLongWord, isLongWord } = require('../../utils/Strings');

const getFloatValue = (value) =>
  getFloatValueUtil(value, {
    symbol: CONSTANTS.APP.CURRENCY_SYMBOL.BRL,
    decimal_separator: ',',
    thousands_separator: '.',
  });

const RowCollapsible = ({
  id,
  value: values,
  deviceType,
  defaultOpened: defaultOpenedProp = false,
  showChevron: showChevronProp = true,
  installment = {},
}) => {
  const fontSize = React.useMemo(
    () => (deviceType === CONSTANTS.COMMONS.DEVICE_TYPE.DESKTOP ? 'm' : 's'),
    [deviceType],
  );

  const interestAmountText = values
    ?.find((v) => v.id === 'interest_amount_row_collapsible')
    ?.value.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.PRICE)?.text;
  const interestAmountValue = interestAmountText !== 'R$ 0' ? getFloatValue(interestAmountText) : 0;
  const hasInterestAmount =
    (interestAmountValue || (!!installment.interestAmount && getFloatValue(installment.interestAmount))) > 0;

  const showChevron = isNaN(+installment.value) === false && hasInterestAmount && showChevronProp === true;

  React.useEffect(() => {
    const groupSummaryElement = document.querySelector('.group-summary');
    const pcjClassName = 'group-summary--pcj';
    const action = showChevron ? 'add' : 'remove';
    groupSummaryElement?.classList?.[action]?.(pcjClassName);
  }, [showChevron]);

  const defaultOpened = (showChevron === false ? defaultOpenedProp : false) && defaultOpenedProp === true;

  const label = React.useMemo(
    () => values?.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.TEXT)?.text,
    [values],
  );

  const value = () => {
    if (!!installment.totalWithInterest) {
      return installment.totalWithInterest;
    }

    return values?.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.PRICE)?.text;
  };

  const items = values
    ?.filter((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.ROW_SUMMARY)
    ?.map((v) => {
      let value = v.value.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.PRICE)?.text;

      const isInterestAmountRow = v.id === 'interest_amount_row_collapsible';

      if (isInterestAmountRow && isNaN(+installment.value) === false && !!installment.interestAmount) {
        value = installment.interestAmount;
      }

      if (isInterestAmountRow && interestAmountValue > 0) {
        value = interestAmountText;
      }

      return {
        id: v.id,
        value,
        label: v.value.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.TEXT)?.text,
        information: v.value.find((v) => v.type === CONSTANTS.COMMONS.ELEMENT_TYPE.INFORMATION)?.text,
      };
    });

  const labelIsLarge = label.length > MAX_LENGTH_TOOLTIP || isLongWord(label, MAX_LENGTH_SUMMARY_ROW)

  const renderLabel = ({ label, fontSize, labelIsLarge }) => {
    if (labelIsLarge) {
      return (
        <Tooltip content={splitLongWord(label, MAX_LENGTH_TOOLTIP)}>
          <Text size={fontSize} color="primary">
            {reduceLongWord(label, MAX_LENGTH_SUMMARY_ROW)}
          </Text>
        </Tooltip>
      );
    }

    return (
      <Text size={fontSize} color="primary">
        {label}
      </Text>
    );
  };

  return (
    <Accordion
      id={id}
      defaultOpened={deviceType === CONSTANTS.COMMONS.DEVICE_TYPE.MOBILE ? hasInterestAmount : defaultOpened}
      showChevron={showChevron}
      deviceType={deviceType}
      title={
        <div className="row-collapsible__title">
          <div className={`row-collapsible__title__label ${labelIsLarge ? 'row-collapsible__title__label--large' : ''}`}>
            {renderLabel({ label, fontSize, labelIsLarge })}
          </div>

          <div className="row-collapsible__title__value">
            <Text size={fontSize} color="primary">
              {value()}
            </Text>
          </div>
        </div>
      }
    >
      <div className="row-collapsible__content">
        {items?.map((item) => (
          <div className="row-collapsible__content__row" key={item.id}>
            <div className="row-collapsible__content__row__label">
              <Text size="s" color="primary">
                {item.label}
              </Text>

              {item.information && (
                <IconWithTooltip
                  icon="info"
                  iconColor="var(--cow-light-blue)"
                  text={item.information}
                  type="dark"
                  side="top"
                />
              )}
            </div>

            <Text size="s" color="primary">
              {item.value}
            </Text>
          </div>
        ))}
      </div>
    </Accordion>
  );
};

RowCollapsible.propTypes = {
  id: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.objectOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
  ),
  deviceType: PropTypes.string,
  defaultOpened: PropTypes.bool,
  showChevron: PropTypes.bool,
  installment: PropTypes.shape({
    totalPurchase: PropTypes.string,
    label: PropTypes.string,
  }),
};

RowCollapsible.defaultProps = {
  deviceType: CONSTANTS.COMMONS.DEVICE_TYPE.DESKTOP,
  installment: {
    label: '',
  },
};

const mapStateToProps = (state) => ({
  deviceType: state?.configurations.deviceType,
  installment: state.installment,
});

module.exports = connectToReduxAndInjectI18n(RowCollapsible, mapStateToProps);
