const React = require('react');
const PropTypes = require('prop-types');

const { QRCodeSVG } = require('qrcode.react');

const QrCode = ({ value }) => (
  <div className="qr-code">
    <QRCodeSVG renderAs="svg" value={value} />
  </div>
);

QrCode.propTypes = {
  value: PropTypes.string,
};

QrCode.defaultProps = {
  value: '',
};

module.exports = QrCode;
