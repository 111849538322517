const { DOCUMENTS } = require('../../constants/app');

const formatRemoveSpaces = (str, target = {}) => {
  const value = str?.replace(/\s/g, '');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatStringToNumber = (str, target = {}) => {
  const value = str?.match(/\d/g)?.join('') || '';

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatString = (str, target = {}) => {
  const value = str?.match(/^[A-Za-zÁ-Úá-ú'\s]+/g)?.join('') || '';

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatYapePhone = (phone, target = {}) => {
  const value = formatStringToNumber(phone)
    ?.replace(/^([0-9]{3})([0-9])/g, '$1 $2')
    ?.replace(/^([0-9]{3})\s([0-9]{3})([0-9])/g, '$1 $2 $3');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatDocument = (document, type, target = {}) => {
  let value = formatStringToNumber(document);

  switch (type) {
    case DOCUMENTS.CPF:
      value = value
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d{1,2})/, '$1-$2')
        ?.replace(/(-\d{2})\d+?$/, '$1');
      break;

    case DOCUMENTS.CNPJ:
      value = value
        ?.replace(/(\d{2})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1/$2')
        ?.replace(/(\d{4})(\d)/, '$1-$2')
        ?.replace(/(-\d{2})\d+?$/, '$1');
      break;

    default:
      return document;
  }

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatZipcode = (zipcode, target = {}) => {
  const value = formatStringToNumber(zipcode)?.replace(/^(\d{5})(\d)/, '$1-$2');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

module.exports = {
  formatString,
  formatRemoveSpaces,
  formatStringToNumber,
  formatYapePhone,
  formatDocument,
  formatZipcode,
};
