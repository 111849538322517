const React = require('react');
const PropTypes = require('prop-types');

const {connect} = require('react-redux');

const {useState, useEffect} = React;

const hiddableComponent = (Component) => {
  const WrapperComponent = ({visibility, step, show, ...props}) => {
    const [hidden, setHidden] = useState(!show);

    useEffect(() => {
      if (typeof visibility !== 'undefined') {
        setHidden(!visibility);
      }
    }, [visibility]);

    if (hidden) {
      return null;
    }

    return (
      <Component {...props} />
    );
  }

  /* istanbul ignore next: can't test it with tests */
  const mapStateToProps = (state, ownProps) => {
    const {step} = state.page.flow;
    const storeValue = state.inputValues?.visibility[`${step}_${ownProps.storeKey}`];
    const visibility = typeof storeValue !== 'undefined' ? storeValue : ownProps.show;
    return {
      visibility,
      step,
    }
  };

  WrapperComponent.propTypes = {
    storeKey: PropTypes.string,
    visibility: PropTypes.bool,
    show: PropTypes.bool,
    step: PropTypes.string,
  };

  WrapperComponent.defaultProps = {
    show: false,
    step: ''
  };

  if (process.env.NODE_ENV === 'test') {
    return WrapperComponent;
  }
  /* istanbul ignore next: can't test it with tests */
  return connect(mapStateToProps, null)(WrapperComponent);
}

module.exports = hiddableComponent;

