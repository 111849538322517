/**
 * Module dependencies
 */
const { StyleSheet: Aphrodite } = require('aphrodite/no-important');

/**
 * Enables support for nested rule "&"" to reference selector of the parent rule.
 */
const { StyleSheet, css } = Aphrodite.extend([{
  selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
    const nestedTags = [];

    if (selector[0] === '&') {
      const selectors = selector.split(',');

      for (let i = 0; i < selectors.length; i += 1) {
        const space = selector[1] === ' ' ? ' ' : '';
        const tag = i === 0 ? selectors[i].slice(1) : selectors[i];

        nestedTags.push(generateSubtreeStyles(`${baseSelector}${space}${tag}`.replace(/ +(?= )/g, '')));
      }
    }

    return nestedTags.length > 0 ? nestedTags : null;
  },
}]);

/**
 * Expose Aphrodite functions
 */
module.exports = { StyleSheet, css };
