const React = require('react');
const PropTypes = require('prop-types');

const { Checkbox } = require('@andes/checkbox');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const { defaultProps } = require('../../containers/Optimus/helper');
const Button = require('../../containers/Button/ButtonWithStore');
const modalActions = require('../../spa/actions/modal');
const { TOGGLE_MODAL_VISIBILITY } = require('../../spa/actions/types');


class InputAction extends BaseValidation {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.value || '',
      checked: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.getChildComponent = this.getChildComponent.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange(event) {
    this.onChange(event); // From BaseValidation
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  updateValue(value) {
    // Update the state
    this.setState({
      value,
    });
  }

  onCheckboxChange(event) {
    const { checked } = event.target;
    if (checked && this.props.childvalue) {
      this.setState({
        checked,
        value: this.props.childvalue,
      });
    } else {
      this.setState({ checked, value: '' });
    }
  }

  getChildComponent() {
    if (this.props.kind !== 'no_link') {
      const components = {
        link: <Button
          {...defaultProps()}
          kind="quiet"
          text={this.props.childlabel}
          onClick={(event) => {
            event.preventDefault();
            if (this.props.next_page) {
              location.hash = `#${this.props.next_page}`; // eslint-disable-line
            }
          }}
        />,
        checkbox: <Checkbox
          label={this.props.childlabel}
          onChange={this.onCheckboxChange}
          checked={this.state.checked}
        />,
        modal_link: <Button
          {...defaultProps()}
          kind="transparent"
          text={this.props.childlabel}
          onClick={(event) => {
            event.preventDefault();
            this.props.modalActions[TOGGLE_MODAL_VISIBILITY]();
          }}
        />,
      };
      return components[this.props.kind];
    }
    return null;
  }

  render() {
    const {
      errors,
      validations,
      modalActions,
      initialValue,
      kind,
      className,
      childlabel,
      childvalue,
      ...inputProps
    } = this.props;

    return (
      <BaseInput
        {...inputProps}
        id={this.props.id}
        name={this.props.name}
        className={`input-action ${className}`}
        onChange={this.handleChange}
        error={this.state.error}
        invalid={this.state.invalid}
        updateCallback={(value) => { this.updateValue(value); }}
        value={this.state.value}
      >
        {this.getChildComponent()}
      </BaseInput>
    );
  }
}

InputAction.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  updateCallback: PropTypes.func,
  onChange: PropTypes.func,
  modalActions: PropTypes.object, // eslint-disable-line
  value: PropTypes.string,
  childlabel: PropTypes.string, // Text to be displayed by the checkbox
  childvalue: PropTypes.string, // Input value when checkbox is checked
  kind: PropTypes.oneOf(['link', 'checkbox', 'modal_link', 'no_link']),
};

InputAction.defaultProps = {
  id: '',
  className: '',
  label: '',
  placeholder: '',
  kind: 'checkbox',
  message: '',
  error: [],
  invalid: false,
  updateCallback: null,
  onChange: null,
  modalActions: {},
  show: true,
};

const mapDispatchToProp = dispatch => ({
  modalActions: bindActionCreators(modalActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputAction;
} else {
  /* istanbul ignore next: cant test it with tests */module.exports = connect(null, mapDispatchToProp)(InputAction);
}
