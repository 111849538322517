const React = require('react');
const PropTypes = require('prop-types');

// Constants
const { CHECKOUT_VERSION } = require('../../../constants/commons');

const SiteSecurityWidget = (props) => {
  const { siteSecurity, version, locals } = props;

  const isVersionAllowed = React.useCallback(() => {
    const isCheckout = version === CHECKOUT_VERSION.CHECKOUT;
    const hasCardFormV2Enabled = locals?.checkout?.featureFlags.cardForm ?? false;

    return isCheckout || hasCardFormV2Enabled;
  }, [version, locals?.checkout?.featureFlags]);

  React.useEffect(() => {
    const siteSecurityScriptsContainer = document.querySelector('#site-security-scripts');
    if (
      siteSecurityScriptsContainer &&
      !document.querySelector('#site-security-widget') &&
      isVersionAllowed(version, locals?.checkout?.featureFlags) &&
      siteSecurity &&
      siteSecurity.widget &&
      siteSecurity.session_id
    ) {
      const container = document.createElement('div');
      container.id = 'site-security-widget';
      // reacreate the script template as DOM node
      const inlineScriptFromString = document.createRange().createContextualFragment(siteSecurity.widget);
      container.appendChild(inlineScriptFromString);
      siteSecurityScriptsContainer.appendChild(container);
    }
  }, [siteSecurity.widget, siteSecurity.session_id, version, locals?.checkout?.featureFlags]);

  return null;
};

SiteSecurityWidget.propTypes = {
  siteSecurity: PropTypes.shape({
    session_id: PropTypes.string,
    widget: PropTypes.string,
  }),
  version: PropTypes.string,
  locals: PropTypes.shape({
    checkout: PropTypes.shape({
      featureFlags: PropTypes.shape({
        cardForm: PropTypes.bool,
      }),
    }),
  }),
};

module.exports = SiteSecurityWidget;
