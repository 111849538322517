/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  IDENTIFICATION_CARD_CHANGE,
  IDENTIFICATION_CARD_TYPE,
  IDENTIFICATION_CARD_FOCUS,
  IDENTIFICATION_CARD_BLUR,
  IDENTIFICATION_CARD_CLEAN,
} = require('./types');

// Request Actions
const identificationCardChange = createAction(IDENTIFICATION_CARD_CHANGE);
const identificationCardType = createAction(IDENTIFICATION_CARD_TYPE);
const identificationCardFocus = createAction(IDENTIFICATION_CARD_FOCUS);
const identificationCardBlur = createAction(IDENTIFICATION_CARD_BLUR);
const identificationCardClean = createAction(IDENTIFICATION_CARD_CLEAN);

/**
 * Export functions
 */
module.exports = {
  [IDENTIFICATION_CARD_CHANGE]: (number) => (dispatch) => {
    dispatch(identificationCardChange(number));
  },
  [IDENTIFICATION_CARD_TYPE]: (type) => (dispatch) => {
    dispatch(identificationCardType(type));
  },
  [IDENTIFICATION_CARD_FOCUS]: () => (dispatch) => {
    dispatch(identificationCardFocus());
  },
  [IDENTIFICATION_CARD_BLUR]: () => (dispatch) => {
    dispatch(identificationCardBlur());
  },
  [IDENTIFICATION_CARD_CLEAN]: () => (dispatch) => {
    dispatch(identificationCardClean());
  },
};
