const React = require('react');
const PropTypes = require('prop-types');

const Button = require('../../containers/Button/ButtonWithStore');

const FullscreenMessage = (props) => {
  let classNames = 'fullscreen-message';

  if (props.className) {
    classNames += ` ${props.className}`;
  }

  return (
    <div className={classNames}>
      <div className="fullscreen-message__content">
        {props.title && <h1 className="fullscreen-message__title">{props.title}</h1>}
        {props.message && <p className="fullscreen-message__message">{props.message}</p>}
        {props.mainAction && (
          <Button
            text={props.mainAction.label}
            kind="loud"
            onClick={props.mainAction.onClick}
            className="fullscreen-message__button-primary"
          />
        )}
        {props.secondaryAction && (
          <Button
            text={props.secondaryAction.label}
            kind="quiet"
            onClick={props.secondaryAction.onClick}
            className="fullscreen-message__button-secondary"
          />
        )}
      </div>
    </div>
  );
};

FullscreenMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  mainAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

FullscreenMessage.defaultProps = {
  className: '',
  title: '',
  message: '',
  mainAction: {},
  secondaryAction: {},
};

module.exports = FullscreenMessage;
