const React = require('react');
const PropTypes = require('prop-types');

const IconWarningBadge = ({ title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="icon-warning-badge">
    {title
      && <title>{title}</title>}
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" fill="#E6540B" />
      <path d="M4 4h8v8H4z" opacity=".3" />
      <path fill="#FFF" d="M8.944 4.026l-.142 4.366a.567.567 0 1 1-1.134 0l-.141-4.366a.709.709 0 0 1 .708-.732.709.709 0 0 1 .709.732z" />
      <circle cx="8.235" cy="11.529" r="1" fill="#FFF" />
    </g>
  </svg>
);

IconWarningBadge.defaultProps = {
  title: '',
};

IconWarningBadge.propTypes = {
  title: PropTypes.string,
};

module.exports = IconWarningBadge;
