const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../../translation');

const Navbar = (props) => {
  const {
    siteId,
    theme,
    i18n,
    children,
  } = props;
  const translations = translate(i18n);

  let classNames = `navbar navbar--${siteId.toLowerCase()}`;

  if (theme) {
    classNames += ` navbar--${theme}`;
  }

  return (
    <div className={classNames}>
      <div className="navbar__container">
        {theme
          && (
            <span className="navbar__logo">
              {theme === 'ml'
                && <span className="u-accessibility-hidden">{translations.MERCADO_LIBRE}</span>}
              {theme === 'mp'
                && <span className="u-accessibility-hidden">{translations.MERCADO_PAGO}</span>}
            </span>
          )}
        {children}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  theme: PropTypes.string,
  siteId: PropTypes.string,
};

Navbar.defaultProps = {
  theme: '',
  siteId: '',
};

if (process.env.NODE_ENV === 'test') {
  module.exports = Navbar;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(Navbar);
}
