const React = require('react');
const PropTypes = require('prop-types');

const IconCreditCard = require('../icons/CreditCard');
const ShoppingBag = require('../icons/ShoppingBag');
const IconCheckBadge = require('../icons/CheckBadge');
const IconErrorBadge = require('../icons/ErrorBadge');
const IconInstructionsBadge = require('../icons/InstructionsBadge');
const IconWarningBadge = require('../icons/WarningBadge');
const Wallet = require('../icons/Wallet');
const AccountMoney = require('../icons/AccountMoney');
const SecureLock = require('../icons/SecureLock');

const Avatar = (props) => {
  const { status, icon } = props;
  let baseClassname;
  let avatarComponent;
  let color;
  let statusIcon = null;

  switch (status) {
    case ('approved'):
      color = '#0db478';
      statusIcon = <IconCheckBadge />;
      break;
    case ('rejected'):
      color = '#f04449';
      statusIcon = <IconErrorBadge />;
      break;
    case ('recover'):
      color = '#ff7733';
      statusIcon = <IconWarningBadge />;
      break;
    case ('instructions'):
      color = '#0db478';
      statusIcon = <IconInstructionsBadge />;
      break;
    default: color = '#0db478';
  }

  switch (icon) {
    case ('creditCard'):
      avatarComponent = <IconCreditCard color={color} avatar />;
      baseClassname = 'avatar--credit-card';
      break;
    case ('shopping'):
      avatarComponent = <ShoppingBag color={color} />;
      baseClassname = 'avatar--shopping-bag';
      break;
    case ('wallet'):
      avatarComponent = <Wallet color={color} />;
      baseClassname = 'avatar--wallet';
      break;
    case ('accountMoney'):
      avatarComponent = <AccountMoney color={color} />;
      baseClassname = 'avatar--account-money';
      break;
    case ('secureLock'):
      avatarComponent = <SecureLock color={color} />;
      baseClassname = 'avatar--secure-lock';
      break;
    default:
      avatarComponent = <IconCreditCard color={color} />;
      baseClassname = 'avatar--credit-card';
  }

  let classNames = `avatar ${baseClassname}`;

  if (status) {
    classNames += ` avatar--${status}`;
  }

  return (
    <div className={classNames}>
      {avatarComponent}
      {statusIcon
        && statusIcon}
    </div>
  );
};

Avatar.propTypes = {
  icon: PropTypes.string,
  status: PropTypes.string,
};

Avatar.defaultProps = {
  icon: '',
  status: '',
};

module.exports = Avatar;
