const React = require('react');

const NoCollaborator = () => (
  <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="112" height="89" viewBox="0 0 112 89">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeWidth="1.8"
        d="M51.151 51.84c-14.006 0-25.401-11.404-25.401-25.421C25.75 12.403 37.145 1 51.151 1c14.007 0 25.402 11.403 25.402 25.419 0 14.017-11.395 25.42-25.402 25.42"
      />
      <path
        fill="#D7DDE4"
        d="M75.25 21.857a24.544 24.544 0 0 0-5.773-12.22 25.205 25.205 0 0 0-5.698-4.853A25.289 25.289 0 0 0 50.45 1c-12.3 0-22.516 8.736-24.7 20.285 2.661.373 5.397.572 8.158.572 10.944 0 21.186-3.01 29.871-8.263v8.263H75.25z"
      />
      <path
        stroke="#9BA9BB"
        strokeWidth="1.8"
        d="M51.151 51.84c-14.006 0-25.401-11.404-25.401-25.421C25.75 12.403 37.145 1 51.151 1c14.007 0 25.402 11.403 25.402 25.419 0 14.017-11.395 25.42-25.402 25.42M14.026 72.696c1.05-7.404 6.627-13.035 14.548-13.035h45.27c7.901 0 13.378 5.638 14.432 13.035"
      />
      <path stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.8" d="M1 72.696h99" />
      <g>
        <path
          fill="#D7DDE4"
          d="M88.5 89C75.542 89 65 78.458 65 65.5S75.542 42 88.5 42 112 52.542 112 65.5 101.458 89 88.5 89"
        />
        <path
          fill="#F0F0F0"
          d="M88.5 44C76.645 44 67 53.645 67 65.5S76.645 87 88.5 87 110 77.355 110 65.5 100.355 44 88.5 44"
        />
        <g stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.8">
          <path d="M82.541 59.541L95.53 72.53M82.33 72.248l12.988-12.989" />
        </g>
      </g>
    </g>
  </svg>
);

module.exports = React.memo(NoCollaborator);
