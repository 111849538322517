/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  MERCADOPAGO_SDK_LOADED,
  MERCADOPAGO_SDK_TOKENIZE_ERRORS,
  MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN,
  MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN,
  MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION,
  MERCADOPAGO_SDK_SECURE_FIELD_MOUNT,
  MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT,
} = require('../actions/types');

const actions = {};

const initialState = {
  loaded: false,
  errors: [],
  exceptionThrown: false,
  secureFields: {},
};

actions[MERCADOPAGO_SDK_LOADED] = (state, action) => ({
  ...state,
  loaded: action.payload,
});
actions[MERCADOPAGO_SDK_TOKENIZE_ERRORS] = (state, action) => ({
  ...state,
  errors: action.payload,
});
actions[MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN] = (state) => ({
  ...state,
  errors: [],
});
actions[MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION] = (state, action) => ({
  ...state,
  exceptionThrown: action.payload,
});
actions[MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN] = (state, action) => ({
  ...state,
  cardToken: action.payload.cardToken,
  zeroDollarCardToken: action.payload.zeroDollarCardToken,
});
actions[MERCADOPAGO_SDK_SECURE_FIELD_MOUNT] = (state, { payload: { field, settings } }) => ({
  ...state,
  secureFields: {
    ...state.secureFields,
    [field]: {
      show: true,
      settings,
    },
  }
});
actions[MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT] = (state, { payload: { field } }) => ({
  ...state,
  secureFields: {
    ...state.secureFields,
    [field]: {
      show: false,
    },
  }
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
