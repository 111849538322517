const React = require('react');
const PropTypes = require('prop-types');

const BottomSheet = require('@andes/bottom-sheet');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');

const stepActionCreators = require('../../spa/actions/step');
const { BOTTOM_SHEET, STEP_NEXT } = require('../../spa/actions/types');
const bottomSheetActionCreators = require('../../spa/actions/bottomSheet');
const trackBottomSheet = require('../../utils/trackBottomSheet');
const { getData } = require('../../utils/melidataTracking');
const { trackInfo } = require('../../service/api');

const { useEffect } = React;

const BottomSheetEmail = ({
  openBottomSheet,
  title,
  children,
  bottomSheetActions,
  hiddenComponents,
  stateConfigs,
  paymentMethodForm,
}) => {
  const onClose = () => {
    const tracking = getData(hiddenComponents);

    if (openBottomSheet) {
      try {
        trackBottomSheet('bottom_sheet_closed', tracking, stateConfigs);
        bottomSheetActions[BOTTOM_SHEET]({ openBottomSheet: false, paymentMethodForm: null });
        paymentMethodForm.reset();
      } catch (e) {
        trackInfo({
          message: 'Error tracking bottom-sheet-close',
        });
      }
    }
  };

  /* istanbul ignore next: cant test it with tests */
  useEffect(() => {
    if (typeof window !== 'undefined' && window.hj) {
      window.hj('event', 'login_user_not_identified')
    }
  }, [])

  return (
    <BottomSheet open={openBottomSheet} title={title} onClose={onClose} initialHeight={500} maxHeight={280} id="email-bottom-sheet">
      {children}
    </BottomSheet>
  );
};

BottomSheetEmail.defaultProps = {
  i18n: {},
};

BottomSheetEmail.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  openBottomSheet: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  bottomSheetActions: PropTypes.shape({
    [BOTTOM_SHEET]: PropTypes.func,
  }),
  hiddenComponents: PropTypes.any,
  stateConfigs: PropTypes.any,
  stepActions: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
  }),
  paymentMethodForm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  openBottomSheet: state.bottomSheet.openBottomSheet,
  stateConfigs: state.configurations,
  hiddenComponents: state.page.data.hidden_components,
  paymentMethodForm: state.bottomSheet.paymentMethodForm,
});

const mapDispatchToProps = (dispatch) => ({
  bottomSheetActions: bindActionCreators(bottomSheetActionCreators, dispatch),
  stepActions: bindActionCreators(stepActionCreators, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = BottomSheetEmail;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectI18n(BottomSheetEmail));
}
