const React = require('react');
const PropTypes = require('prop-types');

const FeedbackScreen = require('@andes/feedback-screen');
const Thumbnail = require('@andes/thumbnail');
const { Dot } = require('@andes/badge');

const IconCreditCard = require('../icons/CreditCard');
const Markdown = require("../Markdown");

const Feedback = ({ title, color, dotType }) => (
  // defaultIcon creditCard if you want to support new icons use a hook or a switch
  <FeedbackScreen
    className="feedback-screen"
    header={{
      asset: <Thumbnail badge={<Dot color={dotType} />} size="64"><IconCreditCard color="#FF7733" /></Thumbnail>,
      color,
      title: <Markdown text={title} />,
    }}
  />
);

Feedback.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(['green', 'orange', 'red']),
  dotType: PropTypes.oneOf(['gray', 'accent', 'green', 'orange', 'red']),
};

Feedback.defaultProps = {
  title: '',
  color: 'orange',
  dotType: 'orange'
};

module.exports = Feedback;
