const React = require('react');
const PropTypes = require('prop-types');

const IconCross = ({ title, color }) => (
  <svg width="16" height="16" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
    {title
      && <title>{title}</title>}
    <path d="M7.194 0L4 3.194.806 0 0 .806 3.194 4 0 7.194.806 8 4 4.806 7.194 8 8 7.194 4.806 4 8 .806" fillRule="nonzero" fill={color} />
  </svg>
);

IconCross.defaultProps = {
  title: '',
  color: '#eee',
};

IconCross.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

module.exports = IconCross;
