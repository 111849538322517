import React from 'react';

const Bank = ({ width = 24, height = 24, ...props }: SVGProps) => (
  <svg {...props} width={width} height={height} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.999.632l9.751 5.67v3.447l-2.244.001v7.52h1.24l2.24 5.265H1.164l2.071-5.265h1.267V9.766l-2.253.004V6.302l9.749-5.67zm7.755 18.138H4.258l-.891 2.265h17.35l-.963-2.265zm-1.748-1.5V9.751l-5.257.006v7.511h5.257zm-6.757-7.51l-5.246.006v7.503h5.246V9.76zm.75-7.392L3.75 7.165v1.104l16.5-.018V7.165l-8.251-4.797z"
    />
  </svg>
);

export default React.memo(Bank);
