/**
 * Module dependencies
 */
const querystring = require('querystring');

const { createAction } = require('redux-actions');

const { getInstallments } = require('../../service/api');
const { COMPONENT_ID, URL } = require('../../../constants/app');
const {
  INSTALLMENT_UPDATE,
  INSTALLMENT_CLEAN,
  INSTALLMENT_REQUEST,
  INSTALLMENT_UPDATE_COMBINATION,
  INSTALLMENT_REQUEST_CLEAN,
} = require('./types');

// Request Actions
const installmentUpdate = createAction(INSTALLMENT_UPDATE);
const installmentClean = createAction(INSTALLMENT_CLEAN);
const installmentRequest = createAction(INSTALLMENT_REQUEST);
const installmentUpdateCombination = createAction(INSTALLMENT_UPDATE_COMBINATION);

/**
 * Export functions
 */
module.exports = {
  [INSTALLMENT_UPDATE]: installment => (dispatch) => {
    dispatch(installmentUpdate(installment));
  },
  [INSTALLMENT_CLEAN]: () => (dispatch) => {
    dispatch(installmentClean());
  },
  [INSTALLMENT_REQUEST_CLEAN]: () => (dispatch) => {
    dispatch(installmentRequest({ installment_information: [] }));
  },
  [INSTALLMENT_UPDATE_COMBINATION]: installment => (dispatch) => {
    dispatch(installmentUpdateCombination(installment));
  },
  [INSTALLMENT_REQUEST]: (amount) => (dispatch, getState) => {
    const currentState = getState();
    const {
      page: {
        data: {
          currentStep,
          hidden_components,
        }
      },
      configurations,
      installment,
    } = currentState;

    const siteId = configurations?.platform?.siteId;
    const keyDynamicComponent = hidden_components
      .find(c => c.id === COMPONENT_ID.INSTALLMENT_DYNAMIC);

    const keyDynamic = keyDynamicComponent?.value ? keyDynamicComponent.value : '';
    const pmSearchComponent = hidden_components.find(c => c.id === COMPONENT_ID.SELECTED_PAYMENT_METHOD_ID);
    const paymentMethodId = pmSearchComponent?.value ? pmSearchComponent.value : '';

    const queryParams = querystring.stringify({
      key: keyDynamic,
      site_id: siteId,
      amount,
      payment_method_id: paymentMethodId,
      no_cache: true
    })

    if (paymentMethodId.indexOf('deb') === -1 || currentStep === URL.FIRST_SPLIT_PAYMENT) {
      getInstallments(queryParams)
        .then((response) => {
          if (!response.installment_information.length) {
            throw new Error();
          }

          if (installment.maxInstallments) {
            const withMaxInstallments = response.installment_information
              .map((item, index) => index <= (installment.maxInstallments - 1) ? { ...item } : undefined)
              .filter(Boolean);

            dispatch(installmentRequest(withMaxInstallments));
            return;
          }

          dispatch(installmentRequest(response));
        })
        .catch(() => dispatch(installmentRequest({
          installment_information: [{}]
        })));
    }
  },
};
