/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  REQUEST_CAPTCHA_TOKEN,
  SAVE_CAPTCHA_TOKEN,
} = require('./types');

// Request Actions
const requestCaptchaToken = createAction(REQUEST_CAPTCHA_TOKEN);
const saveCaptchaToken = createAction(SAVE_CAPTCHA_TOKEN);

/**
 * Export functions
 */
module.exports = {
  [REQUEST_CAPTCHA_TOKEN]: requestCaptcha => dispatch => dispatch(requestCaptchaToken({ requestCaptcha })),
  [SAVE_CAPTCHA_TOKEN]: captchaToken => dispatch => dispatch(saveCaptchaToken({ captchaToken })),
};
