const ERROR_MAX_RETRIES = 'Exceeded max retries';

let running;
let pollingTimeoutId;

/**
 * @param {() => async () => void} fn function to execute in each lap
 * @param {(result) => boolean} stopWhen function to stop polling when fn result is valid
 * @param {number} delay milliseconds between laps
 * @param {number} retries maximum number of laps
 * @param {number} allowedErrors number of error responses that polling allows until it stops
 * @returns {promise}
 * @resolve result when stopWhen returns true
 * @reject fn error when async fn function rejects
 * @reject 'Exceeded max retries' when retries are over
 */
const polling = ({ fn, stopWhen, delay, retries, allowedErrors }) => {
  let lap = retries;
  let errorCounter = 0;

  running = true;

  const recursivePromise = (resolve, reject) => {
    if (!running) {
      return resolve();
    }

    if (!lap) {
      return reject(new Error(ERROR_MAX_RETRIES));
    }

    lap--;
    pollingTimeoutId = setTimeout(() => {
      fn()
        .then((result) => {
          if (stopWhen(result)) {
            return resolve(result);
          }

          return recursivePromise(resolve, reject);
        })
        .catch((error) => {
          errorCounter++;

          if (errorCounter > allowedErrors) {
            return reject(error);
          }

          return recursivePromise(resolve, reject);
        });
    }, delay);

    return null;
  };

  return new Promise(recursivePromise);
};

const cancelPolling = () => {
  running = false;
  clearTimeout(pollingTimeoutId);
};

module.exports = {
  polling,
  cancelPolling,
  ERROR_MAX_RETRIES,
};
