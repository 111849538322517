/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const Avatar = require('ui-library-mp/ui/Avatar');

const IconWrapper = require('../IconWrapper');

const IconsInstallments = ({ text }) => (
  <IconWrapper>
    <Avatar
      initials={text}
      className="icon-installments"
    />
  </IconWrapper>
);

IconsInstallments.propTypes = {
  text: PropTypes.string.isRequired,
};

IconsInstallments.defaultProps = {
  className: '',
  text: '',
};

module.exports = IconsInstallments;
