const React = require('react');
const PropTypes = require('prop-types');

const QrCodeImg = ({ src, alt }) => (
  <div className="qr_code_img">
    <img src={`data:image/jpeg;base64,${src}`} alt={alt} className="img" />
  </div>
);

QrCodeImg.defaultProps = {
  src: '',
};

QrCodeImg.propTypes = {
  src: PropTypes.string,
};

module.exports = QrCodeImg;
