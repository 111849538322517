const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');

const ProgressSpinner = ({ text }) => (
  <div className="spinner-wrapper">
    <ProgressIndicatorCircular
      size="small"
      modifier="inline"
      label={text}
    />
  </div>
);

ProgressSpinner.propTypes = {
  text: PropTypes.string.isRequired,
};

module.exports = injectI18n(ProgressSpinner);
