/**
 * Module dependencies
 */
const React = require('react');

const GroupSummary = (props) => (
  <div className="group-summary">
    <div className="group-summary__container">
      {props.children}
    </div>
  </div>
);

module.exports = GroupSummary;
