/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  SAVE_INPUT_VALUES,
  CURRENT_INPUT_VALUES,
  CLEAN_INPUT_VALUES,
  VISIBILITY_INPUT_VALUES,
  DISABLE_INPUT_VALUES,
  MESSAGE_INPUT_VALUES,
} = require('./types');

// Request Actions
const saveInputValue = createAction(SAVE_INPUT_VALUES);
const currentInputValue = createAction(CURRENT_INPUT_VALUES);
const cleanInputValues = createAction(CLEAN_INPUT_VALUES);
const visibilityInputValues = createAction(VISIBILITY_INPUT_VALUES);
const disableInputValues = createAction(DISABLE_INPUT_VALUES);
const messageInputValues = createAction(MESSAGE_INPUT_VALUES);

/**
 * Export functions
 */
module.exports = {
  [SAVE_INPUT_VALUES]: (input, value) => (dispatch) => {
    dispatch(saveInputValue({ input, value }));
  },
  [CURRENT_INPUT_VALUES]: (input, value) => (dispatch) => {
    dispatch(currentInputValue({ input, value }));
  },
  [CLEAN_INPUT_VALUES]: () => (dispatch) => {
    dispatch(cleanInputValues());
  },
  [VISIBILITY_INPUT_VALUES]: (inputsVisibility) => (dispatch) => {
    dispatch(visibilityInputValues(inputsVisibility));
  },
  [DISABLE_INPUT_VALUES]: (disabledInputs) => (dispatch) => {
    dispatch(disableInputValues(disabledInputs));
  },
  [MESSAGE_INPUT_VALUES]: (input, message) => (dispatch) => {
    dispatch(messageInputValues({ input, message }));
  },
};
