/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');

const Switch = require('@andes/switch');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { Button } = require('@andes/button');

const stepActions = require('../../spa/actions/step');
const { STEP_NEXT } = require('../../spa/actions/types');
const { getQueryParams } = require('../../utils/Dom');

const { useEffect, useState, useRef } = React;

const SwitchButtonNavigator = ({ flow, history, id, stepAction, value, kind, label }) => {
  const [checked, setChecked] = useState(false);
  const formRef = useRef();
  let callToAction;

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleAction = () => {
    const queryParams = getQueryParams();

    stepAction[STEP_NEXT](
      formRef.current,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  switch (kind) {
    case 'transparent':
      callToAction = (
        <Button hierarchy={kind} onClick={handleAction}>
          {label}
        </Button>
      );
      break;
    default:
      callToAction = <Switch checked={checked} onChange={handleAction} />;
  }

  return (
    <>
      <form hidden id={id} key={id} ref={formRef}>
        <input name={`[components][${id}]`} type="hidden" value={!value} />
      </form>
      {callToAction}
    </>
  );
};

SwitchButtonNavigator.propTypes = {
  flow: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  history: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func,
  }),
  type: PropTypes.string,
  value: PropTypes.bool.isRequired,
  kind: PropTypes.string,
  label: PropTypes.string,
};

SwitchButtonNavigator.defaultProps = {
  flow: {
    id: '',
    type: '',
  },
  id: '',
  type: '',
  stepAction: { [STEP_NEXT]: () => {} },
};

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SwitchButtonNavigator;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(SwitchButtonNavigator);
}
