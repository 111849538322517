import type { ChangeEvent, ReactElement } from 'react';
import type { TextFieldProps } from '@andes/textfield';
import type { OnErrorValidator } from '../../utils/validateEventValueByRegex';

import React from 'react';
import { Card, CardContent } from '@andes/card';
import { TextField } from '@andes/textfield';

import validateEventValueByRegex from '../../utils/validateEventValueByRegex';
import IconWrapper from '../Icons/IconWrapper';

export interface Validations {
  regex: string;
  error: string;
  show: boolean;
}

export interface InputCardProps {
  label: string;
  helper: string;
  value: string;
  icon?: ReactElement | null;
  id?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onError?: OnErrorValidator;
  validations?: Array<Validations>;
  containerClassName?: string;
  style?: TextFieldProps['modifier'];
  device?: 'desktop' | 'mobile';
}

const InputCard = ({
  label,
  helper,
  value,
  icon,
  id,
  onChange,
  onError,
  validations = [],
  containerClassName = '',
  style,
  device = 'desktop',
}: InputCardProps) => (
  <div className={`input-card__${device} ${containerClassName}`}>
    <Card shadow="flat">
      <CardContent>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <TextField
          id={id}
          helper={helper}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={validateEventValueByRegex({ onError, validations })}
          modifier={style}
        />
      </CardContent>
    </Card>
  </div>
);

export default InputCard;
