const React = require('react');
const PropTypes = require('prop-types');

const BottomSheet = require('@andes/bottom-sheet');
const { injectI18n } = require('nordic/i18n');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const className = require('classnames');

const ValidatorProvider = require('../../utils/validator-provider/ValidatorProvider');
const { STEP_NEXT } = require('../../spa/actions/types');
const stepActionCreators = require('../../spa/actions/step');
const IconMercadoPago = require('../icons/MercadoPago');
const BaseInput = require('../BaseInput');
const Button = require('../../containers/Button/ButtonWithStore');
const { getQueryParams } = require('../../utils/Dom');
const BppShield = require('../BppShield');
const Form = require('../Form');
const { getData } = require('../../utils/melidataTracking');
const trackBottomSheet = require('../../utils/trackBottomSheet');
const { TRACKS, BOTTOM_SHEET } = require('../../../constants/app');
const translate = require('../../translation');

const { useState, useRef, useEffect, useCallback } = React;

const boolVal = (boolString) => boolString === 'true';

const useBottomSheet = () => {
  const [open, setOpen] = useState(false);

  const setOnceViewed = (value = true) => {
    window.sessionStorage.setItem(BOTTOM_SHEET.ONCE_VIEWED, value)
  }

  useEffect(() => {
    const onceViewed = window.sessionStorage.getItem(BOTTOM_SHEET.ONCE_VIEWED);

    setOpen(!onceViewed)
  }, [])

  return { open, setOpen, setOnceViewed }
}

const BottomSheetLogin = ({ email, title, bpp, i18n, flow, stepActions, history, stateConfigs, hiddenComponents, phase }) => {
  const tracking = getData(hiddenComponents);
  const continueAsGuestFormRef = useRef();
  const { open, setOpen, setOnceViewed } = useBottomSheet();
  const isPhase2 = phase === '2'
  const translations = translate(i18n);
  const cta = isPhase2 ? translations.LOG_IN : translations.PAY_WITH_MY_ACCOUNT

  useEffect(() => {
    if (isPhase2) {
      setOnceViewed(true)
    }
  }, [isPhase2, setOnceViewed])

  const onClose = () => {
    trackBottomSheet(TRACKS.BOTTOM_SHEET_CLOSED, tracking, stateConfigs);
    setOpen(false);
  };

  const stepNext = useCallback((event) => {
    const queryParams = getQueryParams();

    stepActions[STEP_NEXT](
      event.target,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  }, [flow.id, flow.type, history, stepActions])

  const initialHeight = bpp ? 380 : 284;


  const handleFormSubmit = (event) => {
    event.preventDefault();
    const redirectToLogin = event.target.elements['[redirect_to_login]'].value;
    const shouldRedirect = boolVal(redirectToLogin)
    const trackName = shouldRedirect ? TRACKS.INIT_SESSION : TRACKS.CONTINUE_AS_GUEST
    trackBottomSheet(trackName, tracking, stateConfigs);

    if (shouldRedirect || !isPhase2) {
      stepNext(event);
    }

    setOpen(false);
  };

  return (
    <BottomSheet
      title={title}
      open={open}
      onClose={onClose}
      initialHeight={initialHeight}
      className={className({ bottomSheetWithBpp: bpp, bottomSheet: !bpp })}
    >
      {bpp && <BppShield i18n={i18n} />}
      <BaseInput className="input" defaultValue={email} disabled modifier="completed" />
      <ValidatorProvider>
        <Form id="init_session" key={Math.random()} onSubmit={handleFormSubmit}>
          <input type="hidden" name="[redirect_to_login]" value="true" />
          <Button
            type="submit"
            kind="loud"
            text={
              <>
                <div className="mpLogoWrapper">
                  <IconMercadoPago color="#00BCFF" />
                </div>
                {cta}
              </>
            }
            blockedByCaptcha={false}
          />
        </Form>
      </ValidatorProvider>
      <form ref={continueAsGuestFormRef} id="continue_as_guest" key={Math.random()} onSubmit={handleFormSubmit}>
        <input type="hidden" name="[redirect_to_login]" value="false" />
        <Button
          type="submit"
          kind="transparent"
          text={translations.CONTINUE_AS_GUEST}
          blockedByCaptcha={false}
        />
      </form>
    </BottomSheet>
  );
}

BottomSheetLogin.defaultProps = {
  history: {},
  stateConfigs: {},
  pageProps: {},
};

BottomSheetLogin.propTypes = {
  email: PropTypes.string.isRequired,
  bpp: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  phase: PropTypes.string.isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  stepActions: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
  }),
  stateConfigs: PropTypes.any,
  hiddenComponents: PropTypes.any,
};

const mapStateToProps = (state) => ({
  search: state.bankSearch.value,
  flow: state.page.flow,
  stateConfigs: state.configurations,
  hiddenComponents: state.page.data.hidden_components,
});

const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActionCreators, dispatch),
});

const connectWithRedux = connect(mapStateToProps, mapDispatchToProps);

if (process.env.NODE_ENV === 'test') {
  module.exports = BottomSheetLogin;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(connectWithRedux(BottomSheetLogin));
}
