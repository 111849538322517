/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
const React = require('react');

const { defaultProps, defaultTextProps } = require('../helper');
const Title = require('../../../components/Title');
const Text = require('../../../components/Text');
const Processing = require('../../../components/Processing');
const Oops = require('../../../components/Oops');
const RowSummary = require('../../../components/RowSummary');
const RowCollapsible = require('../../../components/RowCollapsible');
const RowDiscount = require('../../../components/RowDiscount');
const StickySummary = require('../../../components/StickySummary');
const IconInstallments = require('../../../components/IconInstallments');
const IconInstallmentsDynamic = require('../../../components/IconInstallmentsDynamic');
const MpBrand = require('../../../components/MpBrand');
const Terms = require('../../../components/Terms');
const Avatar = require('../../../components/Avatar');
const CvvIcon = require('../../../components/CvvIcon');
const IconCardCvv = require('../../../components/IconCardCvv');
const PaymentCardIcon = require('../../../components/PaymentCardIcon');
const ImageIcon = require('../../../components/icons/ImageIcon');
const IconAccountMoney = require('../../../components/icons/AccountMoney');
const IconMercadoCredito = require('../../../components/icons/MercadoCredito');
const IconMercadoCreditoFull = require('../../../components/icons/MercadoCreditoFull');
const IconQR = require('../../../components/icons/QR');
const IconPhoneQR = require('../../../components/icons/PhoneQR');
const IconMercadoPago = require('../../../components/icons/MercadoPago');
const IconMercadoPagoFull = require('../../../components/icons/MercadoPagoFull');
const IconMercadoLivreFull = require('../../../components/icons/MercadoLivreFull');
const IconMercadoPagoBlack = require('../../../components/icons/MercadoPagoBlack');
const IconCreditCard = require('../../../components/icons/CreditCard');
const IconSplitPayment = require('../../../components/icons/SplitPayment');
const IconEmail = require('../../../components/icons/Email');
const IconBank = require('../../../components/icons/Bank');
const IconCash = require('../../../components/icons/Cash');
const IconOops = require('../../../components/icons/Oops');
const IconBack = require('../../../components/icons/Back');
const IconIdentification = require('../../../components/icons/Identification');
const IconChevron = require('../../../components/icons/Chevron');
const IconBlueChevron = require('../../../components/icons/BlueChevron');
const IconUserDefault = require('../../../components/icons/UserDefault');
const IconPin = require('../../../components/icons/Pin');
const IconPix = require('../../../components/icons/Pix');
const IconLocalPick = require('../../../components/icons/LocalPick');
const IconShppingType = require('../../../components/icons/ShippingType');
const IconStore = require('../../../components/icons/Store');
const IconNoAvailableShipping = require('../../../components/icons/NoAvailableShipping');
const IconUser = require('../../../components/icons/User');
const Boleto = require('../../../components/icons/Boleto');
const Loteria = require('../../../components/icons/Loteria');
const IconExtraData = require('../../../components/icons/ExtraData');
const CardCancel = require('../../../components/icons/CardCancel');
const Brand = require('../../../components/Brand');
const UserMenu = require('../../../components/UserMenu');
const UserTitle = require('../../../components/UserTitle');
const Markdown = require('../../../components/Markdown');
const IdentificationCardWrapper = require('../../../components/IdentificationCardWrapper');
const ConsumerCreditsTyC = require('../../../components/ConsumerCreditsTyC');
const IconTimeOut = require('../../../components/icons/TimeOut');
const QrCode = require('../../../components/QrCode');
const IconSecureComputer = require('../../../components/icons/SecureComputer');
const IconNoCollaborator = require('../../../components/icons/NoCollaborator');
const IconUnrecoverablePayment = require('../../../components/icons/UnrecoverablePayment');
const IconRecoveryApprovedPayment = require('../../../components/icons/RecoveryApprovedPayment');
const IconNoAccountBalance = require('../../../components/icons/NoAccountBalance');
const IconNoPaymentOption = require('../../../components/icons/NoPaymentOption');
const IconNoMeliCollector = require('../../../components/icons/NoMeliCollector');
const AutoReturn = require('../../../components/AutoReturn');
const LottieAnimation = require('../../../components/LottieAnimation');
const PaymentOptionSelector = require('../../../components/PaymentOptionSelector');
const InstallmentsCollapsibleSelector = require('../../../components/InstallmentsCollapsibleSelector');
const WalletSummary = require('../../../components/WalletSummary');
const Resizer = require('../../../components/Resizer');
const IconChevronLeft = require('../../../components/icons/ChevronLeft');
const TermsAndConditions = require('../../../components/TermsAndConditions');
const CaptchaV2 = require('../../../components/CaptchaV2');
const CaptchaTerms = require('../../../components/CaptchaTerms');
const Snackbar = require('../../../components/Snackbar');
const Modal = require('../../../components/Modal');
const IconDiscount = require('../../../components/icons/Discount');
const Badge = require('../../../components/Badge');
const IconQuestionMark = require('../../../components/icons/QuestionMark');
const IconTicket = require('../../../components/icons/Ticket');
const IconWebpay = require('../../../components/icons/Webpay');
const IconOpenFinance = require('../../../components/icons/OpenFinance');
const TransitionRedirect = require('../../../components/TransitionRedirect');
const ProcessingRedirect = require('../../../components/ProcessingRedirect');
const InstallmentDetail = require('../../../components/InstallmentDetail');
const IconAccountMoneyDisabled = require('../../../components/icons/AccountMoneyDisabled');
const GeoLocalization = require('../../../components/GeoLocalization');
const IconCaixa = require('../../../components/icons/Caixa');
const Message = require('../../../components/Message');
const Skeleton = require('../../../components/Skeleton');
const SkeletonFull = require('../../../components/SkeletonFull');
const ModalHeader = require('../../../components/ModalHeader');
const UserInfo = require('../../../components/UserInfo');
const UserPill = require('../../../components/UserPill');
const InfoRow = require('../../../components/InfoRow');
const IconShield = require('../../../components/icons/shield');
const IconShieldBPP = require('../../../components/icons/ShieldBPP');
const IconShieldBPPDisabled = require('../../../components/icons/ShieldBPPDisabled');
const IconShieldBPPWhite = require('../../../components/icons/ShieldBPPWhite');
const WebpayDebitUnavailable = require('../../../components/icons/WebpayDebitUnavailable');
const BlockedPopupFallback = require('../../../components/BlockedPopupFallback');
const QrCodeImg = require('../../../components/QrCodeImg');
const DisabledText = require('../../../components/DisabledText');
const IconPse = require('../../../components/icons/Pse');
const IconPixUnavailable = require('../../../components/icons/PixUnavailable');
const InstallmentsSummary = require('../../../components/InstallmentsSummary');
const IconAppStore = require('../../../components/icons/AppStore');
const IconPlayStore = require('../../../components/icons/PlayStore');
const IconLock = require('../../../components/icons/Lock');
const Instruction = require('../../../components/Instruction');
const QrController = require('../../../components/QrController');
const OpenFinanceTermsAndConditions = require('../../../components/OpenFinanceTermsAndConditions');
const OpenFinanceAwaitingController = require('../../../components/OpenFinanceAwaitingController');
const IconProtectedPurchase = require('../../../components/icons/ProtectedPurchase');
const IconProtectedPurchaseGuest = require('../../../components/icons/ProtectedPurchaseGuest');
const BankSearch = require('../../../components/BankSearch');
const BankList = require('../../../components/BankList');
const hiddableComponent = require('../../../components/HOCs/hiddableComponent');
const IconFeedbackNegative = require('../../../components/icons/FeedbackNegative');
const IconCheckBadge = require('../../../components/icons/CheckBadge');
const IconsList = require('../../../components/IconsList');
const IconWithTooltip = require('../../../components/IconWithTooltip');
const IconSuccess = require('../../../components/icons/Success');
const OverflowMenuHorizontal = require('../../../components/icons/OverflowMenuHorizontal');
const GenericWalletIcon = require('../../../components/icons/GenericWallet');
const PaymentMethodsList = require('../../../components/PaymentMethodsList');
const IconCopy = require('../../../components/icons/Copy');
const IconInfo = require('../../../components/icons/Info');
const IconCombination = require('../../../components/icons/Combination');
const Anchor3DSIcon = require('../../../components/icons/Anchor3DS');
const FacetecUnicoIcon = require('../../../components/icons/FacetecModal');
const BottomSheetLogin = require('../../../components/BottomSheetLogin');
const IconMercadoLibre = require('../../../components/icons/MercadoLibre');
const IconMercadoLibreFull = require('../../../components/icons/MercadoLibreFull');
const MailIcon = require('../../../components/icons/Mail');
const Feedback = require('../../../components/FeedbackScreen');
const Stepper = require('../../../components/Stepper');
const GrowthCouponIcon = require('../../../components/icons/GrowthCoupon');
const IconFeedbackWithMercadoPago = require('../../../components/icons/FeedbackWithMercadoPago');
const PaymentMethodInfo = require('../../../components/PaymentMethodInfo');
const PaymentDetailRowWithCombination = require('../../PaymentDetailRow/PaymentDetailRowWithCombination');
const { SummaryListWithCombination } = require('../../SummaryList');
const TyCCredits = require("../../TyCCredits");
const TechnicalError = require('../../../components/TechnicalError');
const {
  COMMONS: { PAYMENT_METHOD_TYPE },
} = require('../../../../constants');

module.exports = {
  brand: (props) => (
    <Brand {...defaultProps(props)} name={props.name} logo={props.logo} device={props.deviceType} key={Math.random()} />
  ),
  bottom_sheet_login: (props) => (
    <BottomSheetLogin
      {...defaultProps(props)}
      {...props}
      email={props.email}
      title={props.title}
      bpp={props.bpp}
      tracking={props.tracking}
    />
  ),
  instruction: (props) => <Instruction {...defaultProps(props)} position={props.position} text={props.text} />,
  processing: (props) => (
    <Processing {...defaultProps(props)} size={props.value} message={props.text} key={Math.random()} />
  ),
  oops: (props) => (
    <Oops {...defaultProps(props)} message={props.text} key={Math.random()}>
      <IconOops />
    </Oops>
  ),
  title: (props) => <Title {...defaultTextProps(props)} tagName="h1" className="c-title" />,
  title_2: (props) => <Title {...defaultTextProps(props)} tagName="h2" className="title-h2" />,
  title_3: (props) => <Title {...defaultTextProps(props)} tagName="h2" className="title-h3" />,
  title_hr: (props) => <Title {...defaultTextProps(props)} tagName="h2" className="title-hr" />,
  title_congrats_agencies: (props) => (
    <h2 className="title-congrats-agencies" key={Math.random()}>
      <Markdown text={props.text} />
    </h2>
  ),
  subtitle_congrats_agencies: (props) => (
    <h2 className="subtitle-congrats-agencies" key={Math.random()}>
      <Markdown text={props.text} />
    </h2>
  ),
  text: (props) => <Text {...defaultTextProps(props)} />,
  text_centered: (props) => (
    <p id={props.id} className="text centered" key={Math.random()}>
      <Markdown text={props.text} />
    </p>
  ),
  text_installment: (props) => {
    const HiddableText = hiddableComponent(Text);
    return <HiddableText show={props.show} storeKey="text_installment" {...defaultTextProps(props)} />;
  },
  text_light: (props) => <Text {...defaultTextProps(props)} className="gray" />,
  separator: (props) => <hr aria-hidden="true" className={props.full ? 'separator-full' : ''} key={Math.random()} />,
  autoreturn: (props) => (
    <AutoReturn {...defaultProps(props)} value={props.value} countdown={props.countdown} key={Math.random()} />
  ),
  row_collapsible: (props) => <RowCollapsible {...defaultProps(props)} key={Math.random()} {...props} />,
  row_summary: (props) => <RowSummary {...defaultProps(props)} key={Math.random()} id={props.id} value={props.value} />,
  row_summary_installment: (props) => (
    <RowSummary {...defaultProps(props)} key={Math.random()} value={props.value} kind="installment" />
  ),
  row_summary_pay: (props) => (
    <RowSummary {...defaultProps(props)} value={props.value} key={Math.random()} kind="pay" />
  ),
  row_summary_pay_combined: ({ value }) => <RowSummary {...defaultProps()} value={value} kind="pay-combined" />,
  row_summary_first_combined: ({ value }) => <RowSummary {...defaultProps()} value={value} kind="first-combine" />,
  row_summary_dynamic: (props) => (
    <RowSummary {...defaultProps(props)} value={props.value} key={Math.random()} kind="dynamic" />
  ),
  row_summary_tax: (props) => (
    <RowSummary {...defaultProps(props)} value={props.value} key={Math.random()} kind="tax" />
  ),
  row_discount: (props) => <RowDiscount value={props.value} />,
  payment_methods_list: (props) => (
    <PaymentMethodsList {...defaultProps(props)} text={props.text} id={props.id} className={props.className} />
  ),
  identification_card: (props) => <IdentificationCardWrapper name={props.text} key={Math.random()} />,
  sticky_summary: (props) => (
    <StickySummary
      value={props.value}
      key={Math.random()}
      isCollapsible={props.is_collapsible}
      collapsibleValues={props.collapsible_values}
      label={props.label}
    />
  ),
  sticky_summary_coupon: (props) => (
    <StickySummary
      value={props.value}
      key={Math.random()}
      isCollapsible={props.is_collapsible}
      collapsibleValues={props.collapsible_values}
      label={props.label}
      kind="coupon"
    />
  ),
  wallet_summary: (props) => (
    <WalletSummary value={props.value} key={Math.random()} collapsibleValues={props.collapsible_values} id={props.id} />
  ),
  sticky_summary_pay: (props) => (
    <StickySummary value={props.value} key={Math.random()} label={props.label} kind="pay" />
  ),
  sticky_summary_pay_combined: (props) => (
    <StickySummary
      value={props.value}
      key={Math.random()}
      label={props.label}
      isCollapsible={props.is_collapsible}
      collapsibleValues={props.collapsible_values}
      kind="pay-combined"
      isCombination="true"
      deviceType={props.deviceType}
    />
  ),
  // Will be DEPRECATED
  cvv_icon: (props) => <CvvIcon className={props.className} key={Math.random()} />,
  icon_cvv: (props) => (
    <IconCardCvv
      {...defaultProps()}
      cardId={props.data.payment_method_id}
      size={props.size}
      show={props.show}
      id={props.id}
    />
  ),
  avatar_payment_card: (props) => <Avatar status={props.status} icon="creditCard" key={Math.random()} />,
  avatar_shopping: (props) => <Avatar status={props.status} icon="shopping" key={Math.random()} />,
  icon_installments: (props) => <IconInstallments {...defaultProps(props)} text={props.text} key={Math.random()} />,
  icon_installments_dynamic: (props) => (
    <IconInstallmentsDynamic {...defaultProps(props)} id={props.id} text={props.text} key={Math.random()} />
  ),
  icon_chevron: () => <IconChevron key={Math.random()} />,
  icon_blue_chevron: () => <IconBlueChevron key={Math.random()} />,
  icon_user_default: () => <IconUserDefault ley={Math.random()} />,
  processed_by: () => <MpBrand key={Math.random()} />,
  terms_and_conditions: (props) => <Terms {...defaultProps(props)} urls={props.urls} key={Math.random()} />,
  payment_card_icon: (props) => (
    <PaymentCardIcon
      cardId={props.data.payment_method_id}
      issuerName={props.data.issuer_name}
      siteId={props.siteId}
      key={Math.random()}
    />
  ),
  icon_qr: () => <IconQR key={Math.random()} />,
  icon_phone_qr: () => <IconPhoneQR key={Math.random()} />,
  icon_account_money: (props) => <IconAccountMoney key={Math.random()} alt={props.title} />,
  icon_email: () => <IconEmail key={Math.random()} />,
  icon_mail: () => <MailIcon key={Math.random()} />,
  icon_mercado_credito: () => <IconMercadoCredito key={Math.random()} />,
  icon_mercado_credito_full: () => <IconMercadoCreditoFull {...defaultProps()} />,
  icon_mercado_pago: () => <IconMercadoPago key={Math.random()} />,
  icon_mercado_pago_full: () => <IconMercadoPagoFull key={Math.random()} />,
  icon_mercado_pago_black: () => <IconMercadoPagoBlack key={Math.random()} />,
  icon_mercado_libre_full: () => <IconMercadoLibreFull key={Math.random()} />,
  icon_mercado_livre_full: () => <IconMercadoLivreFull key={Math.random()} />,
  icon_bank: () => <IconBank key={Math.random()} />,
  icon_card_cancel: () => <CardCancel />,
  icon_oops: () => <IconOops key={Math.random()} />,
  icon_back: () => <IconBack key="icon-back" />,
  icon_loteria: () => <Loteria key={Math.random()} />,
  icon_boleto: () => <Boleto key={Math.random()} />,
  icon_cash: (props) => <IconCash {...props} key={Math.random()} />,
  icon_identification: () => <IconIdentification key={Math.random()} />,
  image_icon: (props) => <ImageIcon {...props} key={Math.random()} />,
  icon_extra_data: (props) => <IconExtraData {...props} key={Math.random()} />,
  icon_question_mark: () => <IconQuestionMark key={Math.random()} />,
  icon_pin: () => <IconPin key={Math.random()} />,
  icon_pix: () => <IconPix key={Math.random()} />,
  icon_local_pick: () => <IconLocalPick key={Math.random()} />,
  icon_shipping_type: () => <IconShppingType key={Math.random()} />,
  icon_store: () => <IconStore key={Math.random()} />,
  icon_user: () => <IconUser key={Math.random()} />,
  generic_payment_card_icon: () => <IconCreditCard key={Math.random()} />,
  generic_wallet_icon: () => <GenericWalletIcon key={Math.random()} />,
  split_payment_card_icon: () => <IconSplitPayment key={Math.random()} />,
  merchant_logo: () => <span className="merchant-logo" key={Math.random()} />,
  user_menu: (props) => (
    <UserMenu
      {...defaultProps(props)}
      userName={props.user_name}
      avatarUrl={props.avatar_url}
      key={Math.random()}
      history={props.history}
    />
  ),
  user_title: (props) => <UserTitle {...defaultProps(props)} user={props.user} key={Math.random()} />,
  avatar_account_fund: (props) => <Avatar status={props.status} icon="wallet" key={Math.random()} />,
  avatar_account_money: (props) => <Avatar status={props.status} icon="accountMoney" key={Math.random()} />,
  avatar_secure_lock: (props) => <Avatar status={props.status} icon="secureLock" />,
  consumer_credits_tyc_ui: (props) => <ConsumerCreditsTyC {...defaultProps()} {...props} type="general" />,
  tyc_consumer_credits: ({ url_tyc_consumer_credits: tycUrl, url_contract_consumer_credits: contractUrl, value: text, ...props }) =>
    (<TyCCredits {...defaultProps(props)} text={text} tycUrl={tycUrl} contractUrl={contractUrl} />),
  consumer_credits_tyc_particular_ui: (props) => (
    <ConsumerCreditsTyC {...defaultProps()} {...props} type="particular" />
  ),
  consumer_credits_contract_ui: (props) => <ConsumerCreditsTyC {...defaultProps()} {...props} type="contract" />,
  preference_unavailable: (props) => (
    <Oops message={props.text} className="pref-unavailable" key={Math.random()}>
      <IconTimeOut />
    </Oops>
  ),
  qr_code: (props) => <QrCode {...defaultProps()} value={props.value} trackEvent={props.track_event} />,
  preference_expired: (props) => (
    <Oops message={props.text} className="pref-expired" key={Math.random()}>
      <IconTimeOut key={Math.random()} />
    </Oops>
  ),
  icon_secure_computer: () => <IconSecureComputer />,
  payment_option_selector: (props) => (
    <PaymentOptionSelector options={props.value} label={props.label} id={props.id} show={props.show} />
  ),
  installments_collapsible_selector: (props) => (
    <InstallmentsCollapsibleSelector options={props.value} label={props.label} id={props.id} />
  ),
  icon_no_collaborator: () => <IconNoCollaborator />,
  icon_unrecoverable_payment: () => <IconUnrecoverablePayment />,
  geolocalization: (props) => <GeoLocalization {...defaultProps(props)} name={props.name} />,
  icon_recovery_approved_payment: () => <IconRecoveryApprovedPayment />,
  icon_no_account_balance: () => <IconNoAccountBalance {...defaultProps()} />,
  icon_no_payment_option: () => <IconNoPaymentOption />,
  icon_no_meli_collector: () => <IconNoMeliCollector />,
  congrats_animation: (props) => <LottieAnimation text={props.text} />,
  resizer: () => <Resizer />,
  // TODO: When second chevron is available merge into only one:q
  icon_chevron_left: () => <IconChevronLeft />,
  coupons_terms_and_conditions: (props) => <TermsAndConditions text={props.text} />,
  captcha_v2: (props) => (
    <CaptchaV2 key={Math.random()} {...props} name={props.name} captchaRetry={props.captcha_retry} />
  ),
  captcha_terms_and_conditions: (props) => (
    <CaptchaTerms {...defaultProps(props)} logged={props.logged} key={Math.random()} />
  ),
  snackbar: (props) => <Snackbar message={props.message} status={props.status} delay={props.delay} />,
  modal: (props) => (
    <Modal title={props.title} initialVisibility={props.show}>
      <p className="content">{props.content}</p>
    </Modal>
  ),
  icon_mercado_libre: () => <IconMercadoLibre {...defaultProps()} />,
  icon_discount: (props) => <IconDiscount {...defaultProps(props)} />,
  icon_shield: (props) => <IconShield {...defaultProps(props)} />,
  icon_lock: (props) => <IconLock {...defaultProps(props)} />,
  icon_shield_bpp: (props) => <IconShieldBPP {...defaultProps(props)} />,
  icon_shield_bpp_disabled: (props) => <IconShieldBPPDisabled {...defaultProps(props)} />,
  icon_shield_bpp_white: (props) => <IconShieldBPPWhite {...defaultProps(props)} />,
  badge: (props) => (
    <Badge
      size={props.size}
      icon={props.icon}
      kind={props.kind}
      border={props.border}
      content={props.content}
      className={props.className}
      hierarchy={props.hierarchy}
    />
  ),
  icon_ticket: () => <IconTicket {...defaultProps()} />,
  discount_price: (props) => <Text {...defaultTextProps(props)} className="discount_price" />,
  text_congrats_additional_info: (props) => (
    <Text {...defaultTextProps(props)} className="text_congrats_additional_info" />
  ),
  installment_detail: (props) => <InstallmentDetail {...defaultProps()} {...props} />,
  icon_webpay: () => <IconWebpay key={Math.random()} />,
  icon_open_finance: () => <IconOpenFinance key={Math.random()} />,
  transition_redirect: (props) => (
    <TransitionRedirect
      {...props}
      delay={props.delay}
      key={Math.random()}
      transitionName={props.transition_name}
      oneClick={props.one_click}
      tbkToken={props.tbk_token}
    />
  ),
  processing_redirect: (props) => (
    <ProcessingRedirect
      {...defaultProps()}
      url={props.url}
      size={props.size}
      label={props.label}
      delay={props.delay}
      target={props.target}
    />
  ),
  aspirational: (props) => (
    <Message
      {...defaultProps()}
      text={props.label}
      title={props.title}
      color={props.status}
      className="aspirational"
      hierarchy="quiet"
    />
  ),
  icon_account_money_disabled: () => <IconAccountMoneyDisabled key={Math.random()} />,
  icon_virtual_cvv: (props) => <IconCardCvv cardId="virtual" show={props.show} id={props.id} />,
  icon_caixa: (props) => <IconCaixa {...props} {...defaultProps()} />,
  message: (props) => (
    <Message
      {...defaultProps()}
      id={props.id}
      text={props.text}
      color={props.message_type}
      title={props.title}
      closable={props.closable}
      hierarchy={props.hierarchy}
      trackEvent={props.track_event}
      actionUrl={props.action_url}
      actionText={props.action_text}
    >
      {props.children}
    </Message>
  ),
  skeleton: (props) => <Skeleton {...props} {...defaultProps(props)} />,
  skeleton_full: (props) => <SkeletonFull {...props} {...defaultProps(props)} />,
  modal_header: (props) => (
    <ModalHeader
      {...props}
      {...defaultProps()}
      mask={props.mask}
      userName={props.user_name}
      imageURL={props.image_url}
    />
  ),
  qr_controller: (props) => (
    <QrController
      expirationDate={props.expiration_date}
      relationId={props.relation_id}
      actions={props.actions}
      value={props.value}
      price={props.price}
      history={props.history}
      {...defaultProps(props)}
    />
  ),
  open_finance_awaiting: (props) => {
    const { payment_id, payer_user_test, history, actions, deviceType } = props;
    return (
      <OpenFinanceAwaitingController
        paymentId={payment_id}
        payerUserTest={payer_user_test}
        history={history}
        actions={actions}
        deviceType={deviceType}
        {...defaultProps(props)}
      />
    );
  },
  user_info: (props) => (
    <UserInfo {...props} {...defaultProps(props)} userName={props.first_attribute} imageURL={props.second_attribute} />
  ),
  user_pill: (props) => <UserPill {...props} {...defaultProps(props)} userEmail={props.label} />,
  info_row: (props) => (
    <InfoRow {...defaultProps(props)} title={props.first_attribute} detail={props.second_attribute} />
  ),
  icon_no_available_shipping: () => <IconNoAvailableShipping key={Math.random()} />,
  icon_webpay_debit_unavailable: () => <WebpayDebitUnavailable key={Math.random()} />,
  blocked_popup_fallback: (props) => <BlockedPopupFallback {...props} ctaLabel={props.cta_label} key={Math.random()} />,
  qr_code_img: (props) => <QrCodeImg key={Math.random()} {...props} />,
  disabled_text: (props) => <DisabledText key={Math.random()} text={props.value} />,
  icon_pse: () => <IconPse key={Math.random()} />,
  icon_pix_unavailable: () => <IconPixUnavailable key={Math.random()} />,
  installments_summary: (props) => <InstallmentsSummary {...defaultProps()} {...props} />,
  icon_app_store: () => <IconAppStore key={Math.random()} />,
  icon_play_store: () => <IconPlayStore key={Math.random()} />,
  icon_protected_purchase: () => <IconProtectedPurchase key={Math.random()} />,
  icon_protected_purchase_guest: () => <IconProtectedPurchaseGuest key={Math.random()} />,
  bank_search: () => <BankSearch />,
  bank_list: (props) => {
    const banks = props.value;

    return <BankList banks={banks} {...props} />;
  },
  check_badge: (props) => <IconCheckBadge {...props} />,
  icons_list: (props) => {
    const items = props.value.map((opt) => ({
      icon: opt.icon,
      text: opt.text,
    }));
    return <IconsList items={items} id={props.id} />;
  },
  open_finance_terms_and_conditions: (props) => <OpenFinanceTermsAndConditions key={Math.random()} {...props} />,
  icon_feedback_negative: (props) => <IconFeedbackNegative {...props} key={Math.random()} />,
  icon_feedback_positive_with_mercado_pago: (props) => <IconFeedbackWithMercadoPago {...props} key={Math.random()} />,
  icon_with_tooltip: (props) => (
    <IconWithTooltip
      {...defaultProps(props)}
      text={props.text}
      icon={props.icon}
      type={props.layout}
      trigger={props.trigger}
      title={props.title}
      deviceType={props.deviceType}
      showCloseButton={props.showCloseButton}
    />
  ),
  icon_copy: () => <IconCopy {...defaultProps()} />,
  icon_feedback_positive: (props) => <IconSuccess {...props} key={Math.random()} />,
  icon_instructions: (props) => <OverflowMenuHorizontal {...props} key={Math.random()} />,
  icon_info: (props) => <IconInfo {...defaultProps()} {...props} />,
  icon_combination: (props) => <IconCombination {...props} key={Math.random()} />,
  icon_anchor_3ds: (props) => <Anchor3DSIcon {...defaultProps()} {...props} />,
  icon_facetec_modal: (props) => <FacetecUnicoIcon {...defaultProps()} {...props} />,
  feedback_screen: (props) => <Feedback {...defaultProps()} {...props} />,
  stepper: (props) => <Stepper {...defaultProps()} {...props} />,
  icon_growth_coupon: (props) => <GrowthCouponIcon {...defaultProps()} {...props} />,
  payment_method_info: (props) => {
    const paymentMethodTypeCards = [
      PAYMENT_METHOD_TYPE.CREDIT_CARD,
      PAYMENT_METHOD_TYPE.DEBIT_CARD,
      PAYMENT_METHOD_TYPE.PREPAID_CARD,
    ];
    return (
      <PaymentMethodInfo
        {...defaultProps()}
        firstText={props.label}
        secondText={
          paymentMethodTypeCards.includes(props.payment_method?.type) ? props.payment_method?.issuer_name : props.text
        }
        paymentMethod={props.payment_method}
        amount={props.amount}
        installments={props.installments}
        extraLabel={props.extra_label}
      />
    );
  },
  payment_detail_row: (props) => <PaymentDetailRowWithCombination {...defaultProps()} {...props} />,
  summary_list_combination: (props) => <SummaryListWithCombination {...defaultProps()} {...props} />,
  technical_error: (props) => (
    <TechnicalError {...defaultProps(props)} message={props.text} errorCode={props.errorCode} label={props.label} key={Math.random()} />
  ),
  remedies_terms_and_condition: (props) => <TermsAndConditions text={props.label} customClass={props.id} />,
};
