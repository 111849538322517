/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const IconChevron = require('../icons/Chevron');
const TermsAndConditions = require('../TermsAndConditions');
const sizeActions = require('../../spa/actions/size');
const collapsibleActions = require('../../spa/actions/collapsible');
const { CHANGE_IFRAME_SIZE, COLLAPSIBLE_CLICKED } = require('../../spa/actions/types');

class WalletSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapsible: false,
      arrowTransitioning: false,
    };

    this.renderCollapsible = this.renderCollapsible.bind(this);
    this.displaySummary = this.displaySummary.bind(this);
  }

  getRegularRow(rowData) {
    return (
      <div className={rowData.type}>
        {rowData.value && rowData.value.map(({ type, text }) => (
          <div
            className={`wallet-summary__${type} u-truncate`}
            key={Math.random()}
          >
            {text}
          </div>
        ))}
      </div>
    );
  }

  getDiscountRow(rowData) {
    let collapsibleChevron = null;
    if (this.props.collapsibleValues.length) {
      collapsibleChevron = (
        <div className={`chevron-button-wrapper ${this.state.arrowTransitioning ? 'transitioning' : ''}`} onClick={this.displaySummary}>
          <span className={this.state.showCollapsible ? 'up' : 'down'}>
            <IconChevron />
          </span>
        </div>
      );
    }
    return (
      <div className="discount_row">
        <div className="left">
          <div className={`wallet-summary__${rowData.value[0].type} u-truncate`}>
            {rowData.value[0].text}
          </div>
          <div className={`wallet-summary__${rowData.value[1].type} u-truncate`}>
            {rowData.value[1].text}
          </div>
          {collapsibleChevron}
        </div>
        <div className={`wallet-summary__${rowData.value[2].type} u-truncate`}>
          {rowData.value[2].text}
        </div>
      </div>
    );
  }

  displaySummary() {
    const transitionTime = 800;
    this.setState({ showCollapsible: !this.state.showCollapsible, arrowTransitioning: true }, () => {
      this.props.collapsibleActions[COLLAPSIBLE_CLICKED]({ collapsibleId: 'wallet_summary', isOpen: this.state.showCollapsible });
      this.resize();
    });
    setTimeout(() => this.setState({ arrowTransitioning: false }), transitionTime);
  }

  resize() {
    const size = this.showCollapsible ? document.body.scrollHeight : document.documentElement.scrollHeight;
    this.props.sizeActions[CHANGE_IFRAME_SIZE](size);
  }

  renderCollapsible() {
    return (
      <div className={`collapsible-summary ${this.state.showCollapsible ? 'open' : 'hidden'}`}>
        {
          this.props.collapsibleValues.map((elem) => {
            let row = null;
            if (elem.type === 'text') {
              row = (
                <div className="collapsible-row">
                  {elem.text}
                </div>
              );
            } else if (elem.type === 'terms_and_conditions') {
              row = <TermsAndConditions text={elem.text} />;
            }
            return row;
          })
        }
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  renderRow(row) {
    switch (row.type) {
      case 'row':
        return this.getRegularRow(row);
      case 'row_discount':
        return this.getDiscountRow(row);
      default:
    }
  }

  render() {
    const walletSummaryClasses = `${this.props.collapsibleValues.length ? 'collapsible' : ''} ${this.state.showCollapsible ? 'open' : 'closed'}`;
    return (
      <>
        <div className={`wallet-summary ${walletSummaryClasses} `}>
          <div className="wallet-summary__row-container">
            {this.props.value.map(row => this.renderRow(row))}
          </div>
          {this.renderCollapsible()}
        </div>
      </>
    );
  }
}

/**
 * Prop Types
 */
WalletSummary.propTypes = {
  id: PropTypes.string,
  kind: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  collapsibleValues: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.array,
  })),
};

/**
 * Default Props
 */
WalletSummary.defaultProps = {
  id: '',
  kind: '',
  value: [],
  collapsibleValues: [],
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  sizeActions: bindActionCreators(sizeActions, dispatch),
  collapsibleActions: bindActionCreators(collapsibleActions, dispatch),
});
/**
 * Expose WalletSummary
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = WalletSummary;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(WalletSummary);
}
