import React from 'react';

const Email = ({
  className,
  id,
  width = 36,
  height = 28,
  ...props
}: SVGProps) => (
  <svg
    {...props}
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 38 28"
    style={{ borderRadius: 4 }}
  >
    <defs>
      <rect height="24" id="email-a" width="37" rx="4" x=".5" y=".373" />
    </defs>
    <g fill="none" transform="translate(-5 -14)">
      <path d="M0 .373h48v48H0z" />
      <g transform="translate(5 12)">
        <mask id="email-b" fill="#fff">
          <rect height="24" width="37" rx="4" x=".5" y=".373" />
        </mask>
        <rect height="24" width="37" rx="4" x=".5" y=".373" />
        <path
          d="M5.646-11.79H32.18v26.534l-20.128-3.051a4 4 0 0 1-3.355-3.356L5.646-11.79z"
          fill="currentColor"
          fillOpacity=".3"
          transform="rotate(-45 18.913 1.477)"
        />
      </g>
      <rect height="24" width="36" rx="3" stroke="currentColor" x="6" y="14" />
    </g>
  </svg>
);

export default React.memo(Email);
