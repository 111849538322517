const { createAction } = require('redux-actions');

const { BOTTOM_SHEET } = require('./types');

const bottomSheetOpen = createAction(BOTTOM_SHEET);

module.exports = {
  /**
   * Open bottom sheet
   * @return {function(*)}
   */

  [BOTTOM_SHEET]: (payload) => (dispatch) => {
    dispatch(bottomSheetOpen(payload));
  },
};
