import React from 'react';

const DebitCard = ({ height = 24, width = 24, ...props }: SVGProps) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.751 12.75H8.248V11.25H3.751V12.75Z" fill="currentColor" />
    <path d="M8.248 9.75H3.751V8.25H8.248V9.75Z" fill="currentColor" />
    <path d="M9.7515 12.75H14.2485V11.25H9.7515V12.75Z" fill="currentColor" />
    <path d="M20.249 12.75H15.752V11.25H20.249V12.75Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 3.75H3C1.75736 3.75 0.75 4.75736 0.75 6V18C0.75 19.2426 1.75736 20.25 3 20.25H21C22.2426 20.25 23.25 19.2426 23.25 18V6C23.25 4.75736 22.2426 3.75 21 3.75ZM2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6V18C21.75 18.4142 21.4142 18.75 21 18.75H3C2.58579 18.75 2.25 18.4142 2.25 18V6Z"
      fill="currentColor"
    />
  </svg>
);

export default React.memo(DebitCard);
