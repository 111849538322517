const React = require('react');

const IconWrapper = require('../IconWrapper');

const Shield = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
      <g fill="none" fillOpacity=".45" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.062.599l.383.317c1.92 1.59 3.81 2.374 5.68 2.374h.6v.6c0 5.633-2.165 9.242-6.473 10.679l-.19.063-.19-.063C3.564 13.132 1.4 9.523 1.4 3.89v-.6h.6c1.87 0 3.76-.783 5.68-2.374l.383-.317zm0 1.548c-1.8 1.4-3.62 2.179-5.455 2.32.135 4.725 1.947 7.648 5.455 8.898 3.508-1.25 5.32-4.173 5.455-8.898-1.835-.141-3.656-.92-5.455-2.32zm2.286 2.895l.896.798-4.02 4.513-2.472-2.377.831-.865 1.574 1.513 3.191-3.582z"
                  transform="translate(-71 -504) translate(56 503) translate(15 1) translate(.5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Shield);
