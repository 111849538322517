/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  GLOBAL_ERRORS_ADD,
  GLOBAL_ERRORS_CLEAN,
} = require('./types');

// Request Actions
const globalErrorsAdd = createAction(GLOBAL_ERRORS_ADD);
const globalErrorsClean = createAction(GLOBAL_ERRORS_CLEAN);

/**
 * Export functions
 */
module.exports = {
  [GLOBAL_ERRORS_ADD]: ({ id, validations }) => (dispatch) => {
    dispatch(globalErrorsAdd({ id, validations }));
  },
  [GLOBAL_ERRORS_CLEAN]: () => (dispatch) => {
    dispatch(globalErrorsClean());
  },
};
