const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconPse = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="42" height="42" viewBox="0 0 42 42">
      <defs>
        <circle id="w41kxk88qa" cx="17.957" cy="17.918" r="17.918"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-742 -230) translate(742 230) translate(3.5 3.938)">
              <mask id="5cpwxs4jwb" fill="#fff">
                <use href="#w41kxk88qa"/>
              </mask>
              <use fill="#314979" fillRule="nonzero" href="#w41kxk88qa"/>
              <g mask="url(#5cpwxs4jwb)">
                <g transform="translate(-12.28 8.96)">
                  <g>
                    <path fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".583" d="M5.6 8.96H.783c-.112 0-.224-.113-.224-.225s.112-.224.224-.224h4.815c.224 0 1.456-.336 1.568-1.904.112-1.456-.784-1.68-.896-1.68H3.136C1.904 4.705 1.68 3.585 1.68 3.025c0-1.568 1.344-2.24 2.016-2.352h4.479c.112 0 .224.112.224.224s-.112.224-.224.224H3.808c-.224 0-1.568.448-1.568 1.904 0 0 0 0 0 0s-.112 1.12 1.008 1.344H6.27s1.568.112 1.456 2.24C7.503 8.174 6.271 8.847 5.6 8.958c0 0 0 0 0 0z" transform="translate(27.997 4.48)"/>
                  </g>
                  <g>
                    <path fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".583" d="M6.831 8.96h-4.48s0 0 0 0C1.792 8.846.672 8.51.56 7.39c0-1.12.784-4.703.784-4.927 0 0 0 0 0 0 0-.112.672-1.792 2.128-1.792H6.83c.112 0 1.344.448 1.344 1.568 0 1.008-.336 2.576-.336 2.688 0 .111-.112.223-.224.223H1.344c-.112.896-.336 1.792-.224 2.24 0 .896 1.12 1.008 1.232 1.008h4.48c.111 0 .223.112.223.224.112.224 0 .336-.224.336zM1.904 2.687c0 .112-.224 1.008-.448 1.904H7.39c.112-.448.224-1.568.224-2.352 0-.672-.784-.896-.896-1.008H3.584c-1.008 0-1.568 1.344-1.68 1.456z" transform="translate(35.836 4.48)"/>
                  </g>
                  <g>
                    <path fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".583" d="M.357 12.655s0 0 0 0c-.224 0-.336-.224-.224-.336 0 0 .448-2.464.896-4.816.224-1.232.448-2.464.56-3.36.112-.447.112-.783.224-1.12 0-.223.112-.335.112-.447h0C2.26 1.68 3.157.784 3.94.672h3.247s0 0 0 0c.224 0 1.68.56 1.568 1.68 0 0 0 0 0 0l-.784 4.703s0 0 0 0c0 .112-.448 1.456-1.792 1.904h-.112l-2.8.112s0 0 0 0-.895-.112-1.68-1.008c-.335 1.68-.56 3.36-.783 4.368-.224.112-.336.224-.448.224zm1.232-5.376c.56 1.12 1.456 1.232 1.568 1.232l2.8-.112C6.963 8.063 7.3 7.055 7.3 6.943l.784-4.703c.112-.56-.672-1.12-1.008-1.12H4.053c-.448.112-1.344.784-1.568 1.568-.112.224-.448 2.24-.896 4.591z" transform="translate(19.913 4.48)"/>
                  </g>
                  <circle cx="15.79" cy="3.92" r="1" fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583"/>
                  <circle cx="13.999" cy="1.344" r="1" fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583"/>
                  <circle cx="12.319" cy="3.92" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="15.678" cy="14.447" r="1" fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583"/>
                  <circle cx="13.999" cy="11.983" r="1" fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583"/>
                  <circle cx="10.639" cy="11.983" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="12.319" cy="14.447" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="10.639" cy="17.134" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="9.071" cy="14.559" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="8.959" cy="3.92" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="10.527" cy="1.344" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="8.847" cy="9.295" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="10.527" cy="6.719" r="1" fill="#EBB532" fillRule="nonzero"/>
                  <circle cx="13.999" cy="17.134" r="1" fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583"/>
                  <path fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583" d="M9.295 9.071L20.718 9.071 20.718 9.519 9.295 9.519zM11.199 6.383L14.783 6.383 14.783 6.831 11.199 6.831z"/>
                  <path fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583" d="M16.238 9.519L14.335 6.383 14.895 6.383 16.798 9.519zM14.671 11.759L16.462 11.759 16.462 12.207 14.671 12.207z"/>
                  <path fill="#EBB532" fillRule="nonzero" stroke="#EBB532" strokeWidth=".583" d="M16.014 12.207L17.694 9.519 18.142 9.519 16.462 12.207z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = IconPse;
