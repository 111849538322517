const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const StepActions = require('../../spa/actions/step');
const SnackbarActions = require('../../spa/actions/snackbar');
const { SNACKBAR_TRIGGER, STEP_LOGOUT, STEP_NEXT } = require('../../spa/actions/types');
const apiService = require('../../service/api');
const {
  COMMONS: {
    CHECKOUT_TYPE: {
      REDIRECT
    }
  },
  APP: {
    URLS: {
      BASE_URL_MERCADO_LIBRE
    }
  }
} = require('../../../constants');
const translate = require('../../translation');
const { getQueryParams } = require('../../utils/Dom');

const { useState, useRef } = React;

const LogoutButton = (props) => {
  const { i18n } = props;
  const translations = translate(i18n);
  const formRef = useRef(null);
  const resetTimeout = 10000;
  const checkInterval = 250;
  const logoutUrl = `${BASE_URL_MERCADO_LIBRE}/jms/${props.siteId.toLowerCase()}/lgz/logout`;

  const [loadIframe, setLoadIframeState] = useState(false);

  const {
    className: componentClassName,
    history,
    snackbarActions,
    stepActions,
    flow,
    browserName,
  } = props;

  const handleLoad = () => {
    const queryParams = getQueryParams();

    stepActions[STEP_NEXT](formRef.current, flow.id, {
      cancel: flow.cancel,
      type: flow.type,
      urlParams: queryParams,
    }, flow.type, queryParams, history);
  }

  const handleLogOut = () => {
    setLoadIframeState(true);
  }

  const redirectLogOut = () => {
    const queryParams = getQueryParams();
    const stepLogoutOptions = {
      data: formRef.current,
      flowId: flow.id,
      defaultData: {
        cancel: flow.cancel,
        type: flow.type,
        urlParams: queryParams,
      },
      type: flow.type,
      urlParams: queryParams,
      history,
      logoutUrl,
    }
    stepActions[STEP_LOGOUT](stepLogoutOptions);
  }

  const getSnackbarMessage = () => {
    // Link to MP home _blank
    const mpHomeHref = (<a href="/" target="_blank">{translations.PANEL}</a>);
    return (
      <span>
        {translations.SIGN_OUT} {mpHomeHref} {translations.TRY_AGAIN}
      </span>
    );
  }

  const displaySnackbar = () => {
    // set action data for error message
    const snackbarActionData = {
      show: true,
      status: 'default',
      message: getSnackbarMessage(),
    };

    snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    setTimeout(() => {
      snackbarActionData.show = false;
      snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    }, resetTimeout);
  }

  /**
   * @TODO Review this code recessivity + async code + timeout
   * @param {*} logoutPopup
   * @param {*} retries
   */
  const checkSafariLogout = (logoutPopup, retries) => {
    setTimeout(() => {
      apiService.isUserLogged()
        .then((response) => {
          if (!response.logged) {
            logoutPopup.close();
            handleLoad();
          } else if (retries > 0) {
            checkSafariLogout(logoutPopup, retries - 1);
          } else {
            logoutPopup.close();
            displaySnackbar();
          }
        }).catch(() => {
          // if there is any error trigger the snackbar error and close the popup
          logoutPopup.close();
          displaySnackbar();
        });
    }, checkInterval);
  }

  const doLogout = () => {
    const { type } = flow;
    if (type === REDIRECT) {
      return redirectLogOut();
    }
    const iframeUrl = `${logoutUrl}?embedded_mode=true`;
    if (['safari', 'mobile safari', 'firefox'].includes(browserName)) {
      const logoutPopup = window.open(iframeUrl, 'logout_popup', 'width=10, height=10, top=10, left=10');
      if (logoutPopup && !logoutPopup.closed) {
        checkSafariLogout(logoutPopup, 3);
      } else {
        // Couldnt open the Logout popup so a Snackbar will display the action he needs to do to logout
        displaySnackbar();
      }

      return null;
    }

    return (
      <iframe
        id="logout-frame"
        title="logout"
        style={{ visibility: 'hidden', display: 'none', width: 0, height: 0 }}
        src={iframeUrl}
        onLoad={handleLoad}
      />
    );
  }

  return (
    <div className={componentClassName}>
      {loadIframe && doLogout()}
      <form method="POST" style={{ visibility: 'hidden' }} ref={formRef} key={Math.random()}>
        <input
          type="hidden"
          name="[components][user]"
          value="false"
        />
        {
          props.changeUser && (
            <input
              type="hidden"
              name="[components][change_user]"
              value="true"
            />
          )
        }
      </form>
      <button onClick={handleLogOut} className="user-logout andes-button--link">{props.text}</button>
    </div>
  );
}

LogoutButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  siteId: PropTypes.string,
  changeUser: PropTypes.bool,
  history: PropTypes.shape({}),
  flow: PropTypes.shape({}),
  stepActions: PropTypes.shape({}),
  snackbarActions: PropTypes.shape({}),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  snackbarActions: bindActionCreators(SnackbarActions, dispatch),
  stepActions: bindActionCreators(StepActions, dispatch),
});
const mapStateToProps = state => ({
  flow: state.page.flow,
  siteId: state.configurations.platform.siteId,
  browserName: state.configurations.browserName,
});

LogoutButton.defaultProps = {
  text: '',
  className: '',
  siteId: '',
  flow: {},
  history: {},
  stepActions: {},
  snackbarActions: {},
  i18n: {
    gettext: t => t,
  },
  changeUser: false,
};

if (process.env.NODE_ENV === 'test') {
  module.exports = LogoutButton;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(LogoutButton));
}
