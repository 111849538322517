/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  DYNAMIC_MODAL_DESCRIPTION,
  BLOCKED_POPUP,
} = require('./types');

// Request Actions
const dynamicModalDescription = createAction(DYNAMIC_MODAL_DESCRIPTION);
const blockedPopupFired = createAction(BLOCKED_POPUP);

/**
 * Export functions
 */
module.exports = {
  [DYNAMIC_MODAL_DESCRIPTION]: dynamicDescription => dispatch => dispatch(dynamicModalDescription({ dynamicDescription })),
  [BLOCKED_POPUP]: blockedPopup => dispatch => dispatch(blockedPopupFired({ blockedPopup })),
};
