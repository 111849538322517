const React = require('react');
const PropTypes = require('prop-types');

const { convertTime } = require('../../utils/Date');

const Markdown = (props) => {
  let text = props.text || '';

  const splitAmount = (regex, amount, separator) => {
    const splittedAmount = amount.split(separator);
    // split the amount by the decimal separator , or .
    // if there are more than 1 result we found the decimals else just ruturn the original amount
    return splittedAmount.length === 2 ? `${splittedAmount[0]}<span class="amount-decimal-separator">${separator}</span><sup>${splittedAmount[1]}</sup>` : amount;
  };

  const markdownList = [{
    regex: /\*{2}([^\s].*?)\*{2}/g,
    markupOpen: '<b>',
    markupClose: '</b>',
  },
  {
    regex: /_{2}([^\s^_+$][_\SA-Za-z0-9\s]*?)_{2}/g,
    markupOpen: '<i>',
    markupClose: '</i>',
  },
  {
    regex: /\[([^\[]+)\]\(([^\)]+)\)(\{\:target=(_blank|_self|_parent|_top)\})?/g,
    callback: (...args) => `<a href="${args[2]}" target="${args[4] || '_self'}">${args[1]}</a>`,
  },
  {
    regex: /\-\#\-(.*)\-\#\-/g,
    /**
     * Date time from client
     */
    callback: (originalText, date) => convertTime(date),
  },
  {
    regex: /\-\$\-(.*)\-\$\-/g,
    /**
     * Callback to apply a complex replace rule for the ammout markdown
     * to separte the decimals and wrap them on a <sup></sup>
     */
    callback: (captured, amount) => {
      let result;
      // regex to match amounts with comma decimals eg: $2.000,23, R$-2.000,23, ARS 2,56
      const commaDecimalRegex = /^[A-Za-z$]{1,}\s*-?((\d{1,3}(\.(\d){3})*)|\d*)(,\d{1,2})?\s?\s*$/g;
      // regex to match amounts with dot decimals eg: $2,000.23, R$-2,000.23, ARS 2.56
      const dotDecimalRegex = /^[A-Za-z$]{1,}\s*-?((\d{1,3}(,(\d){3})*)|\d*)(\.\d{1,2})?\s?\s*$/g;

      if (amount.match(commaDecimalRegex)) {
        // if is a comma decimal split the decimals by "," to wrapp them with <sup></sup>
        result = splitAmount(commaDecimalRegex, amount, ',');
      } else if (amount.match(dotDecimalRegex)) {
        // if is a dot decimal split the decimals by "." to wrapp them with <sup></sup>
        result = splitAmount(dotDecimalRegex, amount, '.');
      } else {
        // if either just replace the *$* markdown
        result = amount;
      }
      return `<span class="price--wrapper">${result}</span>`;
    },
  },
  {
    regex: /\n/g,
    markupOpen: '<br>',
    markupClose: '',
  },
  {
    regex: /~{2}([^\s].*?)~{2}/g,
    markupOpen: '<del>',
    markupClose: '</del>',
  }];

  markdownList.forEach((markdown) => {
    if (markdown.callback) {
      text = text.replace(markdown.regex, markdown.callback);
    } else {
      const replacement = markdown.markupClose ? `${markdown.markupOpen}$1${markdown.markupClose}` : markdown.markupOpen;
      text = text.replace(markdown.regex, replacement);
    }
  });

  return (
    <span dangerouslySetInnerHTML={{ __html: text }} />
  );
};

Markdown.propTypes = {
  text: PropTypes.string,
};

Markdown.defaultProps = {
  text: '',
};

module.exports = Markdown;
