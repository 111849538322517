const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const GenericWalletIcon = ({color}) => (
    <IconWrapper>
      <svg
        className="with-custom-color"
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.4799 9.40032H14.6V7.96032H17.4799V9.40032Z"
          fill={color}/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.199951 0.761719V16.5105L19.2562 16.5993L19.268 16.5993C20.6597 16.5993 21.7879 15.4711 21.7879 14.0793V3.28172C21.7879 1.88996 20.6597 0.761719 19.268 0.761719H0.199951ZM20.348 6.52632V10.8471L15.201 10.8323C14.012 10.8289 13.05 9.8641 13.05 8.67518C13.05 7.4884 14.0121 6.52632 15.1989 6.52632H20.348ZM15.1989 5.08632H20.348V3.28172C20.348 2.68525 19.8644 2.20172 19.2679 2.20172H1.63995V15.0772L19.2629 15.1593C19.8644 15.1593 20.348 14.6758 20.348 14.0793V12.2871L15.1968 12.2723C13.2142 12.2666 11.61 10.6578 11.61 8.67518C11.61 6.69311 13.2168 5.08632 15.1989 5.08632Z"
          fill={color}/>
      </svg>
    </IconWrapper>
  )
;
GenericWalletIcon.defaultProps = {
  color: 'currentColor',
};

GenericWalletIcon.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(GenericWalletIcon);
