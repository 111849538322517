const React = require('react');
const PropTypes = require('prop-types');

const IconAccountMoney = require('../icons/AccountMoney');
const IconMercadoCredito = require('../icons/MercadoCredito');
const PaymentCardIcon = require('../PaymentCardIcon');
const IconCreditCard = require('../icons/CreditCard');
const IconMercadoPagoBlack = require('../icons/MercadoPagoBlack');
const {
  COMMONS: { PAYMENT_METHOD_ID, PAYMENT_METHOD_TYPE, SITE_ID },
} = require('../../../constants');

const IconOnlinePaymentMethod = (props) => {
  if (props.paymentMethod.type === PAYMENT_METHOD_TYPE.ACCOUNT_MONEY) {
    const sitesWithIconBlack = [SITE_ID.MLM, SITE_ID.MLB, SITE_ID.MLC, SITE_ID.MLA];
    return sitesWithIconBlack.includes(props.siteId) ? (
      <IconMercadoPagoBlack key={props.paymentMethod.id} />
    ) : (
      <IconAccountMoney key={props.paymentMethod.id} alt={props.title} />
    );
  }

  if (props.paymentMethod.id === PAYMENT_METHOD_ID.CONSUMER_CREDITS) {
    return <IconMercadoCredito key={props.paymentMethod.id} />;
  }

  if (
    props.paymentMethod.type === PAYMENT_METHOD_TYPE.CREDIT_CARD ||
    props.paymentMethod.type === PAYMENT_METHOD_TYPE.DEBIT_CARD ||
    props.paymentMethod.type === PAYMENT_METHOD_TYPE.PREPAID_CARD
  ) {
    return <PaymentCardIcon cardId={props.paymentMethod.id} issuerName={props.paymentMethod.issuer_name} />;
  }

  return <IconCreditCard key={Math.random()} />;
};

IconOnlinePaymentMethod.propTypes = {
  paymentMethod: PropTypes.object,
  siteId: PropTypes.string,
};

IconOnlinePaymentMethod.defaultProps = {
  paymentMethod: {
    id: '',
    type: '',
    issuer_name: '',
  },
  siteId: '',
};

module.exports = IconOnlinePaymentMethod;
