import React from 'react';
import { Card, CardContent } from '@andes/card';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import IconWrapper from '../Icons/IconWrapper';
import MercadoCredito from '../Icons/MercadoCredito';
import CloseIcon from '../Icons/CloseIcon';

export type MercadoCreditsBannerProps = {
  className?: string;
  title: string;
  description: string;
  promotion?: string;
  submitLabel: string;
  onClose: () => void;
  onClick: () => void;
  isMobile?: boolean;
};

export default function MercadoCreditsBanner({
  className = '',
  title,
  description,
  promotion,
  submitLabel,
  onClose,
  onClick,
  isMobile = false,
}: MercadoCreditsBannerProps) {
  return (
    <div className={`card-credits ${isMobile ? 'card-credits--mobile' : ''}`}>
      <Card className={className} shadow={undefined}>
        <CardContent>
          <IconWrapper className="card-credits__icon-wrapper">
            <MercadoCredito width={36} height={26} />
          </IconWrapper>

          <div className="card-credits__texts">
            <div className="card-credits__texts__title">
              <Typography
                className="title__text"
                type="body"
                size="s"
                weight="semibold"
              >
                {title}
              </Typography>
            </div>

            <div className="card-credits__texts__description">
              <Typography className="description__text" type="body" size="s">
                {description}
              </Typography>
            </div>

            {promotion && (
              <div className="card-credits__texts__promotion">
                <Typography className="promotion__text" type="body" size="s">
                  {promotion}
                </Typography>
              </div>
            )}

            <Button
              onClick={onClick}
              className="card-credits__texts__submit"
              hierarchy="transparent"
              size="medium"
            >
              {submitLabel}
            </Button>
          </div>

          {<CloseIcon onClick={onClose} className="card-credits__close" />}
        </CardContent>
      </Card>
    </div>
  );
}
