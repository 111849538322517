const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const Shield = require('../icons/ShieldBPP');
const translate = require('../../translation');

const BppShield = (props) => {
  const { i18n } = props;
  const translations = translate(i18n);
  const { CONTEXT, IF_YOUR_PACKAGE_DOES_NOT_ARRIVE_WE_WILL_REFUND_YOUR_MONEY } = translations;

  return (
    <div className="bpp">
      <p className="bpp__title">{i18n.pgettext(CONTEXT, IF_YOUR_PACKAGE_DOES_NOT_ARRIVE_WE_WILL_REFUND_YOUR_MONEY)}</p>
      <div className="bpp__shield">
        <Shield /> <span>{` ${translations.PROTECTED_PURCHASE}`}</span>
      </div>
    </div>
  );
};

BppShield.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

if (process.env.NODE_ENV === 'test') {
  module.exports = BppShield;
} else {
  module.exports = injectI18n(BppShield);
}
