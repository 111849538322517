/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  DYNAMIC_MODAL_DESCRIPTION,
  BLOCKED_POPUP,
} = require('../actions/types');

const actions = {};

const initialState = {
  dynamicDescription: false,
  blockedPopup: false,
};

actions[DYNAMIC_MODAL_DESCRIPTION] = (state, action) => ({
  ...state,
  dynamicDescription: action.payload.dynamicDescription,
});

actions[BLOCKED_POPUP] = (state, action) => ({
  ...state,
  blockedPopup: action.payload.blockedPopup,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
