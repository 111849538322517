/**
 * Module dependencies
 */
const { matchPath } = require('react-router');

/**
 * Match pathname with routes
 * @param {Array} routes
 * @param {String} pathname
 */
module.exports = (routes = [], pathname = '') => {
  let route = null;

  for (let i = 0; i < routes.length; i += 1) {
    if (routes[i].path) {
      const match = matchPath(pathname, routes[i]);

      if (match) {
        const matchParamKeys = Object.keys(match.params);

        // Remove ? from url params
        for (let j = 0; j < matchParamKeys.length; j += 1) {
          if (/\?$/.test(match.params[matchParamKeys[j]])) {
            match.params[matchParamKeys[j]] = match.params[matchParamKeys[j]].replace(/\?$/, '');

            break;
          }
        }

        route = {
          ...routes[i],
          component: routes[i].component,
          fetchData: routes[i].fetchData || null,
          params: match.params,
        };

        break;
      }
    }
  }

  return route;
};
