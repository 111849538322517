/**
 * Module dependencies
 * The purpose of this reducer is to create a state for the tracking redirect step
 * to comunicate the callback url to the challenges.
 */
const { handleActions } = require('redux-actions');

const {
  TRACKING_REDIRECT_CHALLENGE,
} = require('../actions/types');

const actions = {};

const initialState = {
  challengeRedirectUrl: null,
};

actions[TRACKING_REDIRECT_CHALLENGE] = (state, action) => ({
  challengeRedirectUrl: action.payload,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
