const React = require('react');
const PropTypes = require('prop-types');

const { Button: AndesButton } = require('@andes/button');
const classNames = require('classnames');

const Button = (props) => {
  const {
    id,
    name,
    text,
    disabled,
    loading,
    size,
    type,
    fullWidth,
    kind,
    onClick,
    icon,
    isDefault,
    className,
    progressDone,
    dynamicDescription,
  } = props;
  /**
   * Recieve a handle click function to attach to the click event
   * @param {*} event
   */
  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const isInvalindKind = () => {
    const availableKinds = ['link', 'loud', 'quiet', 'transparent'];
    return !availableKinds.includes(kind);
  };

  // temporary change to normalyze the kind value
  const hierarchy = isInvalindKind() ? 'loud' : kind;

  const _className = classNames(className, {
    'progress-done': progressDone,
    'done-dynamic': dynamicDescription,
    button_back_default: isDefault,
    [`icon icon-${icon}`]: !!icon,
  });

  return (
    <AndesButton
      className={_className}
      data-loading={loading}
      disabled={disabled}
      hierarchy={hierarchy}
      id={id}
      name={name}
      onClick={handleClick}
      type={type}
      fullWidth={fullWidth}
      size={size}
    >
      {text}
    </AndesButton>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  fullWidth: PropTypes.bool,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(['lock--outline', null]),
  isDefault: PropTypes.bool,
  className: PropTypes.string,
  progressDone: PropTypes.bool,
  dynamicDescription: PropTypes.bool,
};

Button.defaultProps = {
  id: null,
  name: '',
  text: '',
  disabled: false,
  loading: false,
  size: 'large',
  type: 'submit',
  fullWidth: false,
  kind: 'loud',
  onClick: null,
  icon: null,
  isDefault: false,
  className: '',
  progressDone: false,
  dynamicDescription: false,
};

module.exports = Button;
