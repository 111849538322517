// TODO unify with hooks from review templates.
const React = require('react');

const ApiService = require('../../service/api');

// Redux
const {
  STEP_NEXT,
  STEP_LOGOUT,
  SNACKBAR_TRIGGER,
} = require('../../spa/actions/types');

// Constants
const {
  CHECKOUT_TYPE,
  URLS: {
    BASE_URL_MERCADO_LIBRE
  },
} = require('../../../constants/app');

// Utils
const { getQueryParams } = require('../../utils/Dom');

export const useLogOut = ({
  flow,
  siteId,
  browserName,
  history,
  stepActions,
  snackbarActions,
  translations
}) => {
  function getLogoutSnackbarComponent() {
    // Link to MP home _blank
    const mpHomeHref = (<a href="/" target="_blank">{translations.PANEL}</a>);
    return (
      <span>
        {translations.SIGN_OUT} {mpHomeHref} {translations.TRY_AGAIN}
      </span>
    );
  };

  function displaySnackbar ({ status = 'default', component, displayTimeout = 10000 }) {
    // set action data for error message
    const snackbarActionData = {
      show: true,
      status,
      message: component,
    };

    snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    setTimeout(() => {
      snackbarActionData.show = false;
      snackbarActions[SNACKBAR_TRIGGER](snackbarActionData);
    }, displayTimeout);
  };

  // ===== GENERAL ACTIONS =====

  function nextStepAction ({ data }) {
    const queryParams = getQueryParams();
    stepActions[STEP_NEXT](
      {...data, isNewInterface: true},
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  /**
   * @TODO Review this code recessivity + async code + timeout
   * @param {*} logoutPopup
   * @param {*} retries
   */
  function checkLogoutStatus ({ logoutPopup, retries, changeUser }) {
    const queryParams = getQueryParams();
    setTimeout(() => {
      ApiService.isUserLogged()
        .then((response) => {
          if (!response.logged) {
            logoutPopup.close();
            nextStepAction({
              data: {
                components: {
                  user: changeUser.toString(),
                },
              },
              defaultData: { type: flow.type, urlParams: queryParams, cancel: flow.cancel },
            });
            return;
          }

          if (retries > 0) {
            checkLogoutStatus(logoutPopup, retries - 1);
            return;
          }

          logoutPopup.close();
          displaySnackbar({
            component: getLogoutSnackbarComponent(),
          });
        })
        .catch(() => {
          // If there is any error, trigger the snackbar error and close the popup
          logoutPopup.close();
          displaySnackbar({
            component: getLogoutSnackbarComponent(),
          });
        });
    }, 250);
  };

  function createLogoutIframe ({ logoutUrl, changeUser = false }) {
    const queryParams = getQueryParams();
    const iframe = document.createElement('iframe');
    iframe.id = 'logout-frame';
    iframe.title = 'logout';
    iframe.style.visibility = 'hidden';
    iframe.style.display = 'none';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.src = logoutUrl;

    iframe.onload = () =>
      nextStepAction({
        data: {
          components: {
            user: changeUser.toString(),
          },
        },
        defaultData: { type: flow.type, urlParams: queryParams, cancel: flow.cancel },
      });

    return iframe;
  };

  function logOutStepAction ({ logoutUrl, data }) {
    const queryParams = getQueryParams();
    stepActions[STEP_LOGOUT]({
      data: {
        ...data,
        isNewInterface: true
      },
      flowId: flow.id,
      defaultData: {
        cancel: flow.cancel,
        type: flow.type,
        urlParams: queryParams
      },
      type: flow.type,
      urlParams: queryParams,
      history,
      logoutUrl,
    });
  };


  function logout (configs = {}) {
    const { changeUser } = configs;
    const LOG_OUT_URL = `${BASE_URL_MERCADO_LIBRE}/jms/${siteId.toLowerCase()}/lgz/logout`;

    // === REDIRECT MODE ===
    if (flow?.type === CHECKOUT_TYPE.REDIRECT) {
      const payload = {
        logoutUrl: LOG_OUT_URL,
        data: {
          components: {
            user: changeUser.toString(),
          },
        },
      };
      if(changeUser) {
        payload.data.components.change_user = "true";
      }
      return logOutStepAction(payload);
    }

    // === EMBEDDED & MODAL MODES ===
    const IFRAME_URL = `${LOG_OUT_URL}?embedded_mode=true`;
    const WHITELISTED_BROWSERS = ['safari', 'mobile safari', 'firefox'];

    if (WHITELISTED_BROWSERS.includes(browserName)) {
      const logoutPopup = window.open(IFRAME_URL, 'logout_popup', 'width=10, height=10, top=10, left=10');
      if (logoutPopup && !logoutPopup.closed) {
        checkLogoutStatus({ logoutPopup, retries: 3, changeUser });
        return;
      }

      // Couldn't open the Logout popup so a Snackbar will display the action he needs to do to logout
      displaySnackbar({
        component: getLogoutSnackbarComponent(),
      });
      return;
    }

    const iframe = createLogoutIframe({ logoutUrl: IFRAME_URL, changeUser });
    document?.body?.appendChild(iframe);
  };

  return {
    logout,
  };
}
