export function getInitials(name: string, maxInitialsLength: number): string {
  if (!name) {
    console.error('[getInitials] You need to provide the name argument');

    return '';
  }

  const nameParts = name.trim().split(/\s+/);

  let initials = '';

  const length = Math.min(nameParts.length, maxInitialsLength);

  for (let i = 0; i < length; i += 1) {
    initials += nameParts[i][0].toUpperCase();
  }

  return initials;
}
