const React = require('react');
const PropTypes = require('prop-types');

const { ListItem } = require('@andes/list');

const renderPrimaryText = ({ code, label }) => (
  <div>
    <p>
      <span>{code} - </span>
      <span className="andes-list__primary-text">{label}</span>
    </p>
  </div>
);

const BankListItem = ({ bank, onClick }) => (
  <ListItem
    key={bank.label}
    className="bank-selection-item"
    onClick={onClick}
    title={renderPrimaryText(bank)}
  />
);

BankListItem.propTypes = {
  bank: PropTypes.shape({
    label: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
};

module.exports = BankListItem;
