const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const translate = require('../../translation');

const Terms = props => {
  const { i18n } = props;
  const translations = translate(i18n);

  return (
    <div className="terms">
      <p>
        <span>{translations.ACCEPT_THE_TERMS}</span>
        <a
          href={props.urls.tyc.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translations.TERMS_AND_CONDITIONS}
        </a>
        <span>{translations.OF_MP}</span>
      </p>
    </div>
  )
};

Terms.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  urls: {
    tyc: {
      link: '',
    },
  },
};

Terms.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  urls: PropTypes.shape({
    tyc: PropTypes.shape({
      link: PropTypes.string,
    }),
  }),
};

if (process.env.NODE_ENV === 'test') {
  module.exports = Terms;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(Terms);
}
