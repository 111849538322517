/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  COLLAPSIBLE_CLICKED,
} = require('./types');

// Request Actions
const collapsibleClickedAction = createAction(COLLAPSIBLE_CLICKED);

/**
 * Export functions
 */
module.exports = {
  /**
   * handles collapsible statuses
   * @return {function(*)}
   */
  [COLLAPSIBLE_CLICKED]: payload => (dispatch) => {
    dispatch(collapsibleClickedAction(payload));
  },
};
