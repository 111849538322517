const React = require('react');
const PropTypes = require('prop-types');

const ButtonCloseModalLink = ({ kind, name, text, id }) => (
  <button
    className={`andes-button andes-button--${kind}`}
    id={id}
    name={name}
    type="submit"
    data-close-payment
  >
    <span className="andes-button__content">{text}</span>
  </button>
);

ButtonCloseModalLink.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'loud', 'quiet', 'transparent']),
};

ButtonCloseModalLink.defaultProps = {
  id: null,
  name: '',
  text: '',
  kind: 'link',
};

module.exports = ButtonCloseModalLink;
