const React = require('react');

const { ListItem } = require('@andes/list');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const IconBlueChevron = require('../icons/BlueChevron');
const CollapsibleSelector = require('../CollapsibleSelector');
const paymentMethodActions = require('../../spa/actions/paymentMethod');
const collapsibleActions = require('../../spa/actions/collapsible');
const sizeActions = require('../../spa/actions/size');
const currencyUtil = require('../../utils/currency');
const installmentActions = require('../../spa/actions/installment');
const inputValuesActions = require('../../spa/actions/inputValues');
const IconInstallmentsDynamic = require('../IconInstallmentsDynamic');
const {
  INSTALLMENT_UPDATE,
  CURRENT_INPUT_VALUES,
} = require('../../spa/actions/types');

const translate = require('../../translation');

class InstallmentsCollapsibleSelector extends CollapsibleSelector {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.translations = translate(i18n);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options.length > 0) {
      if (nextProps.options !== this.props.options) {
        const selected = nextProps.options[0];
        const options = nextProps.options.slice(1);
        this.setState({ options, selected });
        this.props.installmentActions[INSTALLMENT_UPDATE]({ value: selected.installments });
        this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_${this.props.id}`, selected.installments);
      }
    } else if (nextProps.step !== 'wallet_congrats_approved' && nextProps.step !== 'wallet_congrats_rejected') {
      /* TODO: Look for a better way of handling the transition to the congrats in wallet_payment while we're
      * processing a payment in order to avoid having to check for the specific step name in the component
      * to prevent it from erasing the data we still need to show. */
      this.setState({ options: [], selected: {}, open: false });
    }
  }

  setOption(installment) {
    const selected = installment;
    const options = this.props.options.filter(option => option.installments !== installment.installments);
    this.setState({ options, selected });
    this.props.installmentActions[INSTALLMENT_UPDATE]({ value: installment.installments });
    this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_${this.props.id}`, installment.installments);
    this.toggle();
  }

  getInstallmentLabel(option, hasInterest) {
    const installmentRate = option.interest_free ? <span className="rate">{this.translations.NO_INTEREST()}</span> : null;
    const cftText = option.financing_cost_label && option.financing_cost_value ? ` | ${option.financing_cost_label}: ${option.financing_cost_value}` : '';
    const interestAmount = this.state.open
      ? <div className="interest-amount"> {option.interest_amount_label}</div>
      : <div className="interest-amount"> {option.interest_amount_label} {cftText}</div>;

    return (
      <div>
        {this.state.open ? null : <IconInstallmentsDynamic />}
        <div className="installment-label">
          <div className="installment">
            <span className={`quantity ${option.installments.toString().length > 1 ? 'double-digit' : 'single-digit'}`}>{option.installments}x</span>
            <span className="installment-amount">{currencyUtil.parseAmount(option.installment_amount, this.props.currency)}</span>
            {installmentRate}
          </div>
          {hasInterest ? interestAmount : null}
        </div>
      </div>
    );
  }

  renderItem(option, className = '') {
    const hasInterest = option.installments !== 1 && !option.interest_free;
    const primaryText = this.getInstallmentLabel(option, hasInterest);
    const onClick = option.installments === this.state.selected.installments ? this.toggle : () => this.setOption(option);
    const total = option.financing_cost_label && option.financing_cost_value && hasInterest
      ? (
        <div className="tertiary">
          {this.state.open ? (
            <div className="cft">
              <span className="total">{currencyUtil.parseAmount(option.total_amount, this.props.currency)}</span><span>{option.financing_cost_label}: {option.financing_cost_value}</span>
            </div>
          ) : null}
          <IconBlueChevron />
        </div>
      )
      : <IconBlueChevron />;
    return <ListItem key={Math.random()} className={hasInterest ? `${className} option-with-interest` : className} title={primaryText} onClick={onClick} rightContent={total} />;
  }

  getClasses() {
    return 'installments-collapsible-selector';
  }
}

const mapDispatchToProps = dispatch => ({
  paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
  sizeActions: bindActionCreators(sizeActions, dispatch),
  collapsibleActions: bindActionCreators(collapsibleActions, dispatch),
  installmentActions: bindActionCreators(installmentActions, dispatch),
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

const mapStateToProps = (state, props) => ({
  savedValue: state.inputValues.current[`${state.page.flow.step}_${props.id}`],
  options: state.selectOptions[`${state.page.flow.step}_installments_collapsible_selector`],
  step: state.page.flow.step,
  currency: state.configurations.currency,
  show: state.inputValues.visibility[`${state.page.flow.step}_${(props.id)}`],
});

/**
 * Expose OptionList
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = InstallmentsCollapsibleSelector;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(InstallmentsCollapsibleSelector);
}
