const {
  CARD_FORM_BRICKS_ERRORS: {
    SUBMIT_FORM_ERROR,
    MISSING_CARD_DATA
  }
} = require('../../../../../../constants/app');

const BRICKS_SUBMIT_ERRORS = [
  SUBMIT_FORM_ERROR,
  MISSING_CARD_DATA,
]

const handleBricksSubmitError = (error) =>
  BRICKS_SUBMIT_ERRORS.includes(error?.reason);

module.exports = {
  handleBricksSubmitError,
};