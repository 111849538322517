const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconBack = () => (
  <IconWrapper className="icon-back">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
      <path fill="#333" fillRule="evenodd" d="M8.334 0l1.39 1.4L4.165 7h12.115v2H4.163l5.562 5.6-1.39 1.4L.39 8z" opacity=".5" />
    </svg>
  </IconWrapper>
);

module.exports = IconBack;
