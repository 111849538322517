/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { COLLAPSIBLE_CLICKED } = require('../actions/types');

const actions = {};

const initialState = {
  status: {},
};

actions[COLLAPSIBLE_CLICKED] = (state, action) => {
  const status = {
    ...state.status,
  };
  status[action.payload.collapsibleId] = action.payload.isOpen;
  return status;
};

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
