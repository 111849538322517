const React = require('react');
const PropTypes = require('prop-types');

const GroupMediaObject = ({ id, children }) => (
  <div className="group-media-object" id={id}>
    {children}
  </div>
);

GroupMediaObject.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GroupMediaObject.defaultProps = {
  id: '',
  children: null,
};

module.exports = GroupMediaObject;
