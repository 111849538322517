const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconOops = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="104" viewBox="0 0 128 104">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path fill="#D7DDE4" fillRule="nonzero" stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M126 50.5h-15 15z" />
        <path fill="#D7DDE4" d="M58.148 42.87c0-1.382 1.125-2.5 2.49-2.5h12.8v5h-12.8a2.49 2.49 0 0 1-2.49-2.5zm0 16.986c0-1.38 1.125-2.5 2.49-2.5h12.8v5h-12.8a2.49 2.49 0 0 1-2.49-2.5z" />
        <path stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M74 31.997A2 2 0 0 1 76.002 30h19.87C104.225 30 111 36.77 111 45.118v11.764C111 65.232 104.235 72 95.87 72H76.003A2 2 0 0 1 74 70.003V31.997z" />
        <path fill="#D7DDE4" d="M79.5 37.992c0-1.1.895-1.992 1.997-1.992h13.876c5.593 0 10.127 4.524 10.127 10.115v9.77C105.5 61.47 100.97 66 95.373 66H81.497a1.998 1.998 0 0 1-1.997-1.992V37.992z" />
        <path fill="#D7DDE4" stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M126 38v26" />
        <path stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M88 43.7v14.63M42 27H0v48h42z" />
        <rect width="28" height="35" x="7" y="33" fill="#D7DDE4" rx="10" transform="matrix(-1 0 0 1 42 0)" />
        <path fill="#D7DDE4" stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M63 0v12m24-3l-8 8M39 9l8 8m16 85V90m24 3l-8-8m-40 8l8-8M16.5 44.5h10m-10 13h10" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = IconOops;
