const bus = require('./bus')(true);

const messages = {
  RESIZE: 'resize',
  OPEN_WALLET_LOGIN: 'open_wallet_login',
};

module.exports = () => ({
  resize: (size = 0) => bus.emit(messages.RESIZE, { size }),
  openWalletLogin: () => bus.emit(messages.OPEN_WALLET_LOGIN),
});
