const React = require('react');
const PropTypes = require('prop-types');

const GroupCoupon = props => (
  <div className={`group-coupon ${props.applied ? 'applied' : ''}`}>
    {props.children}
  </div>
);

GroupCoupon.defaultValues = {
  children: null,
  applied: false,
};

GroupCoupon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  applied: PropTypes.bool,
};

module.exports = GroupCoupon;
