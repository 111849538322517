const React = require('react');

const IconWrapper = require('../IconWrapper');

const SecureComputerIcon = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="124" height="82" viewBox="0 0 124 82">
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M121.158 78.274c0 .552-.45 1-1 1H3c-.551 0-1-.448-1-1v-5.068c0-.552.449-1 1-1h117.158c.55 0 1 .448 1 1v5.068zm-1-8.068h-11.701v-27.85c-.644.42-1.312.807-2 1.16v26.69H16.7V19.92c0-2.207 1.794-4 4-4h54.298c.245-.683.52-1.35.828-2H20.7c-3.31 0-6 2.691-6 6v50.287H3c-1.654 0-3 1.345-3 3v5.068c0 1.654 1.346 3 3 3h117.158c1.654 0 3-1.346 3-3v-5.068c0-1.655-1.346-3-3-3z"
        />
        <path
          fill="#9DD8FF"
          d="M96.497.274c-12.428 0-22.628 9.665-23.445 21.85H23v42.15h77.768V46.73C111.695 44.72 120 35.16 120 23.699 120 10.783 109.456.274 96.497.274z"
        />
        <path
          fill="#9DD8FF"
          d="M72.082 22C73.098 9.68 83.42 0 96 0c13.255 0 24 10.745 24 24 0 11.54-8.145 21.178-19 23.478V64H23V22h49.082z"
        />
        <path fill="#FFF" d="M96 46c-12.13 0-22-9.87-22-22S83.87 2 96 2s22 9.87 22 22-9.87 22-22 22" />
        <path stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M96 18v6l3.091 3.091" />
        <path d="M96 12v1M96 35v1" stroke="currentColor" strokeLinecap="square" strokeWidth="2" />
        <path
          d="M101.887 13.577l-.5.866M90.387 33.496l-.5.866"
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth="2"
        />
        <path
          d="M106.197 17.887l-.866.5M86.278 29.387l-.866.5"
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth="2"
        />
        <path d="M107.774 23.774h-1M84.774 23.774h-1" stroke="currentColor" strokeLinecap="square" strokeWidth="2" />
        <path
          d="M106.197 29.662l-.866-.5M86.278 18.162l-.866-.5"
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth="2"
        />
        <path
          d="M101.887 33.971l-.5-.866M90.387 14.053l-.5-.866"
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth="2"
        />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(SecureComputerIcon);
