const React = require('react');

const Grey = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-card-cvv-grey">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.25 4.99658H2.25C1.00736 4.99658 0 6.00394 0 7.24658V17.7474C0 18.99 1.00736 19.9974 2.25 19.9974H20.25C21.4926 19.9974 22.5 18.99 22.5 17.7474V7.24658C22.5 6.00394 21.4926 4.99658 20.25 4.99658ZM1.5 17.7474V11.0083H21V17.7474C21 18.1616 20.6642 18.4974 20.25 18.4974H2.25C1.83579 18.4974 1.5 18.1616 1.5 17.7474ZM1.5 9.50832H21V7.24658C21 6.83237 20.6642 6.49658 20.25 6.49658H2.25C1.83579 6.49658 1.5 6.83237 1.5 7.24658V9.50832Z"
          fill="black" fillOpacity="0.25"/>
    <rect x="7.5" y="3.75" width="17.5" height="11.25" rx="2" fill="#737373"/>
    <path
      d="M12.1223 10.36L12.1723 9.21L11.2123 9.84L10.9923 9.44L12.0123 8.92L10.9923 8.4L11.2123 8.01L12.1723 8.63L12.1223 7.48H12.5823L12.5223 8.63L13.4823 8.01L13.7123 8.4L12.6923 8.92L13.7123 9.44L13.4823 9.84L12.5223 9.21L12.5823 10.36H12.1223ZM16.0208 10.36L16.0708 9.21L15.1108 9.84L14.8908 9.44L15.9108 8.92L14.8908 8.4L15.1108 8.01L16.0708 8.63L16.0208 7.48H16.4808L16.4208 8.63L17.3808 8.01L17.6108 8.4L16.5908 8.92L17.6108 9.44L17.3808 9.84L16.4208 9.21L16.4808 10.36H16.0208ZM19.9192 10.36L19.9692 9.21L19.0092 9.84L18.7892 9.44L19.8092 8.92L18.7892 8.4L19.0092 8.01L19.9692 8.63L19.9192 7.48H20.3792L20.3192 8.63L21.2792 8.01L21.5092 8.4L20.4892 8.92L21.5092 9.44L21.2792 9.84L20.3192 9.21L20.3792 10.36H19.9192Z"
      fill="white"/>
  </svg>

);

module.exports = Grey;
