/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  SAVE_ADDRESS_STATE_INFORMATION,
  SAVE_ADDRESS_CITY_INFORMATION,
  SAVE_ADDITIONAL_ADDRESS_INFORMATION,
  CLEAR_ADDRESS_INFORMATION,
} = require('../actions/types');

const actions = {};

const initialState = {
  state: {
    id: '',
    name: '',
  },
  city: {
    id: '',
    name: '',
  },
  street: {
    name: '',
    number: '',
  },
  zipcode: '',
  neighborhood: '',
};

actions[SAVE_ADDRESS_STATE_INFORMATION] = (state, action) => {
  const newState = {
    ...state,
    city: initialState.city,
    state: {
      name: action.payload.name,
      id: action.payload.id,
    },
  };
  return newState;
};
actions[SAVE_ADDRESS_CITY_INFORMATION] = (state, action) => {
  const newState = {
    ...state,
    city: {
      name: action.payload.name,
      id: action.payload.id,
    },
  };
  return newState;
};
actions[SAVE_ADDITIONAL_ADDRESS_INFORMATION] = (state, action) => {
  const newState = {
    ...state,
    street: {
      name: action.payload.streetName,
      number: action.payload.streetNumber,
    },
    zipcode: action.payload.zipcode,
    neighborhood: action.payload.neighborhood,
  };
  return newState;
};
actions[CLEAR_ADDRESS_INFORMATION] = () => ({
  state: {
    id: '',
    name: '',
  },
  city: {
    id: '',
    name: '',
  },
  street: {
    name: '',
    number: '',
  },
  zipcode: '',
  neighborhood: '',
});
/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
