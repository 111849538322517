/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const Button = require('../../containers/Button/ButtonWithStore');
const ChallengePopUp = require('../../utils/ChallengePopUp');
const { getConfig } = require('../../utils/ChallengeConfigs');

/**
 * Button to open login popup
 */
class ButtonPopup extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault(event);

    getConfig(this.props.stepData, this.props.configurations).then((challengeConfig) => {
      ChallengePopUp.open(challengeConfig);
    });
  }

  render() {
    const {
      configurations,
      ...buttonProps
    } = this.props;

    return (
      <Button
        {...buttonProps}
        onClick={this.handleClick}
      />
    );
  }
}

/**
 * Prop Types
 */
ButtonPopup.propTypes = {
  configurations: PropTypes.object, // eslint-disable-line
  stepData: PropTypes.object, // eslint-disable-line
};

/**
 * Default Props
 */

ButtonPopup.defaultProps = {
  configurations: null,
  stepData: null,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  configurations: state.configurations,
  stepData: state.page.data,
});

/**
 * Expose Button
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonPopup;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(ButtonPopup);
}
