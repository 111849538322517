/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { BANK_SEARCH, RESET_BANK_SEARCH } = require('../actions/types');

const actions = {};

const initialState = {};

actions[BANK_SEARCH] = (state = initialState, action) => ({
  ...state,
  value: action.payload,
});

actions[RESET_BANK_SEARCH] = () => ({
  value: null,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
