const React = require('react');
const PropTypes = require('prop-types');

const GroupRow = props => (
  <div className="group-row">
    {props.children}
  </div>
);

GroupRow.defaultValues = {
  children: null,
};

GroupRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

module.exports = GroupRow;
