const React = require('react');
const PropTypes = require('prop-types');

const GroupSidebar = ({ children, id }) => (
  <div className="layout__col-sidebar" id={id || ""}>{children}</div>
);

GroupSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GroupSidebar;
