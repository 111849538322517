const React = require('react');
const PropTypes = require('prop-types');

const TextField = require('@andes/textfield');
const MaskTextField = require('@andes/masktextfield');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const inputValuesActions = require('../../spa/actions/inputValues');
const { CURRENT_INPUT_VALUES } = require('../../spa/actions/types');

const DefaultCardIcon = require('../icons/defaultCardIcon');

class BaseInput extends React.Component {
  constructor(props) {
    super(props);
    this.oldCaretIndex = 0;
    this.oldValueLength = 0;
    this.supportsSelection = props.type && props.type.match(/text|password|search|tel|url/g);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      mounted: false,
      step: props.step, //  If not the SPA will change the step prop before changing page and before calling componentWillUnmount
    };
    this.isCardForm = props.isCardForm;
    this.paymentMethodLogo = props.paymentMethodLogo;
  }

  /**
   * Set the default value after the component is mounted
   */
  componentDidMount() {
    const { updateCallback, savedValue } = this.props;

    if (this.props.id && this.props.shouldSaveValue && this.state.step && !savedValue) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, '');
    }

    if (this.field && this.field.value !== '') {
      this.handleOnChange({
        target: {
          value: this.field.value,
        },
      });
    } else if (updateCallback && savedValue) {
      updateCallback(savedValue);
    }
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // update store with new value, to keep store updated
    if (this.props.inputValuesActions[CURRENT_INPUT_VALUES]) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, nextProps.value);
    }
  }

  componentDidUpdate() {
    if (!this.field) {
      return;
    }

    const newIndex = Math.max(0, this.field.value.length - this.oldValueLength + this.oldCaretIndex);
    if (this.supportsSelection && newIndex !== this.oldCaretIndex) {
      // re arrange the caret position to the last
      this.field.selectionStart = newIndex;
      this.field.selectionEnd = newIndex;
    }
  }

  handleOnChange(event) {
    if (this.props.id && this.state.step) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, event.target.value);
    }

    if (this.field && this.supportsSelection) {
      this.oldCaretIndex = this.field.selectionStart;
      this.oldValueLength = this.field.value.length;
    }
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  render() {
    // If the element doesn't need to be shown do not render it
    if (!this.props.show) {
      return null;
    }

    const {
      deviceType,
      savedValue,
      updateCallback,
      invalid,
      error,
      children,
      value,
      defaultValue,
      shouldSaveValue,
      inputValuesActions,
      show,
      mask,
      validations,
      step,
      isCardForm,
      paymentMethodLogo,
      ...inputProps
    } = this.props;

    const [ErrorMessage] = error ?? [false];

    if (invalid) {
      inputProps.modifier = 'error';
    }

    if (ErrorMessage) {
      inputProps.message = ErrorMessage;
      inputProps.messageShow = true;
    } else if (inputProps.message) {
      // Shows message below the input if set
      inputProps.messageFixed = true;
      inputProps.messageShow = true;
    }

    if (defaultValue && value) {
      throw new Error(`
        Input should be either have a value (controlled)
        or a defaultValue (uncontrolled). Could not be both.
        https://fb.me/react-controlled-components
      `);
    }
    // Backwards compatibility
    inputProps.className += ' ui-input-text';

    if (defaultValue) {
      inputProps.defaultValue = defaultValue;
    } else {
      inputProps.value = value;
    }

    if (!this.state.mounted) {
      inputProps.className += ' offline';
    }

    let ComponentType = TextField;
    if (mask) {
      ComponentType = MaskTextField;
      inputProps.mask = mask;
    }

    if (validations && validations.some(({ error }) => error === 'required')) {
      inputProps.className += ' required';
    }

    const paymentMethodIcon = (
      <div className="cow-card-number-icon-logo icon-card--pm">
        <img src={paymentMethodLogo} alt="Payment Method Logo" />
      </div>
    );
    const renderShowIconDefault = isCardForm ? <DefaultCardIcon /> : null;
    const renderIconLogo = isCardForm && paymentMethodLogo ? paymentMethodIcon : renderShowIconDefault;

    return (
      <ComponentType
        {...inputProps}
        onChange={this.handleOnChange}
        setField={(field) => {
          this.field = field;
        }}
      >
        {children}
        {renderIconLogo}
      </ComponentType>
    );
  }
}

BaseInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  deviceType: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  placeholder: PropTypes.string,
  savedValue: PropTypes.string,
  messageShow: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  updateCallback: PropTypes.func,
  onChange: PropTypes.func,
  shouldSaveValue: PropTypes.bool,
  inputValuesActions: PropTypes.object, // eslint-disable-line
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.string,
  show: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.object),
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
};

BaseInput.defaultProps = {
  children: null,
  type: 'text',
  className: '',
  deviceType: 'desktop',
  label: '',
  message: '',
  placeholder: '',
  savedValue: '',
  messageShow: false,
  error: [],
  invalid: false,
  updateCallback: null,
  onChange: null,
  shouldSaveValue: true,
  value: undefined,
  defaultValue: undefined,
  inputValuesActions: {},
  show: true,
  id: '',
  multiline: false,
  disabled: false,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param {Object} state
 * @param {Object} props
 * @returns {Object}
 */
const mapStateToProps = (state, props) => {
  const { page, inputValues } = state;

  const disabled = inputValues.disabled && inputValues.disabled[`${page.flow.step}_${props.id}`];
  const visibility = inputValues.visibility && inputValues.visibility[`${page.flow.step}_${props.id}`];
  const messageFromInputValue = inputValues.message && inputValues.message[`${page.flow.step}_${props.id}`];

  return {
    step: page.flow.step,
    show: visibility !== undefined ? visibility : props.show,
    disabled: disabled || props.disabled,
    message: props.message || messageFromInputValue,
    savedValue: inputValues[`${page.flow.step}_${props.id}`],
  };
};

if (process.env.NODE_ENV === 'test') {
  module.exports = BaseInput;
  module.exports.mapStateToProps = mapStateToProps;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BaseInput); /* istanbul ignore next: cant test it with tests */
}
