import React from 'react';

const BoletoBancario = ({ width = 24, height = 24, ...props }: SVGProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path d="M1.25781 18.75V5.25H2.75781V18.75H1.25781Z" fill="currentColor" />
    <path d="M7.24781 18.75V5.25H8.74781V18.75H7.24781Z" fill="currentColor" />
    <path d="M3.46764 5.25V18.75H4.96764V5.25H3.46764Z" fill="currentColor" />
    <path d="M10.9696 18.75V5.25H12.4696V18.75H10.9696Z" fill="currentColor" />
    <path d="M13.2468 5.25V18.75H14.7468V5.25H13.2468Z" fill="currentColor" />
    <path d="M17.7438 18.75V5.25H19.2438V18.75H17.7438Z" fill="currentColor" />
    <path d="M20.016 5.25V18.75H21.516V5.25H20.016Z" fill="currentColor" />
    <path d="M22.2418 18.75V5.25H23.7418V18.75H22.2418Z" fill="currentColor" />
  </svg>
);

export default React.memo(BoletoBancario);
