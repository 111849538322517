const React = require('react');

const IconWrapper = require('../IconWrapper');

const UserDefault = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeOpacity="0.25"
        strokeWidth="1.3"
        transform="translate(1 1)"
      >
        <circle cx="13" cy="13" r="13" />
        <circle cx="13" cy="9.1" r="3.9" />
        <path d="M20.8 23.4a7.8 7.8 0 00-15.6 0" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = UserDefault;
