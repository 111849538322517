/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');
const { Dropdown } = require('@andes/dropdown');

const { DropdownItem } = Dropdown;

const { injectValidations } = require('../../utils/validator-provider');
const currencyUtil = require('../../utils/currency');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const creditsInstallmentActions = require('../../spa/actions/creditsInstallment');
const {
  VISIBILITY_INPUT_VALUES,
  SAVE_INPUT_VALUES,
  CURRENT_INPUT_VALUES,
  CREDITS_INSTALLMENT_UPDATE_COMBINATION,
} = require('../../spa/actions/types');
const translate = require('../../translation');
/**
 * SelectInstallmentsCredits
 * an _almost exact_ copy of the Select component, but with little variations in the handling of `installment`
 */
class SelectInstallmentsCreditsDynamic extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: props.savedValue || '',
      step: props.step,
      unselected: props.savedValue === '',
      selectKey: Math.random(),
    };

    this.getComponentValue = this.getComponentValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getOptionByValue = this.getOptionByValue.bind(this);
    this.translations = translate(i18n);
  }

  componentDidMount() {
    let defaultValue;
    const hasSelectedOption = this.props.options.some(option => option.selected);

    if (this.props.saveInputValue && !!this.props.savedValue) {
      defaultValue = this.props.savedValue;
    } else if (!this.props.showPlaceholder || hasSelectedOption) {
      defaultValue = this.getDefaultValue(this.props.options) || '';
    }

    this.updateValue(defaultValue);
    const selectedOption = defaultValue
      ? this.getOptionByValue(defaultValue)
      : null;
    if (selectedOption) {
      this.props.creditsInstallmentActions[CREDITS_INSTALLMENT_UPDATE_COMBINATION]({
        label: selectedOption.summaryLabel,
        value: selectedOption.id,
        total: selectedOption.total,
        detail: selectedOption.detail,
        contractUrl: selectedOption.contractUrl,
        tycUrl: selectedOption.tycUrl,
        totalPurchase: selectedOption.totalPurchase,
        interestFree: selectedOption.interestFree,
      });
    }
    this.dispatchVisibilityOnValue(defaultValue);

    // If there is a trigger function on mount call it
    if (this.props.triggerOnMount) {
      this.props.triggerOnMount(defaultValue);
    }

    if (this.props.id && this.state.step) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](
        `${this.state.step}_${this.props.id}`,
        defaultValue,
      );
    }
  }

  /**
   * This is execute when the step change and the content needs to be unmount
   */
  componentWillUnmount() {
    // Use the step from the state because the props change on route change (this is execute after the route change)
    if (this.props.id && this.props.shouldSaveValue && this.state.step) {
      this.props.inputValuesActions[SAVE_INPUT_VALUES](
        `${this.state.step}_${this.props.id}`,
        this.state.value,
      );
    }
  }

  componentDidUpdate(prevProps) {
    // check if options changed
    const currOptions = prevProps.options;
    const nextOptions = this.props.options;
    const optionsChanged = nextOptions.length !== currOptions.length
      || currOptions.filter((op, i) => op.label !== nextOptions[i].label).length > 0;

    if (optionsChanged) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](
        `${this.state.step}_credits_pricing_id`,
        '',
      );
      // if the current value was removed, reset it
      const value = nextOptions.filter(op => op.value === this.state.value).length > 0
        ? this.state.value
        : '';

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectKey: Math.random(), // force redraw
        value,
      });

      if (value) {
        const options = nextOptions.find(opt => opt.value === value);
        if (options) {
          // stripe unwanted values from intput i.e. : []
          const processedValues = value.replace(/\W/g, '');
          this.props.creditsInstallmentActions[CREDITS_INSTALLMENT_UPDATE_COMBINATION]({
            label: options.summaryLabel,
            value: processedValues,
            total: options.total,
            detail: options.detail,
            contractUrl: options.contractUrl,
            tycUrl: options.tycUrl,
            totalPurchase: this.updateTotalPurchase(options.totalPurchase),
            interestFree: options.interest_free,
            installmentRequest: this.props.optionsDynamic,
          });
        }
      }
    }
  }

  dispatchVisibilityOnValue(valueSelected) {
    const optionSelected = this.props.options.find(opt => opt.value === valueSelected);

    // If the select is dynamic, hide and show elements
    if (optionSelected && optionSelected.toShow && optionSelected.toHide) {
      const inputsVisibility = {};

      optionSelected.toShow.forEach(
        id => { inputsVisibility[`${this.state.step}_${id}`] = true; },
      );
      optionSelected.toHide.forEach(
        id => { inputsVisibility[`${this.state.step}_${id}`] = false; },
      );

      this.props.inputValuesActions[VISIBILITY_INPUT_VALUES](inputsVisibility);
    }
  }

  getDefaultValue(options) {
    const selectedOption = options.find(opt => opt.selected);
    if (selectedOption) {
      return selectedOption.value;
    }
    return '';
  }

  getComponentValue() {
    const value = (this.state.value || '').toString();
    // Remove the brakets
    return value.substring(1, value.length - 1);
  }

  getOptionByValue(value) {
    const selectedValue = this.props.options.filter(
      option => option.value === value,
    );
    return selectedValue.length > 0 ? selectedValue[0] : null;
  }

  onChange(e, value) {
    const optionSelected = this.props.options.find(opt => opt.value === value);

    // worst hack on history of hacks remove ASAP
    if (this.props.updateSummary && this.state.error.length > 0) {
      this.updateValidations({});
    }

    if (this.props.onChange) {
      this.props.onChange(e, value);
    }

    this.setState({ unselected: false });

    // Save the current option label to be used on visible elements like Extra-Data component
    if (this.props.id && this.state.step) {
      /**
       * Temporary fix for error on express step
       * caused by the value saved by the select component
       */
      let inputValue = optionSelected ? optionSelected.label : value;
      if (this.props.updateSummary) {
        inputValue = value;
      }
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](
        `${this.state.step}_${this.props.id}`,
        inputValue,
      );
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](
        `${this.state.step}_credits_pricing_id`,
        optionSelected.creditsPricingId,
      );
    }

    if (this.props.updateSummary) {
      this.props.creditsInstallmentActions[CREDITS_INSTALLMENT_UPDATE_COMBINATION]({
        label: optionSelected.summaryLabel,
        value: optionSelected.id,
        total: optionSelected.total,
        detail: optionSelected.detail,
        contractUrl: optionSelected.contractUrl,
        tycUrl: optionSelected.tycUrl,
        totalPurchase: this.updateTotalPurchase(optionSelected.totalPurchase),
        interestFree: optionSelected.interestFree,
        installmentRequest: this.props.optionsDynamic,
      });
    }
    this.dispatchVisibilityOnValue(value);

    this.updateValue(value);
  }

  getFinancialInformation(value) {
    const option = this.props.options.find(it => it.value === value);
    const result = {
      financialCostLabel: '',
      financialCostValue: '',
      optionMessage: '',
    };
    /* istanbul ignore next */
    if (option) {
      if (option.financialCostLabel) {
        result.financialCostLabel = option.financialCostLabel;
        result.financialCostValue = option.financialCostValue;
      } else if (option.message) {
        result.optionMessage = option.message;
      }
    }
    return result;
  }

  updateValue(value) {
    this.setState({
      value,
    });
  }

  updateTotalPurchase(totalPurchase) {
    if (this.props.optionsDynamic) {
      const totalPurchaseInstallment = currencyUtil.getFloatValue(totalPurchase, this.props.currency);
      const firsValueTyped = currencyUtil.getFloatValue(this.props.firstValue, this.props.currency);
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_split_total_amount_second_method`, totalPurchaseInstallment);
      return currencyUtil.parseAmount((totalPurchaseInstallment + firsValueTyped), this.props.currency);
    }
    return totalPurchase;
  }

  render() {
    const {
      onChange,
      invalid,
      errors,
      updateSummary,
      shouldSaveValue,
      creditsInstallmentActions, // eslint-disable-line
      inputValuesActions, // eslint-disable-line
      identificationCardActions, // eslint-disable-line
      savedValue,
      saveInputValue,
      triggerOnMount,
      validateCallback,
      validations,
      showErrorMessage,
      showPlaceholder,
      error,
      errorMessage,
      options,
      i18n,
      placeholder,
      deviceType,
      ...selectProps
    } = this.props;

    const {
      financialCostLabel,
      financialCostValue,
      optionMessage,
    } = this.getFinancialInformation(this.state.value);

    const {NO_INTEREST, CONTEXT, CHOOSE_OPTION} = this.translations;

    let className = this.props.className || '';
    if (options.length > 0 && options[0].value === '') {
      className += ' placeholder';

      if (this.state.unselected) {
        className += ' unselected ';
      }
    }

    if (optionMessage) {
      selectProps.message = optionMessage;
      className += ' fixedMessage';
    }

    if (this.state.invalid && this.state.error.length > 0 && showErrorMessage) {
      const [errorMessageFromState] = this.state.error;
      selectProps.message = errorMessageFromState;
      selectProps.valid = false;
    }

    const optionsList = [...options];

    return (
      <div className="select-installments">
        <Dropdown
          {...selectProps}
          helper={selectProps.message}
          onChange={this.onChange}
          value={this.state.value}
          className={className}
          disabled={options.length <= 0 || !this.props.firstValue}
          type="form"
          placeholder={placeholder || i18n.pgettext(CONTEXT, CHOOSE_OPTION)}
          key={this.state.selectKey}
          menuMaxHeight={220}
        >
          {optionsList.map(({ value, label, interestFree }, index) => (index === 0 && !value ? (
            <DropdownItem
              key="dd_default"
              value=""
              title={label}
              selected
              disabled
            />
          ) : (
            <DropdownItem
              name={value}
              key={`dd_${value}`}
              value={value}
              title={label}
              description={interestFree && <span className="installment__interest">{NO_INTEREST(true)}</span>}
            />
          )))}
        </Dropdown>
        {this.state.value && (
          <input
            type="hidden"
            value={this.state.value}
            name={selectProps.name}
          />
        )}
        {financialCostValue && financialCostLabel && (
          <div className="select-additional-info">
            <div className="financial-cost-info">
              {financialCostLabel}: {financialCostValue}
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * Prop Types
 */
SelectInstallmentsCreditsDynamic.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  updateSummary: PropTypes.bool,
  shouldSaveValue: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      total: PropTypes.string,
      selected: PropTypes.bool,
      toShow: PropTypes.arrayOf(PropTypes.string),
      toHide: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  inputValuesActions: PropTypes.shape({
    current_input_values: PropTypes.func,
    visibility_input_values: PropTypes.func,
  }),
  showErrorMessage: PropTypes.bool,
  triggerOnMount: PropTypes.func,
  creditsInstallmentActions: PropTypes.shape({
    credits_installment_update: PropTypes.func,
    credits_installment_clean: PropTypes.func,
  }),
  saveInputValue: PropTypes.bool,
  savedValue: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    pgettext: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  stateoptions: PropTypes.string,
};

/**
 * Default Props
 */

SelectInstallmentsCreditsDynamic.defaultProps = {
  id: `sel-${Math.random()
    .toString(36)
    .substr(2, 9)}`,
  name: '',
  className: '',
  label: '',
  total: '',
  hint: '',
  error: [],
  errorMessage: '',
  disabled: false,
  options: [],
  showErrorMessage: true,
  saveInputValue: true,
  showPlaceholder: false,
  updateSummary: false,
  shouldSaveValue: true,
  savedValue: '',
  step: '',
  creditsInstallmentActions: {
    credits_installment_update: null,
    credits_installment_clean: null,
  },
  inputValuesActions: {
    current_input_values: null,
    visibility_input_values: null,
  },
  i18n: {
    gettext: t => t,
    pgettext: (c, t) => t,
  },
  stateoptions: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditsInstallmentActions: bindActionCreators(
    creditsInstallmentActions,
    dispatch,
  ),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue:
    state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
  options: state.installment.installmentRequest ? state.installment.installmentRequest : ownProps.options,
  optionsDynamic: state.installment.installmentRequest,
  currency: state.configurations.currency,
  firstValue: state.inputValues.current[`${state.page.flow.step}_split_amount_first_method`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectInstallmentsCreditsDynamic;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectI18n(injectValidations(SelectInstallmentsCreditsDynamic)));
}
