const React = require('react');

const useInputs = (paymentOptionsToCombine = [], ref) => (
  <div ref={ref}>
    {
      paymentOptionsToCombine.map(({ id }, index) => (
        <input
            key={id}
            type="hidden"
            name={`[values][option-${index}]`}
            value={id}
          />
        ))
    }
  </div>
);


module.exports = useInputs;
