const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconQR = () => (
  <IconWrapper>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 5V3.5H5V5H3.5Z" fill="#009EE3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.5H8V8H0.5V0.5ZM2 2V6.5H6.5V2H2Z" fill="#009EE3"/>
      <path d="M3.5 18.49V17.02H5V18.49H3.5Z" fill="#009EE3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 14H0.5V21.5H8V14ZM2 20V15.5H6.5V20H2Z" fill="#009EE3"/>
      <path d="M17 5V3.5H18.5V5H17Z" fill="#009EE3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5 0.5H14V8H21.5V0.5ZM15.5 6.5V2H20V6.5H15.5Z" fill="#009EE3"/>
      <path d="M9.5 11.02V15.5H11V14H12.5V12.5H11V11.02H9.5Z" fill="#009EE3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 9.5V8H11V6.51H12.5V9.5H9.5ZM12.5 9.5H15.5V11H12.5V9.5Z" fill="#009EE3"/>
      <path d="M11 5V6.51H9.5V0.5H12.5V2H11V3.5H12.5V5H11Z" fill="#009EE3"/>
      <path d="M15.5 15.5H14V12.5H15.5V15.5Z" fill="#009EE3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 15.5H15.5V20H14V21.5H15.5V20H20V21.5H21.5V20H20V18.5H21.5V17.02H20V15.5ZM17 17V18.5H18.5V17H17Z" fill="#009EE3"/>
      <path d="M20 15.5V12.5H21.5V15.5H20Z" fill="#009EE3"/>
      <path d="M14 15.5V18.5H12.5V21.5H9.5V20H11V18.5H9.5V17.02H11V18.49H12.5V15.5H14Z" fill="#009EE3"/>
      <path d="M17 12.5V13.97H18.5V12.5H17Z" fill="#009EE3"/>
      <path d="M0.5 11.02V12.49H2V11.02H0.5Z" fill="#009EE3"/>
      <path d="M5 11H6.5V9.5H8V12.5H3.5V11H2V9.5H5V11Z" fill="#009EE3"/>
      <path d="M21.5 11H20V12.49H18.5V11H17V9.5H21.5V11Z" fill="#009EE3"/>
    </svg>
  </IconWrapper>
);

module.exports = IconQR;
