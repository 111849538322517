/* global window */

/**
 * Module dependencies
 */
const merge = require('deepmerge');
const uuidv4 = require('uuid/v4');
const { createAction } = require('redux-actions');
const { CancelToken, isCancel } = require('nordic/restclient/cancel');

const StepUtils = require('../../utils/Step');
const apiService = require('../../service/api');
const melidataTracking = require('../../utils/melidataTracking');
const { isZeroDollarError } = require('../../utils/HiddenComponents');
const { generateLoginURL } = require('../../utils/LoginPopUp');
const { isIframe } = require('../../utils/Dom');

const {
  PAGE_LOAD,
  STEP_NEXT,
  STEP_RETRY,
  STEP_LOGOUT,
  CREATE_FLOW,
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_CANCELLED,
  REQUEST_CANCEL_TOKEN,
  LOADER_STOP_ANIMATION,
  LOADER_PAY_BUTTON,
  STEP_NEXT_ZERO_DOLLAR,
  REQUEST_STARTED_WITHOUT_RENDER,
  REQUEST_FINISHED_WITHOUT_RENDER,
  CONFIGURATIONS_UPDATE_CARD_FORM_BRICKS,
  MANAGE_CARD_FORM_ERROR,
} = require('./types');

// Request Actions
const pageLoad = createAction(PAGE_LOAD);
const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);
const requestCancelled = createAction(REQUEST_CANCELLED);
const requestCancelToken = createAction(REQUEST_CANCEL_TOKEN);
const loaderStopAnimation = createAction(LOADER_STOP_ANIMATION);
const loaderPayButton = createAction(LOADER_PAY_BUTTON);
const requestStartedWithoutRender = createAction(REQUEST_STARTED_WITHOUT_RENDER);
const requestFinishedWithoutRender = createAction(REQUEST_FINISHED_WITHOUT_RENDER);
const manageCardFormError = createAction(MANAGE_CARD_FORM_ERROR);

// Skeleton
const { VISIBILITY_INPUT_VALUES, CONFIGURATIONS } = require('./types');

// Request Actions
const inputInvisibility = createAction(VISIBILITY_INPUT_VALUES);
const configurationAction = createAction(CONFIGURATIONS);
const configurationUpdateCardFormBricks = createAction(CONFIGURATIONS_UPDATE_CARD_FORM_BRICKS);

// Dependency Methods
const { waitMs } = require('../../utils/animations');
const isRetryAllowed = require('../../utils/isRetryAllowed');
const { isChallenge } = require('../../utils/ChallengeConfigs');
const { trackMelidata } = require('../../utils/Tracker');
const { SITE_ID } = require('../../../constants/commons');

const {
  URL,
  HTTP_STATUS,
  CUSTOM_EVENTS,
  CURRENT_STEP: { LOGIN, REDIRECT, EXCEPTION, CHALLENGE, CHALLENGE_REAUTH },
  CHALLENGES,
} = require('../../../constants/app');


/**
 * Send form data to flows api to create the next step
 * @param checkout
 * @param flowId
 * @param defaultData
 * @param type
 * @param urlParams
 * @param history
 * @return {function(*)}
 */
const createFlow = ({ id: flowId, type }, urlParams, checkout, history) => (dispatch, getState) => {
  const requestId = uuidv4();
  const source = CancelToken.source();
  const currentState = getState();

  dispatch(requestStarted({ requestId }));

  return apiService
    .createFlowFromAction(checkout, urlParams)
    .then((response) => {
      const responseData = response.preloadedServerData;
      dispatch(inputInvisibility(responseData.inputVisibility));
      dispatch(configurationAction(response.initialStore.configurations));

      dispatch(pageLoad({ data: responseData }));
      // Keep loading alive until next page is render
      dispatch(requestFinished({ requestId, cancelToken: source, leaveLoading: true }));

      if (
        ['login', 'login_irreversible'].includes(responseData.currentStep) &&
        currentState.configurations.checkoutType === 'redirect'
      ) {
        const pathname = window.location.pathname.replace(/\/redirect.*/g, responseData.redirectTo);
        const originalUrl = `${window.location.protocol}//${window.location.host}${pathname}`;
        // If the step to render is the login and the checkout type is redirect redirect to MercadoPago login
        const redirectUrl = generateLoginURL(currentState.configurations, originalUrl);
        return (window.location.href = redirectUrl);
      }

      // replace the state with the new url after the flow id was created
      history.replace(responseData.redirectTo, {});
    })
    .catch((err) => {
      if (isCancel(err)) {
        dispatch(requestCancelled({ requestId }));
      } else {
        // Finish the request and keep loading
        dispatch(requestFinished({ requestId, cancelToken: source, leaveLoading: true }));
        // Stop the animation
        dispatch(loaderStopAnimation({}));

        waitMs().then(() => {
          // Go to exception/retry step
          const showRetry = isRetryAllowed(err);
          const flowIdPart = showRetry ? `/${flowId}` : '';
          const url = `/${type}${flowIdPart}/${EXCEPTION}?${urlParams}`;
          history.push(url);
          dispatch(
            pageLoad({
              data: {
                ...currentState.page.data,
                currentStep: EXCEPTION,
                showRetry,
              },
            }),
          );
        });
      }
    });
};

/**
 * Try to get last step and render it
 * @param flowId
 * @param type
 * @param urlParams
 * @param history
 * @return {function(*)}
 */
const stepRetry = (flowId, type, urlParams, history) => async (dispatch, getState) => {
  const requestId = uuidv4();
  const currentState = getState();
  const { version } = currentState.configurations;

  dispatch(requestStarted({ requestId }));

  return apiService
    .retryFlow(version, flowId, type, urlParams)
    .then((responseData) => {
      // update the state with the next step data
      dispatch(pageLoad({ data: responseData }));
      dispatch(requestFinished({ requestId, leaveLoading: true }));
      history.replace(responseData.redirectTo);
    })
    .catch((err) => {
      if (isCancel(err)) {
        dispatch(requestCancelled({ requestId }));
      } else {
        // Finish the request and keep loading
        dispatch(requestFinished({ requestId, leaveLoading: true }));
        // Stop the animation
        dispatch(loaderStopAnimation({}));

        waitMs().then(() => {
          // Go to exception/retry step
          const showRetry = isRetryAllowed(err);
          history.replace(`/${type}${showRetry ? `/${flowId}` : ''}/${EXCEPTION}?${urlParams}`);
          dispatch(
            pageLoad({
              data: {
                ...currentState.page.data,
                currentStep: EXCEPTION,
                showRetry,
              },
            }),
          );
        });
      }
    });
};

const handleNavigationError = (err, stepData) => {
  const { currentStep, dispatch, requestId, source, currentState, flowId, type, urlParams, history } = stepData;
  const { siteId } = currentState?.configurations?.platform;
  // @TODO This retry condition should be reviewed ASAP too. This fix is related to PXGIN-1145 solution.
  const shouldRetry = siteId === SITE_ID.MLC &&
    err?.response?.status === HTTP_STATUS.BAD_GATEWAY &&
    [URL.REVIEW, URL.EXPRESS].includes(currentStep);

  if (isCancel(err)) {
    dispatch(requestCancelled({ requestId }));
  } else if (shouldRetry) {
    const timeout = 10000;

    setTimeout(() => {
      // Finish the request and keep loading
      dispatch(requestFinished({ requestId, cancelToken: source, leaveLoading: true }));
      // Stop the animation
      dispatch(loaderStopAnimation({}));

      dispatch(stepRetry(flowId, type, urlParams, history));
    }, timeout);
  } else {
    // Finish the request and keep loading
    dispatch(requestFinished({ requestId, cancelToken: source, leaveLoading: true }));
    // Stop the animation
    dispatch(loaderStopAnimation({}));

    waitMs().then(() => {
      // Go to exception/retry step
      const showRetry = isRetryAllowed(err);
      const flowIdPart = showRetry ? `/${flowId}` : '';
      const url = `/${type}${flowIdPart}/${EXCEPTION}?${urlParams}`;
      history.push(url);
      dispatch(
        pageLoad({
          data: {
            ...currentState.page.data,
            currentStep: EXCEPTION,
            showRetry,
          },
        }),
      );
    });
  }
}

/**
 * Send data to flows api to create the next step
 *
 * @param {*} data // Could be a HTML form or an object with the data to send
 * @param {*} flowId
 * @param {*} defaultData
 * @param {*} type
 * @param {*} urlParams
 * @param {*} history
 *
 * @return {function(*)}
 */
const stepNext = (data, flowId, defaultData = {}, type, urlParams, history) => async (dispatch, getState) => {
  const requestId = uuidv4();
  const source = CancelToken.source();
  const currentState = getState();
  const currentStep = currentState.page.flow.step;
  const { idempotencyKey } = currentState.page.data;
  const { version } = currentState.configurations;
  const uiComponents = currentState?.page?.data?.components;
  const challengeName = Array.isArray(uiComponents) && uiComponents.find((group) => group.type === CHALLENGES.CHALLENGE_COMPONENT_TYPE)?.challenge;
  const isReauthChallenge = currentStep === CHALLENGE && challengeName === CHALLENGE_REAUTH;

  dispatch(requestStarted({ requestId }));

  // New method to extract the data depending of the Process version (v1|v2|v3)
  const values = StepUtils.extractData(data, currentState);
  // Add default data to the values
  const putData = merge.all([{}, defaultData, values]);

  dispatch(requestCancelToken(source));

  return apiService
    .updateFlow(
      version,
      flowId,
      putData,
      {
        cancelToken: source.token,
        idempotencyKey,
      },
      currentStep,
      urlParams, // pass url params to get access token query param to persis the user session
      isReauthChallenge,
    )
    .then((responseData) => {
      let redirectTo = null;
      const dispatchRequestOptions = {
        requestId,
        cancelToken: source,
        leaveLoading: true
      }
      const isRedirectStep = responseData.currentStep === REDIRECT;
      const isTypeRedirect = type === REDIRECT;
      const isRedirectChallenge = isTypeRedirect && !isIframe() && isChallenge(responseData);
      // If the return step from the Update is the same one. It means that has a Backend error and must solved it from FE
      // Ex.: Bad Input field (Identification number, etc...)
      // update the state with the next step data
      if (currentStep === responseData.currentStep) {
        // Special case for when the user needs to complete two challenge continuously
        if (isRedirectChallenge) {
          redirectTo = `/redirect/${responseData.flowId}/tracking/redirect/?${urlParams}`;
        } else {
          dispatchRequestOptions.cancelToken = null;
          dispatchRequestOptions.leaveLoading = null;
          redirectTo = null;
        }
      } else if (isRedirectStep || isRedirectChallenge) {
        // move forwards to tracking redirect step
        // this block will match with any redirect step or challenge in redirect (ifpe, login
        redirectTo = `/redirect/${responseData.flowId}/tracking/redirect/?${urlParams}`;
      } else {
        redirectTo = responseData.redirectTo;
      }

      dispatch(pageLoad({ data: responseData }));
      dispatch(requestFinished(dispatchRequestOptions));
      dispatch(configurationUpdateCardFormBricks(responseData.internalBricksData));

      // Stop Pay Button Animation
      dispatch(loaderPayButton({ isLoading: false }));
      if (redirectTo) {
        history.push(redirectTo);
      }
    })
    .catch((error) => {
      const stepData = { currentStep, dispatch, requestId, source, currentState, flowId, type, urlParams, history };
      handleNavigationError(error, stepData);
    });
};

/**
 * Send form data to flows api to create the next step
 * TODO we should not do this, but the re-render of optimus and SPA works terrible, we must evaluate what is happening with the navigation between pages.
 */
const stepNextZeroDollar = (form, flowId, defaultData = {}, type, urlParams, history) => async (dispatch, getState) => {
  const requestId = uuidv4();
  const source = CancelToken.source();
  const currentState = getState();
  const currentStep = currentState.page.flow.step;
  const { version } = currentState.configurations;
  const uiComponents = currentState?.page?.data?.components;
  const challengeName = Array.isArray(uiComponents) &&
    uiComponents.find((group) => group.type === CHALLENGES.CHALLENGE_COMPONENT_TYPE)?.challenge;
  const isReauthChallenge = currentStep === CHALLENGE && challengeName === CHALLENGE_REAUTH;

  dispatch(requestStartedWithoutRender());

  const formValues = StepUtils.getFormValues(form);

  const data = merge.all([{}, defaultData, formValues]);

  dispatch(requestCancelToken(source));

  return apiService
    .updateFlow(
      version,
      flowId,
      data,
      {
        cancelToken: source.token,
      },
      currentStep,
      urlParams, // pass url params to get access token query param to persis the user session
      isReauthChallenge,
    )
    .then((responseData) => {
      let redirectTo = null;
      redirectTo = responseData.redirectTo;
      if (currentStep === responseData.currentStep) {
        // TODO REMOVE THIS WITH NEW ARCHITECTURE, THIS IS A WORKAROUND BECAUSE IF WE UPDATE THE PAGE, EVERYTHING WILL RE RENDER AND WE WILL LOSE THE VALUES OF SECURE FIELDS. AVOID USING WINDOW EVENTS INSTEAD USE REDUX ACTIONS
        const zeroDollarEvent = new CustomEvent(CUSTOM_EVENTS.ZERO_DOLLAR_VALIDATION, { detail: { currentStep: responseData.currentStep, zeroDollarValue: isZeroDollarError(responseData.hidden_components) } });
        window.dispatchEvent(zeroDollarEvent);
        window.idempotencyKey = responseData.idempotencyKey;
        // finish todo
        dispatch(requestFinishedWithoutRender({}));
        // This a workaround to track melidata without moving to the page.
        const analyticsObj = {
          browser: currentState.configurations.browser,
          siteId: currentState.configurations.platform.siteId,
          checkoutType: currentState.configurations.checkoutType,
          checkoutPurpose: currentState.configurations.checkoutPurpose,
          checkoutProduct: currentState.configurations.checkoutProduct,
        }
        trackMelidata(melidataTracking.getData(responseData.hidden_components), analyticsObj, true);
        return;
      }
      redirectTo && history.push(redirectTo);
    })
    .catch((error) => {
      const stepData = { currentStep, dispatch, requestId, source, currentState, flowId, type, urlParams, history };
      handleNavigationError(error, stepData);
    });
};

const stepLogout = ({ data, flowId, defaultData = {}, type, urlParams, history, logoutUrl }) => async (dispatch, getState) => {
  const requestId = uuidv4();
  const source = CancelToken.source();
  const currentState = getState();
  const currentStep = currentState?.page?.flow?.step;
  const { idempotencyKey } = currentState?.page?.data;
  const { version } = currentState?.configurations;
  const isReauthChallenge = false;

  dispatch(requestStarted({ requestId }));

  const values = StepUtils.extractData(data, currentState);
  const putData = merge.all([{}, defaultData, values]);

  dispatch(requestCancelToken(source));

  return apiService
    .updateFlow(
      version,
      flowId,
      putData,
      {
        cancelToken: source?.token,
        idempotencyKey,
        logout: true
      },
      currentStep,
      urlParams, // pass url params to get access token query param to persis the user session
      isReauthChallenge,
    )
    .then((responseData) => {
      let redirectTo = null;
      const dispatchRequestOptions = {
        requestId,
        cancelToken: source,
        leaveLoading: true
      }

      redirectTo = responseData.redirectTo;

      dispatch(pageLoad({ data: responseData }));
      dispatch(requestFinished(dispatchRequestOptions));
      if (redirectTo) {
        const { location } = window;
        const pathname = location?.pathname?.replace(/\/redirect.*/g, responseData.redirectTo);
        const originalUrl = `${location?.protocol}//${location.host}${pathname}`;
        const callbackUrl = encodeURIComponent(originalUrl);
        /**
         * This is a temporary fix for the change user scenario where flows inmediatly after the PUT
         * send us to the login step.
         * But in this case we are not yet logged out actually.
         * So if we reach the login url from mp will automatically send back to the callback url (since the user still logged).
         * To prevent this we need to create 2 urls
         * logout url + callback (this callback is the LOGIN url)
         * login url + callback (this is actually the checkout)
         * logoutUrl?go=loginUrl?go=checkoutUrl
         * @TODO fragile and overcomplicated ASAP change this logic from backend/frontend
         * this cannot be fixed only from frontend.
         */
        if (responseData.currentStep === LOGIN) {
          const changeUserUrl = generateLoginURL(currentState.configurations, originalUrl);
          const loginUrl = encodeURIComponent(changeUserUrl);
          window.location.href = `${logoutUrl}?go=${loginUrl}`;
        } else {
          window.location.href = `${logoutUrl}?go=${callbackUrl}`;
        }
      }
      return null;
    })
    .catch((error) => {
      const stepData = { currentStep, dispatch, requestId, source, currentState, flowId, type, urlParams, history }
      handleNavigationError(error, stepData);
    });
};

/**
 * Export functions
 */
module.exports = {
  [CREATE_FLOW]: createFlow,
  [STEP_NEXT]: stepNext,
  [STEP_NEXT_ZERO_DOLLAR]: stepNextZeroDollar,
  [STEP_RETRY]: stepRetry,
  [STEP_LOGOUT]: stepLogout
};
