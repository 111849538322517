const {
  ERROR_SPA: { SCRIPT_ERROR },
} = require('../../constants/app');

const UNTRACK_ERRORS = [SCRIPT_ERROR];
const SPA_NR_ACTIONS = {
  RENDER_TIME: 'renderTime',
};

const trackNewRelicError = (payload) => {
  if (!payload || typeof payload !== 'object') {
    return;
  }

  if (!window || !window.newrelic) {
    return;
  }

  window.newrelic.noticeError(payload?.stack?.group_error, {
    ...payload.stack,
    message: payload.message,
  });
};

const shouldIgnoreError = (error) => UNTRACK_ERRORS.some((errorType) => errorType === error.message);

module.exports = {
  trackNewRelicError,
  shouldIgnoreError,
  SPA_NR_ACTIONS,
};
