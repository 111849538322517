const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { Message } = require('@andes/message');
const { injectI18n } = require('nordic/i18n');

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const Mask = require('../../utils/Mask');
const inputValuesActions = require('../../spa/actions/inputValues');
const creditCardActions = require('../../spa/actions/creditCard');
const PaymentMethodUtils = require('../../utils/PaymentMethodUtils');
const { CREDIT_CARD_GUESSING, CREDIT_CARD_PC_NUMBER } = require('../../spa/actions/types');
const ApiService = require('../../service/api');
const {
  PLACEHOLDER: { CARD_NUMBER },
} = require('../../../constants/commons');
const { CURRENT_STEP, PAYMENT_METHOD } = require('../../../constants/app');
const translate = require('../../translation');

class CardNumber extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    // Default State
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: '',
      maskedValue: '',
      paymentMethod: null,
      maxLength: 20,
      step: props.step,
      paymentMethodLogo: null,
      label: '',
      deferredCapture: null,
    };
    this.translations = translate(i18n);

    this.masksAvailable = {
      13: new Mask('____ ____ ____ _'), // 4-4-4-1 -> Tarjeta Shopping without cvv (bin 279951)
      14: new Mask('____ ______ ____'), // 4-6-4 -> Diners
      15: new Mask('____ ______ _____'), // 4-6-5 -> Amex
      16: new Mask('____ ____ ____ ____'), // 4-4-4-4 -> Visa, Master, Etc
      18: new Mask('__________ _____ ___'), // 10-5-3 -> Maestro 18
      19: new Mask('_________ __________'), // 9-10 -> Maestro 19
      // default: Apply at start when guessing is not done
      // The majority of the mask starts with 4 and this prevent copy / paste without format
      // Lets add 20 digits but when the bin is solve the value is truncated
      default: new Mask('____ ____ ____ ____ ____ ____'), // 4-4-4-4-4-4
    };

    this.onChange = this.onChange.bind(this);
    this.getPaymentMethod = this.getPaymentMethod.bind(this);
    this.extraValidations = this.extraValidations.bind(this);
    this.getMask = this.getMask.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.showCaptureNotSupportedMessage = this.showCaptureNotSupportedMessage.bind(this);
    this.validateSplitPayment = this.validateSplitPayment.bind(this);
    // Set the mask to be apply
    this.mask = this.getMask();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we receive a inputValueActions with a empty value, we clean the field
    // this is necessary because the field value (with mask) is controled by local state
    if (nextProps.creditCardValue === '') {
      this.updateValue('');
    }
  }

  /**
   * Set the masks depending on the length of the Card
   * @param length
   * @returns {*}
   */
  getMask(length) {
    return length ? this.masksAvailable[length] || this.masksAvailable.default : this.masksAvailable.default;
  }

  onChange(event) {
    this.updateValue(event.target.value);
  }

  /**
   * Override onChange method
   * @param event
   */
  updateValue(rawValue) {
    // Clean value
    const previousValue = this.state.value;
    const cardNumber = rawValue.replace(/[^0-9]/g, '');
    const currentBin = cardNumber.slice(0, 8);
    const toUpdate = {
      value: cardNumber,
      paymentMethod: this.state.paymentMethod,
    };
    // Prevent copy / paste
    const binChange = cardNumber.length >= 8 && previousValue.slice(0, 8) !== currentBin;

    // If the number has the bin length and wasn't already guess it, run the guessing
    // If the number change and the payment method was already guessing,
    // it mean a copy / paste just happen and guessing is required
    if ((cardNumber.length >= 8 && !this.state.paymentMethod) || (binChange && this.state.paymentMethod)) {
      const paymentMethod = this.getPaymentMethod(cardNumber);

      this.props.creditCardActions[CREDIT_CARD_GUESSING]({ bin: currentBin, paymentMethod });
      toUpdate.paymentMethod = paymentMethod;
      toUpdate.error = !paymentMethod ? [this.props.errors[this.getInvalidFieldError(cardNumber)]] : [];
      toUpdate.invalid = toUpdate.error.length > 0;
      if (paymentMethod) {
        this.getPaymentMethodLogo(paymentMethod.id);
        toUpdate.deferredCapture = paymentMethod.deferredCapture === PAYMENT_METHOD.DEFERRED_CAPTURE.SUPPORTED;
      }
    } else if (cardNumber.length < 8) {
      // Blank the paymentMethod
      toUpdate.paymentMethod = null;
      toUpdate.paymentMethodLogo = null;
      toUpdate.deferredCapture = null;

      // Remove guessing data
      this.props.creditCardActions[CREDIT_CARD_GUESSING]({});
    }

    // Save the new mask to be apply
    this.mask =
      toUpdate.paymentMethod !== null
        ? this.getMask(toUpdate.paymentMethod.settings.card_number.length)
        : this.getMask();

    // Apply the mask to the visible value
    toUpdate.maskedValue = this.mask.apply(cardNumber);
    // Set the maxLength based on the mask length to be apply
    toUpdate.maxLength = this.mask.getLength();

    // Dispatch action for updating the PaymentCard component
    this.props.creditCardActions[CREDIT_CARD_PC_NUMBER](cardNumber);

    this.setState((old) => ({ ...old, ...toUpdate }));
  }

  /**
   * Get Payment method logo and update the state
   */
  async getPaymentMethodLogo(paymentMethodId) {
    if (paymentMethodId) {
      try {
        const logo = await ApiService.getLogoUrlById(paymentMethodId);
        this.setState({ paymentMethodLogo: logo }); // Set the paymentMethodLogo state
      } catch (err) {
        this.setState({ paymentMethodLogo: null }); // Handle any errors and reset the state
      }
    }
  }

  /**
   * Add extra validations beside the ones by default
   * @returns {*}
   */
  extraValidations() {
    const value = this.getComponentValue();
    // Validate credit card number with luhn algorithm (Only if the validation is not none)
    if (
      this.state.paymentMethod &&
      this.state.paymentMethod.settings.card_number.validation !== 'none' &&
      !this.validateLuhn(value)
    ) {
      return this.getInvalidFieldError(value);
    }
    // Validate if we guess the payment method
    if (!this.state.paymentMethod) {
      return this.getInvalidFieldError(value);
    }
    // Validate the length of the guessed payment_method
    if (value.length !== this.state.paymentMethod.settings.card_number.length) {
      return 'E301'; // This code is already on the response
    }
    if (!this.state.deferredCapture && this.validateSplitPayment()) {
      return this.translations.ENTER_A_DIFFERENT_CARD;
    }
    return '';
  }

  /**
   * Guess the payment method using the bin number
   * @param cardNumber
   * @returns {*}
   */
  getPaymentMethod(cardNumber) {
    const { data, maxInstallments } = this.props;
    const bin = cardNumber.slice(0, 8);
    let paymentMethod = null;

    const pmUtil = new PaymentMethodUtils(data, {
      max_installments: maxInstallments,
    });

    const pm = pmUtil.getPaymentMethod(bin);

    if (paymentMethod === null && pm != null) {
      paymentMethod = {
        id: pm.id,
        settings: pm.settings[0],
        paymentType: pm.payment_type_id,
        deferredCapture: pm.deferred_capture,
      };
    }
    return paymentMethod;
  }

  /**
   * Validate luhn Alghoritm
   * The tests are ignore here, this method was copy from the Javascript SDK
   * TODO: Maybe move to another library and tests this specific method
   * @param cardNumber
   * @returns {boolean}
   */

  /* istanbul ignore next */
  validateLuhn(cardNumber = '') {
    let digit;
    let odd = true;
    let sum = 0;
    let i = 0;
    const digits = cardNumber.split('').reverse();
    const digitLength = digits.length;

    for (; i < digitLength; i += 1) {
      digit = digits[i];
      digit = parseInt(digit, 10);

      if ((odd = !odd)) {// eslint-disable-line
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }

      sum += digit;
    }

    return sum % 10 === 0;
  }

  /**
   * Get the invalid field error related to the card number's status
   * This functionality allows to solve separately each invalid_field error
   * and return the existing case.
   * @param {String} value - card number to check
   */
  getInvalidFieldError(value = '') {
    const invalidField = 'invalid_field'; // This code is already on the response
    const invalidFieldWithValidLuhn = 'invalid_field_with_valid_luhn'; // this code could come on the response

    // When luhn is valid, return it if exists
    if (
      value &&
      value.length &&
      this.props.errors &&
      this.validateLuhn(value) &&
      this.props.errors[invalidFieldWithValidLuhn]
    ) {
      return invalidFieldWithValidLuhn;
    }
    return invalidField;
  }

  validateSplitPayment() {
    return (
      this.props.step === CURRENT_STEP.FIRST_SPLIT_PAYMENT || this.props.step === CURRENT_STEP.SECOND_SPLIT_PAYMENT
    );
  }

  showCaptureNotSupportedMessage() {
    if (this.validateSplitPayment() && !!this.state.paymentMethod && !this.state.deferredCapture) {
      return (
        <Message color="red" hierarchy="quiet">
          {this.translations.DEFERRED_CAPTURE_UNSUPPORTED}
        </Message>
      );
    }
    return null;
  }

  render() {
    const className = 'input-card-number';

    const {
      error,
      invalid,
      errors,
      validations,
      showErrorMessage,
      validateCallback,
      data,
      // Remove the ones coming from Redux
      inputValuesActions,
      creditCardActions,
      step,
      globalErrors,
      shouldSaveValue,
      ...inputProps
    } = this.props;

    const isCardForm = step === 'card_form';

    return (
      <>
        {this.showCaptureNotSupportedMessage()}
        <BaseInput
          {...inputProps}
          className={className}
          type="tel"
          error={showErrorMessage ? this.state.error : ''}
          invalid={this.state.invalid}
          onChange={this.onChange}
          value={this.state.maskedValue}
          maxLength={this.state.maxLength}
          updateCallback={(value) => {
            this.updateValue(value);
          }}
          autoComplete="cc-number"
          autoCorrect="no"
          autoCapitalize="no"
          spellCheck="no"
          placeholder={CARD_NUMBER}
          isCardForm={isCardForm}
          paymentMethodLogo={this.state.paymentMethodLogo}
        />
      </>
    );
  }
}

/**
 * Prop Types
 */
CardNumber.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  showErrorMessage: PropTypes.bool,
  invalid: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      payment_method_id: PropTypes.string,
      name: PropTypes.string,
      settings: PropTypes.arrayOf(
        PropTypes.shape({
          bin: PropTypes.object,
          card_number: PropTypes.object,
          security_code: PropTypes.object,
        }),
      ),
    }),
  ),
  deviceType: PropTypes.string,
  step: PropTypes.string,
  inputValuesActions: PropTypes.object, // eslint-disable-line
  creditCardActions: PropTypes.object, // eslint-disable-line
  globalErrors: PropTypes.object, // eslint-disable-line
  shouldSaveValue: PropTypes.bool,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  maxInstallments: PropTypes.number.isRequired,
};

/**
 * Default Props
 */
CardNumber.defaultProps = {
  error: [],
  invalid: false,
  showErrorMessage: true,
  data: [],
  deviceType: 'desktop',
  globalErrors: {},
  shouldSaveValue: true,
  i18n: {
    gettext: (t, i) => t.replace('{0}', i),
  },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  step: state.page.flow.step,
  globalErrors: state.globalErrors, // If this component support globalErrors
  creditCardValue: state.inputValues.current[`${state.page.flow.step}_card_number`],
  maxInstallments: state.installment.maxInstallments,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = CardNumber;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(CardNumber))); // eslint-disable-line max-len
}
