// Constants
const { PAGE_ACTIONS } = require('../../../../../constants/app');
// Actions
const { STEP_NEXT } = require('../../../../spa/actions/types');
// Utils
const { getQueryParams } = require('../../../../utils/Dom');
const { logErrorFromClient } = require('../../../../utils/logTags');

export const useActions = ({ flow, history, stepActions }) => {
  const nextStepAction = (data) => {
    const queryParams = getQueryParams();
    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  // ===== STEP ACTIONS =====
  const changePaymentMethod = () => {
    const data = {
      id: PAGE_ACTIONS.CHANGE_PAYMENT_OPTION,
      values: {},
    };
    nextStepAction(data);
  };

  const sendCardData = ({ additionalData: { paymentMethodData, issuerData }, cardToken, zeroDollarToken }) => {
    const logError = (reason, detail) => {
      logErrorFromClient(reason, detail, '[useActions][sendCardData]');
    };

    if (!cardToken) {
      logError('cardToken is null or undefined', 'The cardToken from the useCardForm hook returned a null or undefined value.');
    }

    if (typeof zeroDollarToken !== 'undefined' && !zeroDollarToken) {
      logError('zeroDollarToken is null or undefined', 'The zeroDollarToken from the useCardForm hook returned a null or undefined value.');
    }

    const data = {
      id: PAGE_ACTIONS.NEXT_STEP,
      values: {
        payment_method: {
          id: paymentMethodData.paymentMethodId,
          name: paymentMethodData.name,
          type: paymentMethodData.paymentTypeId,
          issuer: {
            id: issuerData.id,
            name: issuerData.name,
          },
        },
        card_token: cardToken,
        ...(zeroDollarToken && { zero_dollar_token: zeroDollarToken }),
      },
    };
    nextStepAction(data);
  };

  const navigateToErrorPage = () => {
    const queryParams = getQueryParams();
    const data = {
      id: PAGE_ACTIONS.ERROR,
      values: {},
    };
    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  const navigateToPendingBackUrl = (url) => {
    window.location.replace(url);
  };

  return {
    navigateToPendingBackUrl,
    navigateToErrorPage,
    changePaymentMethod,
    sendCardData,
  };
};
