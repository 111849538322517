import type { TypographyProps } from '@andes/typography';
import type { MoneyAmountProps } from '@andes/money-amount';

import { MoneyAmount } from '@andes/money-amount';
import { Typography } from '@andes/typography';
import React from 'react';
import { VisuallyHidden } from '@andes/common';

import { DeviceType } from '../../../constants/commons';

type MoneyAmountValues = {
  cents: string;
  fraction: string;
  centsType: MoneyAmountProps['centsType'];
  symbol: MoneyAmountProps['symbol'];
};

type QuantityDetailProps = {
  quantity: string;
  srLabel: string;
  amountInfo: MoneyAmountValues;
  deviceType?: DeviceType;
};

const QuantityDetail = ({
  quantity,
  srLabel,
  amountInfo,
  deviceType = DeviceType.DESKTOP,
}: QuantityDetailProps) => {
  const commonTypographyProps: Partial<TypographyProps> = {
    type: 'body',
    size: 'l',
    color: 'primary',
  };

  return (
    <div className="installment-left-content">
      <Typography
        {...commonTypographyProps}
        weight="semibold"
        aria-hidden
        className="installment-quantity"
      >
        {quantity}
      </Typography>
      <VisuallyHidden>{`${srLabel} `}</VisuallyHidden>
      <MoneyAmount
        centsType={amountInfo.centsType}
        size={deviceType === DeviceType.DESKTOP ? 18 : 16}
        symbol={amountInfo.symbol}
        value={{
          cents: amountInfo.cents,
          fraction: amountInfo.fraction,
        }}
        className="installment-quantity-amount"
      />
    </div>
  );
};

export default QuantityDetail;
