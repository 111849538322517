import React from 'react';

export type TermProps = {
  isMCO?: boolean;
  acceptLabel?: string;
  termAndConditionsLabel?: string;
  termsAndConditionsLink?: string;
  brandLabel?: string;
  help?: {
    label: string;
    link: string;
  };
};

export default function Term({
  isMCO = false,
  acceptLabel = 'Al pagar, afirmo que soy mayor de edad y acepto los',
  termAndConditionsLabel = 'Términos y Condiciones',
  termsAndConditionsLink = 'https://www.mercadopago.com.ar/ayuda/terminos-y-politicas_194',
  brandLabel = 'de Mercado Pago',
  help,
}: TermProps) {
  return (
    <div className={`cow-terms-and-conditions ${help ? 'cow-terms-and-conditions--with-help' : ''}`}>
      {(help && isMCO) && (
        <p className="cow-terms-and-conditions--help">
          <a
            className="cow-terms-and-conditions__links"
            href={help.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {help.label}
          </a>
        </p>
      )}
      <p className="cow-terms-and-conditions--terms">
        <span className="cow-terms-and-conditions__accept">{acceptLabel}</span>{' '}
        <a
          className="cow-terms-and-conditions__links"
          href={termsAndConditionsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termAndConditionsLabel}
        </a>{' '}
        <span className="cow-terms-and-conditions__brand">{brandLabel}</span>
      </p>
    </div>
  );
}
