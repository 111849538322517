const React = require('react');

module.exports = props => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={51} height={42} viewBox="0 0 51 42">
    <defs>
      <linearGradient id="c" x1="75.473%" x2="20.068%" y1="43.069%" y2="65.006%">
        <stop offset="0%" stopColor="#5E8AA0" />
        <stop offset="100%" stopColor="#3A4E64" />
      </linearGradient>
      <path id="master_path_a" d="M3.797 0h30a3 3 0 0 1 3 3v16.5a3 3 0 0 1-3 3h-30a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" />
      <filter id="master_filter_b" width="127.8%" height="144.4%" x="-13.9%" y="-17.8%" filterUnits="objectBoundingBox">
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <linearGradient id="e" x1="16.442%" x2="39.22%" y1="60.553%" y2="93.464%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".525" />
        <stop offset="100%" stopColor="#7B7B7B" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <linearGradient id="f" x1="6.795%" x2="65.274%" y1="49.895%" y2="50.12%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="g" x1="12.312%" x2="50.789%" y1="49.922%" y2="50.145%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="h" x1="36.861%" x2="9.705%" y1="50.362%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="i" x1="35.707%" x2="9.705%" y1="50.238%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="18.645%" stopColor="#F7F7F7" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="j" x1="12.312%" x2="56.973%" y1="49.922%" y2="50.079%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="k" x1="12.312%" x2="54.344%" y1="49.922%" y2="50.101%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="l" x1="42.095%" x2="9.705%" y1="50.357%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="14.977%" stopColor="#F8F8F8" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="m" x1="45.329%" x2="9.705%" y1="50.55%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="n" x1="12.312%" x2="58.852%" y1="49.922%" y2="50.069%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="o" x1="55.435%" x2="9.705%" y1="50.248%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="p" x1="12.312%" x2="92.468%" y1="49.922%" y2="50.088%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="q" x1="63.581%" x2="9.705%" y1="50.476%" y2="49.767%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="10.025%" stopColor="#FBFBFB" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="r" x1="78.363%" x2="58.11%" y1="50%" y2="64.889%">
        <stop offset="0%" stopColor="#F2F2F2" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <linearGradient id="s" x1="12.312%" x2="92.468%" y1="48.712%" y2="51.453%">
        <stop offset="0%" />
        <stop offset="100%" stopColor="#282828" />
      </linearGradient>
      <circle id="t" cx="11.836" cy="11.836" r="11.836" />
      <filter id="u" width="188.6%" height="188.6%" x="-44.3%" y="-35.9%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="1.991" result="shadowSpreadOuter1" />
        <feOffset dy={2} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology in="SourceAlpha" radius={1} result="shadowInner" />
        <feOffset dy={2} in="shadowInner" result="shadowInner" />
        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
      </filter>
      <linearGradient id="y" x1="75.473%" x2="20.068%" y1="43.407%" y2="64.273%">
        <stop offset="0%" stopColor="#5E8AA0" />
        <stop offset="100%" stopColor="#3A4E64" />
      </linearGradient>
      <path
        id="w"
        d="M0 4.927A4.923 4.923 0 0 1 4.916 0H120.48a4.92 4.92 0 0 1 4.916 4.927v66.582a4.923 4.923 0 0 1-4.916 4.927H4.916A4.92 4.92 0 0 1 0 71.51V4.927z"
      />
      <filter id="x" width="108%" height="113.1%" x="-4%" y="-5.2%" filterUnits="objectBoundingBox">
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <linearGradient id="A" x1="15.243%" x2="38.834%" y1="60.553%" y2="93.464%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".525" />
        <stop offset="100%" stopColor="#7B7B7B" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <linearGradient id="B" x1="6.795%" x2="65.274%" y1="49.98%" y2="50.023%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="C" x1="12.312%" x2="50.789%" y1="49.985%" y2="50.027%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="D" x1="36.861%" x2="9.705%" y1="50.068%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="E" x1="35.707%" x2="9.705%" y1="50.045%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="18.645%" stopColor="#F7F7F7" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="F" x1="12.312%" x2="56.973%" y1="49.985%" y2="50.015%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="G" x1="12.312%" x2="54.344%" y1="49.985%" y2="50.019%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="H" x1="42.095%" x2="9.705%" y1="50.067%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="14.977%" stopColor="#F8F8F8" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="I" x1="45.329%" x2="9.705%" y1="50.104%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="J" x1="12.312%" x2="58.852%" y1="49.985%" y2="50.013%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="K" x1="55.435%" x2="9.705%" y1="50.047%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="21.661%" stopColor="#F5F5F5" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="L" x1="12.312%" x2="92.468%" y1="49.985%" y2="50.017%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="M" x1="63.581%" x2="9.705%" y1="50.09%" y2="49.956%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="10.025%" stopColor="#FBFBFB" />
        <stop offset="100%" stopColor="#D2D2D2" />
      </linearGradient>
      <linearGradient id="N" x1="78.363%" x2="58.11%" y1="50%" y2="58.917%">
        <stop offset="0%" stopColor="#F2F2F2" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4.203 9.75)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#master_path_a" />
        </mask>
        <use fill="#000" filter="url(#master_filter_b)" xlinkHref="#master_path_a" />
        <use fill="url(#c)" xlinkHref="#master_path_a" />
        <ellipse
          cx="11.372"
          cy="24.375"
          fill="#1C1C52"
          fillOpacity=".6"
          mask="url(#d)"
          opacity=".2"
          rx="22.257"
          ry="22.875"
        />
        <ellipse
          cx="29.615"
          cy="-4.125"
          fill="#142858"
          fillOpacity=".87"
          stroke="url(#e)"
          strokeWidth=".5"
          mask="url(#d)"
          opacity=".103"
          rx="22.257"
          ry="22.875"
        />
        <g mask="url(#d)">
          <path
            fill="url(#f)"
            fillOpacity=".9"
            d="M0 0h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#g)"
            fillOpacity=".9"
            d="M0 .701h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#h)"
            fillOpacity=".9"
            d="M0 .35h23.693v.351H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#i)"
            fillOpacity=".9"
            d="M0 1.052h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#j)"
            fillOpacity=".9"
            d="M0 1.403h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#k)"
            fillOpacity=".9"
            d="M0 2.104h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#l)"
            fillOpacity=".9"
            d="M0 1.753h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#m)"
            fillOpacity=".9"
            d="M0 2.454h23.693v.351H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#n)"
            fillOpacity=".9"
            d="M0 2.805h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#o)"
            fillOpacity=".9"
            d="M0 3.156h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#p)"
            fillOpacity=".9"
            d="M0 3.506h23.693v.351H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#q)"
            fillOpacity=".9"
            d="M0 3.857h23.693v.35H0z"
            transform="translate(4.297 11)"
          />
          <path
            fill="url(#r)"
            d="M23.693 0h5.28v4.208h-5.28z"
            transform="translate(4.297 11)"
          />
        </g>
        <path
          fill="url(#s)"
          fillOpacity=".9"
          d="M0 4h38.993v4.18H0z"
          mask="url(#d)"
        />
      </g>
      <g transform="translate(20 9)">
        <mask id="v" fill="#fff">
          <use xlinkHref="#t" />
        </mask>
        <use fill="#000" filter="url(#u)" xlinkHref="#t" />
        <circle
          cx="11.836"
          cy="11.836"
          r="12.831"
          stroke="#F04449"
          strokeWidth="1.991"
        />
        <g mask="url(#v)">
          <g transform="translate(-90.991 -28.79)">
            <mask id="z" fill="#fff">
              <use xlinkHref="#w" />
            </mask>
            <use fill="#000" filter="url(#x)" xlinkHref="#w" />
            <use fill="url(#y)" xlinkHref="#w" />
            <ellipse
              cx="31.619"
              cy="83.192"
              fill="#29295F"
              fillOpacity=".596"
              mask="url(#z)"
              opacity=".103"
              rx="77.516"
              ry="78.283"
            />
            <ellipse
              cx="95.365"
              cy="-12.348"
              fill="#1A3A83"
              fillOpacity=".869"
              stroke="url(#A)"
              strokeWidth=".72"
              mask="url(#z)"
              opacity=".103"
              rx="77.516"
              ry="78.283"
            />
          </g>
          <path
            fill="url(#B)"
            fillOpacity=".9"
            d="M0 0h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#C)"
            fillOpacity=".9"
            d="M0 1.842h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#D)"
            fillOpacity=".9"
            d="M0 .921h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#E)"
            fillOpacity=".9"
            d="M0 2.763h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#F)"
            fillOpacity=".9"
            d="M0 3.684h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#G)"
            fillOpacity=".9"
            d="M0 5.526h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#H)"
            fillOpacity=".9"
            d="M0 4.605h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#I)"
            fillOpacity=".9"
            d="M0 6.446h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#J)"
            fillOpacity=".9"
            d="M0 7.367h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#K)"
            fillOpacity=".9"
            d="M0 8.288h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#L)"
            fillOpacity=".9"
            d="M0 9.209h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#M)"
            fillOpacity=".9"
            d="M0 10.13h80.408v1H0z"
            transform="translate(-77.207 6.244)"
          />
          <path
            fill="url(#N)"
            d="M80.408 0h17.919v11.051H80.408z"
            transform="translate(-77.207 6.244)"
          />
        </g>
      </g>
      <text
        fill="#333"
        fontFamily="RobotoMono-Medium, Roboto Mono"
        fontSize="7.373"
        fontWeight={400}
        transform="translate(20 9)"
      >
        <tspan x="5.269" y="13.636">
          {props.text}
        </tspan>
      </text>
    </g>
  </svg>
);
