const React = require('react');
const PropTypes = require('prop-types');

const UserTitle = props => (
  <div className="user-title">
    <span className="user-title__name">{props.user.firstName} {props.user.lastName}</span>
    <span className="user-title__email">{props.user.email}</span>
  </div>
);

UserTitle.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
};

module.exports = UserTitle;
