const {createAction} = require('redux-actions');

const { CHECK_PAYMENT_OPTION, UNCHECK_PAYMENT_OPTION, RESET_COMBINATION} = require('./types');

const checkPaymentOption = createAction(CHECK_PAYMENT_OPTION);
const unCheckPaymentOption = createAction(UNCHECK_PAYMENT_OPTION);
const resetCombination = createAction(RESET_COMBINATION);

module.exports = {
  [CHECK_PAYMENT_OPTION]: (id, value = '') => dispatch => {
    dispatch(checkPaymentOption({ id, value }));
  },
  [UNCHECK_PAYMENT_OPTION]: (id, value) => dispatch => {
    dispatch(unCheckPaymentOption({ id, value }));
  },
  [RESET_COMBINATION]: () => dispatch => {
    dispatch(resetCombination())
  }
};
