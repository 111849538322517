/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  TOGGLE_MODAL_VISIBILITY,
  RESET_MODAL_CUSTOM_TITLE,
  UPDATE_MODAL_CUSTOM_TITLE,
} = require('./types');

// Request Actions
const toggleModalVisibility = createAction(TOGGLE_MODAL_VISIBILITY);
const resetModalCustomTitle = createAction(RESET_MODAL_CUSTOM_TITLE);
const updateModalCustomTitle = createAction(UPDATE_MODAL_CUSTOM_TITLE);

/**
 * Export functions
 */
module.exports = {
  [TOGGLE_MODAL_VISIBILITY]: () => (dispatch, getState) => dispatch(
    toggleModalVisibility(
      { visible: !getState().modal.visible },
    ),
  ),
  [RESET_MODAL_CUSTOM_TITLE]: () => (dispatch) => dispatch(resetModalCustomTitle({})),
  [UPDATE_MODAL_CUSTOM_TITLE]: (title) => (dispatch) => dispatch(updateModalCustomTitle({ title })),
};
