const React = require('react');
const PropTypes = require('prop-types');

const { ProgressIndicatorLinear: ProgressIndicator } = require('@andes/progress-indicator-linear');

const ProgressIndicatorLinear = ({ counterText, counterValue, progressPercentage }) => (
  <div className="progress-container" tabIndex={-1} aria-hidden="true">
    <ProgressIndicator
      size="small"
      value={progressPercentage}
    />
    <div className="counter" tabIndex={-1} aria-hidden="true">{`${counterText} ${counterValue}`}</div>
  </div>
);

ProgressIndicatorLinear.propTypes = {
  counterText: PropTypes.string,
  counterValue: PropTypes.string,
  progressPercentage: PropTypes.number,
};

ProgressIndicatorLinear.defaultProps = {
  counterText: '',
  counterValue: '',
  progressPercentage: 0,
};

module.exports = ProgressIndicatorLinear;
