/* global document */
const React = require('react');
const ReactDOM = require('react-dom');
const PropTypes = require('prop-types');

const Button = require('../../containers/Button/ButtonWithStore');
const { submitForm } = require('../../utils/Dom');

class ButtonForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.getForm = this.getForm.bind(this);
  }

  componentDidMount() {
    const form = this.getForm();
    if (form) {
      const button = ReactDOM.findDOMNode(this); // eslint-disable-line
      form.onsubmit = () => {
        button.click();
      };
    }
  }

  handleClick(event) {
    const form = this.getForm();
    if (!form) {
      return;
    }

    // Stop submiting form only if it has a target
    event.preventDefault();

    submitForm(form);
  }

  getForm() {
    const form = document.querySelector(`#${this.props.target}`);
    if (!form || form.tagName !== 'FORM') {
      return null;
    }
    return form;
  }

  render() {
    return (
      <Button
        id={this.props.id}
        kind={this.props.kind}
        type={this.props.type}
        text={this.props.text}
        onClick={this.handleClick}
        disabled={this.props.disabled}
        icon={this.props.icon}
      />
    );
  }
}

/**
 * Prop Types
 */
ButtonForm.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  kind: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

/**
 * Default Props
 */

ButtonForm.defaultProps = {
  id: '',
  text: '',
  kind: 'loud',
  target: '',
  type: '',
  disabled: false,
  icon: null,
};

module.exports = ButtonForm;
