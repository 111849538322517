const React = require('react');

const { ValidatorProvider } = require('../../utils/validator-provider');
const Form = require('../Form');

class GeoLocalization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geolocation: null,
    };
    this.button = null;
    this.saveGeoLocation = this.saveGeoLocation.bind(this);
    this.successGeo = this.successGeo.bind(this);
    this.failGeo = this.failGeo.bind(this);
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.successGeo, this.failGeo);
    } else {
      // if browser does not support it .. just fail
      this.failGeo();
    }
  }

  /**
   * if the users accepts the promp will exit on success with the geo location data
   * @param {*} position
   */
  /* istanbul ignore next */
  successGeo(position) {
    this.saveGeoLocation(position);
  }

  /**
   * if the users block geo or just closes the prompt it will exit on fail
   */
  failGeo() {
    this.saveGeoLocation(null);
  }

  /**
   * save geo data into the state
   * @param {*} position
   */
  saveGeoLocation(position) {
    const coords = position && position.coords ? position.coords : null;
    const geolocation = coords ? `latitude: ${coords.latitude}, longitude: ${coords.longitude}` : null;
    this.setState({
      geolocation,
    }, () => {
      // once saved auto submit the form
      this.button.click();
    });
  }

  render() {
    return (
      <ValidatorProvider>
        <Form id="geolocation-form" key={Math.random()} method="POST">
          <input type="hidden" name={this.props.name} value={this.state.geolocation} />
          <button style={{ display: 'none' }} ref={el => this.button = el} type="submit">submit</button>
        </Form>
      </ValidatorProvider>
    );
  }
}

module.exports = GeoLocalization;
