const React = require('react');

// Cow Components
const Pin = require('../../../../../components/icons/Pin');

// Custom Components
const IconLocalPick = require('../../../../../components/icons/LocalPick');
const ShippingType = require('../../../../../components/icons/ShippingType');

// Utils
const { isMobile } = require('../../../../../utils/webview');

const getShippingOptions = ({ shipping, translations, changeShippingOption,
  changeShippingAddress, deviceType }) => {
  const options = [];

  if (shipping.is_pickup) {
    const extractDataOption = {
      title: translations.PICKUP_SHIPPING,
      icon: <IconLocalPick />,
      onClick: changeShippingOption,
      callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
      isMobile: isMobile(deviceType),
    };

    options.push(extractDataOption);
  } else {
    const extractAddressInformation = {
      title: shipping.delivery.address.street,
      description: shipping.delivery.address.details,
      extraInfo: shipping.delivery.address.contact,
      icon: <Pin />,
      onClick: changeShippingAddress,
      callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
      isMobile: isMobile(deviceType)
    };

    options.push(extractAddressInformation);

    const extractDeliveryInformation = {
      title: shipping.delivery.arrival,
      description: shipping.delivery.price === "" ? translations.FREE_SHIPPING : shipping.delivery.price,
      descriptionColor: shipping.delivery.price === "" ? 'green' : '',
      icon: <ShippingType />,
      onClick: changeShippingOption,
      callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
      isMobile: isMobile(deviceType)
    };

    options.push(extractDeliveryInformation);
  }

  return options;
}

module.exports = {
  getShippingOptions
}
