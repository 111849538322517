import React from 'react';

const PagamentoNaLoteria = ({
  className,
  width = 22,
  height = 22,
  ...props
}: SVGProps) => (
  <svg
    {...props}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={'currentColor'}
  >
    <defs>
      <path id="icon_loteria_a" d="M0 .12h24.8V22H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.4)">
      <mask id="icon_loteria_b" fill="#fff">
        <use xlinkHref="#icon_loteria_a" />
      </mask>
      <path
        fill={'currentColor'}
        d="M5.28 21V9.52H2.75l9.65-8.1 9.65 8.1h-1.97V21H5.28zm15.8-10.48h3.72L12.4.12 0 10.52h4.28V21h-3.2v1h22.64v-1h-2.64V10.52z"
        mask="url(#icon_loteria_b)"
      />
      <path
        fill={'currentColor'}
        d="M12.1 17.47a.55.55 0 0 0 .94.39.54.54 0 0 0 .16-.39v-.56c1.4-.16 2.29-1 2.29-2.17s-.72-1.88-2.4-2.3l-.84-.22c-.82-.26-.96-.53-.96-.92 0-.46.35-.76.96-.82a4.22 4.22 0 0 1 .82.08c.38.1.74.26 1.08.46.11.07.24.1.37.1a.67.67 0 0 0 .68-.67.64.64 0 0 0-.36-.58 4 4 0 0 0-1.62-.6v-.51a.54.54 0 0 0-.55-.55.55.55 0 0 0-.55.55v.5c-1.37.14-2.25.97-2.25 2.13s.69 1.85 2.36 2.29l.85.2c.84.26 1 .54 1 .94 0 .48-.35.78-.98.85h-.22a3.55 3.55 0 0 1-.61-.05 4.25 4.25 0 0 1-1.55-.7.68.68 0 1 0-.76 1.12c.64.45 1.37.73 2.14.83v.6z"
        mask="url(#icon_loteria_b)"
      />
    </g>
  </svg>
);

export default React.memo(PagamentoNaLoteria);
