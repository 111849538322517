const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');

const IconWrapper = require('../IconWrapper');

const IconFeedbackNegative = ({ status, width, height }) => (
  <IconWrapper className={classNames(status, { [`icon-${status}`]: true })}>
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.96967 3.87878H7.03027L7.2727 9.21212H8.72724L8.96967 3.87878Z" />
      <path d="M7.99997 10.1818C8.53552 10.1818 8.96967 10.616 8.96967 11.1515C8.96967 11.6871 8.53552 12.1212 7.99997 12.1212C7.46442 12.1212 7.03027 11.6871 7.03027 11.1515C7.03027 10.616 7.46442 10.1818 7.99997 10.1818Z" />
    </svg>
  </IconWrapper>
);

IconFeedbackNegative.defaultProps = {
  status: '',
  width: 32,
  height: 32,
};

IconFeedbackNegative.propTypes = {
  status: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

module.exports = React.memo(IconFeedbackNegative);
