import React from 'react';

const PixOutline = ({ width = 24, height = 24, ...props }: SVGProps) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7553 8.96391L15.0106 2.20698C13.4043 0.597675 10.6064 0.597675 8.98936 2.20698L2.24468 8.96391C1.43617 9.76323 1 10.8397 1 11.98C1 13.1204 1.44681 14.1861 2.24468 14.9961L8.98936 21.7531C9.79787 22.563 10.8617 23 12 23C13.1383 23 14.2021 22.5524 15.0106 21.7531L21.7553 14.9961C23.4149 13.3335 23.4149 10.6265 21.7553 8.96391ZM10.117 3.33668C10.617 2.83578 11.2872 2.55868 12 2.55868C12.7128 2.55868 13.383 2.83578 13.883 3.33668L16.7872 6.24621H15.8298C15.4681 6.24621 15.1277 6.41674 14.9043 6.70449L12.5106 9.76323C12.3723 9.93375 12.1702 10.0403 11.9574 10.0403C11.8192 10.0403 11.5957 10.0084 11.4149 9.77389L9.02128 6.71515C8.79787 6.42739 8.45744 6.25687 8.09574 6.25687H7.21277L10.117 3.34734V3.33668ZM13.883 20.6234C12.883 21.6358 11.1277 21.6358 10.117 20.6234L7.55319 18.0549H7.74468C8.31915 18.0549 8.85106 17.7991 9.21277 17.3408L11.4043 14.5378C11.5851 14.3034 11.8298 14.2501 11.9468 14.2714C12.1596 14.2714 12.3617 14.3673 12.5 14.5378L14.6915 17.3408C15.0426 17.7884 15.5851 18.0442 16.1596 18.0442H16.4362L13.8723 20.6127L13.883 20.6234ZM20.6277 13.8664L18.0319 16.4669H16.1596C16.0745 16.4669 16 16.4242 15.9468 16.371L13.7553 13.568C13.3085 13.0138 12.6596 12.6834 11.9468 12.6834C11.2447 12.6834 10.5851 13.0031 10.1596 13.568L7.96809 16.371C7.9149 16.4349 7.84042 16.4775 7.75532 16.4775H5.96808L3.37234 13.8771C2.87234 13.3762 2.59574 12.7047 2.59574 11.9907C2.59574 11.2766 2.87234 10.6052 3.37234 10.1043L5.61702 7.85552H7.89362L10.1596 10.7544C10.5957 11.3086 11.2447 11.639 11.9468 11.639C12.6489 11.639 13.3085 11.3192 13.7553 10.7544L16.0213 7.85552H18.3723L20.617 10.1043C21.6489 11.1381 21.6489 12.8326 20.617 13.8771L20.6277 13.8664Z"
      fill="currentColor"
    />
  </svg>
);

export default React.memo(PixOutline);
