const { getWebviewType } = require("./webview");

// Constants
const { CHO_VERSION_PREFIX } = require("../../constants/app");

class MelidataEventData {
  constructor(stateConfigs) {
    this.stateConfigs = stateConfigs;
    this.choVersionPrefix = CHO_VERSION_PREFIX.V2;
  }

  build(properties = {}) {
    const additionalProperties = {
      checkout_open_mode: this.checkoutOpenMode(),
    };

    return Object.assign(properties, additionalProperties);
  }

  checkoutOpenMode() {
    const prefixAndVersion = `${this.choVersionPrefix}_${this.stateConfigs.version}`;
    const typeAndProduct = this.stateConfigs.checkoutType + this.checkoutProduct();

    return `${prefixAndVersion}_${typeAndProduct}${this.buildWebviewSufix()}`;
  }

  checkoutProduct() {
    return this.stateConfigs.checkoutProduct ? `_${this.stateConfigs.checkoutProduct}` : '';
  }

  buildWebviewSufix() {
    const state = window?.__PRELOADED_STATE__;
    const webviewType = getWebviewType(window?.location?.search, this.stateConfigs, state?.initialStore?.configurations?.browser, true);

    if (webviewType) {
      return `_webview_${webviewType}`;
    }

    return '';
  }
}

module.exports = MelidataEventData;
