const React = require('react');
const PropTypes = require('prop-types');

const IconOnlinePaymentMethod = require('../../containers/IconOnlinePaymentMethod');
const AmountDetailsRow = require('./components/AmountDetailsRow');

/* @TODO This component must be migrated to the cow library (revamp) */

const PaymentMethodInfo = ({ firstText, secondText, paymentMethod, amount, installments, extraLabel }) => (
  <div className="payment-method-info">
    <div className="description">
      <IconOnlinePaymentMethod paymentMethod={paymentMethod} />
      <div className="description__details">
        <div className="first-text">{firstText}</div>
        {secondText && <div className="second-text">{secondText}</div>}
      </div>
    </div>
    {!!amount && <AmountDetailsRow amount={amount} installments={installments} extraLabel={extraLabel} />}
  </div>
);

PaymentMethodInfo.propTypes = {
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  paymentMethod: PropTypes.object,
  amount: PropTypes.string,
  installments: PropTypes.string,
  extraLabel: PropTypes.string,
};

PaymentMethodInfo.defaultProps = {
  firstText: '',
  secondText: '',
  children: null,
  extraLabel: null,
};

module.exports = PaymentMethodInfo;
