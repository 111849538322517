/**
 * Polyfills for ES6
 * If you need to are more polyfills see: https://github.com/zloirock/core-js
 */
// core js new polyfills
require('core-js/actual/object/assign');
require('core-js/actual/array/for-each');
require('core-js/actual/string');
require('core-js/actual/array/fill');
require('core-js/actual/array/find');
require('core-js/actual/map');
require('core-js/actual/set');
require('core-js/actual/promise');
require('core-js/actual/object/entries');
// elem closest
require('element-closest');
// Require intersection from es6 observables for ie
require('intersection-observer');
