const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');
const createNumberMask = require('text-mask-addons/dist/createNumberMask').default;

const { injectValidations } = require('../../utils/validator-provider');
const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const installmentActions = require('../../spa/actions/installment');
const paymentMethodsActions = require('../../spa/actions/paymentMethods');
const IconCreditCard = require('../icons/CreditCard');
const currencyUtil = require('../../utils/currency');
const PaymentMethodUtils = require('../../utils/PaymentMethodUtils');
const {
  CURRENT_INPUT_VALUES,
  INSTALLMENT_REQUEST,
  INSTALLMENT_REQUEST_CLEAN,
  FETCH_PAYMENT_METHODS,
} = require('../../spa/actions/types');
const Text = require('../Text');
const { defaultTextProps } = require('../../containers/Optimus/helper');
const translate = require('../../translation');
const debounce = require('../../utils/debounce');
const ProgressIndicatorLinear = require('../ProgressIndicatorLinear');
const calcPercentage = require('../../utils/calcPercentage');
const {
  INPUT_MODE: { DECIMAL },
} = require('../../../constants/commons');

class InputSplitAmount extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    // Default State
    const initialValue = this.getInitialValue(props);
    this.state = {
      error: props.error,
      invalid: props.invalid,
      value: initialValue,
      inputText: currencyUtil.parseAmount(initialValue, props.currency),
      paymentMethods: [],
    };

    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.splitPercentageDescription = this.splitPercentageDescription.bind(this);
    this.extraValidations = this.extraValidations.bind(this);
    this.showTextPromo = this.showTextPromo.bind(this);
    this.translations = translate(i18n);
    this.getCurrentValue = this.getCurrentValue.bind(this);
    this.fetchPaymentMethods = debounce(this.fetchPaymentMethods.bind(this), 1000);
    this.cleanCreditCardNumber = this.cleanCreditCardNumber.bind(this);
  }

  componentDidMount() {
    if (this.props.isCombination) {
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](
        `${this.props.step}_split_amount_initial_value`,
        this.state.initialValue,
      );
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_is_combination`, true);
      this.updateValue(this.state.initialValue);
      this.setState({ mounted: true });
    }
  }

  getInitialValue(props) {
    if (this.props.isCombination) {
      // Defines whether the default value should be the entire value of account money or not
      return this.props.data.initial_value ? parseFloat(this.props.data.initial_value) : 0;
    }

    // savedValue stores formatted string of amount (i.e.: '$3,000.00')
    const savedValue =
      !props.disabled && props.savedValue ? currencyUtil.getFloatValue(props.savedValue, props.currency) : null;

    // amountValue is a number stringified, coming from Flows (i.e.: '3.000')
    const amountValue = props.data.amount ? parseFloat(props.data.amount) : null;

    // defaultValue is the expected value to display when none is sent from flows
    // Currently is expected to display 50% of total if it's editable
    const defaultValue =
      this.props.data.total && props.data.amount === undefined && !props.disabled
        ? Math.round(this.props.data.total / 2.0)
        : '';

    return savedValue || amountValue || defaultValue; // prioriced eligible value
  }

  /**
   * Fetch payment to resselect the correct issuer from payment methods
   * @returns void
   */
  fetchPaymentMethods(floatValue) {
    this.props.paymentMethodsActions[FETCH_PAYMENT_METHODS](floatValue);
  }

  /**
   * Clean credit card number to user re-type so it can take the correct card issuer
   * @returns void
   */
  cleanCreditCardNumber() {
    this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_card_number`, '');
  }

  onChange(event) {
    this.updateValue(event.target.value);
  }

  handleBlur() {
    if (this.props.isCombination) {
      this.validate();
    }
  }

  updateValue(value) {
    const targetValue = value ? value.toString() : '';
    let floatValue = currencyUtil.getFloatValue(targetValue, this.props.currency);

    if (floatValue <= 0 || targetValue.startsWith('-')) {
      floatValue = '';
    }

    let toUpdate = {
      invalid: false,
      error: [],
    };

    if (floatValue <= this.props.data.total) {
      if (this.props.isCombination) {
        const total = this.props.data.total ? this.props.data.total.toString() : '';
        const totalValue = parseFloat(total);
        const subtractValueTotal = currencyUtil.parseAmount(
          currencyUtil.handleDecimals(totalValue - floatValue),
          this.props.currency,
        );
        this.props.inputValuesActions[CURRENT_INPUT_VALUES](
          `${this.props.step}_split_amount_first_method`,
          targetValue,
        );
        this.props.inputValuesActions[CURRENT_INPUT_VALUES](
          `${this.props.step}_split_amount_second_method`,
          subtractValueTotal,
        );

        if (this.state.mounted) {
          this.props.installmentActions[INSTALLMENT_REQUEST_CLEAN]();
          this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_installments_select_credits`, '');
          this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.props.step}_installments_select_express`, '');
        }
      }

      toUpdate = {
        ...toUpdate,
        inputText: value,
        value: floatValue,
      };
    }

    this.cleanCreditCardNumber();
    this.fetchPaymentMethods(floatValue);

    this.setState(toUpdate);
  }

  /**
   * Calculate the min value available for the selected payment method
   * if the issuer is not present and the min value from all payment methods returned 0
   * set the min value to 1
   * if there is a issuer and min value from all payment methods returned 0
   * search the min value from the selected issuer and set it as minAmount
   * if that also returned 0 set the minAmount to 1
   *
   * tldr; we need to have a value greater than 0 for this min value.
   * @returns minAmount
   */
  getPmMinAmountAvailable(issuer, min) {
    let minAmount = Number.MAX_VALUE;
    const validIssuer = issuer && Array.isArray(issuer.payer_costs);
    if (min === 0 && validIssuer) {
      issuer.payer_costs.forEach((payerCost) => {
        minAmount = minAmount > payerCost.min_allowed_amount ? payerCost.min_allowed_amount : minAmount;
      });
      minAmount = minAmount === 0 ? 1 : minAmount;
    } else if (min === 0 && !validIssuer) {
      minAmount = 1;
    } else {
      minAmount = min;
    }

    return minAmount;
  }

  /**
   * Add extra validations beside the ones by default
   * @returns {*}
   */
  extraValidations() {
    const { disabled, paymentMethods, data, currencySettings, isCombination } = this.props;

    if (disabled && !isCombination) {
      return '';
    } // disabled mode, no custom validations

    const pmUtils = new PaymentMethodUtils(paymentMethods, {
      ...currencySettings,
      max_installments: this.props.maxInstallments,
    });
    const { minimum, maximum } = pmUtils.getAllowedAmountRange();
    const total = parseFloat(data.total);
    const value = this.getCurrentValue();
    const pending = this.props.changedValue && isCombination ? value : total - value;

    /**
     * edge case where the shipping ammount needs to be added on the first payment
     * so the ammount can't be less than the shipping cost + (1 ++++)
     */
    const minAmount = this.props.data.minAmount ? parseFloat(this.props.data.minAmount) : 0;
    const minValueAllowed = minAmount + this.getPmMinAmountAvailable(this.props.issuer, minimum);

    if (this.props.isCombination) {
      return this.validValueCombination(value, pending, total);
    }

    if (pending === 0 || minimum > pending) {
      return this.translations.TRY_LOWER_AMOUNT; // big AM amount, the second will be too small
    }
    if (value < minValueAllowed || total === pending || maximum < pending) {
      return this.translations.TRY_BIGGER_AMOUNT; // small AM amount, the second will be too big
    }
    return '';
  }

  getCurrentValue() {
    const { changedValue, isCombination, calculatedValue, currency } = this.props;

    return changedValue && isCombination
      ? currencyUtil.getFloatValue(calculatedValue, currency)
      : this.getComponentValue();
  }

  /**
   * Return message alert
   */
  validValueCombination(value, pending, total) {
    const {
      data: { max_amount, max_allowed_amount: maxAllowedAmount, min_allowed_amount: minAllowedAmount },
      paymentMethod,
      currency,
    } = this.props;
    const MIN_ALLOWED_AMOUNT = this.props.paymentMethod.min_allowed_amount;

    const maxAmount = parseFloat(max_amount);
    const paymentMethodId = paymentMethod.id;

    if (value === 0) {
      return this.translations.TRY_BIGGER_AMOUNT;
    }

    if (value < minAllowedAmount) {
      const min = currencyUtil.parseAmount(minAllowedAmount, currency);
      return this.translations.ENTER_AN_AMOUNT_FROM(min);
    }

    if (value === total) {
      return this.translations.TRY_AMOUNT_LOWER_THAN_TOTAL_AMOUNT;
    }

    if (value > maxAllowedAmount) {
      return this.translations.TRY_LOWER_AMOUNT;
    }

    // Only payments with a defined max amount
    if (maxAmount > 0 && value > maxAmount) {
      return paymentMethodId === 'consumer_credits'
        ? this.translations.TRY_AMOUNT_LOWER_THAN_LIMIT
        : this.translations.TRY_AMOUNT_LOWER_THAN_BALANCE;
    }

    const MAX_ALLOWED_AMOUNT = parseFloat(this.props.paymentMethod.max_allowed_amount);
    const pendingAmount = pending || 0;

    if (pendingAmount < MIN_ALLOWED_AMOUNT) {
      return this.translations.TRY_LOWER_AMOUNT;
    }

    if (MAX_ALLOWED_AMOUNT > 0 && value > MAX_ALLOWED_AMOUNT) {
      return this.translations.TRY_BIGGER_AMOUNT;
    }

    if (value > total) {
      return this.translations.TRY_BIGGER_AMOUNT;
    }

    return '';
  }

  /**
   * Return the description used below the input
   */
  splitPercentageDescription() {
    if (this.props.isCombination) {
      const maxAmount = currencyUtil.parseAmount(this.props.data.max_amount, this.props.currency);
      if (this.props.firstValue === maxAmount) {
        return this.translations.USING_FULL_BALANCE;
      }
      return this.props.text;
    }

    const currentValue = this.state.value;
    const total = this.props.data.total || '1';
    if (currentValue) {
      const percentageDesc = calcPercentage(currentValue, total);
      return this.translations.PERCENT_OF_TOTAL(percentageDesc);
    }
    return ' ';
  }

  /* istanbul ignore next */
  render() {
    const className = 'split-amount';

    const {
      errors,
      error,
      invalid,
      validations,
      showErrorMessage,
      validateCallback,
      data,
      disabled,
      name,
      inputValuesActions, // eslint-disable-line
      initialValue,
      currency,
      calculatedValue,
      ...inputProps
    } = this.props;

    const disableOptions = disabled
      ? {
          disabled: true,
        }
      : {};

    const mask = createNumberMask({
      prefix: `${currency.symbol} `,
      decimalSymbol: currency.decimal_separator,
      thousandsSeparatorSymbol: currency.thousands_separator,
      allowDecimal: true,
      decimalLimit: currency.decimal_places,
    });

    const { isCombination } = this.props;

    return (
      <div
        className={classNames(`ui-card ${className}`, {
          disabled,
          'new-card-row': this.props.paymentMethod.id === 'new_card_row',
        })}
      >
        {!isCombination && <IconCreditCard key={Math.random()} />}
        <div className={`${className}__input_container`}>
          <BaseInput
            {...inputProps}
            {...disableOptions}
            key={`${this.state.step}_InputSplitAmount_r`}
            error={showErrorMessage ? this.state.error : []}
            invalid={this.state.invalid}
            className={`${className}__input`}
            type={isCombination ? 'numeric' : 'tel'}
            label={this.props.label}
            mask={mask}
            message={
              this.state.invalid && this.state.error.length > 0
                ? this.state.error[0]
                : this.splitPercentageDescription()
            }
            messageFixed
            onChange={this.onChange}
            onBlur={this.handleBlur}
            updateCallback={(value) => {
              this.updateValue(value);
            }}
            autoCorrect="no"
            autoCapitalize="no"
            spellCheck="no"
            autoComplete="off"
            value={this.props.changedValue ? calculatedValue : this.state.inputText}
            placeholder={currencyUtil.parseAmount('0', currency)}
            inputValuesActions={inputValuesActions}
          />
          {this.showTextPromo()}
          {isCombination && (
            <ProgressIndicatorLinear
              counterText={this.translations.REMAINING_AMOUNT}
              counterValue={calculatedValue || currencyUtil.parseAmount('0', currency)}
              progressPercentage={
                this.state.value ? parseInt(calcPercentage(this.state.value, this.props.data.total), 10) : 0
              }
            />
          )}
        </div>
      </div>
    );
  }

  showTextPromo() {
    const { textInstallment } = this.props;
    return textInstallment?.text ? <Text className="text_promo" {...defaultTextProps(textInstallment)} /> : null;
  }
}

InputSplitAmount.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  invalid: PropTypes.bool,
  isCombination: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  data: PropTypes.shape({
    minAmount: PropTypes.string,
    amount: PropTypes.string,
    total: PropTypes.string,
    min_allowed_amount: PropTypes.string,
    max_allowed_amount: PropTypes.string,
  }),
  paymentMethod: PropTypes.object,
  textInstallment: PropTypes.object,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  inputValuesActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  shouldSaveValue: PropTypes.bool,
  currency: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  hidden_components: PropTypes.arrayOf(PropTypes.object),
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  installmentActions: PropTypes.shape({
    [INSTALLMENT_REQUEST]: PropTypes.func,
  }),
  maxInstallments: PropTypes.number.isRequired,
  inputProps: PropTypes.object,
};

InputSplitAmount.defaultProps = {
  id: '',
  name: '',
  invalid: false,
  error: [],
  errors: {},
  data: {
    minAmount: '0',
    amount: '',
    total: '1',
    min_allowed_amount: 0,
    max_allowed_amount: 0,
  },
  paymentMethod: {
    id: '',
    type: '',
    issuer_name: '',
    min_allowed_amount: '0',
    max_allowed_amount: '0',
  },
  textInstallment: {
    id: '',
    type: '',
    text: '',
  },
  disabled: false,
  step: '',
  savedValue: '',
  shouldSaveValue: true,
  i18n: {
    gettext: (t, i) => t.replace('{0}', i),
  },
  currency: {
    symbol: '$',
    decimal_separator: '.',
    thousands_separator: ',',
    decimal_places: 2,
  },
  hidden_components: [],
  paymentMethods: [],
  installmentActions: {
    [INSTALLMENT_REQUEST]: () => {},
  },
  inputProps: { inputmode: DECIMAL },
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  installmentActions: bindActionCreators(installmentActions, dispatch),
  paymentMethodsActions: bindActionCreators(paymentMethodsActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state, props) => ({
  step: state.page.flow.step,
  issuer: state.creditCard.issuer,
  currency: state.configurations.currency,
  savedValue: state.inputValues[`${state.page.flow.step}_${props.id}`],
  hidden_components: state.page.data.hidden_components,
  paymentMethods: state.paymentMethods,
  firstValue: state.inputValues.current[`${state.page.flow.step}_split_amount_first_method`],
  calculatedValue: state.inputValues.current[`${state.page.flow.step}_split_amount_second_method`],
  maxInstallments: state.installment.maxInstallments,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputSplitAmount;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(InputSplitAmount)));
}
