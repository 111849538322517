/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  BUTTON_CLICK_TRIGGERED,
  CLEAN_BUTTON_ACTIONS,
  BUTTON_REQUEST_TRIGGERED,
} = require('./types');

// Request Actions
const buttonClickTriggered = createAction(BUTTON_CLICK_TRIGGERED);
const buttonRequestTriggered = createAction(BUTTON_REQUEST_TRIGGERED);
const cleanButtonActions = createAction(CLEAN_BUTTON_ACTIONS);

/**
 * Export functions
 */
module.exports = {
  [BUTTON_CLICK_TRIGGERED]: buttonId => (dispatch) => {
    dispatch(buttonClickTriggered(buttonId));
  },
  [CLEAN_BUTTON_ACTIONS]: () => (dispatch) => {
    dispatch(cleanButtonActions());
  },
  [BUTTON_REQUEST_TRIGGERED]: value => (dispatch) => {
    dispatch(buttonRequestTriggered(value));
  },
};
