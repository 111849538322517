const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const { ValidatorProvider } = require('../../utils/validator-provider');
const Button = require('../../containers/Button/ButtonWithStore');
const Brand = require('../Brand');
const Title = require('../Title');
const QrCode = require('../QrCode');
const Text = require('../Text');
const ProgressSpinner = require('../ProgressSpinner');
const ErrorHint = require('../ErrorHint');
const { minsBetweenDates } = require('../../utils/Date');
const { polling, cancelPolling, ERROR_MAX_RETRIES } = require('../../utils/polling');
const ApiService = require('../../service/api');
const Form = require('../Form');
const { submitForm } = require('../../utils/Dom');
const translate = require('../../translation');

const { useEffect, useState, useCallback, useRef } = React;

const POLLING_CONFIG = {
  START_DELAY: 5000, // After this time, the first polling begins
  LAP_DELAY: 4000, // Time between rounds of the same polling
  ATTEMPT_LAPS: 225, // Number of rounds each polling has
  ALLOWED_LAPS_WITH_ERRORS: 3, // Number of error responses that polling allows until it stops
  MAX_ATTEMPTS: 2, // Maximum number of times a new polling cycle can be restarted
};

const QR_STATE = {
  VALID: 'VALID',
  EXPIRED: 'EXPIRED',
};

const EXPIRED_VERIFICATION_TIMEOUT = 5000;

const QrController = ({ expirationDate, i18n, relationId, value, history, price }) => {
  const translations = translate(i18n);
  const [qrState, setQrState] = useState();
  const [attempts, setAttempts] = useState(POLLING_CONFIG.MAX_ATTEMPTS);
  const [minsToExpiration, setMinsToExpiration] = useState(minsBetweenDates(new Date(), expirationDate));
  const formRef = useRef();

  const submitFormWithAction = useCallback((actionName) => {
    const form = document.querySelector(`form#${formRef.current.props.id}`);
    form.id = actionName;
    submitForm(form);
  }, []);

  /** Logic to manage api calls with polling  */
  /** ======================================  */

  const doPollingAttempt = () => {
    setAttempts((prevAttempts) => prevAttempts - 1);
    polling({
      fn: async () => ApiService.getRelationById(relationId),
      stopWhen: ({ data }) => data?.status === 'closed',
      delay: POLLING_CONFIG.LAP_DELAY,
      retries: POLLING_CONFIG.ATTEMPT_LAPS,
      allowedErrors: POLLING_CONFIG.ALLOWED_LAPS_WITH_ERRORS,
    })
      .then((relation) => {
        if (!relation) {
          return;
        }

        submitFormWithAction('complete');
      })
      .catch((error) => {
        if (attempts && error?.message === ERROR_MAX_RETRIES) {
          return;
        }

        submitFormWithAction('complete');
      });
  };

  useEffect(() => {
    const startPollingTimeoutId = setTimeout(() => {
      doPollingAttempt();
    }, POLLING_CONFIG.START_DELAY);

    return () => {
      clearTimeout(startPollingTimeoutId);
      cancelPolling();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Logic to manage visual qr expiration changes  */
  /** ============================================  */

  const handleGenerateNewQr = () => {
    submitFormWithAction('generate_new_qr');
  };

  useEffect(() => {
    let checkMinsTimeoutId;

    const checkIfQrExpired = () => {
      if (qrState === QR_STATE.EXPIRED) {
        cancelPolling();
        return;
      }

      setMinsToExpiration(minsBetweenDates(new Date(), expirationDate));
      checkMinsTimeoutId = setTimeout(() => checkIfQrExpired(), EXPIRED_VERIFICATION_TIMEOUT);
    };

    checkIfQrExpired();

    return () => {
      clearTimeout(checkMinsTimeoutId);
    };
  }, [expirationDate, qrState]);

  useEffect(() => {
    if (minsToExpiration > 0) {
      setQrState(QR_STATE.VALID);
    } else {
      setQrState(QR_STATE.EXPIRED);
    }
  }, [minsToExpiration]);

  /** Logic to manage show/hide and classes for child visual components  */
  /** =================================================================  */

  const show = {
    expired_qr_error: qrState === QR_STATE.EXPIRED,
    generate_new_qr_btn: qrState === QR_STATE.EXPIRED,
    countdown_timer: qrState === QR_STATE.VALID,
    scan_qr_hint: qrState === QR_STATE.VALID,
  };

  const qrCodeClass = qrState === QR_STATE.EXPIRED ? 'expired' : 'valid';

  /** Render  */
  /** =======================================================================  */

  return (
    <div className="qr-controller">
      <div className={`qr-code-wrapper ${qrCodeClass}`}>
        <div className="qr-container">
          <div className="brand-wrapper">
            <Brand />
          </div>
          <QrCode value={value} />
          <div className="price-wrapper">
            <Title text={price} />
          </div>
        </div>
      </div>
      <div className="row">
        {show.expired_qr_error && <ErrorHint text={translations.EXPIRED_CODE_PLEASE_GENERATE_ANOTHER} />}
      </div>
      <div className="row">
        {show.generate_new_qr_btn && (
          <Button
            id="new_code_button"
            kind="loud"
            type="submit"
            text={translations.GENERATE_A_NEW_CODE}
            className="andes-button--medium custom-medium"
            onClick={handleGenerateNewQr}
          />
        )}
      </div>
      <div className="row">
        {show.countdown_timer && (
          <ProgressSpinner text={translations.YOU_HAVE_X_MINUTES_TO_SCAN_IT(minsToExpiration)} />
        )}
      </div>
      <div className="row">
        {show.scan_qr_hint && <Text text={translations.DO_NOT_CLOSE_THIS_SCREEN_UNTIL_YOU_FINISH_THE_PAYMENT} />}
      </div>
      <ValidatorProvider>
        <Form ref={formRef} id="qr_controller_form" key={Math.random()} method="POST" history={history} />
      </ValidatorProvider>
    </div>
  );
};

QrController.propTypes = {
  expirationDate: PropTypes.string.isRequired,
  relationId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

module.exports = injectI18n(QrController);
