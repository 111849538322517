const React = require('react');
const IconWrapper = require('../IconWrapper');

const DefaultCardIcon = () => (
  <IconWrapper className="default-card-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        fillOpacity="0.55"
        d="M8.199 13.001l-4.204-.007.002-1.2 4.204.007-.002 1.2z"
      />
      <path
        fill="#000"
        fillOpacity="0.55"
        fillRule="evenodd"
        d="M17.2 3.997H2.8a1.8 1.8 0 00-1.8 1.8v8.4a1.8 1.8 0 001.8 1.8h14.4a1.8 1.8 0 001.8-1.8v-8.4a1.8 1.8 0 00-1.8-1.8zm-15 10.2v-5.39h15.6v5.39a.6.6 0 01-.6.6H2.8a.6.6 0 01-.6-.6zm0-6.59h15.6v-1.81a.6.6 0 00-.6-.6H2.8a.6.6 0 00-.6.6v1.81z"
        clipRule="evenodd"
      />
    </svg>
  </IconWrapper >
);

module.exports = DefaultCardIcon;
