const { getKycStatus, trackInfo } = require('../service/api');
const { createAppDeepLinkFromClient } = require('./deeplinks');

const generateKycURL = (initiative = '', configurations = { challenge: { kyc: {} } }, callbackUrl) => {
  const encodedCallback = encodeURIComponent(callbackUrl);
  const { kyc } = configurations.challenge;
  const kycURL = `${kyc.base_url}${kyc.base_path}?initiative=${initiative}&callback=${encodedCallback}&layout=nav`;

  trackInfo({ message: `Generated KYC url: ${kycURL}` });

  return kycURL;
};

const generateKycDeepLink = (initiative, callbackUrl) => {
  const loyaltyActivityParams = {
    authentication_mode: 'required',
    url: callbackUrl,
  };

  const loyaltyDeeplink = createAppDeepLinkFromClient('loyalty/webview', loyaltyActivityParams);

  const kycActivityParams = {
    initiative,
    callback: loyaltyDeeplink,
    layout: 'nav',
  };

  const kycDeepLink = createAppDeepLinkFromClient('kyc', kycActivityParams);

  trackInfo({ message: `Generated KYC deep link: ${kycDeepLink}` });

  return kycDeepLink;
};

const getChallengeURL = (initiativeId, configurations, callbackUrl) => {
  const isNativeApp = configurations.isWebview && !!configurations.nativeApp?.name;

  if (isNativeApp) {
    return generateKycDeepLink(initiativeId, callbackUrl);
  }

  return generateKycURL(initiativeId, configurations, callbackUrl);
};

const generatePollingFunction = (initiativeId) => () =>
  getKycStatus(initiativeId).then(
    ({ data = {} } = {}) =>
      /*
       * for the case where we have Backoffice, we need to be sure the user have
       * no pending challenges(has_user_challenges)
       */
      !!(data.is_initiative_compliant || (data.has_backoffice_challenges && !data.has_user_challenges)),
  );
/**
 * ChallengeConfig function that builds login challenge configuration
 * @param initiativeId - the initiative that will be configured for the challenge
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param callbackUrl - desired url to use a return url from challenge
 */
const getConfig = async (initiativeId, challengeRawData, configurations, callbackUrl) => {
  const pollingFunction = generatePollingFunction(initiativeId);

  return Promise.resolve({
    challengeUrl: getChallengeURL(initiativeId, configurations, callbackUrl),
    pollingFunction,
    submitFormId: 'kyc_form',
    popupSetup: {
      width: 768, // min possible width on desktop
      height: 600,
    },
  });
};

/**
 * ChallengeConfig function that builds login challenge configuration for COW initiative
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param callbackUrl - desired url to use a return url from challenge
 */
const kycConfig = async (challengeRawData, configurations, callbackUrl) => {
  const initiativeId = 'mp-cow';

  return getConfig(initiativeId, challengeRawData, configurations, callbackUrl);
};

/**
 * ChallengeConfig function that builds login challenge configuration for subscriptions initiative
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param callbackUrl - desired url to use a return url from challenge
 */
const kycSubscriptionsConfig = async (challengeRawData, configurations, callbackUrl) => {
  const initiativeId = 'recurring-engine';

  return getConfig(initiativeId, challengeRawData, configurations, callbackUrl);
};

/**
 * ChallengeConfig function that builds challenge configuration for COW kyc credits
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configurations - Frontend's environment configurations.
 * @param callbackUrl - desired url to use a return url from challenge
 */
const kycCreditsConfig = async (challengeRawData, configurations, callbackUrl) => {
  const initiativeId = 'cow-credits';

  return getConfig(initiativeId, challengeRawData, configurations, callbackUrl);
};

/**
 * ChallengeConfig function that builds challenge configuration for COW TC/TD MLM Cards
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configurations - Frontend's environment configurations.
 * @param callbackUrl - desired url to use a return url from challenge
 */
const kycPayerCardsConfig = async (challengeRawData, configurations, callbackUrl) => {
  const initiativeId = 'payer-cards-kyc';

  return getConfig(initiativeId, challengeRawData, configurations, callbackUrl);
};

module.exports = {
  generatePollingFunction,
  generateKycURL,
  generateKycDeepLink,
  getChallengeURL,
  kycConfig,
  kycSubscriptionsConfig,
  kycCreditsConfig,
  kycPayerCardsConfig
};
