const React = require('react');

const IconWrapper = require('../IconWrapper');

const Ticket = () => (
  <IconWrapper className="icon-ticket">
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36">
      <g fill="none">
        <path d="M0 0h36v36H0z" />
        <path
          fill="#FFF"
          stroke="currentColor"
          d="M7.65 4.65v24.882l3.017-1.509 3.666 1.834L18 28.023l3.667 1.834 3.666-1.834 3.017 1.509V4.65H7.65z"
        />
        <path
          fill="currentColor"
          d="M11.228 14.952h13.75v2.234h-13.75zm0-4.583h13.75v2.234h-13.75zm0 9.166h3.667v2.234h-3.667zm7.333 0h6.417v2.234H18.56z"
        />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Ticket);
