const React = require('react');
const PropTypes = require('prop-types');

const IconInstructionsBadge = ({ title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="icon-instructions-badge">
    {title
      && <title>{title}</title>}
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-909 -97)">
        <g transform="translate(909 97)">
          <circle cx="15" cy="15" r="15" fill="#0D895C" />
          <circle cx="9" cy="15.5" r="2" fill="#fff" />
          <circle cx="15" cy="15.5" r="2" fill="#fff" />
          <circle cx="21" cy="15.5" r="2" fill="#fff" />
        </g>
      </g>
    </g>
  </svg>
);

IconInstructionsBadge.defaultProps = {
  title: '',
};

IconInstructionsBadge.propTypes = {
  title: PropTypes.string,
};

module.exports = IconInstructionsBadge;
