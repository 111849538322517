const React = require('react');
const PropTypes = require('prop-types');

const GroupAnimatedContent = ({ children }) => (
  <div className="animated-content">
    <div className="animated-content-container">{children}</div>
  </div>
);

GroupAnimatedContent.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GroupAnimatedContent;
