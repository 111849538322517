import type { CardOptionProps as CardOptionItem } from '../CardOption';

import React from 'react';

import CardOption from '../CardOption';

export type CardListProps = {
  items: Array<CardOptionItem & { id: string }>;
};

export default function CardList({ items }: CardListProps) {
  return (
    <div className="card-list">
      {items.map((item: CardOptionItem, index: number) => (
        <React.Fragment key={`${item.title}-${index}`}>
          <CardOption {...item} />
        </React.Fragment>
      ))}
    </div>
  );
}
