const React = require('react');

const IconWrapper = require('../IconWrapper');

const Store = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g fill="none" fillRule="evenodd" transform="translate(3 8)">
        <path stroke="currentColor" strokeWidth="1.1" d="M3.107 0h24.786a2 2 0 012 2v17.929H1.107V2a2 2 0 012-2z" />
        <path fill="#99D8F4" d="M6 5.536h5a1 1 0 011 1v13.393H5V6.536a1 1 0 011-1z" />
        <rect width="10" height="6.643" x="16" y="5.536" fill="#99D8F4" rx="1" />
        <path stroke="currentColor" strokeLinecap="round" strokeWidth="1.1" d="M0 19.929h31" />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Store);
