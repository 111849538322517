const React = require('react');

const IconWrapper = require('../IconWrapper');

const ShippingType = () => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.763 17.266a2.398 2.398 0 01-4.726 0H4.8a1.8 1.8 0
        01-1.8-1.8V7.254a1.8 1.8 0 011.8-1.8h8.445a1.8 1.8 0 011.8
        1.8v.568l3.322.036L21 11.88v5.386h-2.394a2.399 2.399 0 01-4.727
        0zm5.48-1.604a1.197 1.197 0 10-.001 2.395 1.197 1.197 0 00.001-2.395zm-7.843
        0a1.198 1.198 0 100 2.396 1.198 1.198 0 000-2.396zm4.845-9.008H4.8a.6.6 0
        00-.6.6v8.212a.6.6 0 00.6.6h1.337a2.397 2.397 0 014.526 0h3.182V7.254a.6.6
        0 00-.6-.6zm1.8 2.369v5.759a2.4 2.4 0 013.461 1.284H19.8v-3.828l-2.086-3.187-2.669-.028z"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(ShippingType);
