/* eslint-disable react/no-did-mount-set-state */
/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindEventListener } = require('../../../utils/Dom');

class GroupTotalSummary extends React.Component {
  constructor(props) {
    super(props);
    this.stickyEnabled = props.deviceType === 'mobile';
    this.handleScroll = this.handleScroll.bind(this);
    this.topHeight = 0;
    this.state = {
      isFixed: false,
      containerCustomStyles: {},
      summaryCustomStyles: {},
    };
  }

  componentDidMount() {
    if (!this.stickyEnabled) {return;}
    this.setBaseHeight();
    this.handleScroll();
    bindEventListener(window, 'scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (!this.stickyEnabled) {return;}
    window.removeEventListener('scroll', this.handleScroll);
  }

  setBaseHeight() {
    const totalSummary = this.getElemByClass('group-total-summary');
    this.topHeight = totalSummary.offsetTop;
    this.setState({
      summaryCustomStyles: {
        ...this.state.summaryCustomStyles,
      },
    });
  }

  getElemByClass(name, component) {
    return (component || document).getElementsByClassName(name)[0];
  }

  getElemByTag(name, component) {
    return (component || document).getElementsByTagName(name)[0];
  }

  handleScroll() {
    const totalSummary = this.getElemByClass('group-total-summary');
    const hr = this.getElemByTag('hr', totalSummary);
    const hrPadding = 19;
    const totalSummaryMargin = 24;
    const hrOffsetTop = hr.offsetTop + hrPadding;

    const { isFixed } = this.state;

    if (window.pageYOffset >= hrOffsetTop && !isFixed) {
      this.setState({
        containerCustomStyles: {
          ...this.state.containerCustomStyles,
          position: 'fixed',
          top: `${-hrOffsetTop + this.topHeight}px`,

        },
        isFixed: true,
        summaryCustomStyles: {
          ...this.state.summaryCustomStyles,
          height: `${totalSummary.offsetHeight + totalSummaryMargin}px`,
        },
      });
    }
    if (window.pageYOffset < hrOffsetTop && isFixed) {
      this.setState({
        containerCustomStyles: {
          ...this.state.containerCustomStyles,
          position: 'relative',
          top: 'unset',
        },
        isFixed: false,
        summaryCustomStyles: {
          ...this.state.summaryCustomStyles,
          height: 'unset',
        },
      });
    }
  }

  render() {
    const { summaryCustomStyles, containerCustomStyles } = this.state;
    return (
      <div className="group-total-summary" style={summaryCustomStyles}>
        <div className="group-total-summary__container" style={containerCustomStyles}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

GroupTotalSummary.defaultProps = {
  deviceType: '',
};

GroupTotalSummary.propTypes = {
  deviceType: PropTypes.string,
};

module.exports = GroupTotalSummary;
