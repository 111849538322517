/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  CREDIT_CARD_GUESSING,
  CREDIT_CARD_PC_NUMBER,
  CREDIT_CARD_PC_NAME,
  CREDIT_CARD_PC_EXPIRATION,
  CREDIT_CARD_PC_SECURITY,
  CREDIT_CARD_ISSUER,
  CREDIT_CARD_CLEAN,
} = require('./types');

// Request Actions
const cardGuessing = createAction(CREDIT_CARD_GUESSING);
const paymentCardChangeNumber = createAction(CREDIT_CARD_PC_NUMBER);
const paymentCardChangeName = createAction(CREDIT_CARD_PC_NAME);
const paymentCardChangeExpiration = createAction(CREDIT_CARD_PC_EXPIRATION);
const paymentCardChangeSecurity = createAction(CREDIT_CARD_PC_SECURITY);
const cardChangeIssuer = createAction(CREDIT_CARD_ISSUER);
const cleanCreditCard = createAction(CREDIT_CARD_CLEAN);

/**
 * Export functions
 */
module.exports = {
  [CREDIT_CARD_GUESSING]: (data) => (dispatch) => {
    dispatch(cardGuessing({ data }));
  },
  [CREDIT_CARD_PC_NUMBER]: (number) => (dispatch) => {
    dispatch(paymentCardChangeNumber({ number }));
  },
  [CREDIT_CARD_PC_NAME]: (name) => (dispatch) => {
    dispatch(paymentCardChangeName({ name }));
  },
  [CREDIT_CARD_PC_EXPIRATION]: (expiration) => (dispatch) => {
    dispatch(paymentCardChangeExpiration({ expiration }));
  },
  [CREDIT_CARD_PC_SECURITY]: (security) => (dispatch) => {
    dispatch(paymentCardChangeSecurity({ security }));
  },
  [CREDIT_CARD_ISSUER]: (value) => (dispatch) => {
    dispatch(cardChangeIssuer({ value }));
  },
  [CREDIT_CARD_CLEAN]: () => (dispatch) => {
    dispatch(cleanCreditCard());
  },
};
