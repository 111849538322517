const React = require('react');
const PropTypes = require('prop-types');

const Login = (props) => {
  const classNames = 'login';

  return (
    <div className={classNames}>
      {props.children}
    </div>
  );
};

Login.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.string,
};

Login.defaultProps = {
  className: '',
  kind: '',
};

module.exports = Login;
