const IframeController = require('../../../utils/IframeController')(true);

const breakpoints = {
  1: 335,
  2: 410,
  3: 495,
  fullSize: 1200,
};

module.exports = (props) => {
  let breakpoint = '';
  if (props.collapsible.installments_collapsible_selector || props.collapsible.payment_option_selector || props.collapsible.wallet_summary) {
    breakpoint = 'fullSize';
  } else {
    const inputsVisibles = (props.inputValues.visibility.wallet_payment_installments_collapsible_selector || false)
    + (props.inputValues.visibility.wallet_payment_cvv || false)
    + (props.inputValues.visibility.wallet_payment_payment_options_selector || false);
    breakpoint = inputsVisibles.toString();
  }

  IframeController.resize(breakpoints[breakpoint]);
};
