const React = require('react');

const IconWrapper = require('../IconWrapper');
const BoletoBancario = require('@cow/core-components/components/Icons/BoletoBancario');

const Boleto = () => (
  <IconWrapper>
    <BoletoBancario className="icon-boleto" />
  </IconWrapper>
);

module.exports = React.memo(Boleto);
