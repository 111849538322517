const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconSecureLock = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="47" viewBox="0 0 42 47">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path stroke="#0DB478" strokeWidth="2" d="M11 13V9a9 9 0 0 1 18 0v4" />
        <rect
          width="40"
          height="32"
          y="13"
          stroke="#0DB478"
          strokeWidth="2"
          rx="2"
        />
        <circle cx="20" cy="29" r="8" fill="#7CD1A5" fillOpacity="0.7" />
        <g transform="translate(17 24)">
          <circle cx="3" cy="3" r="3" fill="#0DB478" />
          <path
            stroke="#0DB478"
            strokeLinecap="round"
            strokeWidth="2"
            d="M3 3v5.33"
          />
        </g>
      </g>
    </svg>
  </IconWrapper>
);

module.exports = IconSecureLock;
