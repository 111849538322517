const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const IconMoreOptions = ({ color }) => (
  <IconWrapper className="icon-more-options">
    <svg className="with-custom-color" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.994 13.6c.662 0 1.2.537 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.663 0-1.2-.538-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.537 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.538 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.662.537-1.2 1.2-1.2z"
      />
    </svg>
  </IconWrapper>
);

IconMoreOptions.defaultProps = {
  color: 'currentColor',
};

IconMoreOptions.propTypes = {
  color: PropTypes.string,
};

module.exports = React.memo(IconMoreOptions);
