const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const Cash = ({color}) => (
  <IconWrapper>
    <svg className="with-custom-color" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.002 4.004v11.981h-18V4.004h18zm-1.2 1.2h-15.6v9.581h15.6V5.204zm-7.8 2.354a2.436 2.436 0 110 4.873 2.436 2.436 0 110-4.873zm0 1.2a1.236 1.236 0 10-.001 2.473 1.236 1.236 0 00.001-2.473zm4.806.03a1.206 1.206 0 11-.002 2.41 1.206 1.206 0 01.002-2.409zm-9.614 0a1.206 1.206 0 110 2.41 1.206 1.206 0 010-2.409z"
      />
    </svg>
  </IconWrapper>
);

Cash.propTypes = {
  color: PropTypes.string,
};

Cash.defaultProps = {
  color: 'currentColor',
};

module.exports = React.memo(Cash);
