function debounce(func, delay) {
  let timeoutId;

  return function fn(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

module.exports = debounce;
