module.exports = {
  PAYMENT_TYPE_METHOD: {
    account_money: 'account_money',
    digital_currency: 'consumer_credits',
    digital_wallet: 'paypal',
    bank_transfer: ['pix', 'webpay', 'pse', 'debin_transfer'],
    credit_card: [
      'visa',
      'master',
      'amex',
      'naranja',
      'tarshop',
      'cabal',
      'cencosud',
      'diners',
      'argencard',
      'cordobesa',
      'cmr',
      'visa',
      'oca',
      'master',
      'diners',
      'lider',
      'creditel',
      'visa',
      'master',
      'amex',
      'magna',
      'presto',
      'hipercard',
      'elo',
    ],
    debit_card: ['maestro', 'debmaster', 'debcabal', 'debvisa', 'debelo', 'debvisa', 'redcompra', 'yape'],
    ticket: [
      'pagofacil',
      'rapipago',
      'bolbradesco',
      'pec',
      'efecty',
      'baloto',
      'oxxo',
      'paycash',
      'abitab',
      'redpagos',
    ],
    atm: ['banamex', 'serfin', 'bancomer', 'pagoefectivo_atm'],
  },
  PAYMENT_STATUS_METHOD: {
    approved: 'accredited',
    cancelled: ['by_payer', 'by_collector'],
    pending: ['pending_waiting_transfer', 'phone_verification', 'pending_waiting_payment'],
    rejected: [
      'cc_rejected_other_reason',
      'cc_rejected_max_attempts',
      'cc_rejected_invalid_installments',
      'cc_rejected_insufficient_amount',
      'cc_rejected_high_risk',
      'cc_rejected_duplicated_payment',
      'cc_rejected_card_type_not_allowed',
      'cc_rejected_card_disabled',
      'cc_rejected_call_for_authorize',
      'cc_rejected_blacklist',
      'cc_rejected_bad_filled_security_code',
      'cc_rejected_bad_filled_date',
      'cc_rejected_bad_filled_other',
      'cc_rejected_bad_filled_card_number',
      'cc_amount_rate_limit_exceeded',
      'rejected_by_bank',
      'rejected_by_regulations',
      'rejected_high_risk',
      'rejected_other_reason',
    ],
    failed: 'offline_payment_creation_failed',
    in_process: ['pending_contingency', 'pending_review_manual'],
  },
  TIMEOUT: {
    /** 3 seconds */
    SHORT: 3_000,
    /** 5 seconds */
    MEDIUM: 5_000,
    /** 10 seconds */
    LONG: 10_000,
  },
};
