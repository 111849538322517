const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const {
  COMMONS: { ENVIROMENT },
} = require('../../constants');

const connectToReduxAndInjectI18n = (component, mapStateToProps, mapDispatchToProps) =>
  process.env.NODE_ENV === ENVIROMENT.TEST
    ? component
    : connect(mapStateToProps, mapDispatchToProps)(injectI18n(component));

module.exports = connectToReduxAndInjectI18n;
