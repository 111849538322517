const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');

const OptionList = require('../OptionsList');
const BlueChevron = require('../icons/BlueChevron');

const SelectBounded = ({ className, label, options, minimunVisible, component, i18n }) => {
  const translations = translate(i18n);
  const [displayAll, setDisplayAll] = React.useState(false);

  const maxElements = options.length > minimunVisible ? options.length : minimunVisible;
  const maxElementsToDisplay = displayAll ? maxElements : minimunVisible;
  const wording = label || translations.MORE_SAVED_CARDS;
  const sliceMaxElementsToDisplay = options.slice(0, maxElementsToDisplay);

  return (
    <>
      <OptionList
        className={className}
        options={sliceMaxElementsToDisplay}
        component={component}
        maxElements={(!displayAll && maxElements !== minimunVisible)}
      />
      {maxElements > minimunVisible && (
        <div
          aria-hidden="true"
          onClick={() => setDisplayAll(true)}
          className={`more-cards ui-card ${!displayAll ? 'visible' : 'hidden'}`}
        >
          <span className="more-cards__label">{wording}</span>
          <BlueChevron />
        </div>
      )}
    </>
  );
};

SelectBounded.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  minimunVisible: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
  ).isRequired,
  component: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
};

SelectBounded.defaultProps = {
  i18n: {
    gettext: (text) => text,
  },
  minimunVisible: 4,
  component: null,
  className: '',
  label: '',
};

module.exports = injectI18n(SelectBounded);
