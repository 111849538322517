const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const GroupRedirectDescription = ({ dynamicDescription, children, blockedPopup }) => {
  if (blockedPopup) {
    return null;
  }
  const redirectDoneChildren = children.filter && children.filter(element => element.props.id === 'group_redirect_done');
  const redirectInProgressChildren = children.filter && children.filter(element => element.props.id !== 'group_redirect_done');

  return (
    <div className="group-redirect-description">
      { dynamicDescription ? redirectDoneChildren : redirectInProgressChildren }
    </div>
  );
};

GroupRedirectDescription.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dynamicDescription: PropTypes.bool,
  blockedPopup: PropTypes.bool,
};

GroupRedirectDescription.defaultProps = {
  children: null,
  dynamicDescription: false,
  blockedPopup: false,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  dynamicDescription: state.transitionRedirect.dynamicDescription,
  blockedPopup: state.transitionRedirect.blockedPopup,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = GroupRedirectDescription;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(GroupRedirectDescription);
}
