/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const IdentificationCard = require('identification-card-component');

/**
 * Button
 */
const IdentificationCardWrapper = props => (
  <IdentificationCard
    name={props.name}
    number={props.identificationCard.number}
    placeHolder={{ number: '********' }}
  />
);

/**
 * Prop Types
 */
IdentificationCardWrapper.propTypes = {
  name: PropTypes.string,
  identificationCard: PropTypes.shape({
    focus: PropTypes.bool,
    number: PropTypes.string,
    type: PropTypes.string,
    type_data: PropTypes.object,
  }),
};

/**
 * Default Props
 */
IdentificationCardWrapper.defaultProps = {
  name: '',
  identificationCard: {
    focus: false,
    number: 0,
    type: '',
    type_data: {},
  },
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  identificationCard: state.identificationCard,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = IdentificationCardWrapper;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(IdentificationCardWrapper); // eslint-disable-line max-len
}
