const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const IconWrapper = require('../IconWrapper');
const Visa = require('./icon/visa');
const Amex = require('./icon/amex');
const Master = require('./icon/master');
const Mobile = require('../icons/Mobile');
const Grey = require('./icon/grey');

const CardCvv = (props) => {
  const { cardId, size, text, paymentMethod, show, iconType } = props;
  const iconClassName = cardId === 'virtual' ? 'icon-card-cvv icon-card-cvv--virtual' : 'icon-card-cvv';

  const getCardId = () => cardId || iconType || paymentMethod.id;

  const getText = (originalText) => {
    const filteredText = originalText.replace(/\D+/g, '');
    const maxLength = getCardId() === 'amex' ? 4 : 3;
    return filteredText.length >= maxLength ?
      filteredText :
      filteredText + new Array(maxLength - filteredText.length + 1).join('*');
  }

  const getCardSvg = () => {
    const iconProps = {
      size,
      text: getText(text),
    };
    const paymentCardId = getCardId();

    const getcardSvg = {
      'visa': <Visa {...iconProps} />,
      'master': <Master {...iconProps} />,
      'amex': <Amex {...iconProps} />,
      'virtual': <Mobile {...iconProps} />,
      'default': <Visa {...iconProps} />,
      'grey': <Grey />,
    }

    return getcardSvg[paymentCardId] || getcardSvg.default;
  }

  if (!show) {
    return null;
  }
  return (
    <IconWrapper className={iconClassName}>
      {getCardSvg()}
    </IconWrapper>
  );
}

CardCvv.propTypes = {
  cardId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  show: PropTypes.bool,
  paymentMethod: PropTypes.object, // eslint-disable-line
  visibility: PropTypes.bool,
};

CardCvv.defaultProps = {
  cardId: '',
  text: '',
  size: '',
  paymentMethod: {},
  show: true,
};
/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state) => ({
  text: state.inputValues.current[`${state.page.flow.step}_cvv`] || '',
  paymentMethod: state.creditCard.guessing.paymentMethod || state.paymentMethod.paymentMethod || {},
});

if (process.env.NODE_ENV === 'test') {
  module.exports = CardCvv;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(CardCvv);
}
