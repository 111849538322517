const React = require('react');
const PropTypes = require('prop-types');

const { Snackbar: SnackbarComponent } = require('@andes/snackbar');

const Snackbar = props => (
  <SnackbarComponent
    message={props.message}
    color={props.status}
    delay={props.delay}
    show
  />
);

Snackbar.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
  delay: PropTypes.number,
};

Snackbar.defaultProps = {
  message: '',
  status: '',
  delay: 3000,
};

module.exports = Snackbar;
