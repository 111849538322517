/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  SAVE_PAYMENT_METHOD,
  SAVE_INSTALLMENT,
} = require('./types');

// Request Actions
const savePaymentMethod = createAction(SAVE_PAYMENT_METHOD);
const saveInstallment = createAction(SAVE_INSTALLMENT);

/**
 * Export functions
 */
module.exports = {
  [SAVE_PAYMENT_METHOD]: paymentMethod => (dispatch) => {
    dispatch(savePaymentMethod({ paymentMethod }));
  },
  [SAVE_INSTALLMENT]: installment => (dispatch) => {
    dispatch(saveInstallment({ installment }));
  },
};
