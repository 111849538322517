const React = require('react');
const { FormProvider } = require('checkout-off-form-validation');
const FormYape = require('../../../components/FormYape');
const FormPersonalInfo = require('../../../components/FormPersonalInfo');
const FormAddressInfo = require('../../../components/FormAddressInfo');

module.exports = {
  form_yape: (props) => (
    <FormProvider>
      <FormYape {...props} />
    </FormProvider>
  ),
  form_personal_info: (props) => (
    <FormProvider>
      <FormPersonalInfo {...props} />
    </FormProvider>
  ),
  form_address_info: (props) => (
    <FormProvider>
      <FormAddressInfo {...props} />
    </FormProvider>
  ),
};
