/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  SAVE_OPTIONS,
  OPTIONS_PAGE,
} = require('./types');

// Request Actions
const saveOptions = createAction(SAVE_OPTIONS);
const optionsPage = createAction(OPTIONS_PAGE);

/**
 * Export functions
 */
module.exports = {
  [SAVE_OPTIONS]: (select, value) => (dispatch) => {
    dispatch(saveOptions({ select, value }));
  },
  [OPTIONS_PAGE]: value => dispatch => dispatch(optionsPage({ optionsPage: value })),
};
