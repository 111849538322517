const React = require('react');
const PropTypes = require('prop-types');

const IconQuestionMark = require('../icons/QuestionMark');

const RowDiscount = (props) => {
  let textLength = 0;
  const rowContent = props.value.map(({ type, text }) => {
    if (type === 'text' || type === 'price' || type === 'discount') {
      textLength += text.length;
      return (
        <span
          className={`row-summary__${type}`}
          key={Math.random()}
        >
          {text}
        </span>
      );
    } if (type === 'instructions') {
      return <IconQuestionMark />;
    }
  });
  // TODO: We should create some kind of generic resize rule
  const size = textLength > 24 && props.value.length > 2 ? 'small' : 'large';
  return (
    <div className={`row-summary--discount ${size}`}>
      {rowContent}
    </div>
  );
};

RowDiscount.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    installment: PropTypes.shape({
      label: PropTypes.string,
    }),
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
      })),
      PropTypes.string,
    ]),
  })).isRequired,
};

RowDiscount.defaultProps = {
  value: [],
};

module.exports = RowDiscount;
