/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  INSTALLMENT_UPDATE,
  INSTALLMENT_CLEAN,
  INSTALLMENT_REQUEST,
  INSTALLMENT_UPDATE_COMBINATION,
  CREDITS_INSTALLMENT_UPDATE_COMBINATION,
  CREDITS_INSTALLMENT_UPDATE,
  CREDITS_INSTALLMENT_CLEAN,
} = require('../actions/types');

const actions = {};

/**
 * The initial state for the installment reducer.
 * @typedef {Object} InitialState
 * @property {string} label - The label for the installment.
 * @property {string} value - The value of the installment.
 * @property {string} total - The total amount of the installment.
 * @property {number} maxInstallments - The maximum number of installments allowed. This is optional.
 */
const initialState = {
  label: '',
  value: '',
  total: '',
  maxInstallments: undefined,
};

actions[INSTALLMENT_UPDATE] = (state, action) => ({
  maxInstallments: state.maxInstallments,
  label: action.payload.label,
  value: action.payload.value,
  total: action.payload.total,
  totalPurchase: action.payload.totalPurchase,
  interestFree: action.payload.interestFree,
  totalWithInterest: action.payload.totalWithInterest,
  interestAmount: action.payload.interestAmount,
});

actions[INSTALLMENT_CLEAN] = (state) => ({
  maxInstallments: state.maxInstallments,
  label: '',
  value: '',
  total: '',
  totalPurchase: '',
});

actions[CREDITS_INSTALLMENT_UPDATE] = (state, action) => ({
  maxInstallments: state.maxInstallments,
  label: action.payload.label,
  value: action.payload.value,
  total: action.payload.total,
  detail: action.payload.detail,
  contractUrl: action.payload.contractUrl,
  tycUrl: action.payload.tycUrl,
  totalPurchase: action.payload.totalPurchase,
  interestFree: action.payload.interestFree,
});

actions[CREDITS_INSTALLMENT_CLEAN] = (state) => ({
  maxInstallments: state.maxInstallments,
  label: '',
  value: '',
  total: '',
  detail: '',
  contractUrl: '',
  tycUrl: '',
  totalPurchase: '',
});

actions[INSTALLMENT_REQUEST] = (state, action) => ({
  maxInstallments: state.maxInstallments,
  installmentRequest: action.payload.installment_information
});

actions[INSTALLMENT_UPDATE_COMBINATION] = (state, action) => ({
  maxInstallments: state.maxInstallments,
  label: action.payload.label,
  value: action.payload.value,
  total: action.payload.total,
  totalPurchase: action.payload.totalPurchase,
  interestFree: action.payload.interestFree,
  installmentRequest: action.payload.installmentRequest,
});

actions[CREDITS_INSTALLMENT_UPDATE_COMBINATION] = (state, action) => ({
  maxInstallments: state.maxInstallments,
  label: action.payload.label,
  value: action.payload.value,
  total: action.payload.total,
  detail: action.payload.detail,
  contractUrl: action.payload.contractUrl,
  tycUrl: action.payload.tycUrl,
  totalPurchase: action.payload.totalPurchase,
  interestFree: action.payload.interestFree,
  installmentRequest: action.payload.installmentRequest,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
