const React = require('react');
const PropTypes = require('prop-types');

const Congrats = ({ id, kind, children, className }) => {
  let classNames = 'congrats';

  if (className) {
    classNames += ` ${className}`;
  }

  if (kind) {
    classNames += ` congrats--${kind}`;
  }

  return (
    <div id={id} className={classNames}>
      {children}
    </div>
  );
};

Congrats.propTypes = {
  id: PropTypes.string,
  kind: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

Congrats.defaultProps = {
  id: '',
  kind: '',
  children: null,
  className: '',
};

module.exports = Congrats;
