const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconOpenFinance = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5314 3.96034C13.6779 3.819 13.8755 3.74325 14.0789 3.75047C17.5038 3.87207 19.9921 5.71516 21.6273 8.62737C25.4989 15.2559 20.5823 23.8929 12.8119 23.7482C10.2398 23.7455 8.63554 23.1136 7.13912 22.126C6.95948 22.0074 6.83943 21.8174 6.80953 21.6042C6.77962 21.3911 6.84273 21.1753 6.98281 21.0119L9.98281 17.5119C10.1134 17.3595 10.2996 17.2659 10.4998 17.2518C10.7 17.2378 10.8975 17.3046 11.0481 17.4373C11.4945 17.8306 12.1929 18.0108 13.033 17.857C13.8678 17.7043 14.7898 17.2251 15.5755 16.3862L15.5764 16.3852C16.7115 15.1777 17.1092 13.5371 16.8152 12.1966C16.5301 10.8965 15.6074 9.88314 13.9895 9.74737C13.601 9.71477 13.3023 9.3899 13.3023 9V4.5C13.3023 4.29647 13.385 4.10168 13.5314 3.96034ZM12.8583 22.2488C12.8485 22.2484 12.8386 22.2482 12.8287 22.2482C10.9871 22.2482 9.76902 21.8954 8.69797 21.3158L10.686 18.9964C11.4642 19.4066 12.3922 19.4992 13.303 19.3325C14.4805 19.117 15.6839 18.4645 16.6698 17.4121C18.1365 15.8514 18.6826 13.7089 18.2804 11.8752C17.9113 10.1922 16.7337 8.76902 14.8023 8.35395V5.31428C17.2194 5.63685 19.0289 7.06071 20.3224 9.36704L20.3222 9.3671L20.3291 9.37887C23.625 15.0131 19.4255 22.357 12.8583 22.2488Z"
        fill="#009EE3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2039 0.526773C11.346 0.637538 11.4307 0.80625 11.4348 0.986321L11.6193 9.13416C11.6261 9.43253 11.4125 9.69051 11.1181 9.73958C11.1228 9.73879 11.1253 9.73834 11.1254 9.73852C11.1255 9.73916 11.0925 9.7484 11.0158 9.78127C10.9337 9.81644 10.8329 9.86495 10.7264 9.92062C10.5034 10.0371 10.3064 10.158 10.2252 10.2211C9.98635 10.4069 9.64654 10.3858 9.43257 10.1718L9.25725 9.99647C9.22919 9.96841 9.20434 9.93803 9.18281 9.90587L4.60946 4.90482C4.39844 4.67406 4.40003 4.31992 4.6131 4.09106L4.8558 3.83038C6.32474 2.15121 8.63289 0.802304 10.5117 0.45808L10.702 0.414828C10.8776 0.37491 11.0619 0.416008 11.2039 0.526773ZM9.95639 8.9736C10.0283 8.93286 10.1009 8.89347 10.1707 8.857C10.2502 8.81548 10.3313 8.77503 10.4101 8.73811L10.2517 1.74855C8.79274 2.15165 7.04969 3.20233 5.866 4.50067L9.95639 8.9736Z"
        fill="#009EE3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34377 6.17384C3.5112 6.15721 3.67792 6.21163 3.80328 6.32385L8.79043 10.7882C8.98413 10.9616 9.04412 11.24 8.93904 11.4778C8.60622 12.2309 8.60571 12.2322 8.49285 12.5202L8.4875 12.5338C8.38608 12.7926 8.1211 12.9465 7.84837 12.9095L2.11252 13.1451C1.94926 13.1518 1.79032 13.0916 1.67243 12.9784C1.55454 12.8653 1.4879 12.709 1.4879 12.5456V12.4376C1.37302 11.3027 1.36015 10.3961 1.51268 9.54077C1.66782 8.67087 1.98727 7.89111 2.48763 7.0011C2.50042 6.97835 2.51468 6.95646 2.53032 6.93557L2.92277 6.41133C3.0236 6.27664 3.17635 6.19047 3.34377 6.17384ZM2.64556 11.9221L7.52144 11.7219C7.55636 11.6399 7.60011 11.5397 7.66532 11.3916L3.49498 7.65844C3.06106 8.44102 2.81481 9.07427 2.69404 9.75145C2.58521 10.3617 2.57323 11.0355 2.64556 11.9221Z"
        fill="#009EE3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39658 14.3714C8.39656 14.3713 8.39654 14.3712 8.39652 14.3711C8.39651 14.3711 8.39651 14.3711 8.39651 14.371C8.39648 14.3709 8.39644 14.3707 8.39641 14.3706C8.334 14.0644 8.04698 13.8573 7.73665 13.8947L2.45855 14.5314C2.27679 14.5534 2.11498 14.6571 2.01915 14.8131C1.92333 14.9691 1.90397 15.1603 1.96659 15.3323L1.99657 15.4147C2.53774 17.2811 3.41038 18.2963 4.13807 19.143C4.22345 19.2423 4.30683 19.3393 4.38745 19.4351C4.39291 19.4416 4.3985 19.448 4.40423 19.4542L4.60723 19.6757C4.77443 19.8581 5.03644 19.9191 5.26704 19.8294C5.36956 19.7895 5.45652 19.7235 5.5215 19.6407L8.80127 16.5512C8.99406 16.3696 9.04497 16.083 8.92651 15.8461C8.7329 15.4589 8.59893 15.0854 8.51347 14.8076C8.47095 14.6695 8.44098 14.5568 8.42196 14.4802C8.41247 14.4419 8.40573 14.4128 8.40157 14.3942L8.39722 14.3744L8.39658 14.3714ZM5.08502 18.4033L7.66407 15.9738C7.53305 15.6671 7.43575 15.3855 7.36654 15.1606C7.36531 15.1565 7.36408 15.1525 7.36286 15.1485L3.33013 15.635C3.79017 16.8926 4.42068 17.6282 5.04863 18.3608C5.06076 18.375 5.07289 18.3891 5.08502 18.4033Z"
        fill="#009EE3" />
    </svg>
  </IconWrapper>
);

module.exports = IconOpenFinance;
