/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  REQUEST_CAPTCHA_TOKEN,
  SAVE_CAPTCHA_TOKEN,
} = require('../actions/types');

const actions = {};

const initialState = {
  blockedByCaptcha: false,
  captchaToken: '',
};

actions[REQUEST_CAPTCHA_TOKEN] = (state, action) => ({
  ...state,
  blockedByCaptcha: action.payload.requestCaptcha,
});

actions[SAVE_CAPTCHA_TOKEN] = (state, action) => ({
  ...state,
  captchaToken: action.payload.captchaToken,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
