import type { NavbarMenuOptionsProps } from '../types';

import React, { useCallback, useEffect, useState } from 'react';
import { DropdownItem, DropdownStandalone } from '@andes/dropdown';
import User20 from '@andes/icons/User20';
import { Thumbnail } from '@andes/thumbnail';
import { Typography } from '@andes/typography';

import { MenuOptionsValues, Theme } from '../../../constants/commons';
import ExitIcon from '../../Icons/Exit';
import { spellNumber } from '../../../utils/accesibility';

const MenuOptions = ({
  changeUser,
  logOut,
  menuOptions,
  setOpenOverlay,
  user,
  theme,
  altOpenedMenu = '',
  altClosedMenu = '',
  isMobile,
}: NavbarMenuOptionsProps) => {
  const [arialLabel, setArialLabel] = useState(altClosedMenu);
  const [open, setOpen] = useState(false);

  // TODO: Remove this when the Dropdown no longer has the behavior of hiding the scroll.
  useEffect(() => {
    if (
      open &&
      (window.scrollY > 0 ||
        window.innerHeight < document.documentElement.scrollHeight)
    ) {
      document.documentElement.style.setProperty(
        'overflow-y',
        isMobile ? 'hidden' : 'scroll',
        'important',
      );
      document.documentElement.style.setProperty(
        'padding-right',
        '0px',
        'important',
      );
    }
  }, [open]);

  const getAvatar = useCallback(() => {
    if (user.avatar) {
      return (
        <img
          aria-hidden="true"
          src={user.avatar}
          alt={user.fullName}
          title={user.fullName}
        />
      );
    }

    const firstInitial = user.firstname.charAt(0) || '';
    const secondInitial = user.lastname?.charAt(0) || '';

    return (
      // TODO: This is set like this due to an override of Andes' aria-hidden.
      <span
        ref={(el) => el && el.setAttribute('aria-hidden', 'true')}
      >{`${firstInitial}${secondInitial}`}</span>
    );
  }, [user.avatar, user.fullName, user.firstname, user.lastname]);
  const handleDropdown = (openOverlay: boolean) => {
    setArialLabel(openOverlay ? altOpenedMenu : altClosedMenu);

    setOpen(openOverlay);
    setOpenOverlay(openOverlay);
  };

  const handleChange = (
    event: React.KeyboardEvent | React.MouseEvent,
    value: Array<string> | string | undefined,
  ): void => {
    if (value === MenuOptionsValues.CHANGE_USER) {
      changeUser?.();
    } else if (value === MenuOptionsValues.LOGOUT) {
      logOut?.();
    }
  };

  const hasLogout = useCallback((): JSX.Element => {
    if (theme === Theme.ML || theme === Theme.MP) {
      // DropdownItem is returned because DropdownStanAlone does not allow additional components; therefore, neither null, &&, nor ?? are used.
      return <DropdownItem value="" disabled></DropdownItem>;
    }

    return (
      <DropdownItem
        className="cow-navbar__dropdown-actions"
        value={MenuOptionsValues.LOGOUT}
      >
        <ExitIcon />
        <Typography size="m" color="primary">
          {menuOptions.logOutUserLabel}
        </Typography>
      </DropdownItem>
    );
  }, [theme, menuOptions]);

  return (
    <>
      <DropdownStandalone
        className="cow-navbar__dropdown"
        label={user.firstname}
        srLabel={arialLabel}
        onOpen={() => handleDropdown(true)}
        onClose={() => handleDropdown(false)}
        menuAlignment="bottom"
        onChange={handleChange}
      >
        <DropdownItem className="cow-navbar__dropdown-account" value="account">
          <Thumbnail size="40">{getAvatar()}</Thumbnail>
          <div className="cow-navbar__dropdown-account-info">
            <Typography size="s" color="secondary">
              {menuOptions.title}
            </Typography>
            <Typography
              aria-label={spellNumber(user.email)}
              size="m"
              color="primary"
            >
              {user.email}
            </Typography>
          </div>
        </DropdownItem>
        <DropdownItem
          className="cow-navbar__dropdown-actions"
          value={MenuOptionsValues.CHANGE_USER}
        >
          <User20 />
          <Typography size="m" color="primary">
            {menuOptions.changeUserLabel}
          </Typography>
        </DropdownItem>
        {hasLogout()}
      </DropdownStandalone>
    </>
  );
};

export default MenuOptions;
