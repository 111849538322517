class Mask {
  /**
   * Represents a mask that can be applied to a value.
   * @param {string} mask - mask to be applied, eg: ___.___.___-__ (CPF) or ____ ____ ____ ____ (visa)
   * @param {Object} blank - char to be replaced in mask
   */
  constructor(mask, blank = '_') {
    if (!mask) {
      throw new TypeError('Invalid mask');
    }

    // Masks to be apply
    this.mask = mask.split(''); // Generate an array
    // Blank symbols
    this.blank = blank;

    // Other symbols (dot, dashes, etc...)
    this.others = mask
      // Replace all the blunks
      .replace(new RegExp(this.blank, 'g'), '') //eslint-disable-line
      .replace(new RegExp('(.)(?=\\1)', 'g'), '') // Strip dups
      .split(''); // Generate an Array
  }

  /**
   * Apply this mask to a value.
   * @param {string} value - value to be masked.
   */
  apply(value = '') {
    if (!value) {
      return value; // IE
    }

    const mask = this.mask.slice(0); // Clone array - do not copy reference
    const arr = `${value}`.split(''); // Create an array from the value

    let result = '';
    let currentChar;
    let currentMaskElement;

    while (arr.length > 0 && mask.length > 0) {
      currentMaskElement = mask.shift(); // Get and remove first element

      // Check if the masks character is a blank
      if (currentMaskElement === this.blank) {
        // Check if there is another symbol to be add in that place
        if (this.others.includes(arr[0])) {
          currentChar = '';
          arr.shift(); // Remove element from array
          mask.unshift(currentMaskElement); // Add the current mask element
        } else {
          // Add the char from the input value
          currentChar = arr.shift();
        }
      } else {
        // If is not a blank it should be a symbol from mask
        currentChar = currentMaskElement;

        // If the user input the same value (space, etc...) remove it from the array
        if (arr[0] === currentMaskElement) {
          arr.shift();
        }
      }

      // Add the char the current value
      result = `${result}${currentChar}`;
    }

    // Return the masked value
    return result;
  }

  /**
   * Get the total length with the masked values
   * @returns {Number}
   */
  getLength() {
    return this.mask.length;
  }
}

module.exports = Mask;
