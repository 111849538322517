interface Validation {
  regex: string;
  error: string;
  show: boolean;
}

export type ErrorData = {
  error: string;
  show: boolean;
};

export type OnErrorValidator = ({ error, show }: ErrorData) => void;

interface ValidateParams {
  onError?: OnErrorValidator;
  validations: Array<Validation>;
}

const validate =
  ({ onError, validations }: ValidateParams) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const error = validations.find(
      (validation: { regex: RegExp | string }) =>
        !RegExp(validation.regex).exec(inputValue),
    );

    if (error) {
      onError?.({ ...error });
    }
  };

export default validate;
