const React = require('react');
const PropTypes = require('prop-types');

const { Notification } = require('@andes/badge');

const ErrorHint = ({ text }) => (
  <div className="error-hint">
    <Notification
      size="small"
      type="error"
    />
    <span className="error-text">{text}</span>
  </div>
);

ErrorHint.propTypes = {
  text: PropTypes.string.isRequired,
};

module.exports = ErrorHint;
