const React = require('react');
const PropTypes = require('prop-types');

const Challenge = (props) => {
  let classNames = 'challenge';

  if (props.challenge) {
    classNames += ` ${props.challenge}`;
  }

  return (
    <div className={classNames}>
      {props.children}
    </div>
  );
};

Challenge.propTypes = {
  kind: PropTypes.string,
};

Challenge.defaultProps = {
  kind: '',
};

module.exports = Challenge;
