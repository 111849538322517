const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const Button = require('../../containers/Button/ButtonWithStore');
const stepActions = require('../../spa/actions/step');
const { getQueryParams } = require('../../utils/Dom');
const { STEP_NEXT } = require('../../spa/actions/types');


const ButtonMoveStep = ({ stepActions, flow, history, value, id, kind, type, text, disabled, blockLoading }) => {
  let formRef = null;

  const handleClick = (event) => {
    event.preventDefault();

    if (formRef) {
      const queryParams = getQueryParams();

      stepActions[STEP_NEXT](formRef, flow.id, {
        cancel: flow.cancel,
        type: flow.type,
        urlParams: queryParams,
      }, flow.type, queryParams, history);
    }
  };

  return (
    <>
      <form id="move_step_form" key="move_step_form" method="POST" style={{ visibility: 'hidden' }} ref={(form) => { formRef = form; }}>
        <input
          type="hidden"
          name="[move_step_button]"
          value={value}
        />
      </form>
      <Button
        id={id}
        kind={kind}
        type={type}
        text={text}
        onClick={handleClick}
        disabled={disabled}
        blockLoading={blockLoading}
      />
    </>
  );
};

/**
 * Prop Types
 */
ButtonMoveStep.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  kind: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  blockLoading: PropTypes.bool,
  history: PropTypes.object, // eslint-disable-line
  flow: PropTypes.object, // eslint-disable-line
};

/**
 * Default Props
 */

ButtonMoveStep.defaultProps = {
  id: '',
  text: '',
  kind: 'loud',
  type: '',
  value: '',
  disabled: false,
  blockLoading: true,
};

const mapDispatchToProps = dispatch => ({
  stepActions: bindActionCreators(stepActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  flow: state.page.flow,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ButtonMoveStep;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(ButtonMoveStep);
}
