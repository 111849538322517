/* global document */
const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');

const IconMercadoPago = require('../icons/MercadoPago');
const LogoutButton = require('../LogoutButton');
const translate = require('../../translation');

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      showPopup: false,
    };
    this.translations = translate(i18n);
    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClick() {
    if (this.state.showPopup) {
      this.setState({
        showPopup: false,
      });
    } else {
      this.setState({
        showPopup: true,
      });
    }
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      this.setState({
        showPopup: false,
      });
    }
  }

  setMenuRef(node) {
    this.menuRef = node;
  }

  render() {
    if (!this.props.userName) {
      return null;
    }

    const { userName, avatarUrl } = this.props;

    return (
      <div className="user-menu" ref={this.setMenuRef}>
        <button type="button" className="user-menu__button" onClick={this.handleClick}>
          {userName}
          <span className={this.state.showPopup ? 'chevron-up' : 'chevron-down'} />
        </button>
        {this.state.showPopup
          && (
            <div className="user-menu__popup">
              <div className="user-menu__section user-menu__header">
                <span className="user-menu__logo-mp">
                  <IconMercadoPago />
                </span>
                <span className="user-menu__mp-text">
                  {this.translations.YOUR_ACCOUNT}
                </span>
              </div>
              <div className="user-menu__user-info user-menu__section">
                <span className="user-menu__avatar">
                  {!avatarUrl ? (
                    <span className="user-menu__initials">
                      <span>{userName.charAt(0)}</span>
                    </span>
                  ) : (
                    <img alt={userName} src={avatarUrl} />
                  )}
                </span>
                <span className="user-menu__username">{userName}</span>
              </div>
              <LogoutButton
                className="user-menu__footer user-menu__section"
                history={this.props.history}
                text={`${this.translations.IM_NOT} ${userName}`}
              />
            </div>
          )}
      </div>
    );
  }
}

UserMenu.propTypes = {
  userName: PropTypes.string,
  avatarUrl: PropTypes.string,
  history: PropTypes.object,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

UserMenu.defaultProps = {
  history: null,
  userName: '',
  avatarUrl: '',
  i18n: {
    gettext: t => t,
  },
};

if (process.env.NODE_ENV === 'test') {
  module.exports = UserMenu;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n((UserMenu));
}
