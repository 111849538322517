const React = require('react');

const RecoveryApprovedPayment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="108" height="74" viewBox="0 0 108 74">
    <g fill="none" fillRule="evenodd">
      <path fill="#F0F0F0" d="M-628-245H738v707H-628z" />
      <g transform="translate(1 1)">
        <path fill="#D7DDE4" d="M0 12h96v10H0z" />
        <rect width="18" height="10" x="12" y="38" fill="#D7DDE4" rx="5" />
        <rect width="96" height="60" stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.8" rx="7" />
      </g>
      <path fill="#D7DDE4" d="M84.5 74C71.542 74 61 63.458 61 50.5S71.542 27 84.5 27 108 37.542 108 50.5 97.458 74 84.5 74" />
      <path fill="#F0F0F0" d="M84.5 29C72.645 29 63 38.645 63 50.5S72.645 72 84.5 72 106 62.355 106 50.5 96.355 29 84.5 29" />
      <path stroke="#9BA9BB" strokeLinecap="round" strokeWidth="1.8" d="M74 50.333l6.6 7.334L96 43" />
    </g>
  </svg>
);

module.exports = RecoveryApprovedPayment;
