const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');

const OptionsListItem = (props) => {
  const { id, disabled: isDisabled, withActionText, withSwitch, isCombination, withBottomRow, children, kind, className, extraInfo, ...rest } = props;
  const withExtraInfo = !!extraInfo;

  return (
    <li
      className={classNames(
        'options-list__item',
        `item-${id}`,
        className,
        {
          disabled: isDisabled,
          'extra-info': withExtraInfo,
          'with-action-text': withActionText,
          'with-switch': withSwitch,
          'is-combination': isCombination,
          'with-bottom-row': withBottomRow?.length,
          'icon-full': kind === 'icon-full',
        },
      )}
      key={id}
      {...rest}
    >
      {children}
    </li>
  );
};

OptionsListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  withActionText: PropTypes.bool,
  kind: PropTypes.string,
  withSwitch: PropTypes.bool,
  isCombination: PropTypes.bool,
  withBottomRow: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    id: PropTypes.string
  })),
  extraInfo: PropTypes.shape({
    hierarchy: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    closable: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

OptionsListItem.defaultProps = {
  disabled: false,
  withSwitch: false,
  isCombination: false,
  withBottomRow: [],
  id: '',
  withActionText: false,
};

module.exports = OptionsListItem;
