/* global window */

const React = require('react');
const PropTypes = require('prop-types');
const path = require('path');

const Loading = require('ui-library-mp/ui/Loading');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const ArrowTail = require('../icons/ArrowTail');
const ArrowHead = require('../icons/ArrowHead');
const Success = require('../icons/Success');
const Failure = require('../icons/Failure');
const {
  DYNAMIC_MODAL_DESCRIPTION,
  BLOCKED_POPUP,
} = require('../../spa/actions/types');
const transitionRedirect = require('../../spa/actions/transitionRedirect');
const ChallengeOneClick = require('../../utils/ChallengeOneClick');
const { isIframe } = require('../../utils/Dom');

class TransitionRedirect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endedLoading: false,
    };

    this.target = isIframe() ? '_blank' : props.target;
    this.timeout = null;
    this.formRef = null;
    this.getBlockedPopupsUrl = this.getBlockedPopupsUrl.bind(this);
    this.normalExperience = this.normalExperience.bind(this);
    this.webpayOneClickExperience = this.webpayOneClickExperience.bind(this);
    this.blockedPopups = this.blockedPopups.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      if (!this.props.oneClick) {
        this.normalExperience();
      } else {
        this.webpayOneClickExperience();
      }
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.transitionRedirect[DYNAMIC_MODAL_DESCRIPTION](false);
    this.props.transitionRedirect[BLOCKED_POPUP](false);
  }

  /**
   * Generate the url to blocked popups error page
   * @param hiddenComponents page relative information, needed for blocked-popups close button
   * @param {string} actionName name of the site that will redirect to manually
   * @param {string} actionUrl url of the site that will redirect to manually
   */
  getBlockedPopupsUrl(hiddenComponents, actionName, preferenceId) {
    return path.join(
      this.props.basePath,
      `/${this.props.checkoutType}/blocked-popups?actionName=${actionName}&preferenceId=${preferenceId}`,
    );
  }

  serializeHiddenComponents(hiddenComponents = []) {
    let serializedHiddenComponents = '';
    hiddenComponents.map((component) => {
      if (!['tracking', 'currency'].includes(component.id)) {
        serializedHiddenComponents += `&${component.id}=${JSON.stringify(component.value)}`;
      }
    });
    return serializedHiddenComponents;
  }

  normalExperience() {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const popup = window.open(this.props.url, this.target);
    // if target is blank but there is no popup opened we need to redirect to Blocked popups error page
    if (this.target === '_blank' && !popup) {
      // Popups are blocked so we will send the user to Modal Blocked popups error
      this.blockedPopups();
    } else {
      this.setState({
        endedLoading: true,
      }, () => this.props.transitionRedirect[DYNAMIC_MODAL_DESCRIPTION](this.target === '_blank'));
    }
  }

  webpayOneClickExperience() {
    if (this.formRef && this.formRef.submit) {
      this.setState({
        endedLoading: true,
      }, () => {
        // For Modal experience in OneClick we simulate a Challenge experience by opening OneClick in a Popup
        if (this.props.checkoutType === 'modal' || isIframe()) {
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          ChallengeOneClick
            .open(this.formRef, 'oneclick', this.props.flowId, this.props.checkoutType, this.blockedPopups);
        } else {
          this.formRef.submit();
        }
      });
    }
  }

  blockedPopups() {
    this.props.transitionRedirect[BLOCKED_POPUP](true);
  }

  render() {
    const { oneClick, url, tbkToken, blockedPopup } = this.props;
    const { endedLoading } = this.state;
    let statusIcon = endedLoading ? <Success /> : <Loading size="medium" />;

    if (blockedPopup) {
      statusIcon = <Failure />;
    }
    return (
      <div className="transition-redirect">
        <ArrowTail />
        {statusIcon}
        <ArrowHead />
        {
          oneClick && (
            <form ref={(form) => { this.formRef = form; }} action={url} method="post">
              <input type="hidden" name="TBK_TOKEN" value={tbkToken} />
            </form>
          )
        }
      </div>
    );
  }
}

TransitionRedirect.propTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  delay: PropTypes.number,
  transitionName: PropTypes.string,
  basePath: PropTypes.string,
  checkoutType: PropTypes.string,
  tbkToken: PropTypes.string,
  oneClick: PropTypes.bool,
  flowId: PropTypes.string,
  transitionRedirect: PropTypes.object,
  hidden_components: PropTypes.object,
};

TransitionRedirect.defaultProps = {
  delay: 3000,
  transitionName: 'Webpay',
  basePath: '',
  checkoutType: 'redirect',
  tbkToken: '',
  oneClick: false,
  flowId: '',
  hidden_components: {},
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  transitionRedirect: bindActionCreators(transitionRedirect, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  dynamicDescription: state.transitionRedirect.dynamicDescription,
  blockedPopup: state.transitionRedirect.blockedPopup,
  hidden_components: state.page.data.hidden_components,
  checkoutType: state.page.flow.type,
  basePath: state.configurations.basePath,
  flowId: state.page.flow.id,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = TransitionRedirect;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(TransitionRedirect);
}
