/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  LOTTIE_ANIMATION,
} = require('../actions/types');

const actions = {};

const initialState = {
  loaded: false,
};

actions[LOTTIE_ANIMATION] = (state, action) => ({
  loaded: action.payload,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
