const React = require('react');

const IconWrapper = require('../IconWrapper');

const Chevron = () => (
  <IconWrapper>
    <svg aria-hidden="true" className="with-custom-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.01 12.064L9.006 6.061 10.066 5l7.065 7.064-7.07 7.071L9 18.075z"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(Chevron);
