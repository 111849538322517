const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { schema, useForm } = require('checkout-off-form-validation');
const { injectI18n } = require('nordic/i18n');
const { Form } = require('@andes/form');
const { Button } = require('@andes/button');
const { Message } = require('@andes/message');
const { Checkbox } = require('@andes/checkbox');
const { TextField } = require('@andes/textfield');
const { Card, CardContent } = require('@andes/card');
const { DropdownNative, DropdownNativeItem } = require('@andes/dropdown');

const ModalZipcode = require('../ModalZipcode');
const FormActions = require('../FormActions');
const country = require('../../utils/country');
const translate = require('../../translation');
const ApiService = require('../../service/api');
const stepActions = require('../../spa/actions/step');
const inputValuesActions = require('../../spa/actions/inputValues');
const { getQueryParams } = require('../../utils/Dom');
const { formatStringToNumber, formatZipcode } = require('../../utils/format');
const { MESSAGE_HIERARCHY, BUTTON_HIERARCHY } = require('../../../constants/commons');
const { CURRENT_INPUT_VALUES } = require('../../spa/actions/types');
const { STEP_NEXT } = require('../../spa/actions/types');
const { USER_TYPES } = require('../../../constants/app');

const {
  CHECKOUT_TYPE: { MODAL },
} = require('../../../constants/commons');

const FormAddressInfo = (props) => {
  const { deviceType, flow, form_type, history, i18n, inputValuesAction, stepAction } = props;
  const { errors, clearError, clearAllErrors, handleSubmit, register, registerSchema, setFieldOptions } = useForm();

  const translations = translate(i18n);
  const formValues = props?.form_data?.values;
  const hasZipcode = !!formValues?.zipcode;

  const [openModal, setOpenModal] = React.useState(false);

  const [disabledFields, setDisabledFields] = React.useState({
    city: !hasZipcode,
    state: !hasZipcode,
    street: !hasZipcode,
    complement: !hasZipcode,
    neighborhood: !hasZipcode,
    number: !!formValues?.has_no_number || !hasZipcode,
  });

  const [placeholderFields, setPlaceholderFields] = React.useState({
    number: disabledFields.number ? translations.PLACEHOLDER_NO_NUMBER : translations.PLACEHOLDER_NUMBER,
  });

  const [fields, setFields] = React.useState({
    city: formValues?.city || '',
    state: formValues?.federal_unit || '',
    street: formValues?.street || '',
    number: formValues?.number || '',
    complement: formValues?.complement || '',
    neighborhood: formValues?.neighborhood || '',
    zipcode: formatZipcode(formValues?.zipcode),
  });

  registerSchema(
    schema.shape({
      zipcode: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 9, message: translations.INVALID_ZIPCODE })
        .validateOnChange(false),
      state: schema.options().validateOnChange(false),
      city: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 2, message: translations.INVALID_CITY })
        .max({ length: 80, message: translations.INVALID_CITY })
        .validateOnChange(false),
      neighborhood: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 2, message: translations.INVALID_NEIGHBORHOOD })
        .max({ length: 80, message: translations.INVALID_NEIGHBORHOOD })
        .validateOnChange(false),
      street: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: 2, message: translations.INVALID_STREET })
        .max({ length: 80, message: translations.INVALID_STREET })
        .validateOnChange(false),
      number: schema
        .options()
        .optional(disabledFields.number)
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .validateOnChange(false),
      complement: schema.options().optional(),
    }),
  );

  const setInputStateValues = React.useCallback(
    (values) => inputValuesAction[CURRENT_INPUT_VALUES](`${flow.step}_${form_type}_values`, values),
    [props],
  );

  const handleFieldChange = (field, value) => {
    setFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePlaceholderFieldChange = (field, value) => {
    setPlaceholderFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleDisabledFieldChange = (field, value) => {
    setDisabledFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStateValidation = () => !!fields.state;

  const handleNumberValidationChange = (disabled) => {
    clearError('number');
    setFieldOptions('number', { optional: disabled });
  };

  const handleNumberPlaceholderChange = (disabled) => {
    const { PLACEHOLDER_NUMBER, PLACEHOLDER_NO_NUMBER } = translations;
    handlePlaceholderFieldChange('number', disabled ? PLACEHOLDER_NO_NUMBER : PLACEHOLDER_NUMBER);
  };

  const handleNoNumberChange = (disabled) => {
    handleFieldChange('number', '');
    handleDisabledFieldChange('number', disabled);
    handleNumberPlaceholderChange(disabled);
    handleNumberValidationChange(disabled);
  };

  const handleLocations = async (value) => {
    const zipcode = formatStringToNumber(value);

    if (zipcode.length === 8) {
      const response = await ApiService.getLocations(zipcode);
      const location = response?.locations?.[0];

      setInputStateValues({
        zipcode: value,
        city: location?.city?.name,
        street: location?.extended_attributes?.address,
        neighborhood: location?.extended_attributes?.neighborhood,
        federal_unit: location?.state?.id,
        state: location?.state?.id,
      });

      setDisabledFields({
        city: false,
        state: false,
        street: false,
        number: false,
        complement: false,
        neighborhood: false,
      });

      handleNoNumberChange(false);
    }
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    const number = data.number || 'S/N';

    setInputStateValues({
      ...data,
      number,
      state: fields.state,
      federal_unit: fields.state,
      has_no_number: disabledFields.number,
    });

    const urlParams = getQueryParams();
    const defaultData = { urlParams, type: flow.type };

    const formValues = {
      ...data,
      number,
      state: fields.state,
      federalUnit: fields.state,
      zipcode: formatStringToNumber(data.zipcode),
      isNewInterface: true,
    };

    stepAction[STEP_NEXT](formValues, flow.id, defaultData, flow.type, urlParams, history);
  };

  React.useEffect(() => {
    clearAllErrors();
    setFields({ ...props.form_data.values });
  }, [props.form_data.values]);

  React.useEffect(() => {
    setFieldOptions('state', {
      custom: {
        message: translations.INVALID_STATE,
        validate: handleStateValidation,
      },
    });
  }, [fields.state]);

  return (
    <div className={`${props.checkoutType === MODAL && 'scroller'}`}>
      <ModalZipcode
        open={openModal}
        setOpen={setOpenModal}
        deviceType={deviceType}
        populateFields={setFields}
        callback={handleLocations}
      />

      <Card className="form_address_info_card">
        <CardContent>
          <Form className="form_address_info" id={props.form}>
            <TextField
              id="zipcode"
              maxLength={9}
              className="form_address_field"
              label={translations.LABEL_ZIPCODE}
              placeholder={translations.PLACEHOLDER_ZIPCODE}
              value={fields.zipcode}
              helper={errors?.zipcode?.message}
              modifier={errors?.zipcode && 'error'}
              {...register('zipcode', {
                onChange: (e) => {
                  handleLocations(e.target.value);
                  handleFieldChange('zipcode', formatZipcode(e.target.value));
                },
              })}
            >
              <Button
                size="small"
                className="form_address_info_modal_button"
                hierarchy={BUTTON_HIERARCHY.TRANSPARENT}
                onClick={() => setOpenModal(true)}
              >
                {translations.NO_ZIPCODE}
              </Button>
            </TextField>

            <DropdownNative
              id="state"
              type="form"
              menuMaxHeight={240}
              className="form_address_field form_address_select"
              label={translations.LABEL_STATE}
              placeholder={translations.PLACEHOLDER_STATE}
              disabled={disabledFields.state}
              value={fields.state}
              helper={errors?.state?.message}
              modifier={errors?.state && 'error'}
              {...register('state', {
                custom: {
                  message: translations.INVALID_STATE,
                  validate: handleStateValidation,
                },
                onChange: (e, value) => {
                  handleFieldChange('state', value);
                },
              })}
            >
              <DropdownNativeItem value="" disabled={!!fields.zipcode} title={translations.SELECT_STATE} />
              {Object.entries(country.MLB.states).map(([state, title]) => (
                <DropdownNativeItem key={state} value={state} title={title} />
              ))}
            </DropdownNative>

            <div className="form_address_double_field">
              <TextField
                id="city"
                className="form_address_field"
                label={translations.LABEL_CITY}
                placeholder={translations.PLACEHOLDER_CITY}
                disabled={disabledFields.city}
                value={fields.city}
                helper={errors?.city?.message}
                modifier={errors?.city && 'error'}
                {...register('city', {
                  onChange: (e) => {
                    handleFieldChange('city', e.target.value);
                  },
                })}
              />

              <TextField
                id="neighborhood"
                className="form_address_field"
                label={translations.LABEL_NEIGHBORHOOD}
                placeholder={translations.PLACEHOLDER_NEIGHBORHOOD}
                disabled={disabledFields.neighborhood}
                value={fields.neighborhood}
                helper={errors?.neighborhood?.message}
                modifier={errors?.neighborhood && 'error'}
                {...register('neighborhood', {
                  onChange: (e) => {
                    handleFieldChange('neighborhood', e.target.value);
                  },
                })}
              />
            </div>

            <div className="form_address_double_field">
              <TextField
                id="street"
                className="form_address_field"
                label={translations.LABEL_STREET}
                placeholder={translations.PLACEHOLDER_STREET}
                disabled={disabledFields.street}
                value={fields.street}
                helper={errors?.street?.message}
                modifier={errors?.street && 'error'}
                {...register('street', {
                  onChange: (e) => {
                    handleFieldChange('street', e.target.value);
                  },
                })}
              />

              <TextField
                id="number"
                className="form_address_field form_address_number_field"
                disabled={disabledFields.number}
                label={translations.LABEL_NUMBER}
                placeholder={placeholderFields.number}
                value={fields.number}
                helper={errors?.number?.message}
                modifier={errors?.number && 'error'}
                {...register('number', {
                  onChange: (e) => {
                    handleFieldChange('number', e.target.value);
                  },
                })}
              >
                <Checkbox
                  labelPosition="left"
                  className="checkbox-in-textfield"
                  label={translations.LABEL_NO_NUMBER}
                  checked={disabledFields.number}
                  onChange={(e) => handleNoNumberChange(e.target.checked)}
                />
              </TextField>
            </div>

            <TextField
              id="complement"
              className="form_address_field"
              label={translations.LABEL_COMPLEMENT}
              placeholder={translations.PLACEHOLDER_COMPLEMENT}
              disabled={disabledFields.complement}
              value={fields.complement}
              helper={errors?.complement?.message}
              modifier={errors?.complement && 'error'}
              {...register('complement', {
                onChange: (e) => {
                  handleFieldChange('complement', e.target.value);
                },
              })}
            />
          </Form>
        </CardContent>
      </Card>

      {props.form_data?.user_type === USER_TYPES.LOGGED && (
        <Message hierarchy={MESSAGE_HIERARCHY.QUIET}>{translations.NO_ADDRESS_MODIFY}</Message>
      )}

      <FormActions.Container>
        <FormActions.BackButton text={translations.TO_GO_BACK} handleBack={() => window.history.back()} />
        <FormActions.SubmitButton text={translations.CONTINUE} handleSubmit={handleSubmit(onSubmit)} />
      </FormActions.Container>
    </div>
  );
};

FormAddressInfo.propTypes = {
  siteId: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  form_type: PropTypes.string.isRequired,
  form_data: PropTypes.shape({
    values: PropTypes.shape({
      zipcode: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      neighborhood: PropTypes.string,
      street: PropTypes.string,
      number: PropTypes.string,
      complement: PropTypes.string,
      federal_unit: PropTypes.string,
      has_no_number: PropTypes.bool,
    }).isRequired,
    user_type: PropTypes.oneOf([USER_TYPES.GUEST, USER_TYPES.LOGGED]).isRequired,
  }).isRequired,
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func.isRequired,
  }).isRequired,
  inputValuesAction: PropTypes.shape({
    [CURRENT_INPUT_VALUES]: PropTypes.func.isRequired,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    back: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => {
  const inputStateValues = state.inputValues.current[`${state.page.flow.step}_${props.form_type}_values`];
  const zipcode = formatZipcode(inputStateValues?.zipcode || props.form_data.values.zipcode);

  const values = {
    zipcode,
    city: inputStateValues?.city || props.form_data.values.city,
    street: inputStateValues?.street || props.form_data.values.street,
    number: inputStateValues?.number || props.form_data.values.number,
    state: inputStateValues?.federal_unit || props.form_data.values.federal_unit,
    complement: inputStateValues?.complement || props.form_data.values.complement,
    neighborhood: inputStateValues?.neighborhood || props.form_data.values.neighborhood,
    federal_unit: inputStateValues?.federal_unit || props.form_data.values.federal_unit,
    has_no_number: inputStateValues?.has_no_number || props.form_data.values.has_no_number,
  };

  return {
    flow: state.page.flow,
    checkoutType: state.configurations.checkoutType,
    form_data: { ...props.form_data, values },
  };
};

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
  inputValuesAction: bindActionCreators(inputValuesActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = FormAddressInfo;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(FormAddressInfo));
}
