/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  CHANGE_IFRAME_SIZE,
} = require('./types');

// Request Actions
const changeSize = createAction(CHANGE_IFRAME_SIZE);

/**
 * Export functions
 */
module.exports = {
  [CHANGE_IFRAME_SIZE]: actionToTake => (dispatch) => {
    dispatch(changeSize(actionToTake));
  },
};
