const React = require('react');
const PropTypes = require('prop-types');

const Message = require('../Message');
const GroupMediaObject = require('../Groups/GroupMediaObject');

const ExtraInfo = ({ hierarchy, text, messageType }) => (
  <GroupMediaObject id="extra-info">
    <Message color={messageType} hierarchy={hierarchy} closable={false} text={text} />
  </GroupMediaObject>
);

ExtraInfo.propTypes = {
  hierarchy: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
};

module.exports = ExtraInfo;
