/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const { Switch, Route } = require('react-router');

/**
 * Routes Compoent - render switch with pages
 */
const Routes = ({ routes, extraProps, location }) => (
  (routes && routes.length > 0) && (
    <Switch location={location}>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          render={props => (
            <route.component {...props} {...extraProps} route={route} />
          )}
        />
      ))}
    </Switch>
  )
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    fetchData: PropTypes.func,
  })),
  extraProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Routes.defaultProps = {
  routes: [],
  extraProps: {},
};

module.exports = Routes;
