/* eslint-disable jsx-a11y/label-has-for */
const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const selectOptions = require('../../spa/actions/selectOptions');
const loadingActions = require('../../spa/actions/loading');
const trackingActions = require('../../spa/actions/tracking');
const OptionsListItem = require('../OptionsListItem');
const { submitForm } = require('../../utils/Dom');
const { LOADER_TRIGGERED, OPTIONS_PAGE, BOTTOM_SHEET } = require('../../spa/actions/types');
const ExtraInfo = require('../ExtraInfo');
const dictionary = require('../../containers/Optimus/dictionary');
const bottomSheetActions = require('../../spa/actions/bottomSheet');
const findFormByEventTarget = require('../../utils/findFormByEventTarget');

/**
 * OptionsList (Generic list)
 */
class OptionsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, props) {
    if (props.withSwitch) {
      return;
    }

    if (props.onChange) {
      props.onChange(event);
    }

    // On change redirect to next hash page or submit closest (parent) form
    if (props.nextPage) {
      // if we are going to ticket or bank_transfer we need to hide back url link
      if (['ticket', 'bank_transfer', 'opensea_credits_loader'].includes(props.nextPage)) {
        this.props.selectOptions[OPTIONS_PAGE](true);
      }

      // Use the history from context that is always going to be the one from the parent <Router />
      this.context.router.history.push(props.nextPage);
      // location.hash = `#${props.nextPage}`; // eslint-disable-line
    } else if (!props.withEmailConfirmation) {
      const form = event.target.closest('form');
      submitForm(form);
    }

    if (this.props.progressBar) {
      this.props.loadingActions[LOADER_TRIGGERED]('empty');
    } else {
      this.props.loadingActions[LOADER_TRIGGERED]();
    }

    this.setState({
      loading: true,
    });
  }

  openBottomSheet(props) {
    return (event) => {
      if (props.withEmailConfirmation) {
        const form = event.target.closest('form');
        const closestForm = findFormByEventTarget(event.target);
        this.props.bottomSheetActions[BOTTOM_SHEET]({ openBottomSheet: true, paymentMethodForm: closestForm || form });
      }
    };
  }

  render() {
    const {
      children,
      className,
      component: ComponentToRender,
      loadingStopAnimation,
      maxElements,
      options,
      extraInfo,
    } = this.props;

    const translate = (uiComponent) =>
      dictionary.get([uiComponent.type]) ? dictionary.get([uiComponent.type])(uiComponent) : '';

    const isDisabled = (option) => option.disabled || option.withSwitch;
    return (
      <ul
        className={classNames('options-list', 'ui-card', `${className}`, {
          'max-elements': maxElements,
        })}
        data-loading={this.state.loading && this.props.loading && this.props.progressBar}
      >
        {options.map((option) => (
          <OptionsListItem {...option} onClick={this.openBottomSheet(option)}>
            <input
              type="radio"
              className="u-hide"
              id={option.id}
              name={option.name}
              value={option.value}
              disabled={isDisabled(option)}
              onChange={(event) => this.handleChange(event, option)}
            />
            <label
              className={classNames('options-list__label', {
                'progress-done': loadingStopAnimation && this.props.progressBar,
                'with-switch': option.withSwitch,
              })}
              htmlFor={option.id}
            >
              {ComponentToRender && (
                <ComponentToRender history={this.props.history} siteId={option.siteId} {...option} />
              )}
              {extraInfo && <ExtraInfo {...extraInfo} messageType={extraInfo.message_type} />}
              {children}
            </label>
            {option.withBottomRow?.length > 0 && (
              <div className="options-list__bottomRow">
                {option.withBottomRow.map(
                  (component) =>
                    component && <React.Fragment key={Math.random()}>{translate(component)}</React.Fragment>,
                )}
              </div>
            )}
          </OptionsListItem>
        ))}
      </ul>
    );
  }
}

OptionsList.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      withBottomRow: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          text: PropTypes.string,
          id: PropTypes.string,
        }),
      ),
    }),
  ),
  component: PropTypes.func,
  siteId: PropTypes.string,
  disabled: PropTypes.bool,
  maxElements: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loadingStopAnimation: PropTypes.bool.isRequired,
  extraInfo: PropTypes.shape({
    message_type: PropTypes.string.isRequired,
    hierarchy: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    closable: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
  }),
  progressBar: PropTypes.bool,
};

OptionsList.defaultProps = {
  name: '',
  className: '',
  options: [],
  component: null,
  siteId: '',
  disabled: false,
  maxElements: false,
  loading: false,
  progressBar: false,
};

// Use the history from context that is always going to be the one from the parent <Router />
OptionsList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      createHref: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  loadingActions: bindActionCreators(loadingActions, dispatch),
  selectOptions: bindActionCreators(selectOptions, dispatch),
  trackingActions: bindActionCreators(trackingActions, dispatch),
  bottomSheetActions: bindActionCreators(bottomSheetActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  loading: state.request.loading,
  loadingStopAnimation: state.request.loadingStopAnimation,
});

/**
 * Expose OptionList
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = OptionsList;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OptionsList);
}
