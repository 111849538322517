/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_CANCEL_TOKEN,
  REQUEST_CANCELLED,
  REQUEST_STARTED_WITHOUT_RENDER,
  REQUEST_FINISHED_WITHOUT_RENDER,
  LOADER_TRIGGERED,
  LOADER_PAY_BUTTON,
  LOADER_STOP_ANIMATION,
  LOADER_STOP_ANIMATION_RESET,
  //  REQUEST_ERROR,
} = require('../actions/types');

const actions = {};

const initialState = {
  requests: [],
  loading: false, // Must be false. Page already loaded with SSR
  loadingWithoutRender: false,
  cancelToken: null, // Exclusive for token creation
  loadingId: null,
  loadingType: '',
  loadingStopAnimation: false,
  loadingStartAnimation: false,
};

actions[LOADER_TRIGGERED] = (state, action) => ({
  ...state,
  loadingType: action.payload.type,
  loadingId: action.payload.id,
  loadingStopAnimation: false,
  loadingStartAnimation: true,
});

actions[LOADER_STOP_ANIMATION] = (state) => ({
  ...state,
  loadingStartAnimation: false,
  loadingStopAnimation: true,
});

actions[LOADER_STOP_ANIMATION_RESET] = (state) => ({
  ...state,
  loadingId: null,
  loadingStopAnimation: false,
  loadingStartAnimation: false,
});

actions[REQUEST_STARTED] = (state, action) => ({
  ...state,
  requests: state.requests.concat([action.payload.requestId]),
  loading: true,
});

actions[REQUEST_FINISHED] = (state, action) => {
  const newState = {
    ...state,
    requests: [...state.requests],
  };
  const index = newState.requests.indexOf(action.payload.requestId);

  // Remove request
  if (index !== -1) {
    newState.requests.splice(index, 1);
  }

  // If it has a cancelToken remove it
  if (action.payload.cancelToken) {
    newState.cancelToken = null;
  }

  if (!action.payload.leaveLoading) {
    newState.loading = (newState.requests.length > 0);
  }

  // Empty loading values
  if (!newState.loading) {
    newState.loadingType = '';
  }

  return newState;
};

actions[REQUEST_STARTED_WITHOUT_RENDER] = (state) => ({
  ...state,
  loadingWithoutRender: true,
});

actions[REQUEST_FINISHED_WITHOUT_RENDER] = (state) => ({
  ...state,
  loadingWithoutRender: false,
});

actions[REQUEST_CANCEL_TOKEN] = (state, action) => {
  if (state.cancelToken !== null) {
    state.cancelToken.cancel();
  }

  return {
    ...state,
    cancelToken: action.payload,
  };
};

actions[REQUEST_CANCELLED] = (state, action) => {
  const newState = {
    ...state,
    requests: [...state.requests],
  };
  const index = newState.requests.indexOf(action.payload.requestId);

  // Remove request
  if (index !== -1) {
    newState.requests.splice(index, 1);
  }

  return newState;
};

actions[LOADER_PAY_BUTTON] = (state, action) => ({
  ...state,
  loadingPayButton: action.payload.isLoading,
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
