import React, { useState } from 'react';
import { Button } from '@andes/button';

import Chevron from '../Icons/Chevron';

export type ToggleButtonProps = {
  labelToggle: string;
  id: string;
  className?: string;
  onClick?: () => void;
};

export default function ToggleButton({
  labelToggle,
  id,
  className = '',
  onClick,
}: ToggleButtonProps) {
  const [iconRotation, setRotateIcon] = useState(false);

  function toggleIcon(): void {
    onClick?.();
    setRotateIcon(!iconRotation);
  }

  return (
    <Button
      className={`coupon-button ${className}`}
      hierarchy="transparent"
      size="medium"
      onClick={toggleIcon}
      id={id}
    >
      {labelToggle}
      <Chevron
        width={12}
        height={12}
        strokeWidth={0}
        className={`chevron--vertical ${
          iconRotation ? 'chevron--upside-down' : ''
        }`}
      />
    </Button>
  );
}
