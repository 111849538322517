/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const matchRoute = require('./match');
const Routes = require('./components/Routes');

/**
 * This is going to be the container for all the Single Page Application Routes
 */
class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      location: props.location,
      isFetching: false,
      firstRender: true,
      data: null,
    };

    this.fetch = this.fetch.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  /**
   * If the location change (page change), fetch the data and chunk
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.fetch(nextProps.location);
    }
  }

  /**
   * Update state when this change
   * @param {Object} location
   * @param {Boolean} isFetching
   * @param {Object} data
   * @param {Boolean} firstRender
   */
  updateState(location, data = null, isFetching = false, firstRender = false) {
    const state = {
      location,
      isFetching,
      data,
      firstRender,
    };

    if (!Object.is(this.state, state)) {
      this.setState(state);
    }
  }

  /**
   * Fetch next component and data before change Switch location
   * @param nextLocation
   */
  fetch(nextLocation) {
    const route = matchRoute(this.props.routes, nextLocation.pathname);
    const promises = [];

    if (route) {
      // preload chunk
      promises.push(route.component.preload());

      // Fetch data from server
      if (route.fetchData) {
        promises.push(route.fetchData(route.params, nextLocation.state, this.props.history));
      }

      // Init transition to next page
      this.updateState(this.state.location, this.state.data, true);

      Promise.all(promises).then((responses) => {
        const data = responses[1] || {};

        // If handleRender is define SPAContainer delegate render
        if (route.handleRender) {
          route.handleRender(data, this.props.history, nextLocation, route.params, this.updateState);
        } else {
          // Update Container state
          this.updateState(nextLocation, data);
        }
      }).catch(() => {
        this.updateState(this.state.location, this.state.data);
      });
    }
  }

  render() {
    const {
      firstRender,
      location,
      isFetching,
      data,
    } = this.state;

    const merged = {
      ...this.props,
      ...data,
      loading: isFetching,
      firstRender,
    };

    return (
      <Routes
        routes={this.props.routes}
        extraProps={merged}
        location={location}
      />
    );
  }
}

AppContainer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    fetchData: PropTypes.func,
  })),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

AppContainer.defaultProps = {
  routes: [],
};

module.exports = AppContainer;
