const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const ArrowTail = ({ color }) => (
  <IconWrapper className="icon-arrow-tail">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="2" viewBox="0 0 24 2">
      <path fill="none" fillRule="evenodd" stroke={color} strokeDasharray="5,5" strokeLinecap="round" strokeLinejoin="round" d="M0 .52h24" />
    </svg>
  </IconWrapper>
);

ArrowTail.propTypes = {
  color: PropTypes.string,
};

ArrowTail.defaultProps = {
  color: '#979797',
};

module.exports = ArrowTail;
