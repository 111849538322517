const React = require('react');

const PixUnavailable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="147" height="129" viewBox="0 0 147 129">
    <defs>
      <filter id="klb0ao097a" width="155.7%" height="155.7%" x="-27.8%" y="-21.6%" filterUnits="objectBoundingBox">
        <feOffset dy="6" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <rect id="w64pjc2vab" width="97" height="97" x="0" y="0" rx="6" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-115 -185) translate(115 185) translate(24.5 10)">
              <use fill="#000" filter="url(#klb0ao097a)" href="#w64pjc2vab" />
              <use fill="#FFF" href="#w64pjc2vab" />
            </g>
            <g fill="#4AB7A8" fillRule="nonzero">
              <path d="M12.307 42.77c2.18.006 4.27-.86 5.808-2.405l8.382-8.382c.617-.59 1.59-.59 2.206 0l8.416 8.416c1.539 1.544 3.631 2.408 5.81 2.4h1.654L33.96 53.42c-1.592 1.594-3.753 2.49-6.005 2.49-2.253 0-4.414-.896-6.006-2.49l-10.665-10.65h1.022zM42.93 13.106c-2.18-.006-4.27.86-5.807 2.404l-8.403 8.42c-.61.607-1.596.607-2.206 0l-8.383-8.383c-1.536-1.546-3.627-2.412-5.807-2.404h-1.04L21.95 2.487c3.317-3.316 8.694-3.316 12.01 0l10.623 10.619H42.93z" transform="translate(-115 -185) translate(115 185) translate(24.5 10) translate(20 20)" />
              <path d="M2.49 21.948l6.344-6.344h3.473c1.523.002 2.983.604 4.064 1.676l8.383 8.383c.754.757 1.78 1.183 2.85 1.183 1.069 0 2.095-.426 2.85-1.183l8.415-8.416c1.082-1.072 2.542-1.674 4.064-1.677h4.118l6.37 6.371c3.316 3.317 3.316 8.694 0 12.01l-6.37 6.37H42.93c-1.523 0-2.983-.603-4.064-1.676L30.45 30.23c-1.594-1.524-4.106-1.524-5.7 0l-8.382 8.383c-1.081 1.072-2.542 1.674-4.064 1.676h-3.47l-6.344-6.33C.896 32.366 0 30.206 0 27.953s.896-4.413 2.49-6.005z" transform="translate(-115 -185) translate(115 185) translate(24.5 10) translate(20 20)" />
            </g>
            <g>
              <path fill="#F23D4F" d="M16.5 0C7.402 0 0 7.402 0 16.5 0 25.598 7.402 33 16.5 33 25.598 33 33 25.598 33 16.5 33 7.402 25.598 0 16.5 0" transform="translate(-115 -185) translate(115 185) translate(24.5 10) translate(80.5 51)" />
              <g stroke="#FFF" strokeLinecap="round" strokeWidth="1.8">
                <path d="M7.674 0.665L7.674 14.761" transform="translate(-115 -185) translate(115 185) translate(24.5 10) translate(80.5 51) rotate(-45 23.396 1.162)" />
                <path d="M7.713 0.397L7.713 14.493" transform="translate(-115 -185) translate(115 185) translate(24.5 10) translate(80.5 51) rotate(-45 23.396 1.162) rotate(-90 7.713 7.445)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

module.exports = PixUnavailable;
