const React = require('react');

const { connect } = require('react-redux');

// Rules import
const rules = require('./rules');

class Resizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resize: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.resize !== this.state.resize) {
      this.sendResizeMessage(nextProps, this.props.step);
    }
  }

  sendResizeMessage(nextProps, step) {
    switch (step) {
      case 'wallet_payment':
        rules.walletPayment(nextProps);
        break;
      case 'wallet_congrats_approved':
      case 'wallet_congrats_rejected':
        rules.walletCongrats();
        break;
      case 'wallet_card_form':
      default:
        rules.defaultRule();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  resize: state.size.actionToTake,
  step: state.page.flow.step,
  inputValues: state.inputValues,
  collapsible: state.collapsible,
});

/**
 * Expose Resizer
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = Resizer;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(Resizer);
}
