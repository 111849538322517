const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { Modal } = require('@andes/modal');
const { Button } = require('@andes/button');
const { TextField } = require('@andes/textfield');
const { Autocomplete } = require('@andes/autocomplete');
const { RadioList, RadioListItem } = require('@andes/radio-list');
const { DropdownNative, DropdownNativeItem } = require('@andes/dropdown');

const FormActions = require('../FormActions');
const country = require('../../utils/country');
const translate = require('../../translation');
const ApiService = require('../../service/api');
const { formatZipcode } = require('../../utils/format');
const { DEVICE_TYPE, MODAL_TYPE } = require('../../../constants/commons');

const ModalZipcode = (props) => {
  const { deviceType, i18n, open, setOpen, populateFields, callback } = props;

  const translations = translate(i18n);
  const modalType = deviceType === DEVICE_TYPE.MOBILE ? MODAL_TYPE.FULL : MODAL_TYPE.SMALL;

  const [zipcode, setZipcode] = React.useState('');

  const [city, setCity] = React.useState({
    name: '',
    disabled: true,
  });

  const [state, setState] = React.useState({
    id: '',
    name: '',
    value: '',
  });

  const [cities, setCities] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [title, setTitle] = React.useState(translations.ZIPCODE_MODAL_TITLE_ADDRESS);

  const [errors, setErrors] = React.useState({
    city: false,
    state: false,
  });

  const clearErrors = () => {
    setErrors({ city: false, state: false });
  };

  const handleCities = async (stateId) => {
    const response = await ApiService.getCities(stateId);
    setCities(response || []);
    clearErrors();
  };

  const handleErrors = () => {
    if (!city.name && !state.id) {
      setErrors({ city: true, state: true });
      return;
    }

    if (!city.name) {
      setErrors({ city: true });
      return;
    }

    clearErrors();
  };

  const handleSuggestedLocations = async () => {
    const response = await ApiService.getSuggestedZipcode(state.name, city.name);

    if (response) {
      setLocations(response);
      setTitle(translations.ZIPCODE_MODAL_TITLE_LOCATION);
    }

    handleErrors();
  };

  const handleStateChange = (e, value) => {
    const [id, name] = value.split('|');
    setState({ id, name, value });
    setCity({ name: '', disabled: false });
    handleCities(id);
  };

  const handleCityChange = (e, values) => {
    setCity({ name: values.name, disabled: false });
    clearErrors();
  };

  const handleAddressChange = () => {
    setCities([]);
    setLocations([]);
    setTitle(translations.ZIPCODE_MODAL_TITLE_ADDRESS);
    setZipcode('');
    setCity({ name: '', disabled: true });
    setState({ id: '', name: '' });
    clearErrors();
  };

  const handleZipcodeChange = (e, value) => {
    const index = value || 0;
    setZipcode(locations[index]?.zipCode);
  };

  const handleSubmit = () => {
    const formatedZipcode = formatZipcode(zipcode || locations[0]?.zipCode);

    populateFields((prevState) => ({
      ...prevState,
      city: city.name,
      state: state.id,
      zipcode: formatedZipcode,
    }));

    if (callback) {
      callback(formatedZipcode);
    }

    setOpen(false);
    handleAddressChange();
  };

  const handleCloseModal = () => {
    setOpen(false);
    handleAddressChange();
  };

  return (
    <Modal className="modal-zipcode" closable open={open} title={title} type={modalType} onClose={handleCloseModal}>
      {locations.length === 0 && (
        <div>
          <DropdownNative
            type="form"
            className="modal-zipcode-select"
            menuMaxHeight={240}
            value={state.value}
            label={translations.LABEL_STATE}
            placeholder={translations.PLACEHOLDER_STATE}
            helper={errors.state && translations.INVALID_STATE}
            modifier={errors.state && 'error'}
            onChange={handleStateChange}
          >
            <DropdownNativeItem value="" disabled={!!state.id} title={translations.SELECT_STATE} />
            {Object.entries(country.MLB.states).map(([state, title]) => (
              <DropdownNativeItem key={state} value={`${state}|${title}`} title={title} />
            ))}
          </DropdownNative>

          <Autocomplete suggestions={cities} onSelect={handleCityChange}>
            <TextField
              className="modal-zipcode-input"
              label={translations.LABEL_CITY}
              disabled={city.disabled}
              placeholder={translations.PLACEHOLDER_CITY}
              helper={errors.city && translations.INVALID_CITY}
              modifier={errors.city && 'error'}
            />
          </Autocomplete>

          <Button onClick={handleSuggestedLocations}>{translations.FIND_ZIPCODE}</Button>
        </div>
      )}

      {locations.length > 0 && (
        <>
          <RadioList className="modal-zipcode-radiolist" defaultValue={0} onChange={handleZipcodeChange}>
            {locations.map(({ zipCode, street, neighborhood }, index) => (
              <RadioListItem
                key={zipCode}
                value={index}
                title={`${neighborhood ? `${neighborhood}, ` : ''} CEP ${zipCode}`}
                description={
                  <>
                    <p>{street}</p>
                    <p>{state.name}</p>
                  </>
                }
              />
            ))}
          </RadioList>

          <FormActions.Container className="modal-zipcode-actions">
            <FormActions.BackButton text={translations.TO_GO_BACK} handleBack={handleAddressChange} />
            <FormActions.SubmitButton text={translations.CONTINUE} handleSubmit={handleSubmit} />
          </FormActions.Container>
        </>
      )}
    </Modal>
  );
};

ModalZipcode.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  populateFields: PropTypes.func.isRequired,
  callback: PropTypes.func,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = injectI18n(ModalZipcode);
