const  ApiService = require("../../service/api");

const fetch = (zipcode)=>
  new Promise((resolve, reject)=>{
    ApiService.getLocations(zipcode.trim())
      .then(({locations}) => {
        resolve(locations);
      })
      .catch((error)=>reject(error));
  });

module.exports = {
  fetch
}

