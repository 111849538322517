import React, { useState } from 'react';

import { Card, CardContent } from '@andes/card';
import { Typography } from '@andes/typography';
import IconWrapper from '../Icons/IconWrapper';

export type CardOptionProps = {
  id?: string;
  icon: React.ReactNode;
  onClick: () => void;
  title: string;
  titleSize?: 'normal' | 'big';
  titleColor?: 'black' | 'gray';
  titleIcon?: React.ReactNode;
  description?: string;
  descriptionColor?: string;
  extraInfo?: string;
  extraInfoColor?: string;
  callToAction?: React.ReactNode;
  badge?: React.ReactNode;
  className?: string;
  bottomContent?: React.ReactNode;
  isMobile?: boolean;
};

export default function CardOption({
  onClick,
  id,
  title,
  titleSize = 'normal',
  titleColor = 'black',
  titleIcon,
  description,
  descriptionColor = 'black',
  extraInfo,
  extraInfoColor = 'black',
  icon,
  badge,
  callToAction,
  className = '',
  bottomContent,
  isMobile = false,
}: CardOptionProps) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsActive(!isActive);
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      id={id}
      className={`card-option ${isMobile ? 'card-option--mobile' : ''} ${
        bottomContent ? 'with-bottom-row' : ''
      } ${className}
        ${isActive ? 'card-option--active' : ''}
     `}
    >
      <Card className={'card-option__top-row'} onClick={handleClick}>
        <CardContent className="card-option__content">
          <div className="card-option__main-row">
            <IconWrapper>{icon}</IconWrapper>

            <div className="texts">
              <div className="texts__title">
                <Typography className={`title__text title__text--size-${titleSize} title__text--color-${titleColor}`} type="body">
                  {title}
                </Typography>
                {titleIcon}
              </div>

              {description && (
                <div className="texts__description">
                  <Typography
                    className={`description__color ${descriptionColor ?? ''}`}
                    type="body"
                    size="s"
                    color="secondary"
                  >
                    {description}
                  </Typography>
                </div>
              )}

              {extraInfo && (
                <div className="texts__extra-info">
                  <Typography
                    className={`extra-info__color ${extraInfoColor ?? ''}`}
                    type="body"
                    size="s"
                    color="secondary"
                  >
                    {extraInfo}
                  </Typography>
                </div>
              )}
            </div>

            {callToAction}
          </div>

          {bottomContent}

          {badge}
        </CardContent>
      </Card>
    </div>
  );
}
