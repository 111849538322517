const React = require('react');
const PropTypes = require('prop-types');

const { Link } = require('frontend-spa');

const ButtonStep = (props) => {
  let classNames = 'button-step';

  if (props.kind) {
    classNames += ` andes-button andes-button--${props.kind}`;
  }
  const handleClick = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Link
      to={props.value}
      className={classNames}
      onClick={handleClick}
    >
      {props.label}
    </Link>
  );
};

ButtonStep.propTypes = {
  value: PropTypes.string,
  kind: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonStep.defaultProps = {
  value: '',
  kind: '',
  label: '',
  onClick: null,
};

module.exports = ButtonStep;
