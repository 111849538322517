import type { ReactNode } from 'react';

import React from 'react';

interface IconWrapperProps {
  children: ReactNode;
  className?: string;
  id?: string;
}

const IconWrapper = ({
  children,
  className,
  id,
}: IconWrapperProps) => (
  <div id={id} className={`cow-icon-wrapper ${className || ''}`}>
    {children}
  </div>
);

IconWrapper.defaultProps = {};

export default IconWrapper;
