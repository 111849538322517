const { useEffect, useState } = require('react')

export function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const setTimeoutReference = setTimeout(() => setDebouncedValue(value), delay)

    return () => clearTimeout(setTimeoutReference)
  }, [value, delay])

  return debouncedValue
}
