const React = require('react');

const useNavigationBack = (callback) => {
  React.useEffect(() => {
    const handleNavigationBack = (event) => {
      event.stopPropagation();

      callback?.();
    };

    window.addEventListener('popstate', handleNavigationBack);

    return () => window.removeEventListener('popstate', handleNavigationBack);
  }, [callback]);
};

module.exports = useNavigationBack;
