const React = require('react');
const PropTypes = require('prop-types');

const { ValidatorProvider } = require('../../utils/validator-provider');
const IconBack = require('../icons/Back');
const Form = require('../Form');

const FormBack = props => (
  <ValidatorProvider>
    <Form key={Math.random()} method="POST">
      {props.children}
    </Form>
  </ValidatorProvider>
);


const ButtonArrow = props => {
  const aditionalProps = {};
  const { onClick, kind } = props;
  if (onClick) {
    aditionalProps.onClick = onClick;
  }

  const buttonType = kind === 'form' ? 'submit' : 'button';

  return (<button
    type={buttonType}
    className="andes-button--link"
    name={props.name}
    {...aditionalProps}
  >
    <IconBack />
  </button>
  );
};

const ButtonBackArrow = (props) => (
  <div className={`button-back-arrow button-back-arrow__${props.kind || 'link'}`}>
    {props.renderForm ?
      <FormBack {...props}><ButtonArrow {...props} /></FormBack> :
      <ButtonArrow {...props} />
    }
  </div>
);

ButtonBackArrow.propTypes = {
  kind: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  renderForm: PropTypes.bool,
};

/**
 * Default Props
 */
ButtonBackArrow.defaultProps = {
  onClick: () => { },
  name: '',
  kind: 'link',
  renderForm: false,
};

module.exports = ButtonBackArrow;
