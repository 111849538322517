const React = require('react');
const PropTypes = require('prop-types');

const Loading = require('ui-library-mp/ui/Loading');

const Processing = props => (
  <div className="processing">
    <Loading
      size={props.size}
      type={props.type}
    />
    {
      props.message && <p className="processing__message">{props.message}</p>
    }
  </div>
);

Processing.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['inline', 'block']),
  message: PropTypes.string,
};

Processing.defaultProps = {
  size: 'large',
  type: 'inline',
  message: '',
};

module.exports = Processing;
