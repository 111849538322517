const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const Avatar = require('ui-library-mp/ui/Avatar');

const { logErrorFromClient } = require('../../utils/logTags');
const { ERROR_SPA } = require('../../../constants/app');

const Brand = ({ brandName, brandLogo, maxBrandnameLength, maxInitialsLength }) => {
  if (!brandName) {
    return null;
  }

  const getInitials = () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return brandName
        .split(' ')
        .map((currValue) => currValue[0])
        .join('')
        .substring(0, maxInitialsLength)
        .toUpperCase();
    } catch (error) {
      logErrorFromClient(error, ERROR_SPA.BRAND_INITIALS_ERROR, '[Brand][getInitials]', {
        brand_name: brandName,
        brand_logo: brandLogo,
        max_initials_lengths: maxInitialsLength,
        max_brand_name_length: maxBrandnameLength,
      });

      throw error;
    }
  };

  return (
    <div className="brand">
      {brandLogo ? (
        <span className="brand__img">
          <img src={brandLogo} alt={brandName} title={brandName} />
        </span>
      ) : (
        <Avatar className={getInitials().length === maxInitialsLength ? 'small' : ''} initials={getInitials()} />
      )}
      <span className={`brand__name brand__name__${brandName.length > maxBrandnameLength ? 'large' : 'small'}`}>
        {brandName}
      </span>
    </div>
  );
};

Brand.propTypes = {
  brandName: PropTypes.string,
  brandLogo: PropTypes.string,
  maxBrandnameLength: PropTypes.number,
  maxInitialsLength: PropTypes.number,
};

Brand.defaultProps = {
  brandName: '',
  brandLogo: '',
  maxBrandnameLength: 17,
  maxInitialsLength: 3,
};

const mapStateToProps = (state) => ({
  brandName: state.configurations.brandName,
  brandLogo: state.configurations.brandLogo,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Brand;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(Brand);
}
