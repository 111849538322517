/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  LOADER_STOP_ANIMATION_RESET,
  ANIMATION_TOGGLE,
} = require('./types');

// Request Actions
const loaderStopAnimationReset = createAction(LOADER_STOP_ANIMATION_RESET);
const animationToggle = createAction(ANIMATION_TOGGLE);

/**
 * Export functions
 */
module.exports = {
  /**
   * Stop loader animation
   * @return {function(*)}
   */
  [LOADER_STOP_ANIMATION_RESET]: () => (dispatch) => {
    // Reset the animation before changing route
    dispatch(loaderStopAnimationReset());
  },
  [ANIMATION_TOGGLE]: (id, animate) => (dispatch) => {
    dispatch(animationToggle({ id, animate }));
  },
};
