/**
 * Module dependencies
 */
const querystring = require('querystring');

const { createAction } = require('redux-actions');

const { COMPONENT_ID } = require('../../../constants/app');
const PaymentMethodSearchService = require('../../../services/paymentMethodsSearch');
const {
  FETCH_PAYMENT_METHODS,
} = require('./types');

// Request Actions
const fetchePaymentMethods = createAction(FETCH_PAYMENT_METHODS);

/**
 * Export functions
 */
module.exports = {
  /**
   * Fetch payment methods fromt payment_methods/search
   * @return {function(*)}
   */
  [FETCH_PAYMENT_METHODS]: (transactionAmount) => (dispatch, getState) => {
    const { page: { data }, configurations } = getState();
    const { hidden_components, publicKey } = data;
    const siteId = configurations?.platform?.siteId;
    const pmSearchComponent = hidden_components
      .filter(c => c.id === COMPONENT_ID.PAYMENT_METHOD)
      .shift();
    const pmSearchComponentValues = pmSearchComponent?.value || {};

    const params = {
      ...pmSearchComponentValues,
      transaction_amount: transactionAmount,
      site_id: siteId,
      public_key: publicKey,
    }
    const { marketplace, processing_mode, product_id, public_key, site_id, differential_pricing_id, transaction_amount } = params;

    const queryParams = querystring.stringify({
      marketplace,
      public_key,
      product_id,
      site_id,
      processing_mode,
      differential_pricing_id,
      transaction_amount
    })

    PaymentMethodSearchService.getPaymentMethods(queryParams)
      .then((response) => dispatch(fetchePaymentMethods(response)))
      .catch(() => dispatch(fetchePaymentMethods([])));
  },
};
