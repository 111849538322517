const PropTypes = require('prop-types');

const DynamicContent = (props) => props.customContent || props.defaultContent;

DynamicContent.propTypes = {
  defaultContent: PropTypes.node.isRequired,
  customContent: PropTypes.node,
};

module.exports = DynamicContent;
