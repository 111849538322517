const React = require('react');
const PropTypes = require('prop-types');

const { HashRouter, StaticRouter } = require('react-router-dom');

class NestedPages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      window: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (typeof (window) !== 'undefined' && window?.history?.replaceState) {
      window.history.replaceState({}, document.title, window.location.pathname + window.location.search); // eslint-disable-line

      this.setState({
        window: true,
      });
    }
  }

  render() {
    const ComponentToRender = this.state.window ? HashRouter : StaticRouter;

    return (
      <ComponentToRender context={{}}>
        <>
          {this.props.children}
        </>
      </ComponentToRender>
    );
  }
}

NestedPages.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

module.exports = NestedPages;
