const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const Markdown = require('../Markdown');
const textsActions = require('../../spa/actions/texts');

const Text = ({ text, className, dinamicText, id }) => {
  let classNames = 'text';

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <p id={id || ''} className={classNames}>
      <Markdown text={dinamicText || text} />
    </p>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  dinamicText: PropTypes.string,
  id: PropTypes.string,
};

Text.defaultProps = {
  className: '',
  text: '',
  dinamicText: '',
  id: '',
};

const mapDispatchToProps = dispatch => ({
  textsActions: bindActionCreators(textsActions, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  dinamicText: (ownProps.id && state.texts) ? state.texts[ownProps.id] : null,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Text;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(Text); // eslint-disable-line max-len
}
