/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { Dropdown } = require('@andes/dropdown');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');
const classNames = require('classnames');

const { DropdownItem } = Dropdown;
const { injectValidations } = require('../../utils/validator-provider');
const inputValuesActions = require('../../spa/actions/inputValues');
const BaseValidation = require('../BaseValidation');
const {
  SAVE_INPUT_VALUES,
  CURRENT_INPUT_VALUES,
} = require('../../spa/actions/types');
const translate = require('../../translation');
const {
  SELECTORS: { COW_SELECT },
} = require('../../../constants');

/** Select Component */
class Select extends BaseValidation {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      value: this.props.savedValue || this.props.value,
      error: this.props.error,
      invalid: this.props.invalid,
      step: this.props.step,
      unselected: this.props.savedValue === '',
      selectKey: Math.random(),
    };
    this.translations = translate(i18n);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.getComponentValue = this.getComponentValue.bind(this);
    this.updateStoreCurrentInput = this.updateStoreCurrentInput.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateValue(this.getDefaultValue(nextProps.options));
    /** parent component has changed value prop and need to update Select value */
    const savedValueChanged = nextProps.savedValue !== this.props.savedValue;
    const propValueChanged = nextProps.value !== this.props.value;
    const stateValueChanged = this.state.value !== this.props.value;

    if (savedValueChanged || propValueChanged || stateValueChanged) {
      const toUpdate = nextProps.savedValue || nextProps.value;
      if (typeof toUpdate !== 'undefined') {
        this.updateValue(toUpdate);
      }
    }
  }

  UNSAFE_componentWillMount() {
    let toUpdate;
    if (!this.props.showPlaceholder) {
      toUpdate = this.getDefaultValue(this.props.options);
    }
    /**
     * if there is a triggerOnMount function passed by some group
     * component ( ie: identification group ) run the metod once there
     * is value to send back to that component
     */
    if (typeof this.props.triggerOnMount === 'function') {
      this.props.triggerOnMount(toUpdate);
    }
    /* update the value to the state */
    this.updateValue(toUpdate);
    /* save component value into the store */
    this.updateStoreCurrentInput(toUpdate);
  }

  /**
   * Save current input value in the store
   * @param {*} value
   */
  updateStoreCurrentInput(value) {
    if (this.props.id && this.state.step) {
      const selectedOption = (this.props.options.filter(opt => value && opt.value === value)[0] || {});

      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}`, value);
      // some componentes will need to know the label too
      this.props.inputValuesActions[CURRENT_INPUT_VALUES](`${this.state.step}_${this.props.id}_label`, selectedOption.label);
    }
  }

  getDefaultValue(options) {
    const selectedOption = options.filter(opt => !!opt.selected)[0];
    if (selectedOption) {
      return selectedOption.value;
    }
    // if there is no selected option return the first option available as default
    return options[0] ? options[0].value : '';
  }

  optionsChanged(currOps, nextOps) {
    if (currOps && nextOps) {
      return nextOps && (nextOps.length !== currOps.length // if length changed
        || currOps.filter((op, i) => op.label !== nextOps[i].label).length > 0); // if labels were modified
    }
    return null;
  }

  componentWillUnmount() {
    // Use the step from the state because the props change on route change (this is execute after the route change)
    /**
     * @TODO added the type check for SAVE_INPUT_VALUES for a weird issue with the test where the whole suite fail
     * but the individual test do not.
     */
    if (
      this.props.id &&
      this.props.shouldSaveValue &&
      this.state.step &&
      typeof this.props.inputValuesActions[SAVE_INPUT_VALUES] === 'function'
    ) {
      this.props.inputValuesActions[SAVE_INPUT_VALUES](`${this.state.step}_${this.props.id}`, this.state.value);
    }

    this.validate = undefined;
  }

  handleOnChange(e, value) {
    // pass wrapper component handleChange
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e, value);
    }
    // pass group triggerOnChange
    if (typeof this.props.triggerOnChange === 'function') {
      this.props.triggerOnChange(e, value);
    }
    // update current value for the selected option
    this.updateStoreCurrentInput(value);
    this.updateValue(value);
  }

  componentDidUpdate(prevProps) {
    if (this.props.entityType !== prevProps.entityType && this.props.entityType === 'Jurídica' && this.props.id === 'select') {
      this.updateStoreCurrentInput('[NIT]');
      this.updateValue('[NIT]');
    }

    if (this.props.entityType !== prevProps.entityType && this.props.entityType === 'Natural'&& this.props.id === 'select') {
      this.updateStoreCurrentInput('[CC]');
      this.updateValue('[CC]');
    }

    if (this.props.entityType !== prevProps.entityType && this.props.entityType === 'Natural' && this.props.id === 'select') {
      if (this.props.selectType === 'C.C.') {
        this.updateStoreCurrentInput('[CC]');
        this.updateValue('[CC]');
      } else if (this.props.selectType === 'C.E.') {
        this.updateStoreCurrentInput('[CE]');
        this.updateValue('[CE]');
      }
    }

    if (this.props.selectType !== prevProps.selectType && this.props.selectType === 'C.C.' && this.props.id === 'entity_type') {
      // If it has changed, update the local state to [association] which is the same as legal
      this.updateStoreCurrentInput('[individual]');
      this.updateValue('[individual]');
    }

    if (this.props.selectType !== prevProps.selectType && this.props.selectType === 'C.E.' && this.props.id === 'entity_type') {
      // If it has changed, update the local state to [association] which is the same as legal
      this.updateStoreCurrentInput('[individual]');
      this.updateValue('[individual]');
    }

    if (this.props.selectType !== prevProps.selectType && this.props.selectType === 'NIT' && this.props.id === 'entity_type') {
      this.updateStoreCurrentInput('[association]');
      this.updateValue('[association]');
    }
  }

  updateValue(value) {
    this.setState({
      value,
    }, () => {
      /**
       * if the component has state invalid (has failed a recent validation)
       * validate again on value update
       */
      if (this.state.invalid) {
        this.validate();
      }
    });
  }

  /**
   * this method is an override to the parent class BaseValidation.getComponentValue
   * to remove the brackets of the value from the component [*]
   */
  getComponentValue() {
    const value = (this.state.value || '').toString();
    // Remove the brakets from option value
    return value.replace(/\[|\]/g, '');
  }

  getSelectAdditionalInformation(value) {
    const option = this.props.options.find(it => it.value === value);
    const result = {
      optionMessage: null,
      selectAdditionalInfo: null,
    };
    /* istanbul ignore next */
    if (option) {
      if (option.selectAdditionalInfo) {
        result.selectAdditionalInfo = option.selectAdditionalInfo;
      } else if (option.message) {
        result.optionMessage = option.message;
      }
    }
    return result;
  }

  /**
   * this is because flows can send an empty option as placeholder
   * so we can use the placeholder prop , the option with empty value or default to "Elige"
   * @TODO implement a propery to send from flow to optimus to populate the options with a correrct placeholder
   * @param {*} props
   */
  getPlaceholder(props) {
    const {CONTEXT, CHOOSE_OPTION} = this.translations;
    const placeholderOption = props.options.filter(opt => opt.value === '' && opt.label)[0];
    const defaultPlaceholder = placeholderOption ? '' : props.i18n.pgettext(CONTEXT, CHOOSE_OPTION);
    return props.placeholder ? props.placeholder : defaultPlaceholder;
  }

  render() {
    const {
      invalid,
      errors,
      shouldSaveValue,
      savedValue,
      saveInputValue,
      triggerOnMount,
      triggerOnChange,
      validateCallback,
      validations,
      showErrorMessage,
      inputValuesActions, // eslint-disable-line
      error,
      errorMessage,
      options,
      i18n,
      deviceType,
      disabled,
      showSearch,
      entityTypes,
      selectTypes,
      ...selectProps
    } = this.props;

    const {
      selectAdditionalInfo,
      optionMessage,
    } = this.getSelectAdditionalInformation(this.state.value);

    let className = this.props.className || '';
    if (options.length > 0 && options[0].value === '') {
      className += ' placeholder';

      if (this.props.unselected) {
        className += ' unselected ';
      }
    }

    if (optionMessage) {
      selectProps.message = optionMessage;
      className += ' fixedMessage';
    }

    /**
     * Set message error for select component
     * and set valid to false to show the error messages
     */
    if (this.state.invalid && this.state.error.length > 0 && showErrorMessage) {
      const [firstError] = this.state.error;
      selectProps.message = firstError;
      selectProps.valid = false;
    }

    const optionsList = Array.from(options);
    const placeholder = this.getPlaceholder(this.props);

    return (
      <>
        <Dropdown
          {...selectProps}
          id={COW_SELECT}
          onChange={this.handleOnChange}
          value={this.state.value}
          className={className}
          disabled={disabled || options.length <= 0}
          type="form"
          search={showSearch}
          key={this.state.selectKey} // this is necessary to reset component when options changed
          placeholder={placeholder}
          menuMaxHeight={220}
          menuAlignment="bottom"
          helper={selectProps.message}
          modifier={this.state.invalid && "error"}
        >
          {optionsList.map(({ value, label, additionalInfo, interestFree }, index) => (
            (index === 0 && !value)
              ? (<DropdownItem value="" title={label} selected disabled />)
              : (
                <DropdownItem
                  name={value}
                  key={`dd_${value}`}
                  value={value}
                  title={label}
                  description={additionalInfo}
                  className={classNames({ 'free-interest': interestFree })}
                />
              )
          ))}
        </Dropdown>
        {this.state.value
          && (<input type="hidden" value={this.state.value} name={selectProps.name} />)}
        {selectAdditionalInfo
          && <div className="select-additional-info">{selectAdditionalInfo}</div>}
      </>
    );
  }
}

/**
 * Prop Types
 */
Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  shouldSaveValue: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
    toShow: PropTypes.arrayOf(PropTypes.string),
    toHide: PropTypes.arrayOf(PropTypes.string),
  })),
  inputValuesActions: PropTypes.shape({
    save_input_values: PropTypes.func,
    current_input_values: PropTypes.func,
    visibility_input_values: PropTypes.func,
  }),
  showErrorMessage: PropTypes.bool,
  triggerOnMount: PropTypes.func,
  triggerOnChange: PropTypes.func,
  saveInputValue: PropTypes.bool,
  savedValue: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    pgettext: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  stateoptions: PropTypes.string,
};

/**
 * Default Props
 */
/* istanbul ignore next: cant test it with tests */
Select.defaultProps = {
  id: `sel-${Math.random().toString(36).substr(2, 9)}`,
  value: '',
  name: '',
  className: '',
  label: '',
  hint: '',
  error: [],
  errorMessage: '',
  disabled: false,
  options: [],
  triggerOnMount: null,
  triggerOnChange: null,
  showErrorMessage: true,
  saveInputValue: true,
  showPlaceholder: false,
  shouldSaveValue: true,
  savedValue: '',
  step: '',
  inputValuesActions: {
    save_input_values: () => { },
    current_input_values: () => { },
    visibility_input_values: () => { },
  },
  i18n: {
    gettext: t => t,
    pgettext: (c, t) => t,
  },
  stateoptions: '',
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state, ownProps) => ({
  step: state.page.flow.step,
  savedValue: state.inputValues.current[`${state.page.flow.step}_${ownProps.id}`],
  options: ownProps.stateoptions ? state.selectOptions[`${state.page.flow.step}_${ownProps.stateoptions}`] : ownProps.options,
  entityType: state.inputValues.current[`${state.page.flow.step}_entity_type_label`],
  selectType: state.inputValues.current[`${state.page.flow.step}_select_label`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = Select;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(Select)));
}
