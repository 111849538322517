const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const Chevron = ({ className = '' }) => (
  <IconWrapper>
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="7" viewBox="0 0 18 7">
      <defs>
        <filter id="chevron-1" width="119.7%" height="115.3%" x="-9.9%" y="-5.4%" filterUnits="objectBoundingBox">
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="chevron-2" width="112.3%" height="118.5%" x="-6.1%" y="-9.3%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.6 0 0 0 0 0.6 0 0 0 0 0.6 0 0 0 0.2 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        fill="#333"
        d="M8.746 2.118L1.46 5.888a1 1 0 0 1-.92-1.776l7.731-4A1 1 0 0 1 9.166.1l8.27 4a1 1 0 0 1-.871 1.8l-7.82-3.782z"
        filter="url(#chevron-2)"
        opacity="0.5"
      />
    </svg>
  </IconWrapper>
);

Chevron.propTypes = {
  className: PropTypes.string,
};

module.exports = Chevron;
