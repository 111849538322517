/**
 * Calculates the percentage of a value in relation to a total.
 * @param {number} value - The value for which to calculate the percentage.
 * @param {number} total - The total with which to calculate the percentage. If not provided, it is assumed as 1.
 * @returns {string} The calculated percentage as a string with a precision of 1 decimal.
 */

function calcPercentage(value, total) {
  const totalValue = total || '1'; // If total is not defined, it is assumed as 1
  const p = (value / totalValue) * 100.0;
  const result = p.toFixed(p % 1 === 0 ? 0 : 1); // Rounds the result to 0 decimals if it's an integer, otherwise to 1 decimal
  return result;
}

module.exports = calcPercentage;
