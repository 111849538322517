import React from 'react';

const Lock = ({
  className,
  id,
  width = 12,
  height = 16,
  ...props
}: SVGProps) => (
  <svg
    id={id}
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.18502 10.3949C7.18502 11.0549 6.65 11.5899 5.99002 11.5899C5.33004 11.5899 4.79502 11.0549 4.79502 10.3949C4.79502 9.7349 5.33004 9.19988 5.99002 9.19988C6.65 9.19988 7.18502 9.7349 7.18502 10.3949Z"
      fill="black"
      fillOpacity="0.55"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2012 6.20015H9.60941V3.80941C9.60941 1.81599 7.99343 0.200012 6.00002 0.200012C4.0066 0.200012 2.39062 1.81599 2.39062 3.80941V6.20015H0.798828V12.798C0.798828 14.4549 2.14197 15.798 3.79883 15.798H8.2012C9.85806 15.798 11.2012 14.4549 11.2012 12.798V6.20015ZM8.40941 3.80941V6.20015H3.59062V3.80941C3.59062 2.47873 4.66934 1.40001 6.00002 1.40001C7.33069 1.40001 8.40941 2.47873 8.40941 3.80941ZM1.99883 12.798V7.40015H10.0012V12.798C10.0012 13.7921 9.19532 14.598 8.2012 14.598H3.79883C2.80472 14.598 1.99883 13.7921 1.99883 12.798Z"
      fill="black"
      fillOpacity="0.55"
    />
  </svg>
);

export default React.memo(Lock);
