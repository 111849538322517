/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { match, Routes } = require('frontend-spa');

const routerActions = require('../../actions/router');
const chunksActions = require('../../actions/chunks');
const { CHUNK_LOADED, LOADER_STOP_ANIMATION_RESET } = require('../../actions/types');
const { getQueryParams } = require('../../../utils/Dom');
const animationActions = require('../../actions/animations');

/**
 * This is going to be the container for all the Single Page Application Routes
 * It is only going to:
 *
 * - Render the current route based on the location
 * - Fetch the action & load the chunk (bundle from webpack / loaded by loadable)
 * - Connect all the Redux components
 */
class AppContainer extends React.Component {
  /**
   * If the location change (page change), fetch the data and chunk
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Only use the pathname because the querystring are not use to match routes on react-router
    const currentPathname = this.props.location.pathname;
    const nextPathname = nextProps.location.pathname;

    if (nextPathname !== currentPathname) {
      this.fetch(nextPathname);
    }
  }

  /**
   * Important:
   * This is a hack made for the Checkout. If the page changes the container will stop the render
   * and is going to be showing the STEP loader. This is also going to be working when BACK and FORWARD are press
   * EVERY PAGE must have a StepLoader
   * @param nextProps
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps) {
    const { action } = nextProps.history;
    const currentPathname = this.props.location.pathname;
    const nextPathname = nextProps.location.pathname;
    const shouldRender = nextProps.loading === false && currentPathname === nextPathname;

    // If you are doing back and forward step the render and show the step loading
    if (action === 'POP' && currentPathname !== nextPathname) {
      return false;
    }

    if (shouldRender) {
      this.props.animationActions[LOADER_STOP_ANIMATION_RESET]();
    }
    // Wait for the data finish fetching and the path are the same to make the change
    // 1.) The location is going to change, but the dispatcher is not run yet
    // 2.) The dispatcher change the loading = true and the path are now the same
    // 3.) Ends fetching the data and loading = false
    return (shouldRender);
  }

  /**
   * Fetch the data (with routerActions) & the corresponding chunk
   * @param pathname
   */
  fetch(pathname) {
    const matchRoute = match(this.props.routes, pathname);

    if (matchRoute) {
      // Preload Chunk
      this.props.chunksActions[CHUNK_LOADED](matchRoute.params, getQueryParams(), matchRoute.component, this.props.history);

      if (matchRoute.action && Object.keys(this.props.routerActions).includes(matchRoute.action)) {
        this.props.routerActions[matchRoute.action](matchRoute.params, getQueryParams(), this.props.history);
      }
    }

    return matchRoute;
  }

  render() {
    const { location, pageData, routes } = this.props;

    // Merge props and the pageData in one single object to pass to the route render
    const merged = { ...this.props, ...pageData };

    return (
      <Routes
        routes={routes}
        extraProps={merged}
        location={location}
      />
    );
  }
}

/**
 * Default Values
 */
AppContainer.defaultProps = {
  location: {
    pathname: '',
    search: '',
    hash: '',
  },
  route: [],
  routerActions: {},
  chunksActions: {},
  pageData: {}, // This value comes from Redux
  loading: false, // This value comes from Redux
  userDataActions: null,
};

/**
 * PropTypes
 */
AppContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
  route: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  routerActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  chunksActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  pageData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  animationActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  chunksActions: bindActionCreators(chunksActions, dispatch),
  animationActions: bindActionCreators(animationActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  loading: state.request.loading,
  pageData: state.page.data,
});

/**
 * export App
 */
if (process.env.NODE_ENV === 'test') {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(AppContainer);
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(AppContainer);
}
