const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const UserDefaultIcon = require('../icons/UserDefault');
const UserDefaultIconML = require('../icons/UserDefaultML');
const UserAvatar = require('../UserAvatar');

const UserInfo = (props) => {
  const DefaultIcon = props.enableMlTheme ? UserDefaultIconML : UserDefaultIcon;
  const avatar = props.imageURL ? <UserAvatar image={props.imageURL} /> : <DefaultIcon />;

  return (
    <div className="user-info">
      <div className="user-info__text">{props.userName}</div>
      {avatar}
    </div>
  );
};

UserInfo.propTypes = {
  userName: PropTypes.string,
  imageURL: PropTypes.string,
  enableMlTheme: PropTypes.bool,
};

UserInfo.defaultProps = {
  userName: '',
  imageURL: null,
  enableMlTheme: false,
};

const mapStateToProps = (state) => ({
  enableMlTheme: state.configurations.enableMlTheme,
  internalConfigurations: state.configurations.internalConfigurations,
});

module.exports = connect(mapStateToProps)(UserInfo);

if (process.env.NODE_ENV === 'test') {
  module.exports = UserInfo;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(UserInfo);
}
