const { handleActions } = require('redux-actions');

const { MANAGE_CARD_FORM_ERROR } = require('../actions/types');

const actions = {};

const initialState = {
  isManagingError: false,
};

actions[MANAGE_CARD_FORM_ERROR] = (state, action) => ({
  ...state,
  isManagingError: action.payload,
});

module.exports = handleActions(actions, initialState);
