const React = require('react');
const PropTypes = require('prop-types');

const SearchBox = require('@andes/search-box');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const bankSearchActionsCreators = require('../../spa/actions/bankSearch');
const { BANK_SEARCH } = require('../../spa/actions/types');

const BankSearch = ({ bankSearchActions }) => (
  <div className="bank-search-wrapper">
    <SearchBox
      onChange={({ currentTarget: { value } }) => bankSearchActions[BANK_SEARCH](value)}
    />
  </div>
);

BankSearch.propTypes = {
  bankSearchActions: PropTypes.objectOf(
    PropTypes.shape({
      [BANK_SEARCH]: PropTypes.func.isRequired,
    }),
  ),
};

const mapDispatchToProps = (dispatch) => ({
  bankSearchActions: bindActionCreators(bankSearchActionsCreators, dispatch),
});

const connectWithRedux = connect(null, mapDispatchToProps);

if (process.env.NODE_ENV === 'test') {
  module.exports = BankSearch;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connectWithRedux(BankSearch);
}
