const React = require('react');

const IconWrapper = require('../IconWrapper');

const ErrorIllustration = () => (
  <IconWrapper>
    <svg width="320" height="168" viewBox="0 0 320 168" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M173.1 28.0683C162.551 28.7355 150.839 33.4684 144.432 42.1741C137.397 51.7301 131.468 57.7828 122.487 62.1861C113.912 66.3929 96.9886 70.2015 90.1483 83.1795C81.6301 99.339 92.6775 115.656 111.124 124.104C131.737 133.545 168.427 139.078 196.893 121.086C224.724 103.494 226.388 74.6317 219.767 58.8394C210.586 36.9422 187.536 27.1533 173.1 28.0683Z" fill="black" fill-opacity="0.04" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M109.295 108.649C109.295 108.649 109.295 108.649 110.88 110.484C112.465 112.319 112.465 112.319 112.465 112.319L112.462 112.321L112.457 112.326L112.441 112.34C112.427 112.352 112.407 112.369 112.382 112.39C112.332 112.433 112.26 112.493 112.169 112.569C111.986 112.721 111.723 112.937 111.395 113.199C110.739 113.721 109.814 114.432 108.734 115.187C106.64 116.65 103.72 118.458 100.979 119.224C97.6064 120.167 94.5755 119.124 92.4046 118.377C92.2011 118.307 92.0052 118.24 91.8172 118.177C89.4127 117.372 88.066 117.137 86.5824 118.038C85.2609 118.84 84.9805 120.163 84.6838 123.077C84.6744 123.17 84.665 123.264 84.6554 123.36C84.4115 125.809 84.0327 129.614 80.4764 131.772L77.9603 127.626C79.2819 126.824 79.5623 125.501 79.859 122.586C79.8683 122.494 79.8777 122.4 79.8873 122.303C80.1312 119.854 80.51 116.05 84.0663 113.892C87.6007 111.747 90.9285 112.765 93.3572 113.578C93.4914 113.623 93.6232 113.668 93.7527 113.711C96.1721 114.525 97.8092 115.075 99.6732 114.554C101.576 114.022 103.932 112.627 105.955 111.212C106.933 110.528 107.776 109.881 108.374 109.405C108.672 109.168 108.907 108.975 109.065 108.843C109.144 108.777 109.204 108.726 109.243 108.693C109.263 108.677 109.277 108.664 109.286 108.657L109.294 108.649L109.295 108.649Z" fill="white" />
      <path d="M80.4764 131.772C84.0327 129.614 84.4115 125.809 84.6554 123.36C84.665 123.264 84.6744 123.17 84.6838 123.077C84.9805 120.163 85.2609 118.84 86.5824 118.038C88.066 117.137 89.4127 117.372 91.8172 118.177C92.0052 118.24 92.2011 118.307 92.4046 118.377C94.5755 119.124 97.6064 120.167 100.979 119.224C103.72 118.458 106.64 116.65 108.734 115.187C109.814 114.432 110.739 113.721 111.395 113.199C111.723 112.937 111.986 112.721 112.169 112.569C112.26 112.493 112.332 112.433 112.382 112.39C112.407 112.369 112.427 112.352 112.441 112.34L112.457 112.326L112.462 112.321L112.465 112.319C112.465 112.319 112.465 112.319 110.88 110.484C109.295 108.649 109.295 108.649 109.295 108.649L109.294 108.649L109.286 108.657C109.277 108.664 109.263 108.677 109.243 108.693C109.204 108.726 109.144 108.777 109.065 108.843C108.907 108.975 108.672 109.168 108.374 109.405C107.776 109.881 106.933 110.528 105.955 111.212C103.932 112.627 101.576 114.022 99.6732 114.554C97.8092 115.075 96.1721 114.525 93.7527 113.711C93.6232 113.668 93.4914 113.623 93.3572 113.578C92.3081 113.227 91.0913 112.837 89.7625 112.681M77.9603 127.626C79.2819 126.824 79.5623 125.501 79.859 122.586C79.8683 122.494 79.8777 122.4 79.8873 122.303C80.1312 119.854 80.51 116.05 84.0663 113.892C84.6732 113.524 85.274 113.249 85.8652 113.05" stroke="#333333" stroke-width="1.5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M200.8 59.4832C200.8 59.4832 200.801 59.4828 199.216 57.6479C197.63 55.813 197.631 55.8125 197.631 55.8125L197.633 55.8104L197.638 55.806L197.655 55.7916C197.669 55.7797 197.689 55.763 197.714 55.7417C197.764 55.6993 197.835 55.6389 197.927 55.5627C198.11 55.4104 198.372 55.1945 198.701 54.9328C199.357 54.4104 200.282 53.6999 201.362 52.945C203.455 51.4814 206.376 49.674 209.116 48.9076C212.489 47.9644 215.52 49.0074 217.691 49.7544C217.895 49.8244 218.091 49.8918 218.279 49.9548C220.683 50.76 222.03 50.9944 223.513 50.094C224.835 49.292 225.115 47.969 225.412 45.0544C225.421 44.9623 225.431 44.8679 225.44 44.7714C225.684 42.3224 226.063 38.5181 229.619 36.3599L232.135 40.5059C230.814 41.3079 230.533 42.6308 230.237 45.5455C230.227 45.6376 230.218 45.7319 230.208 45.8284C229.964 48.2775 229.586 52.0817 226.029 54.24C222.495 56.3849 219.167 55.3668 216.738 54.5535C216.604 54.5086 216.473 54.4643 216.343 54.4207C213.924 53.6073 212.286 53.0569 210.423 53.5782C208.52 54.1102 206.164 55.5053 204.141 56.9196C203.162 57.6036 202.32 58.2509 201.722 58.7266C201.424 58.964 201.189 59.1572 201.03 59.2892C200.951 59.3551 200.891 59.4057 200.852 59.4387C200.833 59.4552 200.819 59.4673 200.81 59.4748L200.801 59.4824L200.8 59.4832Z" fill="white" />
      <path d="M229.619 36.3599C226.063 38.5181 225.684 42.3224 225.44 44.7714C225.431 44.8679 225.421 44.9623 225.412 45.0544C225.115 47.969 224.835 49.292 223.513 50.094C222.03 50.9944 220.683 50.76 218.279 49.9548C218.091 49.8918 217.895 49.8244 217.691 49.7544C215.52 49.0074 212.489 47.9644 209.116 48.9076C206.376 49.674 203.455 51.4814 201.362 52.945C200.282 53.6999 199.357 54.4104 198.701 54.9328C198.372 55.1945 198.11 55.4104 197.927 55.5627C197.835 55.6389 197.764 55.6993 197.714 55.7417C197.689 55.763 197.669 55.7797 197.655 55.7916L197.638 55.806L197.633 55.8104L197.631 55.8125C197.631 55.8125 197.63 55.813 199.216 57.6479C200.801 59.4828 200.8 59.4832 200.8 59.4832L200.801 59.4824L200.81 59.4748C200.819 59.4673 200.833 59.4552 200.852 59.4387C200.891 59.4057 200.951 59.3551 201.03 59.2892C201.189 59.1572 201.424 58.964 201.722 58.7266C202.32 58.2509 203.162 57.6036 204.141 56.9196C206.164 55.5053 208.52 54.1102 210.423 53.5782C212.286 53.0569 213.924 53.6073 216.343 54.4207C216.473 54.4643 216.604 54.5086 216.738 54.5535C217.536 54.8207 218.431 55.11 219.399 55.302M232.135 40.5059C230.814 41.3079 230.533 42.6308 230.237 45.5455C230.227 45.6376 230.218 45.7319 230.208 45.8284C230.055 47.3709 229.848 49.451 228.827 51.3188M223.455 55.302C224.294 55.1075 225.155 54.7705 226.029 54.24C226.108 54.1923 226.185 54.1438 226.261 54.0945" stroke="#333333" stroke-width="1.5" />
      <path d="M140.225 78.6425C139.347 77.196 139.808 75.3118 141.255 74.434L152.462 67.6327C153.908 66.7549 155.792 67.2158 156.67 68.6622C157.548 70.1087 157.087 71.9929 155.641 72.8707L144.434 79.672C142.987 80.5499 141.103 80.0889 140.225 78.6425Z" fill="white" stroke="#333333" stroke-width="1.5" />
      <line x1="153.378" y1="63.5214" x2="151.137" y2="59.8285" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
      <line x1="159.455" y1="65.7627" x2="163.148" y2="63.5216" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
      <line x1="156.85" y1="63.5493" x2="158.25" y2="59.4629" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
      <path d="M152.978 99.6544C153.856 101.101 155.74 101.562 157.186 100.684L168.393 93.8827C169.84 93.0049 170.301 91.1207 169.423 89.6742C168.545 88.2278 166.661 87.7668 165.214 88.6446L154.008 95.446C152.561 96.3238 152.1 98.208 152.978 99.6544Z" fill="white" stroke="#333333" stroke-width="1.5" />
      <path d="M166.883 53.8527L186.165 42.1505C190.179 39.7149 195.406 40.9939 197.842 45.0071L209.376 64.0133C211.812 68.0265 210.533 73.2542 206.52 75.6897L187.237 87.392L166.883 53.8527Z" fill="#EEEEEE" stroke="white" stroke-width="3" />
      <path d="M164.822 53.3486L185.387 40.8681C190.109 38.0028 196.259 39.5074 199.124 44.2288L210.659 63.235C213.524 67.9564 212.019 74.1067 207.298 76.9721L186.733 89.4526L164.822 53.3486Z" stroke="#333333" stroke-width="1.5" />
      <path d="M105.721 107.923C105.148 106.978 105.449 105.748 106.393 105.175L120.939 96.3478L128.33 108.527L113.785 117.355C112.841 117.928 111.611 117.627 111.038 116.683L105.721 107.923Z" fill="#EEEEEE" stroke="#333333" stroke-width="1.5" />
      <path d="M123.792 110.638L116.972 99.3992L118.888 87.4544C119.05 86.4406 119.65 85.5493 120.528 85.0166L129.937 79.306L147.602 108.413L138.192 114.124C137.314 114.656 136.247 114.777 135.273 114.453L123.792 110.638Z" fill="#EEEEEE" stroke="white" stroke-width="3" />
      <path d="M115.403 99.7071L117.406 87.2179C117.639 85.7695 118.495 84.4963 119.749 83.7352L130.441 77.2464L149.663 108.918L138.97 115.407C137.716 116.168 136.192 116.34 134.8 115.877L122.796 111.889L115.403 99.7071Z" stroke="#333333" stroke-width="1.5" />
      <rect x="122.847" y="73.5205" width="17.3841" height="51.2973" rx="5" transform="rotate(-31.2529 122.847 73.5205)" fill="white" />
      <rect x="122.847" y="73.5205" width="17.3841" height="51.2973" rx="5" transform="rotate(-31.2529 122.847 73.5205)" fill="white" stroke="#333333" stroke-width="1.5" />
      <path d="M189.761 54.341C189.017 53.1142 189.408 51.5161 190.634 50.7715V50.7715C191.861 50.027 193.459 50.418 194.204 51.6448L201.044 62.9162C201.789 64.143 201.398 65.7411 200.171 66.4856V66.4856C198.944 67.2302 197.346 66.8392 196.602 65.6124L189.761 54.341Z" stroke="#333333" stroke-width="1.5" />
      <rect x="160.917" y="50.417" width="17.3841" height="51.2973" rx="5" transform="rotate(-31.2529 160.917 50.417)" fill="white" />
      <rect x="160.917" y="50.417" width="17.3841" height="51.2973" rx="5" transform="rotate(-31.2529 160.917 50.417)" stroke="#333333" stroke-width="1.5" />
      <line x1="111.927" y1="101.885" x2="119.319" y2="114.065" stroke="#333333" stroke-width="1.5" />
      <line x1="108.231" y1="104.146" x2="115.623" y2="116.326" stroke="#333333" stroke-width="1.5" />
      <line x1="0.75" y1="-0.75" x2="5.06969" y2="-0.75" transform="matrix(0.202929 0.979193 0.979193 -0.202929 171.031 95.9102)" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
      <line x1="0.75" y1="-0.75" x2="5.06969" y2="-0.75" transform="matrix(0.979193 -0.202929 -0.202929 -0.979193 172.482 90.4326)" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
      <line x1="0.75" y1="-0.75" x2="5.06969" y2="-0.75" transform="matrix(0.881947 0.471349 0.471349 -0.881947 172.663 93.3945)" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  </IconWrapper>
);

module.exports = ErrorIllustration;
