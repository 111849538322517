const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');

const IconWrapper = require('../IconWrapper');

const OverflowMenuHorizontal = ({ status, width, height }) => (
  <IconWrapper className={classNames(status, { [`icon-${status}`]: true })}>
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 13.5029C18.6716 13.5029 18 12.8314 18 12.0029C18 11.1745 18.6716 10.5029 19.5 10.5029C20.3284 10.5029 21 11.1745 21 12.0029C21 12.8314 20.3284 13.5029 19.5 13.5029Z" />
      <path d="M12 13.4969C11.1716 13.4969 10.5 12.8254 10.5 11.9969C10.5 11.1685 11.1716 10.4969 12 10.4969C12.8284 10.4969 13.5 11.1685 13.5 11.9969C13.5 12.8254 12.8284 13.4969 12 13.4969Z" />
      <path d="M3 11.9969C3 12.8254 3.67157 13.4969 4.5 13.4969C5.32843 13.4969 6 12.8254 6 11.9969C6 11.1685 5.32843 10.4969 4.5 10.4969C3.67157 10.4969 3 11.1685 3 11.9969Z" />
    </svg>
  </IconWrapper>
);

OverflowMenuHorizontal.defaultProps = {
  status: '',
  width: 32,
  height: 32,
};

OverflowMenuHorizontal.propTypes = {
  status: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

module.exports = React.memo(OverflowMenuHorizontal);
