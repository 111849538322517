/* global window */
const { bindEventListener } = require('./Dom');

const postMessageToRender = (action, data = {}) => {
  if (!window.parent || typeof window.parent.postMessage === 'undefined') {
    return;
  }

  const json = JSON.stringify({ action, data });

  window.parent.postMessage(json, '*');
};

// Called when close popup
const finalize = (components = []) => {
  const data = {};

  components.forEach((c) => {
    data[c.id] = c.value || null;
  });

  postMessageToRender('finalize', {
    back_url: data.back_url,
    collection_id: data.payment_id,
    collection_status: data.payment_status,
    external_reference: data.external_reference,
    preference_id: data.preference_id,
  });
};

const closeListener = (event, components = []) => {
  if (event.data.action !== 'requestClose') {
    return;
  }

  finalize(components);
};

module.exports = {
  start: (components) => {
    postMessageToRender('v2_init'); // Set iframe to 100% and send ready event
    // TODO: components for closeListener should have same fields than finalize
    bindEventListener(window, 'message', (e) => closeListener(e, components));
  },
  finalize,
};
