const React = require('react');

const IconWrapper = require('../IconWrapper');

const GrowthCoupon = () => (
  <IconWrapper className="icon-growth-coupon">
    <svg width="69" height="64" viewBox="0 0 69 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.3276 21.7759C60.4256 3.98984 53.8072 1.14304 49.454 0.194597C45.1871 -0.735021 39.9645 1.72943 36.6758 6.23192C33.0654 11.1752 30.7381 13.3578 26.7366 15.2408C22.9149 17.0392 14.8414 18.2164 11.3655 25.7831C7.19163 34.8678 9.86421 44.4012 16.72 50.4683C24.3812 57.2485 38.6115 61.7876 51.8956 54.6582C65.1792 47.5289 66.4323 31.3693 64.3276 21.7759Z"
        fill="#E6F5FC"
      />
      <path
        d="M58.7312 25.878C58.7312 35.6338 50.9324 43.5425 41.312 43.5425C36.0368 43.5425 31.3092 41.1645 28.1149 37.408C27.8339 37.0776 27.5648 36.7365 27.3083 36.3855C27.0503 36.0324 26.8049 35.6693 26.5729 35.2967C24.8753 32.5705 23.8928 29.3406 23.8928 25.878C23.8928 16.1223 31.6917 8.21362 41.312 8.21362C50.9324 8.21362 58.7312 16.1223 58.7312 25.878Z"
        fill="white"
      />
      <path
        d="M26.586 35.3327C24.8884 32.6065 23.8939 29.3385 23.8939 25.8759C23.8939 16.1201 31.6928 8.21149 41.3131 8.21149C46.5322 8.21149 51.2151 10.539 54.4077 14.2264M28.1003 37.3924C31.2946 41.149 36.0379 43.5403 41.3131 43.5403C50.9335 43.5403 58.7323 35.6317 58.7323 25.8759C58.7323 23.9398 58.4252 22.0765 57.8576 20.3334M56.8944 17.9696C56.6097 17.3939 56.295 16.8362 55.9523 16.2986"
        stroke="#333333"
      />
      <path
        d="M53.831 25.8794C53.831 32.9004 48.2202 38.5801 41.3136 38.5801C34.407 38.5801 28.7963 32.9004 28.7963 25.8794C28.7963 18.8585 34.407 13.1788 41.3136 13.1788C48.2202 13.1788 53.831 18.8585 53.831 25.8794Z"
        fill="#5AD2FF"
        stroke="white"
      />
      <path
        d="M44.6362 22.2395C44.157 21.1508 43.0762 20.4809 41.1841 20.4809C39.2022 20.4809 37.7296 21.6009 37.7296 23.2427C37.7296 25.2775 38.9308 25.6897 40.7549 26.1753C40.87 26.2068 40.9848 26.2383 41.0989 26.27L41.3261 26.3339C42.7942 26.7532 44.6362 27.1593 44.7983 28.7922C44.9604 30.4252 43.6693 31.88 41.0524 31.874C39.2022 31.8697 37.6392 30.9972 37.3589 29.6192"
        stroke="#333333"
      />
      <path d="M41.2406 18.4699V20.4797" stroke="#333333" />
      <path d="M41.2406 32.0131V34.0229" stroke="#333333" />
      <ellipse cx="41.3136" cy="25.879" rx="13.0174" ry="13.2006" stroke="#333333" />
      <path d="M28.874 43.9297L28.874 46.3749" stroke="#333333" stroke-linejoin="round" />
      <path d="M28.8741 48.4999L28.8741 50.9451" stroke="#333333" stroke-linejoin="round" />
      <path d="M25.3655 47.4363L27.8107 47.4363" stroke="#333333" stroke-linejoin="round" />
      <path d="M29.9338 47.4363L32.379 47.4363" stroke="#333333" stroke-linejoin="round" />
      <path d="M24.7279 4.29858L26.9508 4.29858" stroke="#333333" stroke-linejoin="round" />
      <path d="M28.881 4.29858L31.1038 4.29858" stroke="#333333" stroke-linejoin="round" />
      <path d="M27.9152 7.48688L27.9152 5.26403" stroke="#333333" stroke-linejoin="round" />
      <path d="M27.9152 3.33435L27.9152 1.1115" stroke="#333333" stroke-linejoin="round" />
      <path d="M65.7533 27.8597L65.7533 29.6378" stroke="#333333" stroke-linejoin="round" />
      <path d="M65.7882 31.1826L65.7882 32.9607" stroke="#333333" stroke-linejoin="round" />
      <path d="M68.3394 30.4087L66.5612 30.4087" stroke="#333333" stroke-linejoin="round" />
      <path d="M65.0166 30.4087L63.2385 30.4087" stroke="#333333" stroke-linejoin="round" />
      <path
        d="M65.2805 53.3872C65.2805 57.7491 61.7613 61.2741 57.4337 61.2741C53.1061 61.2741 49.5869 57.7491 49.5869 53.3872C49.5869 49.0253 53.1061 45.5002 57.4337 45.5002C61.7613 45.5002 65.2805 49.0253 65.2805 53.3872Z"
        fill="white"
        stroke="white"
        stroke-width="3"
      />
      <ellipse cx="57.3675" cy="53.3872" rx="9.34677" ry="9.38694" stroke="#333333" />
      <rect x="53.4232" y="55.0482" width="7.80479" height="3.03778" rx="0.5" fill="white" stroke="white" />
      <rect
        x="0.5"
        y="-0.5"
        width="9.14307"
        height="1.98312"
        rx="0.5"
        transform="matrix(1 0 0 -1 52.2441 53.5482)"
        fill="white"
        stroke="white"
      />
      <rect x="52.9232" y="54.5482" width="8.80479" height="4.03778" rx="1" stroke="#333333" />
      <rect width="10.1431" height="2.98312" rx="1" transform="matrix(1 0 0 -1 52.2441 54.5482)" stroke="#333333" />
      <rect x="56.3848" y="51.5649" width="1.8616" height="7.02091" fill="white" stroke="#333333" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.3253 51.2234V49.7055L57.3259 49.6615C57.3492 48.8436 58.0196 48.1876 58.8432 48.1876C59.6815 48.1876 60.3611 48.8672 60.3611 49.7055C60.3611 50.5291 59.7051 51.1995 58.8872 51.2228L58.8432 51.2234H57.3253Z"
        stroke="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.3143 51.2234V49.7055L57.3137 49.6615C57.2904 48.8436 56.62 48.1876 55.7964 48.1876C54.9581 48.1876 54.2786 48.8672 54.2786 49.7055C54.2786 50.5291 54.9345 51.1995 55.7525 51.2228L55.7964 51.2234H57.3143Z"
        stroke="#333333"
      />
      <path
        d="M17.8054 25.2582C17.8054 29.6201 14.2862 33.1451 9.95858 33.1451C5.63099 33.1451 2.11182 29.6201 2.11182 25.2582C2.11182 20.8962 5.63099 17.3712 9.95858 17.3712C14.2862 17.3712 17.8054 20.8962 17.8054 25.2582Z"
        fill="white"
        stroke="white"
        stroke-width="3"
      />
      <ellipse cx="9.89242" cy="25.2582" rx="9.34677" ry="9.38694" stroke="#333333" />
      <path d="M5.57998 21.8577L13.9564 28.7003" stroke="#333333" />
      <ellipse
        cx="7.18245"
        cy="28.2727"
        rx="1.43779"
        ry="1.43779"
        transform="rotate(90 7.18245 28.2727)"
        stroke="#333333"
      />
      <ellipse
        cx="12.0473"
        cy="22.2435"
        rx="1.43779"
        ry="1.43779"
        transform="rotate(90 12.0473 22.2435)"
        stroke="#333333"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(GrowthCoupon);
