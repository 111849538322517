/* eslint-disable max-len */
const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconAccountMoneyDisabled = () => (
  <IconWrapper className="icon-wrapper-disabled">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.049 23.352C18.2904 23.352 23.35 18.2924 23.35 12.051C23.35 5.80963 18.2904 0.75 12.049 0.75C5.80768 0.75 0.748047 5.80963 0.748047 12.051C0.748047 18.2924 5.80768 23.352 12.049 23.352ZM12.049 21.852C6.6361 21.852 2.24805 17.4639 2.24805 12.051C2.24805 6.63806 6.6361 2.25 12.049 2.25C17.462 2.25 21.85 6.63806 21.85 12.051C21.85 17.4639 17.462 21.852 12.049 21.852ZM9.20705 14.13L8.29705 15.39C8.99058 16.1305 9.98019 16.6737 11.299 16.8289V18H12.799V16.8358C14.8169 16.608 15.703 15.3899 15.703 13.99C15.703 11.96 13.869 11.484 12.329 11.092L12.3154 11.0886C11.2152 10.81 10.271 10.571 10.271 9.846C10.271 9.146 10.887 8.684 11.853 8.684C12.833 8.684 13.827 9.02 14.555 9.748L15.493 8.53C14.7914 7.85097 13.8885 7.41895 12.799 7.27831V6H11.299V7.27636C9.64739 7.51379 8.59105 8.59977 8.59105 9.958C8.59105 11.932 10.383 12.38 11.909 12.758L11.9318 12.7639C13.0563 13.0554 14.037 13.3096 14.037 14.144C14.037 14.76 13.505 15.418 12.161 15.418C10.859 15.418 9.85105 14.816 9.20705 14.13Z"
        fill="#444444"
        fillOpacity="0.45"
      />
    </svg>
  </IconWrapper>
);

module.exports = React.memo(IconAccountMoneyDisabled);
