const React = require('react');

const { Title } = require('@andes/typography');

const BackButton = require('../components/BackButton');
const CardListGuestSection = require('../components/CardListGuestSection');
const PaymentOptionPropTypes = require('../templatePropTypes');
const Page = require('../../../../components/Page');
const PaidWithCashSection = require('../components/PaidWithCashSection');
const Optimus = require('../../../../containers/Optimus');
const useHeightElement = require('../hooks/useHeightElement');

const PaymentOptionsMobileGuest = (props) => {
  const paddingContent = useHeightElement();

  return (
    <Page
      title={props.frontendData.pageTitle}
      currentStep={props.currentStep}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <section className="payment-options-mobile-screen payment-options-mobile-screen--guest">
        <Optimus
          components={props.frontendData.summary}
          history={props.history}
          siteId={props.siteId}
          urls={props.urls}
          publicKey={props.publicKey}
          deviceType={props.deviceType}
          captcha={props.captcha}
          hiddenComponents={props.hidden_components}
        />

        <div className="payment-options-mobile-screen__content" style={{ paddingTop: paddingContent }}>
          <Title component="h1" size="s" className="payment-options-mobile-screen__content-title">
            {props.frontendData.pageTitle}
          </Title>

          <div className="payment-options-mobile-screen__content-card-list">
            {!props.frontendData.showPayWithCashScreen && (
              <CardListGuestSection
                otherPaymentMethods={props.frontendData.otherPaymentMethods}
                goToScreen={props.frontendData.goToScreen}
                loginSection={props.frontendData.loginSection}
                guestSection={props.frontendData.guestSection}
                deviceType={props.deviceType}
                showPayWithCashSection={props.frontendData.showPayWithCash}
                isTheOptionToPayWithCash={props.frontendData.isTheOptionToPayWithCash}
              />
            )}

            {props.frontendData.showPayWithCashScreen && (
              <PaidWithCashSection
                {...props.frontendData.payWithCash[props.frontendData.showPayWithCashScreen]}
                onClickMethod={props.frontendData.goToStorePage}
                onNavigationBack={props.frontendData.hidePayWithCash}
              />
            )}

            {/* TODO USE BACK BUTTON FROM LIBRARY COMPONENT */}
            {props.frontendData.backButton && (
              <BackButton url={props.frontendData.backButton?.url} text={props.frontendData.backButton?.title} />
            )}
          </div>
        </div>
      </section>
    </Page>
  );
};

PaymentOptionsMobileGuest.propTypes = PaymentOptionPropTypes;

module.exports = PaymentOptionsMobileGuest;
