const { WEBVIEW_TYPES, INAPP } = require('../../constants/app');

const getExternalWebviewType = (navigator = {}, isDescriptionRequired = false) => {
  if (!!navigator?.inApp?.isActive && navigator?.inApp?.vendor === INAPP.VENDOR.UNKNOWN) {
    return WEBVIEW_TYPES.EXTERNAL;
  }
  if (isDescriptionRequired) {
    return navigator?.inApp?.vendor;
  }
  return '';
}

const isExternalWebview = (navigator) => getExternalWebviewType(navigator) === WEBVIEW_TYPES.EXTERNAL;

module.exports = {
  getExternalWebviewType,
  isExternalWebview,
};
