import type { ListItemProps } from '@andes/list';

import React from 'react';
import { Typography } from '@andes/typography';
import { ListItem } from '@andes/list';

const ShowMoreItemList = ({
  onClick,
  paddingSize,
  label,
}: {
  onClick: () => void;
  paddingSize: ListItemProps['paddingSize'];
  label: string;
}) => (
  <ListItem
    ref={(reference) => {
      if (reference) {
        const chevron = reference.querySelector('.andes-list__item-chevron');

        if (!chevron) {
          return;
        }

        (chevron as HTMLDivElement).onclick = onClick;
      }
    }}
    chevron
    className={'list-item__show-more-items--show'}
    onClick={onClick}
    paddingSize={paddingSize}
    rightContentPosition="centered"
    title={
      <Typography component="p" type="body" size="s">
        {label}
      </Typography>
    }
  />
);

export default ShowMoreItemList;
