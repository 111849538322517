/* eslint-disable jsx-a11y/label-has-associated-control */
const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const classNames = require('classnames');

const ErrorIcon = require('../icons/ErrorIcon');
const DefaultCardIcon = require('../icons/defaultCardIcon');
const GreyIcon = require('../icons/grey');
const ZeroDollarMessage = require('../ZeroDollarMessage');

const SecureFieldInput = (props) => {
  const { isFocused, errorMessage, label, field, paymentMethodIcon, showCvvIcon, id, hint } = props;
  /** this is just to avoid adding classes in camel case */
  const fieldNameToKebabCase = field.replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
  const containerClass = classNames('cow-secure-fields', fieldNameToKebabCase);
  const formControlClass = classNames({
    'andes-form-control--focused': isFocused,
    'andes-form-control--error': errorMessage,
  });
  const isCardNumber = field === 'cardNumber';
  const isCVV = field === 'securityCode';
  const shouldRenderCardIcon = paymentMethodIcon && isCardNumber;
  const cardIconClass = classNames('cow-secure-field-card-icon', {
    'icon-card--default': !shouldRenderCardIcon,
    'icon-card--pm': shouldRenderCardIcon,
  });

  /**
   * render card icon default | paymentmethod
   * @returns
   */
  const renderCardIcon = () => (
    <>
      {isCardNumber && (
        <div className={cardIconClass}>
          {shouldRenderCardIcon ? (
            <Image src={paymentMethodIcon} lazyload="off" alt="payment-method" />
          ) : (
            <DefaultCardIcon />
          )}
        </div>
      )}
    </>
  );
  return (
  <>
    {/* TODO delete after zero dollar is being done by bricks. */}
    {fieldNameToKebabCase === "card-number" && <ZeroDollarMessage />}

    <div className={containerClass}>
      <div
        className={`andes-form-control andes-form-control--textfield andes-form-control--default ${formControlClass}`}
      >
        <label>
          <span className="andes-form-control__label">{label}</span>
          <div className="andes-form-control__control">
            <div id={id} className="andes-form-control__field" />
            {showCvvIcon && isCVV && (
              <div className="andes-form-control__embedded">
                <GreyIcon />
              </div>
            )}
            {renderCardIcon()}
          </div>
        </label>
        {(hint || errorMessage) && (
          <div className="andes-form-control__bottom">
            { errorMessage && <ErrorIcon /> }
            <span className="andes-form-control__message">{errorMessage || hint}</span>
          </div>
        )}
      </div>
    </div>
  </>
  );
};

SecureFieldInput.propTypes = {
  id: PropTypes.string,
  field: PropTypes.oneOf(['cardNumber', 'expirationDate', 'securityCode']),
  label: PropTypes.string,
  showCvvIcon: PropTypes.bool,
  errorMessage: PropTypes.string,
  hint: PropTypes.string,
  isFocused: PropTypes.bool,
  paymentMethodIcon: PropTypes.string,
};
SecureFieldInput.defaultProps = {
  id: '',
  field: '',
  label: '',
  showCvvIcon: false,
  paymentMethodIcon: '',
  errorMessage: '',
  hint: '',
  isFocused: false,
};

module.exports = SecureFieldInput;
