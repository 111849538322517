import React from 'react';

import { Colors } from '../../../constants/styles';

type InstallmentsIconProps = {
  text: string;
  id?: string;
  color?: string;
  fontWeight?: 'bold' | 'regular' | 'semibold';
  className?: string;
};

const InstallmentsIcon = ({
  id,
  className,
  text,
  fontWeight = 'regular',
  color = Colors.COW_LIGHT_BLUE,
}: InstallmentsIconProps) => (
  <span
    className={`icon__installments-text ${className ?? ''}`}
    style={{ color, fontWeight }}
    id={id}
  >
    {text}
  </span>
);

export default React.memo(InstallmentsIcon);
