const {
  THEME: COMPANY_THEME,
  INTERNAL_CONFIGURATIONS,
} = require('../../../../../../constants/app');
const { MERCADO_LIBRE } = require('../../../../../translation');

const getCompanyTheme = (internalConfigurations) => {
  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_ML)){
    return COMPANY_THEME.ML;
  }

  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_MP)){
    return COMPANY_THEME.MP;
  }

  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.YELLOW_BUTTON)){
    return COMPANY_THEME.YELLOW_BUTTON;
  }

  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_POTS)){
    return COMPANY_THEME.POTS;
  }

  return COMPANY_THEME.DEFAULT;
};

/**
 *
 * @param theme
 * @param translation
 * @returns {string|*}
 */
const getBrandNameCompany = (theme, translation) => {
  if (theme === INTERNAL_CONFIGURATIONS.IN_ML || theme === INTERNAL_CONFIGURATIONS.YELLOW_BUTTON) {
    return translation.MERCADO_LIBRE;
  }
  return translation.MERCADO_PAGO;
};

module.exports = {
  getCompanyTheme,
  getBrandNameCompany
};
