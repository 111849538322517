/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const { injectValidations } = require('../../utils/validator-provider');
const installmentActions = require('../../spa/actions/installment');
const Select = require('../Select');
const {
  INSTALLMENT_UPDATE,
} = require('../../spa/actions/types');
const translate = require('../../translation');

class SelectInstallmentCardForm extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {};
    this.translations = translate(i18n);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentPaymentType = this.props.paymentMethod && this.props.paymentMethod.paymentType;
    const nextPaymentType = nextProps.paymentMethod && nextProps.paymentMethod.paymentType;
    if (currentPaymentType !== nextPaymentType) {
      if (this.isDebitCard(nextProps.paymentMethod)) {
        this.props.installmentActions[INSTALLMENT_UPDATE]({ label: '', value: 1 });
      }
    }
  }

  getDebitCardOptions() {
    return [{
      label: this.translations.NOT_APPLY_INSTALLMENTS,
      selected: true,
      id: 1,
      value: '[1]',
    }];
  }

  isDebitCard(paymentMethod) {
    return paymentMethod && paymentMethod.paymentType === 'debit_card';
  }

  render() {
    const {
      id,
      name,
      label,
      deviceType,
      error,
      invalid,
      errors,
      validations,
      options,
      paymentMethod,
    } = this.props;

    const isDebitCard = this.isDebitCard(paymentMethod);

    return (
      <Select
        id={id}
        name={name}
        label={label}
        options={isDebitCard ? this.getDebitCardOptions() : options}
        deviceType={deviceType}
        error={error}
        invalid={invalid}
        errors={errors}
        validations={validations}
        updateSummary
        disabled={isDebitCard}
      />
    );
  }
}

/**
 * Prop Types
 */
SelectInstallmentCardForm.propTypes = {
  deviceType: PropTypes.string,
  invalid: PropTypes.bool,
  validations: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  installmentActions: PropTypes.shape({
    installment_update: PropTypes.func,
  }),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  paymentMethod: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
};

/**
 * Default Props
 */

SelectInstallmentCardForm.defaultProps = {
  id: `sel-${Math.random().toString(36).substr(2, 9)}`,
  name: '',
  label: '',
  error: [],
  installmentActions: {
    installment_update: null,
  },
  i18n: {
    gettext: t => t,
  },
  stateoptions: '',
  paymentMethod: {},
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  installmentActions: bindActionCreators(installmentActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  options: state.selectOptions[`${state.page.flow.step}_installments_options`],
  paymentMethod: state.creditCard && state.creditCard.guessing && state.creditCard.guessing.paymentMethod,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SelectInstallmentCardForm;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(injectValidations(SelectInstallmentCardForm)));
}
