const React = require('react');

const IconWrapper = require('../IconWrapper');

const IconPhoneQR = () => (
  <IconWrapper>
    <svg width="140" height="99" viewBox="0 0 140 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_176_14927" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="140" height="99">
        <rect width="140" height="99" rx="6" fill="#009EE3" />
      </mask>
      <g mask="url(#mask0_176_14927)">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.926 49.9415C112.602 82.7181 102.148 95.4641 94.3632 102.399C86.7324 109.196 73.8505 111.865 62.8369 108.919C50.7458 105.684 43.9389 105.173 34.0739 107.227C24.6523 109.188 7.32708 117.444 -7.07457 109.926C-24.3666 100.901 -28.3895 82.6057 -20.6263 64.5122C-11.9518 44.2926 12.0817 19.3679 45.6948 13.9272C79.307 8.48705 97.7071 32.2626 102.926 49.9415Z" fill="url(#paint0_linear_176_14927)" fillOpacity="0.8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M125.299 108.78C102.446 142.465 82.7981 142.521 70.5217 140.627C58.4884 138.77 46.2805 128.972 40.6572 116.638C34.4843 103.096 29.8029 96.4643 20.4456 88.9681C11.5087 81.8088 -9.20501 72.162 -13.1837 53.2593C-17.9623 30.5637 -4.16278 13.0257 18.3711 6.45374C43.5524 -0.891287 84.6901 2.29387 115.14 29.0146C145.588 55.7349 137.625 90.6111 125.299 108.78Z" fill="url(#paint1_linear_176_14927)" fillOpacity="0.8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2115 79.2851C25.2115 79.2851 23.3408 52.3607 24.9509 49.1453C25.8581 47.3351 29.5757 36.8969 32.082 27.5613C34.026 20.3215 34.6441 13.5245 36.4526 13.3245C37.8094 13.1742 38.9659 13.8718 39.903 15.0023V38.5877C39.4982 40.369 39.2131 41.5682 39.2131 41.5682L39.903 42.0926L39.9917 83.4879C39.9917 86.6883 43.12 89.3749 46.3262 89.3749C46.3262 89.3749 78.6161 87.5647 76.8963 89.3749C76.0813 90.2328 71.2565 95.2997 70.5271 95.9368C68.6687 97.5601 65.2896 99.5174 62.6539 101.457C60.0706 103.358 58.2115 105.258 58.2115 105.258C58.2115 105.258 56.3915 111.671 55.0385 118.561C53.807 124.833 49.9615 141.367 49.9615 141.367L3 140.995C3 140.995 19.1137 98.6763 22.6731 88.7874C25.0647 82.1427 25.2115 79.2851 25.2115 79.2851Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.239 19.0178C86.239 19.0178 94.9025 11.4467 98.0871 11.5718C101.273 11.698 102.363 16.3424 100.436 19.6051C98.5067 22.8688 87.5263 29.9806 87.5263 29.9806L86.239 19.0178Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M83.8501 19.4186C83.8501 19.4186 85.9965 18.2632 88.5177 17.1108V29.8648C86.9625 30.9396 85.844 31.6651 85.844 31.6651L81.0896 33.6554L78.7896 28.9095L78.022 23.858L83.8501 19.4186Z" fill="#EEEEEE" style={{ 'mix-blend-mode': 'multiply' }} />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.2389 18.371C86.2389 18.371 93.2876 12.1487 97.3394 11.6104C101.391 11.072 103.219 16.0506 100.903 18.9497C98.5877 21.8487 87.5262 29.8735 87.5262 29.8735L82.3286 32.1564L79.8143 26.7127L78.9751 20.9185L86.2389 18.371Z" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40.5068 90.1419V84.3242C40.5068 87.5257 43.2305 90.121 46.5915 90.121H75.5509C73.7487 91.9312 71.8515 93.7613 70.3982 94.9725C70.044 95.267 69.6355 95.5924 69.1842 95.9368H46.5915C43.2305 95.9368 40.5068 93.3424 40.5068 90.1419Z" fill="#EEEEEE" style={{ 'mix-blend-mode': 'multiply' }} />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.224 68.3282V12.5589C86.224 9.91882 84.0354 7.77759 81.3375 7.77759H45.4729C42.7743 7.77759 40.5857 9.91882 40.5857 12.5589V41.8161V69.9209V84.4814C40.5857 87.6013 43.1709 90.1305 46.3599 90.1305H80.4498C83.6388 90.1305 86.224 87.6013 86.224 84.4814V76.5106V68.3282Z" fill="white" />
        <rect x="41.2966" y="17.5806" width="44.1383" height="61.5788" fill="#5AD2FF" stroke="white" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M80.194 87.4885H46.3226C43.154 87.4885 40.5854 85.034 40.5854 82.0061V84.6482C40.5854 87.6761 43.154 90.1306 46.3226 90.1306H80.194C83.3626 90.1306 85.9312 87.6761 85.9312 84.6482V82.0061C85.9312 85.034 83.3626 87.4885 80.194 87.4885Z" fill="#EEEEEE" style={{ 'mix-blend-mode': 'multiply' }} />
        <path d="M40.5854 79.9095H85.9312" stroke="#333333" strokeWidth="1.5" />
        <path d="M85.9312 16.8306H40.5854" stroke="#333333" strokeWidth="1.5" />
        <path d="M61.4782 12.4864H68.2049" stroke="#333333" strokeWidth="1.5" />
        <path d="M58.7823 12.4863H59.9966" stroke="#333333" strokeWidth="1.5" />
        <path d="M59.8939 84.5818H65.745" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.1849 68.3282V12.5589C86.1849 9.91882 83.9963 7.77759 81.2985 7.77759H45.4338C42.7353 7.77759 40.5466 9.91882 40.5466 12.5589V41.8161V69.9209V84.4814C40.5466 87.6013 43.1318 90.1305 46.3208 90.1305H80.4108C83.5998 90.1305 86.1849 87.6013 86.1849 84.4814V76.5106V68.3282Z" stroke="#333333" strokeWidth="1.5" />
        <path d="M3 141.323L22.6289 88.9153C22.6289 88.9153 25.4691 82.5524 25.4691 77.3001C25.4691 72.7905 24.578 64.1292 24.578 64.1292C24.4432 60.7158 24.2144 59.4551 24.3962 54.7469C24.578 50.0386 25.822 47.0962 26.5586 45.0614C28.1184 40.7525 30.3796 33.9028 32.082 27.561C34.026 20.3212 34.6441 13.5243 36.4526 13.3242C37.8094 13.174 38.9659 13.8716 39.903 15.0031V38.5874C39.4982 40.3687 39.2131 41.5679 39.2131 41.5679L39.903 42.0924" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M39.6353 42.8933C39.6353 42.8933 43.0462 28.7109 43.2901 24.6238C43.5341 20.5368 41.0978 12.844 36.7112 13.3253C34.7956 13.5347 34.8716 18.4875 32.8124 26.0668L34.1809 42.8933" fill="white" />
        <path d="M39.6352 42.3427C39.6352 42.3427 43.0462 28.1604 43.2901 24.0733C43.534 19.9862 41.0978 12.2934 36.7111 12.7747C34.7955 12.9841 34.1408 20.0998 32.0826 27.6791" stroke="#333333" strokeWidth="1.5" />
        <path d="M75.9996 90.0083C74.8015 92.2237 72.1815 96.3508 64.1061 100.019C63.1128 100.47 58.9972 103.363 57.8989 106.409C55.6328 112.696 49.9615 141.367 49.9615 141.367" stroke="#333333" strokeWidth="1.5" />
        <rect x="46.7631" y="30" width="34.5683" height="32.2304" rx="2" fill="white" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M69.172 33.7266H76.1366C76.5285 33.7266 76.8463 34.0377 76.8463 34.4214V40.8486" fill="white" />
        <path d="M69.172 33.7266H76.1366C76.5285 33.7266 76.8463 34.0377 76.8463 34.4214V40.8486" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M58.5898 33.7266H51.6253C51.2333 33.7266 50.9156 34.0377 50.9156 34.4214V40.8486" fill="white" />
        <path d="M58.5898 33.7266H51.6253C51.2333 33.7266 50.9156 34.0377 50.9156 34.4214V40.8486" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M69.172 58.907H76.1366C76.5285 58.907 76.8463 58.5959 76.8463 58.2121V51.7849" fill="white" />
        <path d="M69.172 58.907H76.1366C76.5285 58.907 76.8463 58.5959 76.8463 58.2121V51.7849" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M58.5898 58.907H51.6253C51.2333 58.907 50.9156 58.5959 50.9156 58.2121V51.7849" fill="white" />
        <path d="M58.5898 58.907H51.6253C51.2333 58.907 50.9156 58.5959 50.9156 58.2121V51.7849" stroke="#333333" strokeWidth="1.5" />
        <rect x="53.7963" y="36.1301" width="7.74193" height="7.57997" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="56.2948" y="38.5767" width="2.74465" height="2.68723" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="56.2948" y="50.9883" width="2.74465" height="2.68724" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="68.5465" y="38.5767" width="2.74465" height="2.68723" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="53.7963" y="48.5417" width="7.74193" height="7.57997" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="66.048" y="36.1301" width="7.74193" height="7.57997" rx="1" stroke="#333333" strokeWidth="1.5" />
        <rect x="66.048" y="48.5417" width="2.74465" height="2.68724" rx="0.5" stroke="#333333" strokeWidth="1.5" />
        <rect x="69.1658" y="52.3318" width="4.28784" height="4.19815" rx="0.5" stroke="#333333" strokeWidth="1.5" />
        <path d="M43.29 46.0986H84.8702" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M81.0922 48.4702C79.9741 46.3215 80.5746 43.6793 82.5117 42.225L88.029 38.0829C90.1724 36.4738 93.2569 37.2974 94.3128 39.7609C95.5529 42.6542 94.5645 46.0206 91.9569 47.7842L88.2581 50.2858C85.8069 51.9437 82.4583 51.0952 81.0922 48.4702Z" fill="white" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M80.2009 61.9673C78.9601 59.7192 79.6066 56.8977 81.7024 55.4142L87.7817 51.1109C89.9183 49.5984 92.9038 50.4057 93.9872 52.7888C95.3512 55.7894 94.2996 59.3365 91.5204 61.1088L87.3277 63.7825C84.8734 65.3476 81.6074 64.5158 80.2009 61.9673Z" fill="white" stroke="#333333" strokeWidth="1.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M81.3139 73.3998C80.0801 71.1659 80.7035 68.3629 82.7678 66.8625L86.7826 63.9443C88.8873 62.4145 91.8648 63.1805 92.9698 65.5362C94.3691 68.5193 93.3451 72.0774 90.5741 73.8602L88.4789 75.2083C86.0172 76.7921 82.7291 75.9622 81.3139 73.3998Z" fill="white" stroke="#333333" strokeWidth="1.5" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_176_14927" x1="108.814" y1="57.5506" x2="-8.62215" y2="20.5591" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BCFF" />
          <stop offset="1" stopColor="#00BCFF" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient id="paint1_linear_176_14927" x1="122.749" y1="119.941" x2="67.9523" y2="-15.8111" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00BCFF" />
          <stop offset="1" stopColor="#00BCFF" stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  </IconWrapper>
);

module.exports = IconPhoneQR;
