const React = require('react');

const ShieldDisabled = () => (
  <svg aria-hidden width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Protected Purchase">
      <path
        id="Icon shape"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.01338 0.822266L6.41453 1.18291C6.58764 1.33854 7.37017 1.91832 8.33446 2.38871C9.22003 2.82069 9.95858 2.84194 10.4631 2.84136L11.0638 2.84068V3.44136C11.0638 3.5398 11.0651 3.64861 11.0665 3.76618C11.0775 4.67949 11.0948 6.1218 10.5552 7.34344C9.74999 9.52777 8.10933 10.937 6.21921 11.6273L6.01333 11.7025L5.80746 11.6273C3.84029 10.9084 2.25333 9.52917 1.448 7.34683C0.903102 6.14983 0.923975 4.70689 0.937222 3.79119C0.939066 3.66366 0.940763 3.54635 0.940763 3.44136L0.940764 2.84068L1.54145 2.84136C2.04595 2.84194 2.78455 2.82069 3.67012 2.38871C4.63995 1.91562 5.44295 1.33511 5.61224 1.18291L6.01338 0.822266ZM6.01099 2.38872C5.5869 2.69195 4.9264 3.11105 4.19623 3.46723C3.39791 3.85665 2.68646 3.98273 2.13626 4.02295C2.12875 4.93344 2.1448 5.99503 2.54769 6.86607L2.55812 6.88861L2.56665 6.91194C3.2068 8.66298 4.43186 9.79001 6.01296 10.4213C7.52516 9.81258 8.79625 8.65997 9.4353 6.91194L9.44253 6.89214L9.45114 6.8729C9.849 5.98373 9.8723 4.92385 9.8674 4.02289C9.31734 3.9826 8.60625 3.85645 7.80835 3.46723C7.07596 3.10997 6.42722 2.69101 6.01099 2.38872Z"
        fill="black"
        fill-opacity="0.55"
      />
      <path id="Vector 1006" d="M11 1L1 11" stroke="#737373" />
    </g>
  </svg>
);

module.exports = ShieldDisabled;
