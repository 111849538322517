const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const {
  OPTIONS_PAGE,
} = require('../../../spa/actions/types');
const optionsPage = require('../../../spa/actions/selectOptions');

class GroupBackUrl extends React.Component {
  componentWillUnmount() {
    if (this.props.optionsPage) {
      this.props.optionsPage[OPTIONS_PAGE](false);
    }
  }

  render() {
    return (
      <div className={`group-back-url ${this.props.paymentOptionsPage ? 'none' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

GroupBackUrl.defaultValues = {
  children: null,
};

GroupBackUrl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  optionsPage: bindActionCreators(optionsPage, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  paymentOptionsPage: state.selectOptions.optionsPage,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = GroupBackUrl;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(GroupBackUrl);
}
