const React = require('react');
const PropTypes = require('prop-types');

const IconWrapper = require('../IconWrapper');

const ImageIcon = ({ image_url: imageUrl, alt, title }) => {
  if (!imageUrl) {
    return null;
  }
  return (
    <IconWrapper>
      <img src={imageUrl} alt={alt} title={title} />
    </IconWrapper>
  );
};

ImageIcon.defaultProps = {
  image_url: '',
  alt: '',
  title: '',
};

ImageIcon.propTypes = {
  image_url: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

module.exports = ImageIcon;
