const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const { STEPS: { CARD_FORM } } = require('../../../constants/app')

/**
 * Inject to Component common methods
 * Register the component on the Provider
 * @param ComponentToWrap
 * @returns {ComponentWithSecureFieldCardFormValidation}
 */
/* istanbul ignore next */
module.exports = (ComponentToWrap) => {
  class ComponentWithSecureFieldCardFormValidation extends React.Component {

    /**
     * Register the component to render on the Provider via register method
     */
    componentDidMount() {
      const isCardFormStep = this.props.flow.step === CARD_FORM;

      // Check if the component is wrapped by the validator and have the register method injected
      if (this.context.registerSecureField !== undefined && isCardFormStep) {
        this.context.registerSecureField(this.props.field);
      }
    }

    componentWillUnmount() {
      // Check if the component is wrapped by the validator and have the register method injected
      if (this.context.unregisterSecureField !== undefined) {
        this.context.unregisterSecureField(this.props.field);
      }
    }

    render() {
      return (<ComponentToWrap {...this.props} />);
    }
  }

  ComponentWithSecureFieldCardFormValidation.contextTypes = {
    registerSecureField: PropTypes.func,
    unregisterSecureField: PropTypes.func,
  };


  /* istanbul ignore next: cant test it with tests */
  const mapStateToProps = (state) => ({
    sdkLoaded: state.mercadopagoSdk.loaded,
  });

  return connect(mapStateToProps)(ComponentWithSecureFieldCardFormValidation);
};
