/* eslint-env browser */
module.exports = {
  /**
   * Bind event to the selected element
   * @param element
   * @param name
   * @param callback
   */
  bindEventListener(element, name, callback) {
    if (element.addEventListener) {
      return element.addEventListener(name, callback, false);
    }

    return element.attachEvent(`on${name}`, callback);
  },
  /**
   * Check if the checkout is inside an iframe
   * @returns {boolean}
   */
  isIframe() {
    if (typeof window !== 'undefined') {
      return window.self !== window.top;
    }

    return false;
  },
  /**
   * Get value from element searched by query. IF not found, return defaultValue
   * @param from
   * @param query
   * @param defaultValue
   * @returns {}
   */
  getValueFromQuery(from, query, defaultValue = '') {
    return from.querySelector(query) ? from.querySelector(query).value : defaultValue;
  },

  /**
   * Get query params as String
   * @returns {string}
   */
  getQueryParams() {
    const search = window.location.search.substring(1);

    return decodeURI(search);
  },

  /**
   * Get specific query param
   * @param name
   * @returns {*}
   */
  // getQueryParam(name) {
  //   const url = window.location.href;
  //   const fixName = name.replace(/[\[\]]/g, "\\$&");
  //   const regex = new RegExp(`[?&]${fixName}(=([^&#]*)|&|#|$)`);
  //   const results = regex.exec(url);

  //   if (!results) {
  //     return null;
  //   }

  //   if (!results[2]) {
  //     return '';
  //   }

  //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
  // },

  /**
   * Get specific index on path variable
   * @param index
   * @returns {*}
   */
  // getPathVariable(index) {
  //   const paths = location.pathname.replace(/^\/|\/$/g, '').split('/');
  //   return paths[index];
  // },

  /**
   * Get query params as object
   * @returns {object}
   */
  // getQueryParamsAsObject() {
  //   const search = location.search.substring(1);
  //   return JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  // },

  /**
   * Submit a form
   * If we use form.submit() the onSubmit event method is not called
   */
  submitForm(form) {
    if (!form) {
      return;
    }

    // Check if form has a submit button (previously appended)
    const submitBtn = form.querySelectorAll('input[type="submit"]');

    if (submitBtn.length) {
      submitBtn[0].click();
    } else {
      // Need to create a button. If we use form.submit() the onSubmit event method is not called
      const button = document.createElement('input');

      button.style.display = 'none';
      button.type = 'submit';
      form.appendChild(button).click();
      button.remove();
    }
  },
  /**
   * Submit a form
   * If we use form.submit() the onSubmit event method is not called
   */
  async loadAsset(tag, url, container) {
    return new Promise((resolve, reject) => {
      const element = document.createElement(tag);

      element.onload = () => {
        resolve(url);
      };
      element.onerror = () => {
        reject(url);
      };

      if (tag === 'link') {
        element.type = 'text/css';
        element.rel = 'stylesheet';
        element.href = url;
        (container || document.head).appendChild(element);
      } else if (tag === 'script') {
        element.src = url;
        element.async = true;
        (container || document.body).appendChild(element);
      }
    });
  },
  /**
   * Calculates the popup position for the challenges
   * @param {*} width
   * @param {*} height
   * @returns {{left: string, top: string}}
   */
  popUpPosition(width, height) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    let popUpHeight;
    let popUpWidth;

    if (window.innerWidth) {
      popUpWidth = window.innerWidth;
    } else if (document.documentElement.clientWidth) {
      popUpWidth = document.documentElement.clientWidth;
    } else {
      popUpWidth = window.screen.width;
    }

    if (window.innerHeight) {
      popUpHeight = window.innerHeight;
    } else if (document.documentElement.clientHeight) {
      popUpHeight = document.documentElement.clientHeight;
    } else {
      popUpHeight = window.screen.height;
    }

    const left = popUpWidth / 2 - width / 2 + dualScreenLeft;
    const top = popUpHeight / 2 - height / 2 + dualScreenTop;

    return { left, top };
  },

  /**
   * Sets the title of the current document.
   * @param {string} newTitle
   */
  setDomTitle(newTitle) {
    if (!document) {
      return;
    }

    document.title = newTitle;
  },
  scrollTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
};
