const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const InputZipCode = require('../InputZipCode');
const { CURRENT_INPUT_VALUES } = require('../../spa/actions/types');
const inputValuesActions = require('../../spa/actions/inputValues');
const translate = require('../../translation');
const { fetch } = require('./index.utils');
const removeDuplicatedFromArray = require('../../utils/RemoveDuplicated')
const { useDebounce } = require('../../hooks/useDebounce')

const DEBOUNCE_USER_TYPING_TIME = 450;

const AutoFiller = (props) => {
  const translations = translate(props.i18n);

  const [error, setError] = React.useState([]);
  const [, rerender] = React.useReducer(() => ({}), {});
  const [zipCode, setZipCode] = React.useState();
  const debouncedValue = useDebounce(zipCode, DEBOUNCE_USER_TYPING_TIME);

  const setInputAutoFillableOptions = React.useCallback((inputName, options) => {
    const { step } = props;
    const cleanOptions = removeDuplicatedFromArray(options);

    props.inputValuesActions[CURRENT_INPUT_VALUES](`${step}_${inputName}_options`, cleanOptions);
  }, [props])

  const setCitiesAndStatesOptions = React.useCallback((cities, states) => {
    const { cityId, stateId } = props;

    setInputAutoFillableOptions(cityId, cities);
    setInputAutoFillableOptions(stateId, states);
  }, [props, setInputAutoFillableOptions])

  const clearCitiesAndStatesOptions = React.useCallback(() => {
    setCitiesAndStatesOptions([], []);
  }, [setCitiesAndStatesOptions])

  const fetchOnUpdate = React.useCallback(async (zipCode) => {
    // clear previous options
    clearCitiesAndStatesOptions();

    if (!zipCode) {
      return;
    }

    try {
      const locations = await fetch(zipCode)

      if (!locations || locations.length === 0) {
        setError([translations.NOT_IDENTIFY_ZIPCODE]);
        rerender();
        return;
      }

      const cities = locations.map(location => location.city.name);
      const states = locations.map(location => location.state.name);
      setCitiesAndStatesOptions(cities, states);
      setError([]);
    } catch (_error) {
      //
    }
  }, [clearCitiesAndStatesOptions, setCitiesAndStatesOptions, translations.NOT_IDENTIFY_ZIPCODE])

  React.useEffect(() => {
    fetchOnUpdate(debouncedValue);
  }, [debouncedValue, fetchOnUpdate]);

  const {
    id, kind, label, name, nextPage, disabled, value, validations, ...inputValidationProps
  } = props;

  return (
    <div className="autofiller">
      <InputZipCode
        {...inputValidationProps}
        onChange={setZipCode}
        id={id}
        error={error}
        kind={kind}
        label={label}
        name={name}
        validations={validations}
        nextPage={nextPage}
        disabled={disabled}
        value={value}
      />
    </div>
  );
}

AutoFiller.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stateId: PropTypes.string,
  cityId: PropTypes.string,
  inputValuesActions: PropTypes.object,
  label: PropTypes.string,
  validation: PropTypes.string,
  next_page: PropTypes.string,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  kind: PropTypes.oneOf(['link', 'modal_link', 'no_link']),
  value: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.object),
  nextPage: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

AutoFiller.defaultProps = {
  stateId: '',
  cityId: '',
  label: '',
  inputValuesActions: {},
  next_page: '',
  validation: '',
  disabled: false,
  step: '',
  kind: '',
  value: '',
  validations: [],
  nextPage: '',
  i18n: {
    gettext: t => t,
  },
};
/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  globalErrors: state.globalErrors, // If this component support globalErrors
  step: state.page.flow.step, siteId: state.configurations.platform.siteId,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = AutoFiller;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(AutoFiller);
}
