const app = require('./app');
const commons = require('./commons');
const services = require('./services');
const translations = require('../app/translation');
const QUALITY_CHECK = require('./qualityCheck');
const SELECTORS = require('./selectors');
const COLORS = require('./colors')

module.exports = {
  APP: app,
  COMMONS: commons,
  SERVICES: services,
  TRANSLATIONS: translations,
  QUALITY_CHECK,
  SELECTORS,
  COLORS
};
