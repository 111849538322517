import React from 'react';
import { Image } from 'nordic/image';

import type { TermProps } from '../Term';

import Term from '../Term';
import { Typography } from '@andes/typography';

export type LegalsProps = {
  isMCO?: boolean;
  isUserLogged?: boolean;
  isMobile?: boolean;
  help?: {
    label: string;
    link: string;
  };
  brand?: {
    processByLabel?: string;
    image?: string;
    imageAlt?: string;
  };
  terms?: TermProps;
};

export default function Legals({
  isUserLogged = false,
  isMCO = false,
  help,
  brand,
  terms,
  isMobile = false,
}: LegalsProps) {
  if (isMCO && help && isUserLogged) {
    return (
      <div className={`cow-legals ${isMobile ? 'cow-legals--mobile' : ''}`}>
        <Typography
          className="cow-legals__link"
          size="xs"
          component="a"
          color="link"
          href={help?.link}
          target="_blank"
        >
          {help?.label}
        </Typography>
      </div>
    );
  }

  return (
    <div className={`cow-legals ${isMobile ? 'cow-legals--mobile' : ''}`}>
      {brand && (
        <div className="cow-legals__brand">
          {brand.processByLabel && (
            <span className="cow-legals__brand-label">
              {brand.processByLabel}
            </span>
          )}
          {brand.image && (
            <Image
              className="cow-legals__brand-logo"
              src={brand.image}
              alt={brand.imageAlt ?? 'Brand logo'}
              title={brand.imageAlt}
              lazyload="off"
            />
          )}
        </div>
      )}

      {!isMobile && brand && terms && <hr className="cow-legals__divider" />}

      {terms && <Term {...terms} help={help} isMCO={isMCO} />}
    </div>
  );
}
