/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_FINISHED_WITHOUT_RENDER
} = require('./types');

// Request Actions
const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);
const requestFinishedWithoutRender = createAction(REQUEST_FINISHED_WITHOUT_RENDER);

/**
 * Export functions
 */
module.exports = {
  [REQUEST_STARTED]: payload => dispatch => dispatch(requestStarted(payload)),
  [REQUEST_FINISHED]: payload => dispatch => dispatch(requestFinished(payload)),
  [REQUEST_FINISHED_WITHOUT_RENDER]: payload => dispatch => dispatch(requestFinishedWithoutRender(payload)),
};
