const React = require('react');
const PropTypes = require('prop-types');

const CvvIcon = ({ className }) => {
  let classNames = 'cvv-icon';

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <span className={classNames} />
  );
};

CvvIcon.propTypes = {
  className: PropTypes.string,
};

CvvIcon.defaultProps = {
  className: '',
};

module.exports = CvvIcon;
