const React = require('react');

const IconProtectedPurchaseGuest = () => (
  <svg width="79" height="72" viewBox="0 0 79 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.3579 21.1734C67.9713 16.1986 61.1228 12.2114 54.3448 12.3831C46.9038 12.5702 41.6245 11.9585 35.9891 9.19843C30.6071 6.56414 22.2131 -0.362955 13.1508 1.23707C1.86664 3.22887 -1.97355 14.9838 0.931498 27.3805C4.177 41.2339 15.5407 61.5569 35.4018 68.7523C54.8213 75.7868 69.7665 65.5098 75.0481 56.1516C82.3717 43.176 78.3613 27.9799 72.3579 21.1734Z"
      fill="black"
      fillOpacity="0.04"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7794 11.8659C11.6158 11.9264 11.4824 12.0322 11.3893 12.1655C11.296 12.2989 11.2431 12.4599 11.2436 12.6298L11.2437 35.4108C11.206 43.7963 14.0678 51.6507 18.9948 57.9134C23.9206 64.1748 30.9107 68.8451 39.5221 70.8641C47.7403 68.8451 54.7304 64.1748 59.6563 57.9134C64.5832 51.6507 67.445 43.7963 67.4074 35.4108V12.6375C67.4079 12.4599 67.355 12.2989 67.2618 12.1655C67.1686 12.0322 67.0352 11.9264 66.8746 11.867L39.6394 1.75809C39.4357 1.69066 39.2153 1.69066 39.0117 1.75809L11.7794 11.8659Z"
      fill="white"
    />
    <path
      d="M12.8625 35.4167V35.41L12.8625 13.0777L39.3255 3.20204L65.7884 13.0777L65.7884 35.41L65.7884 35.4167C65.8243 43.4644 63.0934 51.0021 58.3888 57.0147C53.7554 62.9363 47.21 67.3756 39.5128 69.3601C31.4378 67.3747 24.8936 62.9338 20.2622 57.0147C15.5575 51.0021 12.8266 43.4644 12.8625 35.4167ZM65.7884 12.6054V12.6098C65.7884 12.6083 65.7884 12.6069 65.7884 12.6054Z"
      fill="#5AD2FF"
      stroke="white"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7959 11.8374C11.6331 11.8979 11.5002 12.0038 11.4075 12.1373C11.3146 12.2709 11.2619 12.4321 11.2624 12.6021L11.2625 35.41C11.225 43.8053 14.0746 51.669 18.9807 57.9391C23.8857 64.2078 30.8462 68.8837 39.4211 70.905C47.6045 68.8837 54.565 64.2078 59.47 57.9391C64.3761 51.669 67.2258 43.8053 67.1883 35.41V12.6098C67.1888 12.4321 67.1361 12.2709 67.0433 12.1373C66.9505 12.0038 66.8177 11.8979 66.6577 11.8384L39.5379 1.71763C39.3351 1.65011 39.1157 1.65011 38.9128 1.71763L11.7959 11.8374Z"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <ellipse cx="39.2255" cy="30.0831" rx="14.4658" ry="14.464" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path
      d="M44.1836 24.3673C43.5407 22.9006 42.0906 21.998 39.5519 21.998C36.8927 21.998 34.9169 23.507 34.9169 25.7188C34.9169 28.4602 36.5285 29.0156 38.9761 29.6698C39.1304 29.7122 39.2845 29.7546 39.4376 29.7973L39.7424 29.8834C41.7122 30.4482 44.1836 30.9954 44.4012 33.1953C44.6187 35.3953 42.8863 37.3553 39.3752 37.3472C36.8927 37.3415 34.7955 36.1659 34.4194 34.3094"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path d="M39.627 19.2876V21.9953" stroke="#333333" strokeWidth="1.5" />
    <path d="M39.627 37.5337V40.2414" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

module.exports = React.memo(IconProtectedPurchaseGuest);
