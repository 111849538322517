import type { MouseEventHandler, ChangeEvent } from 'react';
import type { TextFieldProps } from '@andes/textfield';
import type { OnErrorValidator } from '../../utils/validateEventValueByRegex';
import type { Validations } from '../InputCard';

import React from 'react';
import { Button } from '@andes/button';

import InputCard from '../InputCard';
import DiscountIcon from '../Icons/Discount';
import CollapsibleByElementRef from '../CollapsibleByElementRef';

// Constants
import { DeviceType } from '../../constants/commons';

export type CouponCollapsibleProps = {
  triggerId?: string;
  show?: boolean;
  value: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  labelInput: string;
  description: string;
  toggleId?: string;
  inputId?: string;
  submitButtonLabel: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onError?: OnErrorValidator;
  validations?: Array<Validations>;
  style?: TextFieldProps['modifier'];
  device?: 'desktop' | 'mobile';
};

export default function CouponCollapsible(props: CouponCollapsibleProps) {
  return (
    <CollapsibleByElementRef
      triggerId={props.triggerId}
      show={props.show}
      withTransition
    >
      <div className="coupon-collapsible">
        <InputCard
          id={props.inputId}
          value={props.value}
          label={props.labelInput}
          helper={props.description}
          onChange={props.onChange}
          validations={props.validations ?? []}
          icon={
            props.device === DeviceType.MOBILE ? null : (
              <DiscountIcon className="group-coupon" />
            )
          }
          style={props.style}
          device={props.device}
        />
        <div className="coupon-button-wrapper">
          <Button
            hierarchy="loud"
            type="submit"
            onClick={props.onClick}
            id={props.toggleId}
          >
            {props.submitButtonLabel}
          </Button>
        </div>
      </div>
    </CollapsibleByElementRef>
  );
}
