const React = require('react');
const PropTypes = require('prop-types');

// Translations
const injectI18n = require('nordic/i18n/injectI18n');

// Actions
const { STEP_NEXT, MANAGE_CARD_FORM_ERROR } = require('../../../../../spa/actions/types');
// Utils
const { logErrorFromClient } = require('../../../../../utils/logTags');
const { getQueryParams } = require('../../../../../utils/Dom');
// Constants
const { ERROR_SPA, PAGE_ACTIONS } = require('../../../../../../constants/app');
const { ENVIROMENT } = require('../../../../../../constants/commons');

class CardFormErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorLogged: false,
    };
  }

  componentDidCatch(error) {
    if (!this.state.errorLogged) {
      logErrorFromClient(error, ERROR_SPA.CARD_FORM_BRICKS_ERROR, '[CardFormErrorBoundary][componentDidCatch]', {});
      this.setState({ errorLogged: true });
    }

    const { cardFormActions, stepActions, flow, history } = this.props;

    cardFormActions[MANAGE_CARD_FORM_ERROR](true);

    // Redirect to exception page for the error in the Card Form Bricks
    const queryParams = getQueryParams();
    const data = {
      id: PAGE_ACTIONS.ERROR,
      values: {},
    };

    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  }

  render() {
    return this.props.children;
  }
}

CardFormErrorBoundary.defaultProps = {
  stepActions: {},
  flow: {},
  history: {},
  children: null,
  i18n: {
    gettext: (t) => t,
  },
};

CardFormErrorBoundary.propTypes = {
  stepActions: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

/* istanbul ignore next: cant test */
if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = CardFormErrorBoundary;
} else {
  /* istanbul ignore next: cant test */ module.exports = injectI18n(CardFormErrorBoundary);
}
