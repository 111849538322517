const React = require('react');

const { connect } = require('react-redux');

const SandBoxLabel = (props) => {
  React.useEffect(() => {
    if (props.testCredentials && !document.querySelector('.sandbox-mode')) {
      document.querySelector('body').classList.add('sandbox-mode');
    }
  });

  const language = props.countryId === 'BR' ? 'pt' : 'es';
  const sandboxURL = `https://www.${props.domain}/developers/${language}/guides/online-payments/checkout-pro/test-integration`;

  if (props.testCredentials && props.text) {
    return (
      <a
        href={sandboxURL}
        className="sandbox-badge"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="sandbox-badge__title">Sandbox</span>
        <span className="sandbox-badge__text">{props.text}</span>
      </a>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  countryId: state.configurations.platform.countryId,
  domain: state.configurations.platform.domain,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = SandBoxLabel;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(SandBoxLabel);
}
