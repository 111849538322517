const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
const { injectValidations } = require('../../utils/validator-provider');

const BaseInput = require('../BaseInput');
const BaseValidation = require('../BaseValidation');
const inputValuesActions = require('../../spa/actions/inputValues');
const identificationCardActions = require('../../spa/actions/identificationCard');
const {
  IDENTIFICATION_CARD_CHANGE,
  IDENTIFICATION_CARD_FOCUS,
  IDENTIFICATION_CARD_BLUR,
} = require('../../spa/actions/types');
const {PLACEHOLDER} = require("../../../constants/commons");


class InputDocument extends BaseValidation {
  constructor(props) {
    super(props);

    // Default State
    this.state = {
      // Real value to be validated
      value: this.filterValidCharacters(props.initialValue || ''),
      error: props.error,
      invalid: props.invalid,
      // This is the value to show to the user
      valueToShow: props.initialValue || '',
      // Max length coming from Backend
      maxLength: props.maxLength,
      // This is going to be the maxLength of the document + the invalid characters
      currentMaxLength: props.maxLength,
      step: props.step,
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.typeChange = this.typeChange.bind(this);
  }

  /**
   * Check if the mounted component has identificationCard type
   * to update the identification number length otherwise will set the default value
   */
  componentDidMount() {
    if (this.props.identificationCard.type) {
      this.typeChange(this.props.identificationCard.type);
    }
  }

  /**
   * Check if the props change and compare them with the state
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.identificationCard.type
      || this.props.identificationCard.type !== nextProps.identificationCard.type) {
      this.typeChange(nextProps.identificationCard.type);
    }
  }

  onChange(event) {
    this.updateValue(event.target.value);
  }

  onFocus() {
    this.props.identificationCardActions[IDENTIFICATION_CARD_FOCUS]();
  }

  onBlur() {
    this.props.identificationCardActions[IDENTIFICATION_CARD_BLUR]();
  }

  onPaste(e) {
    const pasted = e.clipboardData.getData('text');
    const escapedValue = this.filterValidCharacters(pasted);
    this.setState({
      currentMaxLength: this.state.maxLength + escapedValue.length,
    });
  }

  getPlaceholder() {
    switch(this.props.selectType) {
      case 'NIT':
        return PLACEHOLDER.NIT;
      case 'C.E.':
        return PLACEHOLDER.CE;
      case 'C.C.':
        return PLACEHOLDER.CC;
      default:
        return PLACEHOLDER.INPUT_DOCUMENT_DEFAULT;
    }
  }

  /**
   * Document only accept Numbers or Alphanumeric values. This method is going to filter the other character types
   * This will allow the user to still see those characters but the real value is going to be stripped out
   * @param value
   * @returns {{value: string, length: number}}
   */
  filterValidCharacters(value = '') {
    const validValue = value.match(/[A-Za-z0-9]/g);
    const stripped = validValue ? validValue.join('') : '';
    return {
      value: stripped,
      length: (value) ? (value.length - stripped.length) : 0,
    };
  }

  updateValue(rawValue) {
    const targetValue = rawValue || '';
    const escapedValue = this.filterValidCharacters(targetValue);
    const hasInvalidCharacters = targetValue.match(/[^A-Za-z0-9]{2,}|^[^A-Za-z0-9]/g);
    if (hasInvalidCharacters) { // Has more than one invalid character (e.g: 30..123)
      return;
    }

    // Update number on Identification Card component
    this.props.identificationCardActions[IDENTIFICATION_CARD_CHANGE](targetValue);

    // Update the state
    this.setState({
      valueToShow: targetValue,
      value: escapedValue.value,
      currentMaxLength: this.state.maxLength + escapedValue.length, // Add the invalid characters
    });
  }

  /**
   * The length of the type change
   * @param maxLength
   */
  typeChange(documentType) {
    // Clean the value of the document type selected
    const maxLength = this.props.data.max_lengths[this.cleanDocumentType(documentType)] || this.props.maxLength;
    const escapedValue = this.filterValidCharacters(this.state.valueToShow);

    const nextState = {
      maxLength,
      currentMaxLength: maxLength + escapedValue.length,
    };

    // This comparison prevents race condition error when the type change before the state updates (value coming from saved)
    if (this.state.valueToShow !== '') {
      nextState.value = escapedValue.value.substring(0, maxLength);
      nextState.valueToShow = this.state.valueToShow.substring(0, nextState.currentMaxLength);
    }

    // Update the state
    this.setState(nextState);
  }

  /**
   * Clean document type coming from select
   * @param type
   * @returns {string}
   */
  cleanDocumentType(type) {
    return type ? type.replace('[', '').replace(']', '') : '';
  }

  /* istanbul ignore next */
  render() {
    const className = 'input-document';

    const {
      errors,
      error,
      invalid,
      validations,
      showErrorMessage,
      validateCallback,
      data,
      maxLength,
      name,
      inputValuesActions, // eslint-disable-line
      identificationCardActions, // eslint-disable-line
      identificationCard,
      initialValue,
      selectType,
      ...inputProps
    } = this.props;

    return (
      <>
        <BaseInput
          {...inputProps}
          key={`${this.state.step}_InputDocument_r`}
          error={showErrorMessage ? this.state.error : []}
          invalid={this.state.invalid}
          className={className}
          type="text"
          label={this.props.label}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onPaste={this.onPaste}
          onBlur={this.onBlur}
          value={this.state.valueToShow}
          maxLength={this.state.currentMaxLength}
          updateCallback={(value) => { this.updateValue(value); }}
          autoCorrect="no"
          autoCapitalize="no"
          spellCheck="no"
          autoComplete="off"
          placeholder={this.getPlaceholder()}
        />
        <input
          type="hidden"
          key={`${this.state.step}_InputDocument_h`}
          name={this.props.name}
          value={this.state.value}
        />
      </>
    );
  }
}

InputDocument.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.number,
  step: PropTypes.string,
  inputValuesActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  identificationCardActions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  identificationCard: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  shouldSaveValue: PropTypes.bool,
};

InputDocument.defaultProps = {
  id: '',
  name: '',
  invalid: false,
  error: [],
  maxLength: 30,
  step: '',
  identificationCard: {},
  data: {},
  shouldSaveValue: true,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = dispatch => ({
  inputValuesActions: bindActionCreators(inputValuesActions, dispatch),
  identificationCardActions: bindActionCreators(identificationCardActions, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = state => ({
  identificationCard: state.identificationCard,
  step: state.page.flow.step,
  selectType: state.inputValues.current[`${state.page.flow.step}_select_label`],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = InputDocument;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps, mapDispatchToProps)(injectValidations(InputDocument));
}
