/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  SAVE_INPUT_VALUES,
  CURRENT_INPUT_VALUES,
  CLEAN_INPUT_VALUES,
  VISIBILITY_INPUT_VALUES,
  DISABLE_INPUT_VALUES,
  MESSAGE_INPUT_VALUES,
} = require('../actions/types');

const actions = {};

const initialState = {
  current: {},
  disabled: {},
  visibility: {},
};

actions[SAVE_INPUT_VALUES] = (state = initialState, action) => {
  const newState = {
    ...state,
    [action.payload.input]: action.payload.value,
  };

  return newState;
};

actions[CURRENT_INPUT_VALUES] = (state = initialState, action) => {
  const {
    current,
    ...rest
  } = state;

  const newState = {
    ...rest,
    current: {
      ...current,
      [action.payload.input]: action.payload.value,
    },
  };

  return newState;
};

actions[VISIBILITY_INPUT_VALUES] = (state = initialState, action) => {
  const {
    visibility,
    ...rest
  } = state;

  const newState = {
    ...rest,
    visibility: {
      ...visibility,
      ...action.payload,
    },
  };
  return newState;
};

actions[DISABLE_INPUT_VALUES] = (state = initialState, action) => {
  const {
    disabled,
    ...rest
  } = state;

  const newState = {
    ...rest,
    disabled: {
      ...disabled,
      ...action.payload,
    },
  };

  return newState;
};

actions[MESSAGE_INPUT_VALUES] = (state = initialState, action) => {
  const {
    message,
    ...rest
  } = state;

  const newState = {
    ...rest,
    message: {
      ...message,
      [action.payload.input]: action.payload.message,
    },
  };

  return newState;
};

actions[CLEAN_INPUT_VALUES] = () => (initialState);

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
