const React = require('react');
const PropTypes = require('prop-types');

const { COMMONS: { DEVICE_TYPE }} = require('../../../constants');
const Chevron = require('@andes/icons/ChevronDown12');

/** @deprecated Do not use this component, this component will be remade in revamp */
const Accordion = ({
  id,
  title,
  children,
  defaultOpened,
  showChevron = true,
  deviceType,
}) => {
  const blockOpen = showChevron === false;

  const detailsReference = React.useRef(null);

  React.useEffect(() => {
    if (blockOpen && deviceType === DEVICE_TYPE.DESKTOP) {
      detailsReference.current.removeAttribute('open');
    }
  }, [blockOpen]);

  return (
    <details
      className={`cow-accordion${blockOpen ? '--blocked': ''}`}
      id={id}
      open={defaultOpened}
      ref={detailsReference}
      onClick={(event) => {
        if (blockOpen) {
          event.preventDefault();
        }
      }}
    >
      <summary className={`cow-accordion__title ${showChevron ? '' : 'cow-accordion__title--hide-chevron'}`}>
        {title}
        {showChevron && <Chevron className="cow-accordion__chevron" color="var(--cow-light-blue)" />}
      </summary>

      <div className="cow-accordion__content">{children}</div>
    </details>
  )
};

Accordion.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  defaultOpened: PropTypes.bool,
  showChevron: PropTypes.bool,
  deviceType: PropTypes.string,
};

module.exports = Accordion;
