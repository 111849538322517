const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const translate = require('../../translation');

const OpenFinanceTermsAndConditions = props => {
  const { value: [{ urls: { tyc } }], i18n } = props;
  const translations = translate(i18n);
  return (
    <div className="open-finance-terms">
      <p>
        <span>{`${translations.BY_CONTINUING_YOU_ACCEPT_THE} `}</span>
        <a
          href={tyc}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translations.TERMS_AND_CONDITIONS}
        </a>
      </p>
    </div>
  );
};

OpenFinanceTermsAndConditions.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  value: [
    {
      urls: {
        tyc: '',
      },
    },
  ],
};

OpenFinanceTermsAndConditions.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      urls: PropTypes.shape({
        tyc: PropTypes.string,
      }),
    }),
  ),
};

if (process.env.NODE_ENV === 'test') {
  module.exports = OpenFinanceTermsAndConditions;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = injectI18n(OpenFinanceTermsAndConditions);
}
