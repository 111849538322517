const React = require('react');

const NoAvailableShipping = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="87" viewBox="0 0 100 87" className="icon-no-available-shipping">
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <path fill="#D7DDE4" d="M22 79.157L32.557 79.157 32.557 74.536 22 74.536zM66.88 79.157L77.437 79.157 77.437 74.536 66.88 74.536z" />
      <path stroke="#9BA9BB" strokeWidth="2" d="M.204 84.026L63.469 84.026M66.645 84.026L99.268 84.026" />
      <path fill="#D7DDE4" d="M14.8 35h69v26c0 1.105-.895 2-2 2h-65c-1.105 0-2-.895-2-2V35z" />
      <rect width="69" height="63" x="14.8" stroke="#9BA9BB" strokeWidth="2" rx="2" />
      <path fill="#F0F0F0" stroke="#9BA9BB" strokeWidth="2" d="M23.565 21h51.467c1.01 0 1.86.753 1.985 1.755l1.531 12.403c.833 6.743.976 13.552.427 20.324l-1.026 12.68C77.865 69.2 76.997 70 75.955 70H22.647c-1.043 0-1.911-.801-1.994-1.84L19.616 55.16c-.543-6.794-.389-13.626.458-20.389l1.507-12.02c.125-1.001.976-1.752 1.984-1.752z" />
      <path stroke="#9BA9BB" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M38.733 58.928h21.98M38.733 54.926h21.98" />
      <path fill="#D7DDE4" d="M32.44 57.231c0 2.278-1.845 4.125-4.123 4.125-2.277 0-4.123-1.847-4.123-4.125 0-2.277 1.846-4.124 4.123-4.124 2.278 0 4.123 1.847 4.123 4.124M63.246 70.639c-.532-2.1-2.717-3.8-4.885-3.8H42.618c-2.166 0-4.352 1.698-4.885 3.8l-1.022 4.03h27.557l-1.022-4.03z" />
      <g stroke="#9BA9BB" strokeWidth="2" transform="translate(69.8 49)">
        <circle cx="10.5" cy="10.5" r="10.5" fill="#D7DDE4" />
        <path d="M6.933 6.933L13.867 13.867M13.867 6.933L6.933 13.867" />
      </g>
      <rect width="61" height="5" x="18.8" y="70" stroke="#9BA9BB" strokeWidth="2" rx="2" />
      <path stroke="#9BA9BB" strokeWidth="2" d="M21.8 75h11v7c0 1.105-.895 2-2 2h-7c-1.105 0-2-.895-2-2v-7h0zM66.8 75h11v7c0 1.105-.895 2-2 2h-7c-1.105 0-2-.895-2-2v-7h0z" />
      <path fill="#D7DDE4" d="M26.588 23.28H71.75c1.48 0 2.723 1.117 2.88 2.59l1.866 17.55c.182 1.71-1.159 3.202-2.88 3.202H24.909c-1.708 0-3.045-1.47-2.882-3.172l1.68-17.55c.142-1.486 1.39-2.62 2.882-2.62" />
    </g>
  </svg>
);

module.exports = NoAvailableShipping;
