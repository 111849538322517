const React = require('react');
const PropTypes = require('prop-types');
const { v4: uuidv4 } = require('uuid');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { injectI18n } = require('nordic/i18n');
const { useForm } = require('checkout-off-form-validation');

const { Card } = require('@andes/card');
const { Form } = require('@andes/form');
const { Snackbar } = require('@andes/snackbar');
const { CardContent } = require('@andes/card');

const InputEmail = require('../InputEmailV2');
const InputCode = require('../InputCode');
const InputPhoneYape = require('../InputPhoneYape');
const ProcessingLoading = require('../ProcessingLoading');

const translate = require('../../translation');
const stepActions = require('../../spa/actions/step');
const YapeOtpPayment = require('../../../services/YapePayment');
const { STEP_NEXT } = require('../../spa/actions/types');
const { getQueryParams } = require('../../utils/Dom');
const { formatRemoveSpaces } = require('../../utils/format');

const {
  YAPE_API_ERROR: { INVALID_OTP, INVALID_OTP_VAULT, INVALID_PHONE_NUMBER, INVALID_PHONE_VAULT },
} = require('../../../constants/app');

const FormYape = (props) => {
  const formValues = props?.form_data?.values;
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const { errors, register, handleSubmit, setError } = useForm();
  const { flow, history, i18n, publicKey, stepAction } = props;

  const translations = translate(i18n);

  const handleRemoveLoading = () => {
    setTimeout(() => setIsLoading(false), 300);
  };

  const handleYapeCardToken = async (payload) => {
    try {
      setIsLoading(true);
      const response = await YapeOtpPayment.createPaymentYape(payload, publicKey);
      return response?.data?.id;
    } catch (err) {
      const otpErrors = [INVALID_OTP, INVALID_OTP_VAULT];
      const phoneErrors = [INVALID_PHONE_NUMBER, INVALID_PHONE_VAULT];
      const message = err?.response?.data?.message || err?.message;

      if (otpErrors.includes(message)) {
        setError('code', { message: translations.EXPIRED_CODE });
      } else if (phoneErrors.includes(message)) {
        setError('phone', { message: translations.INVALID_NUMBER_YAPE });
      } else {
        setShowSnackbar(true);
      }

      handleRemoveLoading(false);
    }
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    const payload = {
      otp: data.code,
      requestId: uuidv4(),
      phoneNumber: formatRemoveSpaces(data.phone),
    };

    setShowSnackbar(false);
    const cardToken = await handleYapeCardToken(payload);

    if (cardToken) {
      const formData = {
        cardToken,
        email: data?.email || formValues?.email,
        phone: data?.phone,
        isNewInterface: true,
      };

      const urlParams = getQueryParams();
      const defaultData = { urlParams, type: flow.type };

      stepAction[STEP_NEXT](formData, flow.id, defaultData, flow.type, urlParams, history);
      handleRemoveLoading();
    }
  };

  return (
    <>
      {isLoading && <ProcessingLoading loading={isLoading} />}

      <Snackbar color="red" delay={3000} show={showSnackbar} message={translations.YAPE_CREATE_CARD_TOKEN_ERROR} />

      <Card>
        <CardContent>
          <Form id="form-yape" className="form_yape" onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              label="E-mail"
              modifier={errors?.email && 'error'}
              defaultValue={formValues?.email || ''}
              disable={formValues?.email}
              helper={errors?.email?.message || translations.INPUT_HELPER_EMAIL_YAPE}
              {...register('email', {
                required: {
                  message: translations.COMPLETE_THIS_FIELD,
                },
                email: {
                  message: translations.INVALID_EMAIL,
                },
                validateOnChange: false,
              })}
            />

            <InputPhoneYape
              label="Tu celular registrado en Yape"
              helper={errors?.phone?.message}
              modifier={errors?.phone && 'error'}
              defaultValue={formValues?.phone || ''}
              {...register('phone', {
                required: {
                  message: translations.COMPLETE_THIS_FIELD,
                },
                phone: {
                  type: 'YAPE',
                  message: translations.COMPLETED_NUMBER,
                },
                validateOnChange: false,
              })}
            />

            <InputCode
              digits={6}
              focus={false}
              label={translations.APPROVAL_CODE}
              helper={errors?.code?.message || translations.INPUT_HELPER_CODE_YAPE}
              modifier={errors?.code && 'error'}
              {...register('code', {
                required: {
                  message: translations.COMPLETE_THIS_FIELD,
                },
                min: {
                  length: 6,
                  message: translations.COMPLETED_CODE,
                },
                validateOnChange: false,
              })}
            />
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
});

FormYape.propTypes = {
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func.isRequired,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  publicKey: PropTypes.string.isRequired,
};

if (process.env.NODE_ENV === 'test') {
  module.exports = FormYape;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(FormYape));
}
