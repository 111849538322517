const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const PaymentDetailRow = require('../../components/PaymentDetailRow');
const translate = require('../../translation');
const {
  APP: {
    HIDDEN_COMPONENTS: { IS_SECOND_PAYMENT_METHOD_COMBINATION },
    ICON: { INFO },
  },
  COMMONS: {
    ANDES_PLACEMENT: { RIGHT, TOP_LEFT },
    DEVICE_TYPE: { DESKTOP },
    TOOLTIP_TYPE: { DARK }
  },
} = require('../../../constants');

const PaymentDetailRowWithCombination = ({ text, value, deviceType, i18n, hidden_components }) => {
  const translations = translate(i18n);
  const showTooltip = hidden_components.find((component) => component.id === IS_SECOND_PAYMENT_METHOD_COMBINATION);

  return (
    <PaymentDetailRow
      text={text}
      value={value}
      deviceType={deviceType}
      tooltipText={translations.DEFINED_ACCORDING_TO_THE_AMOUNT_YOU_CHOSE_TO_PAY_WITH_THE_FIRST_MEANS}
      tooltipSide={deviceType === DESKTOP ? RIGHT : TOP_LEFT}
      tooltipIcon={INFO}
      tooltipType={DARK}
      showTooltip={showTooltip?.value ?? false}
    />
  );
};

PaymentDetailRowWithCombination.defaultProps = {
  text: '',
  value: '',
  deviceType: '',
  i18n: {
    gettext: (t) => t,
  },
  hidden_components: [],
  history: {
    push: () => {},
    pop: () => {},
  },
};

PaymentDetailRowWithCombination.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  deviceType: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
  }),
  hidden_components: PropTypes.array,
};

const mapStateToProps = (state) => ({
  hidden_components: state.page.data.hidden_components,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = PaymentDetailRowWithCombination;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, null)(injectI18n(PaymentDetailRowWithCombination));
}
