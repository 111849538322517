const React = require('react');

const UserHeader = props => (
  <div id={props.id} className="user-header navbar">
    <div className="user-header__content">
      {props.children}
    </div>
  </div>
);

module.exports = UserHeader;
