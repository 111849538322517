const React = require('react');
const PropTypes = require('prop-types');

const { injectI18n } = require('nordic/i18n');
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

const Cross = require('../icons/Cross');
const UserAvatar = require('../UserAvatar');
const LogoutButton = require('../LogoutButton');
const modalActions = require('../../spa/actions/modal');
const UserDefaultIcon = require('../icons/UserDefault');
const MercadoPagoFull = require('../icons/MercadoPagoFull');
const MercadoLibreFull = require('../icons/MercadoLibreFull');
const { submitForm } = require('../../utils/Dom');
const { TOGGLE_MODAL_VISIBILITY } = require('../../spa/actions/types');
const translate = require('../../translation');

const { useMemo, useState, useEffect, useCallback } = React;

const ModalHeader = ({ i18n, mask, target, userName, imageURL, modalAction, enableMlTheme, ...props }) => {
  const translations = translate(i18n);
  const [form, setForm] = useState(null);

  const getForm = useCallback(() => {
    const element = document.getElementById(target);
    const isForm = element?.tagName === 'FORM';

    if (!isForm) {
      return;
    }

    setForm(element);
  }, [target, setForm]);

  useEffect(() => {
    getForm();
  }, [getForm]);

  const handleClick = (event) => {
    if (!form) {
      return;
    }

    event.preventDefault();

    modalAction[TOGGLE_MODAL_VISIBILITY]();

    submitForm(form);
  };

  const avatar = useMemo(() => (imageURL ? <UserAvatar image={imageURL} /> : <UserDefaultIcon />), [imageURL]);

  return (
    <header className="modal-header">
      <button className="cross" onClick={handleClick} aria-label="close">
        <Cross />
      </button>
      <div className="modal-header__content">
        {enableMlTheme ? <MercadoLibreFull /> : <MercadoPagoFull />}

        <div className="modal-header__info">
          <div className="modal-header__info-text">
            {translations.HELLO}
            <LogoutButton {...props} text={userName} className="payer-name" />
          </div>
          {avatar}
        </div>
      </div>
      <hr className="divider" />
      {mask && <div className="modal-header__mask" onClick={handleClick} />}
    </header>
  );
};

ModalHeader.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  mask: PropTypes.bool,
  target: PropTypes.string,
  userName: PropTypes.string,
  imageURL: PropTypes.string,
  modalAction: PropTypes.shape({
    [TOGGLE_MODAL_VISIBILITY]: PropTypes.func,
  }),
  enableMlTheme: PropTypes.bool,
};

ModalHeader.defaultProps = {
  i18n: {
    gettext: (text) => text,
  },
  mask: true,
  target: '',
  userName: '',
  imageURL: null,
  modalAction: PropTypes.shape({
    [TOGGLE_MODAL_VISIBILITY]: () => {},
  }),
  enableMlTheme: false,
};

const mapDispatchToProp = (dispatch) => ({
  modalAction: bindActionCreators(modalActions, dispatch),
});

const mapStateToProps = (state) => ({
  enableMlTheme: state.configurations.enableMlTheme,
  internalConfigurations: state.configurations.internalConfigurations,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ModalHeader;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProp)(injectI18n(ModalHeader));
}
