import React from 'react';

export type CaptchaTermsProps = {
  protectedByLabel: string;
  privacyLabel: string;
  privacyLink: string;
  conditionsLabel: string;
  conditionsLink: string;
};

export default function CaptchaTerms({
  protectedByLabel,
  privacyLabel,
  privacyLink,
  conditionsLabel,
  conditionsLink
}: CaptchaTermsProps) {
  return (
    <div className="cow-captcha-terms">
      <p className="cow-captcha-terms__text">
        <span className="cow-captcha-terms__text__protected-by">
          {protectedByLabel}
        </span>
        {' - '}
        <a
          className="cow-captcha-terms__text__privacy"
          href={privacyLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {privacyLabel}
        </a>
        {' - '}
        <a
          className="cow-captcha-terms__text__conditions"
          href={conditionsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {conditionsLabel}
        </a>
      </p>
    </div>
  );
}
