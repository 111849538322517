// Constants
const {
  PAGE_ACTIONS,
  ERROR_SPA: { INSTALLMENT_FORM_INPUT_ERROR },
} = require('../../../../../constants/app');

// Redux
const { STEP_NEXT } = require('../../../../spa/actions/types');

// Utils
const { getQueryParams } = require('../../../../utils/Dom');
const { logErrorFromClient } = require('../../../../utils/logTags');
const { isValidNumber } = require('../../../../utils/Number');

export const useActions = ({ flow, history, stepActions }) => {
  const nextStepAction = (data) => {
    const queryParams = getQueryParams();
    stepActions[STEP_NEXT](
      data,
      flow.id,
      {
        type: flow.type,
        urlParams: queryParams,
      },
      flow.type,
      queryParams,
      history,
    );
  };

  // ===== STEP ACTIONS =====
  const changeInputCard = () => {
    const data = {
        id: PAGE_ACTIONS.INPUT_CARD,
        values: {},
    };
    nextStepAction(data);
  }

  const selectInstallment = (installmentId, installmentRate) => {
    if (!isValidNumber(installmentId) || !isValidNumber(installmentRate)) {
      logErrorFromClient(
        {
          name: INSTALLMENT_FORM_INPUT_ERROR,
          message: `Unexpected input on installment selection. Selected ID: ${installmentId}, rate: ${installmentRate}`,
        },
        INSTALLMENT_FORM_INPUT_ERROR,
        '[InstallmentsFormScreen][selectInstallment]',
      );
    }

    const data = {
      id: PAGE_ACTIONS.SELECT_INSTALLMENTS,
      values: {
        installments: {
          id: installmentId.toString(),
          rate: installmentRate.toString(),
        },
      },
    };
    nextStepAction(data);
  };

  return {
    changeInputCard,
    selectInstallment,
  };
};
