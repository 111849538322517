/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  SNACKBAR_TRIGGER,
} = require('./types');

// Request Actions
const triggerSnackbar = createAction(SNACKBAR_TRIGGER);

/**
 * Export functions
 */
module.exports = {
  [SNACKBAR_TRIGGER]: snackbarData => (dispatch) => {
    dispatch(triggerSnackbar(snackbarData));
  },
};
