const Framebus = require('framebus');

const log = ([eventName, eventParam], debug) => {
  if (!debug) {
    return;
  }

  const params = [`[${eventName}]`];

  if (eventParam) {
    params.push(typeof eventParam !== 'function' ? eventParam : 'fn');
  }
};

module.exports = (debug) => ({
  emit: (...args) => {
    log(args, debug);
    Framebus.emit.apply(this, args);
  },
  on: (...args) => {
    Framebus.on.apply(this, args);
  },
});
