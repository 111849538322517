import React from 'react';

type ChevronShapeType = 'default' | 'obtuse-angle';

export enum ChevronShapeEnum {
  DEFAULT = 'default',
  OBTUSE_ANGLE = 'obtuse-angle',
}

const ChevronShapeValues = {
  default: 'M15.01 12.064L9.006 6.061 10.066 5l7.065 7.064-7.07 7.071L9 18.075',
  'obtuse-angle':
    'M8.746 2.118L1.46 5.888a1 1 0 0 1-.92-1.776l7.731-4A1 1 0 0 1 9.166.1l8.27 4a1 1 0 0 1-.871 1.8l-7.82-3.782z',
};

type SVGProps = React.SVGProps<SVGSVGElement> & {
  chevronShape?: ChevronShapeType;
};

const Chevron = ({
  className,
  id,
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  color = 'currentColor',
  chevronShape = ChevronShapeEnum.DEFAULT,
  opacity = 1,
  ...props
}: SVGProps) => (
  <svg
    {...props}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    className={className}
    id={id}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d={ChevronShapeValues[chevronShape]}
      opacity={opacity}
    />
  </svg>
);

export default React.memo(Chevron);
